import { Box, Typography } from '@mui/material'
import { IAllocations } from 'hooks/types'

import { getAllHours, getAllFTE } from '../../utils'

interface ISummaryProp {
  allocations: IAllocations[] | undefined
  title: string
}

const Summary: React.FC<ISummaryProp> = ({ allocations, title }) => {
  if (!allocations?.length) return null

  return (
    <Box display="flex" alignItems="center">
      <Typography>{title}&nbsp;</Typography>
      <Typography variant="subtitle1">
        {getAllHours(allocations)}&nbsp;
      </Typography>
      <Typography>FTE:&nbsp;</Typography>
      <Typography variant="subtitle1">
        {getAllFTE(allocations).toFixed(2)}
      </Typography>
    </Box>
  )
}

export default Summary
