import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Container, Box, Tab } from '@mui/material'
import {
  useGetReportCustomers,
  useGetReportProjects,
} from 'hooks/reportCustomers'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderTab } from 'views/LayoutUI/components'

import ReportCard from './components/ReportCard'

const ReportCustomers = () => {
  const { t } = useTranslation('reportCustomers')
  const [value, setValue] = useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const reportCustomers = useGetReportCustomers()
  const reportProjects = useGetReportProjects()

  return (
    <Container>
      <HeaderTab translate="reportCustomers" id={t('header.id')} />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label={t('nav.lab3')}>
            <Tab label={t('nav.label1')} value="1" />
            <Tab label={t('nav.label2')} value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <ReportCard title={t('card.title1')} data={reportProjects} />
        </TabPanel>
        <TabPanel value="2">
          <ReportCard title={t('card.title2')} data={reportCustomers} />
        </TabPanel>
      </TabContext>
    </Container>
  )
}

export default ReportCustomers
