import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import DownloadIcon from '@mui/icons-material/Download'
import { PkupContext } from 'pages/Pkup/context/PkupContext'
import { usePkupsDetails } from 'pages/HrDashboard/hooks'
import { GridRowsProp } from '@mui/x-data-grid'
import { EmptyArrayCard, TableComponent } from 'components/common'
import { PkupCommentForm } from 'components/Pkup'
import { useStatus, useSupervisor, useUpdateApplication } from 'hooks/pkup'

import { useDownloadReport } from '../../hooks'

const ShowMoreReport: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['reportPkup', 'buttons'])
  const { selectedReport } = useContext(PkupContext)
  const { getSupervisor } = useSupervisor()
  const { statusBox } = useStatus()
  const { handleDownload } = useUpdateApplication()
  const { pkupDetails } = usePkupsDetails(selectedReport.id)
  const handleDownloadReportZip = useDownloadReport()
  const initialValues = {
    approverComment: pkupDetails.approverComment || '',
  }

  const contentReportArr = [
    { name: `${t('modal.showMore.label1')}`, text: pkupDetails.title },
    { name: `${t('modal.showMore.label2')}`, text: pkupDetails.description },
    { name: `${t('modal.showMore.label3')}`, text: pkupDetails.period },
    {
      name: `${t('modal.showMore.label4')}`,
      text: getSupervisor(pkupDetails.supervisorId).name,
    },
    {
      name: `${t('modal.showMore.label9')}`,
      text: statusBox(pkupDetails),
    },
  ]

  const columns = [
    `${t('modal.showMore.label5')}`,
    `${t('modal.showMore.label1')}`,
    `${t('modal.showMore.label6')}`,
    `${t('modal.showMore.label7')}`,
  ]
  const rows: GridRowsProp = pkupDetails?.works?.map((work) => {
    return {
      project: work.project.name,
      title: work.title,
      members: work.workmembers.map((member) => (
        <Box key={member.id} sx={{ display: 'flex' }}>
          <PersonIcon color="primary" />
          {getSupervisor(member.userId).name}{' '}
          {member.owner ? t('modal.showMore.label6text') : ''}
        </Box>
      )),
      files: work.pkupattachments.length
        ? work.pkupattachments.map((attachment) => (
            <Box key={attachment.id} sx={{ display: 'flex' }}>
              <Typography variant="subtitle1">
                {attachment.attachmentName}
              </Typography>
              <Tooltip title={`${t('download', { ns: 'buttons' })}`}>
                <IconButton
                  onClick={() =>
                    handleDownload({
                      id: attachment.id,
                      name: attachment.attachmentName,
                    })
                  }
                >
                  <DownloadIcon color="success" />
                </IconButton>
              </Tooltip>
            </Box>
          ))
        : `${t('modal.showMore.label7text')}`,
    }
  })
  return (
    <Paper
      sx={{
        overflow: 'auto',
        maxHeight: '75vh',
        margin: '0.5rem',
        padding: '0.5rem',
      }}
    >
      {contentReportArr.map((report, index) => (
        <Box key={report.name}>
          <Typography
            variant="h3"
            sx={{
              display:
                index === contentReportArr.length - 1 ? 'flex' : 'inline-block',
              alignItems: 'center',
            }}
          >
            <b>{report.name}:</b> {report.text}
          </Typography>
        </Box>
      ))}
      {pkupDetails?.works?.length ? (
        <TableComponent rows={rows} columns={columns} />
      ) : (
        <EmptyArrayCard message={t('modal.showMore.label4text')} />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1">
          {t('modal.showMore.downloadText')}
        </Typography>
        <Tooltip title={`${t('download', { ns: 'buttons' })}`}>
          <IconButton onClick={() => handleDownloadReportZip(pkupDetails)}>
            <DownloadIcon color="success" />
          </IconButton>
        </Tooltip>
      </Box>
      <PkupCommentForm id={selectedReport.id} initialValues={initialValues} />
    </Paper>
  )
}
export default ShowMoreReport
