import {
  Box,
  Button,
  ButtonGroup,
  Container,
  IconButton,
  styled,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { NavLink } from 'react-router-dom'

export const LeftNav = styled(Box)({
  boxShadow: '5px 0px 10px -5px rgba(26, 61, 146, 1)',
})
export const Group = styled(ButtonGroup)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  padding: '1rem 0.5rem',
})

export const OneButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  paddingLeft: '0.75rem',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: 'rgba(243, 246, 255, 0.1)',
    '&:after': {
      left: '120%',
      transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
    },
  },
  '& > .itemName': {
    display: 'block',
    textAlign: 'left',
  },
  '&:after': {
    background: '#fff',
    content: '""',
    height: '155px',
    left: '-75px',
    opacity: '0.3',
    position: 'absolute',
    top: '-50px',
    transform: 'rotate(35deg)',
    transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
    width: '50px',
    zIndex: '-10',
  },
}))

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: '#fff',
  textDecoration: 'none',
  '&.active button': {
    backgroundColor: theme.palette.primary.dark,
  },
}))

export const LogoContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  padding: '1rem',
  color: theme.palette.primary.contrastText,
  '& > span': {
    lineHeight: '0.9rem',
    fontSize: '1.5rem',
  },
}))

export const IconButtons = styled(IconButton)({
  color: 'white',
  fontSize: '1rem',
})

export const ProfileText = styled(Typography)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})
