import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { IUserProps } from 'components/users'

import { Summary, TableAllocations } from '../common'
import { ISelectEditAllocationProps } from '../utils'
import { SelectAllocations } from '../common/SelectAllocations'

const CurrentAllocations: React.FC<IUserProps & ISelectEditAllocationProps> = ({
  user,
  setEditAllocation,
}) => {
  const { t } = useTranslation(['userAllocations', 'buttons'])

  const currentAllocations = user?.allocations?.filter(
    (allocation) => allocation.date === format(new Date(), 'yyyy/MM')
  )

  const showCurrentAllocations = SelectAllocations(
    currentAllocations,
    setEditAllocation,
    user
  )
  return (
    <>
      <TableAllocations allocation={showCurrentAllocations || []} />
      <Summary
        allocations={currentAllocations}
        title={t('tabPanel.currentSummary')}
      />
    </>
  )
}

export default CurrentAllocations
