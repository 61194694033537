import { NOTIFICATION, useNotifications } from 'components/common'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

export interface IUpdateData {
  id: number
  isBillable?: boolean
  isProductive?: boolean
  type: string
}

const updateProjectBoolean = async (body: IUpdateData) => {
  const { id, isBillable, isProductive, type } = body
  if (type === 'isBillable') {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PROJECTS_UPDATE,
      { isBillable },
      {
        id: id,
      }
    )
    return data
  } else {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PROJECTS_UPDATE,
      { isProductive },
      {
        id: id,
      }
    )
    return data
  }
}

export const useUpdateCustomerProjectBoolean = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')

  const { mutate: updateCustomerProjectBoolean } = useMutation(
    updateProjectBoolean,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('customerProject')} ${t('update')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.customer])
      },
    }
  )

  return { updateCustomerProjectBoolean }
}
