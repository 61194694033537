import { NOTIFICATION, useNotifications } from 'components/common'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

export interface ICustomerData {
  id: string | undefined
  description?: string
}

const updateSingleProjectDetails = async (
  projectDetailsData: ICustomerData
) => {
  const { id, description } = projectDetailsData
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PROJECTS_UPDATE,
    { description },
    {
      id,
    }
  )
  return data
}

export const useUpdateProjectDetails = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')

  const { mutate: updateProjectDetails } = useMutation(
    updateSingleProjectDetails,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('description')} ${t('update')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.customer])
      },
    }
  )

  return { updateProjectDetails }
}
