import { useQuery } from 'react-query'
import { queryKeys } from 'react-query/constants'
import { AxiosResponse } from 'axios'
import { APIRoutes, apiRequest } from 'apiServices'
import { IProjects } from 'hooks/types'

export const useGetAllProjects = () => {
  async function getAllProjects(): Promise<IProjects[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PROJECTS_GETALL,
      {},
      {},
      'limit=9999'
    )
    return data.data
  }
  const fallback: IProjects[] = []

  const { data: projectList = fallback } = useQuery(
    queryKeys.projectsLimit,
    getAllProjects
  )

  return projectList
}
