import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Container, Tab, Typography } from '@mui/material'
import { HeaderTab } from 'views/LayoutUI/components'
import { DefaultModal } from 'views/LayoutUI/modals'
import { ISelectingModal, ValueType } from 'hooks/types'
import {
  ModalComponent,
  ModalConfirm,
  useNotifications,
} from 'components/common'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { format } from 'date-fns'

import {
  AddVacationApplication,
  EditVacationApplication,
  RequestInfo,
} from './modals'
import { EmployeeVacationRequest, YourVacationRequest } from './components'
import { useVacationRequests } from './hooks'
import { IVacationRequest } from './hooks/useVacationRequests'

const VacationRequest: React.FC = (): JSX.Element => {
  const { t } = useTranslation('vacationRequests')
  const [value, setValue] = useState<ValueType>('1')
  const [selectedRequest, setSelectedRequest] = useState<
    IVacationRequest | undefined
  >(undefined)
  const { modalName } = useNotifications()
  const { user } = useAuth()
  const { deleteVacationRequest } = useVacationRequests(user?.id)
  const handleChange = (_, newValue: string) => {
    setValue(newValue)
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'add':
        return {
          component: <AddVacationApplication />,
          title: t('modal.add.title'),
        }
      case 'edit':
        return {
          component: (
            <EditVacationApplication selectedRequest={selectedRequest} />
          ),
          title: t('modal.edit.title'),
        }
      case 'info':
        return {
          component: <RequestInfo selectedRequest={selectedRequest} />,
          title: t('modal.info.title'),
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle1">
                    {t('modal.delete.question1')}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>{t('modal.delete.type')}:</b>{' '}
                    {selectedRequest?.vacationType || '--'}
                  </Typography>
                  <Typography variant="subtitle1">
                    <b>{t('modal.delete.dates')}:</b>{' '}
                    {selectedRequest?.startDate &&
                      format(
                        new Date(selectedRequest.startDate),
                        'yyyy-MM-dd'
                      )}{' '}
                    -{' '}
                    {selectedRequest?.endDate &&
                      format(
                        new Date(selectedRequest.endDate),
                        'yyyy-MM-dd'
                      )}{' '}
                    ({selectedRequest?.days}{' '}
                    {selectedRequest?.days === 1
                      ? t('modal.delete.day')
                      : t('modal.delete.days')}
                    )?
                  </Typography>
                </Box>
              }
              deleteFunction={() => deleteVacationRequest(selectedRequest?.id)}
            />
          ),
          title: t('modal.delete.title'),
        }
      default:
        return {
          component: <DefaultModal />,
          title: t('modal.default'),
        }
    }
  }
  return (
    <Container>
      <HeaderTab translate="vacationRequests" />
      <Box sx={{ minWidth: '1050px' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('panel1.title')} value="1" />
              {isUserPermitted(user, [
                permissionList.VACATION_REQUEST_ADMIN,
              ]) && <Tab label={t('panel2.title')} value="2" />}
            </TabList>
          </Box>
          <TabPanel value="1">
            <YourVacationRequest setSelectedRequest={setSelectedRequest} />
          </TabPanel>
          <TabPanel value="2">
            <EmployeeVacationRequest setSelectedRequest={setSelectedRequest} />
          </TabPanel>
        </TabContext>
      </Box>
      <ModalComponent
        title={selectingModal().title}
        component={selectingModal().component}
      />
    </Container>
  )
}
export default VacationRequest
