import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Container,
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { HeaderTab } from 'views/LayoutUI/components'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AddIcon from '@mui/icons-material/Add'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import SearchIcon from '@mui/icons-material/Search'
import HistoryIcon from '@mui/icons-material/History'
import DeleteIcon from '@mui/icons-material/Delete'
import CancelIcon from '@mui/icons-material/Cancel'
import RedoIcon from '@mui/icons-material/Redo'
import {
  useNotifications,
  EmptyArrayCard,
  DetailsTooltip,
  ModalComponent,
  ModalConfirm,
  TableComponent,
  NOTIFICATION,
} from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import {
  useGetCustomerProject,
  useDeleteUser,
  useUpdateIsPM,
  useDownloadExcel,
} from 'hooks/customers'
import { addYears, format } from 'date-fns'
import { useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import fileDownload from 'js-file-download'
import { useGetWorkdays } from 'hooks/calendar/useGetWorkdays'
import { ISelectingModal, DateType } from 'hooks/types'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { StyledButton } from 'components/common/SwitchButton/index.style'
import { IUser, ITimeFrame } from 'pages/Customers/types'
import DownloadIcon from '@mui/icons-material/Download'
import { LoadingButton } from '@mui/lab'
import { PageRoutes } from 'routes'
import { config } from 'config'

import { RemoveTimeframes, AddNewUser, AddTimeframes } from './modals'

export interface IButtons {
  title: string
  path: string | null
  icon: JSX.Element
}
const ProjectManageUser: React.FC = (): JSX.Element => {
  const [user, setUser] = useState<IUser>({} as IUser)
  const [startValue, setStartValue] = useState<DateType>(new Date())
  const [isFetching, setIsFetching] = useState(false)
  const { t } = useTranslation(['projectManageUser', 'buttons'])
  const params = useParams()
  const {
    handleOpenModal,
    modalName,
    userDetailsId,
    setUserDetailsId,
    setNotification,
  } = useNotifications()
  const navigate = useNavigate()
  const { updateIsPM } = useUpdateIsPM()
  const { downloadExcel } = useDownloadExcel()
  const { deleteUser } = useDeleteUser()
  const { currentCalendarDate, setSelectedUserWorkDays } = useNotifications()
  const { getWorkdaysForUser } = useGetWorkdays()
  const customerProject = useGetCustomerProject(params.id)

  const columns = [
    `${t('table.cell1')}`,
    `${t('table.cell2')}`,
    `${t('table.cell3')}`,
    `${t('table.cell4')}`,
    '',
  ]

  const handleDownload = () => {
    setIsFetching(true)
    const date = startValue
      ? format(new Date(startValue), 'yyyy-MM')
      : startValue
    const filename =
      customerProject.name.split(' ').join('_') +
      `${t('typographies.typography4')}` +
      date
    const downloadData = {
      id: params.id,
      month: startValue,
    }
    downloadExcel(downloadData)
      .then((response) => {
        if (response) {
          fileDownload(new Blob([response]), filename + '.xlsx')
        } else {
          alert(t('stickybar.alert1'))
        }
      })
      .catch((error) => {
        setNotification({
          message: error.message,
          variant: NOTIFICATION.error,
        })
      })
      .finally(() => setIsFetching(false))
  }

  const buttonsArr: IButtons[] = [
    {
      title: t('buttons.button2'),
      path: PageRoutes.USER_ALLOCATIONS,
      icon: <AddLocationIcon />,
    },
    {
      title: t('buttons.button3'),
      path: PageRoutes.USER_CALENDAR,
      icon: <CalendarMonthIcon />,
    },
    {
      title: `${t('delete', { ns: 'buttons' })}`,
      path: null,
      icon: <DeleteIcon color="error" />,
    },
  ]
  const rows: GridRowsProp = customerProject?.users?.map((user: IUser) => {
    const timeFramesForCurrentMonth = user?.ProjectUser?.timeframes?.filter(
      (timeframe: ITimeFrame) =>
        format(new Date(), 'yyyy-MM-dd') >= timeframe.startDate?.slice(0, 10) &&
        format(new Date(), 'yyyy-MM-dd') <= timeframe.endDate?.slice(0, 10)
    )
    const oldTimeFrames = user?.ProjectUser?.timeframes?.filter(
      (timeframe: ITimeFrame) =>
        format(new Date(), 'yyyy-MM-dd') > timeframe.endDate?.slice(0, 10)
    )
    const incomingTimeFrames = user?.ProjectUser?.timeframes?.filter(
      (timeframe: ITimeFrame) =>
        format(new Date(), 'yyyy-MM-dd') < timeframe.startDate?.slice(0, 10)
    )
    const timeFrames = timeFramesForCurrentMonth?.map(
      (timeframe: ITimeFrame) => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          key={timeframe.id}
        >
          <Box sx={{ margin: '0.1rem' }}>
            {timeframe.startDate?.slice(0, 10)} -
            {timeframe.endDate?.slice(0, 10)}
          </Box>
          {timeframe.notes && (
            <>
              {t('tooltip.title3')}: {timeframe.notes}
            </>
          )}
        </Box>
      )
    )

    const timeFramesHistory = oldTimeFrames.map((timeframe: ITimeFrame) => (
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        key={timeframe.id}
      >
        <Box sx={{ margin: '0.1rem' }}>
          {timeframe.startDate?.slice(0, 10)} -{' '}
          {timeframe.endDate?.slice(0, 10)}{' '}
        </Box>
        {timeframe.notes && (
          <>
            {t('tooltip.title3')}: {timeframe.notes}
          </>
        )}
      </Box>
    ))
    const timeFramesNext = incomingTimeFrames.map((timeframe: ITimeFrame) => (
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        key={timeframe.id}
      >
        <Box sx={{ margin: '0.1rem' }}>
          {timeframe.startDate?.slice(0, 10)} -{' '}
          {timeframe.endDate?.slice(0, 10)}{' '}
        </Box>
        {timeframe.notes && (
          <>
            {t('tooltip.title3')}: {timeframe.notes}
          </>
        )}
      </Box>
    ))

    return {
      name: (
        <DetailsTooltip id={userDetailsId}>
          <Box
            onMouseOver={() => setUserDetailsId(user.id)}
            sx={{
              cursor: 'pointer',
              width: 'fit-content',
              fontWeight: user.ProjectUser.isPm ? 'bold' : 'normal',
            }}
          >
            {user.firstName} {user.lastName}
          </Box>
        </DetailsTooltip>
      ),
      email: user.email,
      IsPM: (
        <StyledButton
          valueistrue={
            user.ProjectUser.isPm === null
              ? 'null'
              : user.ProjectUser.isPm.toString()
          }
          onClick={() => {
            updateIsPM({
              projectId: params.id,
              userId: user.id.toString(),
            })
          }}
        >
          {user.ProjectUser.isPm === null ? (
            t('table.button3')
          ) : user.ProjectUser.isPm ? (
            <ThumbUpIcon color="success" />
          ) : (
            <ThumbDownIcon color="error" />
          )}
        </StyledButton>
      ),
      timeFrame: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
          key={user.id}
        >
          <Tooltip title={`${t('tooltip.title2')}`}>
            <IconButton
              onClick={() => {
                setUser(user)
                handleOpenModal('addTimeframes')
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          {user?.ProjectUser?.timeframes?.length > 0 && (
            <Tooltip title={`${t('tooltip.title')}`}>
              <IconButton
                onClick={() => {
                  setUser(user)
                  handleOpenModal('removeTimeframes')
                }}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          )}
          {timeFrames.length > 0 && (
            <Tooltip
              sx={{ width: 'none' }}
              title={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="subtitle1" color="primary.contrastText">
                    {t('typographies.typography2')}:
                  </Typography>
                  {timeFrames}
                </Box>
              }
            >
              <IconButton>
                <SearchIcon color="disabled" />
              </IconButton>
            </Tooltip>
          )}
          {timeFramesHistory.length > 0 && (
            <Tooltip
              title={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="subtitle1" color="primary.contrastText">
                    {t('typographies.typography3')}:
                  </Typography>
                  {timeFramesHistory}
                </Box>
              }
            >
              <IconButton>
                <HistoryIcon color="disabled" />
              </IconButton>
            </Tooltip>
          )}
          {timeFramesNext.length > 0 && (
            <Tooltip
              title={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="subtitle1" color="primary.contrastText">
                    {t('typographies.typography6')}:
                  </Typography>
                  {timeFramesNext}
                </Box>
              }
            >
              <IconButton>
                <RedoIcon color="disabled" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map((button) => (
            <Tooltip title={button.title} key={button.title}>
              <IconButton
                onClick={() => {
                  if (button.title === `${t('delete', { ns: 'buttons' })}`) {
                    setUser(user)
                    handleOpenModal('delete')
                  }
                  if (button.title === `${t('buttons.button3')}`) {
                    getWorkdaysForUser(
                      currentCalendarDate,
                      user.id.toString()
                    ).then((res) => {
                      setSelectedUserWorkDays(res)
                    })
                  }
                  button.path &&
                    navigate(button.path.replace(':id', `${user.id}`))
                }}
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  })

  const deleteContent = () => {
    return (
      <Typography>
        {t('modal.title5')} {user.firstName} {user.lastName}?
      </Typography>
    )
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteUser(user?.ProjectUser.id)}
            />
          ),
          title: t('modal.title1'),
        }
      case 'addNewUser':
        return {
          component: <AddNewUser />,
          title: t('modal.title2'),
        }
      case 'addTimeframes':
        return {
          component: <AddTimeframes user={user} />,
          title: t('modal.title3'),
        }
      case 'removeTimeframes':
        return {
          component: <RemoveTimeframes user={user} />,
          title: `${t('modal.title4')} - ${user?.firstName} ${user?.lastName}`,
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('modal.defaultModal.title')}`,
        }
    }
  }

  return (
    <Container sx={{ minWidth: '50rem' }}>
      <HeaderTab
        translate="projectManageUser"
        id={`${customerProject.name} - ${t('header.title')}`}
        pageLink={PageRoutes.PROJECTS}
      />
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0.75rem',
          gap: '5px',
        }}
      >
        <Button onClick={() => handleOpenModal('addNewUser')}>
          {t('buttons.button1')}
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
          <Typography>{t('typographies.typography1')}</Typography>
          <DatePicker
            views={['year', 'month']}
            label={t('form.label4')}
            value={startValue}
            minDate={config.START_DATE}
            maxDate={addYears(config.START_DATE, config.AMOUNT_OF_YEARS_TO_ADD)}
            inputFormat="yyyy-MM"
            onChange={(newValue) => {
              setStartValue(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <LoadingButton
            size="small"
            type="submit"
            endIcon={<DownloadIcon />}
            onClick={handleDownload}
            loading={isFetching}
            loadingPosition="end"
            variant="contained"
          >
            {t('buttons.button4')}{' '}
          </LoadingButton>
        </Box>
      </Paper>
      {customerProject?.users?.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard
          styles={{ marginTop: '10px' }}
          message={`${customerProject.name} ${t('typographies.typography5')}`}
        >
          {null}
        </EmptyArrayCard>
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}

export default ProjectManageUser
