import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { ITags, IHelpfulUsers } from 'hooks/types'

interface NewHelpfulUsers {
  name: string
  skills: string
  compatibility: number
}
interface UseHelpingUsers {
  newHelpfulUsers: NewHelpfulUsers[]
}

async function getHelpfulUsers(): Promise<IHelpfulUsers[]> {
  const { data }: AxiosResponse = await apiRequest(APIRoutes.HELPFULUSERS_GET)
  return data
}

export const useHelpingUsers = (): UseHelpingUsers => {
  const fallback: IHelpfulUsers[] = []

  const { data: helpfulUsers = fallback } = useQuery(
    queryKeys.helpfulUsers,
    getHelpfulUsers
  )

  const newHelpfulUsers = helpfulUsers?.map((user: IHelpfulUsers) => {
    return {
      name: `${user?.User?.firstName} ${user?.User?.lastName}`,
      skills: user.User.tags.map((tag: ITags) => tag.name).join(', '),
      compatibility: user.matchPercentage,
    }
  })

  return { newHelpfulUsers }
}
