import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { NOTIFICATION, useNotifications } from 'components/common'

export interface IInitialValues {
  title: string
  note: string
}
export interface INotes {
  author?: { firstName: string; lastName: string }
  authorId?: number
  createdAt?: Date | null
  id?: number
  note: string
  title: string
  updatedAt?: Date | null
  userId?: number
}
export const useNotes = (userId: number, noteId?: INotes['id']) => {
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const getNotes = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.EMPLOYEE_NOTES,
      {},
      { id: userId }
    )
    return data
  }
  const addNoteToUser = async (note: INotes) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.EMPLOYEE_NOTES_ADD,
      {
        userId: userId,
        title: note?.title,
        note: note?.note,
      },
      {}
    )
    return data
  }
  const deleteNoteFromUser = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.EMPLOYEE_NOTES_DELETE,
      {},
      { id: noteId }
    )
    return data
  }
  const editNoteToUser = async (note: INotes) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.EMPLOYEE_NOTES_UPDATE,
      {
        userId: null,
        title: note?.title,
        note: note?.note,
      },
      { id: noteId }
    )
    return data
  }
  const fallback = [] as INotes[]
  const { mutate: addNote } = useMutation(addNoteToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('note')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.notes])
    },
  })
  const { mutate: deleteNote } = useMutation(deleteNoteFromUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('note')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.notes])
    },
  })
  const { mutate: editNote } = useMutation(editNoteToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('note')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.notes])
    },
  })
  const { data: notes = fallback, refetch } = useQuery(
    queryKeys.notes,
    getNotes
  )
  useEffect(() => {
    refetch()
  }, [userId])
  return { notes, addNote, deleteNote, editNote }
}
