import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IProject, IUser } from 'hooks/types'
import { IWorkTypes } from 'pages/Calendar/components/Calendar/types'

export interface ICurrentWork {
  comment: string
  createdAt: string
  deletedAt: string | null
  holidaysCategoryId: number | null
  hours: number
  id: number
  isOverhours: boolean
  isPlanned: boolean
  logDayFrom: string
  logDayTo: string
  project: IProject
  projectId: number
  progress: string | null
  revision: number
  statusId: number
  updatedAt: string
  userId: number
  workType: IWorkTypes
  workTypeId: number
}
interface IUserDetails {
  UserAgreements: IUser['UserAgreements']
  currentWork: ICurrentWork
  firstName: string
  id: number
  lastName: string
  startDate: string
  title: string
}

export const useUserDetails = (userId: number | null) => {
  const getUserDetails = async (): Promise<IUserDetails> => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.GET_USERDETAILS,
      {},
      { id: userId },
      null
    )
    return data
  }
  const fallback = {} as IUserDetails

  const { data: userDetails = fallback, refetch } = useQuery(
    queryKeys.userDetails,
    getUserDetails,
    {
      enabled: userId ? true : false,
    }
  )

  return { userDetails, refetch }
}
