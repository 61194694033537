import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import fileDownload from 'js-file-download'

import { CreatePkupProps } from './types'

export const useUpdateApplication = (applicationId?: number) => {
  const { t } = useTranslation('handleMessage')
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()

  async function updatePkup(values: CreatePkupProps) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_UPDATE,
      {
        title: values.title,
        description: values.description,
        works: values?.works,
        supervisorId: values?.supervisorId?.id,
        period: values?.period,
      },
      { id: applicationId }
    )
    return data
  }
  async function getDownloadAttachment(attachmentId: number) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_GET_ATTACHMENT,
      {},
      { attachmentId: attachmentId }
    )
    return data
  }
  const handleDownload = async (attachment: { id: number; name: string }) => {
    await getDownloadAttachment(attachment.id).then((response) => {
      fileDownload(new Blob([response]), `${attachment.name}`)
    })
  }
  const { mutate: editPkup } = useMutation(updatePkup, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('pkup')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.applicationsList])
      queryClient.invalidateQueries([queryKeys.pkupDetails])
    },
  })

  return {
    handleDownload,
    editPkup,
  }
}
