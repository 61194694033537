import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import palette from 'theme/palette'

interface IProps {
  children: string | ReactNode
}
const ModalTitle: React.FC<IProps> = ({ children }) => {
  return (
    <Box
      sx={{
        boxShadow: `0px 10px 10px -12px ${palette.primary.light}`,
        backgroundColor: 'primary.main',
        padding: 1,
      }}
    >
      <Typography
        color="primary.contrastText"
        sx={{
          paddingX: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          textTransform: 'uppercase',
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default ModalTitle
