import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useCVSchema = () => {
  const { t } = useTranslation('validation')

  const userBioSchema = yup.object({
    title: yup
      .string()
      .min(3, `${t('title.minLength')}`)
      .required(`${t('title.required')}`),
    description: yup
      .string()
      .min(8, `${t('description.minLength')}`)
      .required(`${t('description.required')}`),
  })
  const userEducationSchema = yup.object({
    years: yup
      .string()
      .min(4, `${t('years.minLength')}`)
      .required(`${t('years.required')}`),
    grade: yup
      .string()
      .min(3, `${t('grade.minLength')}`)
      .required(`${t('grade.required')}`),
    place: yup
      .string()
      .min(3, `${t('place.minLength')}`)
      .required(`${t('place.required')}`),
  })
  const userProfileSchema = yup.object({
    type: yup
      .string()
      .min(2, `${t('profile.minLength')}`)
      .required(`${t('profile.required')}`),
  })
  const userProjectsSchema = yup.object({
    title: yup
      .string()
      .min(3, `${t('name.minLength')}`)
      .required(`${t('name.required')}`),
    description: yup
      .string()
      .min(8, `${t('description.minLength')}`)
      .required(`${t('description.required')}`),
    stack: yup
      .string()
      .min(3, `${t('stack.minLength')}`)
      .required(`${t('stack.required')}`),
  })
  return {
    userBioSchema,
    userEducationSchema,
    userProfileSchema,
    userProjectsSchema,
  }
}
