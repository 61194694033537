import { Box, Button } from '@mui/material'
import { format } from 'date-fns'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { HolidayFields } from 'components/users'
import { useValidateSchema } from 'hooks/holidays'
import { IUserHolidays } from 'hooks/types'

import { useUpdateHolidayToUser } from './utils'

interface IEditHolidayProps {
  userHoliday: IUserHolidays | null
}

const EditHoliday: React.FC<IEditHolidayProps> = ({
  userHoliday,
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const updateHoliday = useUpdateHolidayToUser()
  const { validationSchemaDate } = useValidateSchema()
  return (
    <Box>
      <Formik
        initialValues={{
          days: userHoliday?.days || 0,
          year: userHoliday?.year.toString() || '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (userHoliday) {
            updateHoliday({
              days: values?.days,
              year: format(new Date(values.year), 'yyyy'),
              holidaysCategoryId: userHoliday.holidaysCategoryId,
              id: userHoliday?.id,
            })
            setSubmitting(false)
          }
        }}
        validationSchema={validationSchemaDate}
      >
        <Form>
          <Box my={2}>
            <HolidayFields />
          </Box>
          <Box>
            <Button type="submit" sx={{ marginRight: 3 }}>
              {t('save', { ns: 'buttons' })}
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  )
}
export default EditHoliday
