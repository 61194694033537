import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
} from '@mui/material'
import { TextField as TF } from 'formik-mui'
import { CustomizedSelect } from 'components/common'
import { useGetAllCustomers } from 'hooks/customers'
import { useGetAgreements } from 'hooks/agreements'

import { WorkTypeAutocomplete } from '../../components'
import { useArrays, useUpdateWorkTypes } from '../../hooks'

import { useEditWorkTypes } from './utils'

interface IProps {
  workTypeId: number | null
}

const EditWorkType: React.FC<IProps> = ({ workTypeId }) => {
  const { t } = useTranslation(['systemWorkTypes', 'buttons'])

  const agreementsList = useGetAgreements()
  const { customerEditFields } = useArrays()
  const customersList = useGetAllCustomers()
  const { updateWorkTypes, validationSchema } = useUpdateWorkTypes(workTypeId)
  const initialValues = useEditWorkTypes(workTypeId)

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          updateWorkTypes(values)
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                marginTop: '10px',
              }}
            >
              {customerEditFields.map(({ id, name, type, label }) => (
                <Field
                  fullWidth
                  key={id}
                  component={TF}
                  name={name}
                  type={type}
                  label={label}
                />
              ))}

              <FormControl>
                <InputLabel id={t('modals.form.label1')}>
                  {t('modals.form.label1')}
                </InputLabel>
                <Field
                  label={t('modals.form.label1')}
                  name="customer"
                  component={CustomizedSelect}
                  fieldValueToChange="project"
                  changeToValue="none"
                >
                  <MenuItem value={t('modals.none')}>
                    {t('modals.none')}
                  </MenuItem>
                  {customersList?.map(({ id, name }) => (
                    <MenuItem key={id} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              {values.customer !== t('modals.none') && customersList?.length ? (
                <FormControl>
                  <InputLabel id={t('modals.form.label5')}>
                    {t('modals.form.label5')}
                  </InputLabel>
                  <Field
                    label={t('modals.form.label5')}
                    name="project"
                    component={CustomizedSelect}
                  >
                    <MenuItem value={t('modals.none')}>
                      {t('modals.none')}
                    </MenuItem>
                    {customersList
                      ?.find((customer) => customer.name === values.customer)
                      ?.projects.map(({ id, name }) => (
                        <MenuItem key={id} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>
              ) : null}
              <Field
                component={WorkTypeAutocomplete}
                optionsData={agreementsList}
                name="agreements"
              />
              <Button
                disabled={isSubmitting}
                type="submit"
                sx={{ width: '30%', marginTop: '10px' }}
              >
                {t('save', { ns: 'buttons' })}
              </Button>
              {isSubmitting && <LinearProgress />}
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default EditWorkType
