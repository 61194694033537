import { useTranslation } from 'react-i18next'
import TitleIcon from '@mui/icons-material/Title'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import DescriptionIcon from '@mui/icons-material/Description'
import { useUser } from 'pages/Users/pages/utlis'

import { ISelectedOptions } from '../../../UserCV'
import CardCheckboxOption from '../CardCheckboxOption'

const ProjectsOption: React.FC = (): JSX.Element => {
  const { t } = useTranslation('userCV')

  const { userProjects } = useUser()

  const ProjectsFields = (field: ISelectedOptions) => [
    {
      name: field.title,
      title: `${t('cv.bioTable.title')}`,
      icon: <TitleIcon />,
    },
    {
      name: field.description,
      title: `${t('cv.projectsTable.description')}`,
      icon: <DescriptionIcon />,
    },
    {
      name: field.stack,
      title: `${t('cv.projectsTable.stack')}`,
      icon: <StarBorderIcon />,
    },
  ]

  return (
    <CardCheckboxOption
      name="selectedProjects"
      title={t('generateProfile.selectProjects')}
      optionArray={ProjectsFields}
      userDetails={userProjects}
    />
  )
}

export default ProjectsOption
