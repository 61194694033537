import { IUserProps } from 'components/users'
import { IAllocations } from 'hooks/types'

import { getProjectName } from '../../components/utils'
import { AllocationFormik } from '../../components/common'
import { useAllocationCheck } from '../useAllocationCheck'

import { useEditAllocation } from './utils'

interface IEditAllocationProps {
  editAllocation: IAllocations | null
}

const EditAllocation: React.FC<IUserProps & IEditAllocationProps> = ({
  user,
  editAllocation,
}): JSX.Element => {
  const { updateUserAllocation } = useEditAllocation()
  const { allWorkingHours } = useAllocationCheck()

  const projectName = getProjectName(editAllocation, user)
  const initialValues = {
    selectedProject: projectName
      ? {
          name: projectName,
          id: editAllocation?.ProjectUser?.id,
        }
      : null,
    date:
      new Date(
        Number(editAllocation?.date.split('/')[0]),
        Number(editAllocation?.date.split('/')[1]) - 1
      ) || new Date(),
    hours: editAllocation?.allWorkingHours || `${allWorkingHours}`,
    fte: editAllocation?.FTE || '',
    isOverHours: editAllocation?.isOverhours || false,
  }

  return (
    <>
      <AllocationFormik
        user={user}
        formikValues={initialValues}
        submitForm={updateUserAllocation}
        editAllocationId={editAllocation?.id}
      />
    </>
  )
}
export default EditAllocation
