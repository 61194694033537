export enum workType {
  HOLIDAY = 'Holiday',
  BUSINESS_TRIP = 'ALL - Business Trip',
  SICK = 'Sick',
  OFFICE_WORK = 'ALL - Office Work',
  HOME_OFFICE = 'ALL - Home Office',
}

export enum Progress {
  PERFECT = 'PERFECT',
  GOOD = 'GOOD',
  BORING = 'BORING',
  BAD = 'BAD',
}
