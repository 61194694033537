import { useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IPagination, IUsers } from 'hooks/types'
import {
  IPaginateReducer,
  defaultPaginationValue,
  getDefault,
} from 'hooks/table'

export interface ITag {
  id: number
  name: string
}

export type IData = IPagination & { data: IUsers[] }

export const useUsers = (searchParams?: URLSearchParams) => {
  const navigate = useNavigate()

  // Inicjalizuj stan paginacji na podstawie searchParams
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>(
    getDefault(defaultPaginationValue, searchParams, {
      filters: JSON.stringify({ inactive: false }),
    })
  )

  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }

  // Monitoruj zmiany w searchParams
  useEffect(() => {
    if (searchParams) {
      const search = searchParams.get('search') || ''

      // Zaktualizuj paginację w oparciu o search
      setPaginationUc((prev) => {
        const newPaginationUc = {
          ...prev,
          search: search, // Zaktualizowana wartość
        }
        return newPaginationUc
      })
    }
  }, [searchParams])

  const orderBy = paginationUc?.order
    ? `&order={"order":"${paginationUc.order.order}","column":"${paginationUc.order.column}"}`
    : ''

  const params = useMemo(() => {
    const filters = paginationUc?.filters
      ? `&filters=${paginationUc?.filters}`
      : ''
    const searchInput = paginationUc?.search
      ? `&search=${paginationUc?.search}`
      : ''
    const returnQuery = `page=${paginationUc?.currentPage || 1}&limit=${
      paginationUc?.length || 10
    }${filters}${searchInput}${orderBy}`
    return returnQuery
  }, [paginationUc, searchParams])

  async function getUsersPaged(params: string): Promise<IData> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.USERS_GET_ALL,
      {},
      {},
      params
    )
    return data
  }

  const fallback = <IData>{}

  const { data: usersList = fallback, refetch } = useQuery(
    queryKeys.users,
    () => getUsersPaged(params), // Użyj params bezpośrednio
    {
      refetchOnWindowFocus: false, // opcjonalnie
    }
  )

  // Odświeżaj dane na podstawie zmian w paginationUc
  useEffect(() => {
    refetch()
  }, [paginationUc])

  return {
    usersList,
    changePagination,
    paginationUc,
  }
}
