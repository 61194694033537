import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IAgreements } from 'hooks/types'

async function getAgreements(): Promise<IAgreements[] | []> {
  const { data }: AxiosResponse = await apiRequest(APIRoutes.AGREEMENTS_GET)
  return data
}

export const useGetAgreements = () => {
  const fallback: IAgreements[] = []

  const { data: agreementsList = fallback } = useQuery(
    queryKeys.agreements,
    getAgreements
  )
  return agreementsList
}
