import { useState } from 'react'
import { Button, Box } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import {
  CustomizedDataPicker,
  EmptyArrayCard,
  TableComponent,
} from 'components/common'
import { IPaginateReducer, defaultPaginationValue } from 'hooks/table'
import { Formik, Form, Field } from 'formik'

import { IDisplayAllocations } from '../../selectedAllocations'
import { columns } from '../Columns'
import { useAllocationsContext } from '../../context/AllocationContext'

const CustomAllocation = ({
  allocations,
}: {
  allocations: IDisplayAllocations
}): JSX.Element => {
  const { t } = useTranslation(['userEditPage', 'buttons'])
  const { customDate, setCustomDate, initialValues } = useAllocationsContext()
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>(
    defaultPaginationValue
  )
  const { currentAllocationsToShow } = allocations

  const { currentPage, length } = paginationUc
  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }

  const rows: GridRowsProp =
    currentAllocationsToShow.length > 10
      ? currentAllocationsToShow
          ?.slice(currentPage * length, currentPage * length + length)
          .map((row) => row)
      : currentAllocationsToShow
  return (
    <>
      <Box display="flex" alignItems="center" mb={3}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            setCustomDate(values.startValue)
          }}
        >
          {({ submitForm }) => (
            <Form
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
              }}
            >
              <Field
                component={CustomizedDataPicker}
                label={t('allocations.form.label1')}
                name="startValue"
                views={['year', 'month']}
                inputFormat="yyyy-MM"
              />

              <Button onClick={submitForm}>
                {t('update', { ns: 'buttons' })}
              </Button>

              <Button onClick={() => setCustomDate(null)}>
                {t('reset', { ns: 'buttons' })}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
      {rows?.length ? (
        <Box>
          <TableComponent
            rows={rows}
            columns={columns()}
            pagination={currentAllocationsToShow?.length > 10 ? true : false}
            total={currentAllocationsToShow.length}
            changePagination={changePagination}
          />
        </Box>
      ) : (
        <EmptyArrayCard
          message={
            customDate
              ? `${t('allocations.noCustomAllocation')}  ${format(
                  new Date(customDate as Date),
                  'yyyy/MM'
                )}`
              : t('allocations.noUserAllocations')
          }
        />
      )}
    </>
  )
}

export default CustomAllocation
