import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { useState } from 'react'
import { IUserReportProductivity } from 'hooks/types'

interface IUserData {
  dates: string[]
  projects: number[]
  customers: number[]
  users: number[]
}
export const useGetAllocationsFilter = () => {
  const [userData, setUserData] = useState<IUserReportProductivity[]>([])
  const queryClient = useQueryClient()

  const getUsersTimesheets = async (userData: IUserData) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.ALLOCATION_READ,
      userData
    )
    return data
  }

  const { mutate: getTimeSheet } = useMutation(getUsersTimesheets, {
    onSuccess: (data) => {
      data.splice(-1, 1)
      setUserData(data)
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.timesheet])
    },
  })

  return { getTimeSheet, userData }
}
