import { useTranslation } from 'react-i18next'
import { IFields } from 'views/LayoutUI/components/types'

export const useDataFields = () => {
  const { t } = useTranslation('userCV')

  const bioFields: IFields[] = [
    {
      id: '1',
      label: `${t('cv.bioTable.title')}`,
      name: 'title',
      type: 'text',
    },
    {
      id: '2',
      label: `${t('cv.bioTable.description')}`,
      name: 'description',
      type: 'text',
    },
  ]

  const educationFields: IFields[] = [
    {
      id: '5',
      label: `${t('cv.eduTable.years')}`,
      name: 'years',
      type: 'text',
    },
    {
      id: '3',
      label: `${t('cv.eduTable.grade')}`,
      name: 'grade',
      type: 'text',
    },
    {
      id: '4',
      label: `${t('cv.eduTable.place')}`,
      name: 'place',
      type: 'text',
    },
  ]
  const projectsFields: IFields[] = [
    {
      id: '6',
      label: `${t('cv.projectsTable.name')}`,
      name: 'title',
      type: 'text',
    },
    {
      id: '7',
      label: `${t('cv.projectsTable.description')}`,
      name: 'description',
      type: 'text',
    },
    {
      id: '8',
      label: `${t('cv.projectsTable.stack')}`,
      name: 'stack',
      type: 'text',
    },
  ]
  const profileFields: IFields[] = [
    {
      id: '9',
      label: `${t('generateProfile.profile')}`,
      name: 'type',
      type: 'text',
    },
  ]
  return {
    bioFields,
    educationFields,
    projectsFields,
    profileFields,
  }
}
