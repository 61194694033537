import { Button, styled } from '@mui/material'
import { lightenDarkenColor } from 'utils/lightenDarkenColors'

type props = {
  valueistrue?: string
}

export const StyledButton = styled(Button)<props>(({ valueistrue, theme }) => ({
  maxWidth: '2rem',
  maxHeight: '2rem',
  minWidth: '2rem',
  minHeight: '2rem',
  backgroundColor:
    valueistrue === 'null' ? theme.palette.text.secondary : 'transparent',
  '&:hover': {
    ...(valueistrue === 'null'
      ? {
          backgroundColor: lightenDarkenColor(theme.palette.text.secondary, 20),
        }
      : valueistrue === 'true'
      ? {
          backgroundColor: 'rgba(0, 255, 0, 0.3)',
        }
      : {
          backgroundColor: 'rgba(255, 0, 0, 0.3)',
        }),
  },
}))
