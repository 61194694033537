import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const TypographyLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'rgba(0, 0, 0, 0.87)',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
}))
