import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useEffect, useState } from 'react'
import { queryKeys } from 'react-query/constants'
import { useTranslation } from 'react-i18next'
import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { IPaginateReducer } from 'hooks/table'
import { useAuth } from 'hooks/useAuth'
import { IHolidaysCategory, IPagination } from 'hooks/types'
import fileDownload from 'js-file-download'

import { IValidateVacationData } from '../types'

export enum StatusEnum {
  pending = 'PENDING',
  approved = 'APPROVED',
  rejected = 'REJECTED',
  cancelled = 'CANCELLED',
}

export interface IVacationRequest {
  approverComment?: string
  canManage: boolean
  comment: string
  days: number
  units?: number
  endDate: string
  firstName: string
  holidayCategoryId: number
  id: number
  lastName: string
  requestDate: string
  startDate: string
  status: string
  statusDate: string
  vacationType: string
  vacationTypeId: number
  workTypeId: number
  workTypeName: string
  projectId: number
  projectName: string
  HolidaysCategory: IHolidaysCategory
}
type IVacationRequestsPaged = IPagination & { data: IVacationRequest[] }
export const useVacationRequests = (
  userId?: number,
  tableTitle?: string,
  status?: string
) => {
  const fallback = {} as IVacationRequestsPaged
  const { t } = useTranslation('handleMessage')

  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { user } = useAuth()
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>({
    length: 10,
    currentPage: 0,
    search: null,
    order: { order: 'DESC', column: 'requestDate' },
    filters: null,
  })
  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }
  const [isFetching, setIsFetching] = useState(false)
  async function getVacationRequests(): Promise<IVacationRequestsPaged> {
    const onlyPrevious =
      tableTitle === 'previous' ? true : tableTitle === undefined && false
    const onlyFuture =
      tableTitle === 'future' ? true : tableTitle === undefined && false
    const onlyCanManageUsers = tableTitle ? true : false
    const type = status === 'ALL' ? undefined : status
    const page = (paginationUc?.currentPage + 1 || 1).toString()
    const limit = (paginationUc?.length || 10).toString()
    const filters = JSON.stringify({
      userId,
      onlyPrevious,
      onlyFuture,
      onlyCanManageUsers,
      type,
    })
    const order = JSON.stringify({
      order: paginationUc?.order ? paginationUc.order.order : 'DESC',
      column: paginationUc?.order ? paginationUc.order.column : 'requestDate',
    })
    const queryParamsObject = { page, limit, filters, order }
    const queryParams = `${new URLSearchParams(queryParamsObject)}`
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.VACATION_REQUESTS_GET,
      {},
      {},
      queryParams
    )
    return data
  }

  const validateUserVacationRequest = async ({ values }) => {
    const { data }: AxiosResponse<IValidateVacationData> = await apiRequest(
      APIRoutes.VACATION_REQUEST_VALIDATE,
      {
        userHolidayId: values?.vacationType?.id,
        startDate: values.valueStartDate,
        endDate: values.valueEndDate,
        startTime: values.valueStartTime,
        endTime: values.valueEndTime,
        comment: values.comment,
        workTypeId: values.workType.id,
        projectId: values?.project?.id,
      }
    )
    return data
  }

  const addUserVacationRequest = async ({ values }) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.VACATION_REQUEST_ADD,
      {
        userHolidayId: values?.vacationType?.id,
        startDate: values.valueStartDate,
        endDate: values.valueEndDate,
        startTime: values.valueStartTime,
        endTime: values.valueEndTime,
        comment: values.comment,
        workTypeId: values.workType.id,
        projectId: values?.project?.id,
      }
    )
    return data
  }
  const deleteUserVacationRequest = async (vacationRequestId?: number) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.VACATION_REQUEST_DELETE,
      {},
      { vacationRequestId }
    )
    return data
  }
  const downloadVacationRequest = (vacation) => {
    setIsFetching(true)
    generateVacationRequest({
      vacationRequestId: vacation.id,
    })
      .then((response) => {
        fileDownload(
          new Blob([response]),
          `Vacation application - ${user?.firstName} ${user?.lastName}.pdf`
        )
      })
      .finally(() => setIsFetching(false))
  }
  const generateVacationRequest = async ({ vacationRequestId }) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.VACATION_REQUEST_GET_PDF,
      {},
      { vacationRequestId }
    )
    return data
  }

  const updateUserVacationRequest = async ({ values, vacationRequestId }) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.VACATION_REQUEST_UPDATE,
      {
        userHolidayId: values.vacationType.id,
        startDate: values.valueStartDate,
        endDate: values.valueEndDate,
        comment: values.comment,
        workTypeId: values.workType.id,
        projectId: values?.project?.id,
      },
      { vacationRequestId }
    )
    return data
  }
  const updateStatusUserVacationRequest = async ({
    values,
    vacationRequestId,
    type,
  }) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.VACATION_REQUEST_UPDATE_STATUS,
      {
        comment: values.approverComment,
      },
      { vacationRequestId, type }
    )
    return data
  }

  const { data: validateVacationRequestData, mutate: validateVacationRequest } =
    useMutation(validateUserVacationRequest, {
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.vacationRequestsList)
        queryClient.invalidateQueries(queryKeys.user)
      },
    })

  const { mutate: addVacationRequest } = useMutation(addUserVacationRequest, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('vacationRequest')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.vacationRequestsList)
      queryClient.invalidateQueries(queryKeys.user)
    },
  })
  const { mutate: deleteVacationRequest } = useMutation(
    deleteUserVacationRequest,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('vacationRequest')} ${t('delete')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.vacationRequestsList)
        queryClient.invalidateQueries(queryKeys.user)
      },
    }
  )
  const { mutate: updateVacationRequest } = useMutation(
    updateUserVacationRequest,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('vacationRequest')} ${t('update')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.vacationRequestsList)
        queryClient.invalidateQueries(queryKeys.user)
      },
    }
  )
  const { mutate: updateStatusVacationRequest } = useMutation(
    updateStatusUserVacationRequest,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('statusOf')} ${t('vacationRequest')} ${t('update')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.vacationRequestsList)
        queryClient.invalidateQueries(queryKeys.user)
        queryClient.invalidateQueries(queryKeys.calendar)
      },
    }
  )
  const { data: vacationRequestsList = fallback, refetch } = useQuery(
    [queryKeys.vacationRequestsList, tableTitle, userId],
    getVacationRequests
  )
  useEffect(() => {
    refetch()
  }, [paginationUc, status, userId])

  return {
    changePagination,
    paginationUc,
    addVacationRequest,
    deleteVacationRequest,
    downloadVacationRequest,
    updateStatusVacationRequest,
    updateVacationRequest,
    vacationRequestsList,
    isFetching,
    validateVacationRequest,
    validateVacationRequestData,
  }
}
