import { Box, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import palette from 'theme/palette'

interface IProps {
  children: string | ReactNode
}
const TableTitle: React.FC<IProps> = ({ children }) => {
  return (
    <Box
      sx={{
        boxShadow: `0px 10px 10px -12px ${palette.primary.light}`,
        marginBottom: '1rem',
        padding: '0.25rem',
      }}
    >
      <Typography
        variant="h2"
        id="tableTitle"
        component="h2"
        sx={{
          paddingLeft: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default TableTitle
