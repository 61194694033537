import * as yup from 'yup'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography, Autocomplete, TextField } from '@mui/material'
import { useGetGroups } from 'hooks/groups'
import { IGroups } from 'pages/Groups/types'

import { useAssignUserToGroup } from './utils'

const AssignUserToGroup: React.FC = (): JSX.Element => {
  const { groupsList } = useGetGroups()
  const assignUserToGroup = useAssignUserToGroup()
  const [choseGroup, setChosenGroup] = useState<IGroups | null>()
  const { t } = useTranslation(['validation', 'buttons', 'userEditPage'])

  const validationSchema = yup.object({
    groupName: yup.string().required(t('groupName.required')),
  })
  return (
    <Box>
      <Formik
        initialValues={{
          groupName: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          assignUserToGroup(choseGroup?.id)
          setSubmitting(false)
        }}
        validationSchema={validationSchema}
      >
        {({ errors, setFieldValue, touched }) => (
          <Form>
            <Box mb={2}>
              <Autocomplete
                fullWidth
                size="small"
                id="holidayType"
                options={groupsList}
                isOptionEqualToValue={(option, { name }) =>
                  option.name === name
                }
                getOptionLabel={(option) => {
                  return option.name
                }}
                onChange={(e, value) => {
                  {
                    setFieldValue('groupName', value?.name)
                    setChosenGroup(value)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.groupName ? true : false}
                    margin="normal"
                    label={t('modal.assignUser.form.label1', {
                      ns: 'userEditPage',
                    })}
                    name="groupName"
                    variant="outlined"
                  />
                )}
              />
              {errors.groupName && touched.groupName && (
                <Typography color="error" variant="h6">
                  {errors.groupName}
                </Typography>
              )}
            </Box>
            <Button type="submit">{t('assign', { ns: 'buttons' })}</Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
export default AssignUserToGroup
