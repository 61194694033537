import { styled } from '@mui/material'
import { Form } from 'formik'

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    width: 95%;
  }
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin: 0.3rem 0;
    width: 90%;
  }
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 0.438rem;
  }
  .css-2w5wh4-MuiFormLabel-root-MuiInputLabel-root {
    color: ${(props) => props.theme.palette.primary.main};
  }
  .css-i4bv87-MuiSvgIcon-root {
    fill: ${(props) => props.theme.palette.primary.main};
  }
  .css-1nomg3d-MuiButtonBase-root-MuiButton-root {
    margin-top: 0.3rem;
  }
`
