import React from 'react'
import { Helmet } from 'react-helmet-async'

interface IProps {
  title: string
}

const Head: React.FC<IProps> = ({ title }) => {
  return (
    <Helmet>
      <title>
        {title} - {process.env.REACT_APP_NAME}
      </title>
    </Helmet>
  )
}

export default Head
