import { Breadcrumbs, styled, Typography } from '@mui/material'

export const HeaderNav = styled(Breadcrumbs)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  textIndent: '0.5rem',
  paddingInline: 16,
  borderRadius: 5,
  marginLeft: 16,
  height: '100%',
}))

export const StyledLink = styled(Typography)({
  textIndent: '0.5rem',
  paddingLeft: '8px',
  cursor: 'pointer',
  '&:hover': { textDecoration: 'underline' },
})
