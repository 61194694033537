import { EventContentArg } from '@fullcalendar/react'
import { Box, Typography, Tooltip } from '@mui/material'
import { format } from 'date-fns'
import CommentIcon from '@mui/icons-material/Comment'
import AddAlarmIcon from '@mui/icons-material/AddAlarm'
import { useTranslation } from 'react-i18next'

import IconsInCalendar from './IconsInCalendar'
import {
  EndTime,
  EventBox,
  EventTimeBox,
  ProjectTitle,
  StartTime,
  IconWrapper,
  Icon,
} from './index.style'

const RenderEventContent = ({
  eventInfo,
  color,
  isProject,
}: {
  eventInfo: EventContentArg
  color?: string
  isProject?: boolean
}) => {
  const { t } = useTranslation('calendar')
  const { comment, contract, isOverhours, isHoliday, progress } =
    eventInfo.event.extendedProps
  const customColor = color || 'inherit'
  const styleIcon = { fontSize: '16px', color: customColor }
  const start = eventInfo.event.start
    ? format(eventInfo.event.start, 'HH:mm')
    : null
  const end = eventInfo.event.end ? format(eventInfo.event.end, 'HH:mm') : null

  const getEmoji = () => {
    switch (progress) {
      case 'PERFECT':
        return '😄'
      case 'GOOD':
        return '😊'
      case 'BORING':
        return '😴'
      case 'BAD':
        return '😖'
      default:
        return ''
    }
  }
  return (
    <EventBox>
      {isProject && (
        <ProjectTitle component="span" sx={{ color: customColor }}>
          <Tooltip title={eventInfo.event._def.extendedProps.user}>
            <span>{eventInfo.event._def.extendedProps.user}</span>
          </Tooltip>
        </ProjectTitle>
      )}
      {eventInfo.event._def.extendedProps?.isRequestedVacation && (
        <EventTimeBox sx={{ margin: '5px 0' }}>
          <StartTime sx={{ color: customColor }}>
            {t('vacationEvent.pending')}
          </StartTime>
        </EventTimeBox>
      )}
      {eventInfo.event._def.extendedProps?.isRequestedVacation && (
        <EventTimeBox>
          <StartTime
            sx={{ color: customColor, fontWeight: '800', marginBottom: '5px' }}
          >
            {t('vacationEvent.title')}
          </StartTime>
        </EventTimeBox>
      )}
      {eventInfo.event._def.extendedProps?.isRequestedVacation && (
        <EventTimeBox>
          <StartTime sx={{ color: customColor }}>
            #{eventInfo.event._def.extendedProps?.vacationTypeId}
          </StartTime>
        </EventTimeBox>
      )}
      {start !== '00:00' &&
      end &&
      !eventInfo.event._def.extendedProps?.isRequestedVacation ? (
        <Tooltip
          title={`${start} - ${end} ${eventInfo.event.title}`}
          disableInteractive={true}
        >
          <EventTimeBox>
            <StartTime sx={{ color: customColor }}>{start}</StartTime>
            <Typography
              sx={{
                fontSize: 12,
                color: customColor,
              }}
            >
              -
            </Typography>
            <EndTime sx={{ color: customColor }}>{end}</EndTime>
          </EventTimeBox>
        </Tooltip>
      ) : null}
      {eventInfo?.event?._def?.extendedProps?.project ? (
        <ProjectTitle component="span" sx={{ color: customColor }}>
          <Tooltip title={eventInfo.event._def.extendedProps.project}>
            <span>{eventInfo.event._def.extendedProps.project}</span>
          </Tooltip>
        </ProjectTitle>
      ) : null}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        {contract && !eventInfo.event._def.extendedProps?.isRequestedVacation && (
          <Tooltip disableInteractive={true} title={contract} arrow>
            <>
              <Icon>{getEmoji()}</Icon>
              <IconWrapper>
                <IconsInCalendar
                  contractType={contract}
                  isHoliday={isHoliday}
                  color={customColor}
                />
              </IconWrapper>
            </>
          </Tooltip>
        )}
        {comment && (
          <Tooltip disableInteractive={true} title={comment} arrow>
            <IconWrapper>
              <CommentIcon sx={styleIcon} />
            </IconWrapper>
          </Tooltip>
        )}
        {isOverhours && (
          <Tooltip title={isOverhours} arrow>
            <IconWrapper>
              <AddAlarmIcon sx={styleIcon} />
            </IconWrapper>
          </Tooltip>
        )}
      </Box>
    </EventBox>
  )
}

export default RenderEventContent
