import { styled, Grid, IconButton } from '@mui/material'
import { Box } from '@mui/system'

export const LoggedOtherUserInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.error.main,
  opacity: 0.7,
  borderRadius: '5px',
  padding: '3px 15px',
}))
export const GridContainer = styled(Grid)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  right: 0,
  alignItems: 'center',
  boxShadow: `0px 0px 8px 0px ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.primary.contrastText,
  justifyContent: 'space-between',
  zIndex: 1000,
  height: '3.5rem',
}))

export const StyledIconButton = styled(IconButton)({
  margin: '0 0',
})
