import { GroupForm } from 'pages/Groups/components'

import { IGroups } from '../../types'

import { useGroupEdit } from './utils'

export interface EditGroupProps {
  group: IGroups | null
}
const EditGroup: React.FC<EditGroupProps> = ({ group }): JSX.Element => {
  const initialValues = {
    groupName: group?.name || '',
    groupCode: group?.code || '',
  }

  const editGroup = useGroupEdit()
  return (
    <GroupForm
      initialValues={initialValues}
      onSubmitForm={editGroup}
      groupId={group?.id}
    />
  )
}

export default EditGroup
