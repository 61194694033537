import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Box, Typography } from '@mui/material'
import { useNotifications, Wrapper } from 'components/common'
import { PageRoutes } from 'routes'

const InActiveMessage = () => {
  const { notifications } = useNotifications()
  const { t } = useTranslation(['remindPassword', 'validation'])
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Typography variant="h3" fontWeight={550}>
        {t('header.title')}
      </Typography>
      <Box my={3}>
        <Typography color="error" align="center">
          {notifications.message}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
          marginTop: 2,
        }}
      >
        <Button fullWidth onClick={() => navigate(PageRoutes.LOGIN)}>
          {t('form.button2')}
        </Button>
        <Typography>{t('text')}</Typography>
        <Button
          fullWidth
          variant="text"
          onClick={() => navigate(PageRoutes.FORGOT_PASSWORD)}
        >
          {t('form.button3')}
        </Button>
      </Box>
    </Wrapper>
  )
}

export default InActiveMessage
