import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { CreatePkupProps, IApplication } from 'hooks/pkup/types'
import fileDownload from 'js-file-download'

export const useApplications = (date?: string) => {
  const { t } = useTranslation('handleMessage')
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()

  const fallback: IApplication[] = []

  async function getMyApplications(): Promise<IApplication[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_GET_ALL_FOR_ME,
      {},
      { date: date }
    )
    return data
  }

  async function createPkup(values: CreatePkupProps) {
    const { data }: AxiosResponse = await apiRequest(APIRoutes.PKUP_CREATE, {
      title: values.title,
      description: values.description,
      works: values.works,
      supervisorId: values?.supervisorId?.id,
      period: values.period,
    })
    return data
  }
  async function sendThisPkupForApproval(applicationId: number) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_CHANGE_APPROVAL,
      {},
      { id: applicationId }
    )
    return data
  }

  async function getDownloadApplicationZip(applicationId: number) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_GET_ZIP_FOR_ME,
      {},
      { pkupId: applicationId }
    )
    return data
  }
  const handleDownloadZip = async (application: IApplication) => {
    await getDownloadApplicationZip(application.id).then((response) => {
      fileDownload(new Blob([response]), 'report' + '.zip')
    })
  }
  const { mutate: addPkup } = useMutation(createPkup, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('pkup')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.applicationsList])
    },
  })

  const { mutate: sendForApproval } = useMutation(sendThisPkupForApproval, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('pkupStatus')} ${t('sentApproval')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.applicationsList])
    },
  })
  const { data: myApplications = fallback, refetch } = useQuery(
    queryKeys.applicationsList,
    getMyApplications
  )

  useEffect(() => {
    refetch()
  }, [date])

  return {
    getDownloadApplicationZip,
    myApplications,
    addPkup,
    sendForApproval,
    handleDownloadZip,
  }
}
