import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useState } from 'react'
import { format, parseISO } from 'date-fns'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

import { IUserWorkdaysSummary, IData, IInitialValue } from '../types'

export const useGetAllWorkdays = (workTypes) => {
  const { setNotification } = useNotifications()
  const { t } = useTranslation('handleMessage')
  const [workdaysData, setWorkdaysData] = useState<IUserWorkdaysSummary[]>([])
  const [reportWorkdaysValues, setReportWorkdaysValue] =
    useState<IInitialValue | null>(null)

  const formatDates = ({ year }: IInitialValue) => {
    const formattedDates: string[] = []
    for (let i = 1; i <= 12; i++) {
      const days = `${format(year, 'yyyy')}/${i < 10 ? 0 : ''}${i}`
      formattedDates.push(days)
    }
    return formattedDates
  }

  const getWorkDays = async (values: IInitialValue) => {
    const dates = formatDates(values)

    const userData = {
      dates: dates,
      workTypeIds: workTypes?.map(({ id }) => id),
      usersIds: values.users.map((user) => user.id),
    }
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_READ_FILTERED,
      userData
    )

    return data
  }

  const groupData = (data: IData[], dates: string[]) => {
    let userWorkdaysSummary = {} as IUserWorkdaysSummary
    let newWorkdayToCopy: IUserWorkdaysSummary[] = []
    const newDates = dates.map((date, i) => {
      return `${date.slice(0, 4)}-${i + 1 > 9 ? '' : 0}${i + 1}`
    })
    const filterByDate = (filteredDataByWorkType: IData[]) => {
      for (let i = 0; i < newDates.length; i++) {
        const filteredDataByDates = filteredDataByWorkType.filter(
          (workDay) =>
            format(new Date(workDay.logDayFrom), 'yyyy/MM') === dates[i]
        )
        if (filteredDataByDates.length > 0) {
          filteredDataByDates[0].sumDays = filteredDataByDates.filter(
            (value, index, self) => self.indexOf(value) === index
          ).length

          filteredDataByDates[0].month =
            newDates?.[i] && format(parseISO(newDates[i]), 'MM')
          userWorkdaysSummary = {
            ...userWorkdaysSummary,
            workdays: [...userWorkdaysSummary.workdays, filteredDataByDates[0]],
          }
        }
      }
      newWorkdayToCopy = [...newWorkdayToCopy, userWorkdaysSummary]
      if (newWorkdayToCopy) setWorkdaysData(newWorkdayToCopy)
    }
    if (data.length) {
      const userIds = data
        .map(({ userId }) => userId)
        .filter((value, index, self) => self.indexOf(value) === index)
      for (let i = 0; i < userIds.length; i++) {
        const filteredDataByUserId = data.filter(
          (workday) => workday.userId === userIds[i]
        )
        for (let j = 0; j < workTypes.length; j++) {
          const filteredDataByWorkType = filteredDataByUserId.filter(
            ({ workTypeId }) => workTypeId === workTypes[j].id
          )

          if (filteredDataByWorkType.length > 0) {
            userWorkdaysSummary = {
              workType: workTypes[j],
              workdays: [],
              user: filteredDataByWorkType[0].user,
            }
            filterByDate(filteredDataByWorkType)
          }
        }
      }
    } else {
      setNotification({
        message: t('reportWorkdays.noData'),
        variant: NOTIFICATION.error,
      })
    }
  }

  const { mutate: getWorkdays } = useMutation(getWorkDays, {
    onSuccess: (data, variables) => {
      const allDates = formatDates(variables)
      groupData(data, allDates)
    },
  })

  return {
    getWorkdays,
    workdaysData,
    reportWorkdaysValues,
    setReportWorkdaysValue,
  }
}
