import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import * as yup from 'yup'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usePersonalFields } from 'components/users'

import { usePersonalInformation } from '../utils'

const UpdatePassword: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['profile', 'validation', 'buttons'])
  const patchUser = usePersonalInformation()
  const { passwordFields } = usePersonalFields()
  const validationSchema = yup.object({
    currentPassword: yup
      .string()
      .required(t('currentPassword.required', { ns: 'validation' })),

    password: yup
      .string()
      .required(t('newPassword.required', { ns: 'validation' })),
    repeatNewPassword: yup
      .string()
      .required(t('repeatNewPassword.required', { ns: 'validation' }))
      .oneOf(
        [yup.ref('password'), null],
        t('repeatNewPassword.oneOf', { ns: 'validation' })
      ),
  })
  const initialValues = {
    currentPassword: '',
    password: '',
    repeatNewPassword: '',
  }

  return (
    <>
      <Typography variant="h3">
        {t('userInformation.updatePassword')}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({ currentPassword, password }, { setSubmitting }) => {
          patchUser({ currentPassword: currentPassword, password: password })
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box mb={2}>
              {passwordFields.map((field) => (
                <Field
                  fullWidth
                  key={`${field.id}-updatePassword`}
                  component={TextField}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  variant="outlined"
                  margin="normal"
                  size="small"
                  autoComplete="on"
                />
              ))}
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {t('save', { ns: 'buttons' })}
            </Button>
            <br />
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UpdatePassword
