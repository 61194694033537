import CloseIcon from '@mui/icons-material/Close'
import { ModalTitle } from 'components/common'

const ModalHeader = ({ handleClose, title }): JSX.Element => {
  return (
    <ModalTitle>
      {title}
      <CloseIcon
        sx={{
          fontSize: '1.5rem',
          cursor: 'pointer',
          color: 'primary.contrastText',
        }}
        onClick={() => handleClose()}
      />
    </ModalTitle>
  )
}

export default ModalHeader
