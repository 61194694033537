import { styled, Box } from '@mui/material'

export const ModalBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.secondary.light,
  width: '55%',
  maxHeight: '90vh',
  boxShadow: `0px 0px 8px 0px ${theme.palette.primary.main}`,
  '&.large': {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}))
