import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Tooltip } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import {
  EmptyArrayCard,
  TableComponent,
  useNotifications,
} from 'components/common'
import { IUserProps } from 'components/users'
import { IEditInventory } from 'hooks/inventory'

export interface INewInventories {
  lp: number
  type: string
  producer: string
  model: string
  serialNumber: string
  additionalInformation: string
  inventoryActions: JSX.Element
}
interface InventoryProps {
  setInventory: Dispatch<SetStateAction<IEditInventory>>
  permission?: boolean
}

const UserInventory: React.FC<IUserProps & InventoryProps> = ({
  user,
  setInventory,
  permission,
}): JSX.Element => {
  const { t } = useTranslation(['profile', 'buttons'])
  const { handleOpenModal } = useNotifications()

  const columns = [
    'LP',
    t('inventory.column2'),
    t('inventory.column3'),
    t('inventory.column4'),
    t('inventory.column5'),
    t('inventory.column6'),
    '',
  ]
  const userInventories = user?.inventories?.map((inventory, index) => {
    const inventoryItem = {
      producer: inventory.producer,
      model: inventory.model,
      serialNumber: inventory.serialNumber,
      additionalInformation: inventory.additionalInformation,
      id: inventory.id,
      name: inventory.Inventory.name,
      inventoryId: inventory.inventoryId,
    }
    return {
      lp: index + 1,
      type: inventory.Inventory.name,
      producer: inventory.producer,
      model: inventory.model,
      serialNumber: inventory.serialNumber,
      additionalInformation: inventory.additionalInformation,
      inventoryActions: (inventory.Inventory.allowUserToModify ||
        permission) && (
        <>
          <Tooltip title={t('edit', { ns: 'buttons' }) as string}>
            <IconButton
              onClick={() => {
                setInventory(inventoryItem)
                handleOpenModal('editInventory')
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('delete', { ns: 'buttons' }) as string}>
            <IconButton
              onClick={() => {
                handleOpenModal('delete')
                setInventory(inventoryItem)
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        </>
      ),
    }
  }) as INewInventories[]
  const rows: GridRowsProp = userInventories
  return (
    <Box>
      {rows?.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard message={t('inventory.noInventory')} />
      )}

      <Button
        onClick={() => handleOpenModal('addInventory')}
        sx={{ marginTop: '1rem' }}
      >
        {t('inventory.addNewInventory')}
      </Button>
    </Box>
  )
}

export default UserInventory
