import { NOTIFICATION, useNotifications } from 'components/common'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

interface ICustomerData {
  id: string | number | undefined
  description?: string
}

interface ProjectDetailsData {
  projectId: string | undefined
  skills: ICustomerData[]
}

export const useUpdateProjectDetailsTags = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')

  const updateSingleProjectDetailsTag = async (
    projectDetailsData: ProjectDetailsData
  ) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.TAGS_ADD_TO_PROJECT,
      projectDetailsData.skills,
      {
        projectId: projectDetailsData.projectId,
      }
    )
    return data
  }

  const { mutate: updateProjectDetailsTags } = useMutation(
    updateSingleProjectDetailsTag,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('stack')} ${t('add')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.customer])
        queryClient.invalidateQueries([queryKeys.projectHistory])
      },
    }
  )

  return { updateProjectDetailsTags }
}
