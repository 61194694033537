import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IGroups } from 'pages/Groups/types'

export interface IButtons {
  title: string
  icon: JSX.Element
  modal: string
}
export interface initialValuesTypes {
  groupName: string | undefined | null
  groupCode: string | undefined | null
}
async function getGroups(): Promise<IGroups[]> {
  const { data }: AxiosResponse = await apiRequest(APIRoutes.GROUPS_GETALL)
  return data
}

export const useGetGroups = () => {
  const fallback: IGroups[] = []

  const { data: groupsList = fallback } = useQuery(queryKeys.groups, getGroups)
  return { groupsList }
}
