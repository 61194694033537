import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import EditIcon from '@mui/icons-material/Edit'
import { permissionList, isUserPermitted } from 'services'
import {
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
} from 'components/common'
import { EditInventoryForm } from 'components/inventory'
import { DefaultModal } from 'views/LayoutUI/modals'
import { useDeleteInventory, IEditInventory } from 'hooks/inventory'
import { useAllInventories } from 'hooks/inventory/useAllInventories'
import { useAuth } from 'hooks/useAuth'
import { ISelectingModal } from 'hooks/types'

import RewriteUser from '../RewriteUser'

interface IUserInventories {
  name: string
  producer: string
  model: string
  serialNumber: string
  additionalInformation: string
  button: JSX.Element
}
const defaultInventoryValue = {
  additionalInformation: '',
  id: null,
  inventoryId: null,
  model: '',
  producer: '',
  serialNumber: '',
  userInventoryId: null,
  userName: '',
}
const InventoryUsers = ({
  inventoryId,
}: {
  inventoryId: number | undefined
}): JSX.Element => {
  const [inventory, setInventory] = useState<IEditInventory>(
    defaultInventoryValue
  )
  const { t } = useTranslation(['inventory', 'buttons', 'validation'])
  const deleteInventory = useDeleteInventory()
  const inventories = useAllInventories()
  const { handleOpenModal, modalName } = useNotifications()
  const { user } = useAuth()

  useEffect(() => {
    if (!modalName) {
      setInventory(defaultInventoryValue)
    }
  }, [modalName])
  const columns = [
    `${t('modal.userInventory.table.userName')}`,
    `${t('modal.userInventory.table.producer')}`,
    `${t('modal.userInventory.table.model')}`,
    `${t('modal.userInventory.table.serialNumber')}`,
    `${t('modal.userInventory.table.additionalInformation')}`,
    '',
  ]
  const inventoriesWithUsers = inventories?.data
    ?.find((inventory) => inventory.id === inventoryId)
    ?.UserInventories.map((userInventory) => userInventory)
    .filter((userInventory) => userInventory.User !== null)

  const userInventories = inventoriesWithUsers?.map((userInventory) => {
    const inventory = {
      userName: `${userInventory?.User?.firstName} ${userInventory?.User?.lastName}`,
      producer: userInventory.producer,
      model: userInventory.model,
      serialNumber: userInventory.serialNumber,
      additionalInformation: userInventory.additionalInformation,
      id: userInventory.id,
      inventoryId: userInventory.inventoryId,
      userInventoryId: userInventory.UserId,
    }
    return {
      name: `${userInventory?.User?.firstName} ${userInventory?.User?.lastName}`,
      producer: userInventory.producer,
      model: userInventory.model,
      serialNumber: userInventory.serialNumber,
      additionalInformation: userInventory.additionalInformation,

      button: (
        <Box display="flex">
          {isUserPermitted(user, [
            permissionList.INVENTORY_ASSIGN_ITEM_TO_USER,
          ]) && (
            <Tooltip title={t('modal.userInventory.table.button') as string}>
              <IconButton
                onClick={() => {
                  handleOpenModal('rewriteUser')
                  setInventory(inventory)
                }}
              >
                <PersonAddAltIcon />
              </IconButton>
            </Tooltip>
          )}
          {isUserPermitted(user, [
            permissionList.INVENTORY_EDIT_OTHER_USER,
          ]) && (
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setInventory(inventory)
                  handleOpenModal('editUserInventory')
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {isUserPermitted(user, [permissionList.INVENTORY_DELETE_ITEM]) && (
            <Tooltip title={t('delete', { ns: 'buttons' }) as string}>
              <IconButton
                onClick={() => {
                  setInventory(inventory)
                  handleOpenModal('delete')
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    }
  }) as IUserInventories[]
  const rows: GridRowsProp = userInventories

  const deleteContent = () => {
    return (
      <Typography>
        {t('modal.deleteUserInventory.title1')} [ {inventory?.model} ]{' '}
        {t('modal.deleteUserInventory.title2')} {inventory.userName}&apos;{' '}
        {t('modal.deleteUserInventory.title3')}?
      </Typography>
    )
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'rewriteUser':
        return {
          component: <RewriteUser inventory={inventory} rewrite />,
          title: t('modal.rewriteInventory.modalTitle'),
        }
      case 'editUserInventory':
        return {
          component: <EditInventoryForm inventory={inventory} />,
          title: t('modal.editInventory.modalTitle'),
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteInventory(inventory.id)}
            />
          ),
          title: t('modal.deleteUserInventory.modalTitle'),
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('modal.defaultModal.title')}`,
        }
    }
  }

  return (
    <>
      <Box>
        <TableComponent columns={columns} rows={rows} />
      </Box>
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </>
  )
}
export default InventoryUsers
