import * as yup from 'yup'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface IHolidayData {
  holidayName: string
  date: Date
}
const addNewHoliday = async (holidayData: IHolidayData) => {
  const date = format(holidayData.date, 'yyyy-MM-dd')
  const { data }: AxiosResponse = await apiRequest(APIRoutes.HOLIDAYS_CREATE, {
    name: holidayData.holidayName,
    date: date,
  })
  return data
}
export const useAddHolidays = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation(['handleMessage', 'validation'])
  const { mutate: createNewHoliday } = useMutation(addNewHoliday, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('holiday')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.holidays])
      void queryClient.invalidateQueries([queryKeys.calendar])
      void queryClient.invalidateQueries([queryKeys.user])
    },
  })

  const validationSchema = yup.object({
    holidayName: yup
      .string()
      .required(t('holiday.name.required', { ns: 'validation' }))
      .min(3, t('holiday.name.min')),
    date: yup.date().required(t('holiday.date.required', { ns: 'validation' })),
  })

  return { createNewHoliday, validationSchema }
}
