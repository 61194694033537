import { Navigate, Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import { useAuth } from 'hooks/useAuth'
import { Loading, InfoSnackbar, useNotifications } from 'components/common'

import { Footer, Sidebar } from './components'
import { StyledContainer } from './index.style'

const LayoutUI: React.FC = (): JSX.Element => {
  const { user, status } = useAuth()
  const { openSideBar } = useNotifications()
  if (status === 'error') {
    return <Navigate to="/login" />
  }
  if (!user) return <Loading />
  return (
    <StyledContainer>
      <Sidebar />
      <Box
        width={openSideBar ? 'calc(100% - 250px)' : '100%'}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        position="relative"
        justifyContent="space-between"
        minHeight="100vh"
      >
        <Box
          paddingLeft={openSideBar ? 0 : '40px'}
          paddingBottom="2rem"
          minHeight="calc(100% - 4rem)"
        >
          <Outlet />
        </Box>
        <Footer />
      </Box>
      <Loading />
      <InfoSnackbar />
    </StyledContainer>
  )
}

export default LayoutUI
