import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import {
  Box,
  Container,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
  Timeline,
} from '@mui/lab'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import {
  DetailsTooltip,
  EmptyArrayCard,
  ModalComponent,
  ModalConfirm,
  useNotifications,
} from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import { HeaderTab } from 'views/LayoutUI/components'
import palette from 'theme/palette'
import { IButtonArr, ISelectingModal } from 'hooks/types'
import { PageRoutes } from 'routes'

import { useUser } from '../utlis'

import { AddNote, EditNote } from './modals'
import { IInitialValues, INotes, useNotes } from './useNotes'

const Notes: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['userNotes', 'validation'])
  const { id } = useParams()

  const [selectedNote, setSelectedNote] = useState<INotes>({
    author: { firstName: '', lastName: '' },
    authorId: 0,
    createdAt: null,
    id: 0,
    note: '',
    title: '',
    updatedAt: null,
    userId: 0,
  })

  const { user } = useUser()
  const { notes } = useNotes(Number(id), selectedNote?.id)
  const { deleteNote } = useNotes(user.id, selectedNote?.id)
  const { handleOpenModal, modalName, userDetailsId, setUserDetailsId } =
    useNotifications()

  const initialValuesEdit: IInitialValues = {
    title: selectedNote?.title,
    note: selectedNote?.note,
  }
  const initialValuesAdd: IInitialValues = {
    title: '',
    note: '',
  }
  const validationSchema = yup.object({
    title: yup
      .string()
      .required(`${t('title.required', { ns: 'validation' })}`)
      .min(3, `${t('title.minLength', { ns: 'validation' })}`),
    note: yup
      .string()
      .required(`${t('note.required', { ns: 'validation' })}`)
      .min(8, `${t('note.minLength', { ns: 'validation' })}`),
  })
  const deleteContent = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle2">
          {t('notes.modal.delete.text')}
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {selectedNote.title}?
        </Typography>
      </Box>
    )
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'Add':
        return {
          component: (
            <AddNote
              initialValues={initialValuesAdd}
              validationSchema={validationSchema}
              userId={user.id}
            />
          ),
          title: `${t('notes.modal.addNewNote.title')} ${user.firstName} ${
            user.lastName
          }`,
        }
      case 'Edit':
        return {
          component: (
            <EditNote
              initialValues={initialValuesEdit}
              validationSchema={validationSchema}
              userId={user.id}
              note={selectedNote}
            />
          ),
          title: `${t('notes.modal.edit.title1')} ${user.firstName} ${
            user.lastName
          } ${t('notes.modal.edit.title2')}`,
        }
      case 'Delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteNote()}
            />
          ),
          title: `${t('notes.modal.delete.title')}`,
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('notes.modal.default')}`,
        }
    }
  }
  const buttonsArr: IButtonArr[] = [
    {
      title: `${t('edit', { ns: 'buttons' })}`,
      path: PageRoutes.CUSTOMERS_EDIT,
      icon: <EditIcon />,
    },
    {
      title: `${t('delete', { ns: 'buttons' })}`,
      path: null,
      icon: <DeleteIcon />,
    },
  ]
  return (
    <Container>
      <HeaderTab
        translate="userNotes"
        id={`${t('notes.title')} ${user.email}`}
        pageLink={PageRoutes.USERS}
      />
      {notes?.length ? (
        <Timeline sx={{ display: 'flex' }}>
          {notes?.map((note: INotes) => (
            <TimelineItem key={note.id} sx={{ maxWidth: '70rem' }}>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ maxWidth: '200px' }}
              >
                <Box>
                  <Paper
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '1.7rem',
                    }}
                  >
                    <Typography variant="subtitle1">
                      <>{note.createdAt}</>
                    </Typography>
                  </Paper>
                </Box>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot sx={{ bgcolor: 'primary.light' }} />
                <TimelineConnector sx={{ bgcolor: 'primary.light' }} />
              </TimelineSeparator>
              <TimelineContent>
                <Box>
                  <Paper
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '1.7rem',
                      paddingLeft: '1rem',
                      backgroundColor: palette.secondary.dark,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ color: palette.primary.main, fontWeight: 'bold' }}
                    >
                      {note.title}
                    </Typography>
                  </Paper>
                  {note !== undefined && (
                    <DetailsTooltip id={userDetailsId}>
                      <Box sx={{ width: 'fit-content', paddingLeft: '1rem' }}>
                        <Typography
                          variant="h3"
                          onMouseOver={() =>
                            setUserDetailsId(note.authorId as number)
                          }
                          sx={{
                            cursor: 'pointer',
                            fontSize: '0.9rem',
                          }}
                        >
                          {note.author?.firstName} {note.author?.lastName}
                        </Typography>
                      </Box>
                    </DetailsTooltip>
                  )}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      padding: '0 6rem 0 1rem',
                      textAlign: 'justify',
                    }}
                  >
                    {note.note}
                    {note.updatedAt !== note.createdAt && (
                      <Box sx={{ fontSize: '0.8rem', opacity: '70%' }}>
                        ({t('notes.lastEdited')}: <>{note.updatedAt}</>)
                      </Box>
                    )}
                  </Typography>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'right' }}
                    onClick={() => setSelectedNote(note)}
                  >
                    {buttonsArr.map((button) => (
                      <Tooltip
                        title={button.title as string}
                        key={button.title}
                        onClick={() => handleOpenModal(button.title as string)}
                      >
                        <IconButton>{button.icon}</IconButton>
                      </Tooltip>
                    ))}
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      ) : (
        <EmptyArrayCard
          message={t('notes.message')}
          styles={{ color: palette.error.main }}
        />
      )}
      <IconButton onClick={() => handleOpenModal('Add')}>
        <AddCircleRoundedIcon
          sx={{
            fontSize: '5rem',
            position: 'fixed',
            bottom: '3rem',
            right: '2rem',
          }}
        />
      </IconButton>
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}
export default Notes
