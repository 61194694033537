import { Box, styled } from '@mui/material'

export const StyledBadge = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: 5,
  minWidth: 40,
  paddingBlock: 3,
  padding: 3,
  fontSize: '12px',
  marginInline: 5,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  marginTop: 2,
}))
