import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Fade, IconButton, Paper, Tooltip } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useHrDashboard } from 'pages/HrDashboard/hooks/useHrDashboard'
import WorkIcon from '@mui/icons-material/Work'
import { ReviewWorkdays } from 'pages/HrDashboard/modals'
import {
  useNotifications,
  ModalComponent,
  TableComponent,
  TableTitle,
  EmptyArrayCard,
} from 'components/common'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { IWorkdaysToReview } from 'hooks/pkup/types'
import { timeoutAnimation } from 'utils'

const WorkdaysToReview: React.FC = (): JSX.Element => {
  const { t } = useTranslation('hrDashboard')
  const [userId, setUserId] = useState(0)
  const columns = [`${t('workdays.label1')}`, `${t('workdays.label2')}`, '']
  const { workdaysToReview } = useHrDashboard()
  const { setOpenModal } = useNotifications()
  const { user } = useAuth()

  const handleClick = (user: IWorkdaysToReview['user']) => {
    setUserId(user.id)
    setOpenModal(true)
  }
  const rows: GridRowsProp = workdaysToReview.map((workday) => {
    return {
      name: `${workday.user.firstName} ${workday.user.lastName}`,
      workdaysToReview: workday.workdays.length,
      button: isUserPermitted(user, [
        permissionList.WORKDAY_REVIEW_ACCEPT,
        permissionList.WORKDAY_REVIEW_DECLINE,
      ]) && (
        <Tooltip
          title={t('workdays.tooltip1') as string}
          onClick={() => handleClick(workday.user)}
        >
          <IconButton>
            <WorkIcon />
          </IconButton>
        </Tooltip>
      ),
    }
  })
  const userWorkdaysToReview = workdaysToReview.find(
    (user) => user.user.id === userId
  )
  return (
    <Paper sx={{ padding: '1rem', minWidth: '29rem' }}>
      <TableTitle>{t('workdays.title')} </TableTitle>
      {workdaysToReview.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent rows={rows} columns={columns} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('workdays.message')} />
      )}
      <ModalComponent
        title={`Review workdays for ${userWorkdaysToReview?.user.firstName} ${userWorkdaysToReview?.user.lastName}`}
        component={
          <ReviewWorkdays userWorkdaysToReview={userWorkdaysToReview} />
        }
      />
    </Paper>
  )
}
export default WorkdaysToReview
