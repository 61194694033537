import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

import { IGroupDate } from '../../types'

const createNewGroup = async (groupData: IGroupDate) => {
  const { data }: AxiosResponse = await apiRequest(APIRoutes.GROUPS_CREATE, {
    name: groupData.groupName,
    code: groupData.groupCode.toUpperCase(),
  })
  return data
}

export const useAddGroup = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()

  const { t } = useTranslation('handleMessage')
  const { mutate: addGroup } = useMutation(createNewGroup, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('group')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.groups])
    },
  })
  return addGroup
}
