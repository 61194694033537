import { useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { isWithinInterval, format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { useGetOneUser } from 'hooks/user'

import { IUserProjectTimeFrames, ITimeFrames } from '../../types'

const fetchUserTimeFrames = async (id: number): Promise<ITimeFrames[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.USERTIMEFRAMES_GET,
    {},
    { userId: id }
  )
  return data
}

export const useGetUserTimeFrames = () => {
  const { id } = useParams()
  const oneUser = useGetOneUser()
  const fallback: ITimeFrames[] = []

  const { data: userTimeFrames = fallback, refetch } = useQuery(
    queryKeys.timeFrames,
    () => fetchUserTimeFrames(Number(id))
  )
  const getProjectNameById = (id: number): string | null => {
    if (oneUser?.projects) {
      const project = oneUser.projects.find((project) => project.id === id)
      if (project) return project.name
    }
    return null
  }
  const getCurrentTimeFrame = (
    timeFrames: IUserProjectTimeFrames[]
  ): IUserProjectTimeFrames | null => {
    const today = format(new Date(), 'yyyy, M, dd')

    const current = timeFrames.find((timeFrame) => {
      const startDate = format(new Date(timeFrame.startDate), 'yyyy, M, dd')
      const endDate = format(new Date(timeFrame.startDate), 'yyyy, M, dd')
      return isWithinInterval(new Date(today), {
        start: new Date(startDate),
        end: new Date(endDate),
      })
    })
    if (current) return current
    return null
  }

  useEffect(() => {
    refetch()
  }, [id])

  return { userTimeFrames, getProjectNameById, getCurrentTimeFrame }
}
