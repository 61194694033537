import { Dispatch, SetStateAction } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAllUsers } from 'hooks/useAllUsers'

interface IProps {
  checkUser: boolean
  setSelectedUserId: Dispatch<SetStateAction<number | null>>
  setFieldValue?: any
  name?: string
}

const AutocompleteUserInput: React.FC<IProps> = ({
  checkUser,
  setSelectedUserId,
  setFieldValue,
  name,
}): JSX.Element => {
  const { t } = useTranslation('inventory')
  const allUsers = useAllUsers()
  const usersData = allUsers?.map((user) => {
    return {
      userName: `${user.firstName} ${user.lastName} <${user.email}>`,
      userId: user.id,
    }
  })

  return (
    <Autocomplete
      fullWidth
      size="small"
      id={name}
      options={checkUser ? usersData : []}
      isOptionEqualToValue={(option, { userName }) =>
        option.userName === userName
      }
      getOptionLabel={(option) => {
        return checkUser ? option.userName : ''
      }}
      onChange={(_, value) => {
        {
          setFieldValue(name, value?.userName || '')
          setSelectedUserId(value ? value.userId : null)
        }
      }}
      disabled={!checkUser}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          margin="normal"
          label={t('modal.addNewItems.label1')}
        />
      )}
    />
  )
}

export default AutocompleteUserInput
