import { Box, Collapse, Divider, Drawer, Link } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PeopleIcon from '@mui/icons-material/People'
import GroupsIcon from '@mui/icons-material/Groups'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import ReportIcon from '@mui/icons-material/Report'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import CopyrightIcon from '@mui/icons-material/Copyright'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ManageHistoryIcon from '@mui/icons-material/ManageHistory'
import AssignmentIcon from '@mui/icons-material/Assignment'
import InventoryIcon from '@mui/icons-material/Inventory'
import StyleIcon from '@mui/icons-material/Style'
import MenuOpenSharpIcon from '@mui/icons-material/MenuOpenSharp'
import DeckIcon from '@mui/icons-material/Deck'
import palette from 'theme/palette'
import { permissionList } from 'services'
import { WithPagePermissions } from 'hoc/withPagePermission'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageRoutes } from 'routes'
import { useNotifications } from 'components/common'

import { MenuIconButton } from '../../index.style'

import UserSettings from './UserSettings'
import { Group, LogoContainer, OneButton, StyledNavLink } from './index.style'

const Sidebar: React.FC = (): JSX.Element => {
  const { t } = useTranslation('sidebar')
  const { openSideBar, setOpenSideBar } = useNotifications()
  const [open, setOpen] = useState({
    reports: false,
    system: false,
  })

  function handleClick(type: string) {
    if (type === 'reports') {
      setOpen((prev) => ({ ...prev, [type]: !open.reports }))
    } else if (type === 'system') {
      setOpen((prev) => ({ ...prev, [type]: !open.system }))
    }
  }

  const navItems = [
    {
      to: PageRoutes.VACATION_REQUEST,
      name: 'Requests',
      Icon: <CalendarMonthIcon />,
      permissions: [permissionList.VACATION_REQUESTS_READ],
      target: '_self',
    },
    {
      to: PageRoutes.CALENDAR,
      name: t('page.calendar'),
      Icon: <CalendarMonthIcon />,
      permissions: [permissionList.CALENDAR_VIEW],
      target: '_self',
    },
    {
      to: 'https://desk_reservation.bcf-software.com/auth?fromTimesheet=true',
      name: t('page.reservations'),
      Icon: <DeckIcon />,
      permissions: [permissionList.CALENDAR_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.PKUP,
      name: t('page.pkup'),
      Icon: <CopyrightIcon />,
      permissions: [permissionList.PKUP_READ],
      target: '_self',
    },
    {
      to: PageRoutes.USERS,
      name: t('page.users'),
      Icon: <PeopleIcon />,
      permissions: [permissionList.USERS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.CUSTOMERS,
      name: t('page.customers'),
      Icon: <ShoppingCartIcon />,
      permissions: [permissionList.CUSTOMERS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.PROJECTS,
      name: t('page.projects'),
      Icon: <AssignmentIcon />,
      permissions: [permissionList.CUSTOMERS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.GROUPS,
      name: t('page.groups'),
      Icon: <GroupsIcon />,
      permissions: [permissionList.GROUPS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.HOLIDAYS,
      name: t('page.holidays'),
      Icon: <BeachAccessIcon />,
      permissions: [permissionList.HOLIDAYS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.PKUPS_DASHBOARD,
      name: t('page.pkupsdashboard'),
      Icon: <DeveloperBoardIcon />,
      permissions: [permissionList.PKUP_EDIT],
      target: '_self',
    },
    {
      to: PageRoutes.PM_DASHBOARD,
      name: t('page.pmdashboard'),
      Icon: <DeveloperBoardIcon />,
      permissions: [permissionList.PM_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.HR_DASHBOARD,
      name: t('page.hrdashboard'),
      Icon: <DeveloperBoardIcon />,
      permissions: [permissionList.HR_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.INVENTORY,
      name: t('page.inventory'),
      Icon: <InventoryIcon />,
      permissions: [permissionList.INVENTORY_VIEW],
      target: '_self',
    },
  ]

  const navReports = [
    {
      to: PageRoutes.REPORT_DASHBOARD,
      name: t('page.reports.dashboard'),
      Icon: <ReportIcon />,
      permissions: [permissionList.REPORTS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.REPORT_ALLOCATIONS,
      name: t('page.reports.allocations'),
      Icon: <ReportIcon />,
      permissions: [permissionList.REPORTS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.REPORT_PRODUCTIVITY,
      name: t('page.reports.productivity'),
      Icon: <ReportIcon />,
      permissions: [permissionList.REPORTS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.REPORT_WORKDAYS,
      name: t('page.reports.workdays'),

      Icon: <ReportIcon />,
      permissions: [permissionList.REPORTS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.REPORT_AGREEMENTS,
      name: t('page.reports.agreements'),
      Icon: <ReportIcon />,
      permissions: [permissionList.REPORTS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.REPORT_CUSTOMERS,
      name: t('page.reports.customers'),
      Icon: <ReportIcon />,
      permissions: [permissionList.REPORTS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.REPORT_PKUP,
      name: t('page.reports.pkup'),
      Icon: <ReportIcon />,
      permissions: [permissionList.REPORTS_VIEW],
      target: '_self',
    },
  ]

  const navSystemSettings = [
    {
      to: PageRoutes.SYSTEM_WORKTYPES,
      name: t('page.system.worktypes'),
      Icon: <BusinessCenterIcon />,
      permissions: [permissionList.WORKTYPES_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.SYSTEM_LOCATIONS,
      name: t('page.system.locations'),
      Icon: <LocationOnIcon />,
      permissions: [permissionList.LOCATIONS_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.SYSTEM_BUSINESS_UNITS,
      name: t('page.system.bussiness_units'),
      Icon: <GroupsIcon />,
      permissions: [permissionList.BUSINESS_UNIT_READ],
      target: '_self',
    },
    {
      to: PageRoutes.SYSTEM_ACTIVITY,
      name: t('page.system.log_activity'),
      Icon: <ManageHistoryIcon />,
      permissions: [permissionList.ACTIVITY_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.SYSTEM_HOLIDAYS_CATEGORIES,
      name: t('page.system.holidays_categories'),
      Icon: <BeachAccessIcon />,
      permissions: [permissionList.HOLIDAYS_CATEGORIES_VIEW],
      target: '_self',
    },
    {
      to: PageRoutes.SYSTEM_TAGS,
      name: t('page.system.tags'),
      Icon: <StyleIcon />,
      permissions: [permissionList.TAG_VIEW],
      target: '_self',
    },
  ]

  return (
    <>
      <MenuIconButton
        onClick={() => setOpenSideBar(!openSideBar)}
        sx={{
          top: ` ${openSideBar ? '50px' : '24px'} `,
          left: ` ${openSideBar ? '230px' : '20px'} `,
          transition: '0.1s linear',
          transform: ` ${openSideBar ? 'rotate(0)' : 'rotate(180deg)'} `,
          '&:hover': {
            backgroundColor: palette.primary.main,
            boxShadow: `0px 2px 10px 1px ${
              openSideBar ? palette.primary.white : palette.primary.main
            }`,
          },
          '&:hover svg': {
            fill: palette.primary.white,
          },
        }}
      >
        <MenuOpenSharpIcon />
      </MenuIconButton>
      <Box
        sx={{
          transform: ` ${openSideBar ? 'translateX(0)' : 'translateX(-100%)'} `,
          width: ` ${openSideBar ? '250px' : '0'} `,
          zIndex: 100,
          position: 'sticky',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          top: 0,
        }}
      >
        <Drawer
          variant="persistent"
          anchor="left"
          open={openSideBar}
          PaperProps={{
            style: {
              width: '250px',
              backgroundColor: palette.primary.main,
              overflow: 'hidden',
              height: '100vh',
              color: palette.primary.contrastText,
            },
          }}
        >
          <Box
            sx={{
              overflowY: 'scroll',
              height: '100%',
              width: '250px',
              scrollbarWidth: 'none',
              transition: '0.3s linear',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Link href="/">
              <LogoContainer>
                <img
                  height="50"
                  src="https://bcf-software.pl/img/logo.svg"
                  alt="bcf-software logo"
                />
                <span>timesheet</span>
              </LogoContainer>
            </Link>
            <Group color="secondary" aria-label="medium secondary button group">
              <UserSettings />
              <StyledNavLink to={PageRoutes.HOME}>
                <OneButton variant="text" startIcon={<DashboardIcon />}>
                  {t('page.reports.dashboard')}
                </OneButton>
              </StyledNavLink>
              {navItems.map((item) => (
                <WithPagePermissions
                  key={item.name}
                  Component={
                    <StyledNavLink to={item.to} target={item?.target}>
                      <OneButton variant="text" startIcon={item.Icon}>
                        <Box className="itemName">{item.name}</Box>
                      </OneButton>
                    </StyledNavLink>
                  }
                  permission={item.permissions}
                ></WithPagePermissions>
              ))}
              <WithPagePermissions
                Component={
                  <OneButton
                    variant="text"
                    startIcon={<ReportIcon />}
                    onClick={() => handleClick('reports')}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '80%',
                      }}
                    >
                      {t('page.reports.reports')}
                      {open.reports ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </Box>
                  </OneButton>
                }
                permission={[permissionList.REPORTS_VIEW]}
              ></WithPagePermissions>
              <Collapse in={open.reports} timeout="auto" unmountOnExit>
                <Divider />
                <Box component="div" sx={{ pl: 3 }}>
                  {navReports.map((item) => (
                    <WithPagePermissions
                      key={item.name}
                      Component={
                        <StyledNavLink to={item.to}>
                          <OneButton variant="text" startIcon={item.Icon}>
                            <Box className="itemName">{item.name}</Box>
                          </OneButton>
                        </StyledNavLink>
                      }
                      permission={item.permissions}
                    ></WithPagePermissions>
                  ))}
                </Box>
              </Collapse>

              <WithPagePermissions
                Component={
                  <OneButton
                    variant="text"
                    startIcon={<SettingsSuggestIcon />}
                    onClick={() => handleClick('system')}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '80%',
                      }}
                    >
                      {t('page.system.system')}
                      {open.system ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </Box>
                  </OneButton>
                }
                permission={[permissionList.SYSTEM_SETTINGS_VIEW]}
              ></WithPagePermissions>
              <Collapse in={open.system} timeout="auto" unmountOnExit>
                <Divider />
                <Box component="div" sx={{ pl: 3 }}>
                  {navSystemSettings.map((item) => (
                    <WithPagePermissions
                      key={item.name}
                      Component={
                        <StyledNavLink to={item.to}>
                          <OneButton variant="text" startIcon={item.Icon}>
                            <Box className="itemName">{item.name}</Box>
                          </OneButton>
                        </StyledNavLink>
                      }
                      permission={item.permissions}
                    ></WithPagePermissions>
                  ))}
                </Box>
              </Collapse>
            </Group>
          </Box>
        </Drawer>
      </Box>
    </>
  )
}

export default Sidebar
