import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { IPaginateReducer, defaultPaginationValue } from 'hooks/table'

import { IHistory } from './types'

const getActivityLog = async (params: string): Promise<IHistory> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.ACTIVITY_LOG,
    {},
    {},
    `${params}`
  )
  return data
}

export const useGetActivityLog = () => {
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>(
    defaultPaginationValue
  )
  const fallback = <IHistory>{}
  const searchInput = paginationUc?.search
    ? `&search=${paginationUc?.search}`
    : ''

  const params = `page=${paginationUc?.currentPage + 1 || 1}&limit=${
    paginationUc?.length || 10
  }${searchInput}
  `

  const { data: projectGetOneActivityLog = fallback, refetch } = useQuery(
    queryKeys.activityLog,
    () => getActivityLog(params)
  )

  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }

  const total = projectGetOneActivityLog.total

  useEffect(() => {
    refetch()
  }, [paginationUc])

  return { total, changePagination, paginationUc, projectGetOneActivityLog }
}
