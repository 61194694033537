import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { IAllocations } from 'hooks/types'
import { useGetOneUser } from 'hooks/user'

import { useAllocationsContext } from './context/AllocationContext'

export const useAllocations = () => {
  const [tabValue, setValue] = useState(0)
  const [allocations, setAllocations] = useState<IAllocations[] | []>([])
  const { customDate, setCustomDate } = useAllocationsContext()

  const currentDay = new Date()
  const oneUser = useGetOneUser()

  const selectedAllocations = () => {
    switch (tabValue) {
      case 0: {
        const currentMonth = format(currentDay, 'yyyy/MM')
        const currentAllocations = oneUser?.allocations?.filter(
          (allocation: IAllocations) => allocation.date === currentMonth
        ) as IAllocations[]
        return currentAllocations
      }

      case 1: {
        const currentDate = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth() - 1
        )
        const previousMonth = format(currentDate, 'yyyy/MM')
        const previousAllocations = oneUser?.allocations?.filter(
          (allocation) => allocation.date === previousMonth
        ) as IAllocations[]
        return previousAllocations
      }
      case 2: {
        const currentDate = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth() + 1
        )
        const nextMonth = format(currentDate, 'yyyy/MM')
        const nextAllocations = oneUser?.allocations?.filter(
          (allocation: IAllocations) => allocation.date === nextMonth
        ) as IAllocations[]

        return nextAllocations
      }
      case 3: {
        const customMonth = customDate && format(customDate as Date, 'yyyy/MM')
        const customAllocations = customDate
          ? (oneUser?.allocations?.filter(
              (allocation: IAllocations) => allocation.date === customMonth
            ) as IAllocations[])
          : oneUser?.allocations

        return customAllocations
      }
      default:
        return []
    }
  }
  useEffect(() => {
    const allocations = selectedAllocations()
    setAllocations(allocations)
  }, [tabValue, customDate])
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const handleDate = (newValue: Date | null) => {
    setCustomDate(newValue)
  }
  return {
    handleChange,
    tabValue,
    allocations,
    customDate,
    handleDate,
  }
}
