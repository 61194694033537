import { Box, styled } from '@mui/material'
type props = {
  isopenpopup?: string
}
export const StyleWrapper = styled('div')<props>(({ theme, isopenpopup }) => ({
  marginTop: 50,
  marginBottom: 50,
  '.fc-col-header-cell': {
    color: theme.palette.primary.dark,
    fontWeight: '600',
  },
  '.fc .fc-button-primary': {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.contrastText}`,
  },
  '.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active':
    {
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.contrastText}`,
    },
  '.fc .fc-view-harness-active > .fc-view': {
    backgroundColor: theme.palette.primary.contrastText,
  },
  '.fc-direction-ltr .fc-timegrid-slot-label-frame': {
    textAlign: 'center',
  },
  '.fc-timegrid-more-link.fc-more-link': {
    backgroundColor: theme.palette.info.main,
    color: 'white',
  },
  '.fc-popover': {
    visibility: isopenpopup === 'false' ? 'hidden' : 'visible',
  },
  height: '650px',
  '@media (max-height: 800px)': {
    height: '450px',
  },
}))

export const EventBox = styled(Box)(({ theme }) => ({
  '.fc-event .fc-event-draggable, fc-daygrid-event': {
    backgroundColor: theme.palette.primary.main,
  },
}))
