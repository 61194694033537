import { useEffect, useState } from 'react'
import { useCalendarContext } from 'pages/Calendar/context'
import { Workday } from 'pages/Calendar/context/types'
import {
  format,
  differenceInCalendarMonths,
  eachDayOfInterval,
  eachWeekendOfInterval,
  startOfMonth,
  endOfMonth,
} from 'date-fns'
import { useAuth } from 'hooks/useAuth'
import fileDownload from 'js-file-download'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { useCalendarHook } from '../Calendar/utils'

export function useStickyBar() {
  const {
    handleOpenModal,
    handleCloseModal,
    handleCloseBar,
    setNotification,
    currentCalendarDate,
  } = useNotifications()
  const { t } = useTranslation('calendar')
  const [downloadDate, setDownloadDate] = useState<Date | null>(
    new Date(currentCalendarDate)
  )
  const [startValue, setStartValue] = useState<Date | null>(
    startOfMonth(new Date(currentCalendarDate))
  )
  const [endValue, setEndValue] = useState<Date | null>(
    endOfMonth(new Date(currentCalendarDate))
  )

  const [isFetching, setIsFetching] = useState(false)
  const [isFetchingUop, setIsFetchingUop] = useState(false)

  const {
    setDaysRange,
    setTheSameDays,
    copyMode,
    selectedWorkDays,
    excludeWeekends,
    setWeekends,
    setWithoutWeekends,
    holidays,
    setHolidayForMonth,
  } = useCalendarContext()

  useEffect(() => {
    const days = () => {
      let days: Date[] = []
      let weekends: Date[] = []

      if (
        endValue &&
        startValue &&
        startValue <= endValue &&
        moment(endValue).format('MM/YYYY') ===
          moment(startValue).format('MM/YYYY')
      ) {
        days = eachDayOfInterval({
          start: startValue,
          end: endValue,
        })
        weekends = eachWeekendOfInterval({
          start: startValue,
          end: endValue,
        })
      } else {
        setNotification({
          message: t('stickybar.alert3'),
          variant: NOTIFICATION.error,
        })
      }

      const tempDaysArr = days.map((day) => format(day, 'yyyy-MM-dd'))
      const tempWeekendsArr = weekends.map((day) => format(day, 'yyyy-MM-dd'))

      const withoutWeekends = tempDaysArr.filter(
        (day) => !tempWeekendsArr.includes(day)
      )

      let arr: string[] = []
      if (excludeWeekends) {
        for (let i = 0; i < withoutWeekends.length; i++) {
          arr = [...arr, `${withoutWeekends[i]}`]
        }
      } else {
        for (let i = 0; i < days.length; i++) {
          arr = [...arr, `${format(days[i], 'yyyy-MM-dd')}`]
        }
      }
      const filteredWeekends = checkTheSameDays(
        selectedWorkDays,
        tempWeekendsArr
      )

      const filteredWeekendsDates = filteredWeekends.map(
        (day) => new Date(day.start)
      )

      setWithoutWeekends(withoutWeekends)
      setWeekends(filteredWeekendsDates)
      return arr
    }

    const arrDays = days()
    const theSameDates = checkTheSameDays(selectedWorkDays, arrDays)

    setTheSameDays(theSameDates)
  }, [startValue, endValue])

  const { downloadUOPWorkDays, downloadWorkDays } = useCalendarHook()

  const { user } = useAuth()

  const downloadExcel = (downloadType: string) => {
    downloadType === 'uop' ? setIsFetchingUop(true) : setIsFetching(true)
    let filename = ''
    const date =
      downloadDate !== null ? format(new Date(downloadDate), 'yyyy-MM') : null
    const timesheetName =
      downloadType === 'uop' ? '-timesheet-uop-' : '-timesheet-'

    filename = user?.firstName + '_' + user?.lastName + timesheetName + date

    downloadType === 'uop'
      ? downloadUOPWorkDays({ month: downloadDate })
          .then((response) => {
            if (response) {
              fileDownload(new Blob([response]), filename + '.xlsx')
            } else {
              setNotification({
                message: t('stickybar.alert1'),
                variant: NOTIFICATION.error,
              })
            }
          })
          .finally(() =>
            downloadType === 'uop'
              ? setIsFetchingUop(false)
              : setIsFetching(false)
          )
      : downloadWorkDays({ month: downloadDate })
          .then((response) => {
            if (response) {
              fileDownload(new Blob([response]), filename + '.xlsx')
            } else {
              setNotification({
                message: t('stickybar.alert1'),
                variant: NOTIFICATION.error,
              })
            }
          })
          .finally(() =>
            downloadType === 'uop'
              ? setIsFetchingUop(false)
              : setIsFetching(false)
          )
  }

  const addButtonClickHandler = () => {
    let theSameMonth
    const newArr = () => {
      const days = eachDayOfInterval({
        start: startValue !== null ? new Date(startValue) : new Date(),
        end: endValue !== null ? new Date(endValue) : new Date(),
      })

      const weekends = eachWeekendOfInterval({
        start: startValue !== null ? new Date(startValue) : new Date(),
        end: endValue !== null ? new Date(endValue) : new Date(),
      })

      let arr: string[] = []

      for (let i = 0; i < days.length; i++) {
        arr = [...arr, `${format(days[i], 'yyyy-MM-dd')}`]
      }

      theSameMonth = differenceInCalendarMonths(
        new Date(arr[0]),
        new Date(arr[arr.length - 1])
      )
      setWeekends(weekends)
      if (theSameMonth !== 0) {
        setNotification({
          message: t('stickybar.alert2'),
          variant: NOTIFICATION.error,
        })
        handleCloseModal()
      }

      return arr
    }
    const filterHolidaysForMonth = (selectedDays: string[]) => {
      const tempHolidays = holidays.filter((day) => {
        if (selectedDays.includes(format(new Date(day.start), 'yyyy-MM-dd')))
          return day
      })

      setHolidayForMonth(tempHolidays)
    }
    filterHolidaysForMonth(newArr())

    setDaysRange(newArr())

    handleCloseBar()
    if (theSameMonth === 0) {
      handleOpenModal('addWorkdaysModal')
    }
  }

  function checkTheSameDays(daysInCalendar: Workday[], selectedDays: string[]) {
    const copy = daysInCalendar.map((object) => ({ ...object }))

    const filteredDays = copy.filter((day1) =>
      selectedDays.find((day2) => {
        return format(new Date(day1.start), 'yyyy-MM-dd') === day2
      })
    )

    if (copyMode) {
      const filtered: Workday[] = []
      filteredDays.filter((p) => {
        const bool = daysInCalendar.some(
          (w) =>
            w.start === p.start &&
            w.end === p.end &&
            p.extendedProps.comment === w.extendedProps.comment &&
            w.extendedProps.isPlanned !== p.extendedProps.isPlanned
        )
        if (bool) {
          return
        } else {
          filtered.push(p)
          return
        }
      })
      return filtered
    }
    return filteredDays
  }

  return {
    startValue,
    setStartValue,
    endValue,
    setEndValue,
    downloadDate,
    setDownloadDate,
    checkTheSameDays,
    downloadExcel,
    addButtonClickHandler,
    isFetching,
    isFetchingUop,
  }
}
