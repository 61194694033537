import { Typography } from '@mui/material'

import { FooterContainer } from './index.style'
const Footer: React.FC = (): JSX.Element => {
  return (
    <FooterContainer>
      <Typography variant="subtitle1">
        2020 - {new Date().getFullYear()}, version: 1.0.0
      </Typography>
    </FooterContainer>
  )
}

export default Footer
