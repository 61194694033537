import { useEffect } from 'react'
import { Container, IconButton } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import CancelIcon from '@mui/icons-material/Cancel'
import { useDeleteTimeframes, useGetCustomerProject } from 'hooks/customers'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { IUser } from 'pages/Customers/types'
import { useNotifications } from 'components/common'

interface IProps {
  user: IUser
}

const RemoveTimeframes: React.FC<IProps> = ({ user }) => {
  const { t } = useTranslation('projectManageUser')
  const { deleteTimeframe } = useDeleteTimeframes()
  const { handleCloseModal } = useNotifications()
  const { id } = useParams()
  const customerProject = useGetCustomerProject(id)

  const foundUser = customerProject?.users?.find(
    (userProject) => userProject.id === user.id
  )

  useEffect(() => {
    if (!foundUser?.ProjectUser?.timeframes.length) handleCloseModal()
  }, [foundUser?.ProjectUser?.timeframes])

  const columns: GridColDef[] = [
    {
      field: 'startTime',
      headerName: `${t('table.cell5', { ns: 'projectManageUser' })}`,
      flex: 1,
    },
    {
      field: 'endTime',
      headerName: `${t('table.cell6', { ns: 'projectManageUser' })}`,
      flex: 1,
    },
    {
      field: 'type',
      headerName: `${t('table.cell7', { ns: 'projectManageUser' })}`,
      flex: 1,
    },
    {
      field: 'notes',
      headerName: `${t('table.cell8', { ns: 'projectManageUser' })}`,
      flex: 1,
    },
    {
      field: 'button',
      headerName: '',
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              deleteTimeframe(params.row.button as number)
            }}
          >
            <CancelIcon color="error" />
          </IconButton>
        )
      },
    },
  ]

  const rows = foundUser?.ProjectUser?.timeframes?.map((timeframe) => {
    let timeFrameType: string

    if (
      format(new Date(), 'yyyy-MM-dd') >= timeframe.startDate?.slice(0, 10) &&
      format(new Date(), 'yyyy-MM-dd') <= timeframe.endDate?.slice(0, 10)
    ) {
      timeFrameType = `${t('table.option1', { ns: 'projectManageUser' })}`
    } else if (
      format(new Date(), 'yyyy-MM-dd') > timeframe.startDate?.slice(0, 10)
    ) {
      timeFrameType = `${t('table.option2', { ns: 'projectManageUser' })}`
    } else {
      timeFrameType = `${t('table.option3', { ns: 'projectManageUser' })}`
    }
    return {
      id: timeframe.id,
      startTime: timeframe.startDate.slice(0, 10),
      endTime: timeframe.endDate.slice(0, 10),
      type: timeFrameType,
      notes: timeframe.notes,
      button: timeframe.id,
    }
  })

  return (
    <Container>
      <DataGrid
        autoHeight={true}
        rows={rows || []}
        columns={columns}
        sortModel={[{ field: 'startTime', sort: 'asc' }]}
        hideFooterPagination={true}
        hideFooter={true}
        disableSelectionOnClick={true}
      />
    </Container>
  )
}

export default RemoveTimeframes
