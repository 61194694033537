import {
  Button,
  Paper,
  Stack,
  Typography,
  Container,
  Grid,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  AntSwitch,
  AutoCompleteCheckAll,
  CustomizedDataPicker,
  EmptyArrayCard,
  TableComponent,
  TableTitle,
} from 'components/common'
import { HeaderTab } from 'views/LayoutUI/components'
import { useAllUsers } from 'hooks/useAllUsers'

import { RowsReportProductivity } from './components'
import { useReportProductivity } from './utils'

const ReportProductivity = () => {
  const { t } = useTranslation(['reportProductivity', 'buttons'])
  const allUsers = useAllUsers()

  const {
    initialValues,
    userData,
    submitHandler,
    setIsPlanningMode,
    isPlanningMode,
  } = useReportProductivity()

  const users = allUsers?.map((user) => {
    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName} (${user.email})`,
    }
  })

  const columnLabels = [
    t('table.label1'),
    t('table.label2'),
    t('table.label3'),
    t('table.label4'),
    t('table.label5'),
    t('table.label6'),
    t('table.label7'),
    t('table.label8'),
    t('table.label9'),
    t('table.label10'),
    t('table.label11'),
    t('table.label12'),
    t('table.label13'),
    t('table.label14'),
  ]

  const columns = columnLabels.map((column, i) => (
    <Typography variant="h4" key={i} textAlign="center">
      {column}
    </Typography>
  ))

  const rowsReport = RowsReportProductivity()
  const rows = rowsReport(userData, isPlanningMode)

  return (
    <Container>
      <HeaderTab translate="reportProductivity" id={t('header.id')} />
      <Paper
        sx={{ padding: '0.625rem 1.25rem', width: 'calc(100% - 2.5rem)' }}
        elevation={3}
      >
        <TableTitle>
          {t('card.title1')}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ marginRight: 3 }}
          >
            <AntSwitch
              onClick={() => setIsPlanningMode((prev) => !prev)}
              checked={isPlanningMode ? true : false}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>
              {isPlanningMode
                ? t('toggleButton.option1')
                : t('toggleButton.option2')}
            </Typography>
          </Stack>
        </TableTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            submitHandler(values)
          }}
        >
          {({ values }) => (
            <Form>
              <Grid
                container
                direction="row-reverse"
                spacing={1}
                sx={{ marginTop: '10px' }}
              >
                <Grid item xs={6}>
                  <Field
                    component={AutoCompleteCheckAll}
                    name="value"
                    label={t('form.label2')}
                    optionsData={users}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={CustomizedDataPicker}
                    label={t('form.label1')}
                    name="startValue"
                    views={['year']}
                    inputFormat="yyyy"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={!values.value.length}
                    type="submit"
                    sx={{ width: '100%' }}
                  >
                    {t('showReport', { ns: 'buttons' })}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
      {userData.length ? (
        <TableComponent stickyHeader columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard message={t('card.title2')} />
      )}
    </Container>
  )
}

export default ReportProductivity
