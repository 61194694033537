import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

import { IData } from './types'

const getReportCustomers = async (): Promise<IData> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.REPORT_GET_CUSTOMERS,
    {},
    {}
  )
  return data
}

export const useGetReportCustomers = () => {
  const fallback = <IData>{}

  const { data: reportCustomers = fallback } = useQuery(
    queryKeys.reportCustomers,
    () => getReportCustomers()
  )

  return reportCustomers
}
