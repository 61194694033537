import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button, Grid } from '@mui/material'
import { TextField } from 'formik-mui'
import { useAddUser } from 'hooks/user'

import { useUserData } from './hooks/useUserData'

const AddNewUser = (): JSX.Element => {
  const { t } = useTranslation('buttons')
  const addNewUser = useAddUser()
  const { initialValues, validationSchema, fields } = useUserData()
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        addNewUser(values)
        setSubmitting(false)
      }}
      enableReinitialize
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container columnSpacing={2} rowSpacing={1} mb={2}>
            {fields.map((field) => (
              <Grid item xs={6} key={field.id}>
                <Field
                  fullWidth
                  component={TextField}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  margin="normal"
                  autoComplete={field.name === 'password' ? 'on' : 'off'}
                />
              </Grid>
            ))}
          </Grid>
          <Button disabled={isSubmitting} onClick={submitForm}>
            {t('save', { ns: 'buttons' })}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default AddNewUser
