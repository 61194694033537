import { Button, Chip, LinearProgress, Paper } from '@mui/material'
import { Box } from '@mui/system'
import { useGetCustomerProject } from 'hooks/customers'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form, Formik, Field } from 'formik'
import {
  useUpdateProjectDetailsTags,
  useDeleteProjectDetailsTags,
} from 'hooks/projectDetails'
import { useGetAllTags, useTagsValidation } from 'hooks/tags'
import { SkillsAutocomplete, TableTitle } from 'components/common'
import { useState } from 'react'
import { filterArrayById } from 'utils'

export interface ISkills {
  id: number
  name: string
}
export interface IDeleteSkill {
  tagId: number
  userId?: number
}

const Stack = () => {
  const { t } = useTranslation(['projectDetails', 'buttons'])
  const params = useParams()
  const customerProject = useGetCustomerProject(params.id)
  const { updateProjectDetailsTags } = useUpdateProjectDetailsTags()
  const { deleteProjectDetailsTags } = useDeleteProjectDetailsTags()
  const tagsList = useGetAllTags()
  const validationSchema = useTagsValidation()
  const [values, setValues] = useState<string[]>([])

  const difference = [...filterArrayById(tagsList, customerProject.tags)]

  return (
    <Paper elevation={3} sx={{ marginTop: 1, padding: '0.5rem 1.25rem' }}>
      <TableTitle>{t('typographies.typography5')}</TableTitle>
      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          marginTop: '10px',
          flexWrap: 'wrap',
        }}
      >
        {customerProject?.tags?.map(({ id, name }) => (
          <Chip
            key={id}
            label={name}
            variant="outlined"
            color="primary"
            onDelete={() =>
              deleteProjectDetailsTags({
                tagId: id.toString(),
                projectId: customerProject.id.toString(),
              })
            }
          />
        ))}
      </Box>
      <Formik
        initialValues={{ tags: [] }}
        onSubmit={({ tags }, { setSubmitting, resetForm }) => {
          const updateSkill = tags.map(({ id, name }) => ({
            id: id,
            name: name,
          }))
          const sendObject = {
            projectId: customerProject.id.toString(),
            skills: updateSkill,
          }
          updateProjectDetailsTags(sendObject)
          resetForm()
          setValues([])
          setSubmitting(false)
        }}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            <Field
              component={SkillsAutocomplete}
              name="tags"
              values={values}
              error={errors?.tags}
              label={t('typographies.typography9')}
              freeSolo={true}
              setValues={setValues}
              optionsData={difference?.map((option) => option)}
            />
            {isSubmitting && <LinearProgress />}
            <Button color="primary" type="submit" sx={{ marginBottom: 1 }}>
              {t('save', { ns: 'buttons' })}
            </Button>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}

export default Stack
