import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { useEffect } from 'react'
import { IPkupDetails, IPkupWorks } from 'hooks/pkup/types'

export const usePkupsDetails = (pkupId: number) => {
  const fallbackPkupDetails = {} as IPkupDetails
  const fallbackPkupWorks: IPkupWorks[] = []

  async function getPkupDetails(): Promise<IPkupDetails> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_GET_ONE,
      {},
      { id: pkupId }
    )
    return data
  }
  async function getAllWorksForCurrentPkup(): Promise<IPkupWorks[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_GET_ALL_WORK_FOR_PKUP,
      {},
      { id: pkupId }
    )
    return data
  }
  const {
    data: pkupDetails = fallbackPkupDetails,
    refetch: refetchPkupDetails,
  } = useQuery(queryKeys.pkupDetails, getPkupDetails)
  const { data: pkupWorks = fallbackPkupWorks, refetch: refetchPkupWorks } =
    useQuery(queryKeys.pkupWorks, getAllWorksForCurrentPkup)

  useEffect(() => {
    refetchPkupDetails(), refetchPkupWorks()
  }, [pkupId])
  return { pkupDetails, pkupWorks }
}
