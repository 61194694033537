import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

export const useUserProfile = (userId: number | undefined, type?: string) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')
  const { setNotification, handleCloseModal } = useNotifications()

  const addProfileToUser = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_PROFILE_CREATE,
      {
        type,
      },
      { id: userId }
    )
    return data
  }

  const { mutate: addProfile } = useMutation(addProfileToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('profile')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdProfile])
    },
  })

  return { addProfile }
}
