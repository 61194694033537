import { useState } from 'react'
import { Box, Container, Grid, Tab, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import {
  ModalComponent,
  ModalConfirm,
  useNotifications,
} from 'components/common'
import {
  EditInventoryForm,
  AddInventoryForm,
  UserInventory,
} from 'components/inventory'
import { DefaultModal } from 'views/LayoutUI/modals'
import {
  useDeleteInventory,
  IEditInventory,
  defaultValue,
} from 'hooks/inventory'
import { ISelectingModal, ValueType } from 'hooks/types'
import { HeaderTab } from 'views/LayoutUI/components'
import { isUserPermitted, permissionList } from 'services'

import {
  Notifications,
  PersonalInformation,
  UpdateEmail,
  UpdatePassword,
  Skills,
} from './components'

const Profile: React.FC = (): JSX.Element => {
  const [value, setValue] = useState<ValueType>('1')
  const { user } = useAuth()
  const { modalName } = useNotifications()

  const [inventory, setInventory] = useState<IEditInventory>(defaultValue)
  const deleteInventory = useDeleteInventory()
  const { t } = useTranslation(['profile', 'inventory'])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const deleteContent = () => {
    return (
      <Typography>
        {t('modal.deleteInventory.title1', { ns: 'inventory' })}{' '}
        {inventory.name} [ {inventory?.model} ]{' '}
        {t('modal.deleteInventory.title2', { ns: 'inventory' })}
      </Typography>
    )
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addInventory':
        return {
          component: <AddInventoryForm user={user} />,
          title: t('inventory.modalTitle.add'),
        }
      case 'editInventory':
        return {
          component: <EditInventoryForm inventory={inventory} />,
          title: t('inventory.modalTitle.edit'),
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteInventory(inventory?.id)}
            />
          ),
          title: t('inventory.modalTitle.deleteInventory'),
        }
      default:
        return {
          component: <DefaultModal />,
          title: t('inventory.modalTitle.default'),
        }
    }
  }
  const isPermitted = isUserPermitted(user, [
    permissionList.INVENTORY_EDIT_OTHER_USER,
  ])
  return (
    <>
      <Container>
        <HeaderTab translate="profile" />
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('tabs.tab1')} value="1" />
              <Tab label={t('tabs.tab2')} value="2" />
              <Tab label={t('tabs.tab3')} value="3" />
              <Tab label={t('tabs.tab4')} value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={3} justifyContent="space-evenly">
              <Grid item xs={12} md={5}>
                <PersonalInformation user={user} />
              </Grid>
              <Grid item xs={12} md={5}>
                <UpdatePassword />
                <UpdateEmail user={user} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Skills user={user} permission={true} />
          </TabPanel>
          <TabPanel value="3">
            <UserInventory
              user={user}
              setInventory={setInventory}
              permission={isPermitted}
            />
          </TabPanel>
          <TabPanel value="4">
            <Notifications />
          </TabPanel>
        </TabContext>
      </Container>
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </>
  )
}

export default Profile
