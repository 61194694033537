import { useState } from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GridRowsProp } from '@mui/x-data-grid'
import { EmptyArrayCard, TableComponent } from 'components/common'
import {
  IPaginateReducer,
  defaultPaginationValue,
  useTablePagedFunction,
} from 'hooks/table'

interface ITableAllocations {
  allocation: {
    month: string
    project: string
    fte: number
    hours: JSX.Element
  }[]
}

const TableAllocations: React.FC<ITableAllocations> = ({ allocation }) => {
  const { t } = useTranslation('userAllocations')
  const columns = [
    `${t('tableAllocations.col1')}`,
    `${t('tableAllocations.col2')}`,
    `${t('tableAllocations.col3')}`,
    <Tooltip key={1} title={t('tableAllocations.tooltip') as string}>
      <Typography
        sx={{ fontWeight: '550', fontSize: '0.875rem', cursor: 'pointer' }}
      >
        {t('tableAllocations.col4')}
      </Typography>
    </Tooltip>,
    `${t('tableAllocations.col5')}`,
    '',
  ]
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>(
    defaultPaginationValue
  )
  const { currentPage, length } = paginationUc

  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }

  const { paginationUC, handleChangePage, handleChangeRowsPerPage } =
    useTablePagedFunction(changePagination)

  const rows: GridRowsProp =
    allocation.length > 10
      ? allocation
          ?.slice(currentPage * length, currentPage * length + length)
          .map((row) => row)
      : allocation
  return (
    <Box>
      {rows.length ? (
        <TableComponent
          columns={columns}
          rows={rows}
          total={allocation?.length}
          pagination={allocation?.length > 10}
          handleChangePage={handleChangePage}
          paginationUC={paginationUC}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <EmptyArrayCard message={t('tableAllocations.noAllocations')} />
      )}
    </Box>
  )
}

export default TableAllocations
