import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'
import { IPkupReports, IWorkdaysToReview } from 'hooks/pkup/types'

export interface IPkupsToReview {
  approverComment: string | null
  approverId: number | null
  attachmentsCount: number
  createdAt: string
  creatorId: number
  description: string
  id: number
  period: string
  revision: number
  state: number
  supervisorId: number
  title: string
  updatedAt: string | null
}
export const useHrDashboard = () => {
  const fallbackWorkdays: IWorkdaysToReview[] = []
  const fallbackPkups: IPkupReports[] = []
  const { setNotification } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  async function getWorkdaysToReview(): Promise<IWorkdaysToReview[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_TOREVIEW
    )
    return data
  }

  async function acceptWorkdaysToReview(ids: number[]) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_TOREVIEW_ACCEPT,
      { workdays: ids }
    )
    return data
  }
  async function declineWorkdaysToReview(ids: number[]) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_TOREVIEW_DECLINE,
      { workdays: ids }
    )
    return data
  }
  const { mutate: acceptWorkdays } = useMutation(acceptWorkdaysToReview, {
    onSuccess: () => {
      setNotification({
        message: `${t('workdays')} ${t('accept')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.workdaysToReview])
    },
  })
  const { mutate: declineWorkdays } = useMutation(declineWorkdaysToReview, {
    onSuccess: () => {
      setNotification({
        message: `${t('workdays')} ${t('decline')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.workdaysToReview])
    },
  })
  async function getPkupsToReview(): Promise<IPkupReports[]> {
    const { data }: AxiosResponse = await apiRequest(APIRoutes.PKUP_GET_ALL)
    return data
  }

  const { data: workdaysToReview = fallbackWorkdays } = useQuery(
    queryKeys.workdaysToReview,
    getWorkdaysToReview
  )

  const { data: pkupsToReview = fallbackPkups } = useQuery(
    queryKeys.pkupsToReview,
    getPkupsToReview
  )

  return {
    acceptWorkdays,
    declineWorkdays,
    pkupsToReview,
    workdaysToReview,
  }
}
