import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IRevisions } from 'hooks/pkup/types'

export const useRevisions = (applicationId?: number) => {
  const fallback: IRevisions[] = []

  async function getRevisionsPkup(): Promise<IRevisions[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_GET_REVISION,
      {},
      { id: applicationId }
    )
    return data
  }

  const { data: revisionsList = fallback, refetch } = useQuery(
    queryKeys.revisionsList,
    getRevisionsPkup
  )
  useEffect(() => {
    refetch()
  }, [applicationId])

  return {
    revisionsList,
  }
}
