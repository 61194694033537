import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

const deleteSingleCustomerProject = async (id: number | null | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PROJECTS_DELETE,
    {},
    { id }
  )
  return data
}
export const useDeleteCustomerProject = () => {
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const { mutate: deleteCustomerProject } = useMutation(
    deleteSingleCustomerProject,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('project')} ${t('delete')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.customer])
      },
    }
  )
  return { deleteCustomerProject }
}
