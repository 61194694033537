import { styled, Box } from '@mui/material'

export const StyledBadge = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: 5,
  width: 'fit-content',
  paddingBlock: 3,
  paddingInline: 5,
  fontSize: '12px',
  marginInline: 5,
}))
