import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

interface IWorkdaysInMonth {
  hours: number
  month: string
}
const getWorkdaysInMonth = async (): Promise<IWorkdaysInMonth[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.WORKDAYS_HOURS_IN_MONTH,
    {},
    {}
  )
  return data
}

export const useGetWorkdaysInMonth = () => {
  const fallback: IWorkdaysInMonth[] = []

  const { data: workdaysInMonth = fallback } = useQuery(
    queryKeys.workdaysInMonth,
    () => getWorkdaysInMonth()
  )

  return workdaysInMonth
}
