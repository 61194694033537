import * as yup from 'yup'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'
import { IAgreements } from 'hooks/types'
import { useGetAllCustomers } from 'hooks/customers'
import { useGetAllProjects } from 'hooks/projects'

import { findCustomer, findProject, agreementsList } from '../utils'

interface ICustomerProjectData {
  customerCode: string
  customerName: string
  paid: boolean
  isProductive: boolean
  isAllocated: boolean
  isHoliday: boolean
  needApproval: boolean
  isWorking: boolean
  isAssignableToProject: boolean
  customer: string
  agreements: IAgreements[] | []
  project: string
}

export const useAddWorkType = () => {
  const { handleCloseModal, setNotification } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation(['systemWorkTypes', 'handleMessage'])
  const customersList = useGetAllCustomers()
  const projectList = useGetAllProjects()
  const initialValues = {
    customerCode: '',
    customerName: '',
    paid: false,
    isProductive: false,
    isAllocated: false,
    isHoliday: false,
    needApproval: false,
    isWorking: true,
    isAssignableToProject: true,
    customer: t('modals.none'),
    agreements: [],
    project: t('modals.none'),
  }
  const addWorkType = async (workData: ICustomerProjectData) => {
    const {
      agreements,
      customerCode,
      customerName,
      customer,
      project,
      isAllocated,
      isAssignableToProject,
      isHoliday,
      isProductive,
      isWorking,
      needApproval,
      paid,
    } = workData
    const workTypeData = {
      agreementId: agreementsList(agreements),
      customerId: findCustomer(customersList, customer)
        ? findCustomer(customersList, customer).id
        : 0,
      projectId: findProject(projectList, project)
        ? findProject(projectList, project)?.id
        : 0,
      code: customerCode,
      name: customerName,
      isAllocated,
      isAssignableToProject,
      isHoliday,
      isProductive,
      isWorking,
      needApproval,
      paid,
    }
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKTYPE_CREATE,
      workTypeData
    )
    return data
  }
  const { mutate: addNewWorkType } = useMutation(addWorkType, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('workType', { ns: 'handleMessage' })}  ${t('add', {
          ns: 'handleMessage',
        })})`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
    },
  })

  const validationSchema = yup.object({
    customerCode: yup.string().min(2).required(t('modals.form.required1')),
    customerName: yup.string().min(2).required(t('modals.form.required2')),
  })

  return { addNewWorkType, initialValues, validationSchema }
}
