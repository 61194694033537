import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useEffect } from 'react'
import { IUsers } from 'hooks/types'

export interface ILocationUser extends IUsers {
  UserLocation?: {
    LocationId: number
    UserId: number
    createdAt: Date
    deletedAt: null
    id: number
    isDirector: boolean
    locationId: number
    revision: number
    updatedAt: Date | null
    userId: number
  }
}
export interface ILocation {
  createdAt?: Date
  deletedAt?: Date | null
  id?: number
  name?: string
  updatedAt?: Date | null
  users?: ILocationUser[]
}
async function removeLocation(locationId: number): Promise<ILocation[]> {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.LOCATIONS_DELETE,
    {},
    { id: locationId }
  )
  return data
}

export const useLocationsList = (locationId?: number) => {
  const fallbackLocationsList: ILocation[] = []
  const { t } = useTranslation('handleMessage')

  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()

  async function getLocations(): Promise<ILocation[]> {
    const { data }: AxiosResponse = await apiRequest(APIRoutes.LOCATIONS_GETALL)
    return data
  }

  async function addNewLocation(name: ILocation): Promise<ILocation[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.LOCATIONS_CREATE,
      name
    )
    return data
  }

  async function updateLocation(name: ILocation): Promise<ILocation[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.LOCATIONS_UPDATE,
      name,
      { id: locationId }
    )
    return data
  }
  const { mutate: addLocation } = useMutation(addNewLocation, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('location')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.locationsList])
    },
  })

  const { mutate: deleteLocation } = useMutation(removeLocation, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('location')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.locationsList])
    },
  })

  const { mutate: editLocation } = useMutation(updateLocation, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('location')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.locationsList])
    },
  })
  const {
    data: locationsList = fallbackLocationsList,
    refetch: refetchLocationsList,
  } = useQuery(queryKeys.locationsList, getLocations)

  useEffect(() => {
    refetchLocationsList()
  }, [locationId])
  return {
    locationsList,
    location,
    addLocation,
    deleteLocation,
    editLocation,
  }
}
