import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { Box, Button, Grid } from '@mui/material'
import { CustomizedDataPicker } from 'components/common'

import { useAddHolidays } from './utils'

const AddHoliday: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['holidays', 'buttons'])

  const { createNewHoliday, validationSchema } = useAddHolidays()

  return (
    <Box>
      <Formik
        initialValues={{ holidayName: '', date: new Date() }}
        onSubmit={(values, { setSubmitting }) => {
          createNewHoliday(values)
          setSubmitting(false)
        }}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form>
            <Grid container my={2} spacing={1}>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  component={CustomizedDataPicker}
                  name="date"
                  label={t('modal.add.label1')}
                  inputFormat="dd/MM/yyyy"
                  error={errors.date}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  name="holidayName"
                  label={t('modal.add.label2')}
                />
              </Grid>
            </Grid>

            <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default AddHoliday
