import { useTranslation } from 'react-i18next'

export interface IFields {
  id: string | undefined
  label: string
  name: string
  type?: string
  value?: string | undefined
  error?: string | undefined
  touch?: boolean | undefined
  shrinkValue?: string
}

export const usePersonalFields = () => {
  const { t } = useTranslation(['profile', 'userEditPage'])
  const personalFields: IFields[] = [
    {
      id: '1',
      label: t('userInformation.form.label1'),
      name: 'firstName',
      type: 'text',
    },
    {
      id: '2',
      label: t('userInformation.form.label2'),
      name: 'lastName',
      type: 'text',
    },
    {
      id: '3',
      label: t('userInformation.form.label3'),
      name: 'companyPhoneNumber',
      type: 'text',
    },
    {
      id: '4',
      label: t('userInformation.form.label4'),
      name: 'privatePhoneNumber',
      type: 'text',
    },
    {
      id: '5',
      label: t('userInformation.form.label5'),
      name: 'privateEmail',
      type: 'text',
    },
  ]

  const userPersonalFields: IFields[] = [
    ...personalFields,
    {
      id: '6',
      label: t('userInformation.form.label6', { ns: 'userEditPage' }),
      name: 'email',
      type: 'email',
    },
    {
      id: '7',
      label: t('userInformation.form.label7', { ns: 'userEditPage' }),
      name: 'probationPeriod',
      type: 'number',
    },
    {
      id: '8',
      label: t('userInformation.form.label8', { ns: 'userEditPage' }),
      name: 'maxHolidaysDays',
      type: 'number',
    },
    {
      id: '9',
      label: t('userInformation.form.label9', { ns: 'userEditPage' }),
      name: 'jobPosition',
      type: 'text',
    },
  ]

  const userPasswordFields: IFields[] = [
    {
      id: '22',
      label: t('userInformation.form.label7'),
      name: 'password',
      type: 'password',
    },
    {
      id: '23',
      label: t('userInformation.form.label8'),
      name: 'repeatNewPassword',
      type: 'password',
    },
  ]
  const passwordFields: IFields[] = [
    {
      id: '21',
      label: t('userInformation.form.label6'),
      name: 'currentPassword',
      type: 'password',
    },
    ...userPasswordFields,
  ]
  return {
    personalFields,
    userPersonalFields,
    userPasswordFields,
    passwordFields,
  }
}
