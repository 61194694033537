import { Typography } from '@mui/material'
import { AllocationBadge } from 'components/common'
import { useTranslation } from 'react-i18next'

import { IAllocations } from '../../types'

interface IAllocationsDateProps {
  allocations: IAllocations[]
  date: string
}

const AllocationsDate: React.FC<IAllocationsDateProps> = ({
  allocations,
  date,
}): JSX.Element => {
  const { t } = useTranslation('reportAllocations')

  const findAllocations = () => {
    const allocation = allocations.find((data) => data.date === date)
    return allocation
  }
  if (allocations.find((data) => data.date === date)) {
    const allocationsDetails = [
      {
        hours: findAllocations()?.allPlannedHours || 0,
        tooltip: t('allocations.plannedHours'),
        color: 'primary.dark',
      },
      {
        hours: findAllocations()?.allUsedWorkingHours || 0,
        tooltip: t('allocations.workingHours'),
        color: 'primary.medium',
      },
      {
        hours: findAllocations()?.FTE || 0,
        tooltip: t('allocations.fte'),
        color: 'primary.light',
      },
    ]
    return <AllocationBadge details={allocationsDetails} center={true} />
  } else {
    return <Typography align="center">--</Typography>
  }
}

export default AllocationsDate
