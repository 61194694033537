import { useAllUsers } from 'hooks/useAllUsers'
import { useGetAllWorktypes } from 'hooks/worktypes/useGetAllWorktypes'
import { useState } from 'react'

import { IInitialValue } from './types'

export function useReportWorkdays() {
  const allUsers = useAllUsers()
  const allWorktypes = useGetAllWorktypes()
  const [pageSize, setPageSize] = useState(10)
  const users = allUsers?.map((user) => {
    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName} (${user.email})`,
    }
  })
  const workTypes = allWorktypes?.map((worktype) => {
    return {
      id: worktype.id,
      name: `${worktype.name}`,
    }
  })

  const initialValues: IInitialValue = {
    users: [],
    workTypes: [],
    year: new Date(),
  }

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize)
  }

  return {
    initialValues,
    users,
    workTypes,
    handlePageSizeChange,
    pageSize,
  }
}
