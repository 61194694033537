import { Field } from 'formik'
import { Box } from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'

const LocationForm: React.FC = (): JSX.Element => {
  const { t } = useTranslation('systemLocations')

  const groupFields: IFields[] = [
    {
      id: '1',
      label: `${t('modal.add.label1')}`,
      name: 'name',
      type: 'text',
    },
  ]
  return (
    <Box mb={2}>
      {groupFields.map((field) => (
        <Field
          fullWidth
          key={field.id}
          component={TextField}
          name={field.name}
          type={field.type}
          label={field.label}
          variant="outlined"
          margin="normal"
          size="small"
        />
      ))}
    </Box>
  )
}

export default LocationForm
