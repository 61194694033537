import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { queryKeys } from 'react-query/constants'

interface IUpdateUser {
  firstName?: string
  lastName?: string
  companyPhoneNumber?: string
  privatePhoneNumber?: string
  privateEmail?: string
  email?: string
  probationPeriod?: number
  maxHolidaysDays?: number
  jobPosition?: string
  password?: string
  isActive?: boolean
}

const updateSingleUser = async (updateData: IUpdateUser, id: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.USERS_UPDATE,
    updateData,
    {
      id: id,
    }
  )
  return data
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')
  const { id } = useParams()

  const { mutate: updateUser } = useMutation(
    (updateData: IUpdateUser) => updateSingleUser(updateData, Number(id)),
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('user')} ${t('update')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        void queryClient.invalidateQueries([queryKeys.userOne])
        void queryClient.invalidateQueries([queryKeys.history])
      },
    }
  )

  return updateUser
}
