import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

export const useSaveProfile = (profile, bioId, educationIds, projectIds) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')
  const { setNotification, handleCloseModal } = useNotifications()

  const editCVProfile = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_PROFILE_UPDATE,
      {
        bioId: Number(bioId),
        educationIds: educationIds.map((education) => Number(education)),
        projectIds: projectIds.map((project) => Number(project)),
      },
      { profileId: profile.id }
    )
    return data
  }

  const { mutate: updateCVProfile } = useMutation(editCVProfile, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('cv')} ${t('save')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdProfile])
    },
  })
  return updateCVProfile
}
