import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Box,
  Container,
  Fade,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useHrDashboard } from 'pages/HrDashboard/hooks'
import { HeaderTab } from 'views/LayoutUI/components'
import { EmptyArrayCard, TableComponent } from 'components/common'
import { IUsers } from 'hooks/types'
import { useAllUsers } from 'hooks/useAllUsers'
import InfoIcon from '@mui/icons-material/Info'
import { useStatus } from 'hooks/pkup'
import { states } from 'hooks/pkup/useStatus'
import palette from 'theme/palette'
import { lightenDarkenColor } from 'utils/lightenDarkenColors'
import { timeoutAnimation } from 'utils'

import { usePkupsWithFilter } from './hooks/usePkupsWithFilter'
import { StyledFilterButton } from './index.style'

const PkupsDashboard: React.FC = (): JSX.Element => {
  const { pkupsToReview } = useHrDashboard()
  const { getPkupStatus, statusBox } = useStatus()
  const navigate = useNavigate()
  const { t } = useTranslation('pkupsDashboard')
  const [selectedState, setSelectedState] = useState(0)
  const [selectedUser, setSelectedUser] = useState<IUsers | null>(null)
  const filteredPkupsList = usePkupsWithFilter(selectedUser, selectedState)
  const allUsers = useAllUsers()
  const fetchPkupsWithFilter = () => {
    if (selectedUser === null) {
      if (selectedState === 0) {
        return pkupsToReview
      } else {
        return pkupsToReview.filter((pkup) => pkup.state === selectedState)
      }
    } else {
      return filteredPkupsList
    }
  }
  const columns = [
    '#',
    `${t('table.label1')}`,
    `${t('table.label2')}`,
    `${t('table.label3')}`,
    `${t('table.label4')}`,
    `${t('table.label5')}`,
    '',
  ]
  const rows: GridRowsProp = fetchPkupsWithFilter()?.map((pkup) => {
    return {
      id: pkup.id,
      title: pkup.title,
      period: pkup.period,
      created: (
        <Typography
          variant="subtitle1"
          sx={{ whiteSpace: 'pre-line', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          {pkup.createdAt.replace(' ', '\n')}
        </Typography>
      ),
      status: statusBox(pkup),
      attachments: pkup.attachmentsCount,
      button: (
        <Tooltip title={t('table.tooltip1') as string}>
          <IconButton onClick={() => navigate(`/pkups-dashboard/${pkup.id}`)}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      ),
    }
  })

  return (
    <Container>
      <HeaderTab translate="pkupsDashboard" />
      <Paper
        sx={{
          paddingLeft: '1rem',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Autocomplete
          sx={{ width: '35rem', marginRight: '1rem' }}
          id="tags-standard"
          filterSelectedOptions
          options={allUsers}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          onChange={(_, value) => {
            setSelectedUser(value)
          }}
          renderInput={(params) => (
            <TextField {...params} margin="normal" label={t('filter.label1')} />
          )}
        />
        <Box sx={{ width: '47rem', margin: '1rem 0', display: 'flex' }}>
          {states.map((state) => (
            <StyledFilterButton
              key={state.id}
              onClick={() => setSelectedState(state.id)}
              stateid={state.id}
              selectedstate={selectedState}
              bcgcolor={getPkupStatus(state.id)?.color}
              hov={lightenDarkenColor(getPkupStatus(state.id)?.color, -30)}
            >
              {state.text}
            </StyledFilterButton>
          ))}
          <StyledFilterButton
            key={0}
            onClick={() => setSelectedState(0)}
            stateid={0}
            selectedstate={selectedState}
            bcgcolor={palette.info.main}
          >
            {t('filter.button1')}
          </StyledFilterButton>
        </Box>
      </Paper>
      {fetchPkupsWithFilter().length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent rows={rows} columns={columns} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
    </Container>
  )
}
export default PkupsDashboard
