const USER_LOCAL_STORAGE_KEY = 'userToken'
const OTHER_TOKEN_KEY = 'otherToken'

export function getStoredUser(): string | null {
  const storedUser = localStorage.getItem(USER_LOCAL_STORAGE_KEY)
  return storedUser ? storedUser : null
}

export function setStoredUser(token: string): void {
  window.localStorage.setItem(USER_LOCAL_STORAGE_KEY, token)
}

export function clearStoredUser(): void {
  window.localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
}

export function setLoginAsOtherToken(token: string): void {
  sessionStorage.setItem(OTHER_TOKEN_KEY, token)
}

export function logOutAsOther(): void {
  window.sessionStorage.removeItem(OTHER_TOKEN_KEY)
}

export function getTokenOther(): string | null {
  const storedOtherUser = sessionStorage.getItem(OTHER_TOKEN_KEY)
  return storedOtherUser ? storedOtherUser : null
}
