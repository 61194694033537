import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Tooltip } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { PkupContext } from 'pages/Pkup/context/PkupContext'
import {
  EmptyArrayCard,
  TableComponent,
  useNotifications,
} from 'components/common'
import { useStatus, useSupervisor } from 'hooks/pkup'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'

import { usePkupReports } from '../../hooks/usePkupReports'

const PkupReportsTable: React.FC = (): JSX.Element => {
  const { t } = useTranslation('reportPkup')
  const { date, setSelectedReport } = useContext(PkupContext)
  const pkupReports = usePkupReports(date)
  const { getSupervisor } = useSupervisor()
  const { handleOpenModal } = useNotifications()
  const { statusBox } = useStatus()
  const columns = [
    '#',
    `${t('table.cell1')}`,
    `${t('table.cell2')}`,
    `${t('table.cell3')}`,
    `${t('table.cell4')}`,
    `${t('table.cell5')}`,
    ' ',
  ]
  const buttonsArr = [
    {
      title: `${t('table.tooltip1')}`,
      icon: <VisibilityIcon />,
      modal: 'showMoreApplication',
    },
    {
      title: `${t('table.tooltip2')}`,
      icon: <EditIcon />,
      modal: 'revisions',
    },
  ]
  const rows: GridRowsProp = pkupReports.map((report) => {
    return {
      id: report.id,
      from: getSupervisor(report.supervisorId).name,
      title: report.title,
      description: report.description,
      period: report.period,
      status: statusBox(report),
      buttons: (
        <Box
          sx={{ display: 'flex', justifyContent: 'right' }}
          onClick={() => setSelectedReport(report)}
        >
          {buttonsArr.map((button) => (
            <Tooltip
              title={button.title as string}
              key={button.title}
              onClick={() => handleOpenModal(button.modal as string)}
            >
              <IconButton>{button.icon}</IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  })

  return (
    <>
      {pkupReports.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
    </>
  )
}
export default PkupReportsTable
