import {
  Box,
  Container,
  Button,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  EmptyArrayCard,
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
} from 'components/common'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderTab } from 'views/LayoutUI/components'
import { DefaultModal } from 'views/LayoutUI/modals'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useGetAllProjects } from 'hooks/projects'
import { useGetAllCustomers } from 'hooks/customers'
import { ISelectingModal } from 'hooks/types'
import { GridRowsProp } from '@mui/x-data-grid'
import { timeoutAnimation } from 'utils'

import { IconsLegend, SwitchButton } from './components'
import { AddNewWorkType, EditWorkType } from './modals'
import { useArrays, useDeleteWorkType } from './hooks'

const WorkTypes = () => {
  const { t } = useTranslation(['systemWorkTypes', 'buttons'])
  const { user } = useAuth()
  const projectList = useGetAllProjects()
  const customersList = useGetAllCustomers()
  const { handleOpenModal, modalName } = useNotifications()
  const deleteWorkType = useDeleteWorkType()
  const [workTypeId, setWorkTypeId] = useState<number | null>(null)
  const { icons, buttonsArr } = useArrays()
  const handleClick = (workTypeId, title) => {
    setWorkTypeId(workTypeId)
    handleOpenModal(title)
  }
  const columns = [
    '#',
    `${t('table.cell2')}`,
    `${t('table.cell3')}`,
    `${t('table.cell4')}`,
    `${t('table.cell5')}`,
    `${t('table.cell6')}`,
    ...icons,
    '',
  ]

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'Delete':
        return {
          component: (
            <ModalConfirm
              content={<Typography>{t('modals.modal1')}</Typography>}
              deleteFunction={() => deleteWorkType(workTypeId)}
            />
          ),
          title: t('modals.title1'),
        }
      case 'Edit':
        return {
          component: <EditWorkType workTypeId={workTypeId} />,
          title: t('modals.title2'),
        }
      case 'addNewWorkType':
        return {
          component: <AddNewWorkType />,
          title: t('modals.title3'),
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('default')}`,
        }
    }
  }

  const rows: GridRowsProp = user?.system?.workTypes?.map((workType) => {
    const agreements = workType?.agreements?.map(({ id, type }) => (
      <Typography
        sx={{
          textAlign: 'center',
        }}
        key={id}
      >
        {type}
      </Typography>
    ))
    const customers = customersList?.find(
      ({ id }) => id === workType.customerId
    )

    const projects = projectList?.find(({ id }) => id === workType.projectId)

    return {
      id: workType.id,
      code: workType.code,
      name: workType.name,
      customer: customers?.name,
      project: projects?.name,
      agreements: (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            {workType?.agreements?.slice(0, 1).map(({ id, type }) => (
              <Typography
                variant="h4"
                key={id}
                sx={{
                  textAlign: 'center',
                }}
              >
                {type}
              </Typography>
            ))}
          </Box>
          {workType?.agreements?.length > 1 && (
            <Tooltip title={agreements.slice(1)}>
              <IconButton>
                <MoreHorizIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
      isPaid: (
        <SwitchButton id={workType.id} option={workType.paid} name="paid" />
      ),
      isProductive: (
        <SwitchButton
          id={workType.id}
          option={workType.isProductive}
          name="isProductive"
        />
      ),
      isAllocated: (
        <SwitchButton
          id={workType.id}
          option={workType.isAllocated}
          name="isAllocated"
        />
      ),
      isHoliday: (
        <SwitchButton
          id={workType.id}
          option={workType.isHoliday}
          name="isHoliday"
        />
      ),
      isNeed: (
        <SwitchButton
          id={workType.id}
          option={workType.needApproval}
          name="needApproval"
        />
      ),
      isWorking: (
        <SwitchButton
          id={workType.id}
          option={workType.isWorking}
          name="isWorking"
        />
      ),
      isAssignable: (
        <SwitchButton
          id={workType.id}
          option={workType.isAssignableToProject}
          name="isAssignableToProject"
        />
      ),
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map(({ title, icon }) => (
            <Tooltip title={title as string} key={title}>
              <IconButton
                onClick={() => {
                  handleClick(workType.id, title)
                }}
              >
                {icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  }) as []
  return (
    <Container>
      <HeaderTab translate="systemWorkTypes" id={t('header.id')} />
      <Box>
        <Button
          sx={{ marginBottom: '10px' }}
          onClick={() => handleOpenModal('addNewWorkType')}
        >
          {t('buttons.button1')}
        </Button>
        <IconsLegend />
      </Box>
      {rows.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent stickyHeader columns={columns} rows={rows} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}

export default WorkTypes
