import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Grid,
} from '@mui/material'
import { Formik, Field } from 'formik'
import { TextField } from 'formik-mui'
import { useAuth } from 'hooks/useAuth'
import { isUserPermitted, permissionList } from 'services'

import {
  useUserBio,
  useUserEdu,
  useUserProfile,
  useUserProject,
} from '../../components/UserCV/hooks'
import { useFormikPosition } from '../hooks/useFormikPosition'

import { StyledForm } from './index.style'
interface ICategories {
  id: number
  name: string | undefined
}
interface AddNewPositionToCVProps {
  userId: number
}
const AddNewPositionToCV: React.FC<AddNewPositionToCVProps> = ({
  userId,
}): JSX.Element => {
  const { t } = useTranslation(['userCV', 'buttons'])
  const { user } = useAuth()

  const categories: ICategories[] = [
    {
      id: 1,
      name: isUserPermitted(user, [permissionList.CV_CREATE])
        ? 'bio'
        : undefined,
    },
    {
      id: 2,
      name: isUserPermitted(user, [permissionList.CV_CREATE])
        ? 'education'
        : undefined,
    },
    {
      id: 3,
      name: isUserPermitted(user, [permissionList.CV_MANAGE_PROFILE])
        ? 'profile'
        : undefined,
    },
    {
      id: 4,
      name: isUserPermitted(user, [permissionList.CV_CREATE])
        ? 'projects'
        : undefined,
    },
  ]
  const [category, setCategory] = useState('')
  const handleSelectCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string)
  }
  const { CVSchema, selectForm } = useFormikPosition()

  const initialValues = {
    title: '',
    description: '',
    stack: '',
    type: '',
    years: '2010-2015',
    grade: ' BSC / MSC',
    place: '',
  }

  const [values, setValues] = useState<{ [key: string]: string | undefined }>(
    initialValues
  )
  const { addEdu } = useUserEdu(
    userId,
    values.grade,
    values.place,
    values.years
  )
  const { addBio } = useUserBio(userId, values.title, values.description)
  const { addProject } = useUserProject(
    userId,
    values.title,
    values.description,
    values.stack
  )
  const { addProfile } = useUserProfile(userId, values.type)
  const addFunction = (category: string) => {
    switch (category) {
      case 'bio':
        return addBio()
      case 'education':
        return addEdu()
      case 'profile':
        return addProfile()
      case 'projects':
        return addProject()
      default:
        return () => {
          throw new Error('error text')
        }
    }
  }

  return (
    <>
      <Typography variant="subtitle1">
        {t('cv.modal.addNewPositionToCV.text1')}
      </Typography>
      <FormControl fullWidth sx={{ margin: '1rem 0' }}>
        <InputLabel id="demo-simple-select-label">
          {t('cv.modal.addNewPositionToCV.label1')}
        </InputLabel>
        <Select
          id="selectCategory"
          value={category}
          label={t('cv.modal.addNewPositionToCV.label1')}
          onChange={(e) => handleSelectCategory(e)}
        >
          {categories.map((field) => (
            <MenuItem key={field?.id} value={field?.name}>
              {field?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {category && (
        <Typography variant="subtitle1">
          {t('cv.modal.addNewPositionToCV.text2')} {category}
        </Typography>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={CVSchema(category)}
        onSubmit={(values, { setSubmitting }) => {
          setValues(values)
          addFunction(category)
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <StyledForm>
            {selectForm(category).map((field) => (
              <Field
                fullWidth
                key={field.id}
                component={TextField}
                name={field.name}
                type={field.type}
                label={field.label}
                margin="normal"
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ))}
            <Grid
              container
              item
              rowSpacing={2}
              sx={{
                width: '100%',
              }}
            >
              <Grid item xs={6}>
                <Button sx={{ marginTop: '1rem' }} type="submit">
                  {t('save', { ns: 'buttons' })}
                </Button>
              </Grid>
              <br />
            </Grid>
            {isSubmitting && <LinearProgress />}
          </StyledForm>
        )}
      </Formik>
    </>
  )
}
export default AddNewPositionToCV
