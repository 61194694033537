import { Button } from '@mui/material'
import { CloseButton, useNotifications } from 'components/common'
import { useCalendarContext } from 'pages/Calendar/context'
import { useTranslation } from 'react-i18next'

import { useStickyBar } from '../StickyBar/utils'
import { DatePickerComponent } from '../common'

const AddWorkdaysBar = () => {
  const { t } = useTranslation(['calendar', 'buttons'])

  const { setDeleteMode, setCopyMode } = useCalendarContext()
  const { handleCloseBar } = useNotifications()
  const {
    startValue,
    setStartValue,
    endValue,
    setEndValue,
    addButtonClickHandler,
  } = useStickyBar()

  return (
    <>
      <DatePickerComponent
        label={t('addbar.label1')}
        value={startValue}
        setValue={setStartValue}
      />
      <DatePickerComponent
        label={t('addbar.label2')}
        value={endValue}
        setValue={setEndValue}
      />
      <Button
        onClick={addButtonClickHandler}
        disabled={
          startValue !== null &&
          endValue !== null &&
          startValue > endValue &&
          true
        }
      >
        {t('add', { ns: 'buttons' })}
      </Button>
      <CloseButton
        handleOnClick={() => {
          setDeleteMode(false)
          setCopyMode(false)
          handleCloseBar()
        }}
      />
    </>
  )
}

export default AddWorkdaysBar
