import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { FormikValues } from 'formik'
import {
  useDownloadReportCustomer,
  useDownloadReportProject,
} from 'hooks/reportCustomers'

import {
  InitialValues,
  ReportProjectData,
  ReportCustomerData,
  TStringDate,
} from '../types'

export function useReport(data: string[]) {
  const [fileName, setFileName] = useState('')
  const { t } = useTranslation('reportCustomers, handleMessage')

  const { downloadProjectReport, downloadProjectsReport } =
    useDownloadReportProject(fileName)

  const { downloadCustomerReport, downloadCustomersReport } =
    useDownloadReportCustomer(fileName)

  const initialValues: InitialValues = {
    value: '0',
    title: '',
    startValue: new Date(),
  }

  const makeReportProject = (date: TStringDate, value: string) => {
    const reportData = [
      `${t('options.option', { ns: 'reportCustomers' })}`,
      ...data,
    ]
    let fileName = `${reportData[parseInt(value)]} - ${date}.xlsx`
    setFileName(fileName)
    let urlData: ReportProjectData = {
      selectedProjectCode: reportData[parseInt(value)],
      date: date,
    }
    if (value === '0') {
      fileName = `${t('name.name1', { ns: 'reportCustomers' })} - ${date}.zip`
      urlData = { date: date }
      setFileName(fileName)
      downloadProjectsReport(urlData)
    } else {
      downloadProjectReport(urlData)
    }
  }

  const makeReportCustomer = (date: TStringDate, value: string) => {
    const reportData = [
      `${t('options.option', { ns: 'reportCustomers' })}}`,
      ...data,
    ]
    let fileName = `${reportData[parseInt(value)]} - ${date}.xlsx`
    setFileName(fileName)
    let urlData: ReportCustomerData = {
      customer: reportData[parseInt(value)],
      date: date,
    }

    if (value === '0') {
      urlData = { date }
      fileName = `${t('name.name2', { ns: 'reportCustomers' })} - ${date}.zip`
      setFileName(fileName)
      downloadCustomersReport(urlData)
    } else {
      downloadCustomerReport(urlData)
    }
  }

  const submitHandler = (values: FormikValues) => {
    const date = values.startValue
      ? format(new Date(values.startValue), 'yyyy-MM')
      : values.startValue
    if (values.title === `${t('options.option2', { ns: 'reportCustomers' })}`) {
      makeReportProject(date, values.value.toString())
    } else {
      makeReportCustomer(date, values.value.toString())
    }
  }

  return {
    initialValues,
    submitHandler,
  }
}
