import { Tooltip, Box } from '@mui/material'

import { StyledBadge } from './index.style'

interface BadgeProps {
  details: { tooltip: string; hours: number; color: string }[]
  center?: boolean
}
const AllocationBadge: React.FC<BadgeProps> = ({
  details,
  center,
}): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent={center ? 'center' : 'left'}
      flexWrap="wrap"
    >
      {details.map(({ hours, tooltip, color }, index) => (
        <Tooltip title={tooltip} key={index} arrow>
          <StyledBadge sx={{ backgroundColor: color }}>{hours}</StyledBadge>
        </Tooltip>
      ))}
    </Box>
  )
}

export default AllocationBadge
