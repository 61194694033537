import { useTranslation } from 'react-i18next'
import { GridRowsProp } from '@mui/x-data-grid'
import { EmptyArrayCard, TableComponent } from 'components/common'

import { selectProjects } from '../SelectProjects'

const ProjectsWithoutAllocations = ({
  value,
}: {
  value: string
}): JSX.Element => {
  const { t } = useTranslation('dashboard')

  const rows: GridRowsProp = selectProjects(value)

  const columns = [
    `${t('tabPanels.panel2.name')}`,
    `${t('tabPanels.panel2.customer')}`,
    `${t('tabPanels.panel2.previousAllocations')}`,
    '',
  ]

  return (
    <>
      {rows.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard message={t('tabPanels.panel2.message')} />
      )}
    </>
  )
}

export default ProjectsWithoutAllocations
