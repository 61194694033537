import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Box, Button, LinearProgress, TextField as TF } from '@mui/material'
import { TextField } from 'formik-mui'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { useAddTimeframes } from 'hooks/customers'
import { IUser } from 'pages/Customers/types'
import { NOTIFICATION, useNotifications } from 'components/common'
import { DateType } from 'hooks/types'

interface IProps {
  user: IUser
}

const AddTimeframes: React.FC<IProps> = ({ user }): JSX.Element => {
  const { t } = useTranslation(['projectManageUser', 'buttons'])
  const { id } = useParams()
  const initialValues = { notes: '' }
  const validationSchema = yup.object({
    notes: yup.string(),
  })
  const [startValue, setStartValue] = useState<DateType>(new Date())
  const [endValue, setEndValue] = useState<DateType>(new Date())

  const { addNewTimeframes } = useAddTimeframes()
  const { setNotification } = useNotifications()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (startValue && endValue && startValue > endValue) {
          setNotification({
            message: t('form.error', { ns: 'projectManageUser' }),
            variant: NOTIFICATION.error,
          })
          setSubmitting(false)
          return
        }
        const timeFramesData = {
          endDate: endValue,
          notes: values.notes,
          startDate: startValue,
          userId: user.id,
          userProjectId: Number(id),
        }
        addNewTimeframes(timeFramesData)
        setSubmitting(false)
      }}
      enableReinitialize
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              mb: '1rem',
            }}
          >
            <Field
              fullWidth
              component={TextField}
              name="notes"
              label={t('form.label3', { ns: 'projectManageUser' })}
              variant="outlined"
              margin="normal"
              size="small"
            />
            <DatePicker
              label={t('form.label4', { ns: 'projectManageUser' })}
              value={startValue}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue: Date | null) => {
                setStartValue(newValue)
              }}
              renderInput={(params) => <TF {...params} />}
            />{' '}
            <DatePicker
              label={t('form.label5', { ns: 'projectManageUser' })}
              value={endValue}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setEndValue(newValue)
              }}
              renderInput={(params) => <TF {...params} />}
            />
          </Box>
          <Button disabled={isSubmitting} onClick={submitForm}>
            {t('save', { ns: 'buttons' })}
          </Button>
          <br />
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default AddTimeframes
