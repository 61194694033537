import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useCalendarContext } from '../../../../context'

interface SelectProjectProps {
  handleChange: (event: SelectChangeEvent, type: string) => void
  selectedProject: string
}

const SelectProject: React.FC<SelectProjectProps> = ({
  handleChange,
  selectedProject,
}) => {
  const { projects } = useCalendarContext()
  const { t } = useTranslation('calendar')
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="selectProjectLabel">
          {t('addWorkdaysModal.selectProject')}
        </InputLabel>
        <Select
          labelId="selectProjectLabel"
          id="selectProjectLabel"
          value={selectedProject}
          label={t('addWorkdaysModal.selectProject')}
          onChange={(e) => handleChange(e, 'select')}
        >
          {projects?.map((project, i) => (
            <MenuItem key={i} value={project?.id}>
              {project.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default SelectProject
