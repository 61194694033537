import { Typography, Box } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import palette from 'theme/palette'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { StyledButton } from 'components/common/SwitchButton/index.style'

import { useCalendarContext } from '../../context'

import { ListWrapper, ListItem } from './index.style'

const WeekendDates = () => {
  const { excludeWeekends, weekends, setExcludeWeekends } = useCalendarContext()
  const { t } = useTranslation('calendar')
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          marginBottom: !excludeWeekends ? '10px' : 0,
        }}
      >
        <Typography variant="h3" sx={{ fontSize: '14px' }}>
          {t('dates.question2')}
        </Typography>
        <StyledButton
          valueistrue={excludeWeekends ? 'true' : 'false'}
          onClick={() => setExcludeWeekends((prev) => !prev)}
        >
          {excludeWeekends ? (
            <ThumbDownIcon color="error" />
          ) : (
            <ThumbUpIcon color="success" />
          )}
        </StyledButton>
      </Box>
      {!excludeWeekends && (
        <>
          <Typography variant="h3" sx={{ fontSize: '12px' }}>
            {t('dates.text1')}
          </Typography>
          <ListWrapper>
            {weekends
              ? weekends.map((day: Date) => (
                  <ListItem
                    color={palette.primary.main}
                    variant="subtitle2"
                    gutterBottom
                    key={day.toString()}
                  >
                    {format(day, 'yyyy-MM-dd')}
                  </ListItem>
                ))
              : null}
          </ListWrapper>
        </>
      )}
    </>
  )
}

export default WeekendDates
