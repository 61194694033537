import { useState } from 'react'
import { Formik, Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { Button, LinearProgress, Grid } from '@mui/material'

import { StyledForm } from '../AddNewPositionToCV/index.style'
import { useFormikPosition } from '../hooks/useFormikPosition'
import {
  useUserBio,
  useUserEdu,
  useUserProject,
} from '../../components/UserCV/hooks'
import { ISelectedOptions } from '../../components/UserCV'

interface EditPositionProps {
  userId: number
  category?: string
  position: ISelectedOptions
}

const EditPosition: React.FC<EditPositionProps> = ({
  userId,
  category,
  position,
}): JSX.Element => {
  const { t } = useTranslation(['userCV', 'buttons'])
  const { CVSchema, selectForm } = useFormikPosition()

  const initialValues = (category?: string) => {
    switch (category) {
      case 'bio':
        return { title: position.title, description: position.description }
      case 'education':
        return {
          years: position.years,
          grade: position.grade,
          place: position.place,
        }
      case 'profile':
        return { type: position.type }
      case 'projects':
        return {
          title: position.title,
          description: position.description,
          stack: position.stack,
        }
      default:
        return {}
    }
  }
  const [values, setValues] = useState<ISelectedOptions>(
    initialValues(category)
  )
  const { editBio } = useUserBio(
    userId,
    values.title,
    values.description,
    position.id
  )
  const { editProject } = useUserProject(
    userId,
    values.title,
    values.description,
    values.stack,
    position?.id
  )
  const { editEdu } = useUserEdu(
    userId,
    values.grade,
    values.place,
    values.years,
    position?.id
  )
  const editFunction = (category?: string) => {
    switch (category) {
      case 'bio':
        return editBio()
      case 'education':
        return editEdu()
      case 'projects':
        return editProject()
      default:
        return () => {
          throw new Error('error text')
        }
    }
  }
  return (
    <>
      <Formik
        initialValues={initialValues(category)}
        validationSchema={category && CVSchema(category)}
        onSubmit={(values, { setSubmitting }) => {
          setValues(values)
          editFunction(category)
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <StyledForm>
            {category &&
              selectForm(category).map((field) => (
                <Field
                  fullWidth
                  key={field.id}
                  component={TextField}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ))}
            <Grid
              container
              item
              rowSpacing={2}
              sx={{
                width: '100%',
              }}
            >
              <Grid item xs={6}>
                <Button sx={{ marginTop: '1rem' }} type="submit">
                  {t('save', { ns: 'buttons' })}
                </Button>
              </Grid>
              <br />
            </Grid>
            {isSubmitting && <LinearProgress />}
          </StyledForm>
        )}
      </Formik>
    </>
  )
}
export default EditPosition
