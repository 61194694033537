import { Box, Typography } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import {
  DetailsTooltip,
  TableComponent,
  useNotifications,
} from 'components/common'

import { useDetails } from './utils'
import { TextLineBox } from './index.style'

const Details = ({ id }: { id: number | null }): JSX.Element => {
  const { businessUnits, newBusinessUnits, findDirectors } = useDetails(id)
  const { t } = useTranslation('dashboard')
  const { userDetailsId, setUserDetailsId } = useNotifications()

  const columns = [
    '#',
    `${t('modal.webApplication.columns.name')}`,
    `${t('modal.webApplication.columns.email')}`,
    `${t('modal.webApplication.columns.joined')}`,
    '',
  ]
  const rows: GridRowsProp = newBusinessUnits
    ? newBusinessUnits?.map((user) => {
        return {
          ...user,
          name: (
            <DetailsTooltip id={userDetailsId}>
              <Box
                onMouseOver={() => setUserDetailsId(user?.id || 1)}
                sx={{
                  cursor: 'pointer',
                  width: 'fit-content',
                }}
              >
                {user.name}
              </Box>
            </DetailsTooltip>
          ),
        }
      })
    : []

  return (
    <Box sx={{ maxHeight: '80vh', overflow: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextLineBox>
          <Typography variant="subtitle1">
            {t('modal.webApplication.director')}:&nbsp;
          </Typography>
          <Typography variant="subtitle2">
            {findDirectors?.length ? (
              findDirectors?.map((value, index) => (
                <span key={value}>
                  {value}
                  {index === findDirectors?.length - 1 ? '' : ', '}
                </span>
              ))
            ) : (
              <span>---</span>
            )}
          </Typography>
        </TextLineBox>
        <TextLineBox>
          <Typography variant="subtitle1">
            {t('modal.webApplication.contact')}:&nbsp;
          </Typography>
          <Typography variant="subtitle2">{businessUnits.email}</Typography>
        </TextLineBox>
        <TextLineBox>
          <Typography variant="subtitle1">
            {t('modal.webApplication.description')}:&nbsp;
          </Typography>
          <Typography variant="subtitle2">
            {businessUnits.description}
          </Typography>
        </TextLineBox>
      </Box>
      <Box sx={{ margin: '0 5px 5px 1px' }}>
        <TableComponent columns={columns} rows={rows} />
      </Box>
    </Box>
  )
}

export default Details
