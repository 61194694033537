import { Box, Container, Button, Fade, Typography } from '@mui/material'
import { TextField } from 'formik-mui'
import { GridRowsProp } from '@mui/x-data-grid'
import {
  DetailsTooltip,
  EmptyArrayCard,
  TableComponent,
  useNotifications,
} from 'components/common'
import { Field, Form, Formik } from 'formik'
import { useGetActivityLog } from 'hooks/systemActivityLog/useGetActivityLog'
import { useTablePagedFunction } from 'hooks/table'
import { ChangeItemsInPage } from 'hooks/table/enum'
import { useTranslation } from 'react-i18next'
import { HeaderTab } from 'views/LayoutUI/components'
import { timeoutAnimation } from 'utils'

const SystemLogActivity = () => {
  const { t } = useTranslation(['systemLogActivity', 'buttons'])
  const { userDetailsId, setUserDetailsId } = useNotifications()
  const { projectGetOneActivityLog, changePagination } = useGetActivityLog()
  const {
    dispatchUC,
    paginationUC,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTablePagedFunction(changePagination)

  const columns = [
    t('table.cell1'),
    t('table.cell2'),
    t('table.cell3'),
    t('table.cell4'),
    t('table.cell5'),
    t('table.cell6'),
  ]

  const rows: GridRowsProp = projectGetOneActivityLog?.data?.map(
    ({ action, changes, author, date, model, documentId }) => {
      const { firstName, lastName } = author
      const historyChanges = changes
        .map(({ value, field }) => {
          const hasChanges = value ? `to '${value}'` : ''
          return `${field} ${hasChanges}`
        })
        .join(', ')

      return {
        date: (
          <Typography variant="h4">
            <>{date}</>
          </Typography>
        ),
        action: action,
        author:
          author.id !== 0 ? (
            <DetailsTooltip id={userDetailsId}>
              <Box>
                <Typography
                  variant="h4"
                  onMouseOver={() => setUserDetailsId(author.id)}
                  sx={{ cursor: 'pointer' }}
                >
                  {firstName} {lastName}
                </Typography>
              </Box>
            </DetailsTooltip>
          ) : (
            <Typography variant="h4">
              {firstName} {lastName}
            </Typography>
          ),

        data: historyChanges,
        entry: model,
        entryId: documentId,
      }
    }
  )

  const initialValues = {
    searchValue: '',
  }
  return (
    <Container>
      <HeaderTab translate="systemLogActivity" id={t('header.id')} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatchUC({
            type: ChangeItemsInPage.MULTI_EDIT_STATE,
            payload: {
              length: paginationUC.length,
              currentPage: paginationUC.currentPage,
              search: values.searchValue,
            },
          })
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',
              }}
            >
              <Field
                fullWidth
                sx={{ flex: 2 }}
                component={TextField}
                label={t('labels.label1')}
                name="searchValue"
                size="small"
              />
              <Button
                disabled={isSubmitting}
                onClick={submitForm}
                size="medium"
              >
                {t('search', { ns: 'buttons' })}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {projectGetOneActivityLog?.data?.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent
              columns={columns}
              rows={rows}
              total={projectGetOneActivityLog.total}
              pagination={true}
              handleChangePage={handleChangePage}
              paginationUC={paginationUC}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={`${t('typographies.typography1')}`} />
      )}
    </Container>
  )
}

export default SystemLogActivity
