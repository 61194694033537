import { Box } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { EmptyArrayCard } from 'components/common'

import { IDisplayAllocations } from '../../selectedAllocations'
import { columns } from '../Columns'
import TableAllocation from '../TableAllocation'

const AllocationInMonth = ({
  allocations,
}: {
  allocations: IDisplayAllocations
}): JSX.Element => {
  const { t } = useTranslation('userEditPage')
  const { currentAllocationsToShow, summary } = allocations
  const rows: GridRowsProp = currentAllocationsToShow

  return (
    <>
      {rows?.length ? (
        <Box>
          <TableAllocation rows={rows} columns={columns} summary={summary} />
        </Box>
      ) : (
        <EmptyArrayCard message={t('allocations.noAllocationsInMonth')} />
      )}
    </>
  )
}

export default AllocationInMonth
