import { Button, Paper, Container, Grid } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  AutoCompleteCheckAll,
  CustomizedDataPicker,
  EmptyArrayCard,
  TableTitle,
} from 'components/common'
import { HeaderTab } from 'views/LayoutUI/components'
import { DataGrid } from '@mui/x-data-grid'

import { useReportWorkdays } from './utils'
import { ColumnsReportWorkdays } from './components'
import { rowsReportWorkdays } from './helpers/RowsReportWorkdays'
import { useDownloadExcel, useGetAllWorkdays } from './hooks'

const ReportWorkdays = () => {
  const { t } = useTranslation(['reportWorkdays', 'buttons'])
  const { initialValues, workTypes, users, pageSize, handlePageSizeChange } =
    useReportWorkdays()
  const columns = ColumnsReportWorkdays()
  const {
    getWorkdays,
    workdaysData,
    reportWorkdaysValues,
    setReportWorkdaysValue,
  } = useGetAllWorkdays(workTypes)
  const rows = rowsReportWorkdays(workdaysData)

  const downloadWorkdaysReport = useDownloadExcel(workTypes)

  return (
    <Container>
      <HeaderTab translate="reportWorkdays" id={t('header.id')} />
      <Paper
        sx={{ padding: '0.625rem 1.25rem', width: 'calc(100% - 2.5rem)' }}
        elevation={3}
      >
        <TableTitle>{t('card.generateTimesheet')}</TableTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            getWorkdays(values)
            setReportWorkdaysValue(values)
          }}
        >
          {({ values }) => (
            <Form>
              <Grid
                container
                direction="row-reverse"
                spacing={1}
                sx={{ marginTop: '10px' }}
              >
                <Grid item xs={6}>
                  <Field
                    component={AutoCompleteCheckAll}
                    name="users"
                    label={t('form.users')}
                    optionsData={users}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={AutoCompleteCheckAll}
                    name="workTypes"
                    label={t('form.types')}
                    optionsData={workTypes}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={!values.users.length || !values.workTypes.length}
                    type="submit"
                    sx={{ width: '100%', height: '100%' }}
                  >
                    {workdaysData.length
                      ? t('updateReport', { ns: 'buttons' })
                      : t('showReport', { ns: 'buttons' })}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={CustomizedDataPicker}
                    label={t('form.year')}
                    name="year"
                    views={['year']}
                    inputFormat="yyyy"
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
      {workdaysData.length ? (
        <>
          <Paper
            style={{
              width: '100%',
              margin: '10px 0',
            }}
          >
            <DataGrid
              autoHeight={true}
              rows={rows}
              columns={columns}
              onPageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 15]}
              disableSelectionOnClick={true}
            />
          </Paper>
          <Button onClick={() => downloadWorkdaysReport(reportWorkdaysValues)}>
            {t('download', { ns: 'buttons' })}
          </Button>
        </>
      ) : (
        <EmptyArrayCard message={t('card.generateReport')} />
      )}
    </Container>
  )
}

export default ReportWorkdays
