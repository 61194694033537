import { useState } from 'react'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'

interface IFteData {
  userProjectId: number
  date: {
    fte: number
    dateS: string
  }
}

const fetchCalculatedFTE = async (fteData: IFteData) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.ALLOCATIONS_CHECKER,
    fteData
  )
  return data
}

export const useAllocationCheck = () => {
  const [allWorkingHours, setAllWorkingHours] = useState<number | null>(null)

  const { mutate: fteCalculation } = useMutation(fetchCalculatedFTE, {
    onSuccess: ({ data }) => {
      setAllWorkingHours(data.hours)
    },
  })
  return { fteCalculation, allWorkingHours }
}
