import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'

import { ILocation } from './useLocationsList'

export const useLocations = (locationId?: number) => {
  const fallbackLocation = {} as ILocation
  const { t } = useTranslation('handleMessage')

  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()

  async function getLocation(): Promise<ILocation> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.LOCATIONS_READ,
      {},
      { id: locationId }
    )
    return data
  }

  async function addUserToLocation(user: {
    userId: number
    isDirector: boolean
  }): Promise<ILocation> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.LOCATIONS_ADD_USER,
      user,
      { id: locationId }
    )
    return data
  }

  async function deleteUserFromLocation(userId: number): Promise<ILocation> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.LOCATIONS_DELETE_USER,
      {},
      { locationId, userId }
    )
    return data
  }
  async function toggleUserRole(userId: number): Promise<ILocation> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.LOCATIONS_TOGGLEDIRECTOR,
      {},
      { locationId, userId }
    )
    return data
  }

  const { mutate: addUser } = useMutation(addUserToLocation, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('user')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.location])
    },
  })

  const { mutate: deleteUser } = useMutation(deleteUserFromLocation, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('user')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.location])
    },
  })
  const { mutate: toggleUser } = useMutation(toggleUserRole, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('userRole')} ${t('change')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.location])
    },
  })

  const { data: location = fallbackLocation, refetch: refetchLocation } =
    useQuery(queryKeys.location, getLocation)

  useEffect(() => {
    refetchLocation()
  }, [locationId])
  return {
    location,
    addUser,
    deleteUser,
    toggleUser,
  }
}
