import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

export const useUserBio = (
  userId: number | undefined,
  title?: string,
  description?: string,
  editId?: number
) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')
  const { setNotification, handleCloseModal } = useNotifications()

  const addBioToUser = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_BIO_CREATE,
      {
        title,
        description,
      },
      { id: userId }
    )
    return data
  }
  const deleteBioFromUser = async (id?: number) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_BIO_DELETE,
      {},
      { id }
    )
    return data
  }
  const editBioFromUser = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_BIO_UPDATE,
      {
        title,
        description,
      },
      { id: editId }
    )
    return data
  }
  const { mutate: addBio } = useMutation(addBioToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('bio')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdBio])
    },
  })
  const { mutate: deleteBio } = useMutation(deleteBioFromUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('bio')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdBio])
    },
  })
  const { mutate: editBio } = useMutation(editBioFromUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('bio')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdBio])
    },
  })
  return { addBio, deleteBio, editBio }
}
