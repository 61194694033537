import {
  Dispatch,
  createContext,
  useContext,
  useState,
  SetStateAction,
  ReactNode,
} from 'react'
import { IApplication, IAuthorsWork, IPkupReports } from 'hooks/pkup/types'

export interface PkupData {
  selectedMonth: number
  selectedYear: number
  date: string
  selectedApplication: IApplication | Record<string, never>
  selectedAuthorsWork: IAuthorsWork | Record<string, never>
  selectedReport: IPkupReports | Record<string, never>
  setSelectedApplication: Dispatch<
    SetStateAction<IApplication | Record<string, never>>
  >
  setSelectedAuthorsWork: Dispatch<
    SetStateAction<IAuthorsWork | Record<string, never>>
  >
  setSelectedReport: Dispatch<
    SetStateAction<IPkupReports | Record<string, never>>
  >
  setSelectedMonth: Dispatch<SetStateAction<number>>
  setSelectedYear: Dispatch<SetStateAction<number>>
}

const defaultApi: PkupData = {
  selectedMonth: new Date().getMonth() + 1,
  selectedYear: new Date().getFullYear(),
  selectedApplication: {},
  selectedReport: {},
  selectedAuthorsWork: {},
  setSelectedReport: () => null,
  setSelectedApplication: () => null,
  setSelectedAuthorsWork: () => null,
  setSelectedMonth: () => null,
  setSelectedYear: () => null,
  date: `${new Date().getFullYear()}/${new Date().getMonth() + 1}`,
}
export type PkupContextValue = typeof defaultApi
export const PkupContext = createContext<PkupData>(defaultApi)

export const PkupProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const date = `${selectedYear}/${selectedMonth}`
  const [selectedApplication, setSelectedApplication] = useState({})
  const [selectedAuthorsWork, setSelectedAuthorsWork] = useState({})
  const [selectedReport, setSelectedReport] = useState({})
  const contextValue = {
    selectedMonth,
    selectedYear,
    selectedApplication,
    selectedAuthorsWork,
    selectedReport,
    setSelectedAuthorsWork,
    setSelectedApplication,
    setSelectedReport,
    setSelectedMonth,
    setSelectedYear,
    date,
  }

  return (
    <PkupContext.Provider value={contextValue}>{children}</PkupContext.Provider>
  )
}

export function usePKUP() {
  return useContext(PkupContext)
}
