import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface IUserData {
  firstName: string
  lastName: string
  email: string
  password: string
}

export const useAddUser = () => {
  const { handleCloseModal, setNotification } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const addUser = async (userData: IUserData) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.USERS_CREATE,
      userData
    )
    return data
  }

  const { mutate: addNewUser } = useMutation(addUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('user')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.users])
    },
  })

  return addNewUser
}
