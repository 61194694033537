import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface ICustomerData {
  code: string
  name: string
}

export const useAddCustomer = () => {
  const { handleCloseModal, setNotification } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const addCustomer = async (customerData: ICustomerData) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CUSTOMERS_CREATE,
      customerData
    )
    return data
  }

  const { mutate: addNewCustomer } = useMutation(addCustomer, {
    onSuccess: (res) => {
      if (res.data.created) {
        handleCloseModal()
        setNotification({
          message: `${t('customer')} ${t('add')}`,
          variant: NOTIFICATION.success,
        })
      } else {
        handleCloseModal()
        setNotification({
          message: `${t('customer')} ${t('tagsError.text3')}`,
          variant: NOTIFICATION.error,
        })
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.customers])
    },
  })

  return {
    addNewCustomer,
  }
}
