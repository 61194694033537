import { useTranslation } from 'react-i18next'
import { Form, Field } from 'formik'
import { Box, Grid, Button, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AutoCompleteCheckAll } from 'components/common'
import { addYears } from 'date-fns'
import { config } from 'config'

import { useReportAllocationContext } from '../../context'

import { useDownloadReportsAllocations } from './utils'

interface IReportAllocationsForm {
  groupBy: string
}

const AllocationForm: React.FC<IReportAllocationsForm> = ({ groupBy }) => {
  const { t } = useTranslation(['reportAllocations', 'buttons'])

  const { handleDates, allocationByCustomer, allocationByUsers, dates } =
    useReportAllocationContext()
  const {
    downloadCustomersReport,
    downloadUsersReport,
    values,
    errors,
    setFieldValue,
    disabledDownload,
    projects,
    userAllocationCustomers,
    customerAllocationUsers,
    users,
    customers,
  } = useDownloadReportsAllocations(groupBy)

  return (
    <Form>
      <Grid container my={0.5} spacing={2} mb={2}>
        <Grid item xs={12} md={8}>
          <Field
            component={AutoCompleteCheckAll}
            name="option"
            label={
              groupBy === 'users'
                ? t('form.labelReportUsers')
                : t('form.labelReportCustomer')
            }
            optionsData={groupBy === 'users' ? users : customers}
            error={errors.option}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            label={t('form.labelQuarter')}
            value={values.quarter}
            inputFormat="yyyy, QQQ"
            onChange={(newValue: Date | null) => {
              handleDates(newValue as Date)
              setFieldValue('quarter', newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
            views={['month', 'year']}
            minDate={config.START_DATE}
            maxDate={addYears(config.START_DATE, config.AMOUNT_OF_YEARS_TO_ADD)}
          />
        </Grid>
      </Grid>
      <Box>
        <Button type="submit">
          {groupBy === 'users'
            ? allocationByUsers?.length
              ? t('updateReport', { ns: 'buttons' })
              : t('showReport', { ns: 'buttons' })
            : allocationByCustomer?.length
            ? t('updateReport', { ns: 'buttons' })
            : t('showReport', { ns: 'buttons' })}
        </Button>
        <Button
          onClick={() =>
            groupBy === 'users'
              ? downloadUsersReport({
                  dates: dates,
                  projects: projects,
                  users: allocationByUsers.map(({ id }) => id),
                  customers: userAllocationCustomers,
                })
              : downloadCustomersReport({
                  dates: dates,
                  projects: projects,
                  users: customerAllocationUsers,
                  customers: allocationByCustomer.map(
                    ({ customerId }) => customerId
                  ),
                })
          }
          disabled={disabledDownload}
          sx={{ marginLeft: 3 }}
        >
          {t('download', { ns: 'buttons' })}
        </Button>
      </Box>
    </Form>
  )
}

export default AllocationForm
