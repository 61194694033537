import { useContext } from 'react'
import { useApplications } from 'pages/Pkup/hooks'
import { PkupContext } from 'pages/Pkup/context/PkupContext'
import { ApplicationForm } from 'components/Pkup'

const AddApplication: React.FC = (): JSX.Element => {
  const { date } = useContext(PkupContext)
  const { addPkup } = useApplications(date)

  const initialValuesAdd = {
    title: '',
    description: '',
    supervisorId: undefined,
    works: [],
    period: new Date(),
  }

  return (
    <ApplicationForm
      initialValues={initialValuesAdd}
      onSubmit={addPkup}
      buttonName="add"
      disabled={false}
    />
  )
}
export default AddApplication
