import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import DeleteIcon from '@mui/icons-material/Delete'
import { permissionList, isUserPermitted } from 'services'
import {
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
} from 'components/common'
import { useDeleteInventory, IEditInventory } from 'hooks/inventory'
import { useAuth } from 'hooks/useAuth'
import { ISelectingModal } from 'hooks/types'
import { DefaultModal } from 'views/LayoutUI/modals'

import RewriteUser from '../RewriteUser'

import { useInventoryFreeItems } from './utils'

const defaultValue = {
  additionalInformation: '',
  id: null,
  inventoryId: null,
  model: '',
  producer: '',
  serialNumber: '',
  userInventoryId: null,
  name: '',
}

const ShowFreeItems: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['inventory', 'buttons'])
  const deleteInventory = useDeleteInventory()
  const [inventory, setInventory] = useState<IEditInventory>(defaultValue)
  const { handleOpenModal, modalName } = useNotifications()
  const { user } = useAuth()
  const freeInventories = useInventoryFreeItems()

  useEffect(() => {
    if (!modalName) {
      setInventory(defaultValue)
    }
  }, [modalName])

  const columns = [
    `${t('modal.showFreeItems.table.cell1')}`,
    `${t('modal.showFreeItems.table.cell2')}`,
    `${t('modal.showFreeItems.table.cell3')}`,
    `${t('modal.showFreeItems.table.cell4')}`,
    `${t('modal.showFreeItems.table.cell5')}`,
    '',
  ]

  const freeInventoriesItems = freeInventories?.map((inventory) => {
    const inventoryItem = {
      name: inventory.name,
      producer: inventory.producer,
      model: inventory.model,
      serialNumber: inventory.serialNumber,
      additionalInformation: inventory.additionalInformation,
      id: inventory.id,
      inventoryId: inventory.InventoryId,
    }
    return {
      name: inventory.name,
      producer: inventory.producer,
      model: inventory.model,
      serialNumber: inventory.serialNumber,
      additionalInformation: inventory.additionalInformation,

      buttons: (
        <Box display="flex">
          {isUserPermitted(user, [
            permissionList.INVENTORY_ASSIGN_ITEM_TO_USER,
          ]) && (
            <Tooltip title={t('modal.showFreeItems.table.button') as string}>
              <IconButton
                onClick={() => {
                  setInventory(inventoryItem)
                  handleOpenModal('assignUser')
                }}
              >
                <PersonAddAltIcon />
              </IconButton>
            </Tooltip>
          )}

          {isUserPermitted(user, [permissionList.INVENTORY_DELETE_ITEM]) && (
            <Tooltip title={t('delete', { ns: 'buttons' }) as string}>
              <IconButton
                onClick={() => {
                  setInventory(inventoryItem)
                  handleOpenModal('delete')
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    }
  })

  const rows: GridRowsProp = freeInventoriesItems

  const deleteContent = () => {
    return (
      <Typography>
        {t('modal.deleteInventory.title1', { ns: 'inventory' })}{' '}
        {inventory.name} [ {inventory?.model} ]{' '}
        {t('modal.deleteFreeInventory.title2', { ns: 'inventory' })}
      </Typography>
    )
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'assignUser':
        return {
          component: <RewriteUser inventory={inventory} />,
          title: t('modal.assignUser.title', { ns: 'inventory' }),
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteInventory(inventory.id)}
            />
          ),
          title: t('modal.deleteFreeInventory.modalTitle', { ns: 'inventory' }),
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('modal.defaultModal.title')}`,
        }
    }
  }
  return (
    <Box>
      <TableComponent columns={columns} rows={rows} />
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Box>
  )
}
export default ShowFreeItems
