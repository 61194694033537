import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { useMutation } from 'react-query'
import fileDownload from 'js-file-download'
import { useTranslation } from 'react-i18next'
import getQuarter from 'date-fns/getQuarter'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useGetAllCustomers } from 'hooks/customers'
import { useGetAllProjects } from 'hooks/projects'
import { useAllUsers } from 'hooks/useAllUsers'

import { IReportData } from '../../types'
import { useReportAllocationContext } from '../../context'

interface IFormikContext {
  option: { id: number; name: string }[] | []
  quarter: Date
}

export const useDownloadReportsAllocations = (groupBy: string) => {
  const { setNotification } = useNotifications()
  const { t } = useTranslation('handleMessage')
  const { values, errors, setFieldValue } = useFormikContext<IFormikContext>()
  const {
    getUsersAllocations,
    getCustomerAllocations,
    allocationByUsers,
    allocationByCustomer,
  } = useReportAllocationContext()
  const projectList = useGetAllProjects()
  const customersList = useGetAllCustomers()
  const allUsers = useAllUsers()
  const quarter = getQuarter(values.quarter)

  const projects = projectList.map((project) => project.id)
  const userAllocationCustomers = customersList.map((customer) => customer.id)
  const customerAllocationUsers = allUsers.map((user) => user.id)
  const optionMap = values.option.map(({ id }) => id)
  const users = allUsers?.map((user) => {
    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName} (${user.email})`,
    }
  })
  const customers = customersList.map(({ id, name }) => {
    return {
      id: id,
      name: name,
    }
  })

  const disabledDownload =
    groupBy === 'users'
      ? !allocationByUsers?.length
      : groupBy === 'customers'
      ? !allocationByCustomer?.length
      : true
  const getFullYear = values.quarter.getFullYear()
  const fileName =
    groupBy === 'users'
      ? `users_allocations_q-${quarter}/${getFullYear}`
      : `customers_allocations_q-${quarter}/${getFullYear}`
  async function downloadReportForCustomer(userReportReq: IReportData) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.REPORT_GENERATE_ALLOCATIONS_FOR_CUSTOMERS,
      userReportReq,
      {}
    )
    return data
  }

  async function downloadReportForUsers(userReportReq: IReportData) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.REPORT_GENERATE_ALLOCATIONS_FOR_USERS,
      userReportReq,
      {}
    )
    return data
  }

  const { mutate: downloadCustomersReport } = useMutation(
    downloadReportForCustomer,
    {
      onSuccess: async (data) => {
        if (data.size) {
          fileDownload(new Blob([data]), fileName + '.xlsx')
        } else {
          setNotification({
            message: t('noDataForMonth'),
            variant: NOTIFICATION.error,
          })
        }
      },
    }
  )

  const { mutate: downloadUsersReport } = useMutation(downloadReportForUsers, {
    onSuccess: async (data) => {
      if (data.size) {
        fileDownload(new Blob([data]), fileName + '.xlsx')
      } else {
        setNotification({
          message: t('noDataForMonth'),
          variant: NOTIFICATION.error,
        })
      }
    },
  })

  useEffect(() => {
    if (values.option.length) {
      if (groupBy === 'users') {
        getUsersAllocations({
          date: values.quarter,
          projects: projects,
          users: optionMap,
          customers: userAllocationCustomers,
        })
      } else {
        getCustomerAllocations({
          date: values.quarter,
          projects: projects,
          users: customerAllocationUsers,
          customers: optionMap,
        })
      }
    }
  }, [values.quarter])
  return {
    downloadCustomersReport,
    downloadUsersReport,
    values,
    errors,
    setFieldValue,
    disabledDownload,
    projects,
    userAllocationCustomers,
    customerAllocationUsers,
    users,
    customers,
  }
}
