import { useContext } from 'react'
import { Box } from '@mui/material'
import { AuthorsWorkForm } from 'pages/Pkup/components'
import { PkupContext } from 'pages/Pkup/context/PkupContext'
import { useAuthorsWork } from 'pages/Pkup/hooks'
import { useSupervisor } from 'hooks/pkup'

const ShowMoreAuthorsWork: React.FC = (): JSX.Element => {
  const { selectedAuthorsWork } = useContext(PkupContext)
  const { date } = useContext(PkupContext)
  const { myWorks } = useAuthorsWork(date)
  const { getSupervisor } = useSupervisor()
  const initialValuesEdit = {
    title: selectedAuthorsWork.title,
    description: selectedAuthorsWork.description,
    projectId: {
      id: selectedAuthorsWork.project.id,
      name: selectedAuthorsWork.project.name,
    },
    members: selectedAuthorsWork.workmembers.map((member) => {
      return { id: member.id, title: getSupervisor(member.userId).name }
    }),
    files:
      myWorks.find((work) => work.id === selectedAuthorsWork.id)
        ?.attachmentsList || [],
  }
  return (
    <Box>
      <AuthorsWorkForm
        initialValues={initialValuesEdit}
        buttonName="edit"
        disabled={true}
      />
    </Box>
  )
}
export default ShowMoreAuthorsWork
