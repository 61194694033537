import { useTranslation } from 'react-i18next'
import { useGetAllCustomers } from 'hooks/customers'
import { useGetAllProjects } from 'hooks/projects'
import { useAuth } from 'hooks/useAuth'

export const useEditWorkTypes = (workTypeId: number | null) => {
  const { user } = useAuth()
  const projectList = useGetAllProjects()
  const customersList = useGetAllCustomers()
  const { t } = useTranslation(['systemWorkTypes', 'handleMessage'])

  const workType = user?.system?.workTypes?.find(
    (workType) => workType.id === workTypeId
  )
  const findCustomerId =
    workType && customersList?.findIndex(({ id }) => id === workType?.projectId)
  const findProjectId =
    workType &&
    projectList?.findIndex((project) => project.id === workType?.projectId)

  const initialValues = {
    customerCode: workType?.code,
    customerName: workType?.name,
    customer:
      findCustomerId && findCustomerId !== -1 && workTypeId
        ? customersList[findCustomerId]?.name
        : t('modals.none'),
    agreements: workType?.agreements.length ? workType.agreements : [],
    project:
      findProjectId && findProjectId !== -1 && workTypeId
        ? projectList[findProjectId]?.name
        : t('modals.none'),
  }

  return initialValues
}
