import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IWorkTypes } from 'pages/Calendar/components/Calendar/types'

const getAllWorktypes = async (): Promise<IWorkTypes[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.WORKTYPE_GETALL,
    {},
    {},
    'limit=9999'
  )
  return data
}
const fallback: IWorkTypes[] = []

export const useGetAllWorktypes = () => {
  const { data: allWorktypes = fallback } = useQuery(
    queryKeys.allWorktypes,
    getAllWorktypes
  )
  return allWorktypes
}
