import { Box, Button } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { orderIcon } from 'utils'
import { useTablePagedFunction } from 'hooks/table'
import { EmptyArrayCard, TableComponent } from 'components/common'

import { useGetPageTags } from './utils'

const PopularTags: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['systemTags', 'buttons'])
  const { tagsList, changePagination, paginationUc, validationSchema } =
    useGetPageTags()
  const {
    handleFilter,
    sortBy,
    paginationUC,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTablePagedFunction(changePagination)

  const columns = [
    <>
      <Button
        onClick={() => sortBy('Name', 'ASC')}
        variant="text"
        sx={{ fontWeight: 550 }}
      >
        {t('tabsPanels.panel1.table.col1')}{' '}
        {orderIcon(paginationUc?.order, 'Name')}
      </Button>
    </>,
    <>
      <Button
        onClick={() => sortBy('Users', 'ASC')}
        variant="text"
        sx={{ fontWeight: 550 }}
      >
        {t('tabsPanels.panel1.table.col2')}
        {orderIcon(paginationUc?.order, 'Users')}
      </Button>
    </>,
  ]

  const rows = tagsList?.data?.map(({ users, name }) => {
    return {
      Name: name,
      Users: users.length,
    }
  })
  return (
    <>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={(values, { setSubmitting }) => {
          handleFilter(values)
          setSubmitting(false)
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Box display="flex" mb={2}>
            <Field
              component={TextField}
              name="name"
              label={t('tabsPanels.panel3.form.label')}
            />

            <Button type="submit" sx={{ marginLeft: 1 }}>
              {t('search', { ns: 'buttons' })}
            </Button>
          </Box>
        </Form>
      </Formik>
      <Box maxWidth={500}>
        {rows?.length ? (
          <TableComponent
            rows={rows}
            columns={columns}
            total={tagsList?.total}
            pagination={true}
            handleChangePage={handleChangePage}
            paginationUC={paginationUC}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          <EmptyArrayCard message={t('tabsPanels.panel2.noTags')} />
        )}
      </Box>
    </>
  )
}

export default PopularTags
