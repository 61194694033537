import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { useEffect } from 'react'
import { stringToColor } from 'utils/changeColors'
import { IWorkdays } from 'pages/Calendar/components/Calendar/types'
import { IUser } from 'pages/Customers/types'

interface ItempWorkdaysArr {
  id: number
  title: string
  start: string
  end: string
  startTime: string
  endTime: string
  color?: string
  extendedProps: {
    user: string
    isPlanned: boolean
    comment: string
    contract: string
    project: string | JSX.Element | null
    homeOffice?: boolean
    isOverhours: string
    isHoliday: boolean
  }
  description: string
  display?: string
}
interface Users extends IUser {
  workdays?: IWorkdays[]
}

export const useGetProjectDetailsCalendar = (
  id: string | undefined,
  projectCalendarDate: string
) => {
  const { t } = useTranslation('calendar')
  const getProjectDetailsCalendar = async (
    id: string | undefined,
    currentCalendarDate: string
  ) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PROJECTS_GETONE_CALENDAR,
      {},
      { id, date: currentCalendarDate }
    )

    let tempWorkdaysArr: ItempWorkdaysArr[] = []
    data?.users?.map((user: Users) => {
      const color = stringToColor(`${user.firstName}${user.lastName}`)
      user?.workdays?.map((workday) => {
        if (workday.isPlanned) {
          const workedExists = user?.workdays?.find(
            (workday) =>
              !workday.isPlanned && workday.logDayFrom === workday.logDayFrom
          )
          if (workedExists) {
            return null
          }
        }
        tempWorkdaysArr = [
          ...tempWorkdaysArr,
          {
            id: workday.id,
            title: workday.project ? workday.project.name : t('noProject'),
            start: workday.logDayFrom,
            end: workday.logDayTo,
            startTime: workday.logDayFrom,
            endTime: workday.logDayTo,
            color: color,
            extendedProps: {
              user: `${user.firstName} ${user.lastName}`,
              isPlanned: workday.isPlanned,
              comment: workday.comment,
              contract: workday.workType.name,
              project: workday.project ? workday.project.name : t('noProject'),
              homeOffice: true,
              isOverhours: workday.isOverhours ? t('overhours') : '',
              isHoliday: workday.workType.isHoliday,
            },
            description: workday.comment,
          },
        ]
      })
    })
    return tempWorkdaysArr
  }

  useEffect(() => {
    refetch()
  }, [id])
  const fallback: IWorkdays[] = []

  const { data: projectDetailsWorkdays = fallback, refetch } = useQuery(
    [queryKeys.projectDetailsCalendar, projectCalendarDate],
    () => getProjectDetailsCalendar(id, projectCalendarDate)
  )
  return { projectDetailsWorkdays }
}
