import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import ApartmentIcon from '@mui/icons-material/Apartment'
import HomeIcon from '@mui/icons-material/Home'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

import { workType } from './enum'

interface IconsInCalendar {
  contractType: string
  color?: string
  isHoliday: boolean
}

const IconsInCalendar: React.FC<IconsInCalendar> = ({
  contractType,
  color,
  isHoliday,
}): JSX.Element => {
  const customColor = color || 'inherit'

  const styleIcon = { height: '20px', color: customColor }
  if (isHoliday) {
    return <BeachAccessIcon sx={styleIcon} />
  }

  switch (contractType) {
    case workType.BUSINESS_TRIP:
      return <AirplanemodeActiveIcon sx={styleIcon} />
    case workType.SICK:
      return <LocalHospitalIcon sx={styleIcon} />
    case workType.OFFICE_WORK:
      return <ApartmentIcon sx={styleIcon} />
    case workType.HOME_OFFICE:
      return <HomeIcon sx={styleIcon} />
    default:
      return <QuestionMarkIcon sx={styleIcon} />
  }
}

export default IconsInCalendar
