import { Box, Typography, IconButton } from '@mui/material'
import format from 'date-fns/format'
import { GridRowsProp } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { IUserProps } from 'components/users'
import {
  useNotifications,
  EmptyArrayCard,
  TableComponent,
} from 'components/common'

import { IAgreementProps } from '../../types'

import { useUserAgreements } from './utils'
import AddAgreement from './AddAgreement'

interface IUserAgreements {
  type: string | undefined
  startDate: string
  endDate: string
  buttons: JSX.Element
}

const Agreements: React.FC<IUserProps & IAgreementProps> = ({
  user,
  setUserAgreement,
}): JSX.Element => {
  const { t } = useTranslation('userEditPage')
  const { handleOpenModal } = useNotifications()
  const { hasAgreementPermission } = useUserAgreements()

  const columns = [
    `${t('agreements.table.columns.col1')}`,
    `${t('agreements.table.columns.col2')}`,
    `${t('agreements.table.columns.col3')}`,
    '',
  ]
  const userAgreements = user?.UserAgreements?.map((agreement) => {
    return {
      type: agreement.Agreement.type,
      startDate: format(new Date(agreement.startDate), 'dd/MM/yyyy'),
      endDate: agreement.endDate
        ? format(new Date(agreement.endDate), 'dd/MM/yyyy')
        : 'contract for an indefinite period',
      buttons: hasAgreementPermission ? (
        <Box display="flex">
          <IconButton
            onClick={() => {
              setUserAgreement(agreement)
              handleOpenModal('editUserAgreement')
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleOpenModal('deleteAgreement')
              setUserAgreement(agreement)
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      ) : null,
    }
  }) as IUserAgreements[]

  const rows: GridRowsProp = userAgreements
  return (
    <>
      <Box>
        {rows.length ? (
          <TableComponent columns={columns} rows={rows} />
        ) : (
          <EmptyArrayCard message={t('agreements.noAgreements')} />
        )}
      </Box>
      <Box mt={3} maxWidth={500}>
        <Typography variant="h3">{t('agreements.title2')}</Typography>
        {hasAgreementPermission && (
          <Box>
            <AddAgreement />
          </Box>
        )}
      </Box>
    </>
  )
}

export default Agreements
