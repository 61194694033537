import { useState } from 'react'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Container,
  Button,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { HeaderTab } from 'views/LayoutUI/components'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { DefaultModal } from 'views/LayoutUI/modals'
import {
  EmptyArrayCard,
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
} from 'components/common'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { IBusinessUnit, IButtonArr, ISelectingModal } from 'hooks/types'
import { timeoutAnimation } from 'utils'
import { PageRoutes } from 'routes'

import { IInitialValuesBusinessUnit, useBusinessUnits } from './utils'
import { AddBusinessUnit, EditBusinessUnit } from './modals'

const BusinessUnits: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['systemBusinessUnits', 'buttons', 'validation'])
  const [selectGroup, setSelectGroup] = useState<IBusinessUnit | undefined>(
    undefined
  )
  const { user } = useAuth()

  const { handleOpenModal, modalName, setModalName } = useNotifications()
  const { businessUnitsList, deleteBusiness } = useBusinessUnits()
  const navigate = useNavigate()

  const initialValuesEdit: IInitialValuesBusinessUnit = {
    name: selectGroup?.name || '',
    description: selectGroup?.description || '',
    email: selectGroup?.email || '',
  }
  const initialValuesAdd: IInitialValuesBusinessUnit = {
    name: '',
    description: '',
    email: '',
  }
  const validationSchema = yup.object({
    name: yup
      .string()
      .required(`${t('name.required', { ns: 'validation' })}`)
      .min(3, `${t('name.minLength', { ns: 'validation' })}`),
    description: yup
      .string()
      .required(`${t('description.required', { ns: 'validation' })}`)
      .min(8, `${t('description.minLength', { ns: 'validation' })}`),
    email: yup
      .string()
      .required(`${t('email.required', { ns: 'validation' })}`),
  })
  const columns = [
    '#',
    `${t('table.cell1')}`,
    `${t('table.cell2')}`,
    `${t('table.cell3')}`,
    `${t('table.cell4')}`,
    '',
  ]

  const buttonsArr: IButtonArr[] = [
    {
      title: `${t('table.tooltip1')}`,
      path: PageRoutes.SYSTEM_BUSINESS_UNITS_USERS,
      icon: isUserPermitted(user, [
        permissionList.BUSINESS_UNIT_USERS,
        permissionList.BUSINESS_UNIT_ADD_USER,
      ]) && <DashboardIcon />,
      modal: null,
    },
    {
      title: `${t('edit', { ns: 'buttons' })}`,
      path: null,
      icon: isUserPermitted(user, [permissionList.BUSINESS_UNIT_EDIT]) && (
        <EditIcon />
      ),
      modal: 'edit',
    },
    {
      title: `${t('delete', { ns: 'buttons' })}`,
      path: null,
      icon: isUserPermitted(user, [permissionList.BUSINESS_UNIT_DELETE]) && (
        <DeleteIcon color="error" />
      ),
      modal: 'delete',
    },
  ]
  const deleteContent = (
    <>
      <Typography variant="subtitle2"> {t('modal.delete.text')}</Typography>
      <Typography variant="subtitle1">{': ' + selectGroup?.name}</Typography>?
    </>
  )
  const handleDelete = (businessUnitId: number | undefined) => {
    deleteBusiness(businessUnitId)
  }
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addBusinessUnit':
        return {
          component: (
            <AddBusinessUnit
              initialValues={initialValuesAdd}
              validationSchema={validationSchema}
            />
          ),
          title: `${t('modal.add.title')}`,
        }
      case 'edit':
        return {
          component: (
            <EditBusinessUnit
              initialValues={initialValuesEdit}
              validationSchema={validationSchema}
              id={Number(selectGroup?.id)}
            />
          ),
          title: `${t('modal.edit.title')}`,
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent}
              deleteFunction={() => handleDelete(selectGroup?.id)}
            />
          ),
          title: `${t('modal.delete.title')}`,
        }

      default:
        return { component: <DefaultModal />, title: 'Default modal' }
    }
  }

  const rows: GridRowsProp = businessUnitsList?.map(
    (businessUnit: IBusinessUnit) => {
      return {
        id: businessUnit.id,
        name: businessUnit.name,
        contact: businessUnit.email,
        description: businessUnit.description,
        users: businessUnit?.users?.length,
        button: (
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            {buttonsArr.map((button: IButtonArr) => (
              <Tooltip title={button.title as string} key={button.title}>
                <IconButton
                  onClick={() =>
                    handleOpen(businessUnit, button.modal as string)
                  }
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        ),
      }
    }
  )
  const handleOpen = async (
    group: IBusinessUnit | null,
    name: string | null
  ) => {
    group && setSelectGroup(group)
    setModalName(name)
    handleOpenModal(name)
    !name && navigate(`/system/businessUnits/${group?.id}/users`)
  }

  return (
    <Container>
      <HeaderTab translate="systemBusinessUnits" id={t('header.id')} />
      {isUserPermitted(user, [permissionList.BUSINESS_UNIT_CREATE]) && (
        <Button onClick={() => handleOpen(null, 'addBusinessUnit')}>
          {t('button')}
        </Button>
      )}
      {rows.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent rows={rows} columns={columns} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}
export default BusinessUnits
