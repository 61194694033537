export interface IValidateVacationData {
  daysCount: number
  hoursCount?: number
  isRequestValid: boolean
  affectedDates: string[]
}

export enum VacationUnitsType {
  'days' = 'days',
  'hours' = 'hours',
}
