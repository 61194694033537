import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IUsers } from 'hooks/types'

const getAllUsers = async (): Promise<IUsers[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.USERS_GET_ALL,
    {},
    {},
    'limit=9999'
  )
  return data.data
}
const fallback: IUsers[] = []

export const useAllUsers = () => {
  const { data: allUsers = fallback } = useQuery(
    queryKeys.allUsersList,
    getAllUsers
  )
  return allUsers
}
