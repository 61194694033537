import * as yup from 'yup'
import { NOTIFICATION, useNotifications } from 'components/common'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { useGetAllCustomers } from 'hooks/customers'
import { useGetAllProjects } from 'hooks/projects'
import { IAgreements } from 'hooks/types'

import { findCustomer, findProject, agreementsList } from '../modals/utils'

interface IUpdateValues {
  customerCode: string | undefined
  customerName: string | undefined
  customer: string
  agreements: IAgreements[] | []
  project: string
}

interface IBooleanOption {
  paid?: boolean
  isProductive?: boolean
  isAllocated?: boolean
  isHoliday?: boolean
  needApproval?: boolean
  isWorking?: boolean
  isAssignableToProject?: boolean
}

export const useUpdateWorkTypes = (workTypeId: number | null) => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation(['systemWorkTypes', 'handleMessage'])
  const projectList = useGetAllProjects()
  const customersList = useGetAllCustomers()

  const updateWorkTypesBoolean = async (
    values: IUpdateValues | IBooleanOption
  ) => {
    const isFullValue = (
      obj: IUpdateValues | IBooleanOption
    ): obj is IUpdateValues => {
      return (
        'customerCode' in obj &&
        'customerName' in obj &&
        'customer' in obj &&
        'project' in obj &&
        'agreements' in obj
      )
    }
    const updateData = (updateData: IUpdateValues | IBooleanOption) => {
      if (isFullValue(updateData)) {
        const { agreements, customerCode, customerName, customer, project } =
          updateData
        return {
          agreementId: agreementsList(agreements),
          customerId: findCustomer(customersList, customer)
            ? findCustomer(customersList, customer).id
            : 0,
          projectId: findProject(projectList, project)
            ? findProject(projectList, project)?.id
            : 0,
          code: customerCode,
          name: customerName,
        }
      } else {
        return updateData
      }
    }
    const updateValues = updateData(values)
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKTYPE_UPDATE,
      updateValues,
      {
        id: workTypeId,
      }
    )
    return data
  }

  const { mutate: updateWorkTypes } = useMutation(updateWorkTypesBoolean, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message:
          t('workType', { ns: 'handleMessage' }) +
          t('edit', { ns: 'handleMessage' }),
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
    },
  })

  const validationSchema = yup.object({
    customerCode: yup.string().min(2).required(t('modals.form.required1')),
    customerName: yup.string().min(2).required(t('modals.form.required2')),
  })
  return { updateWorkTypes, validationSchema }
}
