import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useArrays } from '../../hooks'
import { StyledBox } from '../../index.style'

const IconsLegend = () => {
  const { t } = useTranslation(['systemWorkTypes'])
  const { iconsButtonsArr, iconsArr } = useArrays()

  return (
    <Paper
      sx={{
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto',
      }}
    >
      <Typography variant="h3" sx={{ alignSelf: 'center', padding: '10px' }}>
        {t('legend')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          justifyContent: 'center',
        }}
      >
        {iconsArr.map(({ icon, text, id }) => (
          <StyledBox key={id}>
            {icon}
            <Typography>{text}</Typography>
          </StyledBox>
        ))}
        {iconsButtonsArr.map(({ icon, text, id }) => (
          <StyledBox key={id}>
            {icon}
            <Typography>{text}</Typography>
          </StyledBox>
        ))}
      </Box>
    </Paper>
  )
}

export default IconsLegend
