import { AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'

interface IUserNotification {
  id: number
  disabledNotifications: boolean
  userId: number
  notificationId: number
  createdAt: Date
  updatedAt: Date | null
  NotificationId: number
}

export interface INotifications {
  id: number
  name: string
  type: string
  description: string
  createdAt: Date
  updatedAt: Date | null
  usersNotifications: IUserNotification[]
}

const getNotifications = async (): Promise<INotifications[] | []> => {
  const { data }: AxiosResponse = await apiRequest(APIRoutes.NOTIFICATIONS_GET)
  return data
}

const updateNotification = async (id: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.NOTIFICATIONS_CREATE,
    { notificationId: id }
  )
  return data
}
const deleteNotification = async (id: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.NOTIFICATIONS_DELETE,
    {},
    { id }
  )
  return data
}
export const useNotification = () => {
  const queryClient = useQueryClient()

  const fallback: INotifications[] = []
  const { data: notifications = fallback } = useQuery(
    queryKeys.notification,
    getNotifications
  )

  const { mutate: changeNotifiaction } = useMutation(updateNotification, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.notification])
    },
  })
  const { mutate: removeNotification } = useMutation(deleteNotification, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.notification])
    },
  })

  return { notifications, changeNotifiaction, removeNotification }
}
