import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { ICardContent } from 'pages/Dashboard/components/Cards'

import { StyledCard } from './index.style'

interface IProps {
  cardContent: ICardContent
}

const CardItem: React.FC<IProps> = ({ cardContent }): JSX.Element => {
  const {
    title,
    subtitle,
    subtitle2,
    component,
    icon,
    button,
    secondButton,
    backgroundColor,
  } = cardContent

  return (
    <StyledCard
      sx={{
        backgroundColor: backgroundColor
          ? backgroundColor
          : 'primary.contrastText',
      }}
    >
      <Grid container alignItems="space-between" height="100%">
        <Grid container item xs={12} justifyContent="space-between">
          <Grid container item xs={12}>
            <Grid item xs={9}>
              <Typography
                variant="h3"
                sx={{
                  color: backgroundColor
                    ? 'primary.contrastText'
                    : 'primary.main',
                }}
              >
                {title}
              </Typography>
              {!component && (
                <Typography
                  variant="h6"
                  sx={{
                    color: backgroundColor ? 'primary.contrastText' : 'inherit',
                  }}
                >
                  {subtitle}
                </Typography>
              )}
              {!component && (
                <Typography
                  variant="h6"
                  sx={{
                    color: backgroundColor ? 'primary.contrastText' : 'inherit',
                  }}
                >
                  {subtitle2}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={3}
              justifyContent="center"
              display="flex"
              mt="0.5rem"
            >
              {icon}
            </Grid>
          </Grid>

          {button && (
            <Grid item xs={12} display="flex" alignItems="flex-end">
              <Box my={1}>
                {button}
                {secondButton && secondButton}
              </Box>
            </Grid>
          )}
        </Grid>
        {component && (
          <Grid item xs={12}>
            <Typography variant="h6">{subtitle}</Typography>
            <Box my={0.5}>{component}</Box>
            <Typography variant="h6">{subtitle2}</Typography>
          </Grid>
        )}
      </Grid>
    </StyledCard>
  )
}

export default CardItem
