import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import AddAlarmIcon from '@mui/icons-material/AddAlarm'

import { useCalendarContext } from '../../../../context'
import { useWorkdaysModal } from '../../utils'

import { AlertContainer } from './index.style'

const FteComponent = (): JSX.Element => {
  const { projectWithOverHours } = useWorkdaysModal()
  const { projects, isOverhours, setIsOverhours, alloc } = useCalendarContext()
  const { t } = useTranslation('calendar')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOverhours(event.target.checked)
  }
  if (!projects.length) {
    return (
      <AlertContainer severity="error">
        {t('addWorkdaysModal.fte.errorAlert')}
      </AlertContainer>
    )
  } else if (projects.length && projectWithOverHours.length) {
    return (
      <>
        <AlertContainer severity="warning" sx={{ marginBottom: 1 }}>
          <>
            <Box display="flex" alignItems="center">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: '0 8px' }}
                      size="small"
                      checked={isOverhours}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Typography variant="h6">
                      {t('addWorkdaysModal.fte.checkbox')}
                    </Typography>
                  }
                />
              </FormGroup>
              <Typography variant="h6">
                {t('addWorkdaysModal.fte.hasOverhours')}
              </Typography>
              <AddAlarmIcon color="disabled" />
            </Box>
          </>
        </AlertContainer>

        {projectWithOverHours.length && isOverhours ? (
          <AlertContainer severity="success">
            {t('addWorkdaysModal.fte.yourFTE')}: {projectWithOverHours[0].FTE},{' '}
            {t('addWorkdaysModal.fte.plannedHours')}:{' '}
            {projectWithOverHours[0].allUsedPlannedHours}/
            {projectWithOverHours[0].allPlannedHours},{' '}
            {t('addWorkdaysModal.fte.usedHours')}:{' '}
            {projectWithOverHours[0].allUsedWorkingHours}/
            {projectWithOverHours[0].allPlannedHours} ({t('overhours')})
          </AlertContainer>
        ) : !alloc?.isOverhours && !!projects.length ? (
          <AlertContainer severity="success">
            {t('addWorkdaysModal.fte.yourFTE')}: {alloc?.FTE},{' '}
            {t('addWorkdaysModal.fte.plannedHours')}:{' '}
            {alloc?.allUsedPlannedHours}/{alloc?.allPlannedHours},{' '}
            {t('addWorkdaysModal.fte.usedHours')}: {alloc?.allUsedWorkingHours}/
            {alloc?.allPlannedHours}
          </AlertContainer>
        ) : (
          <AlertContainer severity="error">
            {t('addWorkdaysModal.fte.errorAlert')}.{' '}
            {t('addWorkdaysModal.fte.asOverhours')}
          </AlertContainer>
        )}
      </>
    )
  } else {
    return (
      <AlertContainer severity="success">
        {t('addWorkdaysModal.fte.yourFTE')}: {alloc?.FTE},{' '}
        {t('addWorkdaysModal.fte.plannedHours')}: {alloc?.allUsedPlannedHours}/
        {alloc?.allPlannedHours}, {t('addWorkdaysModal.fte.usedHours')}:{' '}
        {alloc?.allUsedWorkingHours}/{alloc?.allPlannedHours}
      </AlertContainer>
    )
  }
}

export default FteComponent
