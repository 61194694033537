import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Box, Button, LinearProgress } from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'
import { TextField } from 'formik-mui'
import { useAddCustomer } from 'hooks/customers'

const CustomerAdd: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['customerAdd', 'buttons', 'validation'])
  const { addNewCustomer } = useAddCustomer()
  const initialValues = {
    customerName: '',
    customerCode: '',
  }

  const validationSchema = yup.object({
    customerName: yup
      .string()
      .required(`${t('customerName.required', { ns: 'validation' })}`)
      .min(3),
    customerCode: yup
      .string()
      .required(`${t('customerCode.required', { ns: 'validation' })}`)
      .min(3),
  })

  const customerFields: IFields[] = [
    {
      id: '1',
      label: `${t('form.label1')}`,
      name: 'customerCode',
      type: 'text',
    },
    {
      id: '2',
      label: `${t('form.label2')}`,
      name: 'customerName',
      type: 'text',
    },
  ]
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { customerCode, customerName } = values
          const customerData = {
            code: customerCode,
            name: customerName,
          }
          addNewCustomer(customerData)
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <>
              <Box mb={2}>
                {customerFields.map((field) => (
                  <Field
                    fullWidth
                    key={field.id}
                    component={TextField}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                    variant="outlined"
                    margin="normal"
                    size="small"
                  />
                ))}
              </Box>
            </>
            <Button disabled={isSubmitting} onClick={submitForm}>
              {t('save', { ns: 'buttons' })}
            </Button>
            <br />
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default CustomerAdd
