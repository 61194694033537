import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-mui'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  IconButton,
  LinearProgress,
  TextField as TF,
  Tooltip,
  Typography,
} from '@mui/material'
import { useAuthorsWork } from 'pages/Pkup/hooks'
import { useGetUserList } from 'hooks/customers'
import { PkupContext } from 'pages/Pkup/context/PkupContext'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  CustomAutocompleteInput,
  CustomizedDataPicker,
} from 'components/common'
import { CreatePkupProps, IAuthorsWork } from 'hooks/pkup/types'

interface ApplicationFormProps {
  buttonName: string
  disabled: boolean
  initialValues: CreatePkupProps
  onSubmit: (values: CreatePkupProps) => void
}
const ApplicationForm: React.FC<ApplicationFormProps> = ({
  buttonName,
  disabled,
  initialValues,
  onSubmit,
}): JSX.Element => {
  const { t } = useTranslation(['pkup', 'validation', 'buttons'])
  const { selectedApplication } = useContext(PkupContext)
  const { usersList } = useGetUserList()
  const { myWorks } = useAuthorsWork(
    `${new Date().getFullYear()}/${new Date().getMonth() + 1}`
  )
  const [worksIds, setWorksIds] = useState(
    initialValues?.works?.map((work: { id: number }) => work.id) || []
  )
  const validationSchemaUpdate = yup.object({
    title: yup
      .string()
      .min(4, `${t('titlePkup.minLength', { ns: 'validation' })}`)
      .max(48, `${t('titlePkup.maxLength', { ns: 'validation' })}`)
      .required(`${t('titlePkup.required', { ns: 'validation' })}`),
    description: yup
      .string()
      .min(12, `${t('descriptionPkup.minLength', { ns: 'validation' })}`)
      .max(400, `${t('descriptionPkup.maxLength', { ns: 'validation' })}`)
      .required(`${t('descriptionPkup.required', { ns: 'validation' })}`),
  })
  const validationSchema = yup.object({
    title: yup
      .string()
      .min(4, `${t('titlePkup.minLength', { ns: 'validation' })}`)
      .max(48, `${t('titlePkup.maxLength', { ns: 'validation' })}`)
      .required(`${t('titlePkup.required', { ns: 'validation' })}`),
    description: yup
      .string()
      .min(12, `${t('descriptionPkup.minLength', { ns: 'validation' })}`)
      .max(400, `${t('descriptionPkup.maxLength', { ns: 'validation' })}`)
      .required(`${t('descriptionPkup.required', { ns: 'validation' })}`),
    supervisorId: yup
      .object()
      .required(`${t('supervisor.required', { ns: 'validation' })}`),
  })
  const handleDeleteWork = (workId: number) => {
    const newWorksList = worksList.filter(
      (work: IAuthorsWork) => work.id !== workId
    )
    setWorksIds(newWorksList.map((work) => work.id))
    setWorksList(newWorksList)
  }
  const myWorksList = () =>
    myWorks?.filter((work: IAuthorsWork) => worksIds?.includes(work.id))

  const [worksList, setWorksList] = useState(myWorksList())

  useEffect(() => setWorksList(myWorksList()), [selectedApplication, worksIds])
  return (
    <Box sx={{ mr: '1rem' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={
          buttonName === 'update' ? validationSchemaUpdate : validationSchema
        }
        onSubmit={(values, { setSubmitting }) => {
          buttonName === 'edit'
            ? onSubmit({
                ...values,
                works: worksList.map((work: IAuthorsWork) => {
                  return { id: work.id, title: work.title }
                }),
              })
            : onSubmit({
                ...values,
              })
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ errors, setFieldValue, isSubmitting }) => (
          <>
            <Form>
              <Field
                fullWidth
                disabled={disabled}
                key="1"
                component={TextField}
                name="title"
                type="text"
                label={`${t('modal.common.label1')}`}
                margin="normal"
                variant="outlined"
                size="small"
              />
              <Field
                component={TextField}
                disabled={disabled}
                fullWidth
                key="2"
                margin="normal"
                multiline
                name="description"
                type="text"
                label={`${t('modal.common.label2')}`}
                variant="outlined"
                size="small"
              />
              {buttonName === 'update' ? (
                <Box sx={{ width: '20%', minWidth: '23rem', margin: '1rem 0' }}>
                  <Field
                    component={CustomizedDataPicker}
                    label={t('modal.revisions.label3')}
                    name="period"
                    views={['year', 'month']}
                    inputFormat="yyyy-MM"
                    fullWidth
                    disabled={disabled}
                  />
                </Box>
              ) : (
                <Box mt={2} mb={1}>
                  <Field
                    component={CustomAutocompleteInput}
                    optionsData={
                      usersList.map((user) => {
                        return {
                          name: `${user.firstName} ${user.lastName}`,
                          id: user.id,
                        }
                      }) || []
                    }
                    name="supervisorId"
                    label={t('modal.addApplication.label3')}
                    disableClearable={true}
                    disabled={disabled}
                    error={errors.supervisorId}
                  />{' '}
                </Box>
              )}
              {buttonName === 'edit' ? (
                <>
                  <Typography variant={disabled ? 'subtitle2' : 'subtitle1'}>
                    {t('modal.addApplication.label5')}:
                  </Typography>
                  {myWorksList().length === 0 ? (
                    <Typography variant="subtitle2">
                      {t('modal.addApplication.label5text')}:
                    </Typography>
                  ) : (
                    <>
                      {myWorksList().map((work) => (
                        <Box
                          key={work.id}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textIndent: '0.5rem',
                          }}
                        >
                          <Typography
                            variant={disabled ? 'subtitle2' : 'subtitle1'}
                          >
                            {work.title}
                          </Typography>
                          <IconButton
                            disabled={disabled}
                            onClick={() => handleDeleteWork(work.id)}
                          >
                            <Tooltip title="Delete">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      ))}
                    </>
                  )}
                  <Autocomplete
                    id="works"
                    disabled={disabled}
                    options={myWorks.map((work) => {
                      return {
                        name: work.title,
                        id: work.id,
                      }
                    })}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, { name }) =>
                      option.name === name
                    }
                    onChange={(e, value) => {
                      setFieldValue('works', value?.id)
                      value?.id !== undefined &&
                        setWorksIds((ids: number[]) => [...ids, value?.id])
                    }}
                    includeInputInList
                    renderInput={(params) => (
                      <TF
                        {...params}
                        label={`${t('modal.addApplication.label4')}`}
                        name="works"
                        variant="outlined"
                        margin="normal"
                      />
                    )}
                  />
                  {selectedApplication.approverComment && (
                    <>
                      <Typography variant="subtitle2">
                        {t('modal.addApplication.label6')}:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ whiteSpace: 'pre-line' }}
                      >
                        {selectedApplication.approverComment}
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                buttonName === 'add' && (
                  <Autocomplete
                    multiple
                    id="works"
                    disabled={disabled}
                    options={myWorks}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.title}
                      </li>
                    )}
                    onChange={(e, value) => {
                      const works = value.map((work) => {
                        return { id: work.id, title: work.title }
                      })
                      setFieldValue('works', works)
                    }}
                    renderInput={(params) => (
                      <TF
                        {...params}
                        label={`${t('modal.addApplication.label4')}`}
                        name="checked"
                        margin="normal"
                      />
                    )}
                  />
                )
              )}
              {isSubmitting && <LinearProgress />}
              {!disabled && (
                <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
              )}
            </Form>
          </>
        )}
      </Formik>
    </Box>
  )
}
export default ApplicationForm
