import { Box, Typography, Tooltip, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import { GridRowsProp } from '@mui/x-data-grid'
import { DetailsTooltip, useNotifications } from 'components/common'

import Hours from '../Hours'

const RowsReportProductivity = () => {
  const { userDetailsId, setUserDetailsId } = useNotifications()
  const { t } = useTranslation(['reportProductivity', 'buttons'])
  const rowsReport = (userData, isPlanningMode) => {
    const rows: GridRowsProp = userData?.map((user) => {
      return {
        name: (
          <DetailsTooltip id={userDetailsId}>
            <Box>
              <Typography
                onMouseOver={() => setUserDetailsId(user.id)}
                sx={{ cursor: 'pointer' }}
                fontSize={12}
                textAlign="center"
                variant="h4"
              >
                {user.firstName} {user.lastName}
              </Typography>
            </Box>
          </DetailsTooltip>
        ),
        project: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography fontSize={12} textAlign="center">
              {user?.userProjects?.filter((project) => {
                if (
                  project.allocations.length > 0 &&
                  project.projectName !== 'undefined'
                ) {
                  return project.projectName
                }
              }).length > 0
                ? user?.userProjects
                    .filter((project) => {
                      if (project.allocations.length > 0) {
                        return project
                      }
                    })
                    .map((project) => project.projectName)
                    .join(', ')
                : t('table.noAllocation')}
            </Typography>
            {user?.userProjects.length ? (
              <Tooltip
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {user?.userProjects?.map((project) => (
                      <Typography key={project.projectId}>
                        {project.projectName}
                      </Typography>
                    ))}
                  </Box>
                }
              >
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        ),
        I: <Hours index={0} user={user} isPlanningMode={isPlanningMode} />,
        II: <Hours index={1} user={user} isPlanningMode={isPlanningMode} />,
        III: <Hours index={2} user={user} isPlanningMode={isPlanningMode} />,
        IV: <Hours index={3} user={user} isPlanningMode={isPlanningMode} />,
        V: <Hours index={4} user={user} isPlanningMode={isPlanningMode} />,
        VI: <Hours index={5} user={user} isPlanningMode={isPlanningMode} />,
        VII: <Hours index={6} user={user} isPlanningMode={isPlanningMode} />,
        VIII: <Hours index={7} user={user} isPlanningMode={isPlanningMode} />,
        IX: <Hours index={8} user={user} isPlanningMode={isPlanningMode} />,
        X: <Hours index={9} user={user} isPlanningMode={isPlanningMode} />,
        XI: <Hours index={10} user={user} isPlanningMode={isPlanningMode} />,
        XII: <Hours index={11} user={user} isPlanningMode={isPlanningMode} />,
      }
    })
    return rows
  }
  return rowsReport
}

export default RowsReportProductivity
