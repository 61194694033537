import { Outlet } from 'react-router-dom'

import { StyledContainer } from './index.style'

const AuthLayout: React.FC = (): JSX.Element => {
  return (
    <>
      <StyledContainer>
        <Outlet />
      </StyledContainer>
    </>
  )
}

export default AuthLayout
