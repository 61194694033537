import {
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'

interface ISummary {
  summary: string
  fte: string
  projects: number
  freeTime: string
}

interface TableAllocationProps {
  columns: () => (string | JSX.Element)[]
  rows: GridRowsProp
  summary: ISummary
}
const TableAllocation: React.FC<TableAllocationProps> = ({
  columns,
  rows,
  summary,
}): JSX.Element => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              height: '3rem',
            }}
          >
            {columns()?.map((column, index) => (
              <TableCell
                key={index}
                align="left"
                sx={{ fontWeight: '550', color: 'primary.main' }}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={row.id ? row.id : index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {Object.values(row).map((el: any, index) => (
                <TableCell key={index} align="left">
                  {el}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {Object.values(summary).map((el: any, index) => (
              <TableCell
                key={index}
                align="left"
                sx={{
                  fontWeight: '550',
                  color: 'secondary.light',
                  backgroundColor: 'primary.light',
                }}
              >
                {el}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableAllocation
