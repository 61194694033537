import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useState, MouseEvent } from 'react'
import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { queryKeys } from 'react-query/constants'
import { filterArrayByName } from 'utils'
import { IUser, ITags } from 'hooks/types'
import { useTranslation } from 'react-i18next'

export interface ISkills {
  id: number
  name: string
}
export interface IDeleteSkill {
  userId?: number
  tagId: number
}
interface IRateData {
  userId: number | undefined
  tagId: number
  skillLevel: number
}
const getTags = async (): Promise<ITags[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.TAGS_GET,
    {},
    {},
    'limit=9999'
  )
  return data.data
}
const updateSkillRate = async (rateData: IRateData) => {
  const { data } = await apiRequest(APIRoutes.TAGS_ADD_RATE, rateData)
  return data.data
}

export const useUpdateSkills = (user: IUser | null) => {
  const [sliderId, setSliderId] = useState(0)
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { setNotification } = useNotifications()
  const fallback: ITags[] = []

  const { data: tags = fallback } = useQuery(queryKeys.tags, getTags)

  const addSkillToUser = async (userSkills: ISkills[]) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.TAGS_ADD_TO_USER,
      userSkills,
      { userId: user?.id }
    )
    return data.data
  }

  const deleteSkillOnServer = async (skill: IDeleteSkill) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.TAGS_DELETE_FROM_USER,
      {},
      skill
    )
    return data
  }
  const { mutate: addNewSkills } = useMutation(addSkillToUser, {
    onSuccess: () => {
      setNotification({
        message: `${t('tags')} ${t('add2')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: (_data, _context, variables) => {
      const userTags = user?.tags as ITags[]
      const difference = [...filterArrayByName(variables, userTags)]

      if (difference.length) {
        setNotification({
          message: `${t('tagsError.text1')}${difference?.map(
            (tag) => tag.name
          )} ${t('tagsError.text2')}`,
          variant: NOTIFICATION.error,
        })
      }
      queryClient.invalidateQueries([queryKeys.user])
      queryClient.invalidateQueries([queryKeys.userOne])
    },
  })

  const { mutate: deleteTag } = useMutation(deleteSkillOnServer, {
    onSuccess: () => {
      setNotification({
        message: `${t('tag')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
      queryClient.invalidateQueries([queryKeys.userOne])
    },
  })

  const { mutate: updateRate } = useMutation(updateSkillRate, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
      queryClient.invalidateQueries([queryKeys.userOne])
    },
  })

  const handleDelete = (event: MouseEvent<HTMLElement>) => {
    const id = Number(event.currentTarget.id)
    deleteTag({ userId: user?.id, tagId: id })
  }
  const handleChange = (id: number) => {
    setSliderId(id)
  }
  const handleCommittedChange = (_, value: number | number[]) => {
    updateRate({ userId: user?.id, tagId: sliderId, skillLevel: Number(value) })
  }

  return {
    tags,
    handleDelete,
    addNewSkills,
    handleChange,
    handleCommittedChange,
  }
}
