import { useAuth } from 'hooks/useAuth'

interface IUserHolidays {
  usedPlanned: number
  used: number
  maxHolidays: number
}

export const calculateUsedHolidaysPercentage = (userHolidays: {
  used: number
  maxHolidays: number
}): number => {
  return userHolidays.maxHolidays > 0
    ? parseFloat(
        ((100 * userHolidays.used) / userHolidays.maxHolidays).toFixed(2)
      )
    : 0
}

export const formatAlertMessages = (
  messages: Array<string | boolean>
): string => {
  return messages
    .filter(Boolean)
    .map((s, i) =>
      i === 0 && typeof s === 'string'
        ? s.charAt(0).toUpperCase() + s.slice(1)
        : s
    )
    .join(', ')
}

export const usedDashboard = (): IUserHolidays => {
  const { user } = useAuth()

  const response: IUserHolidays = {
    usedPlanned: 0,
    used: 0,
    maxHolidays: user?.maxHolidaysDays || 0,
  }

  const getUserHolidays = () => {
    const currentYear = new Date().getFullYear()

    const holidaysForCurrentYear = user?.UserHolidays?.filter(
      (userHoliday) => userHoliday.year === currentYear
    )
    if (!user || !user.UserHolidays || !holidaysForCurrentYear) {
      return response
    }

    const reducedUserHolidays: IUserHolidays = holidaysForCurrentYear
      ?.filter((holiday) => holiday.year === currentYear)
      .reduce((acc, curr) => {
        acc.usedPlanned += curr.usedPlannedDays
        acc.used += curr.usedDays
        acc.maxHolidays += curr.days
        return acc
      }, response)
    return reducedUserHolidays
  }

  const userHolidays = getUserHolidays()
  return userHolidays
}
