import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const useValidateSchema = () => {
  const { t } = useTranslation('validation')

  const validation = {
    days: yup.number().required(t('days.required')).min(0, t('days.minValue')),
    year: yup.string().required(t('year.required')),
  }

  const validationSchemaAddHoliday = yup.object({
    ...validation,
    holidayType: yup.string().required(t('selectHolidayCategory')),
  })
  const validationSchemaDate = yup.object({
    ...validation,
  })

  return { validationSchemaDate, validationSchemaAddHoliday }
}
