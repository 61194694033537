import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { useEffect } from 'react'
import { IProject } from 'pages/Customers/types'

const getCustomerProject = async (
  id: string | undefined
): Promise<IProject> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PROJECTS_GETONE,
    {},
    { id }
  )
  return data
}

export const useGetCustomerProject = (id: string | undefined) => {
  useEffect(() => {
    refetch()
  }, [id])
  const fallback = <IProject>{}

  const { data: customerProject = fallback, refetch } = useQuery(
    queryKeys.customer,
    () => getCustomerProject(id)
  )
  return customerProject
}
