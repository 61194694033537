import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { IUser } from 'hooks/types'

const getOneUser = async (id: number): Promise<IUser> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.USERS_GETONE,
    {},
    { id: id }
  )
  return data
}

export const useGetOneUser = () => {
  const fallback = <IUser>{}
  const { id } = useParams()

  const { data: oneUser = fallback, refetch } = useQuery(
    queryKeys.userOne,
    () => getOneUser(Number(id)),
    {
      enabled: !!id,
    }
  )

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])
  return oneUser
}
