import { Paper, styled } from '@mui/material'

export const ButtonsBoxContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '15px',
  width: '100%',
  justifyContent: 'center',
  margin: '10px 0',
  padding: '15px',
  position: 'relative',
  zIndex: 1000,
  background: 'rgba(255, 255, 255, 0.8)',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
  boxSizing: 'border-box',
}))
