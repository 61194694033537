import * as yup from 'yup'
import axios, { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useState } from 'react'

export const useChangePassword = () => {
  const [isPasswordUpdated, setPasswordUpdated] = useState(false)
  const { setNotification } = useNotifications()
  const { token } = useParams()
  const { t } = useTranslation(['validation', 'handleMessage'])

  const ERROR = t('serverError', { ns: 'handleMessage' })

  const validationSchema = yup.object({
    password: yup.string().required(t('newPassword.required')),
    repeatNewPassword: yup
      .string()
      .required(t('repeatNewPassword.required'))
      .oneOf([yup.ref('password'), null], t('repeatNewPassword.oneOf')),
  })

  const resetPassword = async (password: string) => {
    try {
      const { data }: AxiosResponse = await apiRequest(
        APIRoutes.RESET_PASSWORD,
        { password: password, token: token }
      )

      if (data.success) {
        setPasswordUpdated(true)
      }
    } catch (error) {
      const errorTitle = axios.isAxiosError(error)
        ? error?.response?.data?.message ||
          error.toString().replace('Error: ', '')
        : ERROR
      setNotification({
        message: errorTitle,
        variant: NOTIFICATION.error,
      })
    }
  }

  return { validationSchema, resetPassword, isPasswordUpdated }
}
