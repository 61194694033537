import {
  Button,
  FormControl,
  InputLabel,
  Paper,
  Box,
  MenuItem,
} from '@mui/material'
import { Field, Formik } from 'formik'
import React from 'react'
import { IData } from 'hooks/reportCustomers'
import { useTranslation } from 'react-i18next'
import {
  CustomizedDataPicker,
  CustomizedSelect,
  TableTitle,
} from 'components/common'

import { useReport } from '../utils/useReport'

interface IProps {
  title: string
  data: IData
}

const ReportCard: React.FC<IProps> = ({ title, data }) => {
  const { t } = useTranslation('reportCustomers,buttons')
  const { body } = data
  const { initialValues, submitHandler } = useReport(body)
  return (
    <Paper
      sx={{ padding: '0.625rem 1.25rem', width: '70%', margin: '0 auto' }}
      elevation={3}
    >
      <TableTitle>{title}</TableTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          submitHandler(values)
        }}
      >
        {({ submitForm, setFieldValue }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              margin: '20px 0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  flex: 1,
                }}
              >
                <Field
                  component={CustomizedDataPicker}
                  label={t('labels.label1', { ns: 'reportCustomers' })}
                  name="startValue"
                  views={['year', 'month']}
                  inputFormat="yyyy-MM"
                />
              </Box>
              <FormControl
                size="small"
                sx={{ width: '70%', margin: '0 auto', flex: 2 }}
              >
                <InputLabel id={title}>{title}</InputLabel>
                <Field label={title} name="value" component={CustomizedSelect}>
                  <MenuItem value={0}>
                    {t('options.option', { ns: 'reportCustomers' })}
                  </MenuItem>
                  {body?.map((item, i: number) => (
                    <MenuItem key={i} value={i + 1}>
                      {item}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Box>
            <Button
              onClick={() => {
                setFieldValue('title', title)
                submitForm()
              }}
              sx={{ width: '50%', alignSelf: 'flex-end' }}
              name={title}
            >
              {t('download', { ns: 'buttons' })}
            </Button>
          </Box>
        )}
      </Formik>
    </Paper>
  )
}

export default ReportCard
