import palette from './palette'

const typography = {
  h1: {
    fontSize: '2.2rem',
    color: palette.primary.main,
    fontWeight: 400,
  },
  h2: {
    fontSize: '1.2rem',
    color: palette.primary.main,
    fontWeight: 400,
    lineHeight: 1.5,
    margin: '0.2rem 0',
  },
  h3: {
    fontSize: '1rem',
    color: palette.primary.main,
    fontWeight: 400,
    lineHeight: 1.2,
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  h4: {
    fontSize: '0.875rem',
    fontWeight: 550,
  },
  h6: {
    fontSize: '0.75rem',
    color: '#7b7d85',
    lineHeight: 1.66,
    fontWeight: 400,
  },
  body2: {
    color: palette.textSecondary.main,
  },
  subtitle1: {
    color: palette.primary.dark,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    margin: '0.2rem 0',
  },
  subtitle2: {
    color: palette.secondary.contrastText,
  },
}

export default typography
