import {
  Box,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import { IField } from '../types'
import { MainCard, PaperTitle } from '../index.style'
import { ISelectedOptions } from '../../../UserCV'
import OptionBox from '../OptionBox'

interface ICardRadioOptionProps {
  option: ISelectedOptions[] | string[]
  name: string
  optionDetails: (field: ISelectedOptions | string) => IField[]
  title: string
  setFieldValue: (name: string, value: string) => void
  value: string
  error?: string
  touch?: boolean
}
const CardRadioOption: React.FC<ICardRadioOptionProps> = ({
  option,
  name,
  optionDetails,
  title,
  setFieldValue,
  value,
  error,
  touch,
}): JSX.Element => {
  return (
    <MainCard>
      <PaperTitle>
        <Typography variant="h3" color="primary.contrastText">
          {title}
        </Typography>
      </PaperTitle>
      <Box>
        <RadioGroup
          name={name}
          value={value}
          onChange={(event) => {
            setFieldValue(name, event.currentTarget.value)
          }}
        >
          {option.map((field) => (
            <Paper
              key={typeof field === 'string' ? field : `${field.id}-${name}`}
              sx={{ padding: '0.5rem', margin: '0.5rem 0' }}
            >
              <FormControlLabel
                value={
                  typeof field === 'string'
                    ? field.replace('.html', '')
                    : field?.id?.toString()
                }
                control={<Radio />}
                label={
                  <>
                    {optionDetails(field).map(({ name, title, icon }) => (
                      <OptionBox
                        key={name}
                        name={name}
                        title={title}
                        icon={icon}
                      />
                    ))}
                  </>
                }
              />
            </Paper>
          ))}
          {error && touch && (
            <Typography color="error" variant="h6">
              {error}
            </Typography>
          )}
        </RadioGroup>
      </Box>
    </MainCard>
  )
}

export default CardRadioOption
