import { Box, styled } from '@mui/material'

export const CopyModalContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
})

export const IconsContainer = styled(Box)({
  display: 'flex',
  gap: '10px',
  marginTop: '10px',
})

export const ButtonsContainer = styled(Box)({
  display: 'flex',
  alignSelf: 'flex-end',
  columnGap: '10px',
})

export const ParentDiv = styled(Box)`
 *  {
  box-sizing: border-box;
    `
