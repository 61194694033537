import { Typography, Box } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useGetProjectHistory } from 'hooks/projectDetails'
import { EmptyArrayCard, HistoryTable, TableTitle } from 'components/common'
import { useTablePagedFunction } from 'hooks/table'

const History: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['projectDetails', 'buttons'])
  const { projectGetOneHistory, changePagination, total } =
    useGetProjectHistory()

  const { paginationUC, handleChangePage, handleChangeRowsPerPage } =
    useTablePagedFunction(changePagination)

  const projectHistoryRows = projectGetOneHistory?.data?.map((history) => {
    const historyChanges = history.changes
      .map((change) => {
        const hasChanges = change.value ? `to '${change.value}'` : ''
        return `${change.field} ${hasChanges}`
      })
      .join(', ')
    return {
      action: (
        <Typography variant="h4">{history.action.toUpperCase()}</Typography>
      ),
      data: historyChanges,
      date: history.date,
      author: `${history.author.firstName} ${history.author.lastName}`,
    }
  })

  const rows: GridRowsProp = projectHistoryRows

  return (
    <Box>
      <TableTitle>{t('history.title')}</TableTitle>
      {projectGetOneHistory?.data?.length ? (
        <HistoryTable
          historyRows={rows}
          total={total}
          handleChangePage={handleChangePage}
          paginationUC={paginationUC}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        ></HistoryTable>
      ) : (
        <EmptyArrayCard message={`${t('typographies.typography10')}`} />
      )}
    </Box>
  )
}

export default History
