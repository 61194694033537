import { useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { useAuth } from 'hooks/useAuth'
import { IProjects } from 'hooks/types'
import { Worktype } from 'pages/Calendar/context/types'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

import { useCalendarContext } from '../../context'

interface InitialValues {
  project: string
  workdayType: string
  workType: string
  status: string
}

export function useFiltersBar() {
  const { t } = useTranslation('calendar')
  const [date, setDate] = useState<Date | null>(new Date())
  const [typeTime, setTypeTime] = useState('start')
  const [isPlanned, setIsPlanned] = useState(true)
  const [projects, setProjects] = useState<string[]>([])
  const [workTypes, setWorkTypes] = useState<string[]>([])
  const [workType, setWorkType] = useState('1')
  const [project, setProject] = useState('1')
  const [workdayType, setWorkdayType] = useState('1')
  const [status, setStatus] = useState('1')

  const { selectedWorkDays, setFilteredWorkDays, setIsFilter, isFilter } =
    useCalendarContext()
  const { user } = useAuth()
  const { handleCloseBar, setNotification } = useNotifications()

  useEffect(() => {
    if (user) {
      const projectsNames: string[] = user
        ? user?.projects?.map((project: IProjects) => project.name) || []
        : []
      const workTypeNames: string[] = user
        ? user?.system?.workTypes?.map((worktype: Worktype) => worktype.name) ||
          []
        : []

      if (projectsNames && workTypeNames) {
        setProjects(projectsNames)
        setWorkTypes(workTypeNames)
      }
    }
  }, [user])

  useEffect(() => {
    setWorkType('1')
    setProject('1')
    setWorkdayType('1')
    setStatus('1')
  }, [isFilter])

  const initialValues: InitialValues = {
    project: project,
    workdayType: workdayType,
    workType: workType,
    status: status,
  }

  const handleChange = (event: SelectChangeEvent, type: string) => {
    switch (type) {
      case 'workType':
        setWorkType(event.target.value)
        break
      case 'project':
        setProject(event.target.value)
        break
      case 'workdayType':
        setWorkdayType(event.target.value)
        break
      case 'status':
        setStatus(event.target.value)
        break
      default:
        setNotification({
          message: t('addWorkdaysModal.alert3'),
          variant: NOTIFICATION.error,
        })
    }
  }

  const onSubmit = async () => {
    const tempWorkdays = [...selectedWorkDays]

    let filteredWorkdays = tempWorkdays.filter((workday) => {
      return workday.extendedProps.project === projects[parseInt(project) - 2]
    })
    if (parseInt(project) === 1) filteredWorkdays = tempWorkdays
    let filteredWorkdays2 = filteredWorkdays.filter((workday) => {
      return (
        workday.extendedProps.contract === workTypes[parseInt(workType) - 2]
      )
    })
    if (parseInt(workType) === 1) filteredWorkdays2 = filteredWorkdays
    const filteredWorkdays3 = filteredWorkdays2.filter((workday) => {
      if (parseInt(workdayType) === 2) {
        return workday.extendedProps.isPlanned
      } else if (parseInt(workdayType) === 3) {
        return !workday.extendedProps.isPlanned
      } else return filteredWorkdays2
    })
    setFilteredWorkDays(filteredWorkdays3)
    setIsFilter(true)
    handleCloseBar()
  }

  return {
    initialValues,
    typeTime,
    setTypeTime,
    date,
    setDate,
    onSubmit,
    isPlanned,
    setIsPlanned,
    handleChange,
    workType,
    project,
    workdayType,
    status,
    projects,
    workTypes,
  }
}
