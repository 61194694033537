import { IProjects } from 'hooks/types'
import { useAuth } from 'hooks/useAuth'

export const usePMProjects = () => {
  const { user } = useAuth()
  const projects = user?.projects?.filter(
    (project: IProjects) => project?.ProjectUser.isPm === true
  )

  return projects
}
