import { DatePicker, CalendarPickerView, PickersDay } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { format, isWeekend } from 'date-fns'
import palette from 'theme/palette'
import { useCalendarContext } from 'pages/Calendar/context'

interface IDateProps {
  label: string
  value: Date | null
  setValue: Dispatch<SetStateAction<Date | null>>
  views?: CalendarPickerView[]
  inputFormat?: string
}

const DatePickerComponent: React.FC<IDateProps> = ({
  label,
  value,
  setValue,
  views,
  inputFormat,
}): JSX.Element => {
  const { holidays } = useCalendarContext()

  const holidaysStringArr = holidays.map((holiday) => holiday.start)

  return (
    <DatePicker
      label={label}
      value={value}
      inputFormat={inputFormat ? inputFormat : 'dd/MM/yyyy'}
      onChange={(newValue) => {
        setValue(newValue)
      }}
      renderInput={(params) => <TextField {...params} />}
      views={views}
      renderDay={(day, _, DayProps) => {
        const dayToString = format(new Date(day), 'yyyy-MM-dd')
        if (isWeekend(day) || holidaysStringArr.includes(dayToString)) {
          return (
            <PickersDay
              {...DayProps}
              day={day}
              sx={{ backgroundColor: palette.secondary.dark }}
            />
          )
        }

        return <PickersDay {...DayProps} day={day} />
      }}
    />
  )
}

export default DatePickerComponent
