import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import * as yup from 'yup'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usePersonalFields } from 'components/users'
import { useUpdateUser } from 'hooks/user'
import { permissionList, isUserPermitted } from 'services'
import { useAuth } from 'hooks/useAuth'

const UpdatePassword: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['userEditPage', 'validation', 'buttons'])
  const { userPasswordFields } = usePersonalFields()
  const updateUser = useUpdateUser()
  const { user: loggedUser } = useAuth()
  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t('newPassword.required', { ns: 'validation' }))
      .min(6, t('newPassword.minLength', { ns: 'validation' })),
    repeatNewPassword: yup
      .string()
      .required(t('repeatNewPassword.required', { ns: 'validation' }))
      .min(6, t('repeatNewPassword.minLength', { ns: 'validation' }))
      .oneOf(
        [yup.ref('password'), null],
        t('repeatNewPassword.oneOf', { ns: 'validation' })
      ),
  })
  const initialValues = {
    password: '',
    repeatNewPassword: '',
  }

  return (
    <>
      <Typography variant="h3">{t('personalInformation.title1')}</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            updateUser(values)
            setSubmitting(false)
          }, 500)
        }}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <>
              <Box mb={2}>
                {userPasswordFields.map((field) => (
                  <Field
                    fullWidth
                    key={`${field.id}-updatePassword`}
                    component={TextField}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                    variant="outlined"
                    margin="normal"
                    size="small"
                    autoComplete="on"
                  />
                ))}
              </Box>
            </>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                !isUserPermitted(loggedUser, [permissionList.USER_EDIT])
              }
              onClick={submitForm}
            >
              {t('save', { ns: 'buttons' })}
            </Button>
            <br />
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UpdatePassword
