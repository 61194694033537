import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, LinearProgress } from '@mui/material'

import { IInitialValuesBusinessUnit, useBusinessUnits } from '../../utils'
import { BusinessUnitForm } from '../../components'
interface AddBusinessUnitProps {
  initialValues: IInitialValuesBusinessUnit
  validationSchema: object
}
const AddBusinessUnit: React.FC<AddBusinessUnitProps> = ({
  initialValues,
  validationSchema,
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const { addBusiness } = useBusinessUnits()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        addBusiness({ ...values })
        setSubmitting(false)
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <BusinessUnitForm />
          <Button type="submit">{t('save')}</Button>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default AddBusinessUnit
