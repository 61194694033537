import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { IUsers } from 'hooks/types'

async function getAllUsers(): Promise<IUsers[]> {
  const { data }: AxiosResponse = await apiRequest(APIRoutes.USERS_GETLIST)
  return data
}

export const useGetUserList = () => {
  const fallbackUsers: IUsers[] = []

  const { data: usersList = fallbackUsers } = useQuery(
    queryKeys.usersAll,
    getAllUsers
  )
  return { usersList }
}
