import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { useMutation } from 'react-query'
import fileDownload from 'js-file-download'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

export function useDownloadReportCustomer(name: string) {
  const { setNotification } = useNotifications()
  const { t } = useTranslation('handleMessage')

  async function downloadReportForCustomer(customerData: {
    customer?: string | undefined
    date: string | null
  }) {
    const { date, customer } = customerData
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.REPORT_GENERATE_FOR_CUSTOMER,
      {},
      { customer, date }
    )
    return data
  }

  async function downloadReportForCustomers(customerData: {
    date: string | null
  }) {
    const { date } = customerData
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.REPORT_GENERATE_FOR_CUSTOMERS,
      {},
      { date }
    )
    return data
  }

  const { mutate: downloadCustomerReport } = useMutation(
    downloadReportForCustomer,
    {
      onSuccess: async (data) => {
        if (data.size) {
          fileDownload(new Blob([data]), name)
        } else {
          setNotification({
            message: t('noDataForMonth'),
            variant: NOTIFICATION.error,
          })
        }
      },
    }
  )

  const { mutate: downloadCustomersReport } = useMutation(
    downloadReportForCustomers,
    {
      onSuccess: async (data) => {
        if (data.size) {
          fileDownload(new Blob([data]), name)
        } else {
          setNotification({
            message: t('noDataForMonth'),
            variant: NOTIFICATION.error,
          })
        }
      },
    }
  )
  return {
    downloadCustomerReport,
    downloadCustomersReport,
  }
}
