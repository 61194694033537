import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { Workday } from 'pages/Calendar/context/types'
import { useTranslation } from 'react-i18next'
import palette from 'theme/palette'

import { useCalendarContext } from '../../context'

import { ListWrapper, ListItem } from './index.style'

const CopyDates = () => {
  const { theSameDays, copyMode, deleteMode } = useCalendarContext()
  const onlyPlannedDays = theSameDays.filter(
    (day) => day.extendedProps.isPlanned
  )

  const daysWithNoNeedApproval = theSameDays.filter(
    (day) => !day.extendedProps.needApproval
  )

  const { t } = useTranslation('calendar')
  return (
    <>
      <Typography variant="h2">{t('dates.title')}</Typography>
      <ListWrapper>
        {!copyMode && !deleteMode && theSameDays
          ? theSameDays.map((day: Workday, i: number) => (
              <ListItem
                color={palette.primary.main}
                variant="subtitle2"
                gutterBottom
                key={day.id}
              >
                {format(new Date(day.start), 'yyyy-MM-dd')} -{' '}
                {theSameDays[i].extendedProps.isPlanned
                  ? `${t('dates.type1')}`
                  : `${t('dates.type2')}`}
              </ListItem>
            ))
          : deleteMode && theSameDays
          ? daysWithNoNeedApproval.map((day: Workday, i: number) => (
              <ListItem
                color={palette.primary.main}
                variant="subtitle2"
                gutterBottom
                key={day.id}
              >
                {format(new Date(day.start), 'yyyy-MM-dd')} -{' '}
                {daysWithNoNeedApproval[i] && `${t('dates.type1')}`}
              </ListItem>
            ))
          : onlyPlannedDays.map((day: Workday, i: number) => (
              <ListItem
                color={palette.primary.main}
                variant="subtitle2"
                gutterBottom
                key={day.id}
              >
                {format(new Date(day.start), 'yyyy-MM-dd')} -{' '}
                {onlyPlannedDays[i] && `${t('dates.type1')}`}
              </ListItem>
            ))}
      </ListWrapper>
    </>
  )
}

export default CopyDates
