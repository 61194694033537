import { format } from 'date-fns'
import { Formik } from 'formik'
import { IUserProps } from 'components/users'

import { ISubmitData } from '../../../types'

import AllocationForm from './AllocationForm'
import { useValidationAllocationForm } from './utils'

interface formikValues {
  selectedProject: { name: string; id: number | undefined } | null
  date: Date
  hours: number | string
  fte: number | string
  isOverHours: boolean
}

export interface Props {
  formikValues: formikValues
  submitForm: (values: ISubmitData) => void
  editAllocationId?: number | null
}

const AllocationFormik: React.FC<IUserProps & Props> = ({
  formikValues,
  user,
  submitForm,

  editAllocationId,
}): JSX.Element => {
  const validationSchema = useValidationAllocationForm()

  return (
    <Formik
      initialValues={formikValues}
      onSubmit={(values, { setSubmitting }) => {
        submitForm({
          userProjectId: Number(values?.selectedProject?.id),
          allWorkingHours: Number(values.hours),
          isOverhours: values.isOverHours,
          fte: Number(values.fte),
          date: format(new Date(values.date), 'yyyy/MM'),
          id: editAllocationId,
        })
        setSubmitting(false)
      }}
      validationSchema={validationSchema}
    >
      <AllocationForm user={user} />
    </Formik>
  )
}

export default AllocationFormik
