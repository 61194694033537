import { Typography, FormGroup, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CheckboxWithLabel } from 'formik-mui'
import { Field } from 'formik'
import { EmptyArrayCard } from 'components/common'

import { IField } from '../types'
import { ISelectedOptions } from '../../../UserCV'
import { MainCard, PaperTitle } from '../index.style'
import OptionBox from '../OptionBox'

interface IProps {
  name: string
  title: string
  optionArray: (field: ISelectedOptions) => IField[]
  userDetails: ISelectedOptions[]
}

const CardCheckboxOption: React.FC<IProps> = ({
  title,
  optionArray,
  userDetails,
  name,
}): JSX.Element => {
  const { t } = useTranslation('userCV')
  return (
    <MainCard>
      <PaperTitle>
        <Typography variant="h3" color="primary.contrastText">
          {title}
        </Typography>
      </PaperTitle>
      {!userDetails.length ? (
        <EmptyArrayCard message={t('cv.eduTable.message')} />
      ) : (
        <FormGroup>
          {userDetails.map((field) => (
            <Paper
              key={`${field.id}-${name}`}
              sx={{ padding: '0.5rem', margin: '0.5rem 0' }}
            >
              <Field
                type="checkbox"
                component={CheckboxWithLabel}
                name={name}
                key={`${field.id}-${name}`}
                value={field?.id?.toString()}
                Label={{
                  label: (
                    <>
                      {optionArray(field).map(({ name, title, icon }) => (
                        <OptionBox
                          key={name}
                          name={name}
                          title={title}
                          icon={icon}
                        />
                      ))}
                    </>
                  ),
                }}
              />
            </Paper>
          ))}
        </FormGroup>
      )}
    </MainCard>
  )
}

export default CardCheckboxOption
