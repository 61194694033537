import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'
import { HeaderTab } from 'views/LayoutUI/components'
import { useHrDashboard } from 'pages/HrDashboard/hooks/'
import { PkupsDetails } from 'components/Pkup'
import { PageRoutes } from 'routes'

const HrDashboardPkupDetails: React.FC = (): JSX.Element => {
  const { id } = useParams()
  const { pkupsToReview } = useHrDashboard()
  const { t } = useTranslation('hrDashboard')
  const selectedPkup = pkupsToReview.find((pkup) => pkup.id === Number(id))

  return (
    <Container>
      <HeaderTab
        translate="hrDashboard"
        id={`${t('pkupDetails.id')} ${selectedPkup?.title}`}
        pageLink={PageRoutes.HR_DASHBOARD}
      />
      <PkupsDetails page="hrDashboard" />
    </Container>
  )
}
export default HrDashboardPkupDetails
