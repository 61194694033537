import { useTranslation } from 'react-i18next'
import { GridRowsProp } from '@mui/x-data-grid'
import { EmptyArrayCard, TableComponent } from 'components/common'

import { selectProjects } from '../SelectProjects'
interface YourProjectsProps {
  value: string
}

const YourProjects: React.FC<YourProjectsProps> = ({ value }): JSX.Element => {
  const { t } = useTranslation('dashboard')

  const rows: GridRowsProp = selectProjects(value)

  const columns = [
    `${t('tabPanels.panel1.name')}`,
    `${t('tabPanels.panel1.customer')}`,
    `${t('tabPanels.panel1.allocations')}`,
    '',
  ]

  return (
    <>
      {rows.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard message={t('tabPanels.panel1.message')} />
      )}
    </>
  )
}

export default YourProjects
