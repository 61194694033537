import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUpdateUser, useValidationForms } from 'hooks/user'
import { IUserProps, usePersonalFields } from 'components/users'
import { permissionList, isUserPermitted } from 'services'
import { useAuth } from 'hooks/useAuth'

const PersonalInformation: React.FC<IUserProps> = ({ user }): JSX.Element => {
  const { t } = useTranslation(['profile', 'validation', 'save'])
  const updateUser = useUpdateUser()
  const { userPersonalFields } = usePersonalFields()
  const { validationSchemaEditUser } = useValidationForms()
  const { user: loggedUser } = useAuth()
  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    companyPhoneNumber: user?.companyPhoneNumber || '',
    privatePhoneNumber: user?.privatePhoneNumber || '',
    privateEmail: user?.privateEmail || '',
    email: user?.email || '',
    probationPeriod: user?.probationPeriod || 0,
    maxHolidaysDays: user?.maxHolidaysDays || 0,
    jobPosition: user?.jobPosition || '',
  }

  return (
    <>
      <Typography variant="h3">{t('userInformation.title')}</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaEditUser}
        onSubmit={(values, { setSubmitting }) => {
          updateUser(values)
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <>
              {userPersonalFields.map((field) => (
                <Box key={`${field.id}-userInfo`}>
                  <Field
                    fullWidth
                    component={TextField}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                    variant="outlined"
                    margin="normal"
                    size="small"
                  />
                  <br />
                </Box>
              ))}
              <br />
            </>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={submitForm}
              disabled={
                !isUserPermitted(loggedUser, [permissionList.USER_EDIT])
              }
            >
              {t('save', { ns: 'buttons' })}
            </Button>
            <br />
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PersonalInformation
