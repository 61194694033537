import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

import { IData } from './types'

const getAllInventories = async (): Promise<IData> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.INVENTORIES_GETALL,
    {},
    {},
    'limit=99'
  )
  return data
}

export const useAllInventories = () => {
  const fallback = <IData>{}

  const { data: inventories = fallback } = useQuery(
    queryKeys.inventories,
    getAllInventories
  )
  return inventories
}
