import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { HeaderTab } from 'views/LayoutUI/components'
import DeleteIcon from '@mui/icons-material/Delete'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import {
  EmptyArrayCard,
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
} from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { ISelectingModal } from 'hooks/types'
import { StyledButton } from 'components/common/SwitchButton/index.style'
import { PageRoutes } from 'routes'

import { ILocationUser } from '../../useLocationsList'
import { AddUserToLocation } from '../../modals'
import { useLocations } from '../../useLocations'

interface IButtonArr {
  modal: string | null
  title: string
  icon: JSX.Element | boolean
}
const LocationUsersDashboard: React.FC = (): JSX.Element => {
  const { id } = useParams()
  const { location, deleteUser, toggleUser } = useLocations(Number(id))
  const { handleOpenModal, modalName, setOpenModal } = useNotifications()
  const navigate = useNavigate()
  const { user } = useAuth()
  const [person, setPerson] = useState<ILocationUser | undefined>(undefined)
  const { t } = useTranslation(['systemLocationsUsers', 'buttons'])
  const buttonsArr: IButtonArr[] = [
    {
      modal: null,
      title: `${t('calendar', { ns: 'buttons' })}`,
      icon: <CalendarMonthIcon />,
    },
    {
      modal: 'delete',
      title: `${t('delete', { ns: 'buttons' })}`,
      icon: <DeleteIcon color="error" />,
    },
  ]
  const columns = [
    `${t('dashboard.cell2')}`,
    `${t('dashboard.cell3')}`,
    `${t('dashboard.cell4')}`,
    ' ',
  ]
  const deleteContentArr = [
    {
      title: `${t('dashboard.modal.delete.text2')}`,
      date: `${person?.firstName} ${person?.lastName}`,
    },
    {
      title: `${t('dashboard.modal.delete.text3')}`,
      date: person?.email,
    },
  ]
  const deleteContent = () => (
    <Box>
      <Typography variant="subtitle1">
        {t('dashboard.modal.delete.text1')}
      </Typography>
      {deleteContentArr.map((field) => (
        <Box key={field.title} sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant="subtitle1">
            {field.title}: {field.date}
          </Typography>
        </Box>
      ))}
    </Box>
  )
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addUser':
        return {
          component: <AddUserToLocation location={location} />,
          title: `${t('dashboard.modal.addUser.title')}`,
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteUser(Number(person?.id))}
            />
          ),
          title: `${t('modal.delete.title')}`,
        }
      default:
        return { component: <DefaultModal />, title: 'Default modal' }
    }
  }
  const handleOpen = async (
    user: ILocationUser | null,
    name: string | null
  ) => {
    handleOpenModal(name)
    name === null ? navigate(`/calendar/${user?.id}`) : setOpenModal(true)
  }
  const rows: GridRowsProp = location?.users?.map((person: ILocationUser) => {
    return {
      name: `${person.firstName + ' ' + person.lastName}`,
      email: person.email,
      isDirector: (
        <>
          {isUserPermitted(user, [permissionList.BUSINESS_UNIT_ADD_USER]) && (
            <StyledButton
              valueistrue={
                person?.UserLocation?.isDirector === null
                  ? 'null'
                  : person?.UserLocation?.isDirector.toString()
              }
              onClick={() => toggleUser(person?.id)}
            >
              {person?.UserLocation?.isDirector === null ? (
                t('table.button3')
              ) : person?.UserLocation?.isDirector ? (
                <ThumbUpIcon color="success" />
              ) : (
                <ThumbDownIcon color="error" />
              )}
            </StyledButton>
          )}
        </>
      ),
      button: (
        <Box onClick={() => setPerson(person)}>
          {buttonsArr.map(
            (button: IButtonArr) =>
              isUserPermitted(user, [
                button.modal === 'delete'
                  ? permissionList.LOCATION_ADD_USER
                  : permissionList.WORKDAY_READ_FOR_USER,
              ]) && (
                <Tooltip
                  title={button.title}
                  key={button.title}
                  onClick={() => handleOpen(person, button.modal)}
                >
                  <IconButton>{button.icon}</IconButton>
                </Tooltip>
              )
          )}
        </Box>
      ),
    }
  }) as []

  return (
    <Container>
      <HeaderTab
        translate="systemLocationsUsers"
        id={`${t('header.id2')} ${location.name}`}
        pageLink={PageRoutes.SYSTEM_LOCATIONS}
        subPage={t('header.title')}
      />
      <Typography variant="h3">
        {location?.users?.length ? (
          <EmptyArrayCard
            message={`${location?.name} ${t('dashboard.text1')} ${
              location?.users?.length
            }${' '}
          ${t('dashboard.text2')}`}
          >
            {isUserPermitted(user, [permissionList.LOCATION_ADD_USER]) && (
              <Button onClick={() => handleOpen(null, 'addUser')}>
                {t('dashboard.button')}
              </Button>
            )}
          </EmptyArrayCard>
        ) : (
          <EmptyArrayCard message={`${location?.name} ${t('dashboard.text3')}`}>
            {isUserPermitted(user, [permissionList.LOCATION_ADD_USER]) && (
              <Button onClick={() => handleOpen(null, 'addUser')}>
                {t('dashboard.button')}
              </Button>
            )}
          </EmptyArrayCard>
        )}
      </Typography>
      {location?.users?.length !== 0 && (
        <TableComponent rows={rows} columns={columns} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}
export default LocationUsersDashboard
