import axios, { AxiosRequestConfig } from 'axios'
import { getStoredUser, getTokenOther } from 'user-storage'

const REACT_API_URL = (
  window as Window & typeof globalThis & { REACT_API_URL: string }
).REACT_API_URL

const baseUrl =
  (REACT_API_URL === undefined ? false : REACT_API_URL) ||
  process.env.REACT_APP_API_URL ||
  'https://api-dev-timesheet.bcf-software.com'

export function getJWTHeader(token: string | null) {
  return { Authorization: `Bearer ${token}` }
}

const config: AxiosRequestConfig = { baseURL: baseUrl }

export const axiosInstance = axios.create(config)

axiosInstance.interceptors.request.use(
  (config) => {
    const otherUserToken = getTokenOther()
    const userToken = getStoredUser()
    const idToken = otherUserToken || userToken
    if (idToken) {
      config.headers = {
        'Access-Control-Allow-Origin': '*',
        ...(config.headers || {}),
      }
    }
    if (otherUserToken) {
      config.headers = {
        AuthorizationOther: `Bearer ${otherUserToken}`,
        ...(config.headers || {}),
      }
    }
    if (userToken) {
      config.headers = {
        Authorization: `Bearer ${userToken}`,
        ...(config.headers || {}),
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
