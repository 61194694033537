import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const useValidationForms = () => {
  const { t } = useTranslation('validation')
  const validation = {
    firstName: yup.string().required(t('firstName.required')),
    lastName: yup.string().required(t('firstName.required')),
    companyPhoneNumber: yup.string(),
    privatePhoneNumber: yup.string(),
    privateEmail: yup.string().email(),
  }
  const validationProfileSchema = yup.object({
    ...validation,
  })
  const validationSchemaEditUser = yup.object({
    ...validation,
    email: yup.string().email(t('email.email')).required(t('email.required')),
    probationPeriod: yup.number().min(0, t('number.minValue')),
    maxHolidaysDays: yup.number().min(0, t('number.minValue')),
    jobPosition: yup.string(),
  })
  const validationDates = {
    startDate: yup.date().required('agreements.startDate'),
    endDate: yup
      .date()
      .when('indefinite', {
        is: false,
        then: yup
          .date()
          .when(
            'startDate',
            (startDate, schema) =>
              startDate && schema.min(startDate, t('endDate.minValue'))
          ),
      })
      .nullable(true),
  }
  const validationAddUserAgreement = yup.object({
    ...validationDates,
    agreementType: yup.string().required(t('agreements.selectAgreement')),
  })
  const validationAgreementDates = yup.object({
    ...validationDates,
  })

  return {
    validationSchemaEditUser,
    validationProfileSchema,
    validationAddUserAgreement,
    validationAgreementDates,
  }
}
