import { Dispatch, SetStateAction } from 'react'
import { Box } from '@mui/material'
import { IVacationRequest } from 'pages/VacationRequest/hooks/useVacationRequests'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'

import { UsersRequests } from '../'

interface EmployeeVacationRequestProps {
  setSelectedRequest: Dispatch<SetStateAction<IVacationRequest | undefined>>
}
const EmployeeVacationRequest: React.FC<EmployeeVacationRequestProps> = ({
  setSelectedRequest,
}): JSX.Element => {
  const { user } = useAuth()
  return (
    <>
      {isUserPermitted(user, [permissionList.VACATION_REQUESTS_READ]) && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <UsersRequests
            tableTitle="future"
            setSelectedRequest={setSelectedRequest}
          />
          <UsersRequests
            setSelectedRequest={setSelectedRequest}
            tableTitle="previous"
          />
        </Box>
      )}
    </>
  )
}
export default EmployeeVacationRequest
