import React from 'react'
import { EventContentArg } from '@fullcalendar/react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IProps {
  eventInfo: EventContentArg
}

const EventListContent: React.FC<IProps> = ({ eventInfo }) => {
  const { t } = useTranslation('calendar')
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <b>{t('eventList.title1')}</b>
        <i>{eventInfo.event._def.title}</i>
      </Box>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <b>{t('eventList.title1')}</b>
        <i>{eventInfo.event._def.extendedProps.contract}</i>
      </Box>
    </Box>
  )
}

export default EventListContent
