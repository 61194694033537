import { LinearProgress, styled } from '@mui/material'
type props = {
  progressvalue: number
  barcolor: string
}

export const GradientLinearProgress = styled(LinearProgress)<props>(
  ({ theme, progressvalue }) => ({
    height: 10,
    borderRadius: 5,
    backgroundColor: '#eee',
    '& .MuiLinearProgress-bar': {
      background: `linear-gradient(90deg, ${theme.palette.primary.light} ${
        100 - progressvalue
      }%, #9c64f4 100%)`,
    },
  })
)
