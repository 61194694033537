import { SetStateAction, Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import { GridRowsProp } from '@mui/x-data-grid'
import HistoryIcon from '@mui/icons-material/History'
import { Box, Button, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  EmptyArrayCard,
  TableComponent,
  useNotifications,
} from 'components/common'
import { useDeleteTimeframes } from 'hooks/customers'

import { dateFormat } from '../../utils'

import { useGetUserTimeFrames } from './utils'

interface ITimeFrameProps {
  setTimeFrame: Dispatch<SetStateAction<number | null>>
}

const TimeFrames: React.FC<ITimeFrameProps> = ({
  setTimeFrame,
}): JSX.Element => {
  const { t } = useTranslation('userAllocations')
  const { handleOpenModal } = useNotifications()
  const { deleteTimeframe } = useDeleteTimeframes()

  const { userTimeFrames, getProjectNameById, getCurrentTimeFrame } =
    useGetUserTimeFrames()
  const columns = [
    `${t('timeFrames.col1')}`,
    `${t('timeFrames.col2')}`,
    `${t('timeFrames.col3')}`,
    `${t('timeFrames.col4')}`,
    '',
  ]

  const timeFrame = userTimeFrames?.map(
    ({ ProjectId, UserProjectTimeframes }) => {
      return {
        projectName: getProjectNameById(ProjectId),
        startDate: dateFormat(
          getCurrentTimeFrame(UserProjectTimeframes)?.startDate
        ),
        endDate: dateFormat(
          getCurrentTimeFrame(UserProjectTimeframes)?.endDate
        ),
        notes: getCurrentTimeFrame(UserProjectTimeframes)?.notes,
        buttons: (
          <Box display="flex">
            <IconButton
              onClick={() => {
                setTimeFrame(ProjectId)
                handleOpenModal('historyTimeFrames')
              }}
            >
              <HistoryIcon />
            </IconButton>
            {UserProjectTimeframes.length === 1 && (
              <IconButton
                onClick={() => deleteTimeframe(UserProjectTimeframes[0].id)}
              >
                <DeleteIcon color="error" />
              </IconButton>
            )}
          </Box>
        ),
      }
    }
  )
  const rows: GridRowsProp = timeFrame
  return (
    <Box>
      <Button onClick={() => handleOpenModal('addTimeframes')}>
        {t('timeFrames.addButton')}
      </Button>
      {rows.length ? (
        <TableComponent rows={rows} columns={columns} />
      ) : (
        <EmptyArrayCard message={t('timeFrames.noTimeFrames')} />
      )}
    </Box>
  )
}

export default TimeFrames
