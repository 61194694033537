import axios, { AxiosResponse } from 'axios'
import { useState } from 'react'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'

export const useForgotPassword = () => {
  const [description, setDescription] = useState('')
  const { setNotification } = useNotifications()

  const { t } = useTranslation([
    'forgotPassword',
    'validation',
    'handleMessage',
  ])
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('email.email', { ns: 'validation' }))
      .required(t('email.required', { ns: 'validation' })),
  })

  const remindPassword = async (email: { email: string }) => {
    try {
      const { data }: AxiosResponse = await apiRequest(
        APIRoutes.REMIND_PASSWORD,
        email
      )

      if (data.success) {
        setDescription(t('successDescription'))
      } else {
        setNotification({
          message: data.message,
          variant: NOTIFICATION.error,
        })
      }
    } catch (error) {
      const ERROR = t('networkError', { ns: 'handleMessage' })
      const errorTitle = axios.isAxiosError(error)
        ? error?.response?.data?.message ||
          error.toString().replace('Error: ', '')
        : ERROR
      setNotification({
        message: errorTitle,
        variant: NOTIFICATION.error,
      })
    }
  }

  return { remindPassword, validationSchema, description }
}
