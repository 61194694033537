import { useState } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { AutocompleteUserInput } from 'components/common'
import { useUpdateUserInventory, IEditInventory } from 'hooks/inventory'

interface IProps {
  inventory: IEditInventory
  rewrite?: boolean
}

const RewriteUser: React.FC<IProps> = ({
  inventory,
  rewrite = false,
}): JSX.Element => {
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const { t } = useTranslation(['validation', 'buttons', 'inventory'])
  const { updateUserInventory } = useUpdateUserInventory()
  const validationSchema = yup.object({
    assignUser: yup.string().required(t('selectUser.required')),
  })

  return (
    <Box>
      <Formik
        initialValues={{ assignUser: '' }}
        onSubmit={(values, { setSubmitting }) => {
          updateUserInventory({ ...inventory, assignUser: selectedUserId })
          setSubmitting(false)
        }}
        validationSchema={validationSchema}
      >
        {({ errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
            {rewrite && (
              <>
                <Typography variant="h3">
                  <b>
                    {t('modal.userInventory.table.userName', {
                      ns: 'inventory',
                    })}
                    :
                  </b>{' '}
                  {inventory.userName}
                </Typography>
                <Typography variant="h3">
                  <b>
                    {t('modal.userInventory.table.producer', {
                      ns: 'inventory',
                    })}
                    :
                  </b>{' '}
                  {inventory.producer}
                </Typography>
                <Typography variant="h3">
                  <b>
                    {t('modal.userInventory.table.model', { ns: 'inventory' })}:
                  </b>{' '}
                  {inventory.model}
                </Typography>

                <Typography variant="h3">
                  <b>
                    {t('modal.userInventory.table.serialNumber', {
                      ns: 'inventory',
                    })}
                    :
                  </b>{' '}
                  {inventory.serialNumber}
                </Typography>
                {inventory.additionalInformation && (
                  <Typography variant="h3">
                    <b>
                      {t('modal.userInventory.table.additionalInformation', {
                        ns: 'inventory',
                      })}
                      :
                    </b>{' '}
                    {inventory.additionalInformation}
                  </Typography>
                )}
              </>
            )}
            <AutocompleteUserInput
              name="assignUser"
              checkUser={true}
              setSelectedUserId={setSelectedUserId}
              setFieldValue={setFieldValue}
            />
            {errors.assignUser && touched.assignUser && (
              <Typography color="error" variant="h6">
                {errors.assignUser}
              </Typography>
            )}
            <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default RewriteUser
