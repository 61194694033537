import { useTranslation } from 'react-i18next'
import { Grid, IconButton, Paper, Tooltip } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { IProjects, useUser } from 'pages/Users/pages/utlis'
import { EmptyArrayCard, TableComponent, TableTitle } from 'components/common'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { IButtonArr } from 'hooks/types'

import { ISelectedOptions } from '../UserCV'

interface BioAreaProps {
  handleOpen: (
    name: string,
    position: ISelectedOptions,
    category: string
  ) => void
  buttonsArr: IButtonArr[]
}
const ProjectsArea: React.FC<BioAreaProps> = ({
  handleOpen,
  buttonsArr,
}): JSX.Element => {
  const { t } = useTranslation(['userCV', 'buttons'])
  const { userProjects } = useUser()
  const { user } = useAuth()

  const userProjectsColumns = [
    `${t('cv.projectsTable.name')}`,
    `${t('cv.projectsTable.description')}`,
    `${t('cv.projectsTable.stack')}`,
    '',
  ]

  const userProjectsRows: GridRowsProp = userProjects.map(
    (project: IProjects) => {
      return {
        title: project.title,
        description: project.description,
        stack: project.stack,
        button: buttonsArr.map(
          (button) =>
            isUserPermitted(user, [
              button.modal === 'edit'
                ? permissionList.CV_UPDATE
                : permissionList.CV_DELETE,
            ]) && (
              <Tooltip key={button.title} title={button.title as string}>
                <IconButton
                  onClick={() =>
                    handleOpen(button.modal as string, project, 'projects')
                  }
                >
                  {button.icon}
                </IconButton>
              </Tooltip>
            )
        ),
      }
    }
  )

  return (
    <Grid item xs={10} md={12} sx={{ background: 'none', border: 'none' }}>
      <TableTitle>{t('cv.projectsTable.projects')}</TableTitle>
      <Paper>
        {userProjectsRows.length === 0 ? (
          <EmptyArrayCard message={t('cv.projectsTable.message')} />
        ) : (
          <TableComponent
            columns={userProjectsColumns}
            rows={userProjectsRows}
          />
        )}
      </Paper>
    </Grid>
  )
}

export default ProjectsArea
