import { Paper, styled, TableRow } from '@mui/material'

export const PaperField = styled(Paper)`
  padding: 1rem;
  margin-bottom: 2rem;
`

export const BoxContainer = styled(Paper)`
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`
export const TableRowBox = styled(TableRow)`
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`
