import * as yup from 'yup'
import { Formik } from 'formik'
import { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IInitialValues, useGenerateCV } from '../UserCV/hooks/useGenerateCV'

import CVForm from './CVForm'

interface CVFormikProps {
  setCVPreview: Dispatch<SetStateAction<string>>
  cvPreview: string
}
const CVFormik: React.FC<CVFormikProps> = ({ setCVPreview, cvPreview }) => {
  const { id } = useParams()
  const { t } = useTranslation('validation')
  const initialValues: IInitialValues = {
    selectedTemplate: 'bcf-blue',
    selectedProfile: null,
    selectedBio: '',
    selectedEducation: [],
    selectedProjects: [],
  }

  const { previewUserCV } = useGenerateCV()
  const validationSchema = yup.object({
    selectedBio: yup.string().required(t('bio.required')),
    selectedProfile: yup
      .object()
      .required(t('profile.required'))
      .nullable(true),
  })
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false)
        const biosValues = values.selectedBio
          ? `&biosIds=${values.selectedBio}`
          : ''
        const educationValues = values.selectedEducation
          ? values.selectedEducation.map(
              (value: string) => `&educationsIds=${value}`
            )
          : ''
        const projectValues = values?.selectedProjects
          ? values?.selectedProjects?.map(
              (value: string) => `&projectsIds=${value}`
            )
          : ''
        const paramsValue = `template=${values.selectedTemplate}&userId=${id}${biosValues}${educationValues}${projectValues}`
        previewUserCV(paramsValue).then((data) => {
          setCVPreview(data)
        })
      }}
      enableReinitialize
      validationSchema={validationSchema}
    >
      <CVForm
        initialValues={initialValues}
        setCVPreview={setCVPreview}
        cvPreview={cvPreview}
      />
    </Formik>
  )
}

export default CVFormik
