import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Container,
  Button,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { HeaderTab } from 'views/LayoutUI/components'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  EmptyArrayCard,
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
} from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { IButtonArr, ISelectingModal } from 'hooks/types'
import { timeoutAnimation } from 'utils'

import { ILocation, useLocationsList } from './useLocationsList'
import { AddLocation, EditLocation } from './modals'

export interface IInitialValues {
  name?: string
}
const Locations: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['systemLocations', 'buttons', 'validation'])
  const { handleOpenModal, modalName, setModalName } = useNotifications()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { locationsList } = useLocationsList()
  const { deleteLocation } = useLocationsList()

  const [selectedLocation, setSelectedLocation] = useState<ILocation>({
    createdAt: new Date(),
    deletedAt: null,
    id: 0,
    name: '',
    updatedAt: null,
    users: [],
  })

  const columns = ['#', `${t('table.cell2')}`, `${t('table.cell3')}`, '']
  const buttonsArr: IButtonArr[] = [
    {
      title: `${t('table.tooltip1')}`,
      icon: <DashboardIcon />,
      modal: null,
    },
    {
      title: `${t('edit', { ns: 'buttons' })}`,
      icon: <EditIcon />,
      modal: 'edit',
    },
    {
      title: `${t('delete', { ns: 'buttons' })}`,
      icon: <DeleteIcon color="error" />,
      modal: 'delete',
    },
  ]
  const initialValuesAdd: IInitialValues = {
    name: '',
  }
  const initialValuesEdit: IInitialValues = {
    name: '' || selectedLocation?.name,
  }
  const validationSchema = yup.object({
    name: yup
      .string()
      .required(`${t('name.required', { ns: 'validation' })}`)
      .min(3, `${t('name.minLength', { ns: 'validation' })}`),
  })
  const deleteContent = (
    <Typography variant="subtitle1">
      {t('modal.delete.text1')}: {selectedLocation?.name}?
    </Typography>
  )
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addLocation':
        return {
          component: (
            <AddLocation
              initialValues={initialValuesAdd}
              validationSchema={validationSchema}
            />
          ),
          title: `${t('modal.add.title')}`,
        }
      case 'edit':
        return {
          component: (
            <EditLocation
              location={selectedLocation}
              initialValues={initialValuesEdit}
              validationSchema={validationSchema}
            />
          ),
          title: `${t('modal.edit.title')}`,
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent}
              deleteFunction={() =>
                deleteLocation(selectedLocation?.id as number)
              }
            />
          ),
          title: `${t('modal.delete.title')}`,
        }
      default:
        return { component: <DefaultModal />, title: `${t('modal.default')}` }
    }
  }
  const handleOpen = async (
    location: ILocation | null,
    name: string | null
  ) => {
    location && setSelectedLocation(location)
    setModalName(name)
    handleOpenModal(name)
    !name && navigate(`/system/locations/${location?.id}/users`)
  }
  const rows: GridRowsProp = locationsList?.map((location: ILocation) => {
    return {
      id: location.id,
      name: location.name,
      users: location?.users?.length,
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map(
            (button) =>
              isUserPermitted(user, [
                button.modal === 'edit'
                  ? permissionList.LOCATION_EDIT
                  : permissionList.LOCATION_DELETE,
              ]) && (
                <Tooltip
                  title={button.title as string}
                  key={button.title}
                  onClick={() => handleOpen(location, button.modal as string)}
                >
                  <IconButton>{button.icon}</IconButton>
                </Tooltip>
              )
          )}
        </Box>
      ),
    }
  })

  return (
    <Container>
      <HeaderTab translate="systemLocations" id={t('header.id')} />
      {isUserPermitted(user, [permissionList.LOCATION_CREATE]) && (
        <Button onClick={() => handleOpen(null, 'addLocation')}>
          {t('button')}
        </Button>
      )}
      {rows.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent rows={rows} columns={columns} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}
export default Locations
