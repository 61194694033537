import * as yup from 'yup'
import { Formik } from 'formik'
import { useAllUsers } from 'hooks/useAllUsers'
import { useGetAllCustomers } from 'hooks/customers'
import { useGetAllProjects } from 'hooks/projects'
import { useTranslation } from 'react-i18next'

import { useReportAllocationContext } from '../../context'

import AllocationForm from './AllocationForm'

interface IReportAllocationsForm {
  groupBy: string
}

const ReportAllocationsForm: React.FC<IReportAllocationsForm> = ({
  groupBy,
}) => {
  const { t } = useTranslation('validation')

  const allUsers = useAllUsers()
  const projectList = useGetAllProjects()
  const customersList = useGetAllCustomers()
  const { getUsersAllocations, getCustomerAllocations } =
    useReportAllocationContext()
  const validationSchema = yup.object({
    option: yup.array().min(1, t('reportAllocation')),
  })

  return (
    <Formik
      initialValues={{ option: [], quarter: new Date() }}
      onSubmit={(values, { setSubmitting }) => {
        groupBy === 'users'
          ? getUsersAllocations({
              date: values.quarter,
              projects: projectList.map((project) => project.id),
              users: values.option.map(({ id }) => id),
              customers: customersList.map((customer) => customer.id),
            })
          : getCustomerAllocations({
              date: values.quarter,
              projects: projectList.map((project) => project.id),
              users: allUsers.map((user) => user.id),
              customers: values.option.map(({ id }) => id),
            })
        setSubmitting(false)
      }}
      validationSchema={validationSchema}
    >
      <AllocationForm groupBy={groupBy} />
    </Formik>
  )
}

export default ReportAllocationsForm
