import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useValidationForms } from 'hooks/user'
import { usePersonalFields, IUserProps } from 'components/users'

import { usePersonalInformation } from '../utils'

const PersonalInformation: React.FC<IUserProps> = ({ user }): JSX.Element => {
  const { t } = useTranslation(['profile', 'validation', 'buttons'])
  const { personalFields } = usePersonalFields()
  const patchUser = usePersonalInformation()
  const { validationProfileSchema } = useValidationForms()

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    companyPhoneNumber: user?.companyPhoneNumber || '',
    privatePhoneNumber: user?.privatePhoneNumber || '',
    privateEmail: user?.privateEmail || '',
  }

  return (
    <>
      <Typography variant="h3">{t('userInformation.title')}</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationProfileSchema}
        onSubmit={(values, { setSubmitting }) => {
          patchUser(values)
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <>
              {personalFields.map((field) => (
                <Box key={`${field.id}-userInfo`}>
                  <Field
                    fullWidth
                    component={TextField}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                    variant="outlined"
                    margin="normal"
                    size="small"
                  />
                  <br />
                </Box>
              ))}
              <br />
            </>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {t('save', { ns: 'buttons' })}
            </Button>
            <br />
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PersonalInformation
