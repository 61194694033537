import {
  Autocomplete,
  TextField,
  Checkbox,
  createFilterOptions,
  Box,
  Typography,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useTranslation } from 'react-i18next'

export interface ISelectedOptions {
  name: string
  id: number
}

interface IProps {
  form: {
    setFieldValue: (name: string, value: ISelectedOptions[]) => void
  }
  field: {
    name: string
    id: string
    value: any
  }
  error?: string
  label: string
  optionsData: ISelectedOptions[]
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const AutoCompleteCheckAll: React.FC<IProps> = ({
  form,
  field,
  error,
  optionsData,
  label,
}): JSX.Element => {
  const { name, value } = field
  const { setFieldValue } = form
  const { t } = useTranslation('reportAllocations')

  const allSelected = value.length === optionsData?.length
  const handleToggleOption = (selectedOptions) => {
    setFieldValue(name, selectedOptions)
  }

  const getOptionLabel = (option) => `${option.name}`
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setFieldValue(name, optionsData)
    } else {
      setFieldValue(name, [])
    }
  }

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected)
  }
  const handleChange = (_, selectedOptions, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOptions.find((option) => option.id === 0)) {
        handleToggleSelectAll()
      } else {
        handleToggleOption(selectedOptions)
      }
    } else if (reason === 'clear') {
      setFieldValue(name, [])
    }
  }

  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps = option.id === 0 ? { checked: allSelected } : {}
    return (
      <li {...props} key={option.id}>
        <Checkbox
          icon={icon}
          checked={selected}
          checkedIcon={checkedIcon}
          {...selectAllProps}
        />
        {option.name}
      </li>
    )
  }
  return (
    <>
      <Autocomplete
        multiple
        limitTags={5}
        disableListWrap={true}
        options={optionsData}
        disableCloseOnSelect
        value={value}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, { id }) =>
          name === t('form.name') || option.id === id
        }
        renderOption={optionRenderer}
        noOptionsText="No options"
        onChange={(e, newValue, reason) => {
          handleChange(e, newValue, reason)
        }}
        renderInput={(params) => {
          const { InputProps, ...restParams } = params
          const { startAdornment, ...restInputProps } = InputProps
          return (
            <TextField
              {...restParams}
              label={label}
              name={name}
              variant="outlined"
              InputProps={{
                ...restInputProps,
                startAdornment: (
                  <Box
                    sx={{
                      maxHeight: '84px',
                      overflowY: 'auto',
                    }}
                  >
                    {startAdornment}
                  </Box>
                ),
              }}
            />
          )
        }}
        filterOptions={(options, params) => {
          const filtered = createFilterOptions()(options, params)
          return [{ id: 0, name: t('form.name') }, ...filtered]
        }}
      />
      {error && (
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      )}
    </>
  )
}

export default AutoCompleteCheckAll
