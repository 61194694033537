import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Field, Form, Formik } from 'formik'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
} from '@mui/material'
import { CustomAutocompleteInput } from 'components/common'
import { useAllUsers } from 'hooks/useAllUsers'

import { useBusinessUnits } from '../../utils'

interface IValues {
  user: {
    id: number
  }
  isDirector: string
}
interface AddBusinessUnitProps {
  businessUnitId: number | undefined
}

const AddUser: React.FC<AddBusinessUnitProps> = ({
  businessUnitId,
}): JSX.Element => {
  const { t } = useTranslation(['systemBusinessUnits', 'buttons', 'validation'])
  const { addUser } = useBusinessUnits(businessUnitId)
  const allUsers = useAllUsers()

  const [isDirector, setIsDirector] = useState(false)
  const [canManage, setCanManage] = useState(false)
  const initialValues = {} as IValues
  const validationSchema = yup.object({
    user: yup.object().required(t('user.required', { ns: 'validation' })),
  })
  return (
    <Box sx={{ marginTop: '1.5rem' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          values?.user?.id !== undefined &&
            addUser({
              userId: values?.user?.id,
              isDirector: isDirector,
              canManageRequests: canManage,
            })
          actions.setSubmitting(false)
        }}
      >
        {({ isSubmitting, errors }) => {
          return (
            <Form style={{ display: 'flex', flexDirection: 'column' }}>
              <Field
                component={CustomAutocompleteInput}
                optionsData={
                  allUsers.map((user) => {
                    return {
                      name: `${user.firstName} ${user.lastName} <${user.email}>`,
                      id: user.id,
                    }
                  }) || []
                }
                name="user"
                label={t('modal.addUser.label1')}
                disableClearable={true}
                error={errors.user}
              />
              <Field
                as={FormControlLabel}
                type="checkbox"
                name="isDirector"
                control={<Checkbox checked={isDirector} />}
                label={t('modal.addUser.label2')}
                onChange={() => setIsDirector(!isDirector)}
              />
              <Field
                as={FormControlLabel}
                type="checkbox"
                name="canManage"
                control={<Checkbox checked={canManage} />}
                label={t('modal.addUser.label3')}
                onChange={() => setCanManage(!canManage)}
              />
              <Button
                disabled={isSubmitting}
                type="submit"
                sx={{ width: '30%' }}
              >
                {t('save', { ns: 'buttons' })}
              </Button>
              {isSubmitting && <LinearProgress />}
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}
export default AddUser
