import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useUserDetails } from 'hooks/useUserDetails'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import BuildCircleIcon from '@mui/icons-material/BuildCircle'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EditIcon from '@mui/icons-material/Edit'
import NotesIcon from '@mui/icons-material/Notes'
import ReportIcon from '@mui/icons-material/Report'
import CircularProgress from '@mui/material/CircularProgress'
import palette from 'theme/palette'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { IButtonArr } from 'hooks/types'
import { useIsFetching } from 'react-query'

interface DetailsTooltipProps {
  id: number | null
  children: JSX.Element
}
const DetailsTooltip: React.FC<DetailsTooltipProps> = ({ id, children }) => {
  const { t } = useTranslation(['users', 'validation'])
  const { user } = useAuth()
  const isFetchingDetails = useIsFetching(['userDetails'])

  const { userDetails, refetch } = useUserDetails(id)
  const navigate = useNavigate()
  const buttonsArr: IButtonArr[] = [
    {
      title: `${t('userDetails.tooltip1')}`,
      icon: <EditIcon />,
      path: `/users/${userDetails.id}`,
      permissions: [permissionList.USER_READ, permissionList.USER_EDIT],
    },
    {
      title: `${t('userDetails.tooltip2')}`,
      icon: <AddLocationIcon />,
      path: `/users/${userDetails.id}/allocations`,
      permissions: [
        permissionList.USER_READ,
        permissionList.USER_EDIT,
        permissionList.ALLOCATION_MANAGER_VIEW,
      ],
    },
    {
      title: `${t('userDetails.tooltip3')}`,
      icon: <NotesIcon />,
      path: `/users/${userDetails.id}/notes`,
      permissions: [
        permissionList.USER_READ,
        permissionList.USER_EDIT,
        permissionList.EMPLOYEE_NOTES_MANAGEMENT,
      ],
    },
    {
      title: `${t('userDetails.tooltip4')}`,
      icon: <CalendarMonthIcon />,
      path: `/calendar/${userDetails.id}`,
      permissions: [permissionList.WORKDAY_READ_FOR_USER],
    },
  ]
  const userDataArr = [
    {
      id: 1,
      name: <>{t('userDetails.text1')}&nbsp;</>,
      content: userDetails?.title || `${t('userDetails.worker')}`,
    },
    {
      id: 2,
      name: <>{t('userDetails.text2')}&nbsp;</>,
      content: userDetails?.firstName || '---',
    },
    {
      id: 3,
      name: <>{t('userDetails.text3')}&nbsp;</>,
      content: userDetails?.lastName || '---',
    },
    {
      id: 4,
      name: <>{t('userDetails.text4')}&nbsp;</>,
      content: userDetails?.startDate?.slice(0, 10) || '---',
    },
  ]

  return (
    <Tooltip
      placement="bottom-start"
      arrow={true}
      enterNextDelay={300}
      onOpen={() => {
        refetch()
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'common.white',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: '0px 0px 3px 0px #1a3d92',
            fontSize: 11,
            width: 300,
            '& .MuiTooltip-arrow': {
              color: 'common.white',
              '&::before': {
                boxShadow: '0px 0px 3px 0px #1a3d92',
              },
            },
          },
        },
      }}
      title={
        <Box sx={{ padding: '0.5rem', width: '100%' }}>
          {isFetchingDetails ? (
            <Box
              minHeight={170}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '1rem',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '0.5rem',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      width: 'fit-content',
                      color: `${
                        !userDetails?.currentWork
                          ? palette.warning.main
                          : palette.primary.main
                      }`,
                    }}
                  >
                    {userDetails?.currentWork?.project?.name
                      ? userDetails?.currentWork?.project.name
                      : `${t('userDetails.work')}`}
                  </Typography>

                  {!userDetails?.currentWork ? (
                    <ReportIcon color="warning" sx={{ marginLeft: '0.5rem' }} />
                  ) : (
                    <BuildCircleIcon
                      color="primary"
                      sx={{ marginLeft: '0.5rem' }}
                    />
                  )}
                </Box>
              </Box>
              {userDataArr.map((user) => (
                <Box sx={{ display: 'flex' }} key={user.id}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {user.name}
                  </Typography>
                  <Typography variant="subtitle2" color="primary">
                    {user.content}
                  </Typography>
                </Box>
              ))}

              <Box
                sx={{
                  paddingTop: '0.5rem',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {buttonsArr.map(
                  (button) =>
                    isUserPermitted(user, button.permissions) && (
                      <Tooltip
                        title={button.title as string}
                        key={button.title}
                        onClick={() => navigate(button.path as string)}
                      >
                        <IconButton>{button.icon}</IconButton>
                      </Tooltip>
                    )
                )}
              </Box>
            </>
          )}
        </Box>
      }
    >
      {children}
    </Tooltip>
  )
}
export default DetailsTooltip
