import {
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  Box,
} from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Formik, Form, Field } from 'formik'
import { format } from 'date-fns'
import { CustomizedSelect, CustomizedTimePicker } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import palette from 'theme/palette'
import { TextField } from 'formik-mui'
import { useWorkTypes } from 'utils'
import { useAuth } from 'hooks/useAuth'

import { useCalendarContext } from '../../context'

import { useWorkdaysEditModal } from './utils'
import {
  ButtonContainer,
  CurrentInformationsBox,
  CurrentInformationsContainer,
  ModalWrapper,
  SetupProjectBox,
  StyledButton,
  TypographyWrapper,
} from './index.style'
import Reactions from './components/Reactions'

const EventClickModal: React.FC = (): JSX.Element => {
  const { clickedInfo } = useCalendarContext()
  const { t } = useTranslation(['calendar', 'buttons'])
  const { id } = useParams()
  const {
    initialValues,
    onSubmitEditHandler,
    handleDeleteEvent,
    handleCopySingleEvent,
  } = useWorkdaysEditModal()
  const { workTypesOnlyForSelectedProject } = useWorkTypes()

  const { user } = useAuth()
  const getIdOfProject = () => {
    return user?.projects?.find(
      (project) => project.name === clickedInfo.event._def.extendedProps.project
    )?.id
  }

  const getContract = () => {
    return user?.system?.workTypes.find(
      (worktype) =>
        worktype.name === clickedInfo.event._def.extendedProps.contract
    )
  }

  return (
    <ModalWrapper>
      <>
        <CurrentInformationsBox>
          <CurrentInformationsContainer>
            <TypographyWrapper>
              <b>{t('clickModal.title1')}</b>
              <ArrowRightAltIcon />
              {clickedInfo !== null
                ? format(
                    new Date(
                      clickedInfo?.event?._instance?.range.start as Date
                    ),
                    'dd-MM-yyyy'
                  )
                : null}
            </TypographyWrapper>
            <TypographyWrapper>
              <b>{t('clickModal.title2')}</b>
              <ArrowRightAltIcon />
              {clickedInfo
                ? clickedInfo.event._def.extendedProps.contract
                : null}
            </TypographyWrapper>
            {clickedInfo.event._def.extendedProps.project && (
              <TypographyWrapper>
                <b>{t('clickModal.title3')}</b>
                <ArrowRightAltIcon />
                {clickedInfo
                  ? clickedInfo.event._def.extendedProps.project
                  : null}
              </TypographyWrapper>
            )}
          </CurrentInformationsContainer>
          {clickedInfo?.event?._def.extendedProps?.VacationRequest
            ?.vacationRequestIdentifier && (
            <Typography sx={{ fontStyle: 'italic' }}>
              {t('clickModal.source') +
                clickedInfo?.event?._def.extendedProps?.VacationRequest
                  ?.vacationRequestIdentifier}
            </Typography>
          )}
        </CurrentInformationsBox>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmitEditHandler(values)}
        >
          {({ submitForm, values, errors, dirty, setFieldValue }) => (
            <Form style={{ width: '100%' }}>
              <SetupProjectBox>
                <Typography
                  fontSize={20}
                  color={palette.primary.main}
                  fontWeight={600}
                >
                  {t('clickModal.formTitle')}
                </Typography>{' '}
                {!getContract()?.needApproval && (
                  <>
                    <FormControl>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            component={CustomizedTimePicker}
                            name="startTime"
                            label={t('addWorkdaysModal.timeStart')}
                            style={{ boxSizing: 'border-box' }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="endTime"
                            error={errors?.endTime as string}
                            component={CustomizedTimePicker}
                            label={t('addWorkdaysModal.timeEnd')}
                            style={{ boxSizing: 'border-box' }}
                          />
                          {errors?.endTime && (
                            <Typography variant="h6" color="error">
                              <>{errors.endTime}</>
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl
                      fullWidth
                      sx={{ margin: '20px 0' }}
                      size="small"
                      disabled={
                        clickedInfo?.event._def.extendedProps.contract.includes(
                          'Sick'
                        ) ||
                        clickedInfo?.event._def.extendedProps.contract.includes(
                          'Holiday'
                        )
                      }
                    >
                      <InputLabel id="dayType">
                        {t('clickModal.label1')}
                      </InputLabel>
                      <Field
                        label={t('clickModal.label1')}
                        name="contract"
                        component={CustomizedSelect}
                        style={{ boxSizing: 'border-box' }}
                      >
                        {workTypesOnlyForSelectedProject(
                          getIdOfProject(),
                          'Calendar'
                        ).map((worktype, i) => (
                          <MenuItem key={i} value={worktype.id}>
                            {worktype.name}
                            {worktype.paid ? ' (paid)' : ' (unpaid)'}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </>
                )}
                <Field
                  fullWidth
                  component={TextField}
                  label={t('clickModal.label2')}
                  name="comment"
                  style={{ boxSizing: 'border-box' }}
                  disabled={getContract()?.needApproval}
                />
              </SetupProjectBox>
              <ButtonContainer>
                {!getContract()?.needApproval && (
                  <Reactions setFieldValue={setFieldValue} mood={values.mood} />
                )}
                <Box
                  sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                >
                  {!getContract()?.needApproval && (
                    <>
                      <StyledButton
                        color="primary"
                        disabled={!dirty}
                        onClick={submitForm}
                      >
                        {t('save', { ns: 'buttons' })}
                      </StyledButton>
                      {!id && (
                        <StyledButton
                          onClick={handleDeleteEvent}
                          variant="outlined"
                        >
                          {t('delete', { ns: 'buttons' })}
                        </StyledButton>
                      )}
                      {!id &&
                      clickedInfo?.event._def.extendedProps.isPlanned ? (
                        <StyledButton
                          onClick={() => handleCopySingleEvent(values)}
                          variant="outlined"
                        >
                          {t('copy', { ns: 'buttons' })}
                        </StyledButton>
                      ) : null}
                    </>
                  )}
                </Box>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </>
    </ModalWrapper>
  )
}

export default EventClickModal
