import { Box, Typography, Chip, Stack } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { ITags } from 'hooks/types'
import { EmptyArrayCard, TableComponent } from 'components/common'

import { useHelpingUsers } from './utils'

const UsersThatMayHelpYou: React.FC = (): JSX.Element => {
  const { t } = useTranslation('dashboard')
  const { newHelpfulUsers } = useHelpingUsers()
  const { user } = useAuth()
  const columns = [
    `${t('modal.usersMayHelpYou.columns.name')}`,
    `${t('modal.usersMayHelpYou.columns.skills')}`,
    `${t('modal.usersMayHelpYou.columns.compatibility')}`,
  ]

  const rows: GridRowsProp = newHelpfulUsers
  return (
    <Box sx={{ maxHeight: '80vh', overflow: 'auto' }}>
      <Typography variant="subtitle1">
        {t('modal.usersMayHelpYou.addedSkills')}:
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {user?.tags?.length ? (
          user?.tags.map((skill: ITags) => (
            <Chip key={skill.id} label={skill.name} />
          ))
        ) : (
          <Typography variant="h6">
            {t('modal.usersMayHelpYou.noSkills')}
          </Typography>
        )}
      </Stack>
      {rows.length ? (
        <Box sx={{ margin: '0 5px 5px 1px' }}>
          <TableComponent columns={columns} rows={rows} />
        </Box>
      ) : (
        <EmptyArrayCard message={t('modal.usersMayHelpYou.message')} />
      )}
    </Box>
  )
}

export default UsersThatMayHelpYou
