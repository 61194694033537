import { Box, Tooltip } from '@mui/material'
import HouseboatOutlinedIcon from '@mui/icons-material/HouseboatOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import DeckOutlined from '@mui/icons-material/DeckOutlined'
import { format } from 'date-fns/esm'
import { GradientLinearProgress } from 'components/common'
import { StyledButton } from 'pages/Dashboard/index.style'
import { TFunction } from 'i18next'
import { IUser, IUserAgreement } from 'hooks/types'
import { Link } from 'react-router-dom'

export interface ICardDefinitionsArgs {
  t: TFunction
  userHolidays: {
    used: number
    maxHolidays: number
  }
  currentAgreements: IUserAgreement[]
  handleOpenModal: (name: string) => void
  hasLocations: boolean
  primaryLocationName: string
  countPercentage: number
  usedHolidaysDays: string | number
  user: IUser | null
}

const iconProps = { fontSize: '3rem', color: 'primary.main' }

export const createCardDefinitions = ({
  t,
  userHolidays,
  currentAgreements,
  handleOpenModal,
  hasLocations,
  primaryLocationName,
  countPercentage,
  usedHolidaysDays,
  user,
}: ICardDefinitionsArgs) => {
  const getButtonText = (buttonId: number, property: string): string => {
    return t(`cardsPanel.card${buttonId}.${property}`)
  }

  return [
    {
      content: {
        title: `${t('cardsPanel.card1.title')} ${userHolidays.used}/${
          userHolidays.maxHolidays
        }`,
        subtitle: t('cardsPanel.card1.year'),
        subtitle2: (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{getButtonText(1, 'usedDays')}</span>
            <span>{usedHolidaysDays}%</span>
          </Box>
        ),
        component: (
          <GradientLinearProgress
            variant="determinate"
            value={countPercentage}
            barcolor="primary.light"
            progressvalue={countPercentage}
          />
        ),
        icon: <HouseboatOutlinedIcon sx={iconProps} />,
      },
      timeout: 1000,
    },
    {
      content: {
        title: t('cardsPanel.card2.title'),
        subtitle:
          currentAgreements?.length === 0
            ? t('cardsPanel.card2.notAvailable')
            : currentAgreements?.length === 1
            ? t('cardsPanel.card2.currentAgreement')
            : t('cardsPanel.card2.currentAgreements'),
        subtitle2: (
          <>
            {currentAgreements?.map((agreement) => (
              <Tooltip
                title={`${format(
                  new Date(agreement.startDate),
                  'yyyy-MM-dd'
                )} - ${
                  agreement.endDate
                    ? format(new Date(agreement.endDate), 'yyyy-MM-dd')
                    : t('cardsPanel.card2.contractIndefinite')
                }`}
                key={agreement.id}
                placement="right"
              >
                <Box sx={{ width: 'fit-content' }}>
                  {agreement.Agreement.type}
                </Box>
              </Tooltip>
            ))}
          </>
        ),
        icon: <AssignmentOutlinedIcon sx={iconProps} />,
      },
      timeout: 1500,
    },
    {
      content: {
        title: t('cardsPanel.card3.title'),
        subtitle: t('cardsPanel.card3.help'),
        icon: <SupportOutlinedIcon sx={iconProps} />,
        button: (
          // TODO: Consider defining 'usersThatMayHelpYou' in a configuration file or variable for better maintainability.
          <StyledButton
            onClick={() => handleOpenModal('usersThatMayHelpYou')}
            size="small"
          >
            {getButtonText(3, 'button')}
          </StyledButton>
        ),
      },
      timeout: 2000,
    },
    {
      content: {
        title: `${t('cardsPanel.card5.workingOn')}...`,
        subtitle: (
          <Box>
            {user?.currentWork?.project?.name ||
              getButtonText(5, 'notWorkingOn')}
            {user?.currentWork && (
              <>
                <br />
                {t('cardsPanel.card5.from')}{' '}
                {format(
                  Date.parse(user.currentWork.logDayFrom),
                  'yyyy-MM-dd HH:mm'
                )}{' '}
                {t('cardsPanel.card5.to')}{' '}
                {format(Date.parse(user.currentWork.logDayTo), 'HH:mm')}
              </>
            )}
          </Box>
        ),
        icon: <WorkOutlineOutlinedIcon sx={iconProps} />,
      },
      timeout: 2500,
    },
    {
      content: {
        title: t('cardsPanel.card6.title'),
        subtitle: (
          <Box marginBottom={'20px'}>
            {getButtonText(6, 'city')}:{' '}
            {hasLocations
              ? getButtonText(6, primaryLocationName)
              : getButtonText(6, 'noCity')}
          </Box>
        ),
        subtitle2: (
          <Box marginBottom={'-20px'}>{getButtonText(6, 'bookIn')}:</Box>
        ),
        icon: <DeckOutlined sx={iconProps} />,
        button: hasLocations ? (
          <Link
            to={`https://desk_reservation.bcf-software.com/auth?selectCity=${primaryLocationName}&fromTimesheet=true`}
            target="_self"
            rel="noopener noreferrer"
          >
            <StyledButton size="small">
              {getButtonText(6, primaryLocationName)}
            </StyledButton>
          </Link>
        ) : (
          <></>
        ),
        secondButton: (
          <Link
            to="https://desk_reservation.bcf-software.com/auth?fromTimesheet=true"
            target="_self"
            rel="noopener noreferrer"
          >
            <StyledButton btncolor="true" size="small">
              {getButtonText(6, 'otherCity')}
            </StyledButton>
          </Link>
        ),
      },
      timeout: 3000,
    },
  ]
}
