import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { Wrapper } from 'components/common'
import { PageRoutes } from 'routes'

import { useChangePassword } from './utils'

const ChangePasswordForm: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation(['remindPassword', 'validation'])

  const { validationSchema, resetPassword, isPasswordUpdated } =
    useChangePassword()
  return (
    <Wrapper>
      <Typography variant="h3" fontWeight={550}>
        {t('header.title')}
      </Typography>
      {isPasswordUpdated ? (
        <Box>
          <Box mb={1}>
            <Typography>{t('userUpdated')}</Typography>
          </Box>
          <Button fullWidth onClick={() => navigate(PageRoutes.LOGIN)}>
            {t('form.button2')}
          </Button>
        </Box>
      ) : (
        <>
          <Formik
            initialValues={{ password: '', repeatNewPassword: '' }}
            onSubmit={(values, { setSubmitting }) => {
              resetPassword(values.password)
              setSubmitting(false)
            }}
            validationSchema={validationSchema}
          >
            <Form>
              <Box mb={2} mt={4}>
                <Field
                  type="password"
                  fullWidth
                  name="password"
                  component={TextField}
                  label={t('form.label1')}
                />
              </Box>
              <Box my={2}>
                <Field
                  type="password"
                  fullWidth
                  name="repeatNewPassword"
                  component={TextField}
                  label={t('form.label2')}
                />
              </Box>
              <Box my={2}>
                <Button fullWidth type="submit">
                  {t('form.button1')}
                </Button>
              </Box>
            </Form>
          </Formik>

          <Button
            fullWidth
            variant="text"
            onClick={() => navigate(PageRoutes.FORGOT_PASSWORD)}
          >
            {t('form.button3')}
          </Button>
        </>
      )}
    </Wrapper>
  )
}

export default ChangePasswordForm
