import axios, { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'

export const useRemindPasswordCheck = () => {
  const [isActiveToken, setIsActiveToken] = useState(false)
  const { setNotification } = useNotifications()
  const { token } = useParams()

  const { t } = useTranslation([
    'remindPassword',
    'validation',
    'handleMessage',
  ])
  const ERROR = t('serverError', { ns: 'handleMessage' })

  const checkToken = async (token: string) => {
    try {
      const { data }: AxiosResponse = await apiRequest(
        APIRoutes.REMIND_PASSWORD_CHECK,
        { token: token }
      )

      if (data.success) {
        setIsActiveToken(true)
      } else {
        setNotification({
          message: data.message,
          variant: NOTIFICATION.error,
        })
      }
    } catch (error) {
      const errorTitle = axios.isAxiosError(error)
        ? error?.response?.data?.message ||
          error.toString().replace('Error: ', '')
        : ERROR
      setNotification({
        message: errorTitle,
        variant: NOTIFICATION.error,
      })
    }
  }

  useEffect(() => {
    if (token) {
      checkToken(token)
    }
  }, [token])

  return { isActiveToken }
}
