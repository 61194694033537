import { Autocomplete, TextField, Typography } from '@mui/material'

interface IValue {
  name: string
  id: number | null
}
interface IProps {
  form: {
    setFieldValue: (name: string, value: IValue | null) => void
  }
  field: {
    name: string
    id: string
    value: IValue | null
  }
  label: string
  optionsData: { name: string; id?: number }[]
  disableClearable?: boolean
  error?: string | undefined
  disabled?: boolean
}
const CustomAutocompleteInput: React.FC<IProps> = ({
  form,
  field,
  label,
  optionsData,
  error,
  disableClearable,
  disabled,
}) => {
  const { name, value } = field
  const { setFieldValue } = form

  return (
    <>
      <Autocomplete
        disableClearable={disableClearable ? disableClearable : false}
        disabled={disabled}
        fullWidth
        size="small"
        id={name}
        options={optionsData}
        isOptionEqualToValue={(option, { name }) =>
          name === '' ||
          name === 'Unexpected leave' ||
          name === undefined ||
          option?.name === name
        }
        getOptionLabel={(option) => {
          return option.name
        }}
        defaultValue={{ name: value?.name || '' }}
        value={{ name: value?.name || '' }}
        onChange={(_, value) => {
          {
            setFieldValue(
              name,
              value
                ? {
                    name: value?.name || '',
                    id: value?.id || null,
                  }
                : null
            )
          }
        }}
        renderInput={(params) => (
          <TextField error={!!error} {...params} name={name} label={label} />
        )}
      />
      {error && (
        <Typography
          variant="h6"
          color="error"
          sx={{ marginTop: '0.25rem', textIndent: '1rem' }}
        >
          {error}
        </Typography>
      )}
    </>
  )
}

export default CustomAutocompleteInput
