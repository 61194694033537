import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  IconButton,
  Link,
  Tooltip,
  Typography,
  Container,
  Fade,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { Head, HeaderTab } from 'views/LayoutUI/components'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  useNotifications,
  EmptyArrayCard,
  ModalConfirm,
  TableComponent,
  ModalComponent,
} from 'components/common'
import { useState } from 'react'
import { useDeleteCustomer } from 'hooks/customers'
import { DefaultModal } from 'views/LayoutUI/modals'
import { CustomerAdd } from 'pages/Customers/components'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTablePagedFunction } from 'hooks/table'
import { ChangeItemsInPage } from 'hooks/table/enum'
import { IButtonArr, ISelectingModal } from 'hooks/types'
import { timeoutAnimation } from 'utils'
import { PageRoutes } from 'routes'

import { useCustomers } from './utils'

const Customers: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['customers', 'buttons'])
  const navigate = useNavigate()
  const { handleOpenModal, modalName } = useNotifications()
  const [currentCustomerId, setCurrentCustomerId] = useState('')
  const { deleteCustomer } = useDeleteCustomer()
  const { customersList, changePagination } = useCustomers()
  const {
    dispatchUC,
    paginationUC,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTablePagedFunction(changePagination)

  const columns = [
    '#',
    `${t('table.cell1')}`,
    `${t('table.cell2')}`,
    `${t('table.cell3')}`,
    '',
  ]

  const buttonsArr: IButtonArr[] = [
    {
      title: `${t('edit', { ns: 'buttons' })}`,
      path: PageRoutes.CUSTOMERS_EDIT,
      icon: <EditIcon />,
    },
    {
      title: `${t('delete', { ns: 'buttons' })}`,
      path: null,
      icon: <DeleteIcon color="error" />,
    },
  ]
  const rows: GridRowsProp = customersList?.data?.map((customer) => {
    return {
      id: customer.id,
      name: (
        <Link
          sx={{ textDecoration: 'none', textAlign: 'left' }}
          variant="body2"
          component="button"
          onClick={() => navigate(`/customers/${customer.id}`)}
        >
          {customer.name}
        </Link>
      ),
      code: customer.code,
      projects: customer.projects.length,
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map((button) => (
            <Tooltip title={button.title as string} key={button.title}>
              <IconButton
                onClick={() => {
                  button.path &&
                    navigate(button.path.replace(':id', `${customer.id}`))
                  setCurrentCustomerId(customer.id)
                  !button.path && handleOpenModal('delete')
                }}
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  })

  const deleteContent = () => {
    const customerName = customersList?.data?.find(
      (customer) => customer.id === currentCustomerId
    )?.name
    return (
      <Typography>
        {t('deleteCustomer.title1')}: {customerName}?
      </Typography>
    )
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteCustomer(parseInt(currentCustomerId))}
            />
          ),
          title: t('deleteCustomer.modalTitle'),
        }
      case 'addCustomer':
        return {
          component: <CustomerAdd />,
          title: `${t('addCustomer.title')}`,
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('modal.defaultModal.title')}`,
        }
    }
  }
  const initialValues = {
    searchValue: '',
  }

  return (
    <Container>
      <Head title={t('header.title')} />
      <HeaderTab translate="customers" />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatchUC({
            type: ChangeItemsInPage.MULTI_EDIT_STATE,
            payload: {
              length: paginationUC.length,
              currentPage: 0,
              search: values.searchValue,
            },
          })
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',
              }}
            >
              <Button
                size="medium"
                onClick={() => handleOpenModal('addCustomer')}
              >
                {t('buttons.button1')}
              </Button>
              <Field
                fullWidth
                sx={{ flex: 2 }}
                component={TextField}
                label={t('form.label')}
                name="searchValue"
                size="small"
              />
              <Button
                disabled={isSubmitting}
                onClick={submitForm}
                size="medium"
              >
                {t('search', { ns: 'buttons' })}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {customersList?.data?.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent
              columns={columns}
              rows={rows}
              total={customersList?.total}
              pagination={true}
              handleChangePage={handleChangePage}
              paginationUC={paginationUC}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={`${t('typographies.typography1')}`} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}

export default Customers
