import { Stack } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { AntSwitch, EmptyArrayCard, TableComponent } from 'components/common'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'

import { useNotification, INotifications } from './utils'

const Notifications: React.FC = (): JSX.Element => {
  const { notifications, changeNotifiaction, removeNotification } =
    useNotification()
  const { user } = useAuth()
  const { t } = useTranslation('profile')
  const columns = [t('notification.column1'), t('notification.column2'), '']

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const switchId = Number(e.currentTarget.id)
    const findObjectNotifications = notifications
      .map((notification) => notification.usersNotifications[0])
      .filter((item) => typeof item === 'object')
    const hasNotifications = findObjectNotifications.find(
      (x) => x.notificationId === switchId && x.userId === user?.id
    )

    if (hasNotifications) {
      removeNotification(Number(hasNotifications.id))
    } else {
      changeNotifiaction(switchId)
    }
  }

  const userNotifications = notifications?.map(
    ({ usersNotifications, name, description, id }: INotifications) => {
      const isNotifications = usersNotifications?.length ? false : true
      return {
        name: name,
        description: description,
        buttons: (
          <Stack direction="row" spacing={1} alignItems="center">
            <ThumbDownIcon color="error" />
            <AntSwitch
              checked={isNotifications}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'ant design' }}
              id={id.toString()}
              colorofswitch="true"
            />
            <ThumbUpIcon color="success" />
          </Stack>
        ),
      }
    }
  )
  const rows: GridRowsProp = userNotifications

  return (
    <>
      {rows.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard message={t('notification.message')} />
      )}
    </>
  )
}
export default Notifications
