import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'

import { IReportData } from '../types'

export const getAllocationByUser = async (
  reportData: IReportData,
  dates: string[]
) => {
  const { data }: AxiosResponse = await apiRequest(APIRoutes.ALLOCATION_READ, {
    dates: dates,
    projects: reportData.projects,
    users: reportData.users,
    customers: reportData.customers,
  })
  return data
}

export const getAllocationByCustomer = async (
  reportData: IReportData,
  dates: string[]
) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.ALLOCATION_READ_BY_CUSTOMER,
    {
      dates: dates,
      projects: reportData.projects,
      users: reportData.users,
      customers: reportData.customers,
    }
  )

  return data
}
