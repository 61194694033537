import { Typography } from '@mui/material'

import { WrapperContainer } from './index.style'

const LogoContainer = () => {
  return (
    <WrapperContainer>
      <img
        height="85"
        src="https://bcf-software.pl/menu-logo.svg"
        alt="bcf-software logo"
      />
      <Typography variant="h1" sx={{ marginLeft: 3 }}>
        timesheet
      </Typography>
    </WrapperContainer>
  )
}

export default LogoContainer
