import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { Grid } from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'

const InventoryFields = (): JSX.Element => {
  const { t } = useTranslation(['profile'])

  const groupFields: IFields[] = [
    {
      id: '1',
      label: `${t('inventory.form.label1')}`,
      name: 'model',
      type: 'text',
    },
    {
      id: '2',
      label: `${t('inventory.form.label2')}`,
      name: 'serialNumber',
      type: 'text',
    },
    {
      id: '3',
      label: `${t('inventory.form.label3')}`,
      name: 'producer',
      type: 'text',
    },
    {
      id: '4',
      label: `${t('inventory.form.label4')}`,
      name: 'additionalInformation',
      type: 'text',
    },
  ]
  return (
    <>
      <Grid container columnSpacing={3}>
        {groupFields.map((field, index) => (
          <Grid item key={field.id} mb={3} xs={12} md={index === 3 ? 12 : 4}>
            <Field
              fullWidth
              component={TextField}
              name={field.name}
              type={field.type}
              label={field.label}
              variant="outlined"
              size="small"
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default InventoryFields
