import { Autocomplete, TextField, Chip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ITags } from 'hooks/types'

interface IProps {
  form: {
    setFieldValue: (
      name: string,
      value: (string | { name: string | ITags })[]
    ) => void
  }
  field: {
    name: string
    id: string
  }
  error: boolean
  label: string
  optionsData?: ITags[] | []
  freeSolo?: boolean
}

const AutocompleteWithTags: React.FC<IProps> = ({
  form,
  field,
  error,
  optionsData,
  label,
  freeSolo,
}): JSX.Element => {
  const { name } = field
  const { setFieldValue } = form
  const { t } = useTranslation('systemTags')
  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        options={optionsData || []}
        freeSolo={freeSolo}
        renderTags={(value, getTagProps) =>
          value?.map((option, index) => (
            <Chip
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              key={`${index}-tags`}
              label={<>{option.name ? option.name : option}</>}
              {...getTagProps({ index })}
            />
          ))
        }
        getOptionLabel={(option) => {
          if (typeof option === 'object') {
            return option.name
          }
          return option
        }}
        onChange={(_, value) => {
          const changeSkillsFromStringToObject = value
            .filter((item) => typeof item === 'string')
            .map((value) => {
              return { name: value }
            })
          const getSkillsInObjects = value?.filter(
            (item) => typeof item === 'object'
          )
          setFieldValue(name, [
            ...getSkillsInObjects,
            ...changeSkillsFromStringToObject,
          ])
        }}
        renderInput={(params) => (
          <TextField
            error={!!error}
            {...params}
            margin="normal"
            label={label}
            fullWidth
            name={name}
          />
        )}
      />

      <Typography color="primary" variant="h6">
        {t('create.text1')} <b> {t('create.text2')}</b>
      </Typography>

      {error && (
        <Typography color="error" variant="h6" paddingY={0.5}>
          {error}
        </Typography>
      )}
    </>
  )
}

export default AutocompleteWithTags
