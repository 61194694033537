import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'
import { HeaderTab } from 'views/LayoutUI/components'
import { DefaultModal } from 'views/LayoutUI/modals'
import { PkupProvider } from 'pages/Pkup/context/PkupContext'
import { ModalComponent, useNotifications } from 'components/common'
import { SelectTime } from 'components/Pkup'
import { ISelectingModal } from 'hooks/types'

import { ReportRevisions, ShowMoreReport } from './modals/'
import { PkupReportsTable } from './components/'

const ReportPkup: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['pkup', 'reportPkup'])
  const { modalName } = useNotifications()

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'showMoreApplication':
        return {
          component: <ShowMoreReport />,
          title: `${t('modal.showMore.title')}`,
        }
      case 'revisions':
        return {
          component: <ReportRevisions />,
          title: `${t('modal.revisions.title')}`,
        }
      default:
        return { component: <DefaultModal />, title: `${t('modal.default')}` }
    }
  }

  return (
    <Container>
      <PkupProvider>
        <HeaderTab translate="reportPkup" id="Pkup" />
        <SelectTime />
        <PkupReportsTable />
        <ModalComponent
          component={selectingModal().component}
          title={selectingModal().title}
        />
      </PkupProvider>
    </Container>
  )
}
export default ReportPkup
