import { Button } from '@mui/material'
import { useCalendarContext } from 'pages/Calendar/context'
import { CloseButton, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

import { useStickyBar } from '../StickyBar/utils'
import { DatePickerComponent } from '../common'
import { tempArrWithDays, convertToDate } from '../utils'

const DeleteWorkdaysBar = () => {
  const { theSameDays, setCopyMode, setDeleteMode, setTheSameDays } =
    useCalendarContext()

  const { startValue, setStartValue, endValue, setEndValue } = useStickyBar()
  const { handleOpenModal, handleCloseBar } = useNotifications()
  const { t } = useTranslation(['calendar', 'buttons'])

  const tempArr = tempArrWithDays(theSameDays)
  const days = theSameDays.filter((day) => !day.extendedProps.needApproval)
  return (
    <>
      <DatePickerComponent
        label={t('deletebar.label1')}
        value={
          tempArr.length !== 0
            ? convertToDate(tempArr[0].start as string)
            : startValue
        }
        setValue={setStartValue}
      />
      <DatePickerComponent
        label={t('deletebar.label2')}
        value={
          tempArr.length !== 0
            ? convertToDate(tempArr[theSameDays.length - 1].end as string)
            : endValue
        }
        setValue={setEndValue}
      />
      <Button
        color="error"
        disabled={
          days.length === 0
            ? true
            : false ||
              (startValue !== null &&
                endValue !== null &&
                startValue > endValue &&
                true)
        }
        onClick={() => {
          handleOpenModal('deleteModalInfo')
          handleCloseBar()
        }}
      >
        {t('delete', { ns: 'buttons' })}
      </Button>
      <Button onClick={() => setTheSameDays([])}>
        {t('unselect', { ns: 'buttons' })}
      </Button>
      <CloseButton
        handleOnClick={() => {
          setDeleteMode(false)
          setCopyMode(false)
          handleCloseBar()
        }}
      />
    </>
  )
}

export default DeleteWorkdaysBar
