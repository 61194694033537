import { useAuth } from 'hooks/useAuth'
import { VacationRequestFormik } from 'pages/VacationRequest/components'
import { useData, useVacationRequests } from 'pages/VacationRequest/hooks'

const EditVacationApplication = ({ selectedRequest }): JSX.Element => {
  const { getNameOfVacationType } = useData()
  const { user } = useAuth()
  const initialValues = {
    valueStartDate: new Date(selectedRequest?.startDate),
    valueEndDate: new Date(selectedRequest?.endDate),
    comment: selectedRequest?.comment || '',
    vacationType: {
      id: selectedRequest?.vacationTypeId || user?.UserHolidays?.[0].id,
      name: selectedRequest?.vacationTypeId
        ? getNameOfVacationType(selectedRequest?.vacationTypeId).title
        : getNameOfVacationType(user?.UserHolidays?.[0].id).title,
    },
    workType: {
      id: selectedRequest?.workTypeId,
      name: selectedRequest?.workTypeName,
    },
    project: selectedRequest?.projectId
      ? {
          id: selectedRequest?.projectId || null,
          name: selectedRequest?.projectName || '',
        }
      : null,
  }
  const { updateVacationRequest } = useVacationRequests(user?.id)
  return (
    <>
      <VacationRequestFormik
        initialValues={initialValues}
        onSubmit={updateVacationRequest}
        selectedRequest={selectedRequest}
      />
    </>
  )
}

export default EditVacationApplication
