import { Slide } from '@mui/material'
import { useNotifications } from 'components/common'
import { theme } from 'theme'
import { ReactNode } from 'react'

import { AppBarWrapper, ToolBarWrapper } from './index.style'

const StickyBar: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { openBar } = useNotifications()
  return (
    <Slide direction="up" in={openBar}>
      <AppBarWrapper>
        <ToolBarWrapper
          sx={{
            [theme.breakpoints.down('xs')]: {
              gap: '5px',
            },
          }}
        >
          {children}
        </ToolBarWrapper>
      </AppBarWrapper>
    </Slide>
  )
}

export default StickyBar
