import { Box, IconButton, styled } from '@mui/material'

export const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  minWidth: 900,
  minHeight: 600,
  padding: 0,
  margin: 0,
})

export const MenuIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '50%',
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: `0px 2px 10px 1px ${theme.palette.primary.main}`,
  width: '40px',
  height: '40px',
  padding: '10px',
  position: 'fixed',
  zIndex: 1300,
}))
