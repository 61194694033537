import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { useMutation } from 'react-query'
import fileDownload from 'js-file-download'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

export function useDownloadReportProject(name: string) {
  const { setNotification } = useNotifications()
  const { t } = useTranslation('handleMessage')

  async function downloadReportForProject(projectData: {
    selectedProjectCode?: string | undefined
    date: string | null
  }) {
    const { date, selectedProjectCode } = projectData
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.REPORT_GENERATE_PER_PROJECT,
      {},
      { projectCode: selectedProjectCode, date }
    )
    return data
  }

  async function downloadReportForProjects(projectData: {
    date: string | null
  }) {
    const { date } = projectData
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.REPORT_GENERATE_FOR_PROJECTS,
      {},
      { date }
    )
    return data
  }

  const { mutate: downloadProjectReport } = useMutation(
    downloadReportForProject,
    {
      onSuccess: async (data) => {
        if (data.size) {
          fileDownload(new Blob([data]), name)
        } else {
          setNotification({
            message: t('noDataForMonth'),
            variant: NOTIFICATION.error,
          })
        }
      },
    }
  )

  const { mutate: downloadProjectsReport } = useMutation(
    downloadReportForProjects,
    {
      onSuccess: async (data) => {
        if (data.size) {
          fileDownload(new Blob([data]), name)
        } else {
          setNotification({
            message: t('noDataForMonth'),
            variant: NOTIFICATION.error,
          })
        }
      },
    }
  )

  return {
    downloadProjectReport,
    downloadProjectsReport,
  }
}
