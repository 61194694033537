import { TimePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'

const CustomizedTimePicker = ({
  form,
  field,
  label,
  error,
  disabled,
}: {
  children: string
  form: { setFieldValue: (name: string, value: string | null) => void }
  field: { name: string; value: string; id: string }
  label: string
  error?: string
  disabled?: boolean
}) => {
  const { name, value } = field
  const { setFieldValue } = form
  return (
    <TimePicker
      label={label}
      inputFormat="HH:mm"
      mask="__:__"
      ampm={false}
      openTo="hours"
      value={value}
      onChange={(newValue) => {
        setFieldValue(name, newValue)
      }}
      renderInput={(params) => (
        <TextField fullWidth {...params} name={name} error={!!error} />
      )}
      views={['hours', 'minutes']}
      disabled={disabled}
    />
  )
}

export default CustomizedTimePicker
