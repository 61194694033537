import { useEffect } from 'react'
import { queryKeys } from 'react-query/constants'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { formatDistance } from 'date-fns'
import { apiRequest, APIRoutes } from 'apiServices'
import { IBusinessUnit, IBusinessUnitUser } from 'hooks/types'

import { UseDetails } from './types'

async function getHelpfulUsers(id: number | undefined): Promise<IBusinessUnit> {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.BUSINESS_UNITS_READ,
    {},
    { id }
  )
  return data
}

export const useDetails = (id: number | null): UseDetails => {
  const fallback = <IBusinessUnit>{}

  const { data: businessUnits = fallback, refetch } = useQuery(
    queryKeys.businessUnits,
    () => getHelpfulUsers(Number(id))
  )
  useEffect(() => {
    refetch()
  }, [id])

  const newBusinessUnits = businessUnits?.users?.map(
    (user: IBusinessUnitUser) => {
      const createdAt = user.UserBusinessUnits.createdAt
        ? new Date(user.UserBusinessUnits.createdAt)
        : new Date()
      const joinedAt = formatDistance(new Date(), createdAt)
      return {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        joined: `${joinedAt} ago`,
      }
    }
  )

  const findDirectors = businessUnits?.users
    ?.filter((user) => user?.UserBusinessUnits?.isDirector === true)
    ?.map((user) => `${user.firstName} ${user.lastName}`)
  return {
    businessUnits,
    newBusinessUnits,
    findDirectors,
  }
}
