import { NOTIFICATION, useNotifications } from 'components/common'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

export interface ICustomerData {
  id: string | undefined
  code: string
  name: string
}

const updateSingleCustomer = async (customerData: ICustomerData) => {
  const { id, code, name } = customerData
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.CUSTOMERS_UPDATE,
    { code, name },
    {
      id,
    }
  )
  return data
}

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')

  const { mutate: updateCustomer } = useMutation(updateSingleCustomer, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('customer')} ${t('update')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.customer])
    },
  })

  return { updateCustomer }
}
