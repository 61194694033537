import { Button } from '@mui/material'
import { useNotifications } from 'components/common'
import { useCalendarContext } from 'pages/Calendar/context'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import AlarmAddIcon from '@mui/icons-material/AlarmAdd'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import FilterListIcon from '@mui/icons-material/FilterList'

import { ButtonsBoxContainer } from './index.style'

const CalendarButtons = () => {
  const { setDeleteMode, setCopyMode, isFilter, setIsFilter } =
    useCalendarContext()

  const { barName, handleOpenBar, handleCloseBar } = useNotifications()
  const { t } = useTranslation(['calendar', 'buttons'])
  const { id } = useParams()
  return (
    <ButtonsBoxContainer>
      {!id && (
        <>
          <Button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              if (barName === (e.target as HTMLButtonElement).name) {
                setDeleteMode(false)
                setCopyMode(false)
                handleCloseBar()
              } else if (barName !== (e.target as HTMLButtonElement).name) {
                if (
                  (e.target as HTMLButtonElement).name === 'copyWorkdaysBar'
                ) {
                  setCopyMode(true)
                  setDeleteMode(false)
                } else if (
                  (e.target as HTMLButtonElement).name === 'deleteWorkdaysBar'
                ) {
                  setDeleteMode(true)
                  setCopyMode(false)
                } else {
                  setDeleteMode(false)
                  setCopyMode(false)
                }
                handleOpenBar((e.target as HTMLButtonElement).name)
              } else handleOpenBar('addWorkdaysBar')
            }}
            variant={barName === 'addWorkdaysBar' ? 'contained' : 'outlined'}
            name="addWorkdaysBar"
            startIcon={<AlarmAddIcon color="success" />}
          >
            {t('calendarButtons.button1')}
          </Button>

          <Button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              if (barName === (e.target as HTMLButtonElement).name) {
                setDeleteMode(false)
                setCopyMode(false)
                handleCloseBar()
              } else if (barName !== (e.target as HTMLButtonElement).name) {
                if (
                  (e.target as HTMLButtonElement).name === 'copyWorkdaysBar'
                ) {
                  setCopyMode(true)
                  setDeleteMode(false)
                } else if (
                  (e.target as HTMLButtonElement).name === 'deleteWorkdaysBar'
                ) {
                  setDeleteMode(true)
                  setCopyMode(false)
                } else {
                  setDeleteMode(false)
                  setCopyMode(false)
                }
                handleOpenBar((e.target as HTMLButtonElement).name)
              } else {
                handleOpenBar('copyWorkdaysBar')
                setCopyMode(true)
              }
            }}
            variant={barName === 'copyWorkdaysBar' ? 'contained' : 'outlined'}
            name="copyWorkdaysBar"
            startIcon={<ContentCopyIcon color="success" />}
          >
            {t('calendarButtons.button2')}
          </Button>

          <Button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              if (barName === (e.target as HTMLButtonElement).name) {
                setDeleteMode(false)
                setCopyMode(false)
                handleCloseBar()
              } else if (barName !== (e.target as HTMLButtonElement).name) {
                if (
                  (e.target as HTMLButtonElement).name === 'copyWorkdaysBar'
                ) {
                  setCopyMode(true)
                  setDeleteMode(false)
                } else if (
                  (e.target as HTMLButtonElement).name === 'deleteWorkdaysBar'
                ) {
                  setDeleteMode(true)
                  setCopyMode(false)
                } else {
                  setDeleteMode(false)
                  setCopyMode(false)
                }
                handleOpenBar((e.target as HTMLButtonElement).name)
              } else {
                handleOpenBar('deleteWorkdaysBar')
                setDeleteMode(true)
              }
            }}
            variant={barName === 'deleteWorkdaysBar' ? 'contained' : 'outlined'}
            name="deleteWorkdaysBar"
            startIcon={<DeleteForeverIcon color="error" />}
          >
            {t('calendarButtons.button3')}
          </Button>
        </>
      )}

      <Button
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          if (barName === (e.target as HTMLButtonElement).name) {
            setDeleteMode(false)
            setCopyMode(false)
            handleCloseBar()
          } else if (barName !== (e.target as HTMLButtonElement).name) {
            if ((e.target as HTMLButtonElement).name === 'copyWorkdaysBar') {
              setCopyMode(true)
              setDeleteMode(false)
            } else if (
              (e.target as HTMLButtonElement).name === 'deleteWorkdaysBar'
            ) {
              setDeleteMode(true)
              setCopyMode(false)
            } else {
              setDeleteMode(false)
              setCopyMode(false)
            }
            handleOpenBar((e.target as HTMLButtonElement).name)
          } else handleOpenBar('downloadWorkdaysBar')
        }}
        variant={barName === 'downloadWorkdaysBar' ? 'contained' : 'outlined'}
        name="downloadWorkdaysBar"
        startIcon={<DownloadForOfflineIcon color="success" />}
      >
        {t('calendarButtons.button4')}
      </Button>
      <Button
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          if (barName === (e.target as HTMLButtonElement).name) {
            setDeleteMode(false)
            setCopyMode(false)
            handleCloseBar()
          } else if (barName !== (e.target as HTMLButtonElement).name) {
            if ((e.target as HTMLButtonElement).name === 'copyWorkdaysBar') {
              setCopyMode(true)
              setDeleteMode(false)
            } else if (
              (e.target as HTMLButtonElement).name === 'deleteWorkdaysBar'
            ) {
              setDeleteMode(true)
              setCopyMode(false)
            } else {
              setDeleteMode(false)
              setCopyMode(false)
            }
            handleOpenBar((e.target as HTMLButtonElement).name)
          } else handleOpenBar('filtersBar')
        }}
        variant={barName === 'filtersBar' ? 'contained' : 'outlined'}
        name="filtersBar"
        startIcon={<FilterListIcon color="warning" />}
      >
        {t('calendarButtons.button5')}
      </Button>
      {isFilter && (
        <Button
          onClick={() => {
            setIsFilter(false)
          }}
        >
          {t('calendarButtons.button6')}
        </Button>
      )}
    </ButtonsBoxContainer>
  )
}

export default CalendarButtons
