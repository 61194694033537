import { Alert, Snackbar } from '@mui/material'

import { useNotifications } from '../providers/APIErrorProvider'

const InfoSnackbar: React.FC = (): JSX.Element => {
  const { openNotification, setOpenNotification, notifications } =
    useNotifications()
  return (
    <Snackbar
      open={openNotification}
      onClose={() => setOpenNotification(false)}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ position: 'fixed', marginTop: 6 }}
    >
      <Alert
        onClose={() => setOpenNotification(false)}
        severity={notifications.variant}
      >
        {notifications.message}
      </Alert>
    </Snackbar>
  )
}

export default InfoSnackbar
