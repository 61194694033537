import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'
import { useNotifications, ModalComponent } from 'components/common'
import { HeaderTab } from 'views/LayoutUI/components'
import { DefaultModal } from 'views/LayoutUI/modals'
import { useGetOneUser } from 'hooks/user'
import { ISelectingModal } from 'hooks/types'
import { PageRoutes } from 'routes'

import StickyBar from './components/StickyBar'
import {
  AddWorkdaysModal,
  CalendarComponent,
  EventClickModal,
  DeleteModal,
  DownloadWorkdaysBar,
  AddWorkdaysBar,
  DeleteWorkdaysBar,
  CopyWorkdaysBar,
  CalendarButtons,
  ConfirmModal,
  FiltersBar,
} from './components'
import { useCalendarContext } from './context'

const Calendar: React.FC = (): JSX.Element => {
  const { t } = useTranslation('calendar')
  const { modalName, barName } = useNotifications()
  const { daysRange } = useCalendarContext()
  const oneUser = useGetOneUser()
  const { id } = useParams()
  const { clickedInfo, setCopyMode, setDeleteMode } = useCalendarContext()

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addWorkdaysModal':
        return {
          component: <AddWorkdaysModal />,
          title: `${t('modalsTitle.title1')} from ${daysRange[0]} to ${
            daysRange[daysRange.length - 1]
          }`,
        }
      case 'eventClickModal':
        return {
          component: <EventClickModal />,
          title:
            t('modalsTitle.title2') + ' #' + clickedInfo?.event?._def.publicId,
        }
      case 'deleteModalInfo':
        return {
          component: <DeleteModal />,
          title: t('modalsTitle.title3'),
          setMode: setDeleteMode,
        }
      case 'copyWorkdaysModal':
        return {
          component: <ConfirmModal />,
          title: t('modalsTitle.title4'),
          setMode: setCopyMode,
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('modal.defaultModal.title')}`,
        }
    }
  }

  const selectingBar = (): JSX.Element | null => {
    switch (barName) {
      case 'addWorkdaysBar':
        return <AddWorkdaysBar />
      case 'copyWorkdaysBar':
        return <CopyWorkdaysBar />
      case 'deleteWorkdaysBar':
        return <DeleteWorkdaysBar />
      case 'downloadWorkdaysBar':
        return <DownloadWorkdaysBar />
      case 'filtersBar':
        return <FiltersBar />
      default:
        return null
    }
  }

  return (
    <Container>
      <HeaderTab
        translate={id ? 'users' : 'calendar'}
        id={
          id
            ? oneUser
              ? `${t('calendarFor')} ${oneUser.firstName} ${oneUser.lastName}`
              : null
            : ''
        }
        pageLink={id && PageRoutes.USERS}
      />
      <CalendarButtons />
      <CalendarComponent />
      <StickyBar>{selectingBar()}</StickyBar>
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
        setMode={selectingModal().setMode}
        className={modalName === 'eventClickModal' ? 'large' : ''}
      />
    </Container>
  )
}
export default Calendar
