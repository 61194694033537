import { useState } from 'react'
import { Formik, Form } from 'formik'
import { format } from 'date-fns'
import { Autocomplete, Button, Box, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IHolidaysCategory } from 'hooks/types'
import { useGetAllHolidays, useValidateSchema } from 'hooks/holidays'
import { HolidayFields } from 'components/users'

import { useUserHolidays } from './utils'

const AddHoliday = () => {
  const { t } = useTranslation(['userEditPage', 'buttons'])
  const [holidayCategory, setHolidayCategory] =
    useState<IHolidaysCategory | null>(null)
  const { validationSchemaAddHoliday } = useValidateSchema()
  const holidaysCategories = useGetAllHolidays()
  const { addUserHoliday } = useUserHolidays()
  return (
    <Formik
      initialValues={{ holidayType: '', days: '', year: new Date() }}
      onSubmit={(values, { setSubmitting }) => {
        addUserHoliday({
          holidaysCategoryId: holidayCategory?.id || 0,
          days: values.days,
          year: format(new Date(values.year), 'yyyy'),
          id: holidayCategory?.id || 0,
        })
        setSubmitting(false)
      }}
      validationSchema={validationSchemaAddHoliday}
    >
      {({ errors, setFieldValue, touched }) => (
        <Form>
          <Box mb={2} maxWidth={450}>
            <Autocomplete
              fullWidth
              size="small"
              id="holidayType"
              options={holidaysCategories}
              isOptionEqualToValue={(option, { name }) => option.name === name}
              getOptionLabel={(option) => {
                return option.name
              }}
              onChange={(e, value) => {
                {
                  setFieldValue('holidayType', value?.name)
                  setHolidayCategory(value)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.holidayType ? true : false}
                  margin="normal"
                  label={t('holidays.form.label1')}
                  name="holidayType"
                  variant="outlined"
                />
              )}
            />
            {errors.holidayType && touched.holidayType && (
              <Typography color="error" variant="h6">
                {errors.holidayType}
              </Typography>
            )}
          </Box>
          <HolidayFields />
          <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
        </Form>
      )}
    </Formik>
  )
}
export default AddHoliday
