import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

import { IData } from './types'

const getReportProjects = async (): Promise<IData> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.REPORT_GET_PROJECT_CODES,
    {},
    {}
  )
  return data
}

export const useGetReportProjects = () => {
  const fallback = <IData>{}

  const { data: reportProjects = fallback } = useQuery(
    queryKeys.reportProjects,
    () => getReportProjects()
  )

  return reportProjects
}
