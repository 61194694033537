import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { useMutation } from 'react-query'
import fileDownload from 'js-file-download'
import { useTranslation } from 'react-i18next'
import { NOTIFICATION, useNotifications } from 'components/common'
import { format } from 'date-fns'

export const useDownloadExcel = (worktypes) => {
  const { setNotification } = useNotifications()
  const { t } = useTranslation('handleMessage')

  async function downloadReportForWorkdays(userReportReq: any) {
    const formatDates = (year) => {
      const formatedDates: string[] = []
      for (let i = 1; i <= 12; i++) {
        const days = `${format(year, 'yyyy')}/${i < 10 ? 0 : ''}${i}`
        formatedDates.push(days)
      }
      return formatedDates
    }
    const sendData = {
      dates: formatDates(userReportReq.year),
      workTypeIds: worktypes?.map((worktype) => worktype.id),
      usersIds: userReportReq.users.map((user) => user.id),
    }
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.REPORT_GENERATE_WORKDAYS,
      sendData,
      {}
    )
    return data
  }

  const { mutate: downloadWorkdaysReport } = useMutation(
    downloadReportForWorkdays,
    {
      onSuccess: async (data) => {
        if (data.size) {
          fileDownload(new Blob([data]), 'workdays-report' + '.xlsx')
        } else {
          setNotification({
            message: t('noDataForYear'),
            variant: NOTIFICATION.error,
          })
        }
      },
    }
  )

  return downloadWorkdaysReport
}
