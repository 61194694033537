import { useTranslation } from 'react-i18next'
import { Grid, Paper, IconButton, Tooltip } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { IEdu, useUser } from 'pages/Users/pages/utlis'
import { EmptyArrayCard, TableComponent, TableTitle } from 'components/common'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { IButtonArr } from 'hooks/types'

import { ISelectedOptions } from '../UserCV'

interface BioAreaProps {
  handleOpen: (
    name: string,
    position: ISelectedOptions,
    category: string
  ) => void
  buttonsArr: IButtonArr[]
}
const EduArea: React.FC<BioAreaProps> = ({
  handleOpen,
  buttonsArr,
}): JSX.Element => {
  const { t } = useTranslation(['userCV', 'buttons'])
  const { userEdu } = useUser()
  const { user } = useAuth()

  const userEduColumns = [
    `${t('cv.eduTable.years')}`,
    `${t('cv.eduTable.grade')}`,
    `${t('cv.eduTable.place')}`,
    '',
  ]

  const userEduRows: GridRowsProp = userEdu.map((education: IEdu) => {
    return {
      years: education.years,
      grade: education.grade,
      place: education.place,
      button: buttonsArr.map(
        (button) =>
          isUserPermitted(user, [
            button.modal === 'edit'
              ? permissionList.CV_UPDATE
              : permissionList.CV_DELETE,
          ]) && (
            <Tooltip key={button.title} title={button.title as string}>
              <IconButton
                onClick={() =>
                  handleOpen(button.modal as string, education, 'education')
                }
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          )
      ),
    }
  })

  return (
    <Grid item xs={10} md={6}>
      <TableTitle>{t('cv.eduTable.edu')}</TableTitle>
      <Paper>
        {userEduRows.length === 0 ? (
          <EmptyArrayCard message={t('cv.eduTable.message')} />
        ) : (
          <TableComponent columns={userEduColumns} rows={userEduRows} />
        )}
      </Paper>
    </Grid>
  )
}

export default EduArea
