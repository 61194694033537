import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

export const useUpdateStatusPkup = (reportId: number, status: number) => {
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  async function changePkupStatus(approverComment: string | null) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_UPDATE_STATE,
      { approverComment, state: status },
      { id: reportId }
    )
    return data
  }
  const { mutate: updatePkupStatus } = useMutation(changePkupStatus, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('pkupStatus')} ${t('change')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.pkupReportsList])
      queryClient.invalidateQueries([queryKeys.pkupDetails])
    },
  })

  return updatePkupStatus
}
