import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface IGroup {
  userId: number
  id: number
}

const deleteUser = async (group: IGroup) => {
  const groupId = group.id
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.USERS_DELETEGROUP,
    {},
    { userId: group.userId, groupId }
  )
  return data
}
export const useDeleteUserFromGroup = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')
  const { setNotification, handleCloseModal } = useNotifications()
  const { mutate: deleteUserFromGroup } = useMutation(deleteUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('user')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.user])
      void queryClient.invalidateQueries([queryKeys.userOne])
    },
  })
  return deleteUserFromGroup
}
