import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'
import { IBusinessUnit } from 'hooks/types'

export interface IInitialValuesBusinessUnit {
  name: string
  description: string
  email: string
}
async function getBusinessUnits(): Promise<IBusinessUnit[]> {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.BUSINESS_UNITS_GETALL
  )
  return data
}
const addBusinessUnit = async (businessUnit: {
  name: string | undefined
  description: string | undefined
  email: string | undefined
}) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.BUSINESS_UNITS_CREATE,
    { ...businessUnit }
  )
  return data
}

const deleteBusinessUnit = async (id?: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.BUSINESS_UNITS_DELETE,
    {},
    { id }
  )
  return data
}
const updateBusinessUnit = async (businessUnit: {
  name: string | undefined
  description: string | undefined
  email: string | undefined
  id: number
}) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.BUSINESS_UNITS_UPDATE,
    businessUnit,
    {
      id: businessUnit?.id,
    }
  )
  return data
}
export const useBusinessUnits = (businessUnitId?: number) => {
  const { t } = useTranslation('handleMessage')

  const fallback: IBusinessUnit[] = []

  const { data: businessUnitsList = fallback } = useQuery(
    queryKeys.businessUnitsList,
    getBusinessUnits
  )
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()
  const addUserToBusinessUnit = async (user: {
    userId: number
    isDirector: boolean
    canManageRequests: boolean
  }) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.BUSINESS_UNITS_ADD_USER,
      user,
      { id: businessUnitId }
    )
    return data
  }
  const deleteUserFromBusinessUnit = async (userId: number | undefined) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.BUSINESS_UNITS_DELETE_USER,
      {},
      { businessUnitId: businessUnitId, userId }
    )
    return data
  }
  const toggleUserRole = async (user: {
    userId: number | undefined
    canManageRequests?: boolean
    isDirector?: boolean
  }) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.BUSINESS_UNITS_TOGGLE_FLAGS,
      {
        canManageRequests: user.canManageRequests,
        isDirector: user.isDirector,
      },
      { businessUnitId: businessUnitId, userId: user.userId }
    )
    return data
  }

  const { mutate: addBusiness } = useMutation(addBusinessUnit, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('businessUnit')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.businessUnitsList])
    },
  })

  const { mutate: addUser } = useMutation(addUserToBusinessUnit, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('user')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.businessUnitsList])
    },
  })
  const { mutate: toggleUser } = useMutation(toggleUserRole, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('userRole')} ${t('change')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.businessUnitsList])
    },
  })
  const { mutate: deleteUser } = useMutation(deleteUserFromBusinessUnit, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('user')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.businessUnitsList])
    },
  })
  const { mutate: deleteBusiness } = useMutation(deleteBusinessUnit, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('businessUnit')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.businessUnitsList])
    },
  })
  const { mutate: updateBusiness } = useMutation(updateBusinessUnit, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('businessUnit')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.businessUnitsList])
    },
  })
  return {
    businessUnitsList,
    addUser,
    addBusiness,
    deleteUser,
    deleteBusiness,
    toggleUser,
    updateBusiness,
  }
}
