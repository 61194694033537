import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  SelectChangeEvent,
} from '@mui/material'
import { Worktype } from 'pages/Calendar/context/types'
import { useTranslation } from 'react-i18next'

import { useCalendarContext } from '../../../../context'
import AssignButton from '../AssignButton'

import SelectProject from './SelectProject'

interface ISelectProps {
  handleChange: (event: SelectChangeEvent, type: string) => void
  dayType: string
  selectedProject: string
  holidayType: string
}
const ChooseSelect: React.FC<ISelectProps> = ({
  handleChange,
  dayType,
  selectedProject,
  holidayType,
}): JSX.Element => {
  const { types, isAssigned, setIsAssigned, userHolidays } =
    useCalendarContext()
  const { t } = useTranslation(['calendar', 'buttons'])
  const userHolidayNames = userHolidays?.map(
    (holiday) =>
      `${holiday?.HolidaysCategory?.name} (${holiday?.days} ${t(
        'availableDays'
      )}) - ${holiday?.year}`
  )
  const handleAssignToProject = () => {
    setIsAssigned((prev) => !prev)
  }

  const type = types.find((type) => type.id === parseInt(dayType)) as Worktype

  if (type?.name?.includes?.('Holiday') && type?.paid) {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="Holiday">
              {t('addWorkdaysModal.holiday')}
            </InputLabel>
            <Select
              labelId="Holiday"
              id="Holiday"
              value={holidayType ? holidayType : '1'}
              label={t('addWorkdaysModal.holiday')}
              onChange={(e) => handleChange(e, 'holidayType')}
            >
              {[...userHolidayNames, 'none'].map((holiday, i) => (
                <MenuItem key={i} value={i + 1}>
                  {holiday}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <AssignButton
            isAssigned={isAssigned}
            setIsAssigned={handleAssignToProject}
          />
        </Grid>
        {isAssigned && (
          <Grid item xs={6}>
            <SelectProject
              handleChange={handleChange}
              selectedProject={selectedProject}
            />
          </Grid>
        )}
      </Grid>
    )
  } else if (type?.name?.includes?.('Holiday') && !type?.paid) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AssignButton isAssigned={isAssigned} setIsAssigned={setIsAssigned} />
        </Grid>
        {isAssigned && (
          <Grid item xs={6}>
            <SelectProject
              handleChange={handleChange}
              selectedProject={selectedProject}
            />
          </Grid>
        )}
      </Grid>
    )
  } else {
    return (
      <SelectProject
        handleChange={handleChange}
        selectedProject={selectedProject}
      />
    )
  }
}

export default ChooseSelect
