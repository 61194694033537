import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

export interface initialValuesTypes {
  groupName: string
  groupCode: string
}
export const regexNoWhiteSpace = /^\S*$/

const removeGroup = async (id: number | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.GROUPS_DELETE,
    {},
    { id }
  )
  return data
}
export const useGroup = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()

  const { t } = useTranslation('handleMessage')
  const { mutate: deleteGroup } = useMutation(removeGroup, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('group')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.groups])
    },
  })

  return deleteGroup
}
