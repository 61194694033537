import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { useEffect } from 'react'

interface IUserAgreementData {
  daysToEnd: number
  endDate: string
  firstName: string
  id: number
  lastName: string
  startDate: string
  type: string
  userId: number
}

const getAllUserAgreements = async (
  year: string
): Promise<IUserAgreementData[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.USERS_WITH_END_AGREEMENTS_GET,
    {},
    { year }
  )
  return data
}

export const useGetAllAgreements = (year: string) => {
  const fallback: IUserAgreementData[] = []

  const { data: agreements = fallback, refetch } = useQuery(
    queryKeys.userAgreements,
    () => getAllUserAgreements(year)
  )

  useEffect(() => {
    refetch()
  }, [year])
  return agreements
}
