import { useState } from 'react'
import { format } from 'date-fns'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { queryKeys } from 'react-query/constants'

import { IUserProjectTimeFrames } from './types'

const deleteAllocation = async (id: number | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.ALLOCATIONS_DELETEBYID,
    {},
    { id }
  )
  return data
}
export const dateFormat = (date: Date | undefined) => {
  if (date) return format(new Date(date as Date), 'yyyy-MM-dd')
  return '---'
}
export const useUserAllocations = () => {
  const [timeFrames, setTimeFrames] = useState<IUserProjectTimeFrames[] | []>(
    []
  )

  const queryClient = useQueryClient()

  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')
  const { mutate: deleteUserAllocation } = useMutation(deleteAllocation, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('allocation')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.userOne])
    },
  })

  return { deleteUserAllocation, timeFrames, setTimeFrames }
}
