import {
  IconButton,
  Box,
  Dialog,
  AppBar,
  Typography,
  Toolbar,
  Slide,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'

interface IProps {
  openDialog: boolean
  component: JSX.Element
  title: string
  handleCloseDialog: () => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} timeout={700} />
})

const DialogComponent: React.FC<IProps> = ({
  openDialog,
  handleCloseDialog,
  component,
  title,
}): JSX.Element => {
  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={() => handleCloseDialog()}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
            {title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleCloseDialog()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box p={5} mt={3} sx={{ display: 'flex', justifyContent: 'center' }}>
        {component}
      </Box>
    </Dialog>
  )
}

export default DialogComponent
