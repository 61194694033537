import { Box, Button, FormControlLabel, Checkbox } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { IUserAgreement } from 'hooks/types'
import { useValidationForms } from 'hooks/user'
import { AgreementDates } from 'components/users'

import { useUpdateHolidayToUser } from './utils'

interface IEditUserAgreementProps {
  userAgreement: IUserAgreement | null
}

const EditUserAgreement: React.FC<IEditUserAgreementProps> = ({
  userAgreement,
}): JSX.Element => {
  const { t } = useTranslation(['userEditPage', 'buttons'])
  const { validationAgreementDates } = useValidationForms()
  const updateUserAgreement = useUpdateHolidayToUser()
  return (
    <Box>
      <Formik
        initialValues={{
          startDate: userAgreement?.startDate,
          endDate: (userAgreement?.endDate
            ? userAgreement?.endDate
            : new Date()) as Date | null,
          indefinite: !userAgreement?.endDate,
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (values.indefinite) {
            values.endDate = null
          }
          updateUserAgreement({ ...values, id: userAgreement?.id })
          setSubmitting(false)
        }}
        validationSchema={validationAgreementDates}
      >
        {({ values, errors }) => (
          <Form>
            <Field
              type="checkbox"
              name="indefinite"
              as={FormControlLabel}
              control={<Checkbox checked={values.indefinite} />}
              label={t('modal.editUserAgreement.form.label1')}
            />
            <Box my={2}>
              <AgreementDates
                indefinite={values.indefinite}
                error={errors?.endDate as string}
              />
            </Box>
            <Box>
              <Button type="submit" sx={{ marginRight: 3 }}>
                {t('save', { ns: 'buttons' })}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
export default EditUserAgreement
