import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  IconButton,
  Slider,
  Tooltip,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  EmptyArrayCard,
  InfoSnackbar,
  SkillsAutocomplete,
  TableComponent,
} from 'components/common'
import { isUserPermitted, permissionList } from 'services'
import { filterArrayById } from 'utils'
import { ITags } from 'hooks/types'
import { useTagsValidation } from 'hooks/tags'
import { IUserProps } from 'components/users'
import { useState } from 'react'
import { useAuth } from 'hooks/useAuth'

import { useUpdateSkills } from './utils'

interface IUserSkills {
  skill: string
  rating: number | string
  rateYourSkill: JSX.Element
}
interface ISkillsProps {
  permission: boolean
}

const Skills: React.FC<IUserProps & ISkillsProps> = ({
  user,
  permission,
}): JSX.Element => {
  const {
    tags,
    addNewSkills,
    handleDelete,
    handleChange,
    handleCommittedChange,
  } = useUpdateSkills(user)
  const validationSchema = useTagsValidation()
  const { user: loggedUser } = useAuth()
  const { t } = useTranslation(['profile', 'buttons'])
  const columns = [
    t('skills.column1'),
    t('skills.column2'),
    t('skills.column3'),
  ]

  const rate = t('skills.rate')
  const sliders = (value: number, id: number) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box width={300}>
          <Slider
            key={`slider-${value}`}
            size="small"
            aria-label="Default"
            valueLabelDisplay={'auto'}
            min={0}
            max={10}
            onChange={() => handleChange(id)}
            onChangeCommitted={handleCommittedChange}
            disabled={!permission}
            defaultValue={value | 0}
            marks
          />
        </Box>
        {(loggedUser?.id !== user?.id
          ? isUserPermitted(loggedUser, [
              permissionList.SKILL_DELETE_OTHER_USER,
            ])
          : true) && (
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <Tooltip title="Delete">
              <IconButton
                color="primary"
                id={id.toString()}
                onClick={handleDelete}
              >
                <DeleteIcon fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    )
  }
  const userSkills = user?.tags?.map((tag) => {
    const skillLevel = tag?.UserTag?.skillLevel
    return {
      skill: tag.name,
      rating: skillLevel ? skillLevel : rate,
      rateYourSkill: sliders(skillLevel ? skillLevel : 0, tag.id),
    }
  }) as IUserSkills[]

  const rows: GridRowsProp = userSkills
  const userTags = user?.tags as ITags[]
  const difference = [...filterArrayById(tags, userTags)]
  const [values, setValues] = useState<string[]>([])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h3">{t('skills.title')}</Typography>
      <Box>
        <Formik
          initialValues={{ tags: [] }}
          onSubmit={({ tags }, { setSubmitting, resetForm }) => {
            const updateSkill = tags?.map(({ id, name }) => ({
              id: id,
              name: name,
            }))
            addNewSkills(updateSkill)
            resetForm()
            setValues([])
            setSubmitting(false)
          }}
          validationSchema={validationSchema}
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <Field
                component={SkillsAutocomplete}
                name="tags"
                values={values}
                error={errors?.tags}
                freeSolo={true}
                setValues={setValues}
                optionsData={difference?.map((option) => option)}
                label={t('skills.form.label1')}
              />
              {isSubmitting && <LinearProgress />}
              <Button color="primary" type="submit">
                {t('save', { ns: 'buttons' })}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
      <Box maxWidth={600}>
        {rows?.length ? (
          <>
            <Typography variant="h3">{t('skills.updateTitle')}</Typography>
            <TableComponent columns={columns} rows={rows} />
          </>
        ) : (
          <EmptyArrayCard message={t('skills.noSkills')} />
        )}
      </Box>
      <InfoSnackbar />
    </Box>
  )
}
export default Skills
