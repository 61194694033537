import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { IHolidaysCategory } from 'hooks/types'

const getHolidaysCategories = async (): Promise<IHolidaysCategory[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.HOLIDAYS_CATEGORIES_GETALL
  )
  return data
}

export const useGetAllHolidays = () => {
  const fallback: IHolidaysCategory[] = []

  const { data: holidaysCategories = fallback } = useQuery(
    queryKeys.holidaysCategories,
    getHolidaysCategories
  )
  const holidaysCategoriesWithUnits = holidaysCategories.map(
    (holidayCategory) => {
      const name = `${holidayCategory.name} (${
        holidayCategory.HolidayUnit?.name || 'days'
      })`
      return {
        ...holidayCategory,
        name,
      }
    }
  )
  return holidaysCategoriesWithUnits
}
