import { styled, Box } from '@mui/material'

export const BoxTag = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '80%',
  minHeight: 24,
  padding: '3px 10px',
  border: '1px solid rgba(2, 12, 120, 0.5)',
  borderRadius: 3,
  margin: 5,
})
