import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { useNotifications } from 'components/common'

export interface ModalConfirmProps {
  content: JSX.Element
  deleteFunction: () => void
  buttonName?: string
  btnColor?: string
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  content,
  deleteFunction,
  buttonName,
  btnColor,
}): JSX.Element => {
  const { t } = useTranslation(['systemBusinessUnits', 'buttons'])
  const { handleCloseModal } = useNotifications()
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'center',
          margin: '1rem 0',
        }}
      >
        {content}
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          onClick={deleteFunction}
          color={btnColor ? 'error' : 'primary'}
          sx={{ marginRight: 2, maxWidth: '80%' }}
        >
          {buttonName || t('yes', { ns: 'buttons' })}
        </Button>
        <Button onClick={() => handleCloseModal()}>
          {t('no', { ns: 'buttons' })}
        </Button>
      </Box>
    </Box>
  )
}
export default ModalConfirm
