import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@mui/material'
import {
  ModalComponent,
  ModalConfirm,
  useNotifications,
} from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { IButtonArr, ISelectingModal } from 'hooks/types'

import { AddNewPositionToCV, EditPosition } from '../../modals'
import { BioArea, EduArea, ProjectsArea } from '../index'

import { useUserBio, useUserEdu, useUserProject } from './hooks'

interface IDeleteContent {
  name: string
  text: string | number | undefined
}
export interface ISelectedOptions {
  id?: number
  type?: string
  title?: string
  description?: string
  years?: string
  grade?: string
  place?: string
  stack?: string
}
interface UserCVProps {
  userId: number
}
const UserCV: React.FC<UserCVProps> = ({ userId }): JSX.Element => {
  const { t } = useTranslation(['userCV', 'buttons'])
  const { handleOpenModal, modalName } = useNotifications()
  const { deleteEdu } = useUserEdu(userId)
  const { deleteBio } = useUserBio(userId)
  const { deleteProject } = useUserProject(userId)
  const [selectedPosition, setSelectedPosition] = useState<ISelectedOptions>({})
  const [selectedCategory, setSelectedCategory] = useState('')
  const buttonsArr: IButtonArr[] = [
    {
      modal: 'edit',
      title: `${t('edit', { ns: 'buttons' })}`,
      icon: <EditIcon />,
    },
    {
      modal: 'delete',
      title: `${t('delete', { ns: 'buttons' })}`,
      icon: <DeleteIcon />,
    },
  ]

  const deleteContentBioArr: IDeleteContent[] = [
    { name: `${t('cv.bioTable.title')}`, text: selectedPosition.title },
    {
      name: `${t('cv.bioTable.description')}`,
      text: selectedPosition.description,
    },
  ]
  const deleteContentEduArr: IDeleteContent[] = [
    { name: `${t('cv.eduTable.years')}`, text: selectedPosition?.years },
    { name: `${t('cv.eduTable.grade')}`, text: selectedPosition?.grade },
    { name: `${t('cv.eduTable.place')}`, text: selectedPosition.place },
  ]
  const deleteContentProjectArr: IDeleteContent[] = [
    { name: `${t('cv.projectsTable.name')}`, text: selectedPosition.title },
    {
      name: `${t('cv.projectsTable.description')}`,
      text: selectedPosition.description,
    },
    { name: `${t('cv.projectsTable.stack')}`, text: selectedPosition.stack },
  ]

  const deleteContentFunction = (array: IDeleteContent[]) => {
    return (
      <Box>
        <Typography variant="h3"> {t('cv.modal.delete.text')}</Typography>
        {array.map((column: IDeleteContent) => (
          <Box
            key={column.name}
            sx={{ display: 'flex', alignItems: 'baseline', gap: '5px' }}
          >
            <Typography variant="subtitle2">{column.name}: </Typography>
            <Typography variant="subtitle1">{column.text} </Typography>
          </Box>
        ))}
      </Box>
    )
  }
  const deleteContent = () => {
    switch (selectedCategory) {
      case 'bio':
        return deleteContentFunction(deleteContentBioArr)
      case 'education':
        return deleteContentFunction(deleteContentEduArr)
      case 'projects':
        return deleteContentFunction(deleteContentProjectArr)
      default:
        return <></>
    }
  }
  const deleteFunction = () => {
    switch (selectedCategory) {
      case 'bio':
        return deleteBio(Number(selectedPosition?.id))
      case 'education':
        return deleteEdu(Number(selectedPosition?.id))
      case 'projects':
        return deleteProject(Number(selectedPosition?.id))
      default:
        return () => {
          throw new Error('error text')
        }
    }
  }
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'add':
        return {
          component: <AddNewPositionToCV userId={userId} />,
          title: `${t('cv.modal.addNewPositionToCV.title')}`,
        }
      case 'edit':
        return {
          component: (
            <EditPosition
              userId={userId}
              position={selectedPosition}
              category={selectedCategory}
            />
          ),
          title: `${t('cv.modal.edit.title')}`,
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteFunction()}
            />
          ),
          title: `${t('cv.modal.delete.title')}`,
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('cv.modal.default')}`,
        }
    }
  }
  const handleOpen = (
    name: string,
    position: ISelectedOptions,
    category: string
  ) => {
    handleOpenModal(name)
    setSelectedCategory(category)
    category && setSelectedPosition(position)
  }
  return (
    <>
      <Button onClick={() => handleOpenModal('add')}>
        {t('cv.addPosition')}
      </Button>
      <Grid
        container
        spacing={5}
        justifyContent="center"
        sx={{ minHeight: '50vh' }}
      >
        <BioArea handleOpen={handleOpen} buttonsArr={buttonsArr} />
        <EduArea handleOpen={handleOpen} buttonsArr={buttonsArr} />
        <ProjectsArea handleOpen={handleOpen} buttonsArr={buttonsArr} />
      </Grid>
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </>
  )
}
export default UserCV
