import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Box, Button, LinearProgress } from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'
import { TextField } from 'formik-mui'
import { initialValuesTypes } from 'pages/Groups/utils'

import { regexNoWhiteSpace } from '../../utils'

interface GroupFormProps {
  initialValues: initialValuesTypes
  onSubmitForm: (values: {
    groupName: string
    groupCode: string
    id: number | undefined
  }) => void
  groupId?: number
}
const GroupForm: React.FC<GroupFormProps> = ({
  initialValues,
  onSubmitForm,
  groupId,
}): JSX.Element => {
  const { t } = useTranslation(['groups', 'buttons', 'validation'])

  const validationSchema = yup.object({
    groupName: yup
      .string()

      .required(`${t('groupName.required', { ns: 'validation' })}`),
    groupCode: yup
      .string()
      .matches(
        regexNoWhiteSpace,
        `${t('groupCode.match', { ns: 'validation' })}`
      )
      .required(`${t('groupCode.required', { ns: 'validation' })}`),
  })

  const groupFields: IFields[] = [
    {
      id: '1',
      label: `${t('modal.addNewGroup.label1')}`,
      name: 'groupName',
      type: 'text',
    },
    {
      id: '2',
      label: `${t('modal.addNewGroup.label2')}`,
      name: 'groupCode',
      type: 'text',
      inputProps: { style: { textTransform: 'uppercase' } },
    },
  ]
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm({ ...values, id: groupId })
        setSubmitting(false)
      }}
      enableReinitialize
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <>
            <Box mb={2}>
              {groupFields.map(({ id, name, type, label, inputProps }) => (
                <Field
                  fullWidth
                  key={id}
                  component={TextField}
                  name={name}
                  type={type}
                  label={label}
                  margin="normal"
                  size="small"
                  inputProps={inputProps}
                />
              ))}
            </Box>
          </>
          <Button disabled={isSubmitting} onClick={submitForm}>
            {t('save', { ns: 'buttons' })}
          </Button>
          <br />
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}

export default GroupForm
