import { useParams } from 'react-router-dom'
import { Container } from '@mui/material'
import { HeaderTab } from 'views/LayoutUI/components'
import { usePkupsDetails } from 'pages/HrDashboard/hooks'
import { PkupsDetails } from 'components/Pkup'
import { PageRoutes } from 'routes'

const PkupsDashboardPkupsDetails: React.FC = (): JSX.Element => {
  const { id } = useParams()
  const { pkupDetails } = usePkupsDetails(Number(id))
  return (
    <Container>
      <HeaderTab
        translate="pkupsDashboard"
        id={pkupDetails.title}
        pageLink={PageRoutes.PKUPS_DASHBOARD}
      />
      <PkupsDetails page="pkupsDashboard" />
    </Container>
  )
}
export default PkupsDashboardPkupsDetails
