import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Container,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { HeaderTab } from 'views/LayoutUI/components'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  EmptyArrayCard,
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
} from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import { permissionList, isUserPermitted } from 'services'
import { useAuth } from 'hooks/useAuth'
import { ISelectingModal } from 'hooks/types'
import { timeoutAnimation } from 'utils'

import { IHolidays, useHolidays } from './utils'
import { AddHoliday } from './modals'

const Holidays: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['holidays', 'buttons'])
  const { handleOpenModal, modalName } = useNotifications()
  const { holidaysList, deleteHoliday, setHoliday, holiday } = useHolidays()
  const columns = ['#', `${t('table.cell1')}`, `${t('table.cell2')}`, '']

  const { user } = useAuth()
  const rows: GridRowsProp = holidaysList.map((holiday: IHolidays) => {
    return {
      id: holiday.id,
      name: holiday.name,
      date: holiday.date.slice(0, 10),
      button: isUserPermitted(user, [permissionList.HOLIDAY_DELETE]) && (
        <Tooltip title={t('delete', { ns: 'buttons' }) as string}>
          <IconButton
            onClick={() => {
              setHoliday(holiday)
              handleOpenModal('delete')
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      ),
    }
  })
  const deleteContent = (
    <Typography>
      {t('modal.delete.text1')} {holiday?.name}?
    </Typography>
  )
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addHoliday':
        return {
          component: <AddHoliday />,
          title: 'Add holiday',
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent}
              deleteFunction={() => deleteHoliday(holiday?.id)}
            />
          ),
          title: `${t('modal.delete.title')}`,
        }
      default:
        return {
          component: <DefaultModal />,
          title: 'Default modal',
        }
    }
  }
  return (
    <Container>
      <HeaderTab translate="holidays" />
      {isUserPermitted(user, [permissionList.HOLIDAY_CREATE]) && (
        <Button onClick={() => handleOpenModal('addHoliday')}>
          {t('button')}
        </Button>
      )}
      {rows.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent columns={columns} rows={rows} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}

export default Holidays
