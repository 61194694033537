import { useTranslation } from 'react-i18next'
import { TableComponent } from 'components/common'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { useDeleteTimeframes } from 'hooks/customers'

import { dateFormat } from '../../utils'
import { useGetUserTimeFrames } from '../../components/TimeFrames/utils'

interface IHistoryTimeFrames {
  timeFrames?: number | null
}

const HistoryTimeFrames: React.FC<IHistoryTimeFrames> = ({
  timeFrames,
}): JSX.Element => {
  const { t } = useTranslation('userAllocations')
  const { deleteTimeframe } = useDeleteTimeframes()
  const columns = [
    `${t('modals.historyTimeFrames.col1')}`,
    `${t('modals.historyTimeFrames.col2')}`,
    `${t('modals.historyTimeFrames.col3')}`,
    '',
  ]
  const { userTimeFrames } = useGetUserTimeFrames()

  const timeFrame =
    userTimeFrames
      ?.find((userTimeFrame) => userTimeFrame.ProjectId === timeFrames)
      ?.UserProjectTimeframes?.map(({ startDate, endDate, notes, id }) => {
        return {
          startDate: dateFormat(startDate),
          endDate: dateFormat(endDate),
          notes: notes,
          button: (
            <IconButton onClick={() => deleteTimeframe(id)}>
              <DeleteIcon color="error" />
            </IconButton>
          ),
        }
      }) || []
  return <TableComponent columns={columns} rows={timeFrame} />
}

export default HistoryTimeFrames
