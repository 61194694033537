import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Fade, IconButton, Paper, Tooltip } from '@mui/material'
import { useHrDashboard } from 'pages/HrDashboard/hooks/useHrDashboard'
import { GridRowsProp } from '@mui/x-data-grid'
import { EmptyArrayCard, TableComponent, TableTitle } from 'components/common'
import InfoIcon from '@mui/icons-material/Info'
import { timeoutAnimation } from 'utils'

const PkupsToReview: React.FC = (): JSX.Element => {
  const { t } = useTranslation('hrDashboard')
  const columns = [`${t('pkups.label1')}`, `${t('pkups.label2')}`, '']
  const { pkupsToReview } = useHrDashboard()
  const navigate = useNavigate()

  const rows: GridRowsProp = pkupsToReview.map((pkup) => {
    return {
      employee: pkup.title,
      period: pkup.period,
      button: (
        <Tooltip title={t('pkups.tooltip1') as string}>
          <IconButton onClick={() => navigate(`/pkups/${pkup.id}`)}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      ),
    }
  })
  return (
    <Paper sx={{ padding: '1rem', minWidth: '29rem' }}>
      <TableTitle>{t('pkups.title')} </TableTitle>
      {pkupsToReview.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent rows={rows} columns={columns} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('pkups.message')} />
      )}
    </Paper>
  )
}
export default PkupsToReview
