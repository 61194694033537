import { Button, Typography } from '@mui/material'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import DeleteIcon from '@mui/icons-material/Delete'
import { Workday } from 'pages/Calendar/context/types'
import { isWeekend } from 'date-fns/esm'
import { useTranslation } from 'react-i18next'
import { useNotifications } from 'components/common'
import { useDeleteWorkdays } from 'hooks/calendar/useDeleteWorkdays'

import WeekendDates from '../Dates/WeekendDates'
import SelectedDates from '../Dates/SelectedDates'
import { useCalendarContext } from '../../context'

import {
  ButtonsContainer,
  DeleteModalContainer,
  IconsContainer,
  ParentDiv,
} from './index.style'

const DeleteModal: React.FC = (): JSX.Element => {
  const {
    setDeleteMode,
    setTheSameDays,
    theSameDays,
    weekends,
    setWeekends,
    excludeWeekends,
  } = useCalendarContext()

  const { deleteWorkdays } = useDeleteWorkdays()
  const { handleCloseModal, handleCloseBar } = useNotifications()
  const { t } = useTranslation(['calendar', 'buttons'])

  const deleteEventsFromCalendar = (data: Workday[]) => {
    setTheSameDays([])
    setWeekends([])
    const days = data.filter((d) => !d.extendedProps.needApproval)
    let ids: number[]
    if (excludeWeekends) {
      const filterArr = days.filter(
        (workday) => !isWeekend(new Date(workday.start))
      )
      ids = filterArr.map((workday) => workday.id)
    } else {
      ids = days.map((id) => id.id)
    }
    deleteWorkdays(ids)

    setDeleteMode(false)
  }

  return (
    <ParentDiv>
      <DeleteModalContainer>
        <SelectedDates />
        {weekends.length > 0 && <WeekendDates />}
        <IconsContainer>
          <AutoAwesomeMotionIcon color="primary" />
          <ArrowRightAltIcon color="primary" />
          <DeleteIcon color="primary" />
        </IconsContainer>
        <Typography variant="h3">{t('deleteModal.question')}</Typography>
        <ButtonsContainer>
          <Button
            onClick={() => {
              deleteEventsFromCalendar(theSameDays)
              handleCloseModal()
              handleCloseBar()
            }}
          >
            {t('yes', { ns: 'buttons' })}
          </Button>
          <Button
            onClick={() => {
              setTheSameDays([])
              handleCloseModal()
              setWeekends([])
              setDeleteMode(false)
            }}
            variant="outlined"
          >
            {t('no', { ns: 'buttons' })}
          </Button>
        </ButtonsContainer>
      </DeleteModalContainer>
    </ParentDiv>
  )
}

export default DeleteModal
