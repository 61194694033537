import { Button, Typography } from '@mui/material'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import SquareIcon from '@mui/icons-material/Square'
import { useNotifications } from 'components/common'
import { Workday } from 'pages/Calendar/context/types'
import { useTranslation } from 'react-i18next'
import { useCopyWorkdays } from 'hooks/calendar/useCopyWorkdays'

import SelectedDates from '../Dates/SelectedDates'
import { useCalendarContext } from '../../context'

import {
  ButtonsContainer,
  CopyModalContainer,
  IconsContainer,
  ParentDiv,
} from './index.style'

const ConfirmModal: React.FC = (): JSX.Element => {
  const { setCopyDates, theSameDays, setCopyMode } = useCalendarContext()
  const { handleCloseModal, handleCloseBar } = useNotifications()
  const { t } = useTranslation(['calendar', 'buttons'])
  const copyWorkdays = useCopyWorkdays()

  const changeToWorked = (data: Workday[]) => {
    const temp = { workdays: data.map((workday) => workday.id) }
    copyWorkdays(temp)
  }

  return (
    <ParentDiv>
      <CopyModalContainer>
        <SelectedDates />
        <IconsContainer>
          <SquareOutlinedIcon color="primary" />
          <ArrowRightAltIcon color="primary" />
          <SquareIcon color="primary" />
        </IconsContainer>
        <Typography variant="h3">{t('copyModal.question')}</Typography>
        <ButtonsContainer>
          <Button
            onClick={() => {
              setCopyDates(true)
              setCopyMode(false)
              changeToWorked(theSameDays)
              handleCloseModal()
              handleCloseBar()
            }}
          >
            {t('yes', { ns: 'buttons' })}
          </Button>
          <Button
            onClick={() => {
              setCopyMode(false)
              handleCloseModal()
            }}
            variant="outlined"
          >
            {t('no', { ns: 'buttons' })}
          </Button>
        </ButtonsContainer>
      </CopyModalContainer>
    </ParentDiv>
  )
}

export default ConfirmModal
