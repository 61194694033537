import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useAllInventories } from 'hooks/inventory/useAllInventories'

interface IUpdateInventory {
  allowUserToModify: boolean
  id: number
}

const createInventory = async (inventory: {
  name: string
  allowUserToModify: boolean
}) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.INVENTORY_CREATE,
    inventory
  )
  return data
}

const deleteInventory = async (inventoryId: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.INVENTORY_DELETE,
    {},
    { id: inventoryId }
  )
  return data
}

const allowUserToModifyInventoryItem = async (inventory: IUpdateInventory) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.INVENTORY_UPDATE,
    { allowUserToModify: !inventory.allowUserToModify },
    { id: inventory.id }
  )
  return data
}

export const useManageInventoriesList = () => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotifications()
  const inventories = useAllInventories()
  const { t } = useTranslation('handleMessage')
  const { mutate: createNewInventory } = useMutation(createInventory, {
    onSuccess: () => {
      setNotification({
        message: `${t('inventory')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.inventories])
    },
  })
  const { mutate: deleteInventoryItem } = useMutation(deleteInventory, {
    onSuccess: () => {
      setNotification({
        message: `${t('inventory')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.inventories])
    },
  })

  const { mutate: allowUserToModifyInventory } = useMutation(
    allowUserToModifyInventoryItem,
    {
      onSuccess: () => {
        setNotification({
          message: `${t('inventory')} ${t('update')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        void queryClient.invalidateQueries([queryKeys.inventories])
        void queryClient.invalidateQueries([queryKeys.user])
      },
    }
  )

  const allInventories = inventories?.data?.map((inventory) => {
    return {
      id: inventory.id,
      name: inventory.name,
      allowUserToModify: inventory.allowUserToModify,
    }
  })
  return {
    allInventories,
    allowUserToModifyInventory,
    createNewInventory,
    deleteInventoryItem,
  }
}

export default useManageInventoriesList
