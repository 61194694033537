import { Box, styled, Typography, Button } from '@mui/material'

export const ModalWrapper = styled(Box)({
  display: 'flex',
  maxHeight: '70vh',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  overflow: 'auto',
})

export const CurrentInformationsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  width: '100%',
  padding: '20px 30px',
  marginBottom: '10px',
  boxShadow: `inset 0 0 10px ${theme.palette.secondary.dark}`,
  borderRadius: '5px',
  boxSizing: 'border-box',
}))

export const CurrentInformationsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '20px',
  boxSizing: 'border-box',
})

export const TypographyWrapper = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
})

export const TimeWrapper = styled(Box)({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'center',
})

export const SetupProjectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  width: '100%',
  padding: '20px 30px',
  marginBottom: '10px',
  boxShadow: `inset 0 0 10px ${theme.palette.secondary.dark}`,
  borderRadius: '5px',
  boxSizing: 'border-box',
}))

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  height: '50px',
})

export const StyledButton = styled(Button)({
  maxWidth: '30px',
  maxHeight: '30px',
})
