import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Box, Button, LinearProgress } from '@mui/material'
import { IBusinessUnit } from 'hooks/types'

import { BusinessUnitForm } from '../../components'
import { IInitialValuesBusinessUnit, useBusinessUnits } from '../../utils'

export interface EditBusinessUnitProps {
  initialValues: IInitialValuesBusinessUnit
  validationSchema: object
  id: IBusinessUnit['id']
}
const EditBusinessUnit: React.FC<EditBusinessUnitProps> = ({
  initialValues,
  validationSchema,
  id,
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const { updateBusiness } = useBusinessUnits()

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          updateBusiness({ ...values, id: id })
          setSubmitting(false)
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <BusinessUnitForm />
            <Button type="submit" onClick={submitForm}>
              {t('save')}
            </Button>
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </Box>
  )
}
export default EditBusinessUnit
