import { ReactionBarSelector } from '@charkour/react-reactions'
import { Box, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  setFieldValue: (name: string, value: string) => void
  mood: string | null
}

export enum Progress {
  PERFECT = 'PERFECT',
  GOOD = 'GOOD',
  BORING = 'BORING',
  BAD = 'BAD',
}
const Reactions: React.FC<IProps> = ({ setFieldValue, mood }) => {
  const { t } = useTranslation('reactions')
  const [icon, setIcon] = useState(false)

  const reactions = [
    {
      label: t('labels.labelHappy'),
      node: <Box>😄</Box>,
      key: Progress.PERFECT,
    },
    {
      label: t('labels.labelLearn'),
      node: <Box>😊</Box>,
      key: Progress.GOOD,
    },
    {
      label: t('labels.labelBoring'),
      node: <Box>😴</Box>,
      key: Progress.BORING,
    },
    { label: t('labels.labelHard'), node: <Box>😖</Box>, key: Progress.BAD },
  ]

  const findReaction = reactions.find((reaction) => reaction.key === mood)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {mood !== null ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '10px',
          }}
          onClick={() => setIcon((prev) => !prev)}
        >
          <Tooltip title={findReaction?.label as string} placement="top">
            <Box
              sx={{
                height: '25px',
                width: '25px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {findReaction?.node}
            </Box>
          </Tooltip>
        </Box>
      ) : (
        <Box className="BoxA">
          <ReactionBarSelector
            reactions={reactions}
            iconSize={16}
            style={{ marginLeft: '10px' }}
            onSelect={(key) => {
              const item = reactions.find((reaction) => reaction.key === key)
              if (item) {
                setFieldValue('mood', item.key)
              } else {
                return
              }
            }}
          />
        </Box>
      )}
      {icon && (
        <Box className="BoxA">
          <ReactionBarSelector
            reactions={reactions}
            iconSize={16}
            onSelect={(key) => {
              const item = reactions.find((reaction) => reaction.key === key)
              if (item) {
                setFieldValue('mood', item.key)
                setIcon((prev) => !prev)
              } else {
                return
              }
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default Reactions
