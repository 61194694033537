import { Box, styled } from '@mui/material'

export const DeleteModalContainer = styled(Box)({
  display: 'flex',
  maxHeight: '70vh',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  overflow: 'auto',
})

export const IconsContainer = styled(Box)({
  display: 'flex',
  gap: '10px',
  marginTop: '10px',
})

export const ButtonsContainer = styled(Box)({
  display: 'flex',
  alignSelf: 'flex-end',
  columnGap: '10px',
})
export const ParentDiv = styled(Box)`
 *  {
  box-sizing: border-box;
    `
