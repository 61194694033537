import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import {
  Box,
  MenuItem,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  Typography,
} from '@mui/material'
import { InventoryFields } from 'components/inventory'
import { CustomAutocompleteInput, CustomizedSelect } from 'components/common'
import { useAllInventories } from 'hooks/inventory'
import { useAllUsers } from 'hooks/useAllUsers'

import { useAddInventory } from './utils'

const AddNewItems: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['inventory', 'buttons'])

  const inventories = useAllInventories()

  const {
    createNewInventoryInUser,
    handleClick,
    initialValues,
    inventoryId,
    validationSchema,
  } = useAddInventory()
  const allUsers = useAllUsers()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        createNewInventoryInUser({
          model: values.model,
          serialNumber: values.serialNumber,
          producer: values.producer,
          additionalInformation: values.additionalInformation,
          inventoryId: inventoryId,
          userId: values.assignUser ? values.assignUser.id : null,
        })
      }}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, values }) => (
        <Form>
          <Field
            type="checkbox"
            name="checkUser"
            as={FormControlLabel}
            control={<Checkbox checked={values.checkUser} />}
            label={t('modal.addNewItems.label2')}
          />
          {values.checkUser && (
            <Field
              component={CustomAutocompleteInput}
              optionsData={
                allUsers.map((user) => {
                  return {
                    name: `${user.firstName} ${user.lastName} ${user.email}`,
                    id: user.id,
                  }
                }) || []
              }
              name="assignUser"
              label={t('modal.addNewItems.label1')}
              disableClearable={false}
              error={errors.assignUser}
            />
          )}
          <Box my={3}>
            <FormControl fullWidth error={errors.name ? true : false}>
              <InputLabel id="inventory">
                {t('modal.addNewItems.label3')}
              </InputLabel>
              <Field
                label={t('modal.addNewItems.label3')}
                id="inventory"
                name="name"
                component={CustomizedSelect}
              >
                {inventories?.data?.map(({ id, name }) => (
                  <MenuItem
                    key={id}
                    value={name}
                    onClick={() => {
                      handleClick(id)
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            {errors.name && (
              <Typography variant="h6" color="error" mt={0.5}>
                {errors.name}
              </Typography>
            )}
          </Box>
          <Box my={2}>
            <InventoryFields />
          </Box>
          <Button
            type="submit"
            disabled={inventories?.data[0]?.id ? false : true}
          >
            {t('save', { ns: 'buttons' })}
          </Button>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default AddNewItems
