import { useCVSchema } from './useCVSchema'
import { useDataFields } from './useDataFields'

export const useFormikPosition = () => {
  const {
    userBioSchema,
    userEducationSchema,
    userProfileSchema,
    userProjectsSchema,
  } = useCVSchema()
  const { bioFields, educationFields, profileFields, projectsFields } =
    useDataFields()
  const CVSchema = (category: string) => {
    switch (category) {
      case 'bio':
        return userBioSchema
      case 'education':
        return userEducationSchema
      case 'profile':
        return userProfileSchema
      case 'projects':
        return userProjectsSchema
      default:
        return {}
    }
  }
  const selectForm = (category: string) => {
    switch (category) {
      case 'bio':
        return bioFields
      case 'education':
        return educationFields
      case 'projects':
        return projectsFields
      case 'profile':
        return profileFields
      default:
        return []
    }
  }
  return { CVSchema, selectForm }
}
