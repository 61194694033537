import { useReducer, useEffect } from 'react'
import { searchConfig } from 'config'

import { paginationUCReducer } from './paginationReducer'
import { IPaginateReducer, IFilterPayload } from './types'
import { ChangeItemsInPage } from './enum'

export const defaultPaginationValue = {
  length: 10,
  currentPage: 0,
  search: null,
  order: null,
  filters: null,
}

const handleTypeOfVariable = (key: string, value: any) => {
  switch (key) {
    case 'page':
      return Number(value)
    case 'limit':
      return Number(value)
    case 'order':
      try {
        return JSON.parse(value)
      } catch (error) {
        console.error('handleTypeOfVariable: JSON.parse error', value)
        return value
      }
    default:
      return value
  }
}

export const getDefault = (
  defaultPaginationValue: object,
  searchParams?: URLSearchParams,
  defaultOverwrite?: any
) => {
  if (!searchParams)
    return {
      ...defaultPaginationValue,
      filters: JSON.stringify({ inactive: false }),
    }

  const paramsMapping = {
    page: 'currentPage',
    limit: 'length',
    search: 'search',
    order: 'order',
  }

  const newPaginationUc = {
    ...defaultPaginationValue,
    ...(defaultOverwrite || {}),
  }
  for (const [key, value] of searchParams.entries()) {
    if (paramsMapping?.[key]) {
      newPaginationUc[paramsMapping[key]] = handleTypeOfVariable(key, value)
    } else {
      newPaginationUc[key] = handleTypeOfVariable(key, value)
    }
  }
  return newPaginationUc
}

export const useTablePagedFunction = (
  changePagination?: (pagination: IPaginateReducer) => void,
  searchParams?: URLSearchParams
) => {
  const [paginationUC, dispatchUC] = useReducer(
    paginationUCReducer,
    getDefault(defaultPaginationValue, searchParams)
  )

  useEffect(() => {
    if (changePagination) {
      changePagination(paginationUC)
    }
  }, [paginationUC])

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatchUC({
      type: ChangeItemsInPage.CHANGE_PAGE,
      payload: {
        currentPage: newPage,
      },
    })
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatchUC({
      type: ChangeItemsInPage.MULTI_EDIT_STATE,
      payload: {
        length: parseInt(event.target.value, 10),
        currentPage: 0,
      },
    })
  }

  const handleFilter = (payload: IFilterPayload) => {
    localStorage.setItem(searchConfig.LAST_SEARCH_TERM_KEY, payload.name)
    dispatchUC({
      type: ChangeItemsInPage.MULTI_EDIT_STATE,
      payload: {
        length: paginationUC.length,
        currentPage: 0,
        filters: JSON.stringify({
          tags: payload.checked,
          inactive: !payload.active,
        }),
        search: payload.name,
      },
    })
  }

  const sortBy = (column: string, orderBy: string) => {
    const order = paginationUC.order
    if (order && column === order.column) {
      orderBy = order.order === 'ASC' ? 'DESC' : 'ASC'
    }
    dispatchUC({
      type: ChangeItemsInPage.ORDER,
      payload: {
        order: orderBy,
        column,
      },
    })
  }

  return {
    dispatchUC,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    paginationUC,
    sortBy,
  }
}
