import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Paper,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  AllocationBadge,
  DetailsTooltip,
  TableTitle,
  useNotifications,
} from 'components/common'

import AllocationsDate from '../AllocationsDate'
import { useReportAllocationContext } from '../../context'
import { IProjectsRow, IProjects } from '../../types'

import UserRows from './UserRows'

const UsersReportTable = () => {
  const { t } = useTranslation('reportAllocations')
  const { userDetailsId, setUserDetailsId } = useNotifications()
  const { dates, allocationByUsers } = useReportAllocationContext()

  const projectsRow: IProjectsRow[] = allocationByUsers.map((user) => {
    const allocationSummary: JSX.Element[] = user?.userSummary?.map(
      (summary, index) => {
        const allocationsDetails = [
          {
            hours: summary.plannedFTE,
            tooltip: t('allocationSummary.plannedFTE'),
            color: 'primary.dark',
          },
          {
            hours: summary.usedFTE,
            tooltip: t('allocationSummary.usedFTE'),
            color: 'primary.main',
          },
          {
            hours: summary.plannedHours,
            tooltip: t('allocationSummary.plannedHours'),
            color: 'primary.medium',
          },
          {
            hours: summary.usedHours,
            tooltip: t('allocationSummary.usedHours'),
            color: 'primary.light',
          },
        ]
        return (
          <AllocationBadge
            key={index}
            details={allocationsDetails}
            center={true}
          />
        )
      }
    )
    const allProj: IProjects[] = user?.userProjects?.map((project) => {
      const mapDates = dates.map((date) => (
        <AllocationsDate
          key={date}
          allocations={project.allocations}
          date={date}
        />
      ))

      return {
        name: project.projectName,
        dates: mapDates,
      }
    })
    return {
      id: user.id,
      name: (
        <DetailsTooltip id={userDetailsId}>
          <Box>
            <Typography
              typography="h4"
              onMouseOver={() => setUserDetailsId(user.id)}
              sx={{ cursor: 'pointer' }}
            >
              {user.firstName} {user.lastName}
            </Typography>
          </Box>
        </DetailsTooltip>
      ),
      projects: allProj,
      summary: allocationSummary,
    }
  })

  if (!allocationByUsers.length) return null
  return (
    <Box my={2}>
      <TableTitle>{t('usersTable.title')}</TableTitle>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            {projectsRow.map(({ name, projects, summary, id }) => (
              <UserRows
                key={`${id}-name`}
                row={name}
                projects={projects}
                summary={summary}
                dates={dates}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
export default UsersReportTable
