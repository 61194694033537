import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useNotifications, NOTIFICATION } from 'components/common'
import { IUser } from 'hooks/types'
import { queryKeys } from 'react-query/constants'

interface IUserData {
  firstName?: string
  lastName?: string
  companyPhoneNumber?: string
  privatePhoneNumber?: string
  privateEmail?: string
  password?: string
  currentPassword?: string
  email?: string
}

const updateUserOnServer = async (userData: IUserData): Promise<IUser> => {
  const { data } = await apiRequest(APIRoutes.UPDATE_ME, userData)
  return data.data
}

export const usePersonalInformation = (): UseMutateFunction<
  IUserData,
  unknown,
  IUserData,
  unknown
> => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotifications()

  const { mutate: patchUser } = useMutation(updateUserOnServer, {
    onSuccess: () => {
      setNotification({
        message: 'User updated!',
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
    },
  })
  return patchUser
}
