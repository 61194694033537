import { Button, LinearProgress } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { IInitialValues } from '../..'
import LocationForm from '../../components/LocationForm'
import { ILocation, useLocationsList } from '../../useLocationsList'
interface EditLocationProps {
  location: ILocation
  initialValues: IInitialValues
  validationSchema: object
}
const EditLocation: React.FC<EditLocationProps> = ({
  location,
  initialValues,
  validationSchema,
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const { editLocation } = useLocationsList(location?.id)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        editLocation(values)
        setSubmitting(false)
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <LocationForm />
          <Button type="submit">{t('save')}</Button>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default EditLocation
