import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IUser } from 'hooks/types'

export interface IBio {
  id: number
  title: string
  description: string
  userId: number
  order: number
  createdAt: Date
  updatedAt: Date | null
}
export interface IEdu {
  id: number
  grade: string
  place: string
  years: string
  userId: number
  order: number
  createdAt: Date
  updatedAt: Date | null
}
export interface IProfile {
  id: number
  type: string
  userId: number
  createdAt: Date | null
  updatedAt: Date | null
  bios: {
    id: number
    bioId: number
    cvprofileId: number
    createdAt: Date
    updatedAt: Date | null
    revision: number
    cvProfileId: number
  }[]
  educations: {
    id: number
    educationId: number
    cvProfileId: number
    createdAt: Date
    updatedAt: Date | null
    revision: number
  }[]
  projects: {
    id: number
    cvProjectId: number
    CvProfileId: number
    createdAt: Date
    updatedAt: Date | null
    revision: number
  }[]
}
export interface IProjects {
  id: 2
  title: string
  description: string
  stack: string
  userId: number
  order: number
  createdAt: Date
  updatedAt: Date | null
}

export const useUser = () => {
  const { id } = useParams()
  const fallbackUser = <IUser>{}
  const fallbackBio: IBio[] = []
  const fallbackEdu = [] as IEdu[]
  const fallbackProjects = [] as IProjects[]
  const fallbackProfile = [] as IProfile[]

  const fetchUser = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.USERS_GETONE,
      {},
      { id: id }
    )
    return data
  }

  const getBio = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.USERS_GET_BIO,
      {},
      { id: id }
    )
    return data
  }

  const getEdu = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.USERS_GET_EDU,
      {},
      { id: id }
    )
    return data
  }
  const getProjects = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.USERS_GET_CV_PROJECTS,
      {},
      { id: id }
    )
    return data
  }
  const getProfile = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_PROFILE_GET,
      {},
      { id: id }
    )
    return data
  }
  const { data: user = fallbackUser, refetch: refetchUser } = useQuery(
    queryKeys.userById,
    fetchUser
  )
  const { data: userBio = fallbackBio, refetch: refetchBio } = useQuery(
    queryKeys.userByIdBio,
    getBio
  )
  const { data: userEdu = fallbackEdu, refetch: refetchEdu } = useQuery(
    queryKeys.userByIdEdu,
    getEdu
  )
  const { data: userProjects = fallbackProjects, refetch: refetchProjects } =
    useQuery(queryKeys.userByIdProjects, getProjects)
  const { data: userProfile = fallbackProfile, refetch: refetchProfile } =
    useQuery(queryKeys.userByIdProfile, getProfile)

  useEffect(() => {
    refetchUser()
    refetchBio()
    refetchEdu()
    refetchProjects()
    refetchProfile()
  }, [id])
  return { user, userBio, userEdu, userProjects, userProfile, getProfile }
}
