import { IPaginateReducer, PageActions } from './types'
import { ChangeItemsInPage } from './enum'

export const paginationUCReducer = (
  state: IPaginateReducer,
  action: PageActions
) => {
  switch (action.type) {
    case ChangeItemsInPage.CHANGE_PAGE:
      return { ...state, currentPage: action.payload.currentPage }
    case ChangeItemsInPage.SEARCH:
      return { ...state, search: action.payload }
    case ChangeItemsInPage.ORDER:
      return { ...state, order: action.payload }
    case ChangeItemsInPage.FILTERS:
      return { ...state, filters: action.payload }
    case ChangeItemsInPage.MULTI_EDIT_STATE:
      return { ...state, ...action.payload }
    default:
      throw new Error()
  }
}
