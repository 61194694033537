import { useQuery } from 'react-query'
import { queryKeys } from 'react-query/constants'
import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { useEffect } from 'react'

interface IApproversList {
  businessUnitId: number[]
  canManageRequests: boolean
  firstName: string
  isDirector: boolean
  lastName: string
  userId: number
}
export const useApprovers = (userId?: number) => {
  const fallback = [] as IApproversList[]

  async function getVacationApprovers(): Promise<IApproversList[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.VACATION_REQUEST_GET_APPROVERS,
      {},
      {}
    )
    return data
  }

  const { data: vacationApproversList = fallback, refetch } = useQuery(
    [queryKeys.vacationApproversList],
    getVacationApprovers
  )

  useEffect(() => {
    refetch()
  }, [userId])
  return vacationApproversList
}
