import { Box, Button, styled, Typography } from '@mui/material'

export const ExcludeButton = styled(Button)({
  width: '100%',
  marginBottom: 10,
})

export const ListWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '100%',
  boxShadow: `inset 0 0 10px ${theme.palette.secondary.dark}`,
  padding: '5px',
  borderRadius: '5px',
  boxSizing: 'border-box',
}))

export const ListItem = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  margin: '0',
  fontSize: '12px',
})
