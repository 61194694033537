import { useTranslation } from 'react-i18next'
import { Box, Container, Fade, IconButton, Tooltip } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import InfoIcon from '@mui/icons-material/Info'
import { HeaderTab } from 'views/LayoutUI/components'
import { EmptyArrayCard, TableComponent } from 'components/common'
import { GridRowsProp } from '@mui/x-data-grid'
import { IButtonArr, IProjects } from 'hooks/types'
import { useNavigate } from 'react-router-dom'
import { timeoutAnimation } from 'utils'
import { PageRoutes } from 'routes'

import { usePMProjects } from './utils'

interface IProject {
  customerName: string
  projectName: string
}

const PmDashboard: React.FC = (): JSX.Element => {
  const { t } = useTranslation('pmDashboard')
  const columns = [`${t('table.cell1')}`, `${t('table.cell2')}`, '']
  const projects = usePMProjects()
  const navigate = useNavigate()

  const buttonsArr: IButtonArr[] = [
    {
      title: `${t('table.tooltip1')}`,
      icon: <DashboardIcon />,
      path: PageRoutes.PROJECT_USERS,
    },
    {
      title: `${t('table.tooltip2')}`,
      icon: <InfoIcon />,
      path: PageRoutes.PROJECT_DETAILS,
    },
  ]

  const rows: GridRowsProp = projects?.map((project: IProjects) => {
    return {
      projectName: project.name,
      customerName: project.customer.name,
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map((button) => (
            <Tooltip title={button.title as string} key={button.title}>
              <IconButton
                onClick={() =>
                  button.path &&
                  navigate(button.path.replace(':id', `${project.id}`))
                }
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  }) as IProject[]

  return (
    <Container>
      <HeaderTab translate="pmDashboard" />
      {rows.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent columns={columns} rows={rows} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
    </Container>
  )
}
export default PmDashboard
