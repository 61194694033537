import { useContext } from 'react'
import { Box } from '@mui/material'
import { PkupContext } from 'pages/Pkup/context/PkupContext'
import { useSupervisor, useUpdateApplication } from 'hooks/pkup'
import { ApplicationForm } from 'components/Pkup'

const ShowMoreApplication: React.FC = (): JSX.Element => {
  const { selectedApplication } = useContext(PkupContext)
  const { getSupervisor } = useSupervisor()
  const { editPkup } = useUpdateApplication(selectedApplication.id)
  const initialValuesEdit = {
    title: selectedApplication.title,
    description: selectedApplication.description,
    supervisorId: {
      id: selectedApplication.supervisorId,
      name: getSupervisor(selectedApplication.supervisorId).name,
    },
    works: selectedApplication.works,
  }
  return (
    <Box sx={{ overflow: 'auto', maxHeight: '85vh' }}>
      <ApplicationForm
        initialValues={initialValuesEdit}
        onSubmit={editPkup}
        buttonName="edit"
        disabled={
          selectedApplication.state === 4 || selectedApplication.state === 5
            ? false
            : true
        }
      />
    </Box>
  )
}
export default ShowMoreApplication
