import { Autocomplete, Paper, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IProfile } from 'pages/Users/pages/utlis'

interface ProfileProps {
  userProfile: IProfile[]
  setFieldValue: (name: string, value: IProfile | null) => void
  profileError?: string
  profileTouched?: boolean
}

const ProfileOption: React.FC<ProfileProps> = ({
  userProfile,
  setFieldValue,
  profileError,
  profileTouched,
}): JSX.Element => {
  const { t } = useTranslation('userCV')

  return (
    <Paper
      sx={{
        padding: '1rem',
        marginTop: '1rem',
      }}
    >
      <Paper
        sx={{
          padding: '0.25rem 0.5rem',
          margin: '0.25rem 0 1.5rem 0',
          backgroundColor: 'secondary.main',
        }}
      >
        <Typography variant="h3" color="primary.main">
          {t('generateProfile.selectProfile')}
        </Typography>
      </Paper>
      <Autocomplete
        id="user-autocomplete"
        options={userProfile}
        getOptionLabel={(option: IProfile) => option?.type}
        onChange={(e, value) => {
          setFieldValue('selectedProfile', value)
        }}
        includeInputInList
        isOptionEqualToValue={(option, { type }) =>
          type === '' || type === undefined || option?.type === type
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={t('generateProfile.profile')}
            name="selectedProfile"
            variant="outlined"
          />
        )}
      />
      {profileError && profileTouched && (
        <Typography color="error" variant="h6">
          {profileError}
        </Typography>
      )}
    </Paper>
  )
}

export default ProfileOption
