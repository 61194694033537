import { Formik, Form, Field, FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { format } from 'date-fns'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Stack,
} from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'
import { TextField } from 'formik-mui'
import { useParams } from 'react-router-dom'
import {
  useAddCustomerProject,
  useUpdateCustomerProject,
} from 'hooks/customers'
import { CustomizedDataPicker } from 'components/common'
import { IProject } from 'pages/Customers/types'

interface IProps {
  project?: IProject
  type?: string
  projectId?: number
}

const CustomerProjectForm: React.FC<IProps> = ({
  project,
  type,
  projectId,
}): JSX.Element => {
  const { t } = useTranslation(['customerEdit', 'buttons', 'validation'])
  const { addNewCustomerProject } = useAddCustomerProject()
  const { id } = useParams()
  const { updateCustomerProject } = useUpdateCustomerProject()

  const initialValues = {
    projectName: project?.name || '',
    projectCode: project?.code || '',
    startDate: project?.startDate || new Date(),
    endDate: project?.endDate || new Date(),
    isBillable: project?.isBillable || false,
    isProductive: project?.isProductive || false,
  }

  const validationSchema = yup.object({
    projectName: yup
      .string()
      .required(`${t('projectName.required', { ns: 'validation' })}`)
      .min(3),
    projectCode: yup
      .string()
      .required(`${t('projectCode.required', { ns: 'validation' })}`)
      .min(3),
    startDate: yup.date().required('startDate'),
    endDate: yup
      .date()
      .min(yup.ref('startDate'), t('endDate.minValue', { ns: 'validation' })),
  })

  const customerFields: IFields[] = [
    {
      id: '1',
      label: `${t('form.label3')}`,
      name: 'projectCode',
      type: 'text',
      inputProps: { style: { textTransform: 'uppercase' } },
    },
    {
      id: '2',
      label: `${t('form.label4')}`,
      name: 'projectName',
      type: 'text',
      inputProps: { style: { textTransform: 'capitalize' } },
    },
  ]

  const submitHandler = (
    {
      projectCode,
      endDate,
      isBillable,
      isProductive,
      projectName,
      startDate,
    }: FormikValues,
    setSubmitting: (value: boolean) => void
  ) => {
    const customerProjectData = {
      code: projectCode.toUpperCase(),
      isBillable: isBillable,
      isProductive: isProductive,
      name: projectName,
    }
    if (type === 'edit') {
      updateCustomerProject({
        ...customerProjectData,
        id: projectId,
        startDate: format(new Date(startDate), 'dd/MM/yyyy'),
        endDate: format(new Date(endDate), 'dd/MM/yyyy'),
      })

      setSubmitting(false)
    } else {
      addNewCustomerProject({
        ...customerProjectData,
        endDate: endDate,
        startDate: startDate,
        customerId: Number(id),
      })
      setSubmitting(false)
    }
  }

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          submitHandler(values, setSubmitting)
        }}
      >
        {({ submitForm, isSubmitting, errors }) => (
          <Form>
            <>
              <Box mb={2}>
                {customerFields.map(({ id, name, type, label, inputProps }) => (
                  <Field
                    fullWidth
                    key={id}
                    component={TextField}
                    name={name}
                    type={type}
                    label={label}
                    variant="outlined"
                    margin="normal"
                    size="small"
                    inputProps={inputProps}
                  />
                ))}
              </Box>
            </>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                columnGap: '20px',
              }}
            >
              <Stack spacing={2} direction="row">
                <Field
                  label={t('form.label5')}
                  name="startDate"
                  component={CustomizedDataPicker}
                  inputFormat="dd/MM/yyyy"
                />
                <Field
                  label={t('form.label6')}
                  name="endDate"
                  component={CustomizedDataPicker}
                  inputFormat="dd/MM/yyyy"
                />
                {errors?.endDate && (
                  <Typography variant="h3" color="error">
                    {errors.endDate}
                  </Typography>
                )}
              </Stack>
              <Stack spacing={2} direction="row">
                <Field
                  as={FormControlLabel}
                  type="checkbox"
                  name="isBillable"
                  control={<Checkbox />}
                  label={t('form.label7')}
                />
                <Field
                  as={FormControlLabel}
                  type="checkbox"
                  name="isProductive"
                  control={<Checkbox />}
                  label={t('form.label8')}
                />
              </Stack>
            </Box>
            <Button disabled={isSubmitting} onClick={submitForm}>
              {t('save', { ns: 'buttons' })}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default CustomerProjectForm
