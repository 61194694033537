import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Tab, Container, Alert } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useAuth } from 'hooks/useAuth'
import { HeaderTab } from 'views/LayoutUI/components'
import { ModalComponent, useNotifications } from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import { ISelectingModal } from 'hooks/types'
import { useWorkTypes } from 'utils'

import {
  createBusinessCards,
  IBusinessCardsArgs,
} from './components/Cards/businessCardsDefinitions'
import {
  createCardDefinitions,
  ICardDefinitionsArgs,
} from './components/Cards/cardsDefinitions'
import {
  calculateUsedHolidaysPercentage,
  formatAlertMessages,
  usedDashboard,
} from './utils'
import {
  Background,
  Cards,
  ProjectsWithoutAllocations,
  YourProjects,
} from './components'
import { Details, UsersThatMayHelpYou } from './modals'

const Dashboard: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['dashboard', 'buttons'])
  const [value, setValue] = useState('1')
  const [businessUnitsId, setBusinessUnitsId] = useState<number | null>(null)
  const [primaryLocationName, setPrimaryLocationName] = useState<string>('')
  const [hasLocations, setHasLocations] = useState<boolean>(false)
  const userHolidays = usedDashboard()
  const [alertVisible, setAlertVisibility] = useState(false)
  const navigate = useNavigate()
  const { user } = useAuth()
  const countPercentage =
    userHolidays && userHolidays.maxHolidays === 0
      ? 0
      : (userHolidays.used / userHolidays.maxHolidays) * 100
  const { modalName, handleOpenModal } = useNotifications()
  const { currentAgreements } = useWorkTypes()

  useEffect(() => {
    if (user) {
      if (user.locations.length > 0) {
        const fullLocationName = user.locations[0].name

        const locationParts = fullLocationName.split(' - ')
        const locationName = locationParts[locationParts.length - 1].trim()
        setPrimaryLocationName(locationName)
        setHasLocations(true)
      }

      if (!user.UserAgreements?.length || !user.businessUnits.length) {
        setAlertVisibility(true)
      }
    }
  }, [user])

  const selectingModal: ISelectingModal = useMemo(() => {
    switch (modalName) {
      case 'details':
        return {
          component: <Details id={businessUnitsId} />,
          title: `${t('cardsPanel.card4.title')}`,
        }
      case 'usersThatMayHelpYou':
        return {
          component: <UsersThatMayHelpYou />,
          title: `${t('modal.usersMayHelpYou.title')}`,
        }
      default:
        return { component: <DefaultModal />, title: 'Default modal' }
    }
  }, [modalName, businessUnitsId, t])

  const cardsProps: ICardDefinitionsArgs = {
    t,
    userHolidays,
    currentAgreements,
    handleOpenModal,
    hasLocations,
    primaryLocationName,
    countPercentage,
    usedHolidaysDays: calculateUsedHolidaysPercentage(userHolidays),
    user,
  }

  const businessCardsProps: IBusinessCardsArgs = {
    user,
    t,
    handleOpenModal,
    setBusinessUnitsId,
    navigate,
  }

  const cardDefinitions = useMemo(
    () => createCardDefinitions(cardsProps),
    [t, user, userHolidays, countPercentage, currentAgreements, handleOpenModal]
  )

  const businessCards = useMemo(
    () => createBusinessCards(businessCardsProps),
    [user, t, navigate, handleOpenModal]
  )

  const cardsArea = useMemo(
    () => (
      <Cards listOfCards={cardDefinitions} businessUnitsCards={businessCards} />
    ),
    [cardDefinitions, businessCards]
  )

  return (
    <>
      <Background />
      <Container>
        <HeaderTab translate="dashboard" />
        {alertVisible && (
          <Alert
            variant="filled"
            severity="warning"
            onClose={() => setAlertVisibility(false)}
            sx={{ margin: '24px' }}
          >
            {`${formatAlertMessages([
              !user?.locations.length && t('alert.location'),
              !user?.UserAgreements?.length && t('alert.agreement'),
              !user?.businessUnits.length && t('alert.bu'),
            ])} ${t('alert.notFound')}`}
          </Alert>
        )}
        {cardsArea}
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(e, newValue) => setValue(newValue)}
              aria-label="lab API tabs example"
            >
              <Tab label={t('tabPanels.panel1.title')} value="1" />
              <Tab label={t('tabPanels.panel2.title')} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <YourProjects value="1" />
          </TabPanel>
          <TabPanel value="2">
            <ProjectsWithoutAllocations value="2" />
          </TabPanel>
        </TabContext>
        <ModalComponent
          component={selectingModal.component}
          title={selectingModal.title}
        />
      </Container>
    </>
  )
}

export default Dashboard
