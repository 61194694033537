import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useGetAgreements } from 'hooks/agreements'
import { useAllUsers } from 'hooks/useAllUsers'
import { useGetAllProjects } from 'hooks/projects'
import { useGetAllCustomers } from 'hooks/customers'
import { ISelectedOptions } from 'components/common/AutoCompleteCheckAll'
interface IInitialValues {
  agreements: ISelectedOptions[]
  users: ISelectedOptions[]
  projects: ISelectedOptions[]
  month: Date
  onlyActive: boolean
  selectedMonthAgreements: boolean
  customers: number
  planned: boolean
  isOverhours: boolean
}
export const useForm = () => {
  const { t } = useTranslation(['reportDashboard', 'buttons', 'validation'])
  const agreementsList = useGetAgreements()
  const allUsers = useAllUsers()
  const projectList = useGetAllProjects()
  const customersList = useGetAllCustomers()

  const validationSchema = {
    agreements: yup
      .array()
      .min(1, t('agreements.minLength', { ns: 'validation' })),
  }
  const validationSchemaUsers = yup.object({
    ...validationSchema,
    users: yup.array().min(1, t('user.minLength', { ns: 'validation' })),
  })
  const validationSchemaProjects = yup.object({
    ...validationSchema,
    projects: yup.array().min(1, t('projects.required', { ns: 'validation' })),
  })
  const validationSchemaCustomers = yup.object({
    ...validationSchema,
    customers: yup
      .object()
      .required(`${t('customer.required', { ns: 'validation' })}`)
      .nullable(),
  })
  const users = allUsers?.map((user) => {
    return {
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
    }
  })
  const agreements = agreementsList?.map((agreement) => {
    return {
      id: agreement.id,
      name: `${agreement.type}`,
    }
  })
  const projects = projectList?.map((project) => {
    return {
      id: project.id,
      name: `${project.name} (${project.customer.name}) `,
    }
  })
  const customers = customersList?.map((customer) => {
    return {
      id: customer.id,
      name: `${customer.name}`,
      projects: customer.projects,
    }
  })
  const initialValues: IInitialValues = {
    agreements: [],
    users: [],
    projects: [],
    month: new Date(),
    onlyActive: false,
    selectedMonthAgreements: false,
    customers: 0,
    planned: false,
    isOverhours: false,
  }
  const checkboxArr = [
    { name: 'onlyActive', label: `${t('common.label4')}` },
    { name: 'selectedMonthAgreements', label: `${t('common.label5')}` },
    {
      name: 'planned',
      label: t('tab3.label6'),
    },
    {
      name: 'isOverhours',
      label: t('tab3.label7'),
    },
  ]

  const autocompleteArr = [
    {
      item: users,
      name: 'users',
      label: t('tab1.label1'),
    },
    {
      item: projects,
      name: 'projects',
      label: t('tab2.label1'),
    },
    {
      item: agreements,
      name: 'agreements',
      label: t('common.label2'),
    },
  ]

  return {
    autocompleteArr,
    checkboxArr,
    customers,
    initialValues,
    validationSchema,
    validationSchemaCustomers,
    validationSchemaProjects,
    validationSchemaUsers,
  }
}
