import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import * as yup from 'yup'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IFields } from 'views/LayoutUI/components/types'
import { IUserProps } from 'components/users'

import { usePersonalInformation } from '../utils'

const UpdateEmail: React.FC<IUserProps> = ({ user }): JSX.Element => {
  const { t } = useTranslation(['profile', 'validation', 'buttons'])
  const patchUser = usePersonalInformation()

  const validationSchema = yup.object({
    email: yup.string().required(t('email.required', { ns: 'validation' })),
    currentPassword: yup
      .string()
      .required(t('currentPassword.required', { ns: 'validation' })),
  })
  const initialValues = {
    email: user?.email || '',
    currentPassword: '',
  }

  const emailFields: IFields[] = [
    {
      id: '31',
      label: t('userInformation.form.label9'),
      name: 'email',
      type: 'text',
    },
    {
      id: '32',
      label: t('userInformation.form.label6'),
      name: 'currentPassword',
      type: 'password',
    },
  ]
  return (
    <Box mt={3}>
      <Typography variant="h3"> {t('userInformation.updateEmail')}</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          patchUser(values)
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box mb={2}>
              {emailFields.map((field) => (
                <Field
                  fullWidth
                  key={`${field.id}-updateEmail`}
                  component={TextField}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  variant="outlined"
                  margin="normal"
                  size="small"
                />
              ))}
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {t('save', { ns: 'buttons' })}
            </Button>
            <br />
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default UpdateEmail
