import { useTranslation } from 'react-i18next'
import TitleIcon from '@mui/icons-material/Title'
import DescriptionIcon from '@mui/icons-material/Description'
import { useUser } from 'pages/Users/pages/utlis'

import CardRadioOption from '../CardRadioOption'

interface IBioOptionProps {
  bioValue: string
  setFieldValue: (name: string, value: string) => void
  bioError?: string
  bioTouch?: boolean
}
const BioOption: React.FC<IBioOptionProps> = ({
  bioValue,
  setFieldValue,
  bioError,
  bioTouch,
}): JSX.Element => {
  const { t } = useTranslation('userCV')

  const { userBio } = useUser()

  const BioFields = (field) => [
    {
      name: field.title,
      title: `${t('cv.bioTable.title')}`,
      icon: <TitleIcon />,
    },
    {
      name: field?.description,
      title: `${t('cv.bioTable.description')}`,
      icon: <DescriptionIcon />,
    },
  ]

  return (
    <CardRadioOption
      name="selectedBio"
      option={userBio}
      optionDetails={BioFields}
      title={t('generateProfile.selectBio')}
      setFieldValue={setFieldValue}
      value={bioValue}
      error={bioError}
      touch={bioTouch}
    />
  )
}

export default BioOption
