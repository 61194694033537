import { EventContentArg } from '@fullcalendar/react'
import { pickColorBasedByBackground } from 'utils/changeColors'
import { RenderEventContent } from 'components/common'

const EventContentProjectCalendar = (eventInfo: EventContentArg) => {
  const color = pickColorBasedByBackground(
    eventInfo.event._def.ui.backgroundColor,
    'white',
    'black'
  )

  return (
    <RenderEventContent eventInfo={eventInfo} color={color} isProject={true} />
  )
}

export default EventContentProjectCalendar
