import * as yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import { AntSwitch, EmptyArrayCard, TableComponent } from 'components/common'

import { useManageInventoriesList } from './utils'
import { Wrapper } from './index.style'

const ManageInventoryList: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['inventory', 'buttons', 'validation'])
  const {
    allInventories,
    allowUserToModifyInventory,
    createNewInventory,
    deleteInventoryItem,
  } = useManageInventoriesList()
  const columns = ['#', `${t('modal.manageInventoryList.table.cell1')}`, '']
  const displayInventories = allInventories?.map((inventory) => {
    return {
      id: inventory.id,
      name: inventory.name,
      button: (
        <Box display="flex" key={inventory.id}>
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              onClick={() => allowUserToModifyInventory(inventory)}
              checked={inventory.allowUserToModify}
              inputProps={{ 'aria-label': 'controlled' }}
              id={inventory.id.toString()}
            />
            <Typography>
              {t('modal.manageInventoryList.table.cell2')}
            </Typography>
          </Stack>
          <Tooltip title={t('delete', { ns: 'buttons' }) as string}>
            <IconButton onClick={() => deleteInventoryItem(inventory.id)}>
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    }
  })

  const rows: GridRowsProp = displayInventories
  const validationSchema = yup.object({
    name: yup
      .string()
      .required(`${t('inventoryName.required', { ns: 'validation' })}`),
  })

  return (
    <>
      <Wrapper>
        {rows.length ? (
          <TableComponent columns={columns} rows={rows} />
        ) : (
          <EmptyArrayCard message={t('table.message')} />
        )}
      </Wrapper>
      <Formik
        initialValues={{ name: '', allowUserToModify: false }}
        onSubmit={(values) => {
          createNewInventory(values)
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Grid
            container
            marginTop={1}
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={5}
              md={4}
              justifyContent="space-between"
              alignItems="center"
            >
              <Field
                fullWidth
                name="name"
                component={TextField}
                key="name"
                label={t('modal.manageInventoryList.label1')}
                disabled={false}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Field
                key="checkbox"
                as={FormControlLabel}
                type="checkbox"
                name="allowUserToModify"
                control={<Checkbox size="small" />}
                label={t('modal.manageInventoryList.checkbox')}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  )
}
export default ManageInventoryList
