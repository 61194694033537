import { Dispatch, SetStateAction, useState } from 'react'
import { Box, Tabs, Tab, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { permissionList, isUserPermitted } from 'services'
import { IUserProps } from 'components/users'
import { IUserHolidays } from 'hooks/types'
import { useAuth } from 'hooks/useAuth'
import { EmptyArrayCard, HistoryTable } from 'components/common'
import { useGetHistory } from 'hooks/user'
import { useTablePagedFunction } from 'hooks/table'

import { Agreements, Groups, HolidaysDetails, Projects } from './components'
import { IAgreementProps } from './types'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface IDetailsProps extends IAgreementProps {
  setUserHoliday: Dispatch<SetStateAction<IUserHolidays | null>>
}
const Details: React.FC<IUserProps & IDetailsProps> = ({
  user,
  setUserHoliday,
  setUserAgreement,
}): JSX.Element => {
  const [value, setValue] = useState(0)
  const { t } = useTranslation('userEditPage')
  const { user: me } = useAuth()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const { userHistoryRows, changePagination, total } = useGetHistory()
  const { paginationUC, handleChangePage, handleChangeRowsPerPage } =
    useTablePagedFunction(changePagination)

  const hasHolidayPermission = isUserPermitted(me, [
    permissionList.HOLIDAYS_CATEGORIES_LIST,
  ])

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    }
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="details about user"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label={t('details.tab1')} {...a11yProps(0)} />
            <Tab label={t('details.tab2')} {...a11yProps(1)} />
            <Tab label={t('details.tab3')} {...a11yProps(2)} />
            <Tab label={t('details.tab4')} {...a11yProps(3)} />
            <Tab label={t('details.tab5')} {...a11yProps(4)} />
          </Tabs>
        </Grid>
        <Grid item xs={10} lg={9}>
          <TabPanel value={value} index={0}>
            <Groups user={user} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Projects user={user} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            {hasHolidayPermission ? (
              <HolidaysDetails user={user} setUserHoliday={setUserHoliday} />
            ) : (
              <EmptyArrayCard message={t('details.noHolidayPermission')} />
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Agreements user={user} setUserAgreement={setUserAgreement} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            {userHistoryRows?.length ? (
              <HistoryTable
                historyRows={userHistoryRows}
                total={total}
                handleChangePage={handleChangePage}
                paginationUC={paginationUC}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : (
              <EmptyArrayCard message={t('details.tab5message')} />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </>
  )
}
export default Details
