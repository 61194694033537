import { SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { IAllocations, IProjects } from 'hooks/types'
import { Button } from '@mui/material'

import AllocationInfoForUser from '../Allocations'
import { useYourProjects } from '../utils'

interface IProject {
  projectName: string
  customer: string
  yourAllocations: JSX.Element
  buttons: JSX.Element | null
}

export const selectProjects = (val: string): IProject[] => {
  const [buttonId, setButtonId] = useState<string>('')
  const { t } = useTranslation(['dashboard'])
  const { user } = useAuth()
  const { activeAlloc, inactiveAlloc } = useYourProjects()

  const selectProjectsByTab = () => {
    switch (val) {
      case '1':
        return activeAlloc
      case '2':
        return inactiveAlloc
      default:
        return []
    }
  }
  const project = selectProjectsByTab().map((project: IProjects) => {
    const allocations = user?.allocations?.filter(
      (alloc: IAllocations) => alloc.ProjectUser.projectId === project.id
    )
    const idToString = project?.id.toString()
    const getButtonId = (e: SyntheticEvent) => {
      setButtonId(e?.currentTarget?.id)
    }
    return {
      projectName: project.name,
      customer: project.customer.name,
      yourAllocations: (
        <>
          <AllocationInfoForUser
            key={project.name}
            allocations={allocations}
            buttonId={buttonId}
            setButtonId={setButtonId}
          />
        </>
      ),

      buttons: allocations?.length ? (
        <>
          <Button
            variant="text"
            onClick={(e) => getButtonId(e)}
            id={idToString}
          >
            {t('tabPanels.panel1.button')}
          </Button>
        </>
      ) : null,
    }
  })

  return project
}
