import { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Autocomplete,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AgreementDates } from 'components/users'
import { useGetAgreements } from 'hooks/agreements'
import { useValidationForms } from 'hooks/user'
import { IAgreements } from 'hooks/types'

import { useUserAgreements } from './utils'

const AddAgreement = () => {
  const { t } = useTranslation(['userEditPage', 'buttons'])
  const [agreementType, setAgreementType] = useState<IAgreements | null>(null)
  const agreementsList = useGetAgreements()
  const { addUserAgreement, hasAgreementPermission } = useUserAgreements()
  const { validationAddUserAgreement } = useValidationForms()
  if (!hasAgreementPermission) {
    return null
  }

  return (
    <Formik
      initialValues={{
        agreementType: '',
        startDate: new Date(),
        endDate: new Date(),
        indefinite: false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        addUserAgreement({
          agreementId: agreementType?.id || 0,
          startDate: values.startDate,
          endDate: !values.indefinite ? values.endDate : null,
        })
        setSubmitting(false)
      }}
      validationSchema={validationAddUserAgreement}
    >
      {({ errors, setFieldValue, touched, values, isSubmitting }) => (
        <Form>
          <Field
            type="checkbox"
            name="indefinite"
            as={FormControlLabel}
            control={<Checkbox checked={values.indefinite} />}
            label={t('agreements.form.label1')}
          />
          <Box mb={2} maxWidth={450}>
            <Autocomplete
              fullWidth
              size="small"
              id="holidayType"
              options={agreementsList}
              isOptionEqualToValue={(option, { type }) => option.type === type}
              getOptionLabel={(option) => {
                return option.type
              }}
              onChange={(e, value) => {
                {
                  setFieldValue('agreementType', value?.type)
                  setAgreementType(value)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.agreementType ? true : false}
                  margin="normal"
                  label={t('agreements.form.label2')}
                  name="agreementType"
                  variant="outlined"
                />
              )}
            />
            {errors.agreementType && touched.agreementType && (
              <Typography color="error" variant="h6">
                {errors.agreementType}
              </Typography>
            )}
          </Box>
          <AgreementDates
            indefinite={values.indefinite}
            error={errors?.endDate as string}
          />
          <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default AddAgreement
