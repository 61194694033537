import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useParams } from 'react-router-dom'

import { useGetWorkdays } from './useGetWorkdays'

async function deleteWorkDays(body: number[]) {
  await apiRequest(APIRoutes.ALL_WORKDAYS_DELETE, body)
}
async function deleteSingleWorkDay(id: number) {
  await apiRequest(APIRoutes.WORKDAYS_DELETE, {}, { id })
}
export const useDeleteWorkdays = () => {
  const {
    setNotification,
    handleCloseModal,
    currentCalendarDate,
    setSelectedUserWorkDays,
  } = useNotifications()
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { getWorkdaysForUser } = useGetWorkdays()

  const { mutate: deleteWorkdays } = useMutation(deleteWorkDays, {
    onSuccess: async () => {
      handleCloseModal()
      setNotification({
        message: `${t('workdays')} ${t('delete2')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.calendar)
      queryClient.invalidateQueries(queryKeys.user)
      queryClient.refetchQueries([queryKeys.workdaysToReview])
      if (id) {
        const fetchWorkDays2 = async () => {
          const newWorkdays = await getWorkdaysForUser(currentCalendarDate, id)
          setSelectedUserWorkDays(newWorkdays)
        }
        fetchWorkDays2()
      }
    },
  })

  const { mutate: deleteSingleWorkday } = useMutation(deleteSingleWorkDay, {
    onSuccess: async () => {
      handleCloseModal()
      setNotification({
        message: `${t('workday')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.calendar)
      queryClient.invalidateQueries(queryKeys.user)
      queryClient.refetchQueries([queryKeys.workdaysToReview])
      if (id) {
        const fetchWorkDays2 = async () => {
          const newWorkdays = await getWorkdaysForUser(currentCalendarDate, id)
          setSelectedUserWorkDays(newWorkdays)
        }
        fetchWorkDays2()
      }
    },
  })
  return { deleteWorkdays, deleteSingleWorkday }
}
