import { useAuth } from 'hooks/useAuth'
import { isUserPermitted } from 'services'

interface IPermission {
  index: number
  name: string
}

export const WithPagePermissions = ({
  Component,
  permission,
}: {
  Component: JSX.Element
  permission: IPermission[]
}) => {
  const { user } = useAuth()
  return isUserPermitted(user, permission) ? <>{Component}</> : null
}
