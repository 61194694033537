import { useEffect, useState } from 'react'
import { format } from 'date-fns/esm'
import { FormikValues } from 'formik'
import { SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { IAllocations, IProjects, IUserHolidays } from 'hooks/types'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useAddWorkday } from 'hooks/calendar/useAddWorkday'
import {
  getStoredProjectId,
  setStoredProjectId,
  getStoredWorkTypeId,
  setStoredWorkTypeId,
} from 'workday-storage'

import { NewWorkday, Workday, Worktype } from '../../context/types'
import { useCalendarContext } from '../../context'

import { InitialValues } from './types'

export function useWorkdaysModal() {
  const {
    excludeWeekends,
    excludeHolidays,
    daysRange,
    selectedWorkDays,
    projects: filteredByAllocProjects,
    setExcludeWeekends,
    setIsOverhours,
    projectWithOverHours,
    setProjectWithOverHours,
    setAlloc,
    isPlanned,
    setIsAssigned,
    isAssigned,
    types,
    userHolidays,
    isOverhours,
    holidayForMonth,
    start,
    setStart,
    end,
    setEnd,
  } = useCalendarContext()
  const { addNewWorkday } = useAddWorkday()
  const { t } = useTranslation('calendar')

  const { currentCalendarDate, setNotification } = useNotifications()

  const initProjectId = () => {
    if (filteredByAllocProjects.length === 0) {
      localStorage.removeItem('projectId')
    } else {
      setStoredProjectId(filteredByAllocProjects[0].id)
      if (localStorage.getItem('projectId') === null) {
        setStoredProjectId(filteredByAllocProjects[0].id)
      } else {
        return getStoredProjectId()
      }
    }
    return '1'
  }

  const initWorktypeId = () => {
    if (types.length === 0) {
      localStorage.removeItem('workTypeId')
    } else {
      setStoredWorkTypeId(types[0].id)
      if (localStorage.getItem('workTypeId') === null) {
        setStoredWorkTypeId(types[0].id)
      } else {
        return getStoredWorkTypeId()
      }
    }
    return '1'
  }

  const projectId = filteredByAllocProjects.find(
    (project) => project.id.toString() === getStoredProjectId()
  )
    ? getStoredProjectId()
    : initProjectId()

  const workTypeId = types.find(
    (type) => type.id.toString() === getStoredWorkTypeId()
  )
    ? getStoredWorkTypeId()
    : initWorktypeId()

  const { user } = useAuth()

  const [dayType, setDayType] = useState<string>(workTypeId)
  const [selectedProject, setSelectedProject] = useState<string>(projectId)
  const [holidayType, setHolidayType] = useState<string>('1')
  const [workTypes, setWorkTypes] = useState<string[]>([])
  const findWorkdaysFTE = () => {
    const allocation: IAllocations[] | undefined = user?.allocations?.filter(
      (alloc: IAllocations) => {
        if (
          alloc.date.replace('/', '-') === currentCalendarDate &&
          alloc.ProjectUser.projectId === parseInt(selectedProject)
        )
          return alloc
      }
    )

    const allocationsWithoutOverhours = allocation?.find(
      (allocation) => !allocation.isOverhours
    )
    const allocationsWithOverhours = allocation?.find(
      (allocation) => allocation.isOverhours
    )

    if (allocationsWithoutOverhours) {
      return allocationsWithoutOverhours
    } else {
      return allocationsWithOverhours
    }
  }

  useEffect(() => {
    const projectId = filteredByAllocProjects.find(
      (project) => project.id.toString() === getStoredProjectId()
    )
      ? getStoredProjectId()
      : initProjectId()

    setSelectedProject(projectId)
    const workTypeId = types.find(
      (type) => type.id.toString() === getStoredWorkTypeId()
    )
      ? getStoredWorkTypeId()
      : initWorktypeId()

    setDayType(workTypeId)
  }, [filteredByAllocProjects])

  useEffect(() => {
    if (user) {
      const workTypeNames = user?.system?.workTypes?.map(
        (workType: Worktype) => workType?.name
      )
      if (workTypeNames) {
        setWorkTypes(workTypeNames)
      }
    }
    findWorkdaysFTE()
  }, [user])

  useEffect(() => {
    setIsOverhours(false)
    const projectFromAlloc = filteredByAllocProjects.find(
      (p) => p.id === parseInt(selectedProject)
    )
    if (user && filteredByAllocProjects.length) {
      const findProject: IProjects | undefined = user?.projects?.find(
        (project: IProjects) => {
          return project?.name === projectFromAlloc?.name
        }
      )

      const tempProjectWithOverHours: IAllocations[] = user?.allocations.filter(
        (alloc: IAllocations) =>
          alloc.isOverhours === true &&
          alloc.date.replace('/', '-') === currentCalendarDate &&
          alloc.ProjectUser.projectId === findProject?.id
      )

      setProjectWithOverHours(tempProjectWithOverHours)
    }

    setAlloc(findWorkdaysFTE())
  }, [selectedProject, dayType])

  const createEventId = () => {
    if (selectedWorkDays.length === 0) {
      return 1
    } else {
      return selectedWorkDays[selectedWorkDays.length - 1].id + 1
    }
  }

  const initialValues: InitialValues = {
    id: '',
    startTime: start,
    endTime: end,
    mood: null,
    extendedProps: {
      isPlanned: isPlanned,
      comment: '',
      contract: dayType,
      project: selectedProject,
      homeOffice: true,
      description: '',
    },
  }

  const handleChange = (event: SelectChangeEvent, type: string) => {
    switch (type) {
      case 'dayType':
        setIsAssigned(false)
        setStoredWorkTypeId(event.target.value)
        setDayType(event.target.value)
        break
      case 'select':
        setStoredProjectId(event.target.value)
        setSelectedProject(event.target.value)
        break

      case 'holidayType':
        setHolidayType(event.target.value)
        break
      default:
        setNotification({
          message: `${t('addWorkdaysModal.alert3')} ${type}.`,
          variant: NOTIFICATION.error,
        })
    }
  }

  const updateEventsInCalendar = async (data: Workday[]) => {
    let tempArr: NewWorkday[] = []

    const tempProject = filteredByAllocProjects.find(
      (project: IProjects) => project.name === data[0].extendedProps.project
    )

    const tempWorkTypes = types.find(
      (workType: Worktype) => workType?.name === data[0].extendedProps.contract
    )

    let userHolidayNames = userHolidays?.map(
      (holiday) =>
        `${holiday?.HolidaysCategory?.name} (${holiday.days} ${t(
          'availableDays'
        )}) - ${holiday.year}`
    )

    userHolidayNames = [...userHolidayNames, 'none']
    let findHoliday: IUserHolidays | undefined = undefined

    if (parseInt(holidayType)) {
      findHoliday = userHolidays.find((holiday) =>
        userHolidayNames[parseInt(holidayType) - 1].includes(
          holiday?.HolidaysCategory?.name
        )
      )
    }

    const singleWorkType = types.find(
      (worktype) =>
        worktype.id === parseInt(initialValues.extendedProps.contract)
    )

    if (!singleWorkType?.name.includes('Holiday')) {
      findHoliday = undefined
    }

    const holidaysInRange = holidayForMonth?.map(({ start, id, name }) => {
      return { id: id, name: name, date: start }
    })
    tempArr = [
      ...tempArr,
      {
        startDate: format(new Date(data[0].start), 'yyyy-MM-dd'),
        endDate: format(new Date(data[data.length - 1].end), 'yyyy-MM-dd'),
        isPlanned: data[0].extendedProps.isPlanned,
        comment: data[0].extendedProps.comment
          ? data[0].extendedProps.comment
          : '',
        holidaysCategoryId: findHoliday !== undefined ? findHoliday.id : null,
        holidaysinRange: excludeHolidays ? [] : holidaysInRange,
        projects: [
          {
            projectId: tempProject ? tempProject.id : null,
            workTypeId: tempWorkTypes ? tempWorkTypes.id : null,
            timeFrom: format(new Date(data[0].start), 'HH:mm'),
            timeTo: format(new Date(data[0].end), 'HH:mm'),
            isOverhours: isOverhours,
            progress: data?.[0].extendedProps.progress,
          },
        ],
        excludeWeekends: excludeWeekends,
        excludeHolidays: excludeHolidays,
      },
    ]

    addNewWorkday(tempArr[0])
    setHolidayType('0')
  }

  const onSubmit = async (values: FormikValues) => {
    const { title, comment, startTime, endTime, mood } = values
    const tempProjects: IProjects[] = [...filteredByAllocProjects]
    const newArr = () => {
      let arr: Workday[] = []
      for (let i = 0; i < daysRange.length; i++) {
        const { extendedProps } = initialValues
        const singleProject = tempProjects.find(
          (project) => project.id === parseInt(extendedProps.project)
        )
        const singleWorkType = types.find(
          (worktype) => worktype.id === parseInt(extendedProps.contract)
        )
        arr = [
          ...arr,
          {
            id: createEventId() + i,
            start: `${daysRange[i]} ${format(new Date(startTime), 'HH:mm')}`,
            end: `${daysRange[i]} ${format(new Date(endTime), 'HH:mm')}`,
            startTime: `${daysRange[i]} ${format(
              new Date(startTime),
              'HH:mm'
            )}`,
            endTime: `${daysRange[i]} ${format(new Date(endTime), 'HH:mm')}`,
            color: '#42a5f5',
            title: title,
            extendedProps: {
              isPlanned: extendedProps.isPlanned,
              comment: comment,
              contract: singleWorkType ? singleWorkType.name : '',
              project:
                (singleWorkType?.name.includes('Holiday') && !isAssigned) ||
                singleWorkType?.name === 'UoP (Umowa o Prace) - Sick Leave (L4)'
                  ? null
                  : singleProject
                  ? singleProject.name
                  : '1',
              homeOffice: true,
              needApproval: false,
              progress: mood,
            },
            description: 'Project Name',
          },
        ]
      }
      return arr
    }
    const arrDays = newArr()
    updateEventsInCalendar(arrDays)
    setExcludeWeekends(true)
    setStart(new Date(0, 0, 0, 8, 0))
    setEnd(new Date(0, 0, 0, 16, 0))
  }
  return {
    initialValues,
    onSubmit,
    handleChange,
    dayType,
    selectedProject,
    holidayType,
    workTypes,
    projectWithOverHours,
  }
}
