import { Field } from 'formik'
import { Box } from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { MenuItem, Select } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'

import { useGetAllHolidayUnits } from '../../useHolidayUnits'

interface IProps {
  creationMode?: boolean
}

const HolidaysCategoriesForm: React.FC<IProps> = ({
  creationMode,
}): JSX.Element => {
  const { t } = useTranslation('systemHolidaysCategories')
  const { holidayUnits } = useGetAllHolidayUnits()
  const [holidayUnitId, setHolidayUnitId] = React.useState<undefined | number>(
    undefined
  )

  const groupFields: IFields[] = [
    {
      id: '1',
      label: `${t('modal.add.label1')}`,
      name: 'name',
      type: 'text',
    },
  ]

  const selectComponent = (form) => {
    const { setFieldValue } = useFormikContext<any>()

    const updateHolidayId = (ev) => {
      const value = Number(ev.target.value || 0)
      setHolidayUnitId(value)
      setFieldValue('holidayUnitId', value)
    }

    return (
      <Select
        labelId="selectProjectLabel"
        id="selectProjectLabel"
        label={t('addWorkdaysModal.selectProject')}
        defaultValue={holidayUnits?.[0]?.id}
        name="holidayUnitId"
        value={holidayUnitId}
        onBlur={(ev) => {
          ev.target.name = 'holidayUnitId'
          updateHolidayId(ev)
        }}
        onChange={updateHolidayId}
      >
        {holidayUnits?.map((holidayUnit, i) => (
          <MenuItem key={i} value={holidayUnit?.id}>
            {holidayUnit.name}
          </MenuItem>
        ))}
      </Select>
    )
  }

  return (
    <Box mb={2}>
      {groupFields.map((field) => (
        <Field
          fullWidth
          key={field.id}
          component={TextField}
          name={field.name}
          type={field.type}
          label={field.label}
          variant="outlined"
          margin="normal"
          size="small"
        />
      ))}
      {creationMode && (
        <Field
          fullWidth
          key={'2'}
          component={selectComponent}
          variant="outlined"
          margin="normal"
          size="small"
        />
      )}
    </Box>
  )
}

export default HolidaysCategoriesForm
