import { InfoSnackbar, LogoContainer, StyledContainer } from 'components/common'

import { ChangePasswordForm, InActiveMessage } from './components'
import { useRemindPasswordCheck } from './utils'

const RemindPasswordPage: React.FC = (): JSX.Element => {
  const { isActiveToken } = useRemindPasswordCheck()

  return (
    <StyledContainer>
      <LogoContainer />
      {isActiveToken ? (
        <>
          <ChangePasswordForm /> <InfoSnackbar />
        </>
      ) : (
        <InActiveMessage />
      )}
    </StyledContainer>
  )
}
export default RemindPasswordPage
