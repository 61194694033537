import { IProjects } from 'hooks/types'

export const findCustomer = (customersList, customer) => {
  return customersList?.find(
    (singleCustomer) => singleCustomer.name === customer
  )
}

export const findProject = (projectList: IProjects[], project: string) => {
  return projectList?.find((singleProject) => singleProject.name === project)
}

export const agreementsList = (agreements) => {
  return agreements.map(({ id, type }) => {
    return {
      id: id,
      name: type,
    }
  })
}
