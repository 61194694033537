import { useContext, useEffect } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useNotifications, NOTIFICATION } from 'components/common'
import { apiRequest, APIRoutes, getJWTHeader } from 'apiServices'
import {
  getStoredUser,
  clearStoredUser,
  setStoredUser,
  getTokenOther,
  setLoginAsOtherToken,
  logOutAsOther,
} from 'user-storage'
import { queryKeys } from 'react-query/constants'
import { useNavigate } from 'react-router-dom'
import UserContext from 'contexts/user'

import { IUser } from './types'

export interface ILogin {
  data: { token: string }
}

export async function fetchUser(token: null | string): Promise<IUser | null> {
  const { data }: AxiosResponse<IUser> = await apiRequest(APIRoutes.AUTH_ME, {
    headers: getJWTHeader(token),
  })
  return data
}

export function useAuth() {
  const { t } = useTranslation('handleMessage')
  const navigate = useNavigate()
  const SERVER_ERROR = t('networkError')
  const queryClient = useQueryClient()
  const token = getStoredUser()
  const otherUserToken = getTokenOther()
  const { user, setUser } = useContext(UserContext)

  const { setNotification } = useNotifications()

  async function signIn(email: string, password: string): Promise<void> {
    try {
      const { data }: AxiosResponse<ILogin> = await apiRequest(
        APIRoutes.AUTH_LOGIN,
        { email, password },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )

      if (data.data.token) {
        const { token } = data.data
        setStoredUser(token)
        queryClient.setQueryData(queryKeys.me, token)
        const user = await fetchUser(token)

        if (user) {
          updateUser(user)
          navigate('/')
        }
      }
    } catch (errorResponse) {
      clearStoredUser()

      const errorTitle =
        axios.isAxiosError(errorResponse) &&
        errorResponse?.response?.data.message
          ? errorResponse?.response?.data?.message
          : SERVER_ERROR
      setNotification({
        message: errorTitle,
        variant: NOTIFICATION.error,
      })
    }
  }
  async function loginByOther(userToken: string) {
    setLoginAsOtherToken(userToken)
  }

  function logout() {
    logOutAsOther()
    setUser(null)
    clearStoredUser()
    queryClient.setQueryData(queryKeys.user, null)
    navigate('/login')
  }

  async function logoutAsUser() {
    logOutAsOther()
    await refetchUser()
  }

  const {
    data: userData,
    refetch: refetchUser,
    status,
    error,
  } = useQuery<IUser | null, AxiosError>(queryKeys.user, () =>
    fetchUser(otherUserToken || token)
  )

  function updateUser(newUser: object): void {
    queryClient.setQueryData(queryKeys.user, newUser)
    setUser(newUser as IUser)
  }

  useEffect(() => {
    if (error?.response?.status === 401) {
      clearStoredUser()
      setUser(null)
      navigate('/login')
    }
  }, [error])
  useEffect(() => {
    if (token && userData) {
      setUser(userData as IUser)
    }
  }, [userData, token])

  return {
    logout,
    signIn,
    user,
    loginByOther,
    logoutAsUser,
    status,
    updateUser,
    refetchUser,
  }
}
