import { Typography } from '@mui/material'
import { IUserReportProductivity } from 'hooks/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from 'theme'

interface IProps {
  index: number
  user: IUserReportProductivity
  isPlanningMode: boolean
}

const Hours: React.FC<IProps> = ({ index, user, isPlanningMode }) => {
  const { t } = useTranslation(['reportProductivity', 'buttons'])
  return (
    <Typography
      fontSize={10}
      fontWeight={600}
      sx={{
        color: user?.userSummary?.length
          ? (isPlanningMode
              ? Math.round(user?.userSummary[index].FTEPlanningHours)
              : Math.round(user?.userSummary[index].FTEWorkingHours)) > 0
            ? theme.palette.success.dark
            : (isPlanningMode
                ? Math.round(user?.userSummary[index].FTEPlanningHours)
                : Math.round(user?.userSummary[index].FTEWorkingHours)) === 0
            ? theme.palette.success.main
            : theme.palette.error.main
          : theme.palette.error.dark,
      }}
      textAlign="center"
    >
      {user?.userSummary?.length
        ? isPlanningMode
          ? Math.round(user?.userSummary[index].FTEPlanningHours) +
            '\n' +
            '(' +
            user?.userSummary[index].usedFTE.toFixed(2) +
            t('table.fte') +
            ')'
          : Math.round(user?.userSummary[index].FTEWorkingHours) +
            '\n' +
            '(' +
            user?.userSummary[index].usedFTE.toFixed(2) +
            t('table.fte') +
            ')'
        : '---'}
    </Typography>
  )
}

export default Hours
