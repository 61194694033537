import { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import {
  AutoCompleteCheckAll,
  CustomAutocompleteInput,
  CustomizedDataPicker,
  TableTitle,
} from 'components/common'
import DownloadIcon from '@mui/icons-material/Download'
import { LoadingButton } from '@mui/lab'

import {
  useForm,
  useReportForCustomer,
  useReportForProject,
  useReportForUser,
} from '../../hooks'

export interface ReportFormProps {
  typeOfReport: string
}

const ReportForm: React.FC<ReportFormProps> = ({
  typeOfReport,
}): JSX.Element => {
  const { t } = useTranslation(['reportDashboard', 'buttons', 'validation'])
  const [selectedReport, setSelectedReport] = useState('')

  const { handleDownloadCustomerReport } = useReportForCustomer()
  const { handleDownloadProjectReport } = useReportForProject()
  const { handleDownloadUserReport } = useReportForUser()
  const {
    autocompleteArr,
    checkboxArr,
    customers,
    initialValues,
    validationSchemaCustomers,
    validationSchemaProjects,
    validationSchemaUsers,
  } = useForm()

  const validationSchema = (typeOfReport) => {
    switch (typeOfReport) {
      case 'users':
        return validationSchemaUsers
      case 'projects':
        return validationSchemaProjects
      case 'customers':
        return validationSchemaCustomers
      default:
        validationSchemaUsers
    }
  }
  const onSubmitFunction = (typeOfReport, values, selectedReport) => {
    switch (typeOfReport) {
      case 'users':
        return handleDownloadUserReport(values, selectedReport)
      case 'projects':
        return handleDownloadProjectReport(values)
      case 'customers':
        return handleDownloadCustomerReport(values)
      default:
        handleDownloadUserReport(values, selectedReport)
    }
  }
  const checkboxArrForSelectedReport =
    typeOfReport === 'customers' ? checkboxArr : checkboxArr.slice(0, -2)
  return (
    <Paper sx={{ minWidth: '20rem', width: '29%', padding: '1rem' }}>
      <TableTitle>
        {t('common.title')} {t(`${typeOfReport}`)}
      </TableTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(typeOfReport)}
        onSubmit={(values, { setSubmitting }) => {
          onSubmitFunction(typeOfReport, values, selectedReport)?.then(() =>
            setSubmitting(false)
          )
        }}
      >
        {({ values, isSubmitting, errors }) => (
          <Form>
            <Grid container spacing={2} sx={{ padding: '0.5rem' }}>
              {typeOfReport === 'customers' && (
                <Grid item xs={12}>
                  <Field
                    component={CustomAutocompleteInput}
                    optionsData={
                      customers.map((customer) => {
                        return {
                          name: `${customer.name} (${
                            customer?.projects?.length
                          } ${
                            customer.projects.length === 1
                              ? t('tab3.label1text')
                              : t('tab3.label1text2')
                          })`,
                          id: customer.id,
                        }
                      }) || []
                    }
                    name="customers"
                    label={t('tab3.label1')}
                    disableClearable={true}
                    error={errors.customers}
                  />
                </Grid>
              )}
              {autocompleteArr
                .filter(
                  (group) =>
                    group.name === typeOfReport || group.name === 'agreements'
                )
                .map((group) => (
                  <Grid item xs={12} key={group.name}>
                    <Field
                      component={AutoCompleteCheckAll}
                      optionsData={group.item}
                      name={group.name}
                      label={group.label}
                      error={errors['item.item']}
                    />
                    {errors[group.name] && (
                      <Typography
                        variant="h6"
                        color="error"
                        sx={{ margin: '0.5rem 0' }}
                      >
                        {errors[group.name]}
                      </Typography>
                    )}
                  </Grid>
                ))}
              <Grid item xs={12}>
                <Field
                  component={CustomizedDataPicker}
                  label={t('common.label3')}
                  name="month"
                  views={['year', 'month']}
                  inputFormat="yyyy-MM"
                />
              </Grid>
              <Grid item xs={12}>
                {checkboxArrForSelectedReport.map((checkbox) => (
                  <Field
                    key={checkbox.name}
                    type="checkbox"
                    name={checkbox.name}
                    as={FormControlLabel}
                    control={<Checkbox checked={values[checkbox.name]} />}
                    label={checkbox.label}
                  />
                ))}
              </Grid>
              <Grid container justifyContent="space-around" marginTop="1rem">
                <LoadingButton
                  type="submit"
                  endIcon={<DownloadIcon />}
                  onClick={() => setSelectedReport('normal')}
                  loading={selectedReport === 'normal' && isSubmitting}
                  loadingPosition="end"
                  disabled={selectedReport === 'uop' && isSubmitting}
                  variant={
                    selectedReport === 'uop' && isSubmitting
                      ? 'outlined'
                      : 'contained'
                  }
                >
                  {t('download', { ns: 'buttons' })}
                </LoadingButton>
                {typeOfReport === 'users' && (
                  <LoadingButton
                    type="submit"
                    endIcon={<DownloadIcon />}
                    onClick={() => {
                      setSelectedReport('uop')
                    }}
                    loading={selectedReport === 'uop' && isSubmitting}
                    loadingPosition="end"
                    disabled={selectedReport === 'normal' && isSubmitting}
                    variant={
                      selectedReport === 'normal' && isSubmitting
                        ? 'outlined'
                        : 'contained'
                    }
                  >
                    {t('downloadUop', { ns: 'buttons' })}{' '}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}
export default ReportForm
