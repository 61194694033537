import { Button, LinearProgress } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { IInitialValues } from '../..'
import LocationForm from '../../components/LocationForm'
import { useLocationsList } from '../../useLocationsList'
interface AddLocationProps {
  initialValues: IInitialValues
  validationSchema: object
}
const AddLocation: React.FC<AddLocationProps> = ({
  initialValues,
  validationSchema,
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const { addLocation } = useLocationsList()
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        addLocation(values)
        setSubmitting(false)
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <LocationForm />
          <Button type="submit">{t('save')}</Button>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default AddLocation
