import { Box } from '@mui/material'
import { PermissionsList } from 'pages/Groups/components'

import { EditGroupProps } from '../EditGroup'

const ManageGroupPermissions: React.FC<EditGroupProps> = ({
  group,
}): JSX.Element => {
  return (
    <Box sx={{ height: '70vh' }}>
      <PermissionsList group={group} />
    </Box>
  )
}
export default ManageGroupPermissions
