import { Tooltip } from '@mui/material'
import CategoryIcon from '@mui/icons-material/Category'
import EuroIcon from '@mui/icons-material/Euro'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import BeachAccess from '@mui/icons-material/BeachAccess'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import AddTaskIcon from '@mui/icons-material/AddTask'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { IButtonArr } from 'hooks/types'
import { IFields } from 'views/LayoutUI/components/types'

import { IconContainer } from '../index.style'

export function useArrays() {
  const { t } = useTranslation(['systemWorkTypes', 'buttons'])
  const iconsArr = [
    {
      id: 1,
      icon: <EuroIcon />,
      text: t('table.cell7'),
    },
    {
      id: 2,
      icon: <CategoryIcon />,
      text: t('table.cell8'),
    },
    {
      id: 3,
      icon: <AssignmentIndIcon />,
      text: t('table.cell9'),
    },
    {
      id: 4,
      icon: <BeachAccess />,
      text: t('table.cell10'),
    },
    {
      id: 5,
      icon: <AddTaskIcon />,
      text: t('table.cell11'),
    },
    {
      id: 6,
      icon: <HomeRepairServiceIcon />,
      text: t('table.cell12'),
    },
    {
      id: 7,
      icon: <AssignmentTurnedInIcon />,
      text: t('table.cell13'),
    },
  ]

  const iconsButtonsArr = [
    {
      id: 1,
      icon: <ThumbUpIcon />,
      text: t('table.button1'),
    },
    {
      id: 2,
      icon: <ThumbDownIcon />,
      text: t('table.button2'),
    },
    {
      id: 3,
      icon: <MoreHorizIcon />,
      text: t('table.button3'),
    },
  ]
  const buttonsArr: IButtonArr[] = [
    {
      title: `${t('edit', { ns: 'buttons' })}`,
      icon: <EditIcon />,
    },
    {
      title: `${t('delete', { ns: 'buttons' })}`,
      icon: <DeleteIcon color="error" />,
    },
  ]
  const icons = iconsArr.map(({ icon, text, id }) => (
    <Tooltip key={id} title={text} arrow>
      <IconContainer>{icon}</IconContainer>
    </Tooltip>
  ))

  const fieldArr = [
    { name: 'paid', label: t('modals.form.title1') },
    { name: 'isProductive', label: t('modals.form.title2') },
    { name: 'isAllocated', label: t('modals.form.title3') },
    { name: 'isHoliday', label: t('modals.form.title4') },
    { name: 'needApproval', label: t('modals.form.title5') },
    { name: 'isWorking', label: t('modals.form.title6') },
    { name: 'isAssignableToProject', label: t('modals.form.title7') },
  ]
  const customerFields: IFields[] = [
    {
      id: '1',
      label: t('modals.form.label3'),
      name: 'customerCode',
      type: 'text',
    },
    {
      id: '2',
      label: t('modals.form.label4'),
      name: 'customerName',
      type: 'text',
    },
  ]

  const customerEditFields: IFields[] = [
    {
      id: '1',
      label: t('modals.form.label3'),
      name: 'customerCode',
      type: 'text',
    },
    {
      id: '2',
      label: t('modals.form.label4'),
      name: 'customerName',
      type: 'text',
    },
  ]
  return {
    iconsArr,
    iconsButtonsArr,
    icons,
    buttonsArr,
    fieldArr,
    customerFields,
    customerEditFields,
  }
}
