import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import fileDownload from 'js-file-download'
import { IPkupReports } from 'hooks/pkup/types'

export const useDownloadReport = () => {
  async function getDownloadReportZip(reportId: number) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_GET_ZIP,
      {},
      { pkupId: reportId }
    )
    return data
  }
  const handleDownloadReportZip = async (report: IPkupReports) => {
    await getDownloadReportZip(report.id).then((response) => {
      fileDownload(new Blob([response]), `${report.title}.zip`)
    })
  }

  return handleDownloadReportZip
}
