import { IUserWorkdaysSummary } from '../../types'

const monthlySum = (month: string, workday: IUserWorkdaysSummary) => {
  const findSumDays = workday?.workdays.find(
    (workday) => workday.month === month
  )?.sumDays

  return findSumDays || '--'
}

export const rowsReportWorkdays = (workdaysData: IUserWorkdaysSummary[]) => {
  const rows = workdaysData.map((workday, i) => {
    return {
      id: i + 1,
      fullName: `${workday.user.firstName} ${workday.user.lastName}`,
      worktype: workday.workType.name,
      I: monthlySum('01', workday),
      II: monthlySum('02', workday),
      III: monthlySum('03', workday),
      IV: monthlySum('04', workday),
      V: monthlySum('05', workday),
      VI: monthlySum('06', workday),
      VII: monthlySum('07', workday),
      VIII: monthlySum('08', workday),
      IX: monthlySum('09', workday),
      X: monthlySum('10', workday),
      XI: monthlySum('11', workday),
      XII: monthlySum('12', workday),
    }
  })
  return rows
}
