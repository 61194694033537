import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  IVacationRequest,
  useVacationRequests,
} from 'pages/VacationRequest/hooks/useVacationRequests'
import { useAuth } from 'hooks/useAuth'

interface YourVacationRequestProps {
  setSelected: Dispatch<SetStateAction<IVacationRequest | undefined>>
  vacation?: IVacationRequest
  isDisabled?: boolean
}
const DownloadButton: React.FC<YourVacationRequestProps> = ({
  setSelected,
  vacation,
  isDisabled,
}): JSX.Element => {
  const { t } = useTranslation(['vacationRequests', 'buttons'])
  const { user } = useAuth()
  const { downloadVacationRequest, isFetching } = useVacationRequests(user?.id)
  return (
    <Tooltip
      title={
        isDisabled
          ? (t('missLocations', { ns: 'vacationRequests' }) as string)
          : (t('download', { ns: 'buttons' }) as string)
      }
    >
      <span>
        <IconButton
          color="success"
          disabled={isFetching || isDisabled}
          onClick={() => {
            setSelected(vacation), downloadVacationRequest(vacation)
          }}
        >
          {isFetching ? <RefreshIcon /> : <FileDownloadIcon />}
        </IconButton>
      </span>
    </Tooltip>
  )
}
export default DownloadButton
