import { useState, ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { PageRoutes } from 'routes'

import { useNotifications } from '../providers/APIErrorProvider'

const PermissionAlert = (): ReactElement => {
  const [open, setOpen] = useState(false)
  const { notifications } = useNotifications()
  const { t } = useTranslation('permissionAlert')
  const navigate = useNavigate()
  useEffect(() => {
    if (notifications?.permissionAlert) {
      setOpen(true)
    }
  }, [notifications])
  return (
    <Dialog open={open}>
      <DialogTitle>{t('info.title')}</DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false)
            navigate(PageRoutes.LOGIN)
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PermissionAlert
