import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '5px',
  marginBottom: '10px',
  padding: '5px',
  alignItems: 'center',
  columnGap: '10px',
  color: theme.palette.primary.main,
  justifyContent: 'center',
}))

export const IconContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ModalContentContainer = styled(Box)({
  maxHeight: '80vh',
  overflow: 'auto',
})
