import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-mui'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { TableComponent } from 'components/common'
import { useData, useVacationRequests } from 'pages/VacationRequest/hooks'
import {
  IVacationRequest,
  StatusEnum,
} from 'pages/VacationRequest/hooks/useVacationRequests'
import { useAuth } from 'hooks/useAuth'
import { isUserPermitted, permissionList } from 'services'
import { format } from 'date-fns'

interface RequestInfoProps {
  selectedRequest?: IVacationRequest | undefined
}
const RequestInfo: React.FC<RequestInfoProps> = ({
  selectedRequest,
}): JSX.Element => {
  const { t } = useTranslation(['vacationRequests', 'buttons'])
  const [selectedButton, setSelectedButton] = useState('')
  const { getStatusColor } = useData()
  const { user } = useAuth()
  const { updateStatusVacationRequest } = useVacationRequests()

  const infoRequestArr = [
    {
      title: t('panel2.requestsTable.requestDate'),
      text: selectedRequest?.requestDate,
    },
    {
      title: t('panel2.requestsTable.comment'),
      text: selectedRequest?.comment || '--',
    },
    {
      title: t('panel2.requestsTable.status'),
      text: selectedRequest?.status,
    },
    {
      title: t('panel2.requestsTable.approverComment'),
      text: selectedRequest?.approverComment,
    },
    {
      title: t('panel2.requestsTable.workType'),
      text: selectedRequest?.workTypeName,
    },
    {
      title: t('panel2.requestsTable.project'),
      text: selectedRequest?.projectName || '--',
    },
  ]
  const columns = [
    t('panel1.requestsTable.name'),
    t('panel1.requestsTable.vacationType'),
    t('panel1.requestsTable.startDate'),
    t('panel1.requestsTable.endDate'),
    t('panel1.requestsTable.days'),
  ]
  const rows = [
    {
      name: selectedRequest?.firstName + ' ' + selectedRequest?.lastName,
      type: selectedRequest?.vacationType || '--',
      startDate:
        selectedRequest?.startDate &&
        format(new Date(selectedRequest.startDate), 'yyyy-MM-dd'),
      endDate:
        selectedRequest?.endDate &&
        format(new Date(selectedRequest.endDate), 'yyyy-MM-dd'),
      days: selectedRequest?.days,
    },
  ]
  return (
    <Box>
      <TableComponent rows={rows} columns={columns} />{' '}
      <Box
        sx={{
          margin: '1rem 0.25rem',
        }}
      >
        {infoRequestArr.map((request) => (
          <Box key={request.title} sx={{ display: 'flex', gap: '4px' }}>
            <Typography variant="subtitle1">
              <b>{request.title}:</b>
            </Typography>
            <Typography
              variant="subtitle1"
              color={getStatusColor(request.text)}
            >
              {request.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <Formik
        initialValues={{
          approverComment: selectedRequest?.approverComment || '',
          dayType: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateStatusVacationRequest({
            values: values,
            vacationRequestId: selectedRequest?.id,
            type: selectedButton,
          })
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form>
            <Box mb={2} mt={2}>
              <Field
                fullWidth
                component={TextField}
                name="approverComment"
                type="text"
                label={t('panel2.requestsTable.approverComment')}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            {isUserPermitted(user, [
              permissionList.VACATION_REQUESTS_MANAGE,
            ]) && (
              <>
                {selectedRequest?.status === StatusEnum.pending && (
                  <>
                    <Button
                      onClick={() => setSelectedButton(StatusEnum.approved)}
                      color="success"
                      type="submit"
                    >
                      {t('approve', { ns: 'buttons' })}
                    </Button>{' '}
                    <Button
                      onClick={() => setSelectedButton(StatusEnum.rejected)}
                      color="error"
                      type="submit"
                    >
                      {t('reject', { ns: 'buttons' })}{' '}
                    </Button>
                  </>
                )}
                {selectedRequest?.status === StatusEnum.approved && (
                  <Button
                    onClick={() => setSelectedButton(StatusEnum.cancelled)}
                    type="submit"
                  >
                    {t('cancel', { ns: 'buttons' })}{' '}
                  </Button>
                )}
              </>
            )}
            <br />
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default RequestInfo
