import { NOTIFICATION, useNotifications } from 'components/common'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

export interface ICustomerProjectData {
  code: string
  endDate: string | Date
  isBillable: boolean
  isProductive: boolean
  name: string
  startDate: string | Date
  id: number | undefined
}

const updateSingleCustomerProject = async (
  customerProjectData: ICustomerProjectData
) => {
  const { id, ...rest } = customerProjectData
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PROJECTS_UPDATE,
    rest,
    { id }
  )

  return data
}

export const useUpdateCustomerProject = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')

  const { mutate: updateCustomerProject } = useMutation(
    updateSingleCustomerProject,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('customerProject')} ${t('update')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.customer])
      },
    }
  )

  return { updateCustomerProject }
}
