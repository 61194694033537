import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import { usePkupsDetails } from 'pages/HrDashboard/hooks/'
import { useUpdateApplication, useStatus } from 'hooks/pkup'
import { ApplicationForm, PkupCommentForm } from 'components/Pkup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WorkIcon from '@mui/icons-material/Work'
import DownloadIcon from '@mui/icons-material/Download'
import { useAuth } from 'hooks/useAuth'
import palette from 'theme/palette'

import { StyledTitlePaper } from './index.style'

interface PkupsDetailsProps {
  page: string
}
const PkupsDetails: React.FC<PkupsDetailsProps> = ({ page }): JSX.Element => {
  const { id } = useParams()
  const { pkupDetails, pkupWorks } = usePkupsDetails(Number(id))
  const { getPkupStatus } = useStatus()
  const { t } = useTranslation('hrDashboard')
  const user = useAuth()
  const { editPkup, handleDownload } = useUpdateApplication(pkupDetails.id)
  const initialValuesUpdate = {
    title: pkupDetails.title || '',
    description: pkupDetails.description || '',
    period: pkupDetails.period,
  }
  return (
    <Container>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid
          item
          xs={6}
          md={6}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={6} md={12}>
            <Paper
              sx={{
                padding: '1rem',
                margin: '1rem 0',
                minWidth: '30rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginRight: '1rem',
                }}
              >
                <StyledTitlePaper>
                  <Typography variant="h3">
                    {t('pkupDetails.title1')}
                  </Typography>
                </StyledTitlePaper>
                <Paper
                  sx={{
                    height: '2.5rem',
                    padding: '0 1rem',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    color={getPkupStatus(pkupDetails?.state)?.color}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {t('pkupDetails.detailsText1')}
                  </Typography>
                  <Tooltip
                    title={
                      (getPkupStatus(pkupDetails?.state)
                        ?.titleDashboard as string) || ''
                    }
                  >
                    <IconButton>
                      {getPkupStatus(pkupDetails?.state)?.icon}
                    </IconButton>
                  </Tooltip>
                  <Paper
                    sx={{
                      backgroundColor: getPkupStatus(pkupDetails?.state)?.color,
                      color: palette.primary.contrastText,
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 0.5rem',
                      borderRadius: '0.5rem',
                      margin: '0.25rem',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color={palette.primary.contrastText}
                    >
                      {getPkupStatus(pkupDetails?.state)?.title.toUpperCase()}
                    </Typography>
                  </Paper>
                </Paper>
              </Box>
              <ApplicationForm
                buttonName="update"
                disabled={pkupDetails.creatorId !== user?.user?.id}
                initialValues={initialValuesUpdate}
                onSubmit={editPkup}
              />
            </Paper>
          </Grid>
          <Grid item xs={6} md={12}>
            <Paper
              sx={{
                backgroundColor: palette.secondary.main,
                padding: '1rem',
                margin: '1rem 0',
                minWidth: '30rem',
              }}
            >
              <StyledTitlePaper>
                <Typography variant="h3">{t('pkupDetails.title3')}</Typography>
              </StyledTitlePaper>
              <Typography
                sx={{ whiteSpace: 'pre-line', padding: '1rem' }}
                variant="subtitle1"
              >
                {pkupDetails.approverComment
                  ? pkupDetails?.approverComment
                  : t('pkupDetails.feedbackText1')}
              </Typography>

              {page === 'pkupsDashboard' && (
                <PkupCommentForm id={Number(id)} page={page} />
              )}
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              backgroundColor: palette.secondary.main,
              padding: '1rem',
              margin: '1rem 0',
              width: '30rem',
            }}
          >
            <StyledTitlePaper>
              <Typography variant="h3">{t('pkupDetails.title2')}</Typography>
            </StyledTitlePaper>
            {pkupWorks.length ? (
              pkupWorks.map((work) => (
                <Paper key={work.id} sx={{ margin: '0.5rem 0' }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box sx={{ display: 'flex' }}>
                        <IconButton>
                          <WorkIcon />
                        </IconButton>
                        <Typography variant="h3">{work.title}</Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {work.pkupattachments.length ? (
                        <>
                          <Typography variant="subtitle1">
                            {t('pkupDetails.workText1')}:
                          </Typography>
                          {work.pkupattachments.map((attachment) => (
                            <Box key={attachment.id} sx={{ display: 'flex' }}>
                              <Typography variant="subtitle1">
                                {attachment.attachmentName}
                              </Typography>
                              <Tooltip title="Download">
                                <IconButton
                                  onClick={() =>
                                    handleDownload({
                                      id: attachment.id,
                                      name: attachment.attachmentName,
                                    })
                                  }
                                >
                                  <DownloadIcon color="success" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ))}
                        </>
                      ) : (
                        <Box>
                          <Typography variant="subtitle1">
                            {t('pkupDetails.workText2')}
                          </Typography>
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              ))
            ) : (
              <Typography variant="subtitle1" sx={{ padding: '1rem' }}>
                {t('pkupDetails.workText3')}
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
export default PkupsDetails
