import { GroupForm } from 'pages/Groups/components'

import { useAddGroup } from './utils'

const AddNewGroup: React.FC = (): JSX.Element => {
  const addGroup = useAddGroup()
  const initialValues = {
    groupName: '',
    groupCode: '',
  }

  return <GroupForm initialValues={initialValues} onSubmitForm={addGroup} />
}

export default AddNewGroup
