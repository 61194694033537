import { Box, styled, Button } from '@mui/material'

export const ModalContentContainer = styled(Box)({
  maxHeight: '80vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'auto',
})

export const SubmitButton = styled(Button)({
  marginTop: '10px',
  width: '30%',
  display: 'flex',
  alignSelf: 'flex-end',
})

export const AssignButton = styled(Button)({
  fontSize: '12px',
  width: '100%',
})
