import { APIRoutes, HttpMethod } from './enums'

interface endpointMap {
  [key: string]: {
    url: string
    method: number
    timeout?: number
  }
}

export const endpointRequest: endpointMap = {
  [APIRoutes.ACTIVITY_LOG]: {
    url: '/activity',
    method: HttpMethod.GET,
  },
  [APIRoutes.ALLOCATION_READ]: {
    url: '/allocations-filters/user',
    method: HttpMethod.POST,
  },
  [APIRoutes.ALLOCATION_READ_BY_CUSTOMER]: {
    url: '/allocations-filters/customer',
    method: HttpMethod.POST,
  },
  [APIRoutes.ALLOCATIONS_ADD]: {
    url: '/allocations',
    method: HttpMethod.POST,
  },
  [APIRoutes.ALLOCATIONS_CHECKER]: {
    url: '/allocations/calculation',
    method: HttpMethod.POST,
  },
  [APIRoutes.ALLOCATIONS_DELETEBYID]: {
    url: '/allocations/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.ALLOCATIONS_GETBYPROJECTUSER]: {
    url: '/allocations/userProject/:id',
    method: HttpMethod.GET,
  },
  [APIRoutes.ALLOCATIONS_UPDATE]: {
    url: '/allocations/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.AUTH_LOGIN]: {
    url: '/login',
    method: HttpMethod.POST,
  },
  [APIRoutes.ADD_AGREEMENT_TO_USER]: {
    url: '/userAgreement',
    method: HttpMethod.PUT,
  },
  [APIRoutes.ADD_HOLIDAY_CATEGORY_TO_USER]: {
    url: '/user/:id/holidays',
    method: HttpMethod.POST,
  },
  [APIRoutes.AGREEMENTS_GET]: {
    url: '/agreements',
    method: HttpMethod.GET,
  },
  [APIRoutes.ALL_WORKDAYS_DELETE]: {
    url: '/allworkdays',
    method: HttpMethod.POST,
  },
  [APIRoutes.APPLICATION_DELETE]: {
    url: '/applications/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.APPLICATION_GET_ONE]: {
    url: '/applications/:id',
    method: HttpMethod.POST,
  },
  [APIRoutes.APPLICATION_UPDATE]: {
    url: '/applications/update/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.APPLICATION_UPDATE_PARTIAL]: {
    url: '/applications/update/partial/:id',
    method: HttpMethod.PATCH,
  },
  [APIRoutes.APPLICATIONS_GET_ALL]: {
    url: '/applications',
    method: HttpMethod.GET,
  },
  [APIRoutes.APPLICATIONS_SAVE]: {
    url: '/applications',
    method: HttpMethod.POST,
  },
  [APIRoutes.AUTH_LOGIN_BY_OTHER]: {
    url: '/login-by-other',
    method: HttpMethod.POST,
  },
  [APIRoutes.AUTH_ME]: {
    url: '/me',
    method: HttpMethod.GET,
  },
  [APIRoutes.BUSINESS_UNITS_ADD_USER]: {
    url: '/businessUnit/:id/users',
    method: HttpMethod.POST,
  },
  [APIRoutes.BUSINESS_UNITS_CREATE]: {
    url: '/businessUnit',
    method: HttpMethod.POST,
  },
  [APIRoutes.BUSINESS_UNITS_DELETE]: {
    url: '/businessUnits/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.BUSINESS_UNITS_DELETE_USER]: {
    url: '/businessUnits/:businessUnitId/user/:userId',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.BUSINESS_UNITS_GETALL]: {
    url: '/businessUnits',
    method: HttpMethod.GET,
  },
  [APIRoutes.BUSINESS_UNITS_READ]: {
    url: '/businessUnits/:id',
    method: HttpMethod.GET,
  },
  [APIRoutes.BUSINESS_UNITS_TOGGLE_FLAGS]: {
    url: '/businessUnits/:businessUnitId/user/:userId/flags',
    method: HttpMethod.PUT,
  },
  [APIRoutes.BUSINESS_UNITS_UPDATE]: {
    url: '/businessUnits/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.CHECK_LOGIN]: {
    url: '/login',
    method: HttpMethod.POST,
  },
  [APIRoutes.CUSTOMERS_CREATE]: {
    url: '/customer',
    method: HttpMethod.POST,
  },
  [APIRoutes.CUSTOMERS_DELETE]: {
    url: '/customers/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.CUSTOMERS_GETALL]: {
    url: '/customers',
    method: HttpMethod.GET,
  },
  [APIRoutes.CUSTOMERS_GETONE]: {
    url: '/customers/:id',
    method: HttpMethod.GET,
  },
  [APIRoutes.CUSTOMERS_UPDATE]: {
    url: '/customers/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.CV_BIO_CREATE]: {
    url: '/users/:id/bios',
    method: HttpMethod.POST,
  },
  [APIRoutes.CV_BIO_DELETE]: {
    url: '/bios/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.CV_BIO_UPDATE]: {
    url: '/bios/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.CV_EDU_CREATE]: {
    url: '/users/:id/educations',
    method: HttpMethod.POST,
  },
  [APIRoutes.CV_EDU_DELETE]: {
    url: '/educations/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.CV_EDU_UPDATE]: {
    url: '/educations/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.CV_GENERATE]: {
    url: '/cv/generate',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.CV_PREVIEW]: {
    url: '/cv/preview',
    method: HttpMethod.GET,
    timeout: 120000,
  },
  [APIRoutes.CV_PROFILE_CREATE]: {
    url: '/users/:id/cv-profiles',
    method: HttpMethod.POST,
  },
  [APIRoutes.CV_PROFILE_GET]: {
    url: '/users/:id/cv-profiles',
    method: HttpMethod.GET,
  },
  [APIRoutes.CV_PROFILE_UPDATE]: {
    url: '/cv/profile/:profileId',
    method: HttpMethod.PUT,
  },
  [APIRoutes.CV_PROJECT_CREATE]: {
    url: '/users/:id/cv-projects',
    method: HttpMethod.POST,
  },
  [APIRoutes.CV_PROJECT_DELETE]: {
    url: '/cv-projects/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.CV_PROJECT_UPDATE]: {
    url: '/users/:id/cv-project',
    method: HttpMethod.PUT,
  },
  [APIRoutes.CV_TEMPLATES_GET]: {
    url: '/cv/templates',
    method: HttpMethod.GET,
  },
  [APIRoutes.DELETE_AGREEMENT_TO_USER]: {
    url: '/userAgreement/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.DELETE_HOLIDAY_CATEGORY_FROM_USER]: {
    url: '/user-holidays/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.EMPLOYEE_NOTES]: {
    url: '/users/:id/notes',
    method: HttpMethod.GET,
  },
  [APIRoutes.EMPLOYEE_NOTES_ADD]: {
    url: '/user/:id/note',
    method: HttpMethod.POST,
  },
  [APIRoutes.EMPLOYEE_NOTES_DELETE]: {
    url: '/notes/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.EMPLOYEE_NOTES_UPDATE]: {
    url: '/notes/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.GENERATE_VACATION_REQUEST]: {
    url: '/application/vacation/:fullName/:jobPosition/:workPlace/:location/:date/:startDate/:endDate',
    method: HttpMethod.GET_DOWNLOAD,
  },
  [APIRoutes.GET_USERDETAILS]: {
    url: '/users/:id/details',
    method: HttpMethod.GET,
  },
  [APIRoutes.GROUPS_ADDPERMISSION]: {
    url: '/groups/:id/permissions',
    method: HttpMethod.POST,
  },
  [APIRoutes.GROUPS_ADDUSER]: {
    url: '/groups/:id/users',
    method: HttpMethod.POST,
  },
  [APIRoutes.GROUPS_CREATE]: {
    url: '/groups',
    method: HttpMethod.POST,
  },
  [APIRoutes.GROUPS_DELETE]: {
    url: '/groups/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.GROUPS_DELETEPERMISSION]: {
    url: '/groups/permissions/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.GROUPS_GETALL]: {
    url: '/groups',
    method: HttpMethod.GET,
  },
  [APIRoutes.GROUPS_UPDATE]: {
    url: '/groups/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.HELPFULUSERS_GET]: {
    url: '/me/helpingusers',
    method: HttpMethod.GET,
  },
  [APIRoutes.HOLIDAY_CATEGORY_CREATE]: {
    url: '/holidays-categories',
    method: HttpMethod.POST,
  },
  [APIRoutes.HOLIDAY_CATEGORY_DELETE]: {
    url: '/holidays-categories/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.HOLIDAY_CATEGORY_UPDATE]: {
    url: '/holidays-categories/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.HOLIDAYS_CATEGORIES_GETALL]: {
    url: '/holidays-categories',
    method: HttpMethod.GET,
  },
  [APIRoutes.HOLIDAYS_GETALL]: {
    url: '/holidays',
    method: HttpMethod.GET,
  },
  [APIRoutes.HOLIDAYS_CREATE]: {
    url: '/holidays',
    method: HttpMethod.POST,
  },
  [APIRoutes.HOLIDAYS_DELETE]: {
    url: '/holidays/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.HOLIDAYS_SYNC]: {
    url: '/users/:id/holidays-sync',
    method: HttpMethod.POST,
  },
  [APIRoutes.HOLIDAYS_UNITS_GETALL]: {
    url: '/holidayUnits',
    method: HttpMethod.GET,
  },
  [APIRoutes.INVENTORIES_ADD_TO_USER]: {
    url: '/user-inventory',
    method: HttpMethod.POST,
  },
  [APIRoutes.INVENTORIES_DELETE_FROM_USER]: {
    url: '/user-inventory/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.INVENTORIES_GET_FREE]: {
    url: '/inventories-free',
    method: HttpMethod.GET,
  },
  [APIRoutes.INVENTORIES_GETALL]: {
    url: '/inventories',
    method: HttpMethod.GET,
  },
  [APIRoutes.INVENTORIES_UPDATE_TO_USER]: {
    url: '/user-inventory/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.INVENTORY_CREATE]: {
    url: '/inventories',
    method: HttpMethod.POST,
  },
  [APIRoutes.INVENTORY_DELETE]: {
    url: '/inventories/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.INVENTORY_UPDATE]: {
    url: '/inventories/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.LOCATIONS_ADD_USER]: {
    url: '/locations/:id/users',
    method: HttpMethod.POST,
  },
  [APIRoutes.LOCATIONS_CREATE]: {
    url: '/locations',
    method: HttpMethod.POST,
  },
  [APIRoutes.LOCATIONS_DELETE]: {
    url: '/locations/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.LOCATIONS_DELETE_USER]: {
    url: '/locations/:locationId/user/:userId',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.LOCATIONS_GETALL]: {
    url: '/locations',
    method: HttpMethod.GET,
  },
  [APIRoutes.LOCATIONS_READ]: {
    url: '/locations/:id',
    method: HttpMethod.GET,
  },
  [APIRoutes.LOCATIONS_TOGGLEDIRECTOR]: {
    url: '/locations/:locationId/user/:userId/director',
    method: HttpMethod.PUT,
  },
  [APIRoutes.LOCATIONS_UPDATE]: {
    url: '/locations/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.NOTIFICATIONS_CREATE]: {
    url: '/usernotification',
    method: HttpMethod.POST,
  },
  [APIRoutes.NOTIFICATIONS_DELETE]: {
    url: '/usernotification/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.NOTIFICATIONS_GET]: {
    url: '/notifications',
    method: HttpMethod.GET,
  },
  [APIRoutes.PERMISSION_CREATE]: {
    url: '/permissions',
    method: HttpMethod.POST,
  },
  [APIRoutes.PERMISSIONS_DELETE]: {
    url: '/permissions/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.PERMISSIONS_GETALL]: {
    url: '/permissions',
    method: HttpMethod.GET,
  },
  [APIRoutes.PKUP_CHANGE_APPROVAL]: {
    url: '/pkup/:id/approve',
    method: HttpMethod.PUT,
  },
  [APIRoutes.PKUP_CREATE]: {
    url: '/pkup',
    method: HttpMethod.POST,
  },
  [APIRoutes.PKUP_CREATE_WORK]: {
    url: '/pkup-work',
    method: HttpMethod.POST,
  },
  [APIRoutes.PKUP_GET_ALL]: {
    url: '/pkup',
    method: HttpMethod.GET,
  },
  [APIRoutes.PKUP_GET_ALL_FOR_USER]: {
    url: '/pkup/user/:userId/?state=:state',
    method: HttpMethod.GET,
  },
  [APIRoutes.PKUP_GET_ALL_FOR_ME]: {
    url: '/pkup/my/:date',
    method: HttpMethod.GET,
  },
  [APIRoutes.PKUP_GET_ALL_WORK_FOR_PKUP]: {
    url: '/pkup/:id/work',
    method: HttpMethod.GET,
  },
  [APIRoutes.PKUP_GET_ALL_FOR_REPORT]: {
    url: '/pkup/report/:date',
    method: HttpMethod.GET,
  },
  [APIRoutes.PKUP_GET_ALL_WORKS_FOR_ME]: {
    url: '/pkup-work/my/:date',
    method: HttpMethod.GET,
  },
  [APIRoutes.PKUP_GET_ATTACHMENT]: {
    url: '/pkup-work/attachment/:attachmentId',
    method: HttpMethod.GET_DOWNLOAD,
  },
  [APIRoutes.PKUP_GET_ONE]: {
    url: '/pkup/:id',
    method: HttpMethod.GET,
  },
  [APIRoutes.PKUP_GET_REVISION]: {
    url: '/pkup/:id/revision',
    method: HttpMethod.GET,
  },
  [APIRoutes.PKUP_GET_ZIP]: {
    url: '/pkup/zip/:pkupId',
    method: HttpMethod.GET_DOWNLOAD,
  },
  [APIRoutes.PKUP_GET_ZIP_FOR_ME]: {
    url: '/pkup/my/zip/:pkupId',
    method: HttpMethod.GET_DOWNLOAD,
  },
  [APIRoutes.PKUP_UPDATE]: {
    url: '/pkup/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.PKUP_UPDATE_STATE]: {
    url: '/pkup/:id/state',
    method: HttpMethod.PUT,
  },
  [APIRoutes.PROJECTS_ADDUSER]: {
    url: '/projects/:id/users',
    method: HttpMethod.POST,
  },
  [APIRoutes.PROJECTS_CREATE]: {
    url: '/projects',
    method: HttpMethod.POST,
  },
  [APIRoutes.PROJECTS_CREATETIMEFRAMES]: {
    url: '/timeframes',
    method: HttpMethod.POST,
  },
  [APIRoutes.PROJECTS_DELETE]: {
    url: '/projects/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.PROJECTS_DELETEUSER]: {
    url: '/project/user/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.PROJECTS_DELETETIMEFRAMES]: {
    url: '/timeframes/:timeframeId',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.PROJECTS_GETALL]: {
    url: '/projects',
    method: HttpMethod.GET,
  },
  [APIRoutes.PROJECTS_GETALL_FOR_PKUP]: {
    url: '/projects/pkup',
    method: HttpMethod.GET,
  },
  [APIRoutes.PROJECTS_GETONE]: {
    url: '/projects/:id',
    method: HttpMethod.GET,
  },
  [APIRoutes.PROJECTS_TOGGLEPM]: {
    url: '/project/:projectId/user/:userId/pm',
    method: HttpMethod.PUT,
  },
  [APIRoutes.PROJECTS_UPDATE]: {
    url: '/projects/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.PROJECTS_GETONE_CALENDAR]: {
    url: '/projects/:id/calendar?date=:date',
    method: HttpMethod.GET,
  },
  [APIRoutes.PROJECTS_GETONE_HISTORY]: {
    url: '/project/:projectId/history',
    method: HttpMethod.GET,
  },
  [APIRoutes.REPORT_GENERATE_PER_PROJECT]: {
    url: '/report/project/:projectCode/:date',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 700000,
  },
  [APIRoutes.REPORT_GENERATE_ALLOCATIONS_FOR_CUSTOMERS]: {
    url: '/report/allocations-filters/excel-customers',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 700000,
  },
  [APIRoutes.REPORT_GENERATE_ALLOCATIONS_FOR_USERS]: {
    url: '/report/allocations-filters/excel-users',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 700000,
  },
  [APIRoutes.REPORT_GENERATE_FOR_CUSTOMER]: {
    url: '/report/customer/:customer/:date',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 700000,
  },
  [APIRoutes.REPORT_GENERATE_FOR_CUSTOMERS]: {
    url: '/report/customers/:date',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 700000,
  },
  [APIRoutes.REPORT_GENERATE_FOR_PROJECTS]: {
    url: '/report/projects/:date',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 700000,
  },
  [APIRoutes.REPORT_GENERATE_WORKDAYS]: {
    url: '/report/workdays/excel',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 700000,
  },
  [APIRoutes.REPORT_GET_PROJECT_CODES]: {
    url: '/report/projectcode',
    method: HttpMethod.GET,
  },
  [APIRoutes.REPORT_GET_CUSTOMERS]: {
    url: '/report/customers',
    method: HttpMethod.GET,
  },
  [APIRoutes.REMIND_PASSWORD]: {
    url: '/remind-password',
    method: HttpMethod.POST,
  },
  [APIRoutes.REMIND_PASSWORD_CHECK]: {
    url: '/remind-password/check',
    method: HttpMethod.POST,
  },
  [APIRoutes.RESET_PASSWORD]: {
    url: '/reset-password',
    method: HttpMethod.POST,
  },
  [APIRoutes.TAGS_ADD_RATE]: {
    url: '/skill',
    method: HttpMethod.PUT,
  },
  [APIRoutes.TAGS_ADD_TO_PROJECT]: {
    url: '/tags/project/:projectId',
    method: HttpMethod.PUT,
  },
  [APIRoutes.TAGS_ADD_TO_USER]: {
    url: '/skill/:userId',
    method: HttpMethod.PUT,
  },
  [APIRoutes.TAGS_DELETE]: {
    url: '/tags/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.TAGS_DELETE_FROM_PROJECT]: {
    url: '/tags/:projectId/:tagId',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.TAGS_DELETE_FROM_USER]: {
    url: '/skill/:userId/:tagId',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.TAGS_GET]: {
    url: '/tags',
    method: HttpMethod.GET,
  },
  [APIRoutes.TAGS_MULTIPLE_CREATE]: {
    url: '/skillCreate',
    method: HttpMethod.POST,
  },
  [APIRoutes.TAGS_MULTIPLE_GET]: {
    url: '/skill',
    method: HttpMethod.POST,
  },
  [APIRoutes.USERTIMEFRAMES_GET]: {
    url: '/users/:userId/timeframes',
    method: HttpMethod.GET,
  },
  [APIRoutes.USERS_GET_ALL]: {
    url: '/users',
    method: HttpMethod.GET,
  },
  [APIRoutes.USER_SAVE]: {
    url: '/users',
    method: HttpMethod.POST,
  },
  [APIRoutes.UPDATE_AGREEMENT_TO_USER]: {
    url: '/userAgreement/update/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.UPDATE_HOLIDAY_CATEGORY_USER]: {
    url: '/user-holidays/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.UPDATE_ME]: {
    url: '/me',
    method: HttpMethod.PUT,
  },
  [APIRoutes.USER_DELETE]: {
    url: '/users/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.USER_GET_APPLICATIONS]: {
    url: '/users/applications/:id',
    method: HttpMethod.GET,
  },
  [APIRoutes.USER_SAVE_APPLICATION]: {
    url: '/users/applications',
    method: HttpMethod.PUT,
  },
  [APIRoutes.USER_UPDATE]: {
    url: '/users/update/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.USER_UPDATE_PARTIAL]: {
    url: '/users/update/partial/:id',
    method: HttpMethod.PATCH,
  },
  [APIRoutes.USERS_CREATE]: {
    url: '/users',
    method: HttpMethod.POST,
  },
  [APIRoutes.USERS_DELETEGROUP]: {
    url: '/groups/:groupId/user/:userId',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.USERS_GET_BIO]: {
    url: '/users/:id/bios',
    method: HttpMethod.GET,
  },
  [APIRoutes.USERS_GET_CV_PROJECTS]: {
    url: '/users/:id/cv-projects',
    method: HttpMethod.GET,
  },
  [APIRoutes.USERS_GET_EDU]: {
    url: '/users/:id/educations',
    method: HttpMethod.GET,
  },
  [APIRoutes.USERS_GETONE]: {
    url: '/users/:id',
    method: HttpMethod.GET,
    timeout: 20000,
  },
  [APIRoutes.USERS_GETLIST]: {
    url: '/users/list',
    method: HttpMethod.GET,
  },
  [APIRoutes.USERS_GETONE_HISTORY]: {
    url: '/users/:id/history',
    method: HttpMethod.GET,
  },
  [APIRoutes.USERS_UPDATE]: {
    url: '/users/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.USERS_WITH_END_AGREEMENTS_GET]: {
    url: '/userAgreement/endAgreements/:year',
    method: HttpMethod.GET,
  },
  [APIRoutes.VACATION_REQUESTS_GET]: {
    url: '/application/vacations',
    method: HttpMethod.GET,
  },
  [APIRoutes.VACATION_REQUEST_GET_PDF]: {
    url: '/application/vacations/:vacationRequestId/pdf',
    method: HttpMethod.GET_DOWNLOAD,
  },
  [APIRoutes.VACATION_REQUEST_ADD]: {
    url: '/application/vacations',
    method: HttpMethod.POST,
  },
  [APIRoutes.VACATION_REQUEST_VALIDATE]: {
    url: '/application/validateVacationRequest',
    method: HttpMethod.POST,
  },
  [APIRoutes.VACATION_REQUEST_DELETE]: {
    url: '/application/vacations/:vacationRequestId',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.VACATION_REQUEST_GET_APPROVERS]: {
    url: '/application/vacations/approvers',
    method: HttpMethod.GET,
  },
  [APIRoutes.VACATION_REQUEST_UPDATE]: {
    url: '/application/vacations/:vacationRequestId',
    method: HttpMethod.PUT,
  },
  [APIRoutes.VACATION_REQUEST_UPDATE_STATUS]: {
    url: '/application/vacations/:vacationRequestId/status/:type',
    method: HttpMethod.PUT,
  },
  [APIRoutes.WORKDAYS_COPY]: {
    url: '/workdays-copy',
    method: HttpMethod.POST,
  },
  [APIRoutes.WORKDAYS_CREATE]: {
    url: '/workdays',
    method: HttpMethod.POST,
  },
  [APIRoutes.WORKDAYS_DELETE]: {
    url: '/workdays/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.WORKDAYS_DOWNLOADFORCUSTOMER]: {
    url: '/workdays/customer/:id/excel',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.WORKDAYS_DOWNLOADFORME]: {
    url: '/userWorkdays/excel',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.WORKDAYS_DOWNLOADFORPROJECT]: {
    url: '/workdays/project/:id/excel',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.WORKDAYS_DOWNLOADFORPROJECTS]: {
    url: '/workdays-selected/project',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.WORKDAYS_DOWNLOADUOPFORME]: {
    url: '/userWorkdays/excel-uop',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.WORKDAYS_DOWNLOADFORUSER]: {
    url: '/workdays/user/:id/excel',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.WORKDAYS_DOWNLOADFORUSERS]: {
    url: '/workdays-selected',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.WORKDAYS_DOWNLOADUOPFORUSER]: {
    url: '/workdays/user/:id/excel-uop',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.WORKDAYS_DOWNLOADUOPFORUSERS]: {
    url: '/workdays-selected/uop',
    method: HttpMethod.POST_DOWNLOAD,
    timeout: 120000,
  },
  [APIRoutes.WORKDAYS_GETFORME]: {
    url: '/userWorkdays',
    method: HttpMethod.GET,
  },
  [APIRoutes.WORKDAYS_GETFORUSER]: {
    url: '/workdays/user/:id?date=:date',
    method: HttpMethod.GET,
  },
  [APIRoutes.WORKDAYS_HOURS_IN_MONTH]: {
    url: '/workdayHours',
    method: HttpMethod.GET,
  },
  [APIRoutes.WORKDAYS_TOREVIEW]: {
    url: '/workdays-toReview',
    method: HttpMethod.GET,
  },
  [APIRoutes.WORKDAYS_TOREVIEW_ACCEPT]: {
    url: '/workdays-toReview/accept',
    method: HttpMethod.POST,
  },
  [APIRoutes.WORKDAYS_TOREVIEW_DECLINE]: {
    url: '/workdays-toReview/decline',
    method: HttpMethod.POST,
  },
  [APIRoutes.WORKDAYS_UPDATE]: {
    url: '/workdays/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.WORKDAYS_UPDATEFORUSER]: {
    url: '/workdays/user/:userId/:id',
    method: HttpMethod.PUT,
  },
  [APIRoutes.WORKDAYS_READ_FILTERED]: {
    url: '/workdays-filters',
    method: HttpMethod.POST,
    timeout: 80000,
  },
  [APIRoutes.WORKTYPE_GETALL]: {
    url: '/worktypes',
    method: HttpMethod.GET,
  },
  [APIRoutes.WORKTYPE_CREATE]: {
    url: '/worktypes',
    method: HttpMethod.POST,
  },
  [APIRoutes.WORKTYPE_DELETE]: {
    url: '/worktypes/:id',
    method: HttpMethod.DELETE,
  },
  [APIRoutes.WORKTYPE_UPDATE]: {
    url: '/worktypes/:id',
    method: HttpMethod.PUT,
  },
}
