import { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Container, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { HeaderTab } from 'views/LayoutUI/components'

import { UsersBySkills, AllTags, PopularTags } from './components'

const Tags: React.FC = (): JSX.Element => {
  const [value, setValue] = useState('1')
  const { t } = useTranslation('systemTags')
  const handleChange = (_, newValue: string) => {
    setValue(newValue)
  }
  return (
    <Container>
      <HeaderTab translate="systemTags" id={t('header.id')} />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={t('tabsPanels.panel1.title')} value="1" />
            <Tab label={t('tabsPanels.panel2.title')} value="2" />
            <Tab label={t('tabsPanels.panel3.title')} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <UsersBySkills />
        </TabPanel>
        <TabPanel value="2">
          <AllTags />
        </TabPanel>
        <TabPanel value="3">
          <PopularTags />
        </TabPanel>
      </TabContext>
    </Container>
  )
}

export default Tags
