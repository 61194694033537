import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useAllInventories } from 'hooks/inventory'

interface IInventoryValues {
  model: string
  serialNumber: string
  producer: string
  additionalInformation: string
  inventoryId: number | null
  userId: number | null
}
interface IInitialValues {
  model: string
  serialNumber: string
  producer: string
  additionalInformation: string
  assignUser: { id: number; name: string } | null
  name: string
  checkUser: boolean
}

const addNewItemToUser = async (inventory: IInventoryValues) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.INVENTORIES_ADD_TO_USER,
    inventory
  )
  return data
}
export const useAddInventory = () => {
  const queryClient = useQueryClient()
  const { modalName, setNotification, handleCloseModal } = useNotifications()
  const inventories = useAllInventories()
  const [inventoryId, setInventoryId] = useState<number | null>(
    inventories?.data[0]?.id || null
  )
  const { t } = useTranslation('validation')

  const handleClick = (id: number) => {
    setInventoryId(id)
  }

  useEffect(() => {
    if (!modalName) {
      setInventoryId(inventories?.data[0]?.id || null)
    }
  }, [modalName])
  const initialValues: IInitialValues = {
    model: '',
    serialNumber: '',
    producer: '',
    additionalInformation: '',
    name: inventories?.data?.[0]?.name || '',
    assignUser: null,
    checkUser: false,
  }
  const validationSchema = yup.object({
    model: yup.string().required(t('model.required')),
    serialNumber: yup.string().required(t('serialNumber.required')),
    producer: yup.string().required(t('producer.required')),
    name: yup.string().required(t('groupName.required')),
    assignUser: yup
      .object()
      .when('checkUser', {
        is: (checkUser) => checkUser === true,
        then: yup.object().required(t('user.required')).nullable(true),
      })
      .nullable(true),
  })

  const { mutate: createNewInventoryInUser } = useMutation(addNewItemToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: 'Inventory has been added successfully!',
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.user])
      void queryClient.invalidateQueries([queryKeys.freeInventories])
      void queryClient.invalidateQueries([queryKeys.inventories])
    },
  })
  return {
    createNewInventoryInUser,
    handleClick,
    initialValues,
    inventoryId,
    validationSchema,
  }
}
