import { createTheme } from '@mui/material/styles'

import palette from './palette'
import typography from './typography'
import customBreakpoints from './breakpoints'
import customOverrides from './overrides'
export const theme = createTheme({
  breakpoints: customBreakpoints.breakpoints,
  palette,
  typography,
  components: customOverrides.components,
})
