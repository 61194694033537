import { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Container, Box, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { HeaderTab } from 'views/LayoutUI/components'

import {
  UsersReportTable,
  CustomerTable,
  ReportAllocationsForm,
} from './components'

const ReportAllocations: React.FC = (): JSX.Element => {
  const [value, setValue] = useState('1')

  const { t } = useTranslation('reportAllocations')
  const handleChange = (_, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Container>
      <HeaderTab translate="reportAllocations" id={t('header.id')} />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label={t('nav.lab3')}>
            <Tab label="users" value="1" />
            <Tab label="customers" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <ReportAllocationsForm groupBy="users" />
          <UsersReportTable />
        </TabPanel>
        <TabPanel value="2">
          <ReportAllocationsForm groupBy="customers" />
          <CustomerTable />
        </TabPanel>
      </TabContext>
    </Container>
  )
}
export default ReportAllocations
