import { Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { HeaderTab } from 'views/LayoutUI/components'

import { ReportForm } from './components'

const ReportDashboard: React.FC = (): JSX.Element => {
  const { t } = useTranslation('reportDashboard')
  return (
    <Container>
      <HeaderTab translate="reportDashboard" id={t('header.id')} />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <ReportForm typeOfReport="users" />
        <ReportForm typeOfReport="projects" />
        <ReportForm typeOfReport="customers" />
      </Box>
    </Container>
  )
}
export default ReportDashboard
