import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Button,
  IconButton,
  Link,
  Tooltip,
  Typography,
  Container,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import {
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
  EmptyArrayCard,
} from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import { IProject } from 'pages/Customers/types'
import { StyledButton } from 'components/common/SwitchButton/index.style'
import { ISelectingModal } from 'hooks/types'
import {
  useGetCustomer,
  useDeleteCustomerProject,
  useUpdateCustomerProjectBoolean,
} from 'hooks/customers'
import { HeaderTab } from 'views/LayoutUI/components'
import { PageRoutes } from 'routes'

import { CustomerForm, CustomerProjectForm } from './components'

export interface IButtons {
  title: string
  path: string | null
  icon: JSX.Element
}

const CustomerEditPage: React.FC = (): JSX.Element => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation(['customerEdit', 'customers', 'buttons'])
  const customer = useGetCustomer(params.id)
  const { updateCustomerProjectBoolean } = useUpdateCustomerProjectBoolean()
  const { deleteCustomerProject } = useDeleteCustomerProject()
  const [projectId, setProjectId] = useState(1)
  const { modalName, handleOpenModal } = useNotifications()

  const buttonClickHandler = (button: IButtons, project: IProject) => {
    button.path && navigate(button.path.replace(':id', `${project.id}`))
    if (button.title === `${t('edit', { ns: 'buttons' })}`) {
      handleOpenModal(t('modal.name1'))
      setProjectId(project.id)
    } else if (button.title === `${t('delete', { ns: 'buttons' })}`) {
      setProjectId(project.id)
      handleOpenModal(t('modal.name2'))
    }
  }

  const columns = [
    `${t('table.cell1')}`,
    `${t('table.cell2')}`,
    `${t('table.cell3')}`,
    `${t('table.cell4')}`,
    `${t('table.cell5')}`,
    `${t('table.cell6')}`,
    '',
  ]

  const buttonsArr: IButtons[] = [
    {
      title: `${t('edit', { ns: 'buttons' })}`,
      path: null,
      icon: <EditIcon />,
    },
    {
      title: t('buttons.button2'),
      path: PageRoutes.PROJECT_USERS,
      icon: <PersonIcon />,
    },
    {
      title: `${t('delete', { ns: 'buttons' })}`,
      path: null,
      icon: <DeleteIcon color="error" />,
    },
  ]
  const rows: GridRowsProp = customer?.projects?.map((project: IProject) => {
    return {
      projectCode: project.code,
      projectName: (
        <Link
          sx={{ textDecoration: 'none', textAlign: 'left' }}
          variant="body2"
          component="button"
          onClick={() => navigate(`/projects/${project.id}`)}
        >
          {project.name}
        </Link>
      ),
      startDate: format(new Date(project.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(project.endDate), 'yyyy-MM-dd'),
      isBillable: (
        <StyledButton
          valueistrue={
            project.isBillable === null ? 'null' : project.isBillable.toString()
          }
          onClick={() => {
            updateCustomerProjectBoolean({
              id: project.id,
              type: 'isBillable',
              isBillable:
                project.isBillable === null ? true : !project.isBillable,
            })
          }}
        >
          {project.isBillable === null ? (
            t('table.button3')
          ) : project.isBillable ? (
            <ThumbUpIcon color="success" />
          ) : (
            <ThumbDownIcon color="error" />
          )}
        </StyledButton>
      ),
      isProductive: (
        <StyledButton
          valueistrue={
            project.isProductive === null
              ? 'null'
              : project.isProductive.toString()
          }
          onClick={() => {
            updateCustomerProjectBoolean({
              id: project.id,
              type: 'isProductive',
              isProductive:
                project.isProductive === null ? true : !project.isProductive,
            })
          }}
        >
          {project.isProductive === null ? (
            t('table.button3')
          ) : project.isProductive ? (
            <ThumbUpIcon color="success" />
          ) : (
            <ThumbDownIcon color="error" />
          )}
        </StyledButton>
      ),
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map((button) => (
            <Tooltip title={button.title} key={button.title}>
              <IconButton onClick={() => buttonClickHandler(button, project)}>
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  })

  const deleteContent = () => {
    return <Typography>{t('deleteProject.title1')}</Typography>
  }

  const selectingModal = (): ISelectingModal => {
    const project = customer?.projects?.find(
      (project: IProject) => project.id === projectId
    )
    switch (modalName) {
      case t('modal.name1'):
        return {
          component: (
            <CustomerProjectForm
              project={project}
              type="edit"
              projectId={projectId}
            />
          ),
          title: t('modal.title2'),
        }
      case t('modal.name2'):
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteCustomerProject(projectId)}
            />
          ),
          title: t('deleteProject.modalTitle'),
        }
      case t('modal.name3'):
        return {
          component: <CustomerProjectForm />,
          title: t('modal.title1'),
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('modal.defaultModal.title')}`,
        }
    }
  }

  return (
    <Container>
      <HeaderTab
        translate="customerEdit"
        id={customer.name}
        pageLink={PageRoutes.CUSTOMERS}
      />
      <CustomerForm />
      {customer?.projects?.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard
          message={`${customer.name} ${t('typographies.typography1')}`}
        >
          <Button onClick={() => handleOpenModal(t('modal.name3'))}>
            {t('buttons.button1')}
          </Button>
        </EmptyArrayCard>
      )}
      {customer?.projects?.length ? (
        <Button
          sx={{ marginTop: '1rem' }}
          onClick={() => handleOpenModal(t('modal.name3'))}
        >
          {t('buttons.button1')}
        </Button>
      ) : null}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}

export default CustomerEditPage
