import { format } from 'date-fns'
import { useGetAllCustomers } from 'hooks/customers'
import { useGetAllProjects } from 'hooks/projects'
import { useGetAllocationsFilter } from 'hooks/reportsProductivity/useGetAllocationsFilter'
import { useState } from 'react'

export interface IUser {
  id: number
  name: string
}

export interface IInitialValue {
  value: IUser[]
  startValue: Date
}

export function useReportProductivity() {
  const [isPlanningMode, setIsPlanningMode] = useState(true)
  const customers = useGetAllCustomers()
  const projectList = useGetAllProjects()
  const { getTimeSheet, userData } = useGetAllocationsFilter()

  const initialValues: IInitialValue = {
    value: [],
    startValue: new Date(),
  }

  const submitHandler = async (values: IInitialValue) => {
    const dates = [
      `${format(values.startValue, 'yyyy')}/01`,
      `${format(values.startValue, 'yyyy')}/02`,
      `${format(values.startValue, 'yyyy')}/03`,
      `${format(values.startValue, 'yyyy')}/04`,
      `${format(values.startValue, 'yyyy')}/05`,
      `${format(values.startValue, 'yyyy')}/06`,
      `${format(values.startValue, 'yyyy')}/07`,
      `${format(values.startValue, 'yyyy')}/08`,
      `${format(values.startValue, 'yyyy')}/09`,
      `${format(values.startValue, 'yyyy')}/10`,
      `${format(values.startValue, 'yyyy')}/11`,
      `${format(values.startValue, 'yyyy')}/12`,
    ]
    getTimeSheet({
      dates: dates,
      projects: projectList.map((project) => project.id),
      customers: customers.map((customer) => customer.id),
      users: values.value.map((user) => user.id),
    })
  }

  return {
    initialValues,
    submitHandler,
    userData,
    setIsPlanningMode,
    isPlanningMode,
  }
}
