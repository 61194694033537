import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { NOTIFICATION, useNotifications } from 'components/common'

export const useReportForCustomer = () => {
  const { setNotification } = useNotifications()

  async function getDownloadCustomerReport(values) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_DOWNLOADFORCUSTOMER,
      {
        month: values.month,
        planned: values.planned,
        onlyActive: values.onlyActive,
        agreements: values.agreements.map((agreement) => agreement.id),
        selectedMonthAgreements: values.selectedMonthAgreements,
        isOverhours: values.isOverhours,
      },
      { id: values.customers.id }
    )
    return data
  }

  const handleDownloadCustomerReport = async (values) => {
    const date = format(values.month, 'yyyy-MM')
    const filename = values.customers.name + '-customer-' + date
    await getDownloadCustomerReport(values)
      .then((response) => {
        fileDownload(new Blob([response]), filename + '.xlsx')
      })
      .catch((error) =>
        setNotification({
          message: error.message,
          variant: NOTIFICATION.error,
        })
      )
  }

  return {
    handleDownloadCustomerReport,
  }
}
