import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface IAddTags {
  name: string
}

const removeTag = async (id: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.TAGS_DELETE,
    {},
    { id: id }
  )
  return data
}
const addTags = async (tags: IAddTags[] | []) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.TAGS_MULTIPLE_CREATE,
    tags.map((tag) => ({ name: tag.name }))
  )
  return data
}

export const useAllTags = () => {
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { setNotification } = useNotifications()
  const { mutate: deleteTag } = useMutation(removeTag, {
    onSuccess: () => {
      setNotification({
        message: `${t('tag')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.tags])
    },
  })
  const { mutate: addNewTags } = useMutation(addTags, {
    onSuccess: () => {
      setNotification({
        message: `${t('tags')} ${t('add2')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.tags])
    },
  })
  return { deleteTag, addNewTags }
}
