import { axiosInstance } from './axiosClient'
import { endpointRequest } from './endpointRequest'
import { HttpMethod } from './enums'

const renameUrl = (url: string, object: any) => {
  const keys = Object.keys(object)
  for (const key of keys) {
    url = url.replace(':' + key, object[key])
  }
  return url
}
export const apiRequest = async (
  callObject: number,
  data: object = {},
  urlData?: any,
  urlParams: null | string = null
) => {
  let url = endpointRequest[callObject].url
  if (urlData) {
    url = renameUrl(endpointRequest[callObject].url, urlData)
  }
  if (urlParams) {
    url += '?' + urlParams
  }
  let request: any = {}

  switch (endpointRequest[callObject].method) {
    case HttpMethod.POST:
      request = axiosInstance.post(url, data)
      break
    case HttpMethod.PUT:
      request = axiosInstance.put(url, data)
      break
    case HttpMethod.GET:
      request = axiosInstance.get(url)
      break
    case HttpMethod.DELETE:
      request = axiosInstance.delete(url)
      break
    case HttpMethod.PATCH:
      request = axiosInstance.get(url)
      break
    case HttpMethod.POST_DOWNLOAD:
      request = axiosInstance
        .post(url, data, {
          responseType: 'blob',
          validateStatus: (s) => s <= 500,
        })
        .then(async (res) => {
          if (res.status !== 200) {
            const error = JSON.parse(await res?.data?.text())
            throw new Error(error.message)
          } else {
            return res
          }
        })
      break
    case HttpMethod.GET_DOWNLOAD:
      request = axiosInstance.get(url, {
        responseType: 'blob',
      })
      break
  }

  return request
}
