import { Button, styled } from '@mui/material'
type props = {
  stateid: number | string
  selectedstate: number | string
  bcgcolor: string
  hov?: string
}

export const StyledFilterButton = styled(Button)<props>(
  ({ theme, stateid, selectedstate, bcgcolor, hov }) => ({
    backgroundColor: selectedstate === stateid ? 'white' : `${bcgcolor}`,
    color:
      selectedstate === stateid
        ? `${theme.palette.primary.main}`
        : `${theme.palette.primary.contrastText}`,
    marginRight: '1rem',
    fontWeight: selectedstate === stateid ? 600 : 'inherit',
    transition: '.2s all ease-out',
    flex: 1,
    border:
      selectedstate === stateid
        ? `1.5px solid ${theme.palette.primary.light}`
        : 'inherit',
    ':hover': {
      color:
        selectedstate === stateid
          ? `${theme.palette.primary.main}`
          : `${theme.palette.primary.contrastText}`,
      backgroundColor: selectedstate === stateid ? 'white' : `${hov}`,
      fontWeight: 600,
    },
  })
)
