import { useQuery } from 'react-query'
import { queryKeys } from 'react-query/constants'
import { AxiosResponse } from 'axios'
import { APIRoutes, apiRequest } from 'apiServices'
import { IProject } from 'pages/Customers/types'

interface ICustomer {
  code: string
  createdAt: string
  deletedAt: string | null
  id: number
  name: string
  projects: IProject[]
  revision: number
  updatedAt: string
}

export const useGetAllCustomers = () => {
  async function getAllCustomers(): Promise<ICustomer[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CUSTOMERS_GETALL,
      {},
      {},
      'limit=9999'
    )
    return data.data
  }
  const fallback: ICustomer[] = []

  const { data: customersList = fallback } = useQuery(
    queryKeys.customersLimit,
    getAllCustomers
  )
  return customersList
}
