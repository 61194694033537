import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'

export interface IPkupProjects {
  id: number
  name: string
}
export const useProjectsForPkup = () => {
  const fallback: IPkupProjects[] = []

  async function getProjectsForPkup(): Promise<IPkupProjects[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PROJECTS_GETALL_FOR_PKUP
    )
    return data
  }

  const { data: projectsForPkupList = fallback } = useQuery(
    queryKeys.projectsForPkupList,
    getProjectsForPkup
  )

  return {
    projectsForPkupList,
  }
}
