import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface IAgreement {
  startDate: Date | undefined
  endDate: Date | null
  indefinite: boolean
  id: number | undefined
}

const updateAgreementToUser = async (agreement: IAgreement) => {
  const startDate = agreement.startDate
  const endDate = agreement.endDate
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.UPDATE_AGREEMENT_TO_USER,
    {
      startDate,
      endDate,
    },
    {
      id: agreement.id,
    }
  )
  return data
}

export const useUpdateHolidayToUser = () => {
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { mutate: updateUserAgreement } = useMutation(updateAgreementToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('agreement')} ${t('update')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.user])
      void queryClient.invalidateQueries([queryKeys.userOne])
    },
  })
  return updateUserAgreement
}
