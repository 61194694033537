import { Suspense, useState } from 'react'
import { useRoutes } from 'react-router-dom'
import { PermissionAlert } from 'components/common'
import UserContext from 'contexts/user'
import { IUser } from 'hooks/types'

import { AuthLayout, LayoutUI } from './views'
import { loginRoutes, mainRoutes } from './routes'

function App() {
  const mainRoute = {
    element: <LayoutUI />,
    children: mainRoutes,
  }

  const loginRoute = {
    element: <AuthLayout />,
    children: loginRoutes,
  }
  const routing = useRoutes([mainRoute, loginRoute])

  const [user, setUser] = useState<IUser | null>(null)

  return (
    <UserContext.Provider
      value={{
        user: user,
        setUser: setUser,
      }}
    >
      <Suspense fallback="loading">
        <>
          {routing}
          <>
            <PermissionAlert />
          </>
        </>
      </Suspense>
    </UserContext.Provider>
  )
}

export default App
