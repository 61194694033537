import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'
import { IUser } from 'hooks/types'
import { useAuth } from 'hooks/useAuth'
import { isUserPermitted, permissionList } from 'services'

import { useAllInventories } from './useAllInventories'

export interface IInitialValues {
  model: string
  serialNumber: string
  producer: string
  additionalInformation: string
}

interface IInventory {
  id: number | null
  name: string
}

export interface IAddInventory extends IInitialValues {
  inventoryId: number | null
}
export const useAddInventory = (user: IUser | null) => {
  const inventories = useAllInventories()
  const { handleCloseModal, setNotification, modalName } = useNotifications()
  const [selectedInventory, setSelectedInventory] = useState<IInventory>({
    id: null,
    name: '',
  })
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const isPermitted = isUserPermitted(user, [
    permissionList.INVENTORY_EDIT_OTHER_USER,
  ])
  const allowedModifiedInventories = isPermitted
    ? inventories?.data
    : inventories?.data?.filter((inventory) => inventory.allowUserToModify)
  const inventoriesType = allowedModifiedInventories?.map((inventory) => ({
    id: inventory.id,
    name: inventory.name,
  }))
  useEffect(() => {
    if (!modalName) {
      setSelectedInventory({
        id: null,
        name: '',
      })
    }
  }, [modalName])

  const initialValues = {
    model: '',
    serialNumber: '',
    producer: '',
    additionalInformation: '',
    name: '',
  }

  const addInventoryToUser = async (inventory: IAddInventory) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.INVENTORIES_ADD_TO_USER,
      { ...inventory, userId: user?.id }
    )
    return data
  }

  const { mutate: addNewInventory } = useMutation(addInventoryToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('inventory')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
      queryClient.invalidateQueries([queryKeys.inventories])
      queryClient.invalidateQueries([queryKeys.userOne])
    },
  })

  return {
    inventories,
    inventoriesType,
    addNewInventory,
    initialValues,
    selectedInventory,
    setSelectedInventory,
  }
}
