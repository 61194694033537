const palette = {
  primary: {
    light: '#767dc4',
    medium: '#535dc6',
    main: '#1a3d92',
    contrastText: '#fff',
    dark: '#012479',
    white: '#fff',
    oragne: '#d18e1b',
  },
  secondary: {
    light: '#fff',
    main: '#f3f6ff',
    contrastText: '#1a202c',
    dark: '#e2e8f0',
  },
  textSecondary: {
    main: '#111',
    light: '',
  },
  text: {
    secondary: '#808080',
  },
  action: {
    hover: '#e2e8f0',
  },
  error: {
    main: '#ff4961',
    dark: 'rgba(217, 0, 0, 1)',
  },
  warning: {
    main: '#ff9149',
    light: '#ffc277',
    dark: '#c7621a',
  },
  info: {
    main: '#ffeccb',
    contrastText: '#1a3d92',
  },
  success: {
    main: '#28d094',
    dark: '#10AA08',
  },
}

export default palette
