import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridRowsProp } from '@mui/x-data-grid'
import { HeaderTab } from 'views/LayoutUI/components'
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Container,
  Fade,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import KeyIcon from '@mui/icons-material/Key'
import DeleteIcon from '@mui/icons-material/Delete'
import { DefaultModal } from 'views/LayoutUI/modals'
import {
  InfoSnackbar,
  NOTIFICATION,
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
  EmptyArrayCard,
} from 'components/common'
import { useGetGroups } from 'hooks/groups'
import { ISelectingModal } from 'hooks/types'
import { timeoutAnimation } from 'utils'

import { IGroups, IconButtonArray } from './types'
import { AddNewGroup, ManagePermissions } from './modals'
import { useGroup } from './utils'
import ManageGroupPermissions from './modals/ManageGroupPermissions'
import EditGroup from './modals/EditGroup'

const Groups: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['groups', 'buttons', 'handleMessage'])
  const { groupsList } = useGetGroups()
  const [selectGroup, setSelectGroup] = useState<IGroups | null>(null)
  const { setNotification, modalName, handleOpenModal } = useNotifications()
  const deleteGroup = useGroup()

  const deleteContent = () => {
    return (
      <Box>
        <Typography>{t('modal.deleteGroup.text1')}</Typography>
        <Typography>
          {t('modal.deleteGroup.text2')} {selectGroup?.name}
        </Typography>
        <Typography>
          {t('modal.deleteGroup.text3')} {selectGroup?.code}
        </Typography>
      </Box>
    )
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addNewGroup':
        return {
          component: <AddNewGroup />,
          title: `${t('modal.addNewGroup.title')}`,
        }
      case 'managePermissions':
        return {
          component: <ManagePermissions />,
          title: `${t('modal.managePermissions.title')}`,
        }
      case 'edit':
        return {
          component: <EditGroup group={selectGroup} />,
          title: `${t('modal.editGroup.title')}`,
        }
      case 'manage':
        return {
          component: <ManageGroupPermissions group={selectGroup} />,
          title: `${t('modal.managePermissionsGroup.title')} ${
            selectGroup?.name
          }`,
        }
      case 'deleteGroup':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteGroup(selectGroup?.id)}
            />
          ),
          title: t('modal.deleteGroup.title'),
        }
      default:
        return { component: <DefaultModal />, title: 'Default modal' }
    }
  }
  const columns = ['#', `${t('table.cell1')}`, `${t('table.cell2')}`, '']
  const buttonsArr: IconButtonArray[] = [
    {
      title: `${t('edit', { ns: 'buttons' })}`,
      icon: <EditIcon />,
      modal: 'edit',
    },
    {
      title: `${t('table.tooltip2')} (${selectGroup?.permissions?.length})`,
      icon: <KeyIcon />,
      modal: 'manage',
      isMouseOver: true,
    },
    {
      title: `${t('delete', { ns: 'buttons' })}`,
      icon: <DeleteIcon color="error" />,
      modal: 'deleteGroup',
    },
  ]
  const handleDelete = () => {
    setNotification({
      message: `${t('item', { ns: 'handleMessage' })} ${t('delete', {
        ns: 'handleMessage',
      })}`,
      variant: NOTIFICATION.warning,
    })
  }
  const handleClick = (modal: string) => {
    modal === 'delete' ? handleDelete() : handleOpenModal(modal)
  }
  const rows: GridRowsProp = groupsList?.map((group) => {
    return {
      id: group.id,
      name: group.name,
      code: group.code,
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map(({ title, icon, modal, isMouseOver }) => (
            <Tooltip
              title={title}
              key={title}
              onMouseOver={() => (isMouseOver ? setSelectGroup(group) : null)}
            >
              <IconButton
                onClick={() => {
                  setSelectGroup(group)
                  handleClick(modal)
                }}
              >
                {icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  })
  return (
    <Container>
      <HeaderTab translate="groups" />
      <Button
        sx={{ marginRight: '1rem' }}
        onClick={() => handleOpenModal('addNewGroup')}
      >
        {t('buttonGroup.button1')}
      </Button>
      <Button onClick={() => handleOpenModal('managePermissions')}>
        {t('buttonGroup.button2')}
      </Button>
      {rows.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent columns={columns} rows={rows} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
      <InfoSnackbar />
    </Container>
  )
}

export default Groups
