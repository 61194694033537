import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

const deleteSingleUser = async (id: number | null | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PROJECTS_DELETEUSER,
    {},
    { id }
  )
  return data
}
export const useDeleteUser = () => {
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const { mutate: deleteUser } = useMutation(deleteSingleUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('user')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
      queryClient.invalidateQueries([queryKeys.customer])
    },
  })
  return { deleteUser }
}
