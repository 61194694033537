import { GridRowsProp } from '@mui/x-data-grid'
import { Box, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TableComponent, AllocationBadge } from 'components/common'
import { IAllocations } from 'hooks/types'
import AddAlarmIcon from '@mui/icons-material/AddAlarm'

interface ICurrentProject {
  month: string
  fte: number
  hours: JSX.Element
}

const ShowAllocations = ({
  allocations,
}: {
  allocations?: IAllocations[]
}): JSX.Element => {
  const { t } = useTranslation(['dashboard', 'userEditPage'])

  const currentProject = allocations?.map((project: IAllocations) => {
    const currentAllocations = [
      {
        hours: project.allUsedPlannedHours || 0,
        tooltip: t('allocations.tooltips.plannedHours', { ns: 'userEditPage' }),
        color: 'primary.dark',
      },
      {
        hours: project.allUsedWorkingHours || 0,
        tooltip: t('allocations.tooltips.workedHours', { ns: 'userEditPage' }),
        color: 'primary.main',
      },
      {
        hours: project.allPlannedHours || 0,
        tooltip: t('allocations.tooltips.allHours', {
          ns: 'userEditPage',
        }),
        color: 'primary.medium',
      },
    ]

    return {
      month: project.date,
      fte: project.FTE,
      hours: <AllocationBadge details={currentAllocations} />,
      isOverhours: project.isOverhours ? (
        <Box display="flex" justifyContent="center">
          <Tooltip title={t('modal.allAllocations.tooltip') as string}>
            <AddAlarmIcon color="disabled" />
          </Tooltip>
        </Box>
      ) : (
        ''
      ),
    }
  }) as ICurrentProject[]
  const columns = [
    `${t('modal.allAllocations.columns.month')}`,
    `${t('modal.allAllocations.columns.fte')}`,
    `${t('modal.allAllocations.columns.planned')}`,
    '',
  ]
  const rows: GridRowsProp = currentProject
  return (
    <Box sx={{ margin: '0 5px 5px 1px' }}>
      <TableComponent columns={columns} rows={rows} />
    </Box>
  )
}

export default ShowAllocations
