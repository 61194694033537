import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PageRoutes } from 'routes'

import { IHeaderTabProps } from '../types'
import Head from '../Head'

import { HeaderNav, StyledLink } from './index.style'

const HeaderTab: React.FC<IHeaderTabProps> = ({
  translate,
  id,
  headTitle,
  pageLink,
  subPage,
}): JSX.Element => {
  const { t } = useTranslation(translate)
  const navigate = useNavigate()

  return (
    <Box display="flex" alignItems="center" my={3} ml={2}>
      <Head title={headTitle ? headTitle : t('header.title')} />
      <Typography variant="h1">{t('header.title')}</Typography>
      <HeaderNav
        aria-label="breadcrumb"
        sx={{ boxShadow: '0px 0px 7px -4px rgba(66, 68, 90, 1)' }}
      >
        <StyledLink onClick={() => navigate(PageRoutes.HOME)}>
          {t('header.home')}
        </StyledLink>
        {pageLink ? (
          subPage ? (
            <Box display="flex">
              <Typography sx={{ textIndent: '0.5rem', paddingRight: '8px' }}>
                {t('header.tab')}
              </Typography>
              /
              <StyledLink color="inherit" onClick={() => navigate(pageLink)}>
                {subPage}
              </StyledLink>
            </Box>
          ) : (
            <StyledLink onClick={() => navigate(pageLink)}>
              {t('header.tab')}
            </StyledLink>
          )
        ) : (
          <Typography variant="h3">{t('header.tab')}</Typography>
        )}
        {id && id !== 'undefined undefined' && (
          <Typography variant="h3">{id}</Typography>
        )}
      </HeaderNav>
    </Box>
  )
}
export default HeaderTab
