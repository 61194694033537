import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  TextField,
} from '@mui/material'
import { EmptyArrayCard, TableTitle } from 'components/common'
import { useGetCustomerProject } from 'hooks/customers'
import { useUpdateProjectDetails } from 'hooks/projectDetails'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const DetailsAndDesc = () => {
  const { t } = useTranslation(['projectDetails', 'buttons'])
  const params = useParams()
  const customerProject = useGetCustomerProject(params.id)
  const { updateProjectDetails } = useUpdateProjectDetails()
  const [isDescription, setIsDescription] = useState(false)
  const [desc, setDesc] = useState(customerProject?.description)

  const { description } = customerProject
  const detailsArr = [
    {
      name: t('typographies.typography6'),
      text: customerProject?.startDate?.slice(0, 10),
    },
    {
      name: t('typographies.typography7'),
      text: customerProject?.endDate?.slice(0, 10),
    },
    {
      name: t('typographies.typography8'),
      text: customerProject?.customer?.name,
    },
  ]
  useEffect(() => {
    setDesc(description)
  }, [description])
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Paper
          sx={{ padding: '0.5rem 1.25rem', minHeight: '7rem' }}
          elevation={3}
        >
          <TableTitle> {t('typographies.typography3')}</TableTitle>
          <Stack direction={'row'} justifyContent="space-between" mt={1}>
            {detailsArr.map(({ name, text }) => (
              <Box
                key={name}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  minHeight: '3.5rem',
                }}
              >
                <b>{name}:</b>
                <Typography variant="subtitle2">{text}</Typography>
              </Box>
            ))}
          </Stack>
        </Paper>
      </Grid>
      <Grid item md={6} xs={12}>
        <Paper sx={{ padding: '0.5rem 1.25rem' }} elevation={3}>
          <TableTitle>
            {t('typographies.typography4')}
            {!isDescription ? (
              <Button onClick={() => setIsDescription((prev) => !prev)}>
                {t('edit', { ns: 'buttons' })}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setIsDescription((prev) => !prev)
                  updateProjectDetails({
                    id: customerProject.id.toString(),
                    description: desc,
                  })
                }}
              >
                {t('save', { ns: 'buttons' })}
              </Button>
            )}
          </TableTitle>
          {isDescription ? (
            <TextField
              fullWidth
              sx={{ margin: '1.25rem 0px' }}
              id="outlined-basic"
              multiline={true}
              variant="outlined"
              value={desc || ''}
              onChange={(e) => setDesc(e.target.value)}
            />
          ) : desc ? (
            <Typography
              sx={{
                marginTop: 1,
                wordWrap: 'break-word',
                minHeight: '3.5rem',
              }}
            >
              {customerProject.description}
            </Typography>
          ) : (
            <EmptyArrayCard
              message={`${t('typographies.typography11')}`}
              styles={{ marginBottom: 0, marginTop: 1 }}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DetailsAndDesc
