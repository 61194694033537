import React from 'react'
import { useTranslation } from 'react-i18next'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { useAuth } from 'hooks/useAuth'
import { isUserPermitted, permissionList } from 'services'

import { useUpdateWorkTypes } from '../../../pages/System/pages/WorkTypes/hooks'

import { StyledButton } from './index.style'

interface IProps {
  id: number
  option: boolean | null
  name: string
}

const SwitchButton: React.FC<IProps> = ({ id, option, name }) => {
  const { t } = useTranslation(['systemWorkTypes', 'buttons'])
  const { updateWorkTypes } = useUpdateWorkTypes(id)
  const { user } = useAuth()
  return (
    <StyledButton
      disabled={!isUserPermitted(user, [permissionList.WORKTYPE_EDIT])}
      valueistrue={option === null ? 'null' : option.toString()}
      onClick={() => {
        updateWorkTypes({ [`${name}`]: option === null ? true : !option })
      }}
    >
      {option === null ? (
        t('table.button3')
      ) : option ? (
        <ThumbUpIcon color="success" />
      ) : (
        <ThumbDownIcon color="error" />
      )}
    </StyledButton>
  )
}

export default SwitchButton
