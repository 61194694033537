import { GridColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

interface IColumn {
  field: string
  width: number
}

const ColumnsReportWorkdays = () => {
  const { t } = useTranslation('reportWorkdays')
  const month = [
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
    'X',
    'XI',
    'XII',
  ]

  let monthColumn: IColumn[] = []
  for (let i = 0; i < month.length; i++) {
    const column = {
      field: month[i],
      width: 55,
    }
    monthColumn = [...monthColumn, column]
  }
  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: t('table.fullName'),
      width: 200,
    },
    {
      field: 'worktype',
      headerName: t('table.workType'),
      width: 350,
    },
    ...monthColumn,
  ]

  return columns
}

export default ColumnsReportWorkdays
