import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material'
import CommentIcon from '@mui/icons-material/Comment'
import WorkIcon from '@mui/icons-material/Work'
import {
  DownloadButton,
  TableComponent,
  TableTitle,
  useNotifications,
} from 'components/common'
import { StyledFilterButton } from 'pages/PkupsDashboard/index.style'
import { useData, useVacationRequests } from 'pages/VacationRequest/hooks'
import {
  IVacationRequest,
  StatusEnum,
} from 'pages/VacationRequest/hooks/useVacationRequests'
import { lightenDarkenColor } from 'utils/lightenDarkenColors'
import { orderIcon } from 'utils'
import { useAllUsers } from 'hooks/useAllUsers'
import { IUsers } from 'hooks/types'
import { useTablePagedFunction } from 'hooks/table'
import {
  getHolidayUnitFromHolidayCategory,
  getHolidayUnitWithUnitType,
} from 'pages/Holidays/utils'

interface FutureRequestsProps {
  setSelectedRequest: Dispatch<SetStateAction<IVacationRequest | undefined>>
  tableTitle: string
}
const UsersRequests: React.FC<FutureRequestsProps> = ({
  setSelectedRequest,
  tableTitle,
}): JSX.Element => {
  const { t } = useTranslation(['vacationRequests', 'buttons'])
  const { handleOpenModal } = useNotifications()
  const allUsers = useAllUsers()
  const [selectedState, setSelectedState] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<IUsers | null>(null)
  const { vacationRequestsList, changePagination, paginationUc } =
    useVacationRequests(selectedUser?.id, tableTitle, selectedState)
  const { sortBy, handleChangePage, paginationUC, handleChangeRowsPerPage } =
    useTablePagedFunction(changePagination)
  const ButtonStyle = {
    fontWeight: 550,
    textTransform: 'capitalize',
    fontSize: '14px',
  }
  const { getStatusColor, states } = useData()
  const columns = [
    <>
      <Button
        onClick={() => sortBy('id', 'ASC')}
        variant="text"
        sx={ButtonStyle}
      >
        #{orderIcon(paginationUc?.order, 'id')}
      </Button>
    </>,
    t('panel2.requestsTable.name'),
    t('panel2.requestsTable.workType'),
    t('panel2.requestsTable.vacationType'),
    <>
      <Button
        onClick={() => sortBy('startDate', 'ASC')}
        variant="text"
        sx={ButtonStyle}
      >
        {t('panel2.requestsTable.startDate')}
        {orderIcon(paginationUc?.order, 'startDate')}
      </Button>
    </>,
    <>
      <Button
        onClick={() => sortBy('endDate', 'ASC')}
        variant="text"
        sx={ButtonStyle}
      >
        {t('panel2.requestsTable.endDate')}
        {orderIcon(paginationUc?.order, 'endDate')}
      </Button>
    </>,
    <>
      <div style={{ minWidth: 55 }}>{t('panel2.requestsTable.units')}</div>
    </>,
    <>
      <Button
        onClick={() => sortBy('requestDate', 'ASC')}
        variant="text"
        sx={ButtonStyle}
      >
        {t('panel2.requestsTable.requestDate')}
        {orderIcon(paginationUc?.order, 'requestDate')}
      </Button>
    </>,
    t('panel2.requestsTable.comment'),
    t('panel2.requestsTable.status'),
    '',
  ]

  const rows = vacationRequestsList?.data?.map((vacation) => {
    return {
      id: vacation.id,
      name: vacation.firstName + ' ' + vacation.lastName,
      workType: vacation.workTypeName,
      type: vacation.vacationType,
      startDate: format(new Date(vacation.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(vacation.endDate), 'yyyy-MM-dd'),
      units: getHolidayUnitWithUnitType(vacation),
      date: format(new Date(vacation.requestDate), 'yyyy-MM-dd'),
      comment: vacation.comment ? (
        <Tooltip title={vacation.comment}>
          <IconButton color="info">
            <CommentIcon />
          </IconButton>
        </Tooltip>
      ) : (
        '--'
      ),
      status: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: getStatusColor(vacation.status),
          }}
        >
          {vacation.status}
          {vacation.approverComment && (
            <Tooltip title={vacation.approverComment as string}>
              <IconButton color="info">
                <CommentIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
      button: (
        <>
          <Tooltip title={`${t('panel2.requestsTable.info')}`}>
            <IconButton
              onClick={() => {
                setSelectedRequest(vacation)
                handleOpenModal('info')
              }}
            >
              <WorkIcon />
            </IconButton>
          </Tooltip>
          {vacation.status === StatusEnum.approved &&
            vacation.holidayCategoryId && (
              <DownloadButton
                setSelected={setSelectedRequest}
                vacation={vacation}
              />
            )}
        </>
      ),
    }
  })

  return (
    <Paper sx={{ padding: '1rem' }}>
      <TableTitle>
        {tableTitle === 'previous'
          ? t('panel2.previousRequests')
          : t('panel2.futureRequests')}
      </TableTitle>
      <Autocomplete
        sx={{ width: '40%', maxWidth: '35rem', marginRight: '1rem' }}
        id="tags-standard"
        filterSelectedOptions
        options={allUsers}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        onChange={(_, value) => {
          setSelectedUser(value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            label={t('panel2.selectUser')}
          />
        )}
      />
      {states.map((state) => (
        <StyledFilterButton
          key={state.id}
          onClick={() => setSelectedState(state.text)}
          stateid={state.text}
          selectedstate={selectedState}
          bcgcolor={state.color}
          hov={lightenDarkenColor(state.color, -30)}
        >
          {state.text}
        </StyledFilterButton>
      ))}
      <TableComponent
        rows={rows}
        columns={columns}
        total={vacationRequestsList?.total}
        pagination={true}
        changePagination={changePagination}
        handleChangePage={handleChangePage}
        paginationUC={paginationUC}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
export default UsersRequests
