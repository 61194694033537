import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Container, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { HeaderTab } from 'views/LayoutUI/components'
import { PageRoutes } from 'routes'

import { useUser } from '../utlis'

import { GenerateCV, UserCV } from './components'

const CV: React.FC = (): JSX.Element => {
  const { t } = useTranslation('userCV')
  const [value, setValue] = useState('1')
  const { id } = useParams()
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const { user } = useUser()

  return (
    <Container>
      <HeaderTab
        translate="userCV"
        id={`${t('cv.title')} ${user.email}`}
        pageLink={PageRoutes.USERS}
      />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={t('cv.tab1')} value="1" />
            <Tab label={t('cv.tab2')} value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <UserCV userId={Number(id)} />
        </TabPanel>
        <TabPanel value="2">
          <GenerateCV />
        </TabPanel>
      </TabContext>
    </Container>
  )
}
export default CV
