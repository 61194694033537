import { Tooltip, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useTranslation } from 'react-i18next'

export const columns = (): (string | JSX.Element)[] => {
  const { t } = useTranslation('userEditPage')
  const columns = [
    `${t('allocations.table.col1')}`,
    `${t('allocations.table.col2')}`,
    `${t('allocations.table.col3')}`,
    <Tooltip key={1} title={t('allocations.table.tooltip') as string}>
      <Typography sx={{ fontWeight: '550', fontSize: '0.875rem' }}>
        {t('allocations.table.col4.text1')}
        <HelpOutlineIcon fontSize="small" />
        {t('allocations.table.col4.text2')}
      </Typography>
    </Tooltip>,
  ]
  return columns
}
