import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

import { IGroupDate } from '../../types'

const updateGroup = async (groupData: IGroupDate) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.GROUPS_UPDATE,
    { code: groupData.groupCode, name: groupData.groupName },
    { id: groupData.id }
  )
  return data
}

export const useGroupEdit = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()

  const { t } = useTranslation('handleMessage')
  const { mutate: editGroup } = useMutation(updateGroup, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('group')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.groups])
    },
  })

  return editGroup
}
