import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { IPaginateReducer, defaultPaginationValue } from 'hooks/table'

import { IHistory } from './types'

const getUserHistory = async (
  id: number,
  params: string
): Promise<IHistory> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.USERS_GETONE_HISTORY,
    {},
    { id: id },
    `${params}`
  )
  return data
}

export const useGetHistory = () => {
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>(
    defaultPaginationValue
  )
  const fallback = <IHistory>{}
  const { id } = useParams()
  const params = `page=${paginationUc?.currentPage + 1 || 1}&limit=${
    paginationUc?.length || 10
  }`

  const { data: userGetOneHistory = fallback, refetch } = useQuery(
    queryKeys.history,
    () => getUserHistory(Number(id), params)
  )

  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }

  const userHistoryRows = userGetOneHistory?.data?.map((history) => {
    const historyChanges = history.changes
      .map((change) => {
        const hasChanges = change.value ? `to '${change.value}'` : ''
        return `${change.field} ${hasChanges}`
      })
      .join(', ')
    return {
      action: history.action,
      data: historyChanges,
      date: history.date,
      author: `${history.author.firstName} ${history.author.lastName}`,
    }
  })
  const total = userGetOneHistory.total

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])
  useEffect(() => {
    refetch()
  }, [paginationUc])

  return { total, changePagination, paginationUc, userHistoryRows }
}
