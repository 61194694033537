import { Select } from '@mui/material'

const CustomizedSelect = ({
  children,
  form,
  field,
  label,
  fieldValueToChange,
  changeToValue,
  multiple,
}: {
  children: string
  form: { setFieldValue: (name: string, value: string) => void }
  field: { name: string; value: string; id: string }
  label?: string
  fieldValueToChange?: string
  changeToValue?: any
  multiple?: boolean
}) => {
  const { name, value } = field
  const { setFieldValue } = form

  return (
    <Select
      labelId={label}
      label={label}
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value)
        fieldValueToChange
          ? setFieldValue(fieldValueToChange, changeToValue)
          : null
      }}
      multiple={multiple}
    >
      {children}
    </Select>
  )
}
export default CustomizedSelect
