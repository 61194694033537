import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

const deleteSingleTimeframe = async (id: number | null | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PROJECTS_DELETETIMEFRAMES,
    {},
    { timeframeId: id }
  )
  return data
}
export const useDeleteTimeframes = () => {
  const { setNotification } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const { mutate: deleteTimeframe } = useMutation(deleteSingleTimeframe, {
    onSuccess: () => {
      setNotification({
        message: `${t('timeframe')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.user])
      void queryClient.invalidateQueries([queryKeys.customer])
      void queryClient.refetchQueries([queryKeys.customer])
      void queryClient.invalidateQueries([queryKeys.timeFrames])
      void queryClient.invalidateQueries([queryKeys.userOne])
    },
  })
  return { deleteTimeframe }
}
