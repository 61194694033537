import i18next, { use as i18nextUse } from 'i18next'
import { initReactI18next } from 'react-i18next'

import buttons_en from './translations/en/buttons.json'
import calendar_en from './translations/en/calendar.json'
import customerAdd_en from './translations/en/customerAdd.json'
import customerEdit_en from './translations/en/customerEdit.json'
import customers_en from './translations/en/customers.json'
import dashboard_en from './translations/en/dashboard.json'
import groups_en from './translations/en/groups.json'
import forgotPassword_en from './translations/en/forgotPassword.json'
import holidays_en from './translations/en/holidays.json'
import hrDashboard_en from './translations/en/hrDashboard.json'
import inventory_en from './translations/en/inventory.json'
import handleMessage_en from './translations/en/handleMessage.json'
import permissionAlert_en from './translations/en/permissionAlert.json'
import pkup_en from './translations/en/pkup.json'
import pkupsDashboard_en from './translations/en/pkupsDashboard.json'
import pmDashboard_en from './translations/en/pmDashboard.json'
import projectDetails_en from './translations/en/projectDetails.json'
import projectManageUser_en from './translations/en/projectManageUser.json'
import projects_en from './translations/en/projects.json'
import profile_en from './translations/en/profile.json'
import reactions_en from './translations/en/reactions.json'
import reportAllocations_en from './translations/en/reportAllocations.json'
import reportAgreement_en from './translations/en/reportAgreement.json'
import remindPassword_en from './translations/en/remindPassword.json'
import reportCustomers_en from './translations/en/reportCustomers.json'
import reportDashboard_en from './translations/en/reportDashboard.json'
import reportPkup_en from './translations/en/reportPkup.json'
import reportProductivity_en from './translations/en/reportProductivity.json'
import reportWorkdays_en from './translations/en/reportWorkdays.json'
import sidebar_en from './translations/en/sidebar.json'
import signIn_en from './translations/en/signIn.json'
import systemBusinessUnits_en from './translations/en/systemBusinessUnits.json'
import systemBusinessUnitsUsers_en from './translations/en/systemBusinessUnitsUsers.json'
import systemHolidaysCategories_en from './translations/en/systemHolidaysCategories.json'
import systemLocations_en from './translations/en/systemLocations.json'
import systemLocationsUsers_en from './translations/en/systemLocationsUsers.json'
import systemLogActivity_en from './translations/en/systemLogActivity.json'
import systemTags_en from './translations/en/systemTags.json'
import systemWorkTypes_en from './translations/en/systemWorkTypes.json'
import userAllocations_en from './translations/en/userAllocations.json'
import userEditPage_en from './translations/en/userEditPage.json'
import userCV_en from './translations/en/userCV.json'
import userNotes_en from './translations/en/userNotes.json'
import users_en from './translations/en/users.json'
import vacationRequests_en from './translations/en/vacationRequests.json'
import validation_en from './translations/en/validation.json'

i18nextUse(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      buttons: buttons_en,
      calendar: calendar_en,
      customerAdd: customerAdd_en,
      customerEdit: customerEdit_en,
      customers: customers_en,
      dashboard: dashboard_en,
      forgotPassword: forgotPassword_en,
      groups: groups_en,
      handleMessage: handleMessage_en,
      holidays: holidays_en,
      hrDashboard: hrDashboard_en,
      inventory: inventory_en,
      permissionAlert: permissionAlert_en,
      pkupsDashboard: pkupsDashboard_en,
      pkup: pkup_en,
      pmDashboard: pmDashboard_en,
      profile: profile_en,
      projects: projects_en,
      projectDetails: projectDetails_en,
      projectManageUser: projectManageUser_en,
      reactions: reactions_en,
      reportAllocations: reportAllocations_en,
      reportAgreement: reportAgreement_en,
      reportCustomers: reportCustomers_en,
      reportDashboard: reportDashboard_en,
      reportPkup: reportPkup_en,
      reportProductivity: reportProductivity_en,
      reportWorkdays: reportWorkdays_en,
      remindPassword: remindPassword_en,
      sidebar: sidebar_en,
      signIn: signIn_en,
      systemBusinessUnits: systemBusinessUnits_en,
      systemBusinessUnitsUsers: systemBusinessUnitsUsers_en,
      systemHolidaysCategories: systemHolidaysCategories_en,
      systemLocations: systemLocations_en,
      systemLocationsUsers: systemLocationsUsers_en,
      systemLogActivity: systemLogActivity_en,
      systemTags: systemTags_en,
      systemWorkTypes: systemWorkTypes_en,
      userAllocations: userAllocations_en,
      userEditPage: userEditPage_en,
      userCV: userCV_en,
      userNotes: userNotes_en,
      users: users_en,
      vacationRequests: vacationRequests_en,
      validation: validation_en,
    },
  },
})

export default i18next
