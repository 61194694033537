import { useState } from 'react'
import { Box, Button, Tab, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { HeaderTab } from 'views/LayoutUI/components'
import { DefaultModal } from 'views/LayoutUI/modals'
import {
  ModalComponent,
  ModalConfirm,
  useNotifications,
} from 'components/common'
import { useGetOneUser } from 'hooks/user'
import { IAllocations, ISelectingModal, ValueType } from 'hooks/types'
import { useAuth } from 'hooks/useAuth'
import { permissionList, isUserPermitted } from 'services'
import { PageRoutes } from 'routes'

import { getProjectName } from './components/utils'
import {
  HistoryTimeFrames,
  AddNewTimeFrames,
  EditAllocation,
  AddNewAllocation,
} from './modals'
import {
  TimeFrames,
  CurrentAllocations,
  PreviousAllocations,
  FutureAllocations,
} from './components'
import { useUserAllocations } from './utils'

const UserAllocations: React.FC = (): JSX.Element => {
  const [value, setValue] = useState<ValueType>('1')
  const [editAllocation, setEditAllocation] = useState<IAllocations | null>(
    null
  )
  const { modalName, handleOpenModal } = useNotifications()
  const oneUser = useGetOneUser()
  const { user } = useAuth()
  const { t } = useTranslation(['userAllocations', 'inventory'])
  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const { deleteUserAllocation } = useUserAllocations()
  const [timeFrames, setTimeFrames] = useState<number | null>(null)
  const project = user?.projects?.find((project) => project.id === timeFrames)
  const deleteContent = () => {
    return (
      <Box>
        <Box my={1}>
          <Typography>{t('modals.deleteAllocation.text1')}</Typography>
        </Box>
        <Box my={1}>
          <Typography>
            {t('modals.deleteAllocation.text2')}: {oneUser.firstName}{' '}
            {oneUser.lastName}
          </Typography>
          <Typography>
            {t('modals.deleteAllocation.text3')}:{' '}
            {getProjectName(editAllocation, oneUser)}
          </Typography>
        </Box>
        <Box my={1}>
          <Typography color="error">
            {t('modals.deleteAllocation.text4')}
          </Typography>
        </Box>
      </Box>
    )
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'historyTimeFrames':
        return {
          component: <HistoryTimeFrames timeFrames={timeFrames} />,
          title: `${t('modals.historyTimeFrames.title')} ${oneUser.firstName} ${
            oneUser.lastName
          } - ${project?.name}`,
        }
      case 'addTimeframes':
        return {
          component: <AddNewTimeFrames user={oneUser} />,
          title: t('modals.addTimeFrame.title'),
        }
      case 'editAllocation':
        return {
          component: (
            <EditAllocation user={oneUser} editAllocation={editAllocation} />
          ),
          title: t('modals.editAllocation.title'),
        }
      case 'addNewAllocation':
        return {
          component: <AddNewAllocation user={oneUser} />,
          title: t('modals.editAllocation.title2'),
        }
      case 'deleteAllocation':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteUserAllocation(editAllocation?.id)}
              buttonName={t('modals.deleteAllocation.button')}
              btnColor="error"
            />
          ),
          title: t('modals.deleteAllocation.title'),
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('modal.defaultModal.title')}`,
        }
    }
  }
  return (
    <>
      <Box sx={{ margin: '1rem 1.5rem' }}>
        <HeaderTab
          translate="userAllocations"
          id={`${t('header.id')} ${oneUser.email}`}
          pageLink={PageRoutes.USERS}
        />
        {isUserPermitted(user, [permissionList.ALLOCATION_CREATE]) && (
          <Button onClick={() => handleOpenModal('addNewAllocation')}>
            {t('tabPanel.addAllBtn')}
          </Button>
        )}
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('tabPanel.tab1')} value="1" />
              <Tab label={t('tabPanel.tab2')} value="2" />
              <Tab label={t('tabPanel.tab3')} value="3" />
              <Tab label={t('tabPanel.tab4')} value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <CurrentAllocations
              user={oneUser}
              setEditAllocation={setEditAllocation}
            />
          </TabPanel>
          <TabPanel value="2">
            <PreviousAllocations
              user={oneUser}
              setEditAllocation={setEditAllocation}
            />
          </TabPanel>
          <TabPanel value="3">
            <FutureAllocations
              user={oneUser}
              setEditAllocation={setEditAllocation}
            />
          </TabPanel>
          <TabPanel value="4">
            <TimeFrames setTimeFrame={setTimeFrames} />
          </TabPanel>
        </TabContext>
      </Box>
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </>
  )
}

export default UserAllocations
