import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface IUpdateHoliday {
  days: number
  year: string
  holidaysCategoryId: number
  id: number
}

const updateHolidayToUser = async (holiday: IUpdateHoliday) => {
  const id = holiday.id
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.UPDATE_HOLIDAY_CATEGORY_USER,
    {
      holidaysCategoryId: holiday.holidaysCategoryId,
      days: holiday.days,
      year: holiday.year,
    },
    { id }
  )
  return data
}

export const useUpdateHolidayToUser = () => {
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { mutate: updateHoliday } = useMutation(updateHolidayToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('holiday')} ${t('update')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.user])
      void queryClient.invalidateQueries([queryKeys.userOne])
    },
  })
  return updateHoliday
}
