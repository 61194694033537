import { useQuery } from 'react-query'
import { queryKeys } from 'react-query/constants'
import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { APIRoutes, apiRequest } from 'apiServices'
import { IPaginateReducer, defaultPaginationValue } from 'hooks/table'
import { useNavigate } from 'react-router-dom'

import { IData } from './types'

export const useGetProjects = () => {
  const navigate = useNavigate()
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>(
    defaultPaginationValue
  )
  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }

  useEffect(() => {
    navigate(`?${params}`)
  }, [paginationUc])
  const filters = paginationUc?.filters
    ? `&filters=${paginationUc?.filters}`
    : ''
  const searchInput = paginationUc?.search
    ? `&search=${paginationUc?.search}`
    : ''
  const orderBy = paginationUc?.order
    ? `&order={"order":"${paginationUc.order.order}","column":"${paginationUc.order.column}"}`
    : ''

  const params = `page=${paginationUc?.currentPage + 1 || 1}&limit=${
    paginationUc?.length || 10
  }${filters}${searchInput}${orderBy}`

  async function getProjectsPaged(): Promise<IData> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PROJECTS_GETALL,
      {},
      {},
      params
    )
    return data
  }
  const fallback = <IData>{}

  const { data: projectList = fallback, refetch } = useQuery(
    queryKeys.projects,
    getProjectsPaged
  )

  useEffect(() => {
    refetch()
  }, [paginationUc])

  return {
    projectList,
    paginationUc,
    changePagination,
  }
}
