import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { useHrDashboard } from 'pages/HrDashboard/hooks/useHrDashboard'
import { GridRowsProp } from '@mui/x-data-grid'
import { TableComponent, useNotifications } from 'components/common'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import AddAlarmIcon from '@mui/icons-material/AddAlarm'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import { format, parseISO } from 'date-fns'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { IWorkdaysToReview } from 'hooks/pkup/types'

interface ReviewWorkdaysProps {
  userWorkdaysToReview: IWorkdaysToReview | undefined
}
const ReviewWorkdays: React.FC<ReviewWorkdaysProps> = ({
  userWorkdaysToReview,
}): JSX.Element => {
  const { t } = useTranslation('hrDashboard')
  const { setOpenModal } = useNotifications()
  const { acceptWorkdays, declineWorkdays } = useHrDashboard()
  const [selectedWorkdays, setSelectedWorkdays] = useState<number[]>([])
  const { user } = useAuth()
  const columns = [
    '',
    `${t('modal.reviewWorkdays.label1')}`,
    `${t('modal.reviewWorkdays.label2')}`,
    `${t('modal.reviewWorkdays.label3')}`,
    `${t('modal.reviewWorkdays.label4')}`,
    `${t('modal.reviewWorkdays.label5')}`,
    '',
  ]
  const selectAllWorkdays = () => {
    if (userWorkdaysToReview !== undefined) {
      setSelectedWorkdays(userWorkdaysToReview.workdays.map((work) => work.id))
    }
    if (selectedWorkdays.length === userWorkdaysToReview?.workdays.length) {
      setSelectedWorkdays([])
    }
  }
  const toggleSelectedWorkdays = (workId: number) => {
    if (selectedWorkdays?.includes(workId)) {
      setSelectedWorkdays(selectedWorkdays.filter((work) => work !== workId))
    } else {
      setSelectedWorkdays((worksIds) => [...worksIds, workId])
    }
  }

  const handleAcceptOrDeclineOneWorkday = (
    workId: number,
    decision: string
  ) => {
    setSelectedWorkdays([])
    decision === 'accept' ? acceptWorkdays([workId]) : declineWorkdays([workId])
    userWorkdaysToReview?.workdays.length === 1 && setOpenModal(false)
  }
  const handleAcceptOrDeclineMoreWorkdays = (decision: string) => {
    decision === 'accept'
      ? acceptWorkdays(selectedWorkdays)
      : declineWorkdays(selectedWorkdays)
    userWorkdaysToReview?.workdays.length === selectedWorkdays.length &&
      setOpenModal(false)
  }
  const rows: GridRowsProp = userWorkdaysToReview?.workdays?.map((work) => {
    return {
      button: (
        <IconButton onClick={() => toggleSelectedWorkdays(work.id)}>
          {selectedWorkdays?.includes(work.id) ? (
            <CheckBoxIcon />
          ) : (
            <CheckBoxOutlineBlankIcon />
          )}
        </IconButton>
      ),
      date: `${format(
        parseISO(work.logDayFrom),
        'yyyy-MM-dd HH:mm'
      )} - ${format(parseISO(work.logDayTo), 'HH:mm')} `,
      hours: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {work.hours}
          {work.isOverhours && (
            <Tooltip title={t('modal.reviewWorkdays.label2tooltip') as string}>
              <IconButton>
                <AddAlarmIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
      project: work.project
        ? work.project.name
        : `${t('modal.reviewWorkdays.label3text')}`,
      worktype: work.workType.name,
      planned: work.isPlanned
        ? `${t('modal.reviewWorkdays.label5text1')}`
        : `${t('modal.reviewWorkdays.label5text2')}`,
      buttons: (
        <Box>
          {isUserPermitted(user, [permissionList.WORKDAY_REVIEW_ACCEPT]) && (
            <Tooltip title={t('modal.reviewWorkdays.tooltip1') as string}>
              <IconButton
                color="success"
                onClick={() =>
                  handleAcceptOrDeclineOneWorkday(work.id, 'accept')
                }
              >
                <DoneOutlineIcon />
              </IconButton>
            </Tooltip>
          )}
          {isUserPermitted(user, [permissionList.WORKDAY_REVIEW_DECLINE]) && (
            <Tooltip title={t('modal.reviewWorkdays.tooltip2') as string}>
              <IconButton
                color="error"
                onClick={() =>
                  handleAcceptOrDeclineOneWorkday(work.id, 'decline')
                }
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    }
  }) as []
  return (
    <Box sx={{ maxHeight: '90vh' }}>
      <Box sx={{ padding: '1rem', maxHeight: '70vh', overflow: 'auto' }}>
        <Button onClick={() => selectAllWorkdays()}>
          {selectedWorkdays.length === userWorkdaysToReview?.workdays.length
            ? t('modal.reviewWorkdays.button1')
            : t('modal.reviewWorkdays.button2')}
        </Button>
        <TableComponent rows={rows} columns={columns} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '1rem',
          height: '2.5rem',
        }}
      >
        {!!selectedWorkdays.length && (
          <>
            {t('modal.reviewWorkdays.text1')} {selectedWorkdays.length}{' '}
            {t('modal.reviewWorkdays.text2')}
            {isUserPermitted(user, [permissionList.WORKDAY_REVIEW_ACCEPT]) && (
              <IconButton
                color="success"
                onClick={() => handleAcceptOrDeclineMoreWorkdays('accept')}
              >
                <DoneOutlineIcon />
                <Typography>{t('modal.reviewWorkdays.tooltip1')}</Typography>
              </IconButton>
            )}
            {isUserPermitted(user, [permissionList.WORKDAY_REVIEW_DECLINE]) && (
              <IconButton
                color="error"
                onClick={() => handleAcceptOrDeclineMoreWorkdays('decline')}
              >
                <CancelIcon />
                <Typography>{t('modal.reviewWorkdays.tooltip2')}</Typography>
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}
export default ReviewWorkdays
