import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import { HeaderTab } from 'views/LayoutUI/components'
import { GridRowsProp } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { DefaultModal } from 'views/LayoutUI/modals'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import {
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
} from 'components/common'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import {
  IBusinessUnit,
  IBusinessUnitUser,
  IButtonArr,
  ISelectingModal,
} from 'hooks/types'
import { StyledButton } from 'components/common/SwitchButton/index.style'
import { PageRoutes } from 'routes'

import { AddUser } from '../../modals'

import { useBusinessUnits } from './../../utils'

export interface IManageUsersProps {
  businessUnit?: IBusinessUnit
}
const ManageUsers: React.FC = (): JSX.Element => {
  const [person, setPerson] = useState<IBusinessUnitUser | undefined>(undefined)
  const { id } = useParams()
  const { t } = useTranslation(['systemBusinessUnitsUsers', 'buttons'])
  const navigate = useNavigate()
  const { handleOpenModal, modalName, setOpenModal } = useNotifications()
  const { businessUnitsList } = useBusinessUnits()
  const businessUnit = businessUnitsList.find(
    (businessUnit) => businessUnit.id === Number(id) && businessUnit
  )
  const { user } = useAuth()
  const { deleteUser, toggleUser } = useBusinessUnits(businessUnit?.id)
  const columns = [
    `${t('usersDashboard.table.cell1')}`,
    `${t('usersDashboard.table.cell2')}`,
    `${t('usersDashboard.table.cell3')}`,
    `${t('usersDashboard.table.cell4')}`,
    '',
  ]
  const buttonsArr: IButtonArr[] = [
    {
      modal: null,
      title: `${t('calendar', { ns: 'buttons' })}`,
      icon: isUserPermitted(user, [permissionList.WORKDAY_READ_FOR_USER]) && (
        <CalendarMonthIcon />
      ),
    },
    {
      modal: 'delete',
      title: `${t('delete', { ns: 'buttons' })}`,
      icon: isUserPermitted(user, [
        permissionList.BUSINESS_UNIT_DELETE_USER,
      ]) && <DeleteIcon color="error" />,
    },
  ]
  const rows: GridRowsProp = businessUnit?.users?.map((person) => {
    return {
      name: `${person.firstName + ' ' + person.lastName}`,
      email: person.email,
      isDirector: (
        <>
          {isUserPermitted(user, [permissionList.BUSINESS_UNIT_ADD_USER]) && (
            <StyledButton
              valueistrue={
                person.UserBusinessUnits.isDirector === null
                  ? 'null'
                  : person.UserBusinessUnits.isDirector.toString()
              }
              onClick={() =>
                toggleUser({
                  userId: person?.id,
                  isDirector: !person?.UserBusinessUnits?.isDirector,
                })
              }
            >
              {person.UserBusinessUnits.isDirector === null ? (
                t('table.button3')
              ) : person.UserBusinessUnits.isDirector ? (
                <ThumbUpIcon color="success" />
              ) : (
                <ThumbDownIcon color="error" />
              )}
            </StyledButton>
          )}
        </>
      ),
      canManage: (
        <>
          {isUserPermitted(user, [permissionList.BUSINESS_UNIT_ADD_USER]) &&
            !person.UserBusinessUnits.isDirector && (
              <StyledButton
                valueistrue={
                  person.UserBusinessUnits.canManageRequests === null
                    ? 'null'
                    : person.UserBusinessUnits.canManageRequests.toString()
                }
                onClick={() =>
                  toggleUser({
                    userId: person?.id,
                    canManageRequests:
                      !person?.UserBusinessUnits?.canManageRequests,
                  })
                }
              >
                {person.UserBusinessUnits.canManageRequests === null ? (
                  t('table.button3')
                ) : person.UserBusinessUnits.canManageRequests ? (
                  <ThumbUpIcon color="success" />
                ) : (
                  <ThumbDownIcon color="error" />
                )}
              </StyledButton>
            )}
        </>
      ),

      button: (
        <Box onMouseOver={() => setPerson(person)}>
          {buttonsArr.map((button: IButtonArr) => (
            <Tooltip
              title={button.title as string}
              key={button.title}
              onClick={() => handleOpen(person, button.modal as string)}
            >
              <IconButton>{button.icon}</IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  }) as []

  const deleteContentArr = [
    {
      title: `${t('usersDashboard.confirmation.text2')}`,
      date: ` ${person?.firstName} ${person?.lastName}`,
    },
    {
      title: `${t('usersDashboard.confirmation.text3')}`,
      date: person?.email,
    },
  ]
  const deleteContent = () => (
    <Box>
      <Typography variant="subtitle2">
        {t('usersDashboard.confirmation.text1')}
      </Typography>
      {deleteContentArr.map((field) => (
        <Box
          key={field.title}
          sx={{ display: 'flex', alignItems: 'baseline', gap: '3px' }}
        >
          <Typography variant="subtitle2">{field.title}</Typography>
          <Typography variant="subtitle1">{field.date}</Typography>
        </Box>
      ))}
    </Box>
  )
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addUser':
        return {
          component: <AddUser businessUnitId={businessUnit?.id} />,
          title: `${t('modal.addUser')}`,
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteUser(person?.id)}
            />
          ),
          title: `${t('modal.delete')}`,
        }
      default:
        return { component: <DefaultModal />, title: 'Default modal' }
    }
  }

  const handleOpen = async (
    user: IBusinessUnitUser | null,
    name: string | null
  ) => {
    handleOpenModal(name)
    name === null ? navigate(`/calendar/${user?.id}`) : setOpenModal(true)
  }
  return (
    <Container>
      <HeaderTab
        translate="systemBusinessUnitsUsers"
        id={`${t('header.id2')} ${businessUnit?.name}`}
        pageLink={PageRoutes.SYSTEM_BUSINESS_UNITS}
        subPage={t('header.title')}
      />
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          padding: '1rem 0',
          marginBottom: '2rem',
        }}
      >
        <Typography variant="h3">
          {businessUnit?.users?.length ? (
            <>
              {businessUnit?.name} {t('usersDashboard.header.text2')}
              {businessUnit?.users?.length} {t('usersDashboard.header.text3')}
            </>
          ) : (
            <>
              {businessUnit?.name} {t('usersDashboard.header.text1')}
            </>
          )}
        </Typography>
        {isUserPermitted(user, [permissionList.BUSINESS_UNIT_ADD_USER]) && (
          <Button onClick={() => handleOpen(null, 'addUser')}>
            {t('usersDashboard.button')}
          </Button>
        )}
      </Paper>
      {businessUnit?.users?.length !== 0 && (
        <TableComponent rows={rows} columns={columns} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}
export default ManageUsers
