import * as yup from 'yup'
import { useCallback, useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Autocomplete,
  Checkbox,
  TextField as MuiTextField,
  Typography,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useTranslation } from 'react-i18next'
import { useGetAllTags } from 'hooks/tags'
import { IFilterPayload } from 'hooks/table'
import { useSearchParams } from 'react-router-dom'

import { AntSwitch } from '../AntSwitch/index.style'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const SearchInputComponent = ({
  handleFilter,
}: {
  handleFilter: (payload: IFilterPayload) => void
}): JSX.Element => {
  const [onlyActive, setOnlyActive] = useState(true)
  const [selectedTags, setSelectedTags] = useState<number[]>([])
  const tagsList = useGetAllTags()
  const { t } = useTranslation('users')
  const [searchParams] = useSearchParams()
  const validationSchema = yup.object({
    name: yup.string(),
  })

  const defaultOnlyActiveUsers = useCallback(
    (defaultValue?: boolean) => {
      const filters = searchParams.get('filters')
      if (filters) {
        try {
          const jsonFilters = JSON.parse(`${filters}`)
          if (typeof jsonFilters?.inactive === 'boolean') {
            setOnlyActive(!jsonFilters.inactive)
            return !jsonFilters.inactive
          }
        } catch (error) {
          console.error(error)
          return defaultValue
        }
      }
      return defaultValue
    },
    [searchParams]
  )

  const defaultTags = useCallback(
    (defaultValue: Array<number>) => {
      const filters = searchParams.get('filters')
      if (filters) {
        try {
          const jsonFilters = JSON.parse(`${filters}`)
          if (jsonFilters.tags) {
            setSelectedTags(jsonFilters.tags)
            return !jsonFilters.inactive
          }
        } catch (error) {
          console.error(error)
          return defaultValue
        }
      }
      return defaultValue
    },
    [searchParams]
  )

  const getSelectedTags = useCallback(
    (selectedTags: number[]) => {
      return tagsList
        .filter((tagList) => selectedTags.includes(tagList.id))
        .map((value) => ({
          id: value.id,
          name: value.name,
        }))
    },
    [tagsList]
  )

  function removeDuplicatePairs(arr: Array<number>) {
    const seen = new Set()
    const uniqueArr: Array<number> = []

    for (const item of arr) {
      if (!seen.has(item)) {
        seen.add(item)
        uniqueArr.push(item)
      } else {
        const index = uniqueArr.indexOf(item)
        if (index !== -1) {
          uniqueArr.splice(index, 1)
        }
      }
    }

    return uniqueArr
  }

  useEffect(() => {
    defaultOnlyActiveUsers(true)
    defaultTags([])
  }, [])

  const initialValues = {
    name: searchParams.get('search') || '',
    checked: [],
    active: true,
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleFilter({ ...values, active: onlyActive })
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form
            style={{
              width: '100%',
            }}
          >
            <Grid container alignItems="center" columnSpacing={2} mb={3}>
              <Grid item xs={4}>
                <Field
                  fullWidth
                  key="userName"
                  component={TextField}
                  name="name"
                  label={t('search.label1')}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={tagsList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  value={getSelectedTags(selectedTags || [])}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selectedTags.includes(option.id)}
                      />
                      {option.name}
                    </li>
                  )}
                  onChange={(e, value) => {
                    const tagsId = value.map((tag) => tag.id)
                    const tags = removeDuplicatePairs(tagsId)
                    setFieldValue('checked', tags)
                    setSelectedTags(tags)
                  }}
                  renderInput={(params) => {
                    return (
                      <MuiTextField
                        {...params}
                        label={t('search.label2')}
                        placeholder={t('search.label2')}
                        name="checked"
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={2} justifyContent="center" alignItems="center">
                <Button disabled={isSubmitting} type="submit">
                  {t('search', { ns: 'buttons' })}
                </Button>
              </Grid>
              {isSubmitting && <LinearProgress />}
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                gap="10px"
                mt={1}
              >
                <AntSwitch
                  name="active"
                  checked={onlyActive}
                  onChange={() => {
                    setOnlyActive((prevOnlyActive) => !prevOnlyActive),
                      handleFilter({ ...values, active: !onlyActive })
                  }}
                />
                <Typography>
                  {onlyActive ? t('search.label3') : t('search.label4')}
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
export default SearchInputComponent
