import React, { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { EmptyArrayCard } from 'components/common'

import { CustomerRowsProps } from '../../types'

const CustomerRows: FC<CustomerRowsProps> = ({ row, projects, dates }) => {
  const [open, setOpen] = useState(false)
  const mapDates = dates.map((date) => date)

  const { t } = useTranslation('reportAllocations')
  const projectColumns = [
    `${t('customerTable.project')}`,
    `${t('customerTable.userName')}`,
    ...mapDates,
  ]
  const noUsers = [`${t('customerTable.noUsers')}`, '--', '--', '--']

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{ fontWeight: 'bold', color: 'primary.main' }}
          align="center"
        >
          {row}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {projects?.length ? (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {projectColumns.map((column, index) => (
                        <TableCell
                          key={`${index}-${row}`}
                          sx={{
                            color: 'primary.main',
                            width: '20%',
                          }}
                        >
                          <Typography variant="h4" align="center">
                            {column}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects?.map(({ projectName, users }, index) => (
                      <React.Fragment key={`${index}-${projectName}`}>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th, ': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            rowSpan={users.length ? users.length + 1 : 2}
                          >
                            {projectName}
                          </TableCell>
                        </TableRow>
                        {users.length ? (
                          users.map(({ userName, dates }, index) => (
                            <TableRow
                              key={`${index}-${userName}-${projectName}`}
                            >
                              <TableCell colSpan={4}>
                                <Grid container alignItems="center">
                                  <Grid item xs={3}>
                                    {userName}
                                  </Grid>
                                  {dates.map((date, i) => (
                                    <Grid key={i} item xs={3}>
                                      {date}
                                    </Grid>
                                  ))}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <Grid container alignItems="center">
                                {noUsers.map((values, index) => (
                                  <Grid
                                    key={`${values}+${index}`}
                                    item
                                    xs={3}
                                    sx={{ textAlign: 'center' }}
                                  >
                                    {values}
                                  </Grid>
                                ))}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Box ml={'3.5rem'}>
                  <EmptyArrayCard message={t('customerTable.message')} />
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default CustomerRows
