import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { useEffect } from 'react'
import { ICustomer } from 'pages/Customers/types'

const getCustomer = async (id: string | undefined): Promise<ICustomer> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.CUSTOMERS_GETONE,
    {},
    { id }
  )
  return data
}

export const useGetCustomer = (id: string | undefined) => {
  useEffect(() => {
    refetch()
  }, [id])
  const fallback = <ICustomer>{}

  const { data: customer = fallback, refetch } = useQuery(
    queryKeys.customer,
    () => getCustomer(id)
  )
  return customer
}
