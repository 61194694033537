import GroupsIcon from '@mui/icons-material/Groups'
import { IBusinessUnits, IUser } from 'hooks/types'
import { TFunction } from 'i18next'
import { StyledButton } from 'pages/Dashboard/index.style'
import { NavigateFunction } from 'react-router-dom'
import { isUserPermitted, permissionList } from 'services'

export interface IBusinessCardsArgs {
  user: IUser | null
  t: TFunction
  handleOpenModal: (name: string) => void
  setBusinessUnitsId: (id: number | null) => void
  navigate: NavigateFunction
}

export const createBusinessCards = ({
  user,
  t,
  handleOpenModal,
  setBusinessUnitsId,
  navigate,
}: IBusinessCardsArgs) => {
  if (!user || user.businessUnits.length === 0) return []

  return user.businessUnits.map((businessUnit: IBusinessUnits) => {
    const canRead = isUserPermitted(user, [permissionList.BUSINESS_UNIT_READ])
    const canManage = isUserPermitted(user, [permissionList.BUSINESS_UNIT_LIST])

    // TODO: Review and refactor this code in the future. The 'details' could be defined in some config file/variable.
    const handleDetailsClick = () => {
      handleOpenModal('details')
      setBusinessUnitsId(businessUnit.id)
    }

    const handleManageClick = () => {
      navigate(`/system/businessUnits/${businessUnit.id}/users`)
    }

    return {
      title: businessUnit.name,
      subtitle: t('cardsPanel.card4.businessUnits'),
      icon: (
        <GroupsIcon
          sx={{
            fontSize: '3rem',
            color: 'primary.contrastText',
            strokeWidth: 1,
          }}
        />
      ),
      button: canRead ? (
        <StyledButton btnwhite="true" onClick={handleDetailsClick} size="small">
          <>{t('details', { ns: 'buttons' })}</>
        </StyledButton>
      ) : (
        <></>
      ),
      secondButton: canManage ? (
        <StyledButton btncolor="true" onClick={handleManageClick} size="small">
          <>{t('manage', { ns: 'buttons' })}</>
        </StyledButton>
      ) : (
        <></>
      ),
      backgroundColor: 'primary.main',
    }
  })
}
