import { SignIn, ForgotPassword, RemindPasswordPage } from '../pages'

import { PageRoutes } from './routes'

export interface RouteConfig {
  path: string
  element: JSX.Element
}
export const loginRoutes: RouteConfig[] = [
  {
    path: PageRoutes.LOGIN,
    element: <SignIn />,
  },
  {
    path: PageRoutes.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  { path: PageRoutes.REMIND_PASSWORD, element: <RemindPasswordPage /> },
]
