import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IPkupReports } from 'hooks/pkup/types'

export const usePkupReports = (date: string) => {
  const fallback: IPkupReports[] = []

  async function getPkupReports(): Promise<IPkupReports[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_GET_ALL_FOR_REPORT,
      {},
      { date: date }
    )
    return data
  }

  const { data: pkupReports = fallback, refetch } = useQuery(
    queryKeys.pkupReportsList,
    getPkupReports
  )

  useEffect(() => {
    refetch()
  }, [date])

  return pkupReports
}
