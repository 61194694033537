import { Box } from '@mui/material'

import { useNotifications } from '../providers/APIErrorProvider'

import BackgroundAnimation from './BackgroundAnimation'

const Background = () => {
  const { isPlaying } = useNotifications()
  return (
    <>
      {isPlaying && <BackgroundAnimation />}
      <Box
        sx={{
          position: 'fixed',
          transform: 'rotateZ(90deg) rotateX(180deg)',
          top: -250,
          right: 300,
          zIndex: -10000,
          transition: 'all 1s',
        }}
      >
        <img
          style={{ height: '100%', width: '100%', objectFit: 'contain' }}
          src="https://bcf-software.pl/Home/bcf-Intersection-element.png"
          alt="boxes"
        />
      </Box>
      <svg
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: -11000,
          transition: 'all 1s',
        }}
        width="100%"
        height="35vh"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
          fill="rgba(26, 31, 116, 0.1)"
        ></path>
      </svg>
    </>
  )
}

export default Background
