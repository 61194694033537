import { Autocomplete, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IAgreements } from 'hooks/types'

interface IProps {
  form: {
    setFieldValue: (name: string, value: IAgreements[] | []) => void
  }
  field: {
    name: string
    value: any
  }
  name: string
  optionsData: IAgreements[] | []
}

const WorkTypeAutocomplete: React.FC<IProps> = ({
  form,
  field,
  optionsData,
}): JSX.Element => {
  const { t } = useTranslation(['systemWorkTypes'])
  const { name, value } = field
  const { setFieldValue } = form
  return (
    <Autocomplete
      fullWidth
      size="small"
      multiple
      options={optionsData}
      isOptionEqualToValue={(option, { type }) => option.type === type}
      defaultValue={value}
      value={value}
      getOptionLabel={(option) => option.type}
      onChange={(_, value) => {
        {
          setFieldValue(name, value)
        }
      }}
      renderInput={(params) => (
        <TextField {...params} name={name} label={t('modals.form.label2')} />
      )}
    />
  )
}

export default WorkTypeAutocomplete
