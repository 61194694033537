import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { Box, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAuth } from 'hooks/useAuth'
import {
  InfoSnackbar,
  LogoContainer,
  StyledContainer,
  Wrapper,
} from 'components/common'
import { PageRoutes } from 'routes'

const SignIn: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation(['signIn', 'validation'])
  const navigate = useNavigate()
  const { signIn } = useAuth()
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('email.required', { ns: 'validation' }))
      .required(t('email.required', { ns: 'validation' })),
    password: yup
      .string()
      .required(t('password.required', { ns: 'validation' })),
  })
  return (
    <StyledContainer>
      <LogoContainer />
      <Wrapper>
        <Typography>{t('header.text')}</Typography>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={async (values) => {
            const { email, password } = values
            await signIn(email, password)
          }}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box mb={2} mt={4}>
                <Field
                  type="email"
                  fullWidth
                  name="email"
                  label={t('form.label1')}
                  component={TextField}
                />
              </Box>
              <Box my={2}>
                <Field
                  type="password"
                  fullWidth
                  name="password"
                  label={t('form.label2')}
                  component={TextField}
                  autoComplete="on"
                />
              </Box>
              <Box my={2}>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                  fullWidth
                >
                  {t('form.button1')}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
        <Button
          fullWidth
          variant="text"
          onClick={() => navigate(PageRoutes.FORGOT_PASSWORD)}
        >
          {t('form.button2')}
        </Button>
      </Wrapper>
      <InfoSnackbar />
    </StyledContainer>
  )
}
export default SignIn
