import { format } from 'date-fns'
import { useAuth } from 'hooks/useAuth'
import { IProjects, IAllocations } from 'hooks/types'

interface UseYourProjects {
  currentDate: string
  activeAlloc: IProjects[]
  inactiveAlloc: IProjects[]
}

export const useYourProjects = (): UseYourProjects => {
  const { user } = useAuth()
  const currentDate = format(new Date(), 'yyyy/MM')

  const currentAlloc = user?.allocations?.filter((a) => a.date === currentDate)
  const activeAlloc = <IProjects[]>[]

  user?.projects?.forEach((proj: IProjects) =>
    currentAlloc?.forEach((alloc: IAllocations) => {
      if (alloc.ProjectUser.projectId === proj.id) {
        if (!activeAlloc.some((item: IProjects) => item.id === proj.id)) {
          activeAlloc.push(proj)
        }
      }
    })
  )
  const inactiveAlloc = <IProjects[]>(
    user?.projects?.filter((proj: IProjects) => !activeAlloc.includes(proj))
  )

  return {
    currentDate,
    activeAlloc,
    inactiveAlloc,
  }
}
