import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { NOTIFICATION, useNotifications } from 'components/common'

export const useReportForUser = () => {
  const { setNotification } = useNotifications()

  async function getDownloadUserReport(values, reportType) {
    const url =
      reportType === 'normal'
        ? APIRoutes.WORKDAYS_DOWNLOADFORUSER
        : APIRoutes.WORKDAYS_DOWNLOADUOPFORUSER

    const { data }: AxiosResponse = await apiRequest(
      url,
      {
        month: values.month,
        onlyActive: values.onlyActive,
        agreements: values.agreements.map((agreement) => agreement.id),
        selectedMonthAgreements: values.selectedMonthAgreements,
      },
      { id: values.users[0].id }
    )
    return data
  }

  async function getDownloadSelectedUsersReport(values, reportType) {
    const url =
      reportType === 'normal'
        ? APIRoutes.WORKDAYS_DOWNLOADFORUSERS
        : APIRoutes.WORKDAYS_DOWNLOADUOPFORUSERS

    const { data }: AxiosResponse = await apiRequest(url, {
      ids: values.users.map((user) => user.id),
      month: values.month,
      onlyActive: values.onlyActive,
      agreements: values.agreements.map((agreement) => agreement.id),
      selectedMonthAgreements: values.selectedMonthAgreements,
    })
    return data
  }

  const handleDownloadUserReport = async (values, reportType) => {
    const date = format(values.month, 'yyyy-MM')
    if (values.users.length === 1) {
      const timesheetName =
        reportType === 'uop' ? '-timesheet-uop-' : '-timesheet-'
      const filename = values.users[0].name + timesheetName + date
      await getDownloadUserReport(values, reportType)
        .then((response) => {
          fileDownload(new Blob([response]), filename + '.xlsx')
        })
        .catch((error) =>
          setNotification({
            message: error.message,
            variant: NOTIFICATION.error,
          })
        )
    } else {
      const timesheetName =
        reportType === 'uop' ? 'selected_users-uop-' : 'selected_users-'
      const filename = timesheetName + date
      await getDownloadSelectedUsersReport(values, reportType)
        .then((response) => {
          fileDownload(new Blob([response]), filename + '.xlsx')
        })
        .catch((error) =>
          setNotification({
            message: error.message,
            variant: NOTIFICATION.error,
          })
        )
    }
  }

  return {
    handleDownloadUserReport,
  }
}
