import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { IPaginateReducer, defaultPaginationValue } from 'hooks/table'
import { queryKeys } from 'react-query/constants'
import { useTranslation } from 'react-i18next'
import { IPagination } from 'hooks/types'

import { ITags } from '../types'

export type ITagList = IPagination & { data: ITags[] | [] }

export const useGetPageTags = () => {
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>(
    defaultPaginationValue
  )
  const { t } = useTranslation('validation')
  const filters = paginationUc?.filters
    ? `&filters=${paginationUc?.filters}`
    : ''
  const searchInput = paginationUc?.search
    ? `&search=${paginationUc?.search}`
    : ''
  const orderBy = paginationUc?.order
    ? `&order={"order":"${paginationUc.order.order}","column":"${paginationUc.order.column}"}`
    : ''
  const params = `page=${paginationUc?.currentPage + 1 || 1}&limit=${
    paginationUc?.length || 10
  }${filters}${searchInput}${orderBy}`

  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }
  const getTagsPerPage = async (): Promise<ITagList> => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.TAGS_GET,
      {},
      {},
      `${params}`
    )
    return data
  }

  const fallback = <ITagList>{}

  const { data: tagsList = fallback, refetch } = useQuery(
    queryKeys.tagsPerPage,
    getTagsPerPage
  )

  useEffect(() => {
    refetch()
  }, [paginationUc])

  const validationSchema = yup.object({
    name: yup.string().required(t('tagName')),
  })

  return { tagsList, changePagination, paginationUc, validationSchema }
}
