import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'

export interface ITags {
  createdAt: string
  id: number
  name: string
  revision: number
  updatedAt: string
  users: []
}

const getTags = async (): Promise<ITags[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.TAGS_GET,
    {},
    {},
    'limit=9999'
  )
  return data.data
}

export const useGetAllTags = () => {
  const fallback: ITags[] = []
  const { data: tagsList = fallback } = useQuery('tags', getTags)

  return tagsList
}
