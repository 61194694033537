import { Navigate } from 'react-router-dom'
import { permissionList } from 'services'
import { WithPermissions } from 'hoc/withPermissions'
import { CalendarProvider } from 'pages/Calendar/context'
import { UserAllocations, CV, EditUser, Notes } from 'pages/Users/pages'
import { CustomerEditPage } from 'pages/Customers/pages'
import { ProjectDetails, ProjectManageUser } from 'pages/Projects/pages'
import {
  BusinessUnits,
  HolidaysCategories,
  Locations,
  SystemLogActivity,
  Tags,
  WorkTypes,
} from 'pages/System/pages'
import {
  ReportAgreement,
  ReportAllocations,
  ReportCustomers,
  ReportDashboard,
  ReportPkup,
  ReportProductivity,
  ReportWorkdays,
  ReportAllocationProvider,
} from 'pages/Reports/pages'
import { ManageUsers } from 'pages/System/pages/BusinessUnits/components'
import { LocationUsersDashboard } from 'pages/System/pages/Locations/components'
import { HrDashboardPkupsDetails } from 'pages/HrDashboard/components'
import { PkupsDashboardPkupsDetails } from 'pages/PkupsDashboard/components'
import {
  Calendar,
  Customers,
  Dashboard,
  Groups,
  Holidays,
  HrDashboard,
  Inventory,
  Pkup,
  PkupsDashboard,
  PmDashboard,
  Projects,
  Profile,
  Users,
  VacationRequest,
} from 'pages'

import { PageRoutes } from './routes'

export interface RouteConfig {
  path: string
  element: JSX.Element
}
export const mainRoutes: Array<RouteConfig> = [
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
  {
    path: PageRoutes.USER_ALLOCATIONS,
    element: (
      <WithPermissions
        Component={<UserAllocations />}
        permission={[
          permissionList.ALLOCATION_MANAGER_VIEW,
          permissionList.USER_LIST,
        ]}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.HOME,
    element: <Dashboard />,
  },
  {
    path: PageRoutes.VACATION_REQUEST,
    element: (
      <CalendarProvider>
        <VacationRequest />
      </CalendarProvider>
    ),
  },
  {
    path: PageRoutes.CALENDAR,
    element: (
      <WithPermissions
        Component={
          <CalendarProvider>
            <Calendar />
          </CalendarProvider>
        }
        permission={permissionList.WORKDAYS_GET_CURRENT_USER}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.CUSTOMERS,
    element: (
      <WithPermissions
        Component={<Customers />}
        permission={permissionList.CUSTOMER_LIST}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.CUSTOMERS_EDIT,
    element: (
      <WithPermissions
        Component={<CustomerEditPage />}
        permission={
          (permissionList.CUSTOMER_READ, permissionList.CUSTOMER_EDIT)
        }
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.GROUPS,
    element: (
      <WithPermissions
        Component={<Groups />}
        permission={permissionList.GROUP_LIST}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.HOLIDAYS,
    element: (
      <WithPermissions
        Component={<Holidays />}
        permission={permissionList.HOLIDAYS_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.INVENTORY,
    element: (
      <WithPermissions
        Component={<Inventory />}
        permission={permissionList.INVENTORY_VIEW}
      ></WithPermissions>
    ),
  },

  {
    path: PageRoutes.PKUP,
    element: (
      <WithPermissions
        Component={<Pkup />}
        permission={permissionList.PKUP_READ}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.PKUP_DETAILS,
    element: (
      <WithPermissions
        Component={<HrDashboardPkupsDetails />}
        permission={permissionList.PKUP_UPDATE}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.SYSTEM_BUSINESS_UNITS,
    element: (
      <WithPermissions
        Component={<BusinessUnits />}
        permission={permissionList.BUSINESS_UNIT_READ}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.SYSTEM_LOCATIONS,
    element: (
      <WithPermissions
        Component={<Locations />}
        permission={permissionList.LOCATIONS_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.SYSTEM_LOCATIONS_USERS,
    element: (
      <WithPermissions
        Component={<LocationUsersDashboard />}
        permission={
          (permissionList.PROJECT_LIST_USERS, permissionList.PROJECT_ADD_USER)
        }
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.USERS,
    element: (
      <WithPermissions
        Component={<Users />}
        permission={permissionList.USER_LIST}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.USER,
    element: (
      <WithPermissions
        Component={<EditUser />}
        permission={(permissionList.USER_READ, permissionList.USER_EDIT)}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.PKUPS_DASHBOARD,
    element: (
      <WithPermissions
        Component={<PkupsDashboard />}
        permission={permissionList.PKUP_EDIT}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.PKUPS_DASHBOARD_DETAILS,
    element: (
      <WithPermissions
        Component={<PkupsDashboardPkupsDetails />}
        permission={permissionList.PKUP_EDIT}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.PM_DASHBOARD,
    element: (
      <WithPermissions
        Component={<PmDashboard />}
        permission={permissionList.PM_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.HR_DASHBOARD,
    element: (
      <WithPermissions
        Component={<HrDashboard />}
        permission={permissionList.HR_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.USER_CALENDAR,
    element: (
      <WithPermissions
        Component={
          <CalendarProvider>
            <Calendar />
          </CalendarProvider>
        }
        permission={
          (permissionList.WORKDAYS_GET_CURRENT_USER, permissionList.USER_READ)
        }
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.USER_CV,
    element: (
      <WithPermissions
        Component={<CV />}
        permission={permissionList.EMPLOYEE_NOTES_MANAGEMENT}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.USER_NOTES,
    element: (
      <WithPermissions
        Component={<Notes />}
        permission={permissionList.EMPLOYEE_NOTES_MANAGEMENT}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.PROJECTS,
    element: (
      <WithPermissions
        Component={<Projects />}
        permission={permissionList.PROJECT_LIST}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.PROJECT_DETAILS,
    element: (
      <WithPermissions
        Component={<ProjectDetails />}
        permission={permissionList.PROJECT_LIST}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.PROJECT_USERS,
    element: (
      <WithPermissions
        Component={<ProjectManageUser />}
        permission={
          (permissionList.PROJECT_LIST_USERS, permissionList.PROJECT_ADD_USER)
        }
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.PROFILE,
    element: (
      <WithPermissions
        Component={<Profile />}
        permission={permissionList.TAGS_DELETE_FROM_USER}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.SYSTEM_BUSINESS_UNITS_USERS,
    element: (
      <WithPermissions
        Component={<ManageUsers />}
        permission={
          (permissionList.BUSINESS_UNIT_USERS,
          permissionList.BUSINESS_UNIT_ADD_USER)
        }
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.SYSTEM_HOLIDAYS_CATEGORIES,
    element: (
      <WithPermissions
        Component={<HolidaysCategories />}
        permission={permissionList.HOLIDAYS_CATEGORIES_LIST}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.REPORT_AGREEMENTS,
    element: (
      <WithPermissions
        Component={<ReportAgreement />}
        permission={permissionList.REPORTS_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.REPORT_ALLOCATIONS,
    element: (
      <WithPermissions
        Component={
          <ReportAllocationProvider>
            <ReportAllocations />
          </ReportAllocationProvider>
        }
        permission={permissionList.REPORTS_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.REPORT_CUSTOMERS,
    element: (
      <WithPermissions
        Component={<ReportCustomers />}
        permission={permissionList.REPORTS_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.REPORT_DASHBOARD,
    element: (
      <WithPermissions
        Component={<ReportDashboard />}
        permission={permissionList.REPORTS_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.REPORT_PKUP,
    element: (
      <WithPermissions
        Component={<ReportPkup />}
        permission={permissionList.REPORTS_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.REPORT_PRODUCTIVITY,
    element: (
      <WithPermissions
        Component={<ReportProductivity />}
        permission={permissionList.REPORTS_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.REPORT_WORKDAYS,
    element: (
      <WithPermissions
        Component={<ReportWorkdays />}
        permission={permissionList.REPORTS_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.SYSTEM_ACTIVITY,
    element: (
      <WithPermissions
        Component={<SystemLogActivity />}
        permission={permissionList.ACTIVITY_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.SYSTEM_TAGS,
    element: (
      <WithPermissions
        Component={<Tags />}
        permission={permissionList.TAG_VIEW}
      ></WithPermissions>
    ),
  },
  {
    path: PageRoutes.SYSTEM_WORKTYPES,
    element: (
      <WithPermissions
        Component={<WorkTypes />}
        permission={permissionList.WORKTYPES_VIEW}
      ></WithPermissions>
    ),
  },
]
