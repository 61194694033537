import { styled, Box } from '@mui/material'

export const FooterContainer = styled(Box)`
  height: 2rem;
  -webkit-box-shadow: 0px -4px 8px -5px #010102;
  -moz-box-shadow: 0px -4px 8px -5px #1a3d92;
  box-shadow: 0px -4px 8px -5px #1a3d92;
  position: relative;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  & > p {
    color: ${(props) => props.theme.palette.primary.main};
  }
`
