import { createTheme } from '@mui/material'

import customBreakpoints from './breakpoints'

const customOverrides = createTheme({
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          position: 'absolute',
          width: '100%',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          width: '100%',
          [customBreakpoints.breakpoints.up('lg')]: {
            maxWidth: '100%',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        variant: 'contained',
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiChip: {
      defaultProps: {
        color: 'primary',
        variant: 'outlined',
      },
    },
  },
})
export default customOverrides
