import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Fade, Tooltip } from '@mui/material'
import { HeaderTab } from 'views/LayoutUI/components'
import { GridRowsProp } from '@mui/x-data-grid'
import { permissionList, isUserPermitted } from 'services'
import { DefaultModal } from 'views/LayoutUI/modals'
import { useAllInventories, IInventoriesList } from 'hooks/inventory'
import { useAuth } from 'hooks/useAuth'
import { ISelectingModal } from 'hooks/types'
import {
  DialogComponent,
  EmptyArrayCard,
  ModalComponent,
  TableComponent,
  useNotifications,
} from 'components/common'
import { timeoutAnimation } from 'utils'

import {
  AddNewItems,
  ManageInventoryList,
  InventoryUsers,
  ShowFreeItems,
} from './modals'
import { useInventoryFreeItems } from './modals/ShowFreeItems/utils'

const Inventory: React.FC = (): JSX.Element => {
  const { t } = useTranslation('inventory')
  const { modalName, handleOpenModal } = useNotifications()
  const inventories = useAllInventories()
  const [inventory, setInventory] = useState<IInventoriesList | null>(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogName, setDialogName] = useState<string>('')
  const { user } = useAuth()
  const handleOpenDialog = (name: string) => {
    setOpenDialog(true)
    setDialogName(name)
  }
  const freeInventories = useInventoryFreeItems()

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDialogName('')
  }
  useEffect(() => {
    if (!openDialog) {
      setInventory(null)
    }
  }, [openDialog])

  const columns = ['id', `${t('table.cell1')}`, '']
  const rows: GridRowsProp = inventories?.data?.map((inventory) => {
    return {
      id: inventory.id,
      name: inventory.name,
      button: (
        <Button
          variant="outlined"
          onClick={() => {
            setInventory(inventory)
            handleOpenDialog('UsersInventory')
          }}
          disabled={inventory.UserInventories.length ? false : true}
        >
          {inventory.UserInventories.length
            ? t('table.button1')
            : t('table.button2')}
        </Button>
      ),
    }
  })
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'manageInventoryList':
        return {
          component: <ManageInventoryList />,
          title: `${t('modal.manageInventoryList.title')}`,
        }
      case 'showFreeItems':
        return {
          component: <ShowFreeItems />,
          title: `${t('modal.showFreeItems.title')}`,
        }
      case 'addNewItems':
        return {
          component: <AddNewItems />,
          title: `${t('modal.addNewItems.title')}`,
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('modal.defaultModal.title')}`,
        }
    }
  }
  const selectingDialog = (): ISelectingModal => {
    switch (dialogName) {
      case 'UsersInventory':
        return {
          component: <InventoryUsers inventoryId={inventory?.id} />,
          title: `${t('dialog.usersInventory.title1')} ${inventory?.name} ${t(
            'dialog.usersInventory.title2'
          )} `,
        }
      case 'freeInventories':
        return {
          component: <ShowFreeItems />,
          title: `${t('modal.showFreeItems.title')}`,
        }
      default:
        return {
          component: <DefaultModal />,
          title: `${t('modal.defaultModal.title')}`,
        }
    }
  }

  return (
    <Container>
      <HeaderTab translate="inventory" />
      {isUserPermitted(user, [permissionList.INVENTORY_MANAGE_LIST]) && (
        <Button
          sx={{ marginRight: '1rem' }}
          onClick={() => handleOpenModal('manageInventoryList')}
        >
          {t('buttonGroup.button1')}
        </Button>
      )}
      <Tooltip
        title={
          freeInventories.length
            ? ''
            : (t('modal.showFreeItems.tooltip') as string)
        }
      >
        <span>
          <Button
            disabled={!freeInventories.length}
            sx={{ marginRight: '1rem' }}
            onClick={() => handleOpenDialog('freeInventories')}
          >
            {t('buttonGroup.button2')}
          </Button>
        </span>
      </Tooltip>
      {isUserPermitted(user, [permissionList.INVENTORY_ADD_NEW_ITEM]) && (
        <Button onClick={() => handleOpenModal('addNewItems')}>
          {t('buttonGroup.button3')}
        </Button>
      )}
      {rows?.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent columns={columns} rows={rows} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
      <DialogComponent
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        component={selectingDialog().component}
        title={selectingDialog().title}
      />
    </Container>
  )
}
export default Inventory
