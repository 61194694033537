import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface ICustomerProjectData {
  code: string
  customerId: number | undefined
  endDate: string | Date
  isBillable: boolean
  isProductive: boolean
  name: string
  startDate: string | Date
}

export const useAddCustomerProject = () => {
  const { handleCloseModal, setNotification } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const addCustomerProject = async (
    customerProjectData: ICustomerProjectData
  ) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PROJECTS_CREATE,
      customerProjectData
    )
    return data
  }

  const { mutate: addNewCustomerProject } = useMutation(addCustomerProject, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('project')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.customer])
    },
  })

  return {
    addNewCustomerProject,
  }
}
