import { Box } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'

import { CustomizedDataPicker } from '../../common'

const HolidayFields = () => {
  const { t } = useTranslation('userEditPage')

  return (
    <Box my={2} display="flex">
      <Box mr={2}>
        <Field
          component={CustomizedDataPicker}
          name="year"
          label={t('holidays.form.label2')}
          views={['year']}
          inputFormat="yyyy"
        />
      </Box>
      <Box mr={2}>
        <Field
          component={TextField}
          type="number"
          name="days"
          label={t('holidays.form.label3')}
        />
      </Box>
    </Box>
  )
}

export default HolidayFields
