import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { NOTIFICATION, useNotifications } from 'components/common'
import { AddPkupWorkProps, IAuthorsWork } from 'hooks/pkup/types'

export const useAuthorsWork = (date: string) => {
  const { t } = useTranslation('handleMessage')
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()

  const fallback: IAuthorsWork[] = []

  async function getMyWorks(): Promise<IAuthorsWork[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_GET_ALL_WORKS_FOR_ME,
      {},
      { date: date }
    )
    return data
  }
  async function addNewPkupWork({
    values,
    attachment,
  }: {
    values: AddPkupWorkProps['values']
    attachment: AddPkupWorkProps['attachment']
  }) {
    const formData = new FormData()
    if (attachment.current !== null && attachment.current !== undefined) {
      for (const [key, file] of Object.entries(attachment.current.files)) {
        formData.append('files', file, file.name)
      }
    }
    formData.append('title', values.title)
    formData.append('description', values.description)
    formData.append('projectId', values?.projectId?.id.toString() || '0')
    formData.append('members', JSON.stringify(values?.members))
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PKUP_CREATE_WORK,
      formData
    )
    return data
  }
  const { mutate: addPkupWork } = useMutation(addNewPkupWork, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('pkupWork')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.worksList])
    },
  })

  const { data: myWorks = fallback, refetch } = useQuery(
    queryKeys.worksList,
    getMyWorks
  )

  useEffect(() => {
    refetch()
  }, [date])

  return {
    addPkupWork,
    myWorks,
  }
}
