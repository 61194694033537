import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

export const useUserEdu = (
  userId: number | undefined,
  grade?: string,
  place?: string,
  years?: string,
  editId?: number
) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')
  const { setNotification, handleCloseModal } = useNotifications()

  const addEduToUser = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_EDU_CREATE,
      {
        grade,
        place,
        years,
      },
      { id: userId }
    )
    return data
  }
  const deleteEduFromUser = async (id?: number) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_EDU_DELETE,
      {},
      { id }
    )
    return data
  }
  const editEduFromUser = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_EDU_UPDATE,
      {
        years,
        grade,
        place,
      },
      { id: editId }
    )
    return data
  }
  const { mutate: addEdu } = useMutation(addEduToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('education')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdEdu])
    },
  })
  const { mutate: deleteEdu } = useMutation(deleteEduFromUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('education')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdEdu])
    },
  })
  const { mutate: editEdu } = useMutation(editEduFromUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('education')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdEdu])
    },
  })
  return {
    addEdu,
    deleteEdu,
    editEdu,
  }
}
