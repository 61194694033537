import { useTranslation } from 'react-i18next'
import TitleIcon from '@mui/icons-material/Title'

import { useGenerateCV } from '../../../UserCV/hooks/useGenerateCV'
import CardRadioOption from '../CardRadioOption'

interface ITemplateOption {
  templateValue: string
  setFieldValue: (name: string, value: string) => void
  templateError?: string
  templateTouch?: boolean
}
const TemplateOption: React.FC<ITemplateOption> = ({
  templateValue,
  setFieldValue,
  templateError,
  templateTouch,
}): JSX.Element => {
  const { t } = useTranslation('userCV')
  const { templates } = useGenerateCV()

  const TemplateFields = (field) => [
    {
      name: field.replace('.html', ''),
      title: `${t('cv.bioTable.title')}`,
      icon: <TitleIcon />,
    },
  ]

  return (
    <CardRadioOption
      name="selectedTemplate"
      option={templates}
      optionDetails={TemplateFields}
      title={t('generateProfile.selectTemplate')}
      setFieldValue={setFieldValue}
      value={templateValue}
      error={templateError}
      touch={templateTouch}
    />
  )
}

export default TemplateOption
