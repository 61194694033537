import { Box, Container, styled } from '@mui/material'

export const Wrapper = styled(Box)(({ theme }) => ({
  paddingInline: 30,
  paddingBlock: 60,
  boxShadow: `0px 0px 8px 0px ${theme.palette.primary.main}`,
  width: '35%',
  backgroundColor: theme.palette.primary.contrastText,
  borderRadius: '1rem',
}))
export const WrapperContainer = styled(Container)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  '&:hover': {
    color: theme.palette.primary.light,
  },
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  minHeight: 600,
  minWidth: 900,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
  flexDirection: 'column',
}))
