import { Box, Table, TableBody, TableContainer, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TableTitle } from 'components/common'

import AllocationsDate from '../AllocationsDate'
import { useReportAllocationContext } from '../../context'
import { ICustomersRow } from '../../types'

import CustomerRows from './CustomerRows'

const CustomerTable = () => {
  const { t } = useTranslation('reportAllocations')
  const { allocationByCustomer, dates } = useReportAllocationContext()
  const customersRow: ICustomersRow[] | [] = allocationByCustomer?.map(
    ({ customerId, customerProjects, customerName }) => {
      const allProjects = customerProjects?.map(
        ({ projectName, projectUsers }) => {
          return {
            projectName: projectName,
            users: projectUsers.map((user) => {
              const mapDates = dates.map((date) => (
                <AllocationsDate
                  key={date}
                  allocations={user.allocations}
                  date={date}
                />
              ))
              return {
                userId: `${projectName}-${user.id}`,
                userName: `${user.firstName} ${user.lastName}`,
                dates: mapDates,
              }
            }),
          }
        }
      )

      return {
        id: customerId,
        name: customerName,
        projects: allProjects,
      }
    }
  )
  if (!allocationByCustomer.length) return null
  return (
    <Box my={2}>
      <TableTitle>{t('customerTable.title')}</TableTitle>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            {customersRow.map(({ name, projects, id }) => (
              <CustomerRows
                key={`${id}-customerName`}
                row={name}
                projects={projects}
                dates={dates}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
export default CustomerTable
