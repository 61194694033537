import { useTranslation } from 'react-i18next'
import { Box, IconButton, ListItem, Tooltip, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddBoxIcon from '@mui/icons-material/AddBox'
import palette from 'theme/palette'
import { useGetGroups } from 'hooks/groups'

import { IGroups, IPermissionsList } from '../../types'
import { usePermissions } from '../../modals/ManagePermissions/utils'

import { usePermissionList } from './utils'

export interface PermissionsListProps {
  group?: IGroups | null
  deletePermission?: (id: number) => void
}
const PermissionsList: React.FC<PermissionsListProps> = ({
  group,
  deletePermission,
}): JSX.Element => {
  const { permissionsList } = usePermissions()
  const { groupsList } = useGetGroups()

  const { addPermission, deletePermissionFromGroup } = usePermissionList()
  const { t } = useTranslation(['groups', 'validation', 'buttons'])

  const groupPermissions = groupsList.find(
    (groupPermission) => groupPermission.id === group?.id
  )
  const isPermitted = (permission: IPermissionsList) => {
    return groupPermissions?.permissions?.find(
      (groupPerm) => groupPerm.id === permission?.id
    )
  }
  return (
    <Box>
      {permissionsList.map((permission) => (
        <ListItem
          key={permission.id}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '98%',
            justifyContent: 'space-between',
            backgroundColor: isPermitted(permission)
              ? palette.secondary.dark
              : `${palette.secondary.main + '60'}`,
            margin: '0.5rem 0',
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography variant="subtitle1">
                {permission.code + ' / ' + permission.name}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'baseline', flexWrap: 'wrap' }}
            >
              {permission.groups.map((group, index) => (
                <Typography key={group.id} variant="subtitle2">
                  {group.name}
                  {index === permission.groups?.length - 1 ? '' : ', '}
                </Typography>
              ))}
            </Box>
          </Box>
          <Tooltip
            title={
              t(
                `${
                  group
                    ? isPermitted(permission)
                      ? 'Delete'
                      : 'Add'
                    : 'Delete'
                }`,
                {
                  ns: 'buttons',
                }
              ) as string
            }
          >
            {deletePermission ? (
              <IconButton
                onClick={() => {
                  deletePermission(permission.id)
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            ) : (
              <>
                {isPermitted(permission) ? (
                  <IconButton
                    onClick={() => {
                      deletePermissionFromGroup(
                        groupPermissions?.permissions?.find(
                          (per) => per.id === permission.id
                        )?.GroupPermission.id
                      )
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      addPermission({
                        groupId: group?.id,
                        permissionId: permission.id,
                      })
                    }
                  >
                    <AddBoxIcon />
                  </IconButton>
                )}
              </>
            )}
          </Tooltip>
        </ListItem>
      ))}
    </Box>
  )
}

export default PermissionsList
