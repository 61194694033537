import { Box, Container } from '@mui/material'
import { HeaderTab } from 'views/LayoutUI/components'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { theme } from 'theme'

import { PkupsToReview, WorkdaysToReview } from './components'

const HrDashboard: React.FC = (): JSX.Element => {
  const { user } = useAuth()

  return (
    <Container>
      <HeaderTab translate="hrDashboard" />
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        {isUserPermitted(user, [permissionList.WORKDAY_FOR_REVIEW]) && (
          <Box sx={{ width: '100%' }}>
            <WorkdaysToReview />
          </Box>
        )}
        <Box sx={{ width: '100%' }}>
          <PkupsToReview />
        </Box>
      </Box>
    </Container>
  )
}
export default HrDashboard
