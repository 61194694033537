import { Box, Button, Grid, Typography } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { CustomizedTimePicker } from 'components/common'
import palette from 'theme/palette'
import { useAuth } from 'hooks/useAuth'

import { useCalendarContext } from '../../context'
import WeekendDates from '../Dates/WeekendDates'
import HolidaysDates from '../Dates/HolidaysDates'
import Reactions from '../EventClickModal/components/Reactions'

import { FteComponent, ChooseSelect, SelectWorkType } from './components'
import { useWorkdaysModal } from './utils'
import { SubmitButton, ModalContentContainer } from './index.style'
import { AlertContainer } from './components/FteComponent/index.style'

const AddWorkdaysModal = (): JSX.Element => {
  const {
    initialValues,
    onSubmit,
    handleChange,
    dayType,
    selectedProject,
    holidayType,
    projectWithOverHours,
  } = useWorkdaysModal()

  const {
    types,
    isOverhours,
    weekends,
    isPlanned,
    setIsPlanned,
    holidayForMonth,
    projects,
    alloc,
  } = useCalendarContext()
  const { t } = useTranslation(['calendar', 'buttons'])
  const { user } = useAuth()
  const validationSchema = yup.object({
    startTime: yup.date().required(),
    endTime: yup
      .date()
      .min(yup.ref('startTime'), t('endTime.minValue', { ns: 'validation' })),
  })

  const type = types.find((type) => type.id === parseInt(dayType))
  const isBtnDisabled = projects.length
    ? !alloc?.isOverhours
      ? false
      : !isOverhours && !!projectWithOverHours.length
    : true
  return (
    <ModalContentContainer>
      {user?.projects?.length ? (
        <Box
          sx={{
            width: '95%',
            height: '100%',
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            sx={{ height: '100%' }}
            validationSchema={validationSchema}
          >
            {({ submitForm, errors, setFieldValue, values }) => (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <SelectWorkType
                      handleChange={handleChange}
                      dayType={dayType}
                      selectedProject={selectedProject}
                    />
                  </Grid>

                  {!type?.isAssignableToProject && !type?.isHoliday ? null : (
                    <Grid item xs={12}>
                      <ChooseSelect
                        handleChange={handleChange}
                        dayType={dayType}
                        selectedProject={selectedProject}
                        holidayType={holidayType}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FteComponent />
                  </Grid>
                  {!!weekends.length && (
                    <Grid item xs={12}>
                      <WeekendDates />
                    </Grid>
                  )}
                  {!!holidayForMonth.length && (
                    <Grid item xs={12}>
                      <HolidaysDates />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {isPlanned ? (
                      <Button
                        onClick={() => setIsPlanned(false)}
                        sx={{
                          backgroundColor: palette.primary.light,
                          width: '100%',
                        }}
                      >
                        {t('addWorkdaysModal.mode1')}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setIsPlanned(true)}
                        sx={{ width: '100%' }}
                      >
                        {t('addWorkdaysModal.mode2')}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={CustomizedTimePicker}
                      error={errors?.startTime as string}
                      name="startTime"
                      label={t('addWorkdaysModal.timeStart')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="endTime"
                      error={errors?.endTime as string}
                      component={CustomizedTimePicker}
                      label={t('addWorkdaysModal.timeEnd')}
                    />
                    {errors?.endTime && (
                      <Typography variant="h6" color="error">
                        <>{errors.endTime}</>
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      multiline
                      rows={3}
                      component={TextField}
                      label={t('addWorkdaysModal.label')}
                      name="comment"
                      size="small"
                      InputProps={{ multiline: true }}
                    />
                    <br />
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Reactions setFieldValue={setFieldValue} mood={values.mood} />
                  <SubmitButton
                    color="primary"
                    disabled={isBtnDisabled}
                    onClick={submitForm}
                  >
                    {t('save', { ns: 'buttons' })}
                  </SubmitButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <AlertContainer severity="error">
          {t('addWorkdaysModal.alertProjects')}
        </AlertContainer>
      )}
    </ModalContentContainer>
  )
}

export default AddWorkdaysModal
