interface Permission {
  [key: string]: string
}

interface PermissionsList {
  [key: string]: { index: number; name: string }
}

const permissions: Permission = {
  PM_VIEW: 'Turn on item tab for PM',
  HR_VIEW: 'Turn on item tab for HR',
  CALENDAR_VIEW: 'Can view calendar page',
  REPORTS_VIEW: 'Turn on item tab for reports',
  HOLIDAYS_VIEW: 'Turn on item tab for holidays',
  SYSTEM_SETTINGS_VIEW: 'Turn on item tab for system settings',
  WORKTYPES_VIEW: 'Turn on item tab for worktypes',
  GROUPS_VIEW: 'Turn on item tab for groups',
  CUSTOMERS_VIEW: 'Turn on item tab for customers',
  USERS_VIEW: 'Turn on item tab for users',
  LOCATIONS_VIEW: 'Turn on item tab for locations',

  WORKDAYS_LIST_ALL: 'Can get list of all workdays',
  WORKDAYS_GET_CURRENT_USER: 'Can get list of all workdays for current user',
  WORKDAY_READ_FOR_USER: 'Read calendar for other user',
  WORKDAY_EDIT_FOR_USER: 'Edit calendar for other user',
  WORKDAY_COPY: 'Copy planned to worked workdays',
  WORKDAY_GEN_XCL_PROJECT: 'Can generate excel report for project',
  WORKDAY_GEN_XCL: 'Can generate excel report for month',
  WORKDAY_GEN_XCL_UOP: 'Can generate excel report (UoP)',
  WORKDAY_GEN_XCL_FOR_USER: 'Can generate excel report for specific user',
  WORKDAY_GEN_XCL_FOR_CUSTOMER: 'Can generate excel report for customer',
  WORKDAY_GEN_XCL_FOR_USER_UOP:
    'Can generate excel report for specific user (UoP)',
  WORKDAY_FOR_REVIEW: 'Can get workdays for review',
  WORKDAY_REVIEW_ACCEPT: 'Can accept workdays for review',
  WORKDAY_REVIEW_DECLINE: 'Can decline workdays for review',
  WORKDAY_DELETE_OTHER_USER: 'Can delete workday from other user',

  WORKTYPE_CREATE: 'Can create worktype',
  WORKTYPE_EDIT: 'Can edit worktype',
  WORKTYPE_DELETE: 'Can delete worktype',

  CUSTOMER_LIST: 'Can get customer list',
  CUSTOMER_CREATE: 'Can create customer',
  CUSTOMER_READ: 'Can read data about customer',
  CUSTOMER_EDIT: 'Can edit customer',
  CUSTOMER_DELETE: 'Can delete customer',
  CUSTOMER_ADD_PROJECT: 'Can add project to customer',
  CUSTOMER_GET_PROJECTS: 'Can view project for customer',

  PROJECT_CREATE: 'Can create project',
  PROJECT_READ: 'Can read project data',
  PROJECT_EDIT: 'Can edit project',
  PROJECT_DELETE: 'Can delete project',
  PROJECT_LIST: 'Can see list of projects',
  PROJECT_ADD_USER: 'Can add user to project',
  PROJECT_LIST_USERS: 'Can see users in project',
  PROJECT_EDIT_DESCRIPTION: 'Can edit project description',
  PROJECT_SHOW_HISTORY: 'Can show project history',

  USER_CREATE: 'Can create user',
  USER_READ: 'Can read user info',
  USER_EDIT: 'Can edit user',
  USER_DELETE: 'Can delete user',
  USER_LIST: 'Can see list of users',

  GROUP_LIST: 'Can get list of groups',
  GROUP_READ: 'Can read group info',
  GROUP_EDIT: 'Can edit group',
  GROUP_DELETE: 'Can delete group',
  GROUP_CREATE: 'Can create group',
  GROUP_ADD_USER: 'Can add user to group',
  GROUP_DELETE_USER: 'Can delete user from group',
  GROUP_USERS: 'Can get list of users in group',
  GROUP_ADD_PERMISSION: 'Can add permission to group',
  GROUP_GET_PERMISSIONS: 'Can get permissions for group',
  GROUP_DELETE_PERMISSION: 'Can delete permission from group',

  PERMISSION_CREATE: 'Can create permission',
  PERMISSION_LIST: 'Can get list of permissions',
  PERMISSION_READ: 'Can read permission info',
  PERMISSION_EDIT: 'Can edit permission',
  PERMISSION_DELETE: 'Can delete permission',

  ALLOCATION_LIST: 'Can see list of allocations',
  ALLOCATION_READ: 'Can get data about allocation',
  ALLOCATION_CREATE: 'Can create allocation',
  ALLOCATION_DELETE: 'Can delete allocation',
  ALLOCATION_EDIT: 'Can edit allocation',
  ALLOCATION_READ_BY_USER: 'Get allocations for user',
  ALLOCATION_READ_BY_PROJECT: 'Get allocations for project',
  ALLOCATION_MANAGER_VIEW: 'Get allocations for user in allocations view',

  WORKDAY_CREATE: 'Can create workday entry',
  WORKDAY_READ: 'Can read workday entries',
  WORKDAY_EDIT: 'Can edit workday entries',
  WORKDAY_DELETE: 'Can delete workday entries',

  PKUP_CREATE: 'Can create pkup',
  PKUP_READ: 'Can read pkup',
  PKUP_EDIT: 'Can edit pkup',
  PKUP_UPDATE: 'Can update pkup',

  HOLIDAY_LIST: 'Can get list of holidays',
  HOLIDAY_CREATE: 'Can create holiday',
  HOLIDAY_DELETE: 'Can delete holiday',
  HOLIDAY_SYNC: 'Can sync holidays',

  BR_READ: 'Can read br',

  LOCATION_LIST: 'Can get list of locations',
  LOCATION_READ: 'Can read location info',
  LOCATION_EDIT: 'Can edit location',
  LOCATION_CREATE: 'Can create location',
  LOCATION_DELETE: 'Can delete location',
  LOCATION_ADD_USER: 'Can add user to location',
  LOCATION_DELETE_USER: 'Can delete user from location',
  LOCATION_USERS: 'Can get list of users in location',

  HELP_VIEW_ALL: 'Can read all questions',
  HELP_VIEW_ANSWERED: 'Can read questions with answers',
  HELP_CREATE_QUESTION: 'Can create question',
  HELP_DELETE: 'Can remove question',

  HELP_EDIT_QUESTION: 'Can edit question',
  HELP_CREATE_ANSWER: 'Can create answer',
  HELP_EDIT_ANSWER: 'Can edit answer',

  ACTIVITY_VIEW: 'Can see system activity',

  TAG_EDIT_OTHER_USER: 'Can add tags to another user',
  TAG_VIEW: 'Can manage tags in system',
  TAG_DELETE: 'User can delete tag',
  SKILL_DELETE_OTHER_USER: 'Can delete other user s skill',

  TAGS_DELETE_FROM_USER: 'Can delete tags',

  LOGIN_BY_OTHER: 'Can login to another account',

  INVENTORY_LIST: 'Can get list of inventories',
  INVENTORY_MANAGE_LIST: 'Can manage inventories',

  INVENTORY_ADD_NEW_ITEM: 'Can add new items',
  INVENTORY_DELETE_ITEM: 'Can delete items',
  INVENTORY_ASSIGN_ITEM_TO_USER: 'Can assign item to user',
  INVENTORY_EDIT_ITEM: 'Can edit items',

  INVENTORY_READ: 'Can read inventory info',
  INVENTORY_CREATE: 'Can create inventory',
  INVENTORY_DELETE: 'Can delete inventory',
  INVENTORY_EDIT: 'Can edit inventory',
  INVENTORY_VIEW: 'Can view inventory panel',
  INVENTORY_EDIT_OTHER_USER: 'Can edit a tag from other user',
  INVENTORY_DELETE_OTHER_USER: 'Can edit a tag from other user',
  AGREEMENTS_VIEW: 'Can edit/add agreements for user.',

  HOLIDAYS_CATEGORIES_LIST: 'Can see all holidays categories',
  HOLIDAYS_CATEGORIES_VIEW: 'Can see menu item holidays categories',
  HOLIDAY_CATEGORY_CREATE: 'Can create holiday category',
  HOLIDAY_CATEGORY_DELETE: 'Can delete holiday category',
  HOLIDAY_CATEGORY_EDIT: 'Can edit holiday category',
  HOLIDAY_USER_VIEW: 'Can see all user holidays',
  HOLIDAY_USER_CREATE: 'Can add holiday to user',
  HOLIDAY_USER_DELETE: 'Can delete holiday from user',
  HOLIDAY_USER_EDIT: 'Can edit user holiday ',
  BUSINESS_UNIT_LIST: 'Can get list of business unit',
  BUSINESS_UNIT_READ: 'Can read business unit inf',
  BUSINESS_UNIT_CREATE: 'Can create business unit',
  BUSINESS_UNIT_EDIT: 'Can edit business unit',
  BUSINESS_UNIT_DELETE: 'Can delete business unit',
  BUSINESS_UNIT_ADD_USER: 'Can add user to business unit',
  BUSINESS_UNIT_USERS: 'Can get list of users in business unit',
  BUSINESS_UNIT_DELETE_USER: 'Can delete user from business unit',
  EMPLOYEE_NOTES_MANAGEMENT: ' Can manage employee notes',

  CV_VIEW: 'Can view cv page',
  CV_DELETE: 'Delete bio, education, projects',
  CV_UPDATE: 'Update bio, education, projects from profile',
  CV_CREATE: 'Create bio, education, projects from profile',
  CV_MANAGE_PROFILE:
    'Add or projects bio, education projects to profile, create new profile',

  VACATION_REQUESTS_READ: 'Can read list of vacation requests',
  VACATION_REQUESTS_CREATE: 'Can create vacation requests',
  VACATION_REQUESTS_MANAGE: 'Can manage vacation requests',
  VACATION_REQUESTS_DELETE: 'Can delete vacation request',
  VACATION_REQUESTS_UPDATE: 'Can update vacation request',
  VACATION_REQUEST_ADMIN: 'Can everything in vacation request',
}
const rebuildPermissions = (permissions: Permission) => {
  const permissionList: PermissionsList = {}

  Object.keys(permissions).map((item, i) => {
    permissionList[item] = {
      index: i,
      name: permissions[item],
    }
  })

  return permissionList
}
const permissionList = rebuildPermissions(permissions)
export default permissionList
