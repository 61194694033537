import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

const deleteSingleWorkType = async (id: number | null) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.WORKTYPE_DELETE,
    {},
    { id }
  )
  return data
}
export const useDeleteWorkType = () => {
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const { mutate: deleteWorkType } = useMutation(deleteSingleWorkType, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: t('workType') + t('delete'),
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
    },
  })
  return deleteWorkType
}
