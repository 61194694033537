import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

export const useAddUser = () => {
  const { handleCloseModal, setNotification } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const addUserToProject = async (user: {
    userId: number
    isPm: boolean
    id: string | undefined
  }) => {
    const { id, ...rest } = user
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PROJECTS_ADDUSER,
      rest,
      { id }
    )
    return data
  }

  const { mutate: addUser } = useMutation(addUserToProject, {
    onSuccess: (res) => {
      if (res.data.created) {
        handleCloseModal()
        setNotification({
          message: `${t('user')} ${t('add')}`,
          variant: NOTIFICATION.success,
        })
      } else {
        handleCloseModal()
        setNotification({
          message: `${t('user')} ${t('tagsError.text3')}`,
          variant: NOTIFICATION.error,
        })
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.customer])
    },
  })

  return {
    addUser,
  }
}
