import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'
import { TextField } from 'formik-mui'

const NotesForm: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['userNotes', 'buttons'])

  const groupFields: IFields[] = [
    {
      id: '1',
      label: `${t('notes.modal.addNewNote.label1')}`,
      name: 'title',
      type: 'text',
    },
    {
      id: '2',
      label: `${t('notes.modal.addNewNote.label2')}`,
      name: 'note',
      type: 'text',
    },
  ]
  return (
    <Box mb={2}>
      {groupFields.map((field) => (
        <Field
          fullWidth
          key={field.id}
          component={TextField}
          name={field.name}
          type={field.type}
          label={field.label}
          variant="outlined"
          margin="normal"
          size="small"
        />
      ))}
    </Box>
  )
}

export default NotesForm
