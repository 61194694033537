import { useEffect, useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useWorkTypes } from 'utils'

import { useCalendarContext } from '../../../../context'

interface SelectWorkTypeProps {
  handleChange: (event: SelectChangeEvent, type: string) => void
  dayType?: string
  selectedProject: string
}

const SelectWorkType: React.FC<SelectWorkTypeProps> = ({
  handleChange,
  dayType,
  selectedProject,
}) => {
  const { setProjects } = useCalendarContext()
  const { t } = useTranslation('calendar')
  const { user } = useAuth()

  const { getUserWorkTypes, workTypesOnlyForSelectedProject, projects } =
    useWorkTypes()
  const [userWorkTypes, setUserWorkTypes] = useState(
    getUserWorkTypes('Calendar')
  )

  useEffect(() => {
    if (user?.projects?.length) {
      const selectedWorkType = user?.system?.workTypes?.find(
        (worktype) => worktype.id === Number(dayType)
      )

      const filtered = user.projects.filter(
        (project) => project.id === selectedWorkType?.projectId
      )

      if (
        !filtered.find(
          (p) => p.id.toString() === localStorage.getItem('projectId')
        ) &&
        filtered.length
      ) {
        localStorage.setItem('workTypeId', '1')
        return
      }

      selectedWorkType?.projectId
        ? setProjects(filtered)
        : setProjects(projects)
    }
  }, [dayType, user])

  useEffect(() => {
    setUserWorkTypes(
      workTypesOnlyForSelectedProject(selectedProject, 'Calendar')
    )
  }, [selectedProject])
  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id="dayType">{t('addWorkdaysModal.workType')}</InputLabel>
        <Select
          labelId="dayType"
          id="dayType"
          value={dayType}
          label={t('addWorkdaysModal.workType')}
          onChange={(e) => handleChange(e, 'dayType')}
        >
          {userWorkTypes.map((workType, i) => (
            <MenuItem key={i} value={workType?.id}>
              {workType.name}
              {workType.paid ? ' (paid)' : ' (unpaid)'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default SelectWorkType
