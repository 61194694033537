import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Container, Button, Tab, Typography } from '@mui/material'
import { HeaderTab } from 'views/LayoutUI/components'
import { DefaultModal } from 'views/LayoutUI/modals'
import { ModalComponent, useNotifications } from 'components/common'
import { permissionList, isUserPermitted } from 'services'
import { useAuth } from 'hooks/useAuth'
import { ISelectingModal, ValueType } from 'hooks/types'

import { PkupProvider } from './context/PkupContext'
import { Applications, AuthorsWork } from './components'
import {
  AddApplication,
  AddAuthorsWork,
  ApplicationRevisions,
  ShowMoreApplication,
  ShowMoreAuthorsWork,
} from './modals'
import { PaperField } from './index.style'

const Pkup: React.FC = (): JSX.Element => {
  const { t } = useTranslation('pkup')
  const [value, setValue] = useState<ValueType>('1')
  const { handleOpenModal, modalName } = useNotifications()
  const { user } = useAuth()

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addApplication':
        return {
          component: <AddApplication />,
          title: `${t('modal.addApplication.title')}`,
        }
      case 'addAuthorsWork':
        return {
          component: <AddAuthorsWork />,
          title: `${t('modal.addAuthorsWork.title')}`,
        }
      case 'showMoreApplication':
        return {
          component: <ShowMoreApplication />,
          title: `${t('modal.showMore.title')}`,
        }
      case 'showMoreAuthorsWork':
        return {
          component: <ShowMoreAuthorsWork />,
          title: `${t('modal.showMore.title')}`,
        }
      case 'revisions':
        return {
          component: <ApplicationRevisions />,
          title: `${t('modal.revisions.title')}`,
        }
      default:
        return { component: <DefaultModal />, title: `${t('modal.default')}` }
    }
  }
  return (
    <Container>
      <PkupProvider>
        <HeaderTab translate="pkup" />
        <Box>
          {isUserPermitted(user, [permissionList.PKUP_CREATE]) && (
            <PaperField>
              <Typography variant="h3">{t('instruction.header')} </Typography>
              <Typography variant="subtitle1">
                {t('instruction.text1')}{' '}
              </Typography>
              <Button onClick={() => handleOpenModal('addApplication')}>
                {t('instruction.button1')}
              </Button>
              <Typography variant="subtitle1">
                {t('instruction.text2')}
              </Typography>
              <Button onClick={() => handleOpenModal('addAuthorsWork')}>
                {t('instruction.button2')}
              </Button>
            </PaperField>
          )}
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label={t('tabPanels.panel1.title')} value="1" />
                <Tab label={t('tabPanels.panel2.title')} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Applications />
            </TabPanel>
            <TabPanel value="2">
              <AuthorsWork />
            </TabPanel>
          </TabContext>
        </Box>
        <ModalComponent
          component={selectingModal().component}
          title={selectingModal().title}
        />
      </PkupProvider>
    </Container>
  )
}
export default Pkup
