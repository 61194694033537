import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Formik } from 'formik'
import { useAuth } from 'hooks/useAuth'
import { CloseButton } from 'components/common'
import { useTranslation } from 'react-i18next'

import { useFiltersBar } from './utils'
import { ButtonComponent, ButtonWrapper, FormWrapper } from './index.style'

interface Types {
  id: number
  name: string
}

const FiltersBar: React.FC = (): JSX.Element => {
  const { initialValues, onSubmit, handleChange } = useFiltersBar()

  const { user } = useAuth()
  const { t } = useTranslation(['calendar', 'buttons'])

  const workdayTypes: Types[] = [
    {
      id: 1,
      name: t('filterbar.all'),
    },
    {
      id: 2,
      name: t('filterbar.planned'),
    },
    {
      id: 3,
      name: t('filterbar.worked'),
    },
  ]
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ submitForm, isSubmitting }) => (
          <FormWrapper>
            <FormControl fullWidth size="small">
              <InputLabel id="projectLabel">{t('filterbar.label1')}</InputLabel>
              <Select
                labelId="projectLabel"
                id="project"
                value={initialValues.project ? initialValues.project : '1'}
                label={t('filterbar.label1')}
                onChange={(e) => handleChange(e, 'project')}
              >
                <MenuItem value={1}>{t('filterbar.all')}</MenuItem>
                {user?.projects?.map((project, i) => (
                  <MenuItem key={i} value={i + 2}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel id="WorkdayType">{t('filterbar.label2')}</InputLabel>
              <Select
                labelId="WorkdayType"
                id="WorkdayType"
                value={
                  initialValues.workdayType ? initialValues.workdayType : '1'
                }
                label={t('filterbar.label2')}
                onChange={(e) => handleChange(e, 'workdayType')}
              >
                {workdayTypes?.map((workdayType: Types, i) => (
                  <MenuItem key={i} value={i + 1}>
                    {workdayType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel id="workTypeLabel">
                {t('filterbar.label3')}
              </InputLabel>
              <Select
                labelId="workTypeLabel"
                id="workType"
                value={initialValues.workType ? initialValues.workType : '1'}
                label={t('filterbar.label3')}
                onChange={(e) => handleChange(e, 'workType')}
              >
                <MenuItem value={1}>{t('filterbar.all')}</MenuItem>
                {user?.system?.workTypes?.map((worktype, i) => (
                  <MenuItem key={i} value={i + 2}>
                    {worktype.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ButtonWrapper>
              <ButtonComponent
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {t('save', { ns: 'buttons' })}
              </ButtonComponent>
              <CloseButton />
            </ButtonWrapper>
          </FormWrapper>
        )}
      </Formik>
    </>
  )
}

export default FiltersBar
