import { Box, styled } from '@mui/material'

export const DownloadBarConatiner = styled(Box)({
  width: '90%',
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  gap: '10px',
})

export const DataContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '2rem',
})

export const ButtonsContainer = styled(Box)({
  display: 'flex',
  gap: '2rem',
  justifyContent: 'space-evenly',
})
