import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useNotifications } from 'components/common'
import { useAuth } from 'hooks/useAuth'
import { permissionList, isUserPermitted } from 'services'
import { setLoginAsOtherToken } from 'user-storage'

export const useEditUsers = () => {
  const { id } = useParams()
  const { user } = useAuth()
  const { handleCloseModal } = useNotifications()
  const canEditTags = isUserPermitted(user, [
    permissionList.TAG_EDIT_OTHER_USER,
  ])
  const { updateUser, refetchUser } = useAuth()
  const loginByOthers = async (userId: number) => {
    apiRequest(APIRoutes.AUTH_LOGIN_BY_OTHER, { userId: userId }).then(
      (response) => {
        if (response) {
          setLoginAsOtherToken(response.data.data.token)
          const userInfo = apiRequest(APIRoutes.AUTH_ME)
          userInfo.then((userData) => {
            updateUser(userData.data)
            refetchUser()
          })
        }
      }
    )
  }

  const { mutate: loginAsUser } = useMutation(() => loginByOthers(Number(id)), {
    onSuccess: async () => {
      handleCloseModal()
    },
  })

  return { loginAsUser, canEditTags }
}
