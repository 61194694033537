interface IArray {
  id: number
  name?: string
}
interface IArrayWithName {
  id: number
  name: string
}
export const filterArrayById = (array1: IArray[], array2: IArray[]) => {
  return array1?.filter((object1) => {
    return !array2?.some((object2) => {
      return object1.id === object2.id
    })
  })
}

export const filterArrayByName = (
  array1: IArrayWithName[],
  array2: IArrayWithName[]
) => {
  return array1?.filter((object1) => {
    return array2?.some((object2) => {
      return object1.name.toLowerCase() === object2.name.toLowerCase()
    })
  })
}
