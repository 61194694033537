import { useTranslation } from 'react-i18next'
import { Box, IconButton, Tooltip } from '@mui/material'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import FeedbackIcon from '@mui/icons-material/Feedback'
import EditIcon from '@mui/icons-material/Edit'
import palette from 'theme/palette'

enum IndexEnum {
  'review',
  'approved',
  'rejected',
  'modify',
  'created',
}

export const StatusEnum = {
  REVIEW: 1,
  APPROVED: 2,
  REJECTED: 3,
  MODIFY: 4,
  CREATED: 5,
}

export const states = [
  {
    id: 1,
    text: IndexEnum[0],
  },
  {
    id: 2,
    text: IndexEnum[1],
  },
  {
    id: 3,
    text: IndexEnum[2],
  },
  {
    id: 4,
    text: IndexEnum[3],
  },
  {
    id: 5,
    text: IndexEnum[4],
  },
]
interface INormalStatus {
  [key: string]: {
    title: string
    titleDashboard: string
    color: string
    tooltip: string

    icon: JSX.Element
  }
}

export const useStatus = () => {
  const { t } = useTranslation('pkup')
  const normalStatus: INormalStatus = {
    review: {
      title: `${t('normalStatus.title1')}`,
      titleDashboard: `${t('normalStatus.titleDashboard1')}`,
      tooltip: `${t('normalStatus.tooltip1')}`,
      color: palette.primary.light,
      icon: (
        <HourglassEmptyIcon
          id="PkupsIconStatus"
          style={{ fontSize: '24px', color: palette.primary.light }}
        />
      ),
    },
    approved: {
      title: `${t('normalStatus.title2')}`,
      titleDashboard: `${t('normalStatus.titleDashboard2')}`,
      tooltip: `${t('normalStatus.tooltip2')}`,
      color: palette.success.main,
      icon: (
        <CheckIcon
          id="PkupsIconStatus"
          style={{ fontSize: '24px', color: palette.success.main }}
        />
      ),
    },
    rejected: {
      title: `${t('normalStatus.title3')}`,
      titleDashboard: `${t('normalStatus.titleDashboard3')}`,
      tooltip: `${t('normalStatus.tooltip3')}`,
      color: palette.error.main,
      icon: (
        <CloseIcon
          id="PkupsIconStatus"
          style={{ fontSize: '24px', color: palette.error.main }}
        />
      ),
    },
    modify: {
      title: `${t('normalStatus.title4')}`,
      titleDashboard: `${t('normalStatus.titleDashboard4')}`,
      tooltip: `${t('normalStatus.tooltip4')}`,
      color: palette.warning.light,
      icon: (
        <FeedbackIcon
          id="PkupsIconStatus"
          style={{ fontSize: '24px', color: palette.warning.light }}
        />
      ),
    },
    created: {
      title: `${t('normalStatus.title5')}`,
      titleDashboard: `${t('normalStatus.titleDashboard5')}`,
      tooltip: `${t('normalStatus.tooltip5')}`,
      color: palette.warning.dark,
      icon: (
        <EditIcon
          id="PkupsIconStatus"
          style={{ fontSize: '24px', color: palette.warning.dark }}
        />
      ),
    },
  }

  const getPkupStatus = (stateId: number) => {
    return normalStatus[IndexEnum[stateId - 1]]
  }

  const statusBox = (application, page?) => (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
      color={getPkupStatus(application.state)?.color}
    >
      <Tooltip
        title={
          page === 'pkup'
            ? getPkupStatus(application.state)?.tooltip || ''
            : getPkupStatus(application.state)?.titleDashboard || ''
        }
      >
        <IconButton>{getPkupStatus(application.state)?.icon}</IconButton>
      </Tooltip>
      {getPkupStatus(application.state)?.title}
    </Box>
  )

  return {
    getPkupStatus,
    statusBox,
  }
}
