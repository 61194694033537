import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ImportExportIcon from '@mui/icons-material/ImportExport'

interface ICurrentOrder {
  order: string
  column: string
}
export const orderIcon = (
  currentOrder: ICurrentOrder | null,
  column: string
) => {
  if (currentOrder === null || currentOrder === undefined) {
    return <ImportExportIcon sx={{ opacity: '0.5' }} />
  }
  if (currentOrder?.column === column) {
    if (currentOrder?.order === 'ASC') {
      return <ArrowDownwardIcon />
    } else {
      return <ArrowUpwardIcon />
    }
  }
  return <ImportExportIcon sx={{ opacity: '0.3' }} />
}
