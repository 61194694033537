import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'

const getHolidaysSyncFun = async (userId?: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.HOLIDAYS_SYNC,
    {},
    { id: userId }
  )
  return data
}

export const useHolidaysSync = () => {
  const { setNotification } = useNotifications()
  const { t } = useTranslation('handleMessage')

  const { mutate: getHolidaysSync } = useMutation(
    (userId?: number) => getHolidaysSyncFun(userId),
    {
      onSuccess: () => {
        setNotification({
          message: `${t('holidays')} ${t('sync')}`,
          variant: NOTIFICATION.success,
        })
      },
    }
  )

  return getHolidaysSync
}
