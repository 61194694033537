import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'

interface DownloadData {
  id: string | undefined
  month: string | number | Date | null
}

export function useDownloadExcel() {
  async function downloadExcel(downloadData: DownloadData) {
    const { id, ...rest } = downloadData
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_DOWNLOADFORPROJECT,
      rest,
      { id }
    )
    return data
  }

  return {
    downloadExcel,
  }
}
