import { useState } from 'react'

import CVFormik from './CVFormik'

const GenerateCV: React.FC = (): JSX.Element => {
  const [cvPreview, setCVPreview] = useState<string>('')

  return <CVFormik setCVPreview={setCVPreview} cvPreview={cvPreview} />
}
export default GenerateCV
