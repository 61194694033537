import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'

export function useCalendarHook() {
  async function downloadUOPWorkDays(body: { month: string | Date | null }) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_DOWNLOADUOPFORME,
      body
    )
    return data
  }

  async function downloadWorkDays(body: { month: string | Date | null }) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_DOWNLOADFORME,
      body
    )
    return data
  }

  return {
    downloadUOPWorkDays,
    downloadWorkDays,
  }
}
