import { useTranslation } from 'react-i18next'
import { Grid, Paper, IconButton, Tooltip } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { IBio, useUser } from 'pages/Users/pages/utlis'
import { EmptyArrayCard, TableComponent, TableTitle } from 'components/common'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { IButtonArr } from 'hooks/types'

import { ISelectedOptions } from '../UserCV'

interface BioAreaProps {
  handleOpen: (
    name: string,
    position: ISelectedOptions,
    category: string
  ) => void
  buttonsArr: IButtonArr[]
}
const BioArea: React.FC<BioAreaProps> = ({
  handleOpen,
  buttonsArr,
}): JSX.Element => {
  const { t } = useTranslation(['userCV', 'buttons'])
  const { userBio } = useUser()
  const { user } = useAuth()

  const userBioColumns = [
    `${t('cv.bioTable.title')}`,
    `${t('cv.bioTable.description')}`,
    '',
  ]

  const userBioRows: GridRowsProp = userBio?.map((bio: IBio) => {
    return {
      title: bio?.title,
      description: bio?.description,
      button: buttonsArr.map(
        (button) =>
          isUserPermitted(user, [
            button.modal === 'edit'
              ? permissionList.CV_UPDATE
              : permissionList.CV_DELETE,
          ]) && (
            <Tooltip key={button.title} title={button.title as string}>
              <IconButton
                onClick={() => handleOpen(button.modal as string, bio, 'bio')}
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          )
      ),
    }
  })

  return (
    <Grid item xs={10} md={6} sx={{ background: 'none', border: 'none' }}>
      <TableTitle>{t('cv.bioTable.bio')}</TableTitle>
      <Paper>
        {userBioRows.length === 0 ? (
          <EmptyArrayCard message={t('cv.bioTable.message')} />
        ) : (
          <TableComponent columns={userBioColumns} rows={userBioRows} />
        )}
      </Paper>
    </Grid>
  )
}

export default BioArea
