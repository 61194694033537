import { useNotifications } from 'components/common'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'

export interface IIsPmData {
  projectId: string | undefined
  userId: string
}

const updateBoolean = async (isPmData: IIsPmData) => {
  const { projectId, userId } = isPmData
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PROJECTS_TOGGLEPM,
    {},
    {
      projectId,
      userId,
    }
  )
  return data
}

export const useUpdateIsPM = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')

  const { mutate: updateIsPM } = useMutation(updateBoolean, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('user')} ${t('update')}`,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.customer])
    },
  })

  return { updateIsPM }
}
