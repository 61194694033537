import FullCalendar, { EventSourceInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { format } from 'date-fns'
import { useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EventList from 'pages/Calendar/EventList'
import { useRef, useEffect, LegacyRef, useState } from 'react'
import enLocale from '@fullcalendar/core/locales/en-gb'

import EventContentCalendar from '../../Event'
import { useCalendarContext } from '../../context'

import { StyleWrapper } from './index.style'

const CalendarComponent: React.FC = (): JSX.Element => {
  const {
    selectedWorkDays,
    handleDateSelect,
    handleEventDrag,
    handleEventResize,
    handleEventClick,
    holidays,
    isFilter,
    filteredWorkDays,
    setDeleteMode,
    setCopyMode,
    copyMode,
    deleteMode,
  } = useCalendarContext()
  const {
    setCurrentCalendarDate,
    selectedUserWorkDays,
    handleCloseBar,
    isOpenPopup,
    setOpenNotification,
    openSideBar,
    barName,
    openModal,
  } = useNotifications()
  const { t } = useTranslation('calendar')
  const { id } = useParams()
  const calendar: LegacyRef<FullCalendar> | undefined = useRef(null)
  const [_, setUpdate] = useState(true)
  const calendarNavigation = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      calendar?.current?.getApi()?.prev()
    }
    if (e.key === 'ArrowRight') {
      calendar?.current?.getApi()?.next()
    }
  }

  useEffect(() => {
    if (!openModal && !barName)
      window.addEventListener('keyup', calendarNavigation)
    return () => window.removeEventListener('keyup', calendarNavigation)
  }, [openModal, barName])

  useEffect(() => {
    calendar?.current?.getApi().updateSize()
  }, [openSideBar])
  return (
    <StyleWrapper isopenpopup={isOpenPopup.toString()}>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
        eventChange={(e) => e.event.remove()}
        timeZone="local"
        locale={enLocale}
        ref={calendar}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        }}
        eventDragStart={() => setOpenNotification(false)}
        datesSet={(dateInfo) => {
          setCurrentCalendarDate(
            format(
              new Date(dateInfo.start.setDate(dateInfo.start.getDate() + 10)),
              'yyyy-MM'
            )
          )
          setDeleteMode(false)
          setCopyMode(false)
          handleCloseBar()
        }}
        showNonCurrentDates
        stickyHeaderDates
        buttonText={{
          today: t('dateType.today'),
          month: t('dateType.month'),
          week: t('dateType.week'),
          day: t('dateType.day'),
          listWeek: t('dateType.list'),
        }}
        nowIndicator
        firstDay={1}
        dayHeaderFormat={{
          day: '2-digit',
          weekday: 'short',
          omitCommas: true,
        }}
        dragScroll={false}
        slotLabelFormat={{ hour: 'numeric', minute: '2-digit' }}
        views={{
          dayGridMonth: {
            dayHeaderFormat: { weekday: 'long' },
          },
          listWeek: {
            eventContent: EventList,
          },
        }}
        editable
        events={
          id
            ? ([...holidays, ...selectedUserWorkDays] as EventSourceInput)
            : isFilter || copyMode || deleteMode
            ? ([...holidays, ...filteredWorkDays] as EventSourceInput)
            : ([...holidays, ...selectedWorkDays] as EventSourceInput)
        }
        eventDisplay="block"
        selectable={id ? false : true}
        selectMirror
        dayMaxEvents={false}
        select={handleDateSelect}
        eventClick={handleEventClick}
        eventDrop={(data) => {
          handleEventDrag(data)
          setUpdate((prev) => !prev)
        }}
        eventResize={(data) => {
          handleEventResize(data)
          setUpdate((prev) => !prev)
        }}
        slotMaxTime="23:00:00"
        slotMinTime="06:00:00"
        slotDuration="00:30:00"
        slotLabelInterval={{ hours: 1 }}
        eventColor="rgba(79, 76, 76, 0.619)"
        slotEventOverlap={false}
        allDaySlot={false}
        businessHours={{
          startTime: '06:00',
          endTime: '23:00',
          daysOfWeek: [1, 2, 3, 4, 5],
        }}
        droppable={!id}
        eventContent={EventContentCalendar}
        eventMaxStack={2}
        height="100%"
      />
    </StyleWrapper>
  )
}

export default CalendarComponent
