import { Box, Container } from '@mui/material'
import { useGetCustomerProject } from 'hooks/customers'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { HeaderTab } from 'views/LayoutUI/components'
import { PageRoutes } from 'routes'

import {
  Calendar,
  DetailsAndDesc,
  History,
  Stack,
  TeamTable,
} from './components'

const ProjectDetails = () => {
  const params = useParams()
  const customerProject = useGetCustomerProject(params.id)
  const { t } = useTranslation(['projectDetails', 'buttons'])

  return (
    <Container>
      <HeaderTab
        translate="projectDetails"
        id={t(customerProject.name)}
        headTitle={`${t('header.title')} ${customerProject.name}`}
        pageLink={PageRoutes.PROJECTS}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
        <Container>
          <Calendar />
        </Container>
        <TeamTable />
        <DetailsAndDesc />
        <Stack />
        <History />
      </Box>
    </Container>
  )
}

export default ProjectDetails
