import { useTranslation } from 'react-i18next'
import ScheduleIcon from '@mui/icons-material/Schedule'
import SchoolIcon from '@mui/icons-material/School'
import HomeIcon from '@mui/icons-material/Home'
import { useUser } from 'pages/Users/pages/utlis'

import { ISelectedOptions } from '../../../UserCV'
import CardCheckboxOption from '../CardCheckboxOption'

const EducationOption: React.FC = (): JSX.Element => {
  const { t } = useTranslation('userCV')

  const { userEdu } = useUser()

  const EducationFields = (field: ISelectedOptions) => [
    {
      name: field.years,
      title: `${t('cv.eduTable.years')}`,
      icon: <ScheduleIcon />,
    },
    {
      name: field.grade,
      title: `${t('cv.eduTable.grade')}`,
      icon: <SchoolIcon />,
    },
    {
      name: field.place,
      title: `${t('cv.eduTable.place')}`,
      icon: <HomeIcon />,
    },
  ]
  return (
    <CardCheckboxOption
      name="selectedEducation"
      title={t('generateProfile.selectEdu')}
      optionArray={EducationFields}
      userDetails={userEdu}
    />
  )
}

export default EducationOption
