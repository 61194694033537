import { Dispatch, SetStateAction } from 'react'
import { IAllocations, IUser } from 'hooks/types'

export interface ISelectEditAllocationProps {
  setEditAllocation: Dispatch<SetStateAction<IAllocations | null>>
}

export const getProjectName = (
  allocation: IAllocations | null | undefined,
  user: IUser | null
) => {
  const project = user?.projects?.find(
    (project) => project.id === allocation?.ProjectUser?.projectId
  )
  if (project) return project.name
  return ''
}

export const getAllHours = (allocations: IAllocations[] | undefined) => {
  if (!allocations?.length) return 0

  const reduce = allocations
    .map((allocation) => allocation.allWorkingHours)
    .reduce((a, b) => a + b, 0)
  return reduce
}

export const getAllFTE = (allocations: IAllocations[] | undefined) => {
  if (!allocations?.length) return 0

  const reduce = allocations
    .map((alloc) => alloc.FTE)
    .reduce((a, b) => a + b, 0)
  return reduce
}
