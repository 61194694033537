import { Box, Grid, Tabs, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IUserProps } from 'components/users'

import { AllocationInMonth, CustomAllocation } from './components'
import { useAllocations } from './utils'
import { useGetWorkdaysInMonth } from './components/utils'
import { selectedAllocation } from './selectedAllocations'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const Allocations: React.FC<IUserProps> = ({ user }): JSX.Element => {
  const { t } = useTranslation('userEditPage')
  const { handleChange, tabValue, allocations } = useAllocations()

  const workdaysInMonth = useGetWorkdaysInMonth()

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    }
  }
  return (
    <>
      <Grid container>
        <Grid item xs={2} md={3}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleChange}
            aria-label="Allocations from user"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label={t('allocations.table.tab1')} {...a11yProps(0)} />
            <Tab label={t('allocations.table.tab2')} {...a11yProps(1)} />
            <Tab label={t('allocations.table.tab3')} {...a11yProps(2)} />
            <Tab label={t('allocations.table.tab4')} {...a11yProps(3)} />
          </Tabs>
        </Grid>
        <Grid item xs={9} md={9}>
          <TabPanel value={tabValue} index={0}>
            <AllocationInMonth
              allocations={selectedAllocation(
                allocations,
                workdaysInMonth,
                user
              )}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <AllocationInMonth
              allocations={selectedAllocation(
                allocations,
                workdaysInMonth,
                user
              )}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <AllocationInMonth
              allocations={selectedAllocation(
                allocations,
                workdaysInMonth,
                user
              )}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <CustomAllocation
              allocations={selectedAllocation(
                allocations,
                workdaysInMonth,
                user
              )}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  )
}
export default Allocations
