import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { IPaginateReducer, defaultPaginationValue } from 'hooks/table'
import { IHistory } from 'hooks/user/types'

const getProjectHistory = async (
  id: number,
  params: string
): Promise<IHistory> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PROJECTS_GETONE_HISTORY,
    {},
    { projectId: id },
    `${params}`
  )
  return data
}

export const useGetProjectHistory = () => {
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>(
    defaultPaginationValue
  )
  const fallback = <IHistory>{}
  const { id } = useParams()
  const params = `page=${paginationUc?.currentPage + 1 || 1}&limit=${
    paginationUc?.length || 10
  }`

  const { data: projectGetOneHistory = fallback, refetch } = useQuery(
    queryKeys.projectHistory,
    () => getProjectHistory(Number(id), params)
  )

  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }

  const total = projectGetOneHistory.total

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])
  useEffect(() => {
    refetch()
  }, [paginationUc])

  return { total, changePagination, paginationUc, projectGetOneHistory }
}
