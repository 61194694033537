import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LogoutIcon from '@mui/icons-material/Logout'
import RefreshIcon from '@mui/icons-material/Refresh'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PersonIcon from '@mui/icons-material/Person'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Tooltip, Typography } from '@mui/material'
import { PageRoutes } from 'routes'
import { useAuth } from 'hooks/useAuth'
import { useNotifications } from 'components/common'
import { getTokenOther } from 'user-storage'
import palette from 'theme/palette'

import { IconButtons, ProfileText } from './index.style'

const UserDetails = () => {
  const { user } = useAuth()

  return (
    <>
      <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
        <ProfileText variant="h2" color="white" sx={{ lineHeight: '1.2rem' }}>
          {user?.firstName} {user?.lastName}
        </ProfileText>
      </Tooltip>
      <Tooltip title={`${user?.email}`}>
        <ProfileText
          variant="subtitle1"
          color="white"
          sx={{
            fontSize: '0.7rem',
            lineHeight: '0.75rem',
          }}
        >
          {user?.email}
        </ProfileText>
      </Tooltip>
    </>
  )
}

const UserSettings = () => {
  const navigate = useNavigate()
  const { logoutAsUser, logout } = useAuth()
  const { isPlaying, setIsPlaying } = useNotifications()
  const { t } = useTranslation('sidebar')
  const { pathname } = useLocation()
  const isDashboardPage = pathname === '/'
  const otherUserToken = getTokenOther()

  return (
    <Box marginBottom={2}>
      {otherUserToken ? (
        <>
          <Box
            sx={{
              backgroundColor: palette.error.main,
              borderRadius: '5px',
              padding: 2,
            }}
          >
            <Box>
              <Typography>
                <WarningIcon /> {t('loggedAsInfo')}
              </Typography>
              <UserDetails />
            </Box>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Tooltip title={`${t('tooltip.logoutAsUser')}`}>
                <IconButtons onClick={logoutAsUser}>
                  <LogoutIcon />
                </IconButtons>
              </Tooltip>
            </Box>
          </Box>
        </>
      ) : (
        <UserDetails />
      )}

      <Box display="flex" justifyContent="flex-end">
        <Tooltip title={`${t('tooltip.profile')}`}>
          <IconButtons onClick={() => navigate(PageRoutes.PROFILE)}>
            <PersonIcon color="inherit" />
          </IconButtons>
        </Tooltip>
        <Tooltip title={`${t('tooltip.refresh')}`}>
          <IconButtons onClick={() => window.location.reload()}>
            <RefreshIcon color="inherit" />
          </IconButtons>
        </Tooltip>
        {isDashboardPage && (
          <Tooltip
            title={
              isPlaying
                ? `${t('tooltip.disableAnimation')}`
                : `${t('tooltip.startAnimation')}`
            }
          >
            <IconButtons onClick={() => setIsPlaying((prev) => !prev)}>
              {isPlaying ? (
                <PauseIcon color="inherit" />
              ) : (
                <PlayArrowIcon color="inherit" />
              )}
            </IconButtons>
          </Tooltip>
        )}
        <Tooltip title={`${t('tooltip.logout')}`}>
          <IconButtons onClick={logout}>
            <LogoutIcon color="inherit" />
          </IconButtons>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default UserSettings
