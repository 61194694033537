import { ReactElement } from 'react'
import { useIsFetching, useIsMutating } from 'react-query'
import { Box, CircularProgress } from '@mui/material'

import { StyledContainer, StyledBox } from './index.style'

const Loading = (): ReactElement => {
  const isFetchingDetails = useIsFetching(['userDetails'])
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  const display =
    (isFetching && !isFetchingDetails) || isMutating ? 'inherit' : 'none'
  return (
    <StyledContainer style={{ display: display }}>
      <StyledBox>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <CircularProgress size={60} sx={{ color: 'white' }} />
        </Box>
      </StyledBox>
    </StyledContainer>
  )
}

export default Loading
