import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IPkupsToReview } from 'pages/HrDashboard/hooks/useHrDashboard'
import { IUsers } from 'hooks/types'
export const usePkupsWithFilter = (user?: IUsers | null, state?: number) => {
  const fallback: IPkupsToReview[] = []

  async function getPkupsWithFilter(): Promise<IPkupsToReview[]> {
    if (user !== null) {
      const { data }: AxiosResponse = await apiRequest(
        APIRoutes.PKUP_GET_ALL_FOR_USER,
        {},
        { userId: user?.id, state }
      )
      return data
    }
    return []
  }

  const { data: filteredPkupsList = fallback, refetch } = useQuery(
    queryKeys.filteredPkupsList,
    getPkupsWithFilter
  )
  useEffect(() => {
    refetch()
  }, [user, state])

  return filteredPkupsList
}
