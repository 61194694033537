import { useEffect } from 'react'
import { Box, Modal } from '@mui/material'
import { ModalHeader, useNotifications } from 'components/common'

import { ModalBox } from './index.style'

const ModalComponent = ({
  component,
  title,
  setMode,
  className = '',
}: {
  component: JSX.Element
  title: string
  className?: string
  setMode?: (bool: boolean) => void
}): JSX.Element => {
  const { openModal, handleCloseModal } = useNotifications()

  useEffect(
    () =>
      (window.onpopstate = () => {
        handleCloseModal()
      }),
    []
  )
  return (
    <Modal
      open={openModal}
      onClose={() => {
        handleCloseModal()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ overflow: 'auto' }}
    >
      <ModalBox className={className}>
        <ModalHeader
          handleClose={() => {
            handleCloseModal()
            setMode?.(false)
          }}
          title={title}
        />
        <Box sx={{ overflow: 'auto', padding: 3 }}>{component}</Box>
      </ModalBox>
    </Modal>
  )
}

export default ModalComponent
