import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNotifications, NOTIFICATION } from 'components/common'
import { QueryClientProvider, QueryClient, MutationCache } from 'react-query'
import { clearStoredUser } from 'user-storage'
import UserContext from 'contexts/user'

const QueryProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { setNotification } = useNotifications()
  const { setUser } = useContext(UserContext)
  const { t } = useTranslation('handleMessage')
  function queryErrorHandler(error: any) {
    const ERROR = t('networkError')
    const errorTitle = axios.isAxiosError(error)
      ? error?.response?.data?.message ||
        error.toString().replace('Error: ', '')
      : ERROR
    if (error?.response?.status === 401) {
      clearStoredUser()
      setUser(null)
      setNotification({ permissionAlert: errorTitle })
    } else {
      setNotification({ message: errorTitle, variant: NOTIFICATION.error })
    }
  }

  const defaultQueryClientOptions = {
    queries: {
      onError: queryErrorHandler, //here handle error
      staleTime: 600000, // 10 minutes
      cacheTime: 900000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  }

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: defaultQueryClientOptions,
        mutationCache: new MutationCache({
          onError: queryErrorHandler,
        }),
      })
  )

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export default QueryProvider
