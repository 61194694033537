import { Grid, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetAllTags } from 'hooks/tags'
import DeleteIcon from '@mui/icons-material/Delete'
import { EmptyArrayCard } from 'components/common'
import { permissionList, isUserPermitted } from 'services'
import { useAuth } from 'hooks/useAuth'

import { BoxTag } from './index.styles'
import { useAllTags } from './utils'
import AddTagForm from './AddTagForm'

const AllTags: React.FC = (): JSX.Element => {
  const { t } = useTranslation('systemTags')
  const tagsList = useGetAllTags()
  const { deleteTag } = useAllTags()
  const { user } = useAuth()

  return (
    <>
      <AddTagForm tagsList={tagsList} />
      {tagsList.length ? (
        <Grid container spacing={1}>
          {tagsList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ id, name }) => (
              <Grid item xs={3} key={`${id}-tag`}>
                <BoxTag>
                  <Typography color="primary">{name}</Typography>
                  {isUserPermitted(user, [permissionList.TAG_DELETE]) && (
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={() => deleteTag(id)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
                </BoxTag>
              </Grid>
            ))}
        </Grid>
      ) : (
        <EmptyArrayCard message={t('tabsPanels.panel2.noTags')} />
      )}
    </>
  )
}

export default AllTags
