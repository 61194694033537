import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { HolidayUnit } from 'hooks/types'

const getHolidayUnits = async (): Promise<HolidayUnit[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.HOLIDAYS_UNITS_GETALL
  )
  return data
}

export const useGetAllHolidayUnits = () => {
  const fallback: HolidayUnit[] = []
  const { data: holidayUnits = fallback } = useQuery(
    queryKeys.holidayUnits,
    getHolidayUnits
  )

  return { holidayUnits }
}
