import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IProfile } from 'pages/Users/pages/utlis'

export interface IInitialValues {
  selectedTemplate: string
  selectedProfile: IProfile | null
  selectedBio: string
  selectedEducation: string[]
  selectedProjects: string[]
}
export const useGenerateCV = (
  userId?: number | undefined,
  selectedBio?: string,
  selectedTemplate?: string,
  selectedEducation?: string[],
  selectedProjects?: string[]
) => {
  const fetchTemplates = async () => {
    const { data }: AxiosResponse = await apiRequest(APIRoutes.CV_TEMPLATES_GET)
    return data
  }

  const previewUserCV = async (params: string) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_PREVIEW,
      {},
      {},
      params
    )
    return data
  }

  const generateUserCV = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_GENERATE,
      {
        template: selectedTemplate,
        userId: userId,
        biosIds: [Number(selectedBio)],
        educationsIds: selectedEducation?.map((education) => Number(education)),
        projectsIds: selectedProjects?.map((project) => Number(project)),
      },
      {}
    )
    return data
  }
  const fallbackTemplates = [] as string[]
  const { data: templates = fallbackTemplates } = useQuery(
    queryKeys.templates,
    fetchTemplates
  )

  return {
    templates,
    fetchTemplates,
    previewUserCV,
    generateUserCV,
  }
}
