import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Typography,
} from '@mui/material'
import { TextField as TF } from 'formik-mui'
import { useGetAgreements } from 'hooks/agreements'
import { CustomizedSelect } from 'components/common'
import { useGetAllCustomers } from 'hooks/customers'

import { ModalContentContainer } from '../../index.style'
import { WorkTypeAutocomplete } from '../../components'
import { useArrays } from '../../hooks'

import { useAddWorkType } from './utils'

const AddNewWorkType = () => {
  const { t } = useTranslation(['systemWorkTypes', 'buttons'])
  const { fieldArr, customerFields } = useArrays()
  const agreementsList = useGetAgreements()
  const customersList = useGetAllCustomers()
  const { addNewWorkType, initialValues, validationSchema } = useAddWorkType()

  return (
    <ModalContentContainer>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            addNewWorkType(values)
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, values }) => {
            return (
              <Form style={{ display: 'flex', flexDirection: 'column' }}>
                {customerFields.map(({ id, name, type, label }) => (
                  <Field
                    fullWidth
                    key={id}
                    component={TF}
                    name={name}
                    type={type}
                    label={label}
                    style={{ marginBottom: 10, marginTop: 5 }}
                  />
                ))}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h4">
                    {t('modals.form.title8')}:
                  </Typography>
                  <Grid container width="85%">
                    {fieldArr.map((field) => (
                      <Grid item xs={5} key={field.name}>
                        <Field
                          as={FormControlLabel}
                          type="checkbox"
                          name={field.name}
                          control={<Checkbox />}
                          label={field.label}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <FormControl sx={{ marginY: '10px' }}>
                  <InputLabel id={t('modals.form.label1')}>
                    {t('modals.form.label1')}
                  </InputLabel>
                  <Field
                    label={t('modals.form.label1')}
                    name="customer"
                    component={CustomizedSelect}
                    fieldValueToChange="project"
                    changeToValue="none"
                  >
                    <MenuItem value={t('modals.none')}>
                      {t('modals.none')}
                    </MenuItem>
                    {customersList?.map(({ id, name }) => (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                {values.customer !== t('modals.none') &&
                customersList?.length ? (
                  <FormControl sx={{ marginBottom: '10px' }}>
                    <InputLabel id={t('modals.form.label5')}>
                      {t('modals.form.label5')}
                    </InputLabel>
                    <Field
                      label={t('modals.form.label5')}
                      name="project"
                      component={CustomizedSelect}
                    >
                      <MenuItem value={t('modals.none')}>
                        {t('modals.none')}
                      </MenuItem>
                      {customersList
                        ?.find((customer) => customer.name === values.customer)
                        ?.projects.map(({ id, name }) => (
                          <MenuItem key={id} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                    </Field>
                  </FormControl>
                ) : null}
                <Field
                  component={WorkTypeAutocomplete}
                  optionsData={agreementsList}
                  name="agreements"
                />
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  sx={{ width: '30%', marginTop: '10px' }}
                >
                  {t('save', { ns: 'buttons' })}
                </Button>
                {isSubmitting && <LinearProgress />}
              </Form>
            )
          }}
        </Formik>
      </Box>
    </ModalContentContainer>
  )
}

export default AddNewWorkType
