import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'
import { TextField } from 'formik-mui'

const BusinessUnitForm: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['systemBusinessUnits', 'buttons'])

  const groupFields: IFields[] = [
    {
      id: '1',
      label: `${t('modal.add.label1')}`,
      name: 'name',
      type: 'text',
    },
    {
      id: '2',
      label: `${t('modal.add.label2')}`,
      name: 'description',
      type: 'text',
    },
    {
      id: '3',
      label: `${t('modal.add.label3')}`,
      name: 'email',
      type: 'email',
    },
  ]
  return (
    <Box mb={2}>
      {groupFields.map((field) => (
        <Field
          fullWidth
          key={field.id}
          component={TextField}
          name={field.name}
          type={field.type}
          label={field.label}
          variant="outlined"
          margin="normal"
          size="small"
        />
      ))}
    </Box>
  )
}

export default BusinessUnitForm
