import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const useValidationAllocationForm = () => {
  const { t } = useTranslation('validation')

  const validationSchema = yup.object({
    fte: yup.string().required(t('fte')),
    hours: yup.string().required(t('hours')),
    selectedProject: yup
      .object()
      .required(t('project.required'))
      .nullable(true),
  })
  return validationSchema
}
