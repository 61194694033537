import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

export const useAddTimeframes = () => {
  const { handleCloseModal, setNotification } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  interface IDate {
    endDate: Date | null
    startDate: Date | null
  }

  interface ITimeFramesData extends IDate {
    notes: string
    userId: number | undefined
    userProjectId: number | undefined
  }

  const addTimeframes = async (timeFramesData: ITimeFramesData) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.PROJECTS_CREATETIMEFRAMES,
      timeFramesData
    )
    return data
  }

  const { mutate: addNewTimeframes } = useMutation(addTimeframes, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('timeframes')} ${t('add2')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.customer])
      queryClient.invalidateQueries([queryKeys.timeFrames])
    },
  })

  return {
    addNewTimeframes,
  }
}
