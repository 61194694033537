import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { NewWorkday } from 'pages/Calendar/context/types'
import { queryKeys } from 'react-query/constants'
import { useParams } from 'react-router-dom'

import { useGetWorkdays } from './useGetWorkdays'

export const useAddWorkday = () => {
  const {
    setNotification,
    handleCloseModal,
    currentCalendarDate,
    setSelectedUserWorkDays,
    handleCloseBar,
  } = useNotifications()
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { getWorkdaysForUser } = useGetWorkdays()

  const { t } = useTranslation('handleMessage')

  const createWorkDay = async (body: NewWorkday) => {
    const { data } = await apiRequest(APIRoutes.WORKDAYS_CREATE, body)
    return data
  }

  const { mutate: addNewWorkday } = useMutation(createWorkDay, {
    onSuccess: async () => {
      setNotification({
        message: `${t('workdays')} ${t('add2')}`,
        variant: NOTIFICATION.success,
      })
      handleCloseModal()
      handleCloseBar()
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.calendar)
      queryClient.invalidateQueries(queryKeys.user)
      queryClient.refetchQueries([queryKeys.workdaysToReview])
      if (id) {
        const fetchWorkDays2 = async () => {
          const newWorkdays = await getWorkdaysForUser(currentCalendarDate, id)
          setSelectedUserWorkDays(newWorkdays)
        }
        fetchWorkDays2()
      }
    },
  })

  return {
    addNewWorkday,
  }
}
