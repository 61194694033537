import { useState } from 'react'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetAllTags } from 'hooks/tags'
import { TableComponent } from 'components/common'

import { ITags } from '../types'

const UsersBySkills: React.FC = (): JSX.Element => {
  const { t } = useTranslation('systemTags')
  const [usersByTags, setUsersByTags] = useState<ITags[] | []>([])
  const tagsList = useGetAllTags()

  const columns = [
    `${t('tabsPanels.panel1.table.col1')}`,
    `${t('tabsPanels.panel1.table.col2')}`,
  ]

  const rows = usersByTags.map(({ users, name }) => {
    return {
      tagName: name,
      users: users.length ? (
        users.map((user, index) => (
          <Typography key={`${index}-user`}>
            {user.firstName} {user.lastName}
          </Typography>
        ))
      ) : (
        <Typography>{t('tabsPanels.panel1.table.rowNoUser')}</Typography>
      ),
    }
  })

  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        id="tags-standard"
        filterSelectedOptions
        options={tagsList}
        getOptionLabel={(option) => option.name}
        onChange={(e, value) => {
          setUsersByTags(value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            label={t('tabsPanels.panel1.input')}
          />
        )}
      />
      {!!usersByTags.length && (
        <Box maxWidth={500}>
          <TableComponent rows={rows} columns={columns} />
        </Box>
      )}
    </>
  )
}

export default UsersBySkills
