import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import {
  Button,
  FormControl,
  MenuItem,
  LinearProgress,
  Box,
  Typography,
  InputLabel,
} from '@mui/material'
import { CustomizedSelect } from 'components/common'
import { InventoryFields } from 'components/inventory'
import { useAddInventory, useInventoryValidationForm } from 'hooks/inventory'
import { IUser } from 'hooks/types'

interface IInventory {
  id: number
  name: string
}

const AddInventoryForm = ({ user }: { user: IUser | null }): JSX.Element => {
  const { t } = useTranslation(['profile', 'buttons', 'inventory'])
  const {
    inventoriesType,
    addNewInventory,
    initialValues,
    selectedInventory,
    setSelectedInventory,
  } = useAddInventory(user)
  const { validationSchemaAdd } = useInventoryValidationForm()

  const handleClick = (id: number) => {
    const chooseInventory = inventoriesType.find(
      (inventory) => inventory.id === id
    ) as IInventory
    setSelectedInventory(chooseInventory)
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        addNewInventory({ ...values, inventoryId: selectedInventory?.id })
        setSubmitting(false)
      }}
      validationSchema={validationSchemaAdd}
      enableReinitialize
    >
      {({ isSubmitting, errors }) => (
        <Form>
          <Box my={2}>
            <FormControl fullWidth error={errors.name ? true : false}>
              <InputLabel
                id={t('modal.addNewItems.label3', { ns: 'inventory' })}
              >
                {t('modal.addNewItems.label3', { ns: 'inventory' })}
              </InputLabel>
              <Field
                labelId={t('modal.addNewItems.label3', { ns: 'inventory' })}
                label={t('modal.addNewItems.label3', { ns: 'inventory' })}
                name="name"
                component={CustomizedSelect}
              >
                {inventoriesType?.map(({ id, name }) => (
                  <MenuItem
                    key={id}
                    value={name}
                    onClick={() => {
                      handleClick(id)
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            {errors.name && (
              <Typography variant="h6" color="error" mt={0.5}>
                {errors.name}
              </Typography>
            )}
          </Box>

          <InventoryFields />
          <Box>
            <Button
              type="submit"
              disabled={inventoriesType?.length ? false : true}
            >
              {t('save', { ns: 'buttons' })}
            </Button>
          </Box>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default AddInventoryForm
