import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Tooltip } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import SendIcon from '@mui/icons-material/Send'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useApplications } from 'pages/Pkup/hooks'
import { PkupContext } from 'pages/Pkup/context/PkupContext'
import { IButtonArr } from 'hooks/types'
import {
  EmptyArrayCard,
  TableComponent,
  useNotifications,
} from 'components/common'
import { SelectTime } from 'components/Pkup'
import { useStatus } from 'hooks/pkup'
import { IApplication } from 'hooks/pkup/types'

const Applications: React.FC = (): JSX.Element => {
  const { t } = useTranslation('pkup')
  const { date, setSelectedApplication } = useContext(PkupContext)
  const { handleOpenModal } = useNotifications()
  const { myApplications, handleDownloadZip, sendForApproval } =
    useApplications(date)
  const { statusBox } = useStatus()
  const handleOpen = async (modal: string, application: IApplication) => {
    modal === 'downloadZip'
      ? handleDownloadZip(application)
      : handleOpenModal(modal)
  }

  const buttonsArr = [
    {
      title: `${t('tabPanels.panel1.tooltip1')}`,
      icon: <VisibilityIcon />,
      modal: 'showMoreApplication',
    },
    {
      title: `${t('tabPanels.panel1.tooltip2')}`,
      icon: <EditIcon />,
      modal: 'revisions',
    },
    {
      title: `${t('tabPanels.panel1.tooltip3')}`,
      icon: <FileDownloadIcon />,
      modal: 'downloadZip',
    },
  ]
  const rows: GridRowsProp = myApplications.map((application) => {
    return {
      id: application.id,
      title: application.title,
      description: application.description,
      status: statusBox(application, 'pkup'),
      buttons: (
        <Box
          sx={{ display: 'flex', justifyContent: 'right' }}
          onClick={() => setSelectedApplication(application)}
        >
          {(application.state === 4 || application.state === 5) && (
            <Tooltip
              title={t('tabPanels.panel1.tooltip4') as string}
              key="send"
              onClick={() => sendForApproval(application.id)}
            >
              <IconButton color="success">
                <SendIcon />
              </IconButton>
            </Tooltip>
          )}
          {buttonsArr.map((button: IButtonArr) => (
            <Tooltip
              title={button.title as string}
              key={button.title}
              onClick={() => handleOpen(button.modal as string, application)}
            >
              <IconButton>{button.icon}</IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  })
  const columns = [
    '#',
    `${t('tabPanels.panel1.cell1')}`,
    `${t('tabPanels.panel1.cell2')}`,
    `${t('tabPanels.panel1.cell3')}`,
    '',
  ]

  return (
    <>
      <SelectTime />
      {myApplications.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard message={t('tabPanels.panel1.message')} />
      )}
    </>
  )
}

export default Applications
