import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { IPaginateReducer } from 'hooks/table'

import { TableRowBox, TableCellHeader } from './index.style'

interface ITableComponent {
  rows: GridRowsProp
  columns: (string | JSX.Element)[]
  buttons?: JSX.Element | null
  pagination?: boolean
  total?: number
  changePagination?: (pagination: IPaginateReducer) => void
  stickyHeader?: boolean
  handleChangePage?: any
  paginationUC?: IPaginateReducer
  handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const TableComponent: React.FC<ITableComponent> = ({
  rows,
  columns,
  buttons,
  pagination,
  total,
  stickyHeader,
  handleChangePage,
  paginationUC,
  handleChangeRowsPerPage,
}): JSX.Element => {
  return (
    <Box sx={{ marginTop: '1rem' }} overflow={stickyHeader ? 'hidden' : 'none'}>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: stickyHeader ? '80vh' : 'none' }}
      >
        <Table
          stickyHeader={stickyHeader}
          sx={{ minWidth: 450 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow
              sx={{
                height: '3rem',
              }}
            >
              {columns.map((column, index) => (
                <TableCellHeader key={index} align="left">
                  {column}
                </TableCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRowBox
                key={row.id ? row.id : index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {Object.values(row).map((el: any, index) => (
                  <TableCell key={index} align="left">
                    {el}
                  </TableCell>
                ))}
                {buttons}
              </TableRowBox>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 20, 50]}
          page={paginationUC?.currentPage || 0}
          count={total ? total : 1}
          rowsPerPage={paginationUC?.length || 10}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  )
}
export default TableComponent
