import { IUser } from 'hooks/types'
import { createContext } from 'react'

interface IUserContext {
  user: IUser | null
  setUser: (user: IUser | null) => void
}

const UserContext = createContext<IUserContext>({
  user: null,
  setUser: () => undefined,
})

export default UserContext

UserContext.displayName = 'UserContext'
