import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Container,
  Fade,
  IconButton,
  Link,
  Tooltip,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { HeaderTab } from 'views/LayoutUI/components'
import PersonIcon from '@mui/icons-material/Person'
import { EmptyArrayCard, TableComponent } from 'components/common'
import { useGetProjects } from 'hooks/projects'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { ChangeItemsInPage } from 'hooks/table/enum'
import { useTablePagedFunction } from 'hooks/table'
import { IButtons } from 'pages/Customers/types'
import { orderIcon, timeoutAnimation } from 'utils'
import { PageRoutes } from 'routes'

const Projects: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['projects', 'buttons'])
  const navigate = useNavigate()
  const { projectList, changePagination, paginationUc } = useGetProjects()
  const {
    dispatchUC,
    paginationUC,
    sortBy,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTablePagedFunction(changePagination)

  const columns = [
    <>
      <Button
        onClick={() => sortBy('id', 'ASC')}
        variant="text"
        sx={{ fontWeight: 550, minWidth: '100%', padding: 0 }}
      >
        #{orderIcon(paginationUc?.order, 'id')}
      </Button>
    </>,
    <>
      <Button
        onClick={() => sortBy('name', 'ASC')}
        variant="text"
        sx={{ fontWeight: 550 }}
      >
        {t('table.cell1')} {orderIcon(paginationUc?.order, 'name')}
      </Button>
    </>,
    <>
      <Button
        onClick={() => sortBy('customer', 'ASC')}
        variant="text"
        sx={{ fontWeight: 550 }}
      >
        {t('table.cell2')} {orderIcon(paginationUc?.order, 'customer')}
      </Button>
    </>,
    `${t('table.cell3')}`,
    `${t('table.cell4')}`,
    '',
  ]
  const buttonsArr: IButtons[] = [
    {
      title: t('buttons.button1'),
      path: PageRoutes.PROJECT_USERS,
      icon: <PersonIcon />,
    },
  ]
  const rows: GridRowsProp = projectList?.data?.map((project) => {
    return {
      id: project.id,
      name: (
        <Link
          sx={{ textDecoration: 'none', textAlign: 'left' }}
          variant="body2"
          component="button"
          onClick={() => navigate(`/projects/${project.id}`)}
        >
          {project.name}
        </Link>
      ),
      customer: (
        <Link
          sx={{ textDecoration: 'none', textAlign: 'left' }}
          variant="body2"
          component="button"
          onClick={() => navigate(`/customers/${project.customer.id}`)}
        >
          {project.customer.name}
        </Link>
      ),
      users: project?.users?.length,
      time:
        project?.startDate && project?.endDate
          ? `${project?.startDate?.slice(0, 10)} - ${project?.endDate?.slice(
              0,
              10
            )}`
          : null,
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map((button) => (
            <Tooltip title={button.title} key={button.title}>
              <IconButton
                onClick={() => {
                  button.path &&
                    navigate(button.path.replace(':id', `${project.id}`))
                }}
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  })

  const initialValues = {
    searchValue: '',
  }

  return (
    <Container>
      <HeaderTab translate="projects" />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatchUC({
            type: ChangeItemsInPage.MULTI_EDIT_STATE,
            payload: {
              length: paginationUC.length,
              currentPage: 0,
              search: values.searchValue,
            },
          })
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <Field
                fullWidth
                component={TextField}
                label={t('form.label')}
                name="searchValue"
                size="small"
              />
              <Button
                disabled={isSubmitting}
                onClick={submitForm}
                size="medium"
              >
                {t('search', { ns: 'buttons' })}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {projectList?.data?.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent
              columns={columns}
              rows={rows}
              changePagination={changePagination}
              total={projectList.total}
              pagination={true}
              handleChangePage={handleChangePage}
              paginationUC={paginationUC}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={`${t('typographies.typography1')}`} />
      )}
    </Container>
  )
}

export default Projects
