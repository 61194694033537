import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const useInventoryValidationForm = () => {
  const { t } = useTranslation('validation')

  const validation = {
    model: yup.string().required(t('model.required')),
    serialNumber: yup.string().required(t('serialNumber.required')),
    producer: yup.string().required(t('producer.required')),
    additionalInformation: yup.string(),
  }

  const validationSchemaEdit = yup.object({
    ...validation,
  })
  const validationSchemaAdd = yup.object({
    ...validation,
    name: yup.string().required(t('groupName.required')),
  })
  return { validationSchemaEdit, validationSchemaAdd }
}
