import { IUserProps } from 'components/users'

import { getProjectName } from '../../components/utils'
import { AllocationFormik } from '../../components/common'

import { useAddAllocation } from './utils'

const AddNewAllocation: React.FC<IUserProps> = ({ user }): JSX.Element => {
  const { addNewAllocation } = useAddAllocation()
  const projectName = getProjectName(user?.allocations?.[0], user)
  const initialValues = {
    selectedProject: projectName
      ? {
          name: projectName,
          id: user?.allocations?.[0]?.ProjectUser?.id,
        }
      : null,
    date: new Date(),
    hours: '',
    fte: '',
    isOverHours: false,
  }

  return (
    <>
      <AllocationFormik
        user={user}
        formikValues={initialValues}
        submitForm={addNewAllocation}
      />
    </>
  )
}
export default AddNewAllocation
