import { DatePicker } from '@mui/x-date-pickers'
import {
  Button,
  Box,
  Container,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { add, format, sub } from 'date-fns'
import { useGetAllAgreements } from 'hooks/reportAgreement/useGetUserAgreement'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderTab } from 'views/LayoutUI/components'
import {
  DetailsTooltip,
  EmptyArrayCard,
  TableComponent,
  TableTitle,
  useNotifications,
} from 'components/common'
import { DateType } from 'hooks/types'

const ReportAgreement = () => {
  const { t } = useTranslation(['reportAgreement', 'buttons'])
  const [value, setValue] = useState<DateType>(new Date())
  const { userDetailsId, setUserDetailsId } = useNotifications()

  const agreements = useGetAllAgreements(
    value ? format(value, 'yyyy') : format(new Date(), 'yyyy')
  )
  const columns = [
    `${t('table.cell1')}`,
    `${t('table.cell2')}`,
    `${t('table.cell3')}`,
    `${t('table.cell4')}`,
    `${t('table.cell5')}`,
  ]
  const rows: GridRowsProp = agreements?.map((agreement) => {
    return {
      user: (
        <DetailsTooltip id={userDetailsId}>
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <Typography
              variant="h4"
              onMouseOver={() => setUserDetailsId(agreement.userId)}
              sx={{ cursor: 'pointer' }}
            >
              {agreement.firstName} {agreement.lastName}
            </Typography>
          </Box>
        </DetailsTooltip>
      ),
      type: agreement.type,
      startDate: agreement.startDate.slice(0, 10),
      endDate: agreement.endDate.slice(0, 10),
      daysToEnd: (
        <Typography variant="h4">
          {t('typographies.in')} {agreement.daysToEnd} {t('typographies.days')}
        </Typography>
      ),
    }
  })
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <HeaderTab translate="reportAgreement" id={t('header.id')} />
      <Box
        sx={{
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          sx={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <TableTitle>{t('typographies.typography1')}</TableTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              padding: 1,
            }}
          >
            <Button
              onClick={() => {
                setValue((prev) =>
                  prev ? sub(prev, { years: 1 }) : new Date()
                )
              }}
            >
              {t('buttons.button1')}
            </Button>
            <DatePicker
              views={['year']}
              label={t('time.label')}
              value={value}
              onChange={(newValue) => {
                setValue(newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
            <Button
              onClick={() => {
                setValue((prev) =>
                  prev ? add(prev, { years: 1 }) : new Date()
                )
              }}
            >
              {t('buttons.button2')}
            </Button>
          </Box>
        </Paper>
        <Paper
          sx={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            width: '100%',
          }}
        >
          <Box>
            <TableTitle>
              {t('typographies.typography2')}{' '}
              {value ? format(value, 'yyyy') : format(new Date(), 'yyyy')}
            </TableTitle>
            {agreements.length ? (
              <TableComponent columns={columns} rows={rows} />
            ) : (
              <EmptyArrayCard message={t('typographies.message')} />
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}

export default ReportAgreement
