import { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Grid,
  Tab,
  Typography,
  Stack,
  Button,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { useTranslation } from 'react-i18next'
import { Skills } from 'pages/Profile/components'
import {
  IUserHolidays,
  IUserAgreement,
  ValueType,
  ISelectingModal,
} from 'hooks/types'
import { HeaderTab } from 'views/LayoutUI/components'
import { DefaultModal } from 'views/LayoutUI/modals'
import {
  AntSwitch,
  ModalComponent,
  ModalConfirm,
  useNotifications,
} from 'components/common'
import { useUpdateUser, useGetOneUser } from 'hooks/user'
import {
  IEditInventory,
  defaultValue,
  useDeleteInventory,
} from 'hooks/inventory'
import {
  EditInventoryForm,
  UserInventory,
  AddInventoryForm,
} from 'components/inventory'
import { getTokenOther } from 'user-storage'
import { permissionList, isUserPermitted } from 'services'
import { useAuth } from 'hooks/useAuth'
import { PageRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'

import { useUserAgreements } from './components/Details/components/Agreements/utils'
import { EditHoliday, AssignUserToGroup, EditUserAgreement } from './modals'
import {
  PersonalInformation,
  UpdatePassword,
  Details,
  Allocations,
} from './components'
import { useEditUsers } from './utils'
import { AllocationProvider } from './components/Allocations/context/AllocationContext'
import { useUserHolidays } from './components/Details/components/HolidaysDetails/utils'

const EditUser: React.FC = (): JSX.Element => {
  const [value, setValue] = useState<ValueType>('1')
  const [userHoliday, setUserHoliday] = useState<IUserHolidays | null>(null)
  const [userAgreement, setUserAgreement] = useState<IUserAgreement | null>(
    null
  )
  const { t } = useTranslation(['userEditPage', 'inventory'])
  const [inventory, setInventory] = useState<IEditInventory>(defaultValue)
  const handleChange = (_, newValue: string) => {
    setValue(newValue)
  }
  const { modalName, handleOpenModal } = useNotifications()
  const { deleteUserAgreement } = useUserAgreements()
  const oneUser = useGetOneUser()
  const updateUser = useUpdateUser()
  const { loginAsUser, canEditTags } = useEditUsers()
  const otherUserToken = getTokenOther()
  const { user } = useAuth()
  const isPermitted = isUserPermitted(user, [
    permissionList.INVENTORY_EDIT_OTHER_USER,
  ])
  const deleteInventory = useDeleteInventory()
  const navigate = useNavigate()

  const { deleteHolidayFromUser } = useUserHolidays()

  useEffect(() => {
    if (!modalName) {
      setUserHoliday(null)
      setUserAgreement(null)
    }
  }, [modalName])

  const deleteContent = () => {
    return (
      <Typography>
        {t('modal.deleteAgreement.subtitle')} {userAgreement?.Agreement?.type}
      </Typography>
    )
  }

  const deleteInventoryContent = () => {
    return (
      <Typography>
        {t('modal.deleteInventory.title1', { ns: 'inventory' })}{' '}
        {inventory.name} [ {inventory?.model} ]{' '}
        {t('modal.deleteInventory.title2', { ns: 'inventory' })}
      </Typography>
    )
  }

  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'editHoliday':
        return {
          component: <EditHoliday userHoliday={userHoliday} />,
          title: `${t('modal.editUser.title')}`,
        }
      case 'assignUserToGroup':
        return {
          component: <AssignUserToGroup />,
          title: `${t('modal.assignUser.title')}`,
        }
      case 'editUserAgreement':
        return {
          component: <EditUserAgreement userAgreement={userAgreement} />,
          title: `${t('modal.editUserAgreement.title')}: ${
            userAgreement?.Agreement?.type
          }`,
        }
      case 'deleteAgreement':
        return {
          component: (
            <ModalConfirm
              content={deleteContent()}
              deleteFunction={() => deleteUserAgreement(userAgreement?.id)}
            />
          ),
          title: `${t('modal.deleteAgreement.title')} ${oneUser.firstName} ${
            oneUser.lastName
          }`,
        }

      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteInventoryContent()}
              deleteFunction={() => deleteInventory(inventory?.id)}
            />
          ),
          title: `${t('modal.deleteAgreement.title')} ${oneUser.firstName} ${
            oneUser.lastName
          }`,
        }
      case 'editInventory':
        return {
          component: <EditInventoryForm inventory={inventory} />,
          title: t('modal.editInventory.modalTitle', { ns: 'inventory' }),
        }
      case 'addInventory':
        return {
          component: <AddInventoryForm user={oneUser} />,
          title: t('modal.addInventory.modalTitle', { ns: 'inventory' }),
        }
      case 'loginConfirm':
        return {
          component: (
            <ModalConfirm
              content={t('modal.loginConfirm.subtitle')}
              deleteFunction={() => {
                loginAsUser()
                navigate(PageRoutes.HOME)
              }}
            />
          ),
          title: t('modal.loginConfirm.title'),
        }
      case 'deleteHoliday':
        return {
          component: (
            <ModalConfirm
              content={t('modal.deleteHolidayConfirm.subtitle')}
              deleteFunction={() => deleteHolidayFromUser(userHoliday?.id || 0)}
            />
          ),
          title: t('modal.deleteHolidayConfirm.title'),
        }
      default:
        return { component: <DefaultModal />, title: 'Default modal' }
    }
  }
  return (
    <AllocationProvider>
      <Container>
        <HeaderTab
          translate="userEditPage"
          id={`Edit ${oneUser.email}`}
          headTitle={`${oneUser?.firstName} ${oneUser?.lastName}`}
          pageLink={PageRoutes.USERS}
        />
        <Box display="flex" justifyContent="space-between">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ marginRight: 3 }}
          >
            <AntSwitch
              onClick={() => updateUser({ isActive: !oneUser?.isActive })}
              checked={oneUser.isActive ? true : false}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>
              {oneUser?.isActive
                ? t('tabPanel.notActive')
                : t('tabPanel.active')}
            </Typography>
          </Stack>
          {!otherUserToken &&
            isUserPermitted(user, [permissionList.LOGIN_BY_OTHER]) &&
            user?.id !== oneUser.id && (
              <Button onClick={() => handleOpenModal('loginConfirm')}>
                <LockOpenIcon /> {t('loginBtn')} {oneUser?.firstName}{' '}
                {oneUser?.lastName}
              </Button>
            )}
        </Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('tabPanel.tab1')} value="1" />
              <Tab label={t('tabPanel.tab2')} value="2" />
              <Tab label={t('tabPanel.tab3')} value="3" />
              <Tab label={t('tabPanel.tab4')} value="4" />
              <Tab label={t('tabPanel.tab5')} value="5" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <PersonalInformation user={oneUser} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                md={5}
              >
                <Grid item xs={12}>
                  <UpdatePassword />
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Skills user={oneUser} permission={canEditTags} />
          </TabPanel>
          <TabPanel value="3">
            <UserInventory
              user={oneUser}
              setInventory={setInventory}
              permission={isPermitted}
            />
          </TabPanel>
          <TabPanel value="4">
            <Allocations user={oneUser} />
          </TabPanel>
          <TabPanel value="5">
            <Details
              user={oneUser}
              setUserHoliday={setUserHoliday}
              setUserAgreement={setUserAgreement}
            />
          </TabPanel>
        </TabContext>
        <ModalComponent
          component={selectingModal().component}
          title={selectingModal().title}
        />
      </Container>
    </AllocationProvider>
  )
}

export default EditUser
