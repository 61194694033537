import { useTranslation } from 'react-i18next'
import { Box, Stack } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useGetUserList } from 'hooks/customers'
import { useRevisions } from 'pages/Pkup/hooks/'
import { TableComponent } from 'components/common'
import { useStatus } from 'hooks/pkup'
import { IApplication, IPkupReports } from 'hooks/pkup/types'

interface RevisionsProps {
  selectedApplication: IApplication | IPkupReports | Record<string, never>
}

const Revisions: React.FC<RevisionsProps> = ({
  selectedApplication,
}): JSX.Element => {
  const { t } = useTranslation('pkup')
  const { usersList } = useGetUserList()
  const { revisionsList } = useRevisions(selectedApplication.id)
  const { getPkupStatus } = useStatus()

  const columns = [
    `${t('modal.revisions.column1')}`,
    `${t('modal.revisions.column2')}`,
    `${t('modal.revisions.column3')}`,
    `${t('modal.revisions.column4')}`,
  ]

  const getUserName = (userId: number) => {
    const user = usersList.find((user) => user.id === userId)
    return `${user?.firstName} ${user?.lastName}`
  }
  const rows: GridRowsProp = revisionsList.map((revision) => {
    const {
      title,
      description,
      period,
      state,
      supervisorId,
      creatorId,
      approverComment,
    } = JSON.parse(revision.document)

    return {
      id: revision.id,
      document: (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {title && (
            <Stack>
              {t('modal.revisions.label1')}: {title}
            </Stack>
          )}
          {description && (
            <Stack>
              {t('modal.revisions.label2')}: {description}
            </Stack>
          )}
          {period && (
            <Stack>
              {t('modal.revisions.label3')}: {period}
            </Stack>
          )}
          {state && (
            <Stack>
              {t('modal.revisions.label4')}: {getPkupStatus(state).title}
            </Stack>
          )}
          {supervisorId && (
            <Stack>
              {t('modal.revisions.label5')}: {getUserName(supervisorId)}
            </Stack>
          )}
          {creatorId && (
            <Stack>
              {t('modal.revisions.label6')}: {getUserName(creatorId)}
            </Stack>
          )}
          {approverComment && (
            <Stack>
              {t('modal.revisions.label7')}: {approverComment}
            </Stack>
          )}
        </Box>
      ),
      revision: revision.revision,
      time: revision.updatedAt,
    }
  })
  return (
    <Box sx={{ maxHeight: '80vh', padding: '1px 1rem', overflow: 'auto' }}>
      <TableComponent rows={rows} columns={columns} />
    </Box>
  )
}
export default Revisions
