import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const useTagsValidation = () => {
  const { t } = useTranslation(['validation'])

  const validationSchema = yup.object({
    tags: yup
      .array()
      .nullable(true)
      .min(1, t('skill.minLength', { ns: 'validation' }))
      .of(
        yup.object().shape({
          name: yup.string().required(t('skill.tag', { ns: 'validation' })),
        })
      )
      .required(t('skill.tag', { ns: 'validation' })),
  })

  return validationSchema
}
