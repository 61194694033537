import { useAllUsers } from 'hooks/useAllUsers'

export const useSupervisor = () => {
  const allUsers = useAllUsers()
  const getSupervisor = (supervisorId: number) => {
    const user = allUsers?.find((user) => user.id === supervisorId)
    return {
      name: user ? `${user?.firstName} ${user?.lastName}` : '',
      id: user?.id || 0,
    }
  }

  return {
    getSupervisor,
  }
}
