import { Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface AssignButtonProps {
  isAssigned: boolean
  setIsAssigned: React.Dispatch<React.SetStateAction<boolean>>
}

const AssignButton: React.FC<AssignButtonProps> = ({
  isAssigned,
  setIsAssigned,
}) => {
  const { t } = useTranslation('calendar')
  const handleAssignToProject = () => {
    setIsAssigned((prev) => !prev)
  }
  return (
    <Box>
      {!isAssigned ? (
        <Button
          sx={{ width: '100%', height: 38 }}
          onClick={handleAssignToProject}
        >
          {t('addWorkdaysModal.button1')}
        </Button>
      ) : (
        <Button
          sx={{ width: '100%', height: 38 }}
          onClick={handleAssignToProject}
        >
          {t('addWorkdaysModal.button2')}
        </Button>
      )}
    </Box>
  )
}

export default AssignButton
