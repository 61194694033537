import { useState } from 'react'
import { Button, Box, Typography, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { IUserProps } from 'components/users'
import { useNotifications } from 'components/common'

import { useDeleteUserFromGroup } from './utils'

const Groups: React.FC<IUserProps> = ({ user }): JSX.Element => {
  const { t } = useTranslation('userEditPage')
  const [togglePermission, setTogglePermission] = useState(false)
  const { handleOpenModal } = useNotifications()
  const number = 10
  const deleteUserFromGroup = useDeleteUserFromGroup()
  return (
    <>
      <Box>
        <Typography variant="h3">{t('groups.title1')}</Typography>
        {user?.groups?.map((group) => (
          <Box display="flex" key={group.id} alignItems="center">
            <Typography>{group.name}</Typography>
            <Tooltip title={t('groups.tooltip') as string}>
              <IconButton
                onClick={() => {
                  deleteUserFromGroup({ userId: user?.id, id: group.id })
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
        <Button onClick={() => handleOpenModal('assignUserToGroup')}>
          {t('groups.button1')}
        </Button>
      </Box>
      <Box mt={2}>
        <Typography variant="h3">{t('groups.title2')}</Typography>
        <Box>
          {togglePermission ? (
            <Box display="flex" flexWrap="wrap" mb={2}>
              {user?.permissions?.join(', ')}
            </Box>
          ) : (
            <Box display="flex" flexWrap="wrap" mb={2}>
              {user?.permissions.slice(0, number)?.join(', ')}
            </Box>
          )}
          <Button onClick={() => setTogglePermission(!togglePermission)}>
            {togglePermission ? t('groups.button2') : t('groups.button3')}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Groups
