import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useParams } from 'react-router-dom'

interface IHoliday {
  holidaysCategoryId: number
  days: string
  year: string
  id: number
}

const addHolidayToUser = async (holiday: IHoliday, id: number) => {
  const { data } = await apiRequest(
    APIRoutes.ADD_HOLIDAY_CATEGORY_TO_USER,
    {
      holidaysCategoryId: holiday.holidaysCategoryId,
      days: holiday.days,
      year: holiday.year,
    },
    { id }
  )
  return data
}
const deleteHolidayCategoryToUser = async (id: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.DELETE_HOLIDAY_CATEGORY_FROM_USER,
    {},
    { id }
  )
  return data
}

export const useUserHolidays = () => {
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { setNotification, handleCloseModal } = useNotifications()
  const { mutate: addUserHoliday } = useMutation(
    (holiday: IHoliday) => addHolidayToUser(holiday, Number(id)),
    {
      onSuccess: () => {
        setNotification({
          message: `${t('holiday')} ${t('add')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        void queryClient.invalidateQueries([queryKeys.user])
        void queryClient.invalidateQueries([queryKeys.userOne])
      },
    }
  )
  const { mutate: deleteHolidayFromUser } = useMutation(
    deleteHolidayCategoryToUser,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('holiday')} ${t('delete')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        void queryClient.invalidateQueries([queryKeys.user])
        void queryClient.invalidateQueries([queryKeys.userOne])
      },
    }
  )
  return { addUserHoliday, deleteHolidayFromUser }
}
