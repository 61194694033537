import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, LinearProgress } from '@mui/material'

import { IInitialValues, useNotes } from '../../useNotes'
import { NotesForm } from '../../components'
interface AddNoteProps {
  initialValues: IInitialValues
  validationSchema: object
  userId: number
}
const AddNote: React.FC<AddNoteProps> = ({
  initialValues,
  validationSchema,
  userId,
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const { addNote } = useNotes(userId)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        addNote(values)
        setSubmitting(false)
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <NotesForm />
          <Button type="submit">{t('save')}</Button>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default AddNote
