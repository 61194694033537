import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { NOTIFICATION, useNotifications } from 'components/common'
import { queryKeys } from 'react-query/constants'
import { useParams } from 'react-router-dom'

import { useGetWorkdays } from './useGetWorkdays'

async function copyWorkDays(body: { workdays: (string | number)[] }) {
  await apiRequest(APIRoutes.WORKDAYS_COPY, body)
}
export const useCopyWorkdays = () => {
  const {
    setNotification,
    handleCloseModal,
    currentCalendarDate,
    setSelectedUserWorkDays,
  } = useNotifications()
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { getWorkdaysForUser } = useGetWorkdays()

  const { mutate: copyWorkdays } = useMutation(copyWorkDays, {
    onSuccess: async () => {
      handleCloseModal()
      setNotification({
        message: `${t('workday')} ${t('copy')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.calendar)
      queryClient.invalidateQueries(queryKeys.user)
      if (id) {
        const fetchWorkDays2 = async () => {
          const newWorkdays = await getWorkdaysForUser(currentCalendarDate, id)
          setSelectedUserWorkDays(newWorkdays)
        }
        fetchWorkDays2()
      }
    },
  })

  return copyWorkdays
}
