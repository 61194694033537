import { Box, Button } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import {
  DetailsTooltip,
  EmptyArrayCard,
  TableComponent,
  useNotifications,
  TableTitle,
} from 'components/common'
import { useGetCustomerProject } from 'hooks/customers'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'

const TeamTable = () => {
  const { t } = useTranslation(['projectDetails', 'buttons'])
  const params = useParams()
  const customerProject = useGetCustomerProject(params.id)
  const { userDetailsId, setUserDetailsId } = useNotifications()
  const navigate = useNavigate()
  const columns = [
    `${t('table.cell1')}`,
    `${t('table.cell2')}`,
    `${t('table.cell3')}`,
    `${t('table.cell4')}`,
  ]
  const rows: GridRowsProp = customerProject?.users?.map((user) => {
    return {
      name: (
        <>
          <DetailsTooltip id={userDetailsId}>
            <Box
              onMouseOver={() => setUserDetailsId(user.id)}
              sx={{
                cursor: 'pointer',
                width: 'fit-content',
                fontWeight: user.ProjectUser.isPm ? 'bold' : 'normal',
              }}
            >
              {user.firstName} {user.lastName}
            </Box>
          </DetailsTooltip>
        </>
      ),

      email: user.ProjectUser.isPm ? <b>{user.email}</b> : user.email,
      isPm: user.ProjectUser.isPm ? (
        <Box ml={7}>
          <ThumbUpIcon color="success" />
        </Box>
      ) : (
        <Box ml={7}>
          <ThumbDownIcon color="error" />
        </Box>
      ),
      phone: user.companyPhoneNumber,
    }
  })
  return (
    <Box>
      <TableTitle>
        {t('typographies.typography2')}{' '}
        <Button onClick={() => navigate('users')}>
          {t('typographies.typography2button')}
        </Button>
      </TableTitle>
      {customerProject?.users?.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard message={`${t('typographies.typography1')}`} />
      )}
    </Box>
  )
}

export default TeamTable
