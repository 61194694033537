import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { Box, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  InfoSnackbar,
  LogoContainer,
  StyledContainer,
  Wrapper,
} from 'components/common'
import { PageRoutes } from 'routes'

import { useForgotPassword } from './utils'

const ForgotPassword: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['forgotPassword', 'buttons'])
  const { remindPassword, validationSchema, description } = useForgotPassword()

  const navigate = useNavigate()
  return (
    <StyledContainer>
      <LogoContainer />
      {description ? (
        <Wrapper>
          <Typography variant="h2" fontWeight={550}>
            {t('header.title2')}
          </Typography>
          <Typography variant="h3"> {description}</Typography>
          <Button
            fullWidth
            onClick={() => navigate(PageRoutes.LOGIN)}
            sx={{ marginTop: 1 }}
          >
            {t('form.button2')}
          </Button>
        </Wrapper>
      ) : (
        <Wrapper>
          <Typography variant="h2" fontWeight={550}>
            {t('header.title')}
          </Typography>
          <Box mt={3}>
            <Typography>{t('header.text')}</Typography>
          </Box>

          <Formik
            initialValues={{ email: '' }}
            onSubmit={(values) => {
              remindPassword(values)
            }}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box my={2}>
                  <Field
                    fullWidth
                    name="email"
                    component={TextField}
                    variant="standard"
                    label={t('form.label1')}
                  />
                </Box>
                <Box my={2}>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    fullWidth
                  >
                    {t('submit', { ns: 'buttons' })}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
          <Button
            variant="text"
            fullWidth
            onClick={() => navigate(PageRoutes.LOGIN)}
          >
            {t('form.button2')}
          </Button>
        </Wrapper>
      )}
      <InfoSnackbar />
    </StyledContainer>
  )
}
export default ForgotPassword
