import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Formik, Form, Field } from 'formik'
import {
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
} from '@mui/material'
import { CustomAutocompleteInput } from 'components/common'
import { useAllUsers } from 'hooks/useAllUsers'

import { ILocation } from '../../useLocationsList'
import { useLocations } from '../../useLocations'

interface AddUserToLocationProps {
  location: ILocation
}
interface IInitialValues {
  user: { id: number; name: string }
}
const AddUserToLocation: React.FC<AddUserToLocationProps> = ({
  location,
}): JSX.Element => {
  const { t } = useTranslation([
    'systemLocationsUsers',
    'validation',
    'buttons',
  ])
  const [checked, setChecked] = useState(false)
  const { addUser } = useLocations(location.id)
  const initialValues = {} as IInitialValues
  const validationSchema = yup.object({
    user: yup.object().required(t('selectUser.required', { ns: 'validation' })),
  })
  const allUsers = useAllUsers()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        values?.user?.id !== undefined &&
          addUser({ userId: values?.user.id, isDirector: checked })
        setSubmitting(false)
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Field
            component={CustomAutocompleteInput}
            optionsData={
              allUsers?.map((user) => {
                return {
                  name: `${user.firstName} ${user.lastName} <${user.email}>`,
                  id: user.id,
                }
              }) || []
            }
            name="user"
            label={t('dashboard.modal.addUser.label1')}
            disableClearable={true}
            error={errors.user}
          />
          <Field
            as={FormControlLabel}
            type="checkbox"
            name="checked"
            control={<Checkbox checked={checked} />}
            label={t('dashboard.modal.addUser.text1')}
            onChange={() => setChecked(!checked)}
          />

          <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default AddUserToLocation
