import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface IPermissionData {
  groupId: number | undefined
  permissionId: number
}
const addPermissionToGroup = async (permissionData: IPermissionData) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.GROUPS_ADDPERMISSION,
    { permissionId: permissionData.permissionId },
    { id: permissionData.groupId }
  )
  return data
}
const removePermissionFromGroup = async (id: number | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.GROUPS_DELETEPERMISSION,
    {},
    { id: id }
  )
  return data
}

export const usePermissionList = () => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotifications()
  const { t } = useTranslation('handleMessage')

  const { mutate: addPermission } = useMutation(addPermissionToGroup, {
    onSuccess: () => {
      setNotification({
        message: `${t('permission')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.permissions])
      void queryClient.invalidateQueries([queryKeys.groups])
    },
  })
  const { mutate: deletePermissionFromGroup } = useMutation(
    removePermissionFromGroup,
    {
      onSuccess: () => {
        setNotification({
          message: `${t('permission')} ${t('delete')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        void queryClient.invalidateQueries([queryKeys.permissions])
        void queryClient.invalidateQueries([queryKeys.groups])
      },
    }
  )

  return { addPermission, deletePermissionFromGroup }
}
