import { Card, styled } from '@mui/material'
import { theme } from 'theme'

export const StyledCard = styled(Card)({
  height: '100%',
  padding: '0.5rem 0.8rem',
  transition: 'all .25s ease',
  backgroundColor: '#ffffff7a',
  '&:hover': {
    boxShadow: `0px 2px 10px 1px ${theme.palette.primary.main}`,
  },
})
