import { Field, Form, useFormikContext } from 'formik'
import { Button, Grid, FormControlLabel, Checkbox } from '@mui/material'
import { TextField } from 'formik-mui'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { IUserProps } from 'components/users'
import {
  CustomizedDataPicker,
  CustomAutocompleteInput,
} from 'components/common'

import { useAllocationCheck } from '../../../modals/useAllocationCheck'

interface IFormikValues {
  selectedProject: { name: string; id: number }
  date: Date
  hours: number | null
  fte: number
  isOverHours: boolean
}

const AllocationForm: React.FC<IUserProps> = ({ user }) => {
  const { values, errors, setFieldValue } = useFormikContext<IFormikValues>()
  const { t } = useTranslation('userAllocations')
  const { fteCalculation, allWorkingHours } = useAllocationCheck()
  useEffect(() => {
    if (allWorkingHours) {
      setFieldValue('hours', allWorkingHours)
    }
  }, [allWorkingHours])
  const optionsArr = user?.projects?.map(({ name, ProjectUser }) => {
    return {
      name: name,
      id: ProjectUser?.id,
    }
  })
  return (
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Field
            component={CustomAutocompleteInput}
            optionsData={optionsArr || []}
            name="selectedProject"
            label={t('modals.addTimeFrame.label1')}
            disableClearable={true}
            error={errors?.selectedProject}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            label={t('modals.addTimeFrame.label2')}
            views={['year', 'month']}
            name="date"
            inputFormat="MM/yyyy"
            component={CustomizedDataPicker}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fullWidth
            name="hours"
            component={TextField}
            label={t('modals.editAllocation.label2')}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fullWidth
            name="fte"
            component={TextField}
            label={t('modals.editAllocation.label3')}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            type="checkbox"
            name="isOverHours"
            as={FormControlLabel}
            control={<Checkbox checked={values?.isOverHours} />}
            label={t('modals.editAllocation.label4')}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" sx={{ marginRight: 3 }}>
            {t('save', { ns: 'buttons' })}
          </Button>
          <Button
            onClick={() => {
              fteCalculation({
                userProjectId: values?.selectedProject?.id,
                date: {
                  fte: values?.fte,
                  dateS: format(new Date(values?.date), 'yyyy/MM'),
                },
              })
            }}
            disabled={!values.fte}
          >
            {t('modals.editAllocation.button')}{' '}
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}

export default AllocationForm
