import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useParams } from 'react-router-dom'

const assignUser = async (groupId: number | undefined, id: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.GROUPS_ADDUSER,
    { userId: id },
    { id: groupId }
  )
  return data
}

export const useAssignUserToGroup = () => {
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { id } = useParams()
  const { mutate: assignUserToGroup } = useMutation(
    (group: number | undefined) => assignUser(group, Number(id)),
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('user')} ${t('assign')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        void queryClient.invalidateQueries([queryKeys.user])
        void queryClient.invalidateQueries([queryKeys.userOne])
      },
    }
  )
  return assignUserToGroup
}
