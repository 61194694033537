import { AxiosResponse } from 'axios'
import { apiRequest, APIRoutes } from 'apiServices'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { NOTIFICATION, useNotifications } from 'components/common'

export const useReportForProject = () => {
  const { setNotification } = useNotifications()

  async function getDownloadProjectReport(values) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_DOWNLOADFORPROJECT,
      {
        month: values.month,
        onlyActive: values.onlyActive,
        agreements: values.agreements.map((agreement) => agreement.id),
        selectedMonthAgreements: values.selectedMonthAgreements,
      },
      { id: values.projects[0].id }
    )
    return data
  }

  async function getDownloadSelectedProjectsReport(values) {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.WORKDAYS_DOWNLOADFORPROJECTS,
      {
        ids: values.projects.map((project) => project.id),
        month: values.month,
        onlyActive: values.onlyActive,
        agreements: values.agreements.map((agreement) => agreement.id),
        selectedMonthAgreements: values.selectedMonthAgreements,
      }
    )
    return data
  }

  const handleDownloadProjectReport = async (values) => {
    const date = format(values.month, 'yyyy-MM')
    if (values.projects.length === 1) {
      const filename = values.projects[0].name + '-project-' + date
      await getDownloadProjectReport(values)
        .then((response) => {
          fileDownload(new Blob([response]), filename + '.xlsx')
        })
        .catch((error) =>
          setNotification({
            message: error.message,
            variant: NOTIFICATION.error,
          })
        )
    } else {
      const filename = 'selected_projects-' + date
      await getDownloadSelectedProjectsReport(values)
        .then((response) => {
          fileDownload(new Blob([response]), filename + '.xlsx')
        })
        .catch((error) =>
          setNotification({
            message: error.message,
            variant: NOTIFICATION.error,
          })
        )
    }
  }

  return {
    handleDownloadProjectReport,
  }
}
