import { AppBar, Toolbar, styled } from '@mui/material'

export const AppBarWrapper = styled(AppBar)({
  position: 'fixed',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  top: 'auto',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1300,
  padding: '10px',
  backgroundColor: 'white',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
})

export const ToolBarWrapper = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'center',
  gap: '5rem',
  alignItems: 'center',
  overflow: 'auto',
})
