import { SetStateAction, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useAuthorsWork } from 'pages/Pkup/hooks'
import { PkupContext } from 'pages/Pkup/context/PkupContext'
import {
  EmptyArrayCard,
  TableComponent,
  useNotifications,
} from 'components/common'
import { SelectTime } from 'components/Pkup'
import { IAuthorsWork } from 'hooks/pkup/types'

const AuthorsWork: React.FC = (): JSX.Element => {
  const { t } = useTranslation('pkup')
  const { date, setSelectedAuthorsWork } = useContext(PkupContext)
  const { myWorks } = useAuthorsWork(date)
  const { handleOpenModal } = useNotifications()

  const rows: GridRowsProp = myWorks.map((work) => {
    return {
      id: work.id,
      title: work.title,
      project: work.project.name,
      buttons: (
        <Tooltip title={t('tabPanels.panel2.tooltip1') as string}>
          <IconButton onClick={() => handleClick(work)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ),
    }
  })
  const columns = [
    '#',
    `${t('tabPanels.panel2.cell1')}`,
    `${t('tabPanels.panel2.cell2')}`,
    '',
  ]
  const handleClick = (
    work: SetStateAction<IAuthorsWork | Record<string, never>>
  ) => {
    setSelectedAuthorsWork(work)
    handleOpenModal('showMoreAuthorsWork')
  }

  return (
    <>
      <SelectTime />
      {myWorks.length ? (
        <TableComponent columns={columns} rows={rows} />
      ) : (
        <EmptyArrayCard message={t('tabPanels.panel2.message')} />
      )}
    </>
  )
}
export default AuthorsWork
