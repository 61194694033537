import { Formik, Form, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Box, Button } from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'
import { TextField } from 'formik-mui'
import { useUpdateCustomer, useGetCustomer } from 'hooks/customers'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

const CustomerForm: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['customerEdit', 'buttons', 'validation'])
  const { updateCustomer } = useUpdateCustomer()
  const params = useParams()
  const customer = useGetCustomer(params.id)

  const [initialValues, setInitialValues] = useState({
    customerName: '',
    customerCode: '',
  })

  useEffect(() => {
    setInitialValues({
      customerName: customer.name || '',
      customerCode: customer.code || '',
    })
  }, [customer.name, customer.code])

  const validationSchema = yup.object({
    customerName: yup
      .string()
      .required(`${t('customerName.required', { ns: 'validation' })}`)
      .min(3),
    customerCode: yup
      .string()
      .required(`${t('customerCode.required', { ns: 'validation' })}`)
      .min(3),
  })

  const customerFields: IFields[] = [
    {
      id: '1',
      label: `${t('form.label1')}`,
      name: 'customerCode',
      type: 'text',
    },
    {
      id: '2',
      label: `${t('form.label2')}`,
      name: 'customerName',
      type: 'text',
    },
  ]
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const { customerCode, customerName } = values
        const customerData = {
          id: params.id,
          code: customerCode,
          name: customerName,
        }
        updateCustomer(customerData)
        setSubmitting(false)
      }}
      enableReinitialize
    >
      {({ submitForm, isSubmitting }) => (
        <Form style={{ marginBottom: '20px' }}>
          <>
            <Box>
              {customerFields.map((field) => (
                <Field
                  fullWidth
                  key={field.id}
                  component={TextField}
                  name={field.name}
                  type={field.type}
                  label={field.label}
                  variant="outlined"
                  margin="normal"
                  size="small"
                />
              ))}
            </Box>
          </>
          <Button disabled={isSubmitting} onClick={submitForm}>
            {t('save', { ns: 'buttons' })}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default CustomerForm
