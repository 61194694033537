import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { Box, Button, LinearProgress } from '@mui/material'
import { InventoryFields } from 'components/inventory'
import {
  useInventoryValidationForm,
  useUpdateUserInventory,
  IEditInventory,
} from 'hooks/inventory'

const EditInventoryForm = ({
  inventory,
}: {
  inventory: IEditInventory | null
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const { updateUserInventory } = useUpdateUserInventory()
  const { validationSchemaEdit } = useInventoryValidationForm()
  const initialValues = {
    model: inventory?.model || '',
    serialNumber: inventory?.serialNumber || '',
    producer: inventory?.producer || '',
    additionalInformation: inventory?.additionalInformation || '',
  }
  return (
    <Box paddingY={3}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          updateUserInventory({ ...values, id: inventory?.id })
          setSubmitting(false)
        }}
        validationSchema={validationSchemaEdit}
      >
        {({ isSubmitting }) => (
          <Form>
            <InventoryFields />
            <Box>
              <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
            </Box>
            {isSubmitting && <LinearProgress />}
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default EditInventoryForm
