import { Box, Button, LinearProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { AutocompleteWithTags } from 'components/common'
import { useTagsValidation } from 'hooks/tags'
import { filterArrayByName } from 'utils'
import { ITags } from 'hooks/tags/useGetAllTags'

import { useAllTags } from './utils'

interface Props {
  tagsList: ITags[]
}

const AddTagForm: React.FC<Props> = ({ tagsList }): JSX.Element => {
  const { t } = useTranslation(['systemTags', 'buttons'])
  const { addNewTags } = useAllTags()
  const validationSchema = useTagsValidation()

  const initialValues = { tags: [] }
  return (
    <Box my={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={({ tags }, { setSubmitting }) => {
          addNewTags(tags)
          setSubmitting(false)
        }}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, values }) => {
          const theSameTags = filterArrayByName(values.tags, tagsList)
          if (theSameTags?.length) {
            errors.tags = `${t('tabsPanels.panel2.form.text1')} ${theSameTags
              .map(({ name }) => name)
              .join(', ')} ${t('tabsPanels.panel2.form.text2')}`
          }
          return (
            <Form>
              <Field
                component={AutocompleteWithTags}
                name="tags"
                error={errors?.tags}
                label={t('tabsPanels.panel2.form.label')}
                freeSolo={true}
              />

              <Button type="submit" sx={{ marginTop: 1 }}>
                {t('save', { ns: 'buttons' })}
              </Button>
              {isSubmitting && <LinearProgress />}
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default AddTagForm
