import { NOTIFICATION, useNotifications } from 'components/common'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { IEditInventory } from 'hooks/inventory'

export interface IUpdateInventory {
  model: string
  serialNumber: string | number
  producer: string
  additionalInformation: string
  id: number
  assignUser?: number | null
  name?: string
}
export const defaultValue = {
  additionalInformation: '',
  id: null,
  inventoryId: null,
  model: '',
  producer: '',
  serialNumber: '',
  userInventoryId: null,
  name: '',
}
const updateInventoryToUser = async (inventory: IEditInventory | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.INVENTORIES_UPDATE_TO_USER,
    inventory,
    {
      id: inventory?.id,
    }
  )
  return data
}

export const useUpdateUserInventory = () => {
  const queryClient = useQueryClient()
  const { setNotification, handleCloseModal } = useNotifications()
  const { t } = useTranslation('handleMessage')

  const { mutate: updateUserInventory } = useMutation(updateInventoryToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('inventory')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
      queryClient.invalidateQueries([queryKeys.inventories])
      queryClient.invalidateQueries([queryKeys.freeInventories])
      queryClient.invalidateQueries([queryKeys.userOne])
    },
  })

  return { updateUserInventory }
}
