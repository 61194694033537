import { NOTIFICATION, useNotifications } from 'components/common'
import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { useParams } from 'react-router-dom'

import { useGetWorkdays } from './useGetWorkdays'
import { useCopyWorkdays } from './useCopyWorkdays'

export interface IUpdateWorkdays {
  id: string | number
  workTypeId?: string
  comment?: string
  logDayTo?: string
  logDayFrom?: string
  progress?: string | null
}

const updateEvent = async (body: IUpdateWorkdays) => {
  const { comment, workTypeId, logDayTo, logDayFrom, id, progress } = body
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.WORKDAYS_UPDATE,
    {
      workTypeId,
      comment,
      logDayTo,
      logDayFrom,
      progress,
    },
    { id }
  )
  return { data, body }
}

export const useUpdateWorkdays = () => {
  const {
    setNotification,
    handleCloseModal,
    currentCalendarDate,
    setSelectedUserWorkDays,
  } = useNotifications()
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const copyWorkdays = useCopyWorkdays()
  const { id } = useParams()
  const { getWorkdaysForUser } = useGetWorkdays()

  const { mutate: updateWorkdays } = useMutation(updateEvent, {
    onSuccess: async () => {
      handleCloseModal()
      setNotification({
        message: `${t('workday')} ${t('update')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.calendar)
      queryClient.invalidateQueries(queryKeys.user)
      if (id) {
        const fetchWorkDays2 = async () => {
          const newWorkdays = await getWorkdaysForUser(currentCalendarDate, id)
          setSelectedUserWorkDays(newWorkdays)
        }
        fetchWorkDays2()
      }
    },
  })

  const { mutate: updateAndCopyWorkdays } = useMutation(updateEvent, {
    onSuccess: async (res) => {
      handleCloseModal()
      const temp = { workdays: [res.body.id] }
      copyWorkdays(temp)
      if (id) {
        const fetchWorkDays2 = async () => {
          const newWorkdays = await getWorkdaysForUser(currentCalendarDate, id)
          setSelectedUserWorkDays(newWorkdays)
        }
        fetchWorkDays2()
      }
    },
  })

  return { updateWorkdays, updateAndCopyWorkdays }
}
