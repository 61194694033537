import { useTranslation } from 'react-i18next'
import { IFields } from 'views/LayoutUI/components/types'
import * as yup from 'yup'

interface IInitialValues {
  firstName: string
  lastName: string
  email: string
  password: string
}

export const useUserData = () => {
  const { t } = useTranslation(['validation', 'handleMessage, users, buttons'])

  const initialValues: IInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  }

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(`${t('firstName.required', { ns: 'validation' })}`)
      .min(3),
    lastName: yup
      .string()
      .required(`${t('lastName.required', { ns: 'validation' })}`)
      .min(3),
    email: yup
      .string()
      .email(t('email.email', { ns: 'validation' }))
      .required(t('email.required', { ns: 'validation' })),
    password: yup.string().min(6).required(t('password.required')),
  })

  const fields: IFields[] = [
    {
      id: '1',
      label: `${t('modal.label1', { ns: 'users' })}`,
      name: 'firstName',
      type: 'text',
    },
    {
      id: '2',
      label: `${t('modal.label2', { ns: 'users' })}`,
      name: 'lastName',
      type: 'text',
    },
    {
      id: '3',
      label: `${t('modal.label3', { ns: 'users' })}`,
      name: 'email',
      type: 'email',
    },
    {
      id: '4',
      label: `${t('modal.label4', { ns: 'users' })}`,
      name: 'password',
      type: 'password',
    },
  ]
  return {
    initialValues,
    validationSchema,
    fields,
  }
}
