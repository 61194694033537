import { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { EmptyArrayCard } from 'components/common'

import { IProjects } from '../../types'

interface IUserRowsProps {
  row: JSX.Element
  projects: IProjects[]
  summary: JSX.Element[]
  dates: string[]
}

const UserRows: FC<IUserRowsProps> = ({ row, projects, summary, dates }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('reportAllocations')
  const mapDates = dates.map((date) => date)
  const projectColumns = [`${t('customerTable.project')}`, ...mapDates]

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{ fontWeight: '550', color: 'primary.main' }}
          align="center"
        >
          {row}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {projects?.length ? (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {projectColumns.map((column, index) => (
                        <TableCell key={index} align="left">
                          <Typography
                            variant="h4"
                            align="center"
                            color="primary"
                          >
                            {column}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects?.map(({ name, dates }, index) => (
                      <TableRow key={`${index}-rows`}>
                        <TableCell component="th" scope="row">
                          {name}
                        </TableCell>
                        {dates.map((date, i) => (
                          <TableCell key={i}>{date}</TableCell>
                        ))}
                      </TableRow>
                    ))}

                    <TableRow
                      sx={{
                        fontWeight: '550',
                        color: 'secondary.light',
                        backgroundColor: 'secondary.main',
                      }}
                    >
                      <TableCell
                        sx={{
                          fontWeight: '550',
                        }}
                      >
                        {t('usersTable.colSummary')}
                      </TableCell>
                      {summary?.map((value, index) => (
                        <TableCell key={`${index}-summary`}>{value}</TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <Box ml={'3.5rem'}>
                  <EmptyArrayCard message={t('usersTable.message')} />
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default UserRows
