import { Button, LinearProgress } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { IInitialValues } from '../..'
import { HolidaysCategoriesForm } from '../../components/'
import {
  IHolidaysCategory,
  useHolidaysCategories,
} from '../../useHolidaysCategories'

interface EditCategoryProps {
  initialValues: IInitialValues
  validationSchema: object
  category: IHolidaysCategory
}
const EditCategory: React.FC<EditCategoryProps> = ({
  category,
  initialValues,
  validationSchema,
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const { editCategory } = useHolidaysCategories(category?.id)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        editCategory(values)
        setSubmitting(false)
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <HolidaysCategoriesForm />
          <Button type="submit">{t('save')}</Button>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default EditCategory
