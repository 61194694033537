import { IAllocations, IUser } from 'hooks/types'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { AllocationBadge } from 'components/common'

interface IWorkDayInMonth {
  hours: number
  month: string
}

export interface IDisplayAllocations {
  currentAllocationsToShow: {
    month: string
    fte: number
    project: string
    planned: JSX.Element
  }[]
  summary: {
    summary: string
    fte: string
    projects: number
    freeTime: string
  }
}
export const selectedAllocation = (
  allocations: IAllocations[],
  workdaysInMonth: IWorkDayInMonth[],
  user: IUser | null
): IDisplayAllocations => {
  const { t } = useTranslation('userEditPage')
  const getProjectName = (allocation: IAllocations) => {
    const project = user?.projects?.find(
      (project) => project.id === allocation.ProjectUser.projectId
    )
    if (project) return project.name
    return ''
  }

  const workDayInMonth = workdaysInMonth?.find(
    (workday) => format(new Date(), 'yyyy-MM') === workday.month
  ) as IWorkDayInMonth
  const hours = workDayInMonth?.hours

  const currentAllocationsToShow = allocations?.map((allocation) => {
    const allocationsDetails = [
      {
        hours: allocation.allUsedWorkingHours || 0,
        tooltip: t('allocations.tooltips.plannedHours'),
        color: 'primary.dark',
      },
      {
        hours: allocation.allUsedWorkingHours || 0,
        tooltip: t('allocations.tooltips.workedHours'),
        color: 'primary.main',
      },
      {
        hours: allocation.notWorkingHours || 0,
        tooltip: t('allocations.tooltips.notWorkedHours'),
        color: 'primary.medium',
      },
      {
        hours: allocation.allWorkingHours || 0,
        tooltip: t('allocations.tooltips.allHours'),
        color: 'primary.light',
      },
    ]
    return {
      month: allocation.date,
      fte: allocation.FTE,
      project: getProjectName(allocation),

      planned: <AllocationBadge details={allocationsDetails} />,
    }
  })

  const freeTimeFte = (
    1 -
    currentAllocationsToShow
      .map((currentAllocation) => currentAllocation.fte)
      .reduce((a, b) => {
        return a + b
      }, 0)
  ).toFixed(2)

  const freeTimeHours = (
    hours -
    allocations
      .map((allocation) => allocation.allWorkingHours)
      .reduce((a, b) => {
        return a + b
      }, 0)
  ).toFixed(2)

  const sumFTE = currentAllocationsToShow
    .map((currentAllocation) => currentAllocation.fte)
    .reduce((a, b) => {
      return a + b
    }, 0)
    .toFixed(2)
  const summary = {
    summary: 'Summary',
    fte: sumFTE,
    projects: currentAllocationsToShow.length,
    freeTime:
      Number(freeTimeFte) > 0
        ? `Free time: ${freeTimeFte}FTE`
        : `Free time: ${freeTimeFte} FTE (${freeTimeHours}h)`,
  }

  return {
    currentAllocationsToShow,
    summary,
  }
}
