import { format, subHours } from 'date-fns/esm'
import { EventClickArg } from '@fullcalendar/react'
import { theme } from 'theme'

import { Workday } from './types'

interface IExtendedProps {
  isPlanned: boolean
  comment: string
  contract: string
  project: string | JSX.Element | null
  homeOffice?: boolean
  needApproval: boolean
  progress: string | null
}

export function filterClickedWorkdaysArray(
  arr: Workday[],
  obj: EventClickArg,
  key: string
) {
  const clickedWorkDay = {
    color: theme.palette.error.main,
    description: obj?.event?._def?.extendedProps.description,
    end: format(obj?.event?._instance?.range?.end as Date, 'yyyy-MM-dd HH:mm'),
    endTime: format(
      obj?.event?._instance?.range?.end as Date,
      'yyyy-MM-dd HH:mm'
    ),
    start: format(
      subHours(new Date(obj?.event?._instance?.range?.start as Date), 2),
      'yyyy-MM-dd HH:mm:ss'
    ),
    startTime: format(
      obj?.event?._instance?.range?.start as Date,
      'yyyy-MM-dd HH:mm'
    ),
    id: parseInt(obj?.event?._def?.publicId),
    title: obj?.event?._def?.extendedProps.title,
    extendedProps: obj?.event?._def?.extendedProps as IExtendedProps,
  }
  return arr.some(
    (item) => clickedWorkDay.id === item[key as keyof typeof item]
  )
    ? arr.filter((item) => clickedWorkDay.id !== item[key as keyof typeof item])
    : [...arr, clickedWorkDay]
}

export function removeDuplicates(arr: Workday[], key: (day: Workday) => void) {
  return arr.filter((day: Workday, id: number) => {
    return !arr.some(
      (day2: Workday, id2: number) => id !== id2 && key(day) === key(day2)
    )
  })
}

export function checkIfWorkdaysAreCopied(arr: Array<Workday>) {
  const worked = arr.filter((item) => {
    if (!item.extendedProps.isPlanned) return item
  })

  const planned = arr.filter((item) => {
    if (item.extendedProps.isPlanned) return item
  })

  const filtered: Workday[] = []
  planned.filter((p) => {
    const bool = worked.some(
      (w) =>
        w.start === p.start &&
        w.end === p.end &&
        p.extendedProps.comment === w.extendedProps.comment
    )
    if (bool) {
      return
    } else {
      filtered.push(p)
      return
    }
  })
  return filtered
}

export function checkTheSameDays(
  daysInCalendar: Workday[],
  selectedDays: string[]
) {
  const copy = daysInCalendar.map((object) => ({ ...object }))
  const filteredDays = copy.filter((day1) =>
    selectedDays.find((day2) => {
      return format(new Date(day1.start), 'yyyy-MM-dd') === day2
    })
  )
  return filteredDays
}
