import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import palette from 'theme/palette'
import { lightenDarkenColor } from 'utils/lightenDarkenColors'

import { useNotifications } from '../'

interface CloseButtonProps {
  handleOnClick?: () => void
}
const CloseButton: React.FC<CloseButtonProps> = ({
  handleOnClick,
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const { handleCloseBar } = useNotifications()
  const handleClick = handleOnClick || handleCloseBar

  return (
    <Button
      onClick={() => handleClick()}
      color="info"
      sx={{
        ':hover': {
          backgroundColor: lightenDarkenColor(palette.info.main, -10),
        },
      }}
    >
      {t('close')}
    </Button>
  )
}

export default CloseButton
