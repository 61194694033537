import { Container, Box, styled } from '@mui/material'

export const StyledContainer = styled(Container)({
  backgroundColor: 'rgba(2, 2, 27, 0.53)',
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: 5000,
  top: 0,
  right: 0,
})

export const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: 'transparent',
})
