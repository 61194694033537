import { Button, Grid, Box, styled } from '@mui/material'
import { lightenDarkenColor } from 'utils/lightenDarkenColors'
type props = {
  btncolor?: string
  btnwhite?: string
}

export const StyledGrid = styled(Grid)({
  marginBottom: 20,
  minWidth: 250,
})

export const StyledButton = styled(Button)<props>(
  ({ theme, btncolor, btnwhite }) => ({
    fontSize: '0.7rem',
    marginRight: 5,
    ...(btncolor && {
      backgroundColor: theme.palette.error.main,
    }),
    ...(btnwhite && {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
    }),
    ':hover': {
      ...(btncolor && {
        backgroundColor: lightenDarkenColor(theme.palette.error.main, -30),
      }),
      ...(btnwhite && {
        backgroundColor: theme.palette.primary.contrastText,
      }),
    },
  })
)

export const StyledBox = styled(Box)({
  height: '100%',
})
