import { IUser } from 'hooks/types'

import permissionList from './permissions'
interface IPermission {
  index: number
  name: string
}

export const isUserPermitted = (
  user: IUser | null,
  permissions: IPermission[] | undefined
) => {
  if (!user?.permissions) {
    return false
  }

  const findPermissionName = Object.keys(permissionList).filter(
    (key, index) => {
      return permissions?.some((object) => {
        return object.index === index
      })
    }
  )

  const hasUserPermission = user?.permissions?.filter((userPermission) => {
    return findPermissionName?.some((permission) => {
      return userPermission === permission
    })
  })

  return !!hasUserPermission.length
}
