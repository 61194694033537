import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import WindPowerIcon from '@mui/icons-material/WindPower'
import LocalFloristIcon from '@mui/icons-material/LocalFlorist'
import {
  format,
  eachDayOfInterval,
  eachWeekendOfInterval,
  addDays,
  addMilliseconds,
  addHours,
} from 'date-fns/esm'
import { DateSelectArg, EventDropArg, EventClickArg } from '@fullcalendar/react'
import { EventResizeDoneArg } from '@fullcalendar/interaction'
import { useAuth } from 'hooks/useAuth'
import { Holiday, IAllocations, IProjects, IUserHolidays } from 'hooks/types'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useGetWorkdays } from 'hooks/calendar/useGetWorkdays'
import { useUpdateWorkdays } from 'hooks/calendar/useUpdateWorkdays'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import palette from 'theme/palette'
import { setWorkStatus, getWorkStatus } from 'workday-storage'
import { queryKeys } from 'react-query/constants'
import { useQueryClient } from 'react-query'
import { useVacationRequests } from 'pages/VacationRequest/hooks'
import { StatusEnum } from 'pages/VacationRequest/hooks/useVacationRequests'

import { Workday, Worktype, Holidays } from './types'
import {
  filterClickedWorkdaysArray,
  removeDuplicates,
  checkTheSameDays,
  checkIfWorkdaysAreCopied,
} from './utils'

export type CalendarContent = {
  copyDates: boolean
  setCopyDates: Dispatch<SetStateAction<boolean>>
  selectedWorkDays: Workday[]
  setSelectedWorkDays: Dispatch<SetStateAction<Workday[]>>
  filteredWorkDays: Workday[]
  setFilteredWorkDays: Dispatch<SetStateAction<Workday[]>>
  holidays: Holidays[]
  theSameDays: Workday[]
  setTheSameDays: Dispatch<SetStateAction<Workday[]>>
  handleDateSelect: (selectInfo: DateSelectArg) => void
  daysRange: string[]
  setDaysRange: (arr: string[]) => void
  handleEventDrag: (selectInfo: EventDropArg) => void
  handleEventResize: (eventResizeInfo: EventResizeDoneArg) => void
  handleEventClick: (clickedInfo: EventClickArg) => void
  clickedInfo: EventClickArg
  deleteMode: boolean
  setDeleteMode: Dispatch<SetStateAction<boolean>>
  copyMode: boolean
  setCopyMode: Dispatch<SetStateAction<boolean>>
  isFilter: boolean
  setIsFilter: Dispatch<SetStateAction<boolean>>
  openSideBar: boolean
  setOpenSideBar: Dispatch<SetStateAction<boolean>>
  isOverhours: boolean
  setIsOverhours: Dispatch<SetStateAction<boolean>>
  types: Worktype[]
  projects: IProjects[]
  setProjects: Dispatch<SetStateAction<IProjects[]>>
  excludeWeekends: boolean
  setExcludeWeekends: Dispatch<SetStateAction<boolean>>
  excludeHolidays: boolean
  setExcludeHolidays: Dispatch<SetStateAction<boolean>>
  setWeekends: (body: Date[]) => void
  weekends: Date[]
  withoutWeekend: string[]
  setWithoutWeekends: (body: string[]) => void
  holidayForMonth: Holidays[]
  projectWithOverHours: IAllocations[]
  setProjectWithOverHours: Dispatch<SetStateAction<IAllocations[]>>
  alloc: IAllocations | undefined
  setAlloc: Dispatch<SetStateAction<IAllocations | undefined>>
  isPlanned: boolean
  setIsPlanned: Dispatch<SetStateAction<boolean>>
  isAssigned: boolean
  setIsAssigned: Dispatch<SetStateAction<boolean>>
  userHolidays: IUserHolidays[]
  setUserHolidays: Dispatch<SetStateAction<IUserHolidays[]>>
  selectedInfo: DateSelectArg
  setSelectedInfo: Dispatch<SetStateAction<DateSelectArg>>
  start: Date
  setStart: Dispatch<SetStateAction<Date>>
  end: Date
  setEnd: Dispatch<SetStateAction<Date>>
  setHolidayForMonth: Dispatch<SetStateAction<Holidays[]>>
}

export const CalendarContext = createContext<CalendarContent>(
  {} as CalendarContent
)

export const CalendarProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [copyDates, setCopyDates] = useState<boolean>(false)
  const [deleteMode, setDeleteMode] = useState(false)
  const [copyMode, setCopyMode] = useState(false)
  const [theSameDays, setTheSameDays] = useState<Workday[]>([])
  const [daysRange, setDaysRange] = useState<string[]>([])
  const [clickedInfo, setClickedInfo] = useState<EventClickArg>(
    {} as EventClickArg
  )
  const [selectedWorkDays, setSelectedWorkDays] = useState<Workday[]>([])
  const [filteredWorkDays, setFilteredWorkDays] = useState<Workday[]>([])
  const [types, setTypes] = useState<Worktype[]>([])
  const [userHolidays, setUserHolidays] = useState<IUserHolidays[]>([])
  const [projects, setProjects] = useState<IProjects[]>([])
  const [excludeWeekends, setExcludeWeekends] = useState(true)
  const [excludeHolidays, setExcludeHolidays] = useState(true)
  const [weekends, setWeekends] = useState<Date[]>([])
  const [withoutWeekend, setWithoutWeekends] = useState<string[]>([])
  const [openSideBar, setOpenSideBar] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [holidays, setHolidays] = useState<Holidays[]>([])
  const [holidayForMonth, setHolidayForMonth] = useState<Holidays[]>([])
  const [isOverhours, setIsOverhours] = useState(false)
  const [projectWithOverHours, setProjectWithOverHours] = useState<
    IAllocations[]
  >([])
  const [alloc, setAlloc] = useState<IAllocations | undefined>(undefined)
  const [isPlanned, setIsPlanned] = useState(getWorkStatus())
  const [isAssigned, setIsAssigned] = useState(false)
  const [selectedInfo, setSelectedInfo] = useState<DateSelectArg>(
    {} as DateSelectArg
  )
  const [start, setStart] = useState(new Date(0, 0, 0, 8, 0))
  const [end, setEnd] = useState(new Date(0, 0, 0, 16, 0))
  const { updateWorkdays } = useUpdateWorkdays()
  const { workdays, getWorkdays, getWorkdaysForUser } = useGetWorkdays()

  const {
    handleOpenModal,
    currentCalendarDate,
    setSelectedUserWorkDays,
    setNotification,
    selectedUserWorkDays,
    setIsOpenPopup,
    setOpenNotification,
  } = useNotifications()
  const { t } = useTranslation('calendar')
  const queryClient = useQueryClient()

  const { user } = useAuth()
  const { id } = useParams()
  const { vacationRequestsList } = useVacationRequests(user?.id)
  const pendingWorkdays = useMemo(() => {
    return vacationRequestsList?.data
      ?.filter((v) => v.status === StatusEnum.pending)
      .map((vR) => ({
        id: vR.id,
        title: vR.vacationType,
        start: vR.startDate,
        end: vR.endDate,
        startTime: vR.startDate,
        endTime: vR.endDate,
        color: palette.primary.oragne,
        extendedProps: {
          isPlanned: false,
          isRequestedVacation: true,
          comment: vR.comment,
          contract: '',
          project: null,
          homeOffice: true,
          isOverhours: '',
          needApproval: false,
          progress: '',
          isHoliday: false,
          vacationTypeId: vR.vacationTypeId,
        },
        description: vR.comment,
      }))
  }, [vacationRequestsList])

  useEffect(() => {
    setWorkStatus(isPlanned)
  }, [isPlanned])

  useEffect(() => {
    if (workdays) {
      setSelectedWorkDays([...workdays, ...(pendingWorkdays ?? [])])
      setFilteredWorkDays([...workdays, ...(pendingWorkdays ?? [])])
    } else {
      setSelectedWorkDays([])
      setFilteredWorkDays([])
    }
  }, [workdays, pendingWorkdays])

  useEffect(() => {
    if (id) {
      const fetchWorkDays2 = async () => {
        const newWorkdays = await getWorkdaysForUser(currentCalendarDate, id)
        setSelectedUserWorkDays(newWorkdays)
      }
      fetchWorkDays2()
    } else {
      const fetchWorkDays = async () => {
        const newWorkdays = await getWorkdays(currentCalendarDate)
        // Dodaj domyślną wartość pustej tablicy do pendingWorkdays
        setSelectedWorkDays([...newWorkdays, ...(pendingWorkdays ?? [])])
      }
      fetchWorkDays()
      fetchProjectsForMonth()
    }
  }, [currentCalendarDate, id, pendingWorkdays])

  useEffect(() => {
    if (user) {
      let tempHolidays: Holidays[] = []

      user?.system?.holidays?.forEach((holiday: Holiday) => {
        let icon
        let bgc
        let colorIcon
        let color
        const month = format(new Date(holiday.date), 'MM')

        let season = ''
        switch (month) {
          case '12':
          case '01':
          case '02':
            season = 'Winter'
            bgc = palette.primary.dark
            color = palette.primary.white
            colorIcon = palette.primary.white
            break
          case '03':
          case '04':
          case '05':
            season = 'Spring'
            bgc = palette.success.main
            color = palette.primary.white
            colorIcon = palette.primary.white
            break
          case '06':
          case '07':
          case '08':
            season = 'Summer'
            bgc = palette.error.dark
            color = palette.primary.white
            colorIcon = palette.primary.white
            break
          case '09':
          case '10':
          case '11':
            season = 'Autumn'
            bgc = palette.warning.dark
            color = palette.primary.white
            colorIcon = palette.primary.white
            break
        }

        switch (season) {
          case 'Summer':
            icon = <WbSunnyIcon sx={{ color: colorIcon }} />
            break
          case 'Autumn':
            icon = <WindPowerIcon sx={{ color: colorIcon }} />
            break
          case 'Winter':
            icon = <AcUnitIcon sx={{ color: colorIcon }} />

            break
          case 'Spring':
            icon = <LocalFloristIcon sx={{ color: colorIcon }} />
            break
          default:
            return
        }
        tempHolidays = [
          ...tempHolidays,
          {
            id: holiday.id,
            name: holiday.name,
            start: format(new Date(holiday.date), 'yyyy-MM-dd'),
            end: format(addDays(new Date(holiday.date), 1), 'yyyy-MM-dd'),
            color: bgc,
            extendedProps: {
              contract: '',
              project: (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {icon}
                  <Typography
                    sx={{
                      color: color,
                    }}
                  >
                    {holiday.name}
                  </Typography>
                </Box>
              ),
            },
            display: 'background',
          },
        ]
      })
      tempHolidays.length && setHolidays(tempHolidays)
    }
    fetchProjectsForMonth()
  }, [user])

  useEffect(() => {
    let tempSelectedWorkdays = [...selectedWorkDays]
    if (id) {
      tempSelectedWorkdays = [...selectedUserWorkDays]
    }
    if (deleteMode) {
      const workdaysWithNoNeedApproval = [...selectedWorkDays].filter(
        (w) => !w.extendedProps.needApproval
      )

      workdaysWithNoNeedApproval.forEach((workday) => {
        theSameDays.some((day) => day.id === workday.id)
          ? (workday.color = palette.error.main)
          : workday.extendedProps.isPlanned
          ? (workday.color = palette.primary.light)
          : (workday.color = palette.primary.main)
      })

      if (id) {
        setSelectedUserWorkDays(workdaysWithNoNeedApproval)
      } else setFilteredWorkDays(workdaysWithNoNeedApproval)
    } else if (copyMode) {
      const filtered = checkIfWorkdaysAreCopied(theSameDays)

      tempSelectedWorkdays.forEach((workday) => {
        filtered.some((day) => day.id === workday.id)
          ? (workday.color = palette.success.dark)
          : workday.extendedProps.isPlanned
          ? (workday.color = palette.primary.light)
          : (workday.color = palette.primary.main)
      })

      const daysThatAreAlreadyCopied = [...tempSelectedWorkdays].filter(
        (day1) =>
          !tempSelectedWorkdays.some(
            (day2) =>
              day1.start === day2.start &&
              day1.end === day2.end &&
              day1.extendedProps.comment === day2.extendedProps.comment &&
              day1.extendedProps.isPlanned !== day2.extendedProps.isPlanned
          )
      )
      const filteredDaysWithoutWorked = daysThatAreAlreadyCopied.filter(
        (day) => day.extendedProps.isPlanned
      )

      if (id) {
        setSelectedUserWorkDays(filteredDaysWithoutWorked)
      } else setFilteredWorkDays(filteredDaysWithoutWorked)
    } else {
      tempSelectedWorkdays.forEach((workday) => {
        workday.extendedProps.isPlanned
          ? (workday.color = palette.primary.light)
          : (workday.color = palette.primary.main)
      })
      if (id) {
        setSelectedUserWorkDays(tempSelectedWorkdays)
      } else setFilteredWorkDays(selectedWorkDays)
    }
  }, [deleteMode, copyMode, theSameDays])

  const fetchProjectsForMonth = () => {
    if (user) {
      const userHolidays =
        user?.UserHolidays?.filter(
          (day) => day.year === new Date().getFullYear()
        ) || []

      setTypes(user?.system?.workTypes || [])
      setUserHolidays(userHolidays)
      const projects =
        user?.projects?.filter((project: IProjects) => {
          const activeAlloc = user?.allocations?.find(
            (alloc: IAllocations) =>
              alloc.ProjectUser.projectId === project.id &&
              format(new Date(currentCalendarDate), 'yyyy/MM') === alloc.date
          )

          return activeAlloc !== undefined
        }) || []
      setProjects(projects)
    }
  }

  const filterHolidaysForMonth = (selectedDays: string[]) => {
    const tempHolidays = holidays.filter((day) => {
      if (selectedDays.includes(format(new Date(day.start), 'yyyy-MM-dd')))
        return day
    })
    setHolidayForMonth(tempHolidays)
  }

  const handleEventDrag = (info: EventDropArg) => {
    if (info.event._def.extendedProps.needApproval) {
      return
    }

    setOpenNotification(false)
    const tempArr = [...selectedWorkDays]
    const filtered = tempArr.filter(
      (workday) => workday.id.toString() === info.event._def.publicId
    )
    filtered.forEach((workday) => {
      workday.start = format(
        addMilliseconds(
          addDays(new Date(workday.start), info.delta.days),
          info.delta.milliseconds
        ),
        'yyyy-MM-dd HH:mm'
      )
      workday.end = format(
        addMilliseconds(
          addDays(new Date(workday.end), info.delta.days),
          info.delta.milliseconds
        ),
        'yyyy-MM-dd HH:mm'
      )
    })
    const updateFields = {
      id: info.event._def.publicId,
      logDayFrom: format(new Date(filtered[0].start), 'yyyy-MM-dd HH:mm'),
      logDayTo: format(new Date(filtered[0].end), 'yyyy-MM-dd HH:mm'),
    }

    updateWorkdays(updateFields)
  }

  const handleEventResize = (eventResizeInfo: EventResizeDoneArg) => {
    if (eventResizeInfo.event._def.extendedProps.needApproval) {
      return
    }

    const tempArr = [...selectedWorkDays]
    const filtered = tempArr.filter(
      (workday) => workday.id.toString() === eventResizeInfo.event._def.publicId
    )
    filtered.forEach((workday) => {
      workday.end = format(
        addMilliseconds(
          new Date(workday.end),
          eventResizeInfo.endDelta.milliseconds
        ),
        'yyyy-MM-dd HH:mm'
      )
    })
    const updatedInfo = {
      id: eventResizeInfo.event._def.publicId,
      logDayTo: format(new Date(filtered[0].end), 'yyyy-MM-dd HH:mm'),
    }
    updateWorkdays(updatedInfo)
  }

  const handleDateSelect = async (selectInfo: DateSelectArg) => {
    await queryClient.refetchQueries([queryKeys.user])
    setSelectedInfo(selectInfo)
    const calendarApi = selectInfo.view.calendar
    calendarApi.unselect()
    setExcludeWeekends(true)

    let start = format(selectInfo.start, 'yyyy, MM, dd')
    let end
    if (
      selectInfo.view.type === 'timeGridWeek' ||
      selectInfo.view.type === 'timeGridDay'
    ) {
      start = format(selectInfo.start, 'yyyy, MM, dd, HH:mm')
      end = format(selectInfo.end, 'yyyy, MM, dd, HH:mm')
      setStart(new Date(start))
      setEnd(new Date(end))
    } else {
      end = new Date(selectInfo.end.setDate(selectInfo.end.getDate() - 1))
    }

    const eachDay: Date[] = eachDayOfInterval({
      start: new Date(start),
      end: new Date(end),
    })

    const weekends = eachWeekendOfInterval({
      start: new Date(start),
      end: new Date(end),
    })

    const tempDaysArr = eachDay.map((day) => format(day, 'yyyy-MM-dd'))
    filterHolidaysForMonth(tempDaysArr)
    const tempWeekendsArr = weekends.map((day) => format(day, 'yyyy-MM-dd'))

    const withoutWeekends = tempDaysArr.filter(
      (day) => !tempWeekendsArr.includes(day)
    )

    setWithoutWeekends(withoutWeekends)
    setWeekends(weekends)

    const days = () => {
      const arr: string[] = []

      for (let i = 0; i < eachDay.length; i++) {
        arr.push(`${format(eachDay[i], 'yyyy-MM-dd')}`)
      }

      return arr
    }

    const arrDays = days()
    setDaysRange(arrDays)
    const theSameDates = checkTheSameDays(selectedWorkDays, arrDays)
    if (theSameDates.length && copyMode) {
      const filtered = checkIfWorkdaysAreCopied(theSameDates)
      const tempSelectedWorkdays = [...selectedWorkDays]

      const temp = [...theSameDays, ...filtered]

      const withoutDupplicates = removeDuplicates(
        temp,
        (day: Workday) => day.id
      )

      tempSelectedWorkdays.forEach((workday) => {
        withoutDupplicates.some((day) => day.id === workday.id)
          ? (workday.color = palette.success.dark)
          : workday.extendedProps.isPlanned
          ? (workday.color = palette.primary.light)
          : (workday.color = palette.primary.main)
      })

      setTheSameDays(withoutDupplicates)
      setSelectedWorkDays(tempSelectedWorkdays)
    } else if (deleteMode && theSameDates.length) {
      const tempSelectedWorkdays = [...selectedWorkDays]
      const filtered = theSameDates.filter(
        (workday) => !workday.extendedProps.needApproval
      )
      const temp = [...theSameDays, ...filtered]
      const withoutDupplicates = removeDuplicates(
        temp,
        (day: Workday) => day.id
      )

      tempSelectedWorkdays.forEach((workday) => {
        withoutDupplicates.some((day) => day.id === workday.id)
          ? (workday.color = palette.error.main)
          : workday.extendedProps.isPlanned
          ? (workday.color = palette.primary.light)
          : (workday.color = palette.primary.main)
      })

      setSelectedWorkDays(tempSelectedWorkdays)
      setTheSameDays(withoutDupplicates)
    } else if ((deleteMode || copyMode) && !theSameDates.length) {
      setNotification({
        message: t('context.alert'),
        variant: NOTIFICATION.error,
      })
    } else {
      handleOpenModal('addWorkdaysModal')
      setTheSameDays(theSameDates)
    }
  }

  const handleEventClick = async (clickInfo: EventClickArg) => {
    if (!clickInfo.event._def.extendedProps.isRequestedVacation) {
      await queryClient.refetchQueries([queryKeys.user])
      if (clickInfo?.event._def.ui.display === 'background') {
        return
      }
      setIsOpenPopup(false)
      setOpenNotification(false)
      if (deleteMode) {
        if (clickInfo.event._def.extendedProps.needApproval) {
          return
        }
        const temp: Workday[] = filterClickedWorkdaysArray(
          theSameDays,
          clickInfo,
          'id'
        )

        const tempSelectedWorkdays = [...selectedWorkDays]

        tempSelectedWorkdays.forEach((workday) => {
          temp.some((day) => day.id === workday.id)
            ? (workday.color = palette.error.main)
            : workday.extendedProps.isPlanned
            ? (workday.color = palette.primary.light)
            : (workday.color = palette.primary.main)
        })

        setTheSameDays(temp)
        setSelectedWorkDays(tempSelectedWorkdays)
      } else if (copyMode) {
        const filteredDays = selectedWorkDays.filter(
          (d) =>
            (d.extendedProps.comment ===
              clickInfo.event._def.extendedProps.comment &&
              d.extendedProps.isPlanned ===
                clickInfo.event._def.extendedProps.isPlanned &&
              format(
                addHours(clickInfo?.event?._instance?.range?.start as Date, -2),
                'yyyy-MM-dd'
              ) === d.start.toString().slice(0, 10) &&
              format(
                addHours(clickInfo?.event?._instance?.range?.end as Date, -2),
                'yyyy-MM-dd'
              ) === d.end.toString().slice(0, 10)) ||
            (d.extendedProps.comment ===
              clickInfo.event._def.extendedProps.comment &&
              d.extendedProps.isPlanned !==
                clickInfo.event._def.extendedProps.isPlanned &&
              format(
                addHours(clickInfo?.event?._instance?.range?.start as Date, -2),
                'yyyy-MM-dd'
              ) === d.start.toString().slice(0, 10) &&
              format(
                addHours(clickInfo?.event?._instance?.range?.end as Date, -2),
                'yyyy-MM-dd'
              ) === d.end.toString().slice(0, 10))
        )
        if (
          !clickInfo.event._def.extendedProps.isPlanned ||
          filteredDays.length === 2
        ) {
          return
        }
        const temp: Workday[] = filterClickedWorkdaysArray(
          theSameDays,
          clickInfo,
          'id'
        )
        const tempSelectedWorkdays = [...filteredWorkDays]
        tempSelectedWorkdays.forEach((workday) => {
          temp.some((filteredWorkday) => filteredWorkday.id === workday.id)
            ? (workday.color = palette.success.dark)
            : workday.extendedProps.isPlanned
            ? (workday.color = palette.primary.light)
            : (workday.color = palette.primary.main)
        })

        setFilteredWorkDays(tempSelectedWorkdays)
        setTheSameDays(temp)
      } else {
        setClickedInfo(clickInfo)
        handleOpenModal('eventClickModal')
      }
    }
  }

  const value = {
    copyDates,
    setCopyDates,
    selectedWorkDays,
    setSelectedWorkDays,
    filteredWorkDays,
    setFilteredWorkDays,
    theSameDays,
    setTheSameDays,
    handleDateSelect,
    daysRange,
    handleEventDrag,
    handleEventResize,
    handleEventClick,
    clickedInfo,
    deleteMode,
    setDeleteMode,
    setDaysRange,
    types,
    projects,
    excludeWeekends,
    setExcludeWeekends,
    excludeHolidays,
    setExcludeHolidays,
    weekends,
    setWeekends,
    withoutWeekend,
    setWithoutWeekends,
    openSideBar,
    setOpenSideBar,
    copyMode,
    setCopyMode,
    isFilter,
    setIsFilter,
    holidays,
    holidayForMonth,
    isOverhours,
    setIsOverhours,
    projectWithOverHours,
    setProjectWithOverHours,
    alloc,
    setAlloc,
    isPlanned,
    setIsPlanned,
    isAssigned,
    setIsAssigned,
    userHolidays,
    setUserHolidays,
    selectedInfo,
    setSelectedInfo,
    setProjects,
    setHolidayForMonth,
    start,
    setStart,
    end,
    setEnd,
  }
  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  )
}
export const useCalendarContext = () =>
  useContext(CalendarContext) as CalendarContent
