import { Box, IconButton, Tooltip, Typography } from '@mui/material'

interface IOptionBox {
  title: string
  name: string | undefined
  icon: JSX.Element
}
const OptionBox: React.FC<IOptionBox> = ({
  title,
  icon,
  name,
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '2rem 1fr',
        alignItems: 'center',
      }}
    >
      <Tooltip title={title}>
        <IconButton>{icon}</IconButton>
      </Tooltip>
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordWrap: 'break-word',
        }}
      >
        <Typography variant="subtitle1">{name}</Typography>
      </Box>
    </Box>
  )
}

export default OptionBox
