import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { AllocationBadge, useNotifications } from 'components/common'
import { IAllocations, IUser } from 'hooks/types'
import { useAuth } from 'hooks/useAuth'
import { permissionList, isUserPermitted } from 'services'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'

import { getProjectName } from '../../utils'

export const SelectAllocations = (
  allocation: IAllocations[] | undefined,
  setEditAllocation: Dispatch<SetStateAction<IAllocations | null>>,
  otherUser?: IUser | null
) => {
  const { user } = useAuth()
  const { handleOpenModal } = useNotifications()
  const { t } = useTranslation('userEditPage')

  const allocationsFromTab = allocation?.map((allocation) => {
    const allocationsDetails = [
      {
        hours: allocation.allUsedPlannedHours || 0,
        tooltip: t('allocations.tooltips.plannedHours'),
        color: 'primary.dark',
      },
      {
        hours: allocation.allUsedWorkingHours || 0,
        tooltip: t('allocations.tooltips.workedHours'),
        color: 'primary.main',
      },
      {
        hours: allocation.notWorkingHours || 0,
        tooltip: t('allocations.tooltips.notWorkedHours'),
        color: 'primary.medium',
      },
      {
        hours: allocation.allWorkingHours || 0,
        tooltip: t('allocations.tooltips.allHours'),
        color: 'primary.light',
      },
    ]
    const projectName = getProjectName(allocation, user)
    return {
      month: allocation.date,
      project: projectName
        ? projectName
        : getProjectName(allocation, otherUser as IUser),
      fte: allocation.FTE,
      hours: <AllocationBadge details={allocationsDetails} />,
      isOverHours: allocation.isOverhours ? (
        <Box ml={3.5}>
          <ThumbUpIcon color="success" />
        </Box>
      ) : (
        <Box ml={3.5}>
          <ThumbDownIcon color="error" />
        </Box>
      ),
      buttons: (
        <Box display="flex">
          {isUserPermitted(user, [permissionList.ALLOCATION_EDIT]) && (
            <IconButton
              onClick={() => {
                setEditAllocation(allocation)
                handleOpenModal('editAllocation')
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          {isUserPermitted(user, [permissionList.ALLOCATION_DELETE]) && (
            <IconButton
              onClick={() => {
                setEditAllocation(allocation)
                handleOpenModal('deleteAllocation')
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
        </Box>
      ),
    }
  })
  return allocationsFromTab
}
