const PROJECT_ID = 'projectId'
const WORK_TYPE_ID = 'workTypeId'
const WORK_STATUS = 'workStatus'

export function getStoredProjectId(): string {
  const projectId = localStorage.getItem(PROJECT_ID)
  return projectId ? projectId : '0'
}

export function setStoredProjectId(id: string | number): void {
  window.localStorage.setItem(PROJECT_ID, JSON.stringify(id))
}

export function getStoredWorkTypeId(): string {
  const workTypeId = localStorage.getItem(WORK_TYPE_ID)
  return workTypeId ? workTypeId : '0'
}

export function setStoredWorkTypeId(id: string | number): void {
  window.localStorage.setItem(WORK_TYPE_ID, JSON.stringify(id))
}
export function setWorkStatus(status: boolean): void {
  window.localStorage.setItem(WORK_STATUS, JSON.stringify(status))
}
export function getWorkStatus(): boolean {
  const workStatus = localStorage.getItem(WORK_STATUS)
  return workStatus === 'true' ? true : false
}
