import { TableRow, styled, TableCell } from '@mui/material'

export const TableRowBox = styled(TableRow)`
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`
export const TableCellHeader = styled(TableCell)`
  font-weight: 550;
  color: ${(props) => props.theme.palette.primary.main};
`
