import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { IInventories } from 'hooks/types'

const getFreeInventory = async (): Promise<IInventories[]> => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.INVENTORIES_GET_FREE
  )
  return data
}

export const useInventoryFreeItems = () => {
  const fallback = <IInventories[]>[]

  const { data: freeInventories = fallback } = useQuery(
    queryKeys.freeInventories,
    getFreeInventory
  )

  return freeInventories
}
