import { useQuery, useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

import { IPermissionsList } from '../../types'

interface IPermissionData {
  code: string
  name: string
}

const getPermissions = async (): Promise<IPermissionsList[]> => {
  const { data } = await apiRequest(APIRoutes.PERMISSIONS_GETALL)
  return data
}
const createPermission = async (permissionData: IPermissionData) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PERMISSION_CREATE,
    permissionData
  )
  return data
}
const removePermission = async (id: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.PERMISSIONS_DELETE,
    {},
    { id }
  )
  return data
}

export const usePermissions = () => {
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { setNotification } = useNotifications()

  const fallback: IPermissionsList[] = []

  const { data: permissionsList = fallback } = useQuery(
    queryKeys.permissions,
    getPermissions
  )

  const { mutate: addPermission } = useMutation(createPermission, {
    onSuccess: () => {
      setNotification({
        message: `${t('permission')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.permissions])
    },
  })
  const { mutate: deletePermission } = useMutation(removePermission, {
    onSuccess: () => {
      setNotification({
        message: `${t('permission')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.permissions])
    },
  })
  return { permissionsList, addPermission, deletePermission }
}
