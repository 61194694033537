import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { CloseButton } from 'components/common'
import { LoadingButton } from '@mui/lab'
import DownloadIcon from '@mui/icons-material/Download'

import { useStickyBar } from '../StickyBar/utils'
import { DatePickerComponent } from '../common'

import {
  ButtonsContainer,
  DataContainer,
  DownloadBarConatiner,
} from './index.style'

const DownloadWorkdaysBar = () => {
  const {
    downloadDate,
    setDownloadDate,
    downloadExcel,
    isFetching,
    isFetchingUop,
  } = useStickyBar()
  const { t } = useTranslation(['calendar', 'buttons'])

  return (
    <>
      <DownloadBarConatiner>
        <DataContainer>
          <Typography color="black">{t('downloadbar.title')}</Typography>
          <DatePickerComponent
            label={t('copybar.label1')}
            value={downloadDate}
            setValue={setDownloadDate}
            views={['year', 'month']}
            inputFormat="MM/yyyy"
          />
        </DataContainer>
        <ButtonsContainer>
          <LoadingButton
            endIcon={<DownloadIcon />}
            onClick={() => downloadExcel('general')}
            loading={isFetching}
            loadingPosition="end"
            variant="outlined"
          >
            {t('downloadbar.button1')}
          </LoadingButton>
          <LoadingButton
            endIcon={<DownloadIcon />}
            onClick={() => downloadExcel('uop')}
            loading={isFetchingUop}
            loadingPosition="end"
            variant="contained"
          >
            {t('downloadbar.button2')}
          </LoadingButton>
          <CloseButton />
        </ButtonsContainer>
      </DownloadBarConatiner>
    </>
  )
}

export default DownloadWorkdaysBar
