import { Box, styled, Button } from '@mui/material'
import { Form } from 'formik'

export const FormWrapper = styled(Form)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
})

export const ButtonWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly',
  alignItems: 'center',
})

export const ButtonComponent = styled(Button)({
  width: '30%',
  display: 'flex',
  alignSelf: 'center',
})
