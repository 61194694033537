import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, LinearProgress } from '@mui/material'

import { NotesForm } from '../../components'
import { IInitialValues, INotes, useNotes } from '../../useNotes'

interface EditNoteProps {
  initialValues: IInitialValues
  validationSchema: object
  userId: number
  note: INotes
}
const EditNote: React.FC<EditNoteProps> = ({
  initialValues,
  validationSchema,
  userId,
  note,
}): JSX.Element => {
  const { t } = useTranslation('buttons')
  const { editNote } = useNotes(userId, note.id)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        editNote(values)
        setSubmitting(false)
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <NotesForm />
          <Button type="submit">{t('save')}</Button>
          {isSubmitting && <LinearProgress />}
        </Form>
      )}
    </Formik>
  )
}
export default EditNote
