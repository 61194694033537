import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonGroup, Typography } from '@mui/material'
import { PkupContext } from 'pages/Pkup/context/PkupContext'

import { BoxContainer } from '../../../pages/Pkup/index.style'

const SelectTime: React.FC = (): JSX.Element => {
  const { t } = useTranslation('pkup')
  const { selectedMonth, selectedYear, setSelectedMonth, setSelectedYear } =
    useContext(PkupContext)

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const years = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
  ]
  return (
    <BoxContainer>
      <Typography variant="h3">{t('tabPanels.panel1.header')} </Typography>
      <Typography variant="subtitle1">{t('tabPanels.panel1.text1')}</Typography>
      <ButtonGroup>
        {months.map((month) => (
          <Button
            key={month}
            variant={month === selectedMonth ? 'contained' : 'outlined'}
            onClick={() => setSelectedMonth(month)}
          >
            {month}
          </Button>
        ))}
      </ButtonGroup>
      <Typography variant="subtitle1">{t('tabPanels.panel1.text2')}</Typography>
      <ButtonGroup>
        {years.map((year) => (
          <Button
            key={year}
            variant={year === selectedYear ? 'contained' : 'outlined'}
            onClick={() => setSelectedYear(year)}
          >
            {year}
          </Button>
        ))}
      </ButtonGroup>
    </BoxContainer>
  )
}
export default SelectTime
