import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-mui'
import { Box, Button, LinearProgress } from '@mui/material'
import { StatusEnum } from 'hooks/pkup/useStatus'
import { useStatus, useUpdateStatusPkup } from 'hooks/pkup'
import { usePkupsDetails } from 'pages/HrDashboard/hooks'
import { lightenDarkenColor } from 'utils/lightenDarkenColors'

interface PkupCommentFormProps {
  id: number
  page?: string
  initialValues?: { approverComment: string }
}
const PkupCommentForm: React.FC<PkupCommentFormProps> = ({
  id,
  page,
  initialValues,
}): JSX.Element => {
  const [selectedButton, setSelectedButton] = useState(0)
  const { t } = useTranslation(['reportPkup', 'buttons'])
  const { pkupDetails } = usePkupsDetails(Number(id))
  const { getPkupStatus } = useStatus()
  const updatePkupStatus = useUpdateStatusPkup(Number(id), selectedButton)

  return (
    <Formik
      initialValues={initialValues || { approverComment: '' }}
      onSubmit={(values, { setSubmitting }) => {
        updatePkupStatus(values?.approverComment)
        setSubmitting(false)
      }}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <>
          <Form>
            {page !== 'pkupsDashboard' || pkupDetails.state === 1 ? (
              <Field
                fullWidth
                disabled={pkupDetails.state !== 1 && page !== 'pkupsDashboard'}
                component={TextField}
                name="approverComment"
                type="text"
                label={t('modal.showMore.label8')}
                margin="normal"
                variant="outlined"
                size="small"
                multiline
              />
            ) : null}
            {isSubmitting && <LinearProgress />}
            <Box
              sx={{
                marginTop: '1rem',
                maxWidth: '18rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="submit"
                sx={{
                  backgroundColor: getPkupStatus(2).color,
                  ':hover': {
                    backgroundColor: lightenDarkenColor(
                      getPkupStatus(2).color,
                      -30
                    ),
                  },
                }}
                onClick={() => setSelectedButton(StatusEnum.APPROVED)}
              >
                {t('approve', { ns: 'buttons' })}
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: getPkupStatus(4).color,
                  ':hover': {
                    backgroundColor: lightenDarkenColor(
                      getPkupStatus(4).color,
                      -30
                    ),
                  },
                }}
                onClick={() => setSelectedButton(StatusEnum.MODIFY)}
              >
                {t('modify', { ns: 'buttons' })}
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: getPkupStatus(3).color,
                  ':hover': {
                    backgroundColor: lightenDarkenColor(
                      getPkupStatus(3).color,
                      -30
                    ),
                  },
                }}
                onClick={() => setSelectedButton(StatusEnum.REJECTED)}
              >
                {t('reject', { ns: 'buttons' })}
              </Button>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  )
}
export default PkupCommentForm
