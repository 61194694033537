import { Box } from '@mui/material'
import { isFuture } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { IUserProps } from 'components/users'

import { Summary, TableAllocations, SelectAllocations } from '../common'
import { ISelectEditAllocationProps } from '../utils'

const FutureAllocations: React.FC<IUserProps & ISelectEditAllocationProps> = ({
  user,
  setEditAllocation,
}) => {
  const { t } = useTranslation('userAllocations')

  const futureAllocations = user?.allocations.filter((allocation) =>
    isFuture(
      new Date(
        Number(allocation.date.split('/')[0]),
        Number(allocation.date.split('/')[1]) - 1
      )
    )
  )
  const showFutureAllocations = SelectAllocations(
    futureAllocations,
    setEditAllocation,
    user
  )
  return (
    <Box>
      <TableAllocations allocation={showFutureAllocations || []} />
      <Summary
        allocations={futureAllocations}
        title={t('tabPanel.futureSummary')}
      />
    </Box>
  )
}

export default FutureAllocations
