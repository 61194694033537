import FullCalendar, { EventSourceInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useGetProjectDetailsCalendar } from 'hooks/projectDetails'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useNotifications } from 'components/common'
import { StyleWrapper } from 'pages/Calendar/components/Calendar/index.style'

import EventContentProjectCalendar from './Event'

const Calendar: React.FC = (): JSX.Element => {
  const { t } = useTranslation('projectDetails')
  const params = useParams()
  const { projectCalendarDate, setProjectCalendarDate } = useNotifications()
  const { projectDetailsWorkdays } = useGetProjectDetailsCalendar(
    params.id,
    projectCalendarDate
  )

  return (
    <StyleWrapper>
      <Box>
        <FullCalendar
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            listPlugin,
          ]}
          timeZone="local"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          datesSet={(dateInfo) => {
            setProjectCalendarDate(format(new Date(dateInfo.start), 'yyyy-MM'))
          }}
          showNonCurrentDates={false}
          stickyHeaderDates={true}
          buttonText={{
            today: t('dateType.today'),
            month: t('dateType.month'),
            week: t('dateType.week'),
            day: t('dateType.day'),
          }}
          nowIndicator={true}
          firstDay={1}
          dayHeaderFormat={{
            day: '2-digit',
            weekday: 'short',
            omitCommas: true,
          }}
          slotLabelFormat={{ hour: 'numeric', minute: '2-digit' }}
          views={{
            dayGridMonth: {
              dayHeaderFormat: { weekday: 'short' },
            },
          }}
          editable={false}
          events={projectDetailsWorkdays as EventSourceInput}
          eventDisplay="block"
          dayMaxEvents={false}
          slotMaxTime="23:00:00"
          slotMinTime="06:00:00"
          slotEventOverlap={false}
          allDaySlot={false}
          businessHours={{
            startTime: '06:00',
            endTime: '23:00',
            date: '2022-05-05',
            daysOfWeek: [1, 2, 3, 4, 5],
          }}
          eventContent={EventContentProjectCalendar}
          height="500px"
          locale="pl"
          initialView="timeGridDay"
        />
      </Box>
    </StyleWrapper>
  )
}

export default Calendar
