import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-mui'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  IconButton,
  InputBase,
  LinearProgress,
  TextField as TF,
  Tooltip,
  Typography,
} from '@mui/material'
import { useSupervisor, useUpdateApplication } from 'hooks/pkup'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import DownloadIcon from '@mui/icons-material/Download'
import { PkupContext } from 'pages/Pkup/context/PkupContext'
import { useAuth } from 'hooks/useAuth'
import { useGetUserList } from 'hooks/customers'
import { useAuthorsWork } from 'pages/Pkup/hooks/useAuthorsWork'
import { CustomAutocompleteInput } from 'components/common'
import { useProjectsForPkup } from 'pages/Pkup/hooks'
import { AddPkupWorkProps } from 'hooks/pkup/types'
import palette from 'theme/palette'

interface AuthorsWorkFormProps {
  buttonName: string
  disabled: boolean
  initialValues: AddPkupWorkProps['values']
}

const AuthorsWorkForm: React.FC<AuthorsWorkFormProps> = ({
  buttonName,
  disabled,
  initialValues,
}): JSX.Element => {
  const { t } = useTranslation(['pkup', 'validation', 'buttons'])
  const { usersList } = useGetUserList()
  const { projectsForPkupList } = useProjectsForPkup()
  const { selectedAuthorsWork, date } = useContext(PkupContext)
  const { addPkupWork } = useAuthorsWork(date)
  const { handleDownload } = useUpdateApplication()
  const { user } = useAuth()
  const { getSupervisor } = useSupervisor()
  const [fileList, setFileList] = useState<File[]>([])

  const attachment: AddPkupWorkProps['attachment'] | null | undefined = useRef()
  const validationSchema = yup.object({
    title: yup
      .string()
      .min(2, `${t('titlePkupWork.minLength', { ns: 'validation' })}`)
      .required(`${t('titlePkupWork.required', { ns: 'validation' })}`),
    description: yup
      .string()
      .min(2, `${t('descriptionPkupWork.minLength', { ns: 'validation' })}`)
      .max(400, `${t('descriptionPkupWork.maxLength', { ns: 'validation' })}`)
      .required(`${t('descriptionPkupWork.required', { ns: 'validation' })}`),
    projectId: yup
      .object()
      .required(`${t('projectId.required', { ns: 'validation' })}`),
  })
  return (
    <Box sx={{ maxHeight: '70vh', overflow: 'auto', paddingRight: '10px' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (attachment !== null) {
            addPkupWork({ values: { ...values }, attachment: attachment })
          }
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ errors, setFieldValue, isSubmitting }) => (
          <Form>
            {buttonName === 'add' ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    width: '100%',
                  }}
                >
                  <InputBase
                    type="file"
                    name="files"
                    id="fileUpload"
                    inputRef={attachment}
                    inputProps={{ multiple: true }}
                    fullWidth
                    hidden={true}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target !== null) {
                        setFileList(Object.values(e.target.files as FileList))
                      }
                    }}
                    sx={{ display: 'none' }}
                  />
                  <label htmlFor="fileUpload">
                    <Button variant="outlined" component="span">
                      {t('modal.addAuthorsWork.button3')}
                    </Button>
                  </label>

                  <Box>
                    {t('modal.addAuthorsWork.text')} {fileList.length}
                  </Box>
                </Box>
                {fileList.length ? (
                  <Box
                    sx={{
                      border: `1px solid ${palette.secondary.dark}`,
                      borderRadius: '10px',
                      boxSizing: 'border-box',
                      width: '100%',
                      padding: '10px',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="h3">
                      {t('modal.addAuthorsWork.label5')}
                    </Typography>
                    {fileList?.map(({ name }, index) => (
                      <Typography variant="h6" key={index}>
                        {name}
                      </Typography>
                    ))}
                  </Box>
                ) : null}
              </Box>
            ) : (
              <Box>
                <Typography variant="subtitle2">
                  {t('modal.showMore.label1')}:
                </Typography>
                {initialValues.files.length ? (
                  initialValues?.files?.map((file) => (
                    <Box
                      key={file.id}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Typography variant="h6">{file.name}</Typography>
                      <Tooltip
                        title={t('modal.showMore.tooltip1') as string}
                        key="send"
                        onClick={() => handleDownload(file)}
                      >
                        <IconButton color="success">
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ))
                ) : (
                  <Typography variant="subtitle2">
                    {t('modal.showMore.label1text')}
                  </Typography>
                )}
              </Box>
            )}
            <Field
              fullWidth
              key="1"
              component={TextField}
              name="title"
              type="text"
              label={`${t('modal.common.label1')}`}
              margin="normal"
              variant="outlined"
              size="small"
              disabled={disabled}
            />
            <Field
              component={TextField}
              fullWidth
              margin="normal"
              multiline
              name="description"
              type="text"
              label={`${t('modal.common.label2')}`}
              variant="outlined"
              size="small"
              disabled={disabled}
            />
            <Box mt={2} mb={1}>
              <Field
                component={CustomAutocompleteInput}
                optionsData={
                  projectsForPkupList.map((project) => {
                    return {
                      name: project.name,
                      id: project.id,
                    }
                  }) || []
                }
                name="projectId"
                label={t('modal.addAuthorsWork.label3')}
                disableClearable={true}
                error={errors.projectId}
                disabled={disabled}
              />
            </Box>
            {!disabled && (
              <Autocomplete
                multiple
                id="members"
                options={usersList}
                disableCloseOnSelect
                getOptionLabel={(option) =>
                  `${option?.firstName} ${option.lastName} ${option.email}`
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.firstName} {option.lastName} {option.email}
                  </li>
                )}
                onChange={(e, value) => {
                  const members = value
                    .filter((member) => member.id !== user?.id)
                    .map((member) => {
                      return {
                        id: member.id,
                        title: `${member.firstName} ${member.lastName}`,
                      }
                    })
                  setFieldValue('members', members)
                }}
                renderInput={(params) => (
                  <TF
                    {...params}
                    label={`${t('modal.addAuthorsWork.label4')}`}
                    name="checked"
                    margin="normal"
                  />
                )}
              />
            )}
            {buttonName === 'edit' ? (
              <>
                <Typography variant="subtitle2">
                  {t('modal.showMore.label5')}:
                </Typography>
                <ul>
                  {selectedAuthorsWork?.workmembers?.map((member) => (
                    <Typography key={member.userId} variant="subtitle2">
                      <li>
                        {getSupervisor !== undefined &&
                          getSupervisor(member.userId).name}{' '}
                        {member.userId === user?.id &&
                          t('modal.showMore.label5text')}
                      </li>
                    </Typography>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
                {isSubmitting && <LinearProgress />}
              </>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  )
}
export default AuthorsWorkForm
