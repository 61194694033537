import { isPast } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { IUserProps } from 'components/users'

import { Summary, TableAllocations, SelectAllocations } from '../common'
import { ISelectEditAllocationProps } from '../utils'

const PreviousAllocations: React.FC<
  IUserProps & ISelectEditAllocationProps
> = ({ user, setEditAllocation }) => {
  const { t } = useTranslation('userAllocations')

  const previousAllocations = user?.allocations.filter((allocation) =>
    isPast(
      new Date(
        Number(allocation.date.split('/')[0]),
        Number(allocation.date.split('/')[1])
      )
    )
  )
  const showPreviousAllocations = SelectAllocations(
    previousAllocations,
    setEditAllocation,
    user
  )
  return (
    <>
      <TableAllocations allocation={showPreviousAllocations || []} />
      <Summary
        allocations={previousAllocations}
        title={t('tabPanel.previousSummary')}
      />
    </>
  )
}

export default PreviousAllocations
