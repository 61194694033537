import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { permissionList, isUserPermitted } from 'services'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { queryKeys } from 'react-query/constants'
import { useAuth } from 'hooks/useAuth'

interface IAgreement {
  agreementId: number
  startDate: Date
  endDate: Date | null
}

const addAgreementToUser = async (agreement: IAgreement, userId: number) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.ADD_AGREEMENT_TO_USER,
    { ...agreement, userId: userId },
    { userId }
  )
  return data
}

const deleteAgreementToUser = async (id: number | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.DELETE_AGREEMENT_TO_USER,
    {},
    {
      id: id,
    }
  )
  return data
}

export const useUserAgreements = () => {
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { user } = useAuth()
  const { setNotification, handleCloseModal } = useNotifications()
  const hasAgreementPermission = isUserPermitted(user, [
    permissionList.AGREEMENTS_VIEW,
  ])
  const { mutate: addUserAgreement } = useMutation(
    (agreement: IAgreement) => addAgreementToUser(agreement, Number(id)),
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('agreement')} ${t('add')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        void queryClient.invalidateQueries([queryKeys.user])
        void queryClient.invalidateQueries([queryKeys.userOne])
      },
    }
  )
  const { mutate: deleteUserAgreement } = useMutation(deleteAgreementToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('agreement')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.user])
      void queryClient.invalidateQueries([queryKeys.userOne])
    },
  })
  return { addUserAgreement, deleteUserAgreement, hasAgreementPermission }
}
