import { useDeleteWorkdays } from 'hooks/calendar/useDeleteWorkdays'
import { useUpdateWorkdays } from 'hooks/calendar/useUpdateWorkdays'
import { addHours, format, minutesToHours } from 'date-fns'
import { FormikValues } from 'formik'
import { useNotifications } from 'components/common'

import { useCalendarContext } from '../../context'

interface InitialValues {
  contract: string
  comment: string
  startTime: Date
  endTime: Date
  mood: string | null
}

export function useWorkdaysEditModal() {
  const { clickedInfo, types } = useCalendarContext()
  const { setIsOpenPopup } = useNotifications()
  const { updateWorkdays, updateAndCopyWorkdays } = useUpdateWorkdays()
  const { deleteSingleWorkday } = useDeleteWorkdays()

  const localoffset = new Date().getTimezoneOffset()

  const initialValues: InitialValues = {
    contract: (
      types?.find(
        (worktype) =>
          worktype.name === clickedInfo?.event?._def.extendedProps.contract
      )?.id || 0
    ).toString(),
    comment: clickedInfo?.event?._def.extendedProps.comment,
    startTime: addHours(
      clickedInfo?.event?._instance?.range?.start as Date,
      minutesToHours(localoffset)
    ),
    endTime: addHours(
      clickedInfo?.event?._instance?.range?.end as Date,
      minutesToHours(localoffset)
    ),
    mood: clickedInfo?.event?._def.extendedProps.progress,
  }

  const handleDeleteEvent = () => {
    setIsOpenPopup(true)
    deleteSingleWorkday(parseInt(clickedInfo.event.id))
  }

  const handleCopySingleEvent = async (values: FormikValues) => {
    setIsOpenPopup(true)
    let startTime = ''
    let endTime = ''

    if (values.startTime) {
      startTime =
        values.startTime &&
        format(
          new Date(clickedInfo?.event?._instance?.range?.start as Date),
          'yyyy-MM-dd' + ' ' + format(values.startTime, 'HH:mm')
        )
    }
    if (values.endTime) {
      endTime =
        format(
          new Date(clickedInfo?.event?._instance?.range?.end as Date),
          'yyyy-MM-dd'
        ) +
        ' ' +
        format(values.endTime, 'HH:mm')
    }
    const updatedEvent = {
      id: parseInt(clickedInfo.event._def.publicId),
      workTypeId: values.contract.toString(),
      comment: values.comment,
      logDayFrom: !values.startTime ? undefined : startTime,
      logDayTo: !values.endTime ? undefined : endTime,
      progress: values.mood,
    }
    updateAndCopyWorkdays(updatedEvent)
  }

  const onSubmitEditHandler = async (values: FormikValues) => {
    setIsOpenPopup(true)
    let startTime = ''
    let endTime = ''

    if (values.startTime) {
      startTime =
        values.startTime &&
        format(
          new Date(clickedInfo?.event?._instance?.range?.start as Date),
          'yyyy-MM-dd' + ' ' + format(values.startTime, 'HH:mm')
        )
    }
    if (values.endTime) {
      endTime =
        format(
          new Date(clickedInfo?.event?._instance?.range?.end as Date),
          'yyyy-MM-dd'
        ) +
        ' ' +
        format(values.endTime, 'HH:mm')
    }

    const updatedEvent = {
      id: clickedInfo.event.id,
      workTypeId: values.contract.toString(),
      comment: values.comment,
      logDayFrom: !values.startTime ? undefined : startTime,
      logDayTo: !values.endTime ? undefined : endTime,
      progress: values.mood,
    }
    updateWorkdays(updatedEvent)
  }

  return {
    initialValues,
    onSubmitEditHandler,
    handleDeleteEvent,
    handleCopySingleEvent,
  }
}
