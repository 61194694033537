export enum PageRoutes {
  CALENDAR = '/calendar',
  CUSTOMERS = '/customers',
  CUSTOMERS_EDIT = '/customers/:id',
  GROUPS = '/groups',
  HOLIDAYS = '/holidays',
  HOME = '/',
  HR_DASHBOARD = '/hr-dashboard',
  INVENTORY = '/inventory',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  PKUP = '/pkup',
  PKUP_DETAILS = '/pkups/:id',
  PKUPS_DASHBOARD = '/pkups-dashboard',
  PKUPS_DASHBOARD_DETAILS = '/pkups-dashboard/:id',
  PM_DASHBOARD = '/pm-dashboard',
  REMIND_PASSWORD = '/remind-password/:token',
  SYSTEM_ACTIVITY = '/system/activity',
  SYSTEM_BUSINESS_UNITS = '/system/businessUnits',
  SYSTEM_BUSINESS_UNITS_USERS = '/system/businessUnits/:id/users',
  SYSTEM_HOLIDAYS_CATEGORIES = '/system/holidays-categories',
  SYSTEM_LOCATIONS = '/system/locations',
  SYSTEM_LOCATIONS_USERS = '/system/locations/:id/users',
  SYSTEM_TAGS = '/system/tags',
  SYSTEM_WORKTYPES = '/system/worktypes',
  USER = '/users/:id',
  USERS = '/users',
  USER_ALLOCATIONS = '/users/:id/allocations',
  USER_CALENDAR = '/calendar/:id',
  USER_CV = '/users/:id/cv',
  USER_NOTES = '/users/:id/notes',
  VACATION_REQUEST = '/vacation-request',
  PROJECTS = '/projects',
  PROJECT_DETAILS = '/projects/:id',
  PROJECT_USERS = '/projects/:id/users',
  PROFILE = '/profile',
  REPORT_AGREEMENTS = '/reports/agreements',
  REPORT_ALLOCATIONS = '/reports/allocations',
  REPORT_CUSTOMERS = '/reports/customers',
  REPORT_DASHBOARD = '/reports/dashboard',
  REPORT_PKUP = '/reports/pkup',
  REPORT_PRODUCTIVITY = '/reports/productivity',
  REPORT_WORKDAYS = '/reports/workdays',
}
