import { Paper, styled } from '@mui/material'

export const StyledTitlePaper = styled(Paper)`
  height: 2.5rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  width: 14.5rem;
  border: none;
`
