import { Box, Typography, styled } from '@mui/material'

type ExtraProps = {
  component: React.ElementType
}

export const Icon = styled(Box)(() => ({
  position: 'absolute',
  width: '32px',
  left: '6px',
}))

export const EventBox = styled(Box)(({ theme }) => ({
  '.fc-event .fc-event-draggable, fc-daygrid-event': {
    backgroundColor: theme.palette.primary.main,
  },
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  padding: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}))

export const EventTimeBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: '3px',
})

export const StartTime = styled(Typography)({
  fontSize: '12px',
  lineHeight: 1.05,
  textAlign: 'center',
})

export const EndTime = styled(Typography)({
  fontSize: '12px',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: 1.05,
  textAlign: 'center',
})

export const ProjectTitle = styled(Typography)<ExtraProps>({
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'center',
})

export const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: '0 8px 5px 8px',
  },
}))
