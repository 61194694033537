import { useTranslation } from 'react-i18next'
import { Typography, Box } from '@mui/material'
import palette from 'theme/palette'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { StyledButton } from 'components/common/SwitchButton/index.style'

import { useCalendarContext } from '../../context'

import { ListWrapper, ListItem } from './index.style'

const HolidaysDates = () => {
  const { setExcludeHolidays, holidayForMonth, excludeHolidays } =
    useCalendarContext()
  const { t } = useTranslation('calendar')
  const holidaysArr = holidayForMonth.map((day) => (
    <ListItem
      color={palette.primary.main}
      variant="subtitle2"
      gutterBottom
      key={day.start}
    >
      {day.start}
    </ListItem>
  ))
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          marginBottom: !excludeHolidays ? '10px' : 0,
        }}
      >
        <Typography variant="h3" sx={{ fontSize: '14px' }}>
          {t('dates.question1')}
        </Typography>
        <StyledButton
          valueistrue={excludeHolidays ? 'true' : 'false'}
          onClick={() => setExcludeHolidays((prev) => !prev)}
        >
          {excludeHolidays ? (
            <ThumbDownIcon color="error" />
          ) : (
            <ThumbUpIcon color="success" />
          )}
        </StyledButton>
      </Box>
      {!excludeHolidays && (
        <>
          <Typography variant="h3" sx={{ fontSize: '12px' }}>
            {t('dates.text2')}
          </Typography>
          <ListWrapper>{holidaysArr}</ListWrapper>
        </>
      )}
    </>
  )
}

export default HolidaysDates
