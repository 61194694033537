const NUMBER_OF_LI_TO_ANIMATE = 10

const BackgroundAnimation = () => {
  let listOfCircles: JSX.Element[] = []
  for (let i = 0; i < NUMBER_OF_LI_TO_ANIMATE; i++) {
    listOfCircles = [
      ...listOfCircles,
      <li key={i}>
        <svg
          className="svg-snoweb svg-theme-light"
          height="100"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 100 100"
          width="100"
          x="0"
          xmlns="http://www.w3.org/2000/svg"
          y="0"
        >
          <polygon
            className="svg-fill-primary"
            points="81.653 50.05 77.696 47.509 80.334 36.518 70.662 38.13 69.05 33.588 60.111 42.966 62.016 23.769 56.448 26.407 50 14.098 43.552 26.407 37.984 23.769 39.889 42.966 30.95 33.588 29.338 38.13 19.666 36.518 22.304 47.509 18.347 50.05 34.32 63.921 33.294 69.783 48.974 68.318 48.974 85.902 50 85.902 51.026 85.902 51.026 68.318 66.706 69.783 65.68 63.921 81.653 50.05"
            filter="invert(37%) sepia(75%) saturate(2278%) hue-rotate(2deg) brightness(103%) contrast(108%)"
          ></polygon>
        </svg>
      </li>,
    ]
  }
  return <ul className="circles">{listOfCircles}</ul>
}

export default BackgroundAnimation
