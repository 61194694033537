import { Navigate } from 'react-router-dom'

export const WithPermissions = ({
  Component,
  permission,
}: {
  Component: JSX.Element
  permission: object
}) => {
  return permission ? <>{Component}</> : <Navigate to="/" />
}
