import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

interface IProjectDetailsTagsData {
  tagId: string
  projectId: string | undefined
}

const deleteSingleProjectDetailsTags = async (
  ProjectDetailsTagsData: IProjectDetailsTagsData
) => {
  const { tagId, projectId } = ProjectDetailsTagsData
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.TAGS_DELETE_FROM_PROJECT,
    {},
    { projectId, tagId: tagId }
  )
  return data
}
export const useDeleteProjectDetailsTags = () => {
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const { mutate: deleteProjectDetailsTags } = useMutation(
    deleteSingleProjectDetailsTags,
    {
      onSuccess: () => {
        handleCloseModal()
        setNotification({
          message: `${t('stack')} ${t('delete')}`,
          variant: NOTIFICATION.success,
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.user])
        queryClient.invalidateQueries([queryKeys.customer])
        queryClient.invalidateQueries([queryKeys.projectHistory])
      },
    }
  )
  return { deleteProjectDetailsTags }
}
