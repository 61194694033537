import { Grid, Typography } from '@mui/material'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { CustomizedDataPicker } from '../../common'

interface IAgreementDatesProps {
  indefinite: boolean
  error: string | undefined
}

const AgreementDates: React.FC<IAgreementDatesProps> = ({
  indefinite,
  error,
}): JSX.Element => {
  const { t } = useTranslation('userEditPage')
  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={5}>
        <Field
          component={CustomizedDataPicker}
          name="startDate"
          label={t('agreements.form.data.start')}
          inputFormat="dd/MM/yyyy"
        />
      </Grid>
      {!indefinite && (
        <Grid item xs={5}>
          <Field
            component={CustomizedDataPicker}
            name="endDate"
            label={t('agreements.form.data.end')}
            inputFormat="dd/MM/yyyy"
          />
          {error && (
            <Typography variant="h6" color="error" mt={0.5}>
              {error}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default AgreementDates
