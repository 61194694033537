import { IAllocations } from 'hooks/types'
import AddAlarmIcon from '@mui/icons-material/AddAlarm'
import { Box, Tooltip, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ShowAllocations } from 'pages/Dashboard/modals'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ModalBox } from 'components/common/Modal/index.style'
import { ModalHeader } from 'components/common'

import { useYourProjects } from './utils'

const AllocationInfoForUser = ({
  allocations,
  buttonId,
  setButtonId,
}: {
  allocations: IAllocations[] | undefined
  buttonId: string
  setButtonId: Dispatch<SetStateAction<string>>
}): JSX.Element => {
  const { t } = useTranslation('dashboard')
  const { currentDate } = useYourProjects()
  const [open, setOpen] = useState(false)
  const [moreAllocations, setMoreAllocations] = useState<IAllocations[]>([])
  const currentProjects = allocations?.find(
    (a: IAllocations) => a.date === currentDate
  )
  if (allocations?.length === 0) {
    return <>{t('tabPanels.allocations.without')}.</>
  }
  const currentProject = allocations
    ?.sort((a: IAllocations, b: IAllocations) => a.date.localeCompare(b.date))
    .map((alloc: IAllocations) => alloc)

  const findProjectById = currentProject?.some(
    (project) => project.ProjectUser.ProjectId.toString() === buttonId
  )
  const filterProjectsWhichHaveId = currentProject?.filter(
    (project) => project.ProjectUser.ProjectId.toString() === buttonId
  )
  const handleClose = () => {
    setOpen(false)
    setButtonId('0')
  }
  useEffect(() => {
    if (findProjectById && filterProjectsWhichHaveId?.length) {
      setMoreAllocations(filterProjectsWhichHaveId)
      setOpen(true)
    }
  }, [buttonId])

  const yourAllocations = currentProjects && (
    <p>
      {t('tabPanels.allocations.current')}: <b>{currentProjects.FTE} </b>(
      {t('tabPanels.allocations.for')} {currentDate})
      {t('tabPanels.allocations.usedHours')}:{' '}
      {currentProjects.allUsedWorkingHours}/{currentProjects.allWorkingHours},{' '}
      {t('tabPanels.allocations.planned')} {currentProjects.allUsedPlannedHours}
      /{currentProjects.allUsedWorkingHours}
      {currentProjects?.isOverhours && (
        <Tooltip
          title={t('tabPanels.allocations.tooltip') as string}
          placement="top"
          security=""
        >
          <AddAlarmIcon color="info" />
        </Tooltip>
      )}
    </p>
  )
  return (
    <>
      {currentProjects ? (
        <>{yourAllocations}</>
      ) : (
        `${t('tabPanels.allocations.noFTE')}.`
      )}
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: 'auto' }}
      >
        <>
          <ModalBox>
            <ModalHeader
              handleClose={handleClose}
              title={t('modal.allAllocations.title')}
            />
            <Box sx={{ overflow: 'auto', padding: 3 }}>
              <Box sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                <ShowAllocations allocations={moreAllocations} />
              </Box>
            </Box>
          </ModalBox>
        </>
      </Modal>
    </>
  )
}

export default AllocationInfoForUser
