import { AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { apiRequest, APIRoutes } from 'apiServices'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { NOTIFICATION, useNotifications } from 'components/common'

const deleteInventoriesFromUser = async (id: number | null | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.INVENTORIES_DELETE_FROM_USER,
    {},
    { id }
  )
  return data
}
export const useDeleteInventory = () => {
  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')

  const { mutate: deleteInventory } = useMutation(deleteInventoriesFromUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('inventory')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.user])
      queryClient.invalidateQueries([queryKeys.freeInventories])
      queryClient.invalidateQueries([queryKeys.inventories])
      queryClient.invalidateQueries([queryKeys.userOne])
    },
  })
  return deleteInventory
}
