import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { apiRequest, APIRoutes } from 'apiServices'
import { queryKeys } from 'react-query/constants'
import { useNotifications } from 'components/common'
import { Workday, Worktype } from 'pages/Calendar/context/types'
import { IWorkdays } from 'pages/Calendar/components/Calendar/types'
import { useEffect } from 'react'
import palette from 'theme/palette'
import { workType } from 'components/common/RenderEventContent/enum'

export const useGetWorkdays = () => {
  const { t } = useTranslation('calendar')
  const { currentCalendarDate } = useNotifications()
  useEffect(() => {
    refetch()
  }, [currentCalendarDate])
  const displayLeaveName = (workday: IWorkdays) => {
    if (workday.workType.isHoliday) {
      return t('holiday')
    }
    switch (workday.workType.name) {
      case workType.BUSINESS_TRIP:
        return t('businessTrip')
      case workType.SICK:
        return t('sick')
      default:
        return workday.workType.name
    }
  }
  const createWorkdaysArray = async (workdays: IWorkdays[]) => {
    return Promise.all(
      workdays.map((workday) => {
        return {
          id: workday.id,
          title: displayLeaveName(workday),
          start: workday.logDayFrom,
          end: workday.logDayTo,
          startTime: workday.logDayFrom,
          endTime: workday.logDayTo,
          color: workday.isPlanned
            ? palette.primary.light
            : palette.primary.main,
          extendedProps: {
            isPlanned: workday.isPlanned,
            comment: workday.comment,
            contract: workday.workType.name,
            project: workday.project ? workday.project.name : null,
            homeOffice: true,
            isOverhours: workday.isOverhours ? t('overhours') : '',
            needApproval: workday.workType.needApproval,
            progress: workday.progress,
            isHoliday: workday.workType.isHoliday,
            VacationRequest: workday.VacationRequest,
          },
          description: workday.comment,
        }
      }) as Workday[]
    )
  }

  async function getWorkdays(currentCalendarDate: string) {
    const { data }: AxiosResponse<IWorkdays[]> = await apiRequest(
      APIRoutes.WORKDAYS_GETFORME,
      {},
      {},
      `date=${currentCalendarDate}`
    )

    const workdays = createWorkdaysArray(data)

    return workdays
  }

  async function getWorkdaysForUser(
    currentCalendarDate: string,
    id: string | undefined
  ) {
    const { data }: AxiosResponse<IWorkdays[]> = await apiRequest(
      APIRoutes.WORKDAYS_GETFORUSER,
      {},
      { id: id, date: currentCalendarDate }
    )

    const workdays = createWorkdaysArray(data)

    return workdays
  }

  const fallback: Workday[] = []
  const { data: workdays, refetch } = useQuery(
    [queryKeys.calendar, currentCalendarDate],
    () => getWorkdays(currentCalendarDate),
    {
      initialData: fallback,
    }
  )

  return { workdays, getWorkdays, getWorkdaysForUser }
}
