import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IUserProps } from 'components/users'

import { TypographyLink } from './index.style'

const Projects: React.FC<IUserProps> = ({ user }): JSX.Element => {
  const { t } = useTranslation('userEditPage')
  return (
    <>
      <Box>
        <Typography variant="h3">{t('projects.title1')}</Typography>
        <Box display="flex" flexWrap="wrap" mb={2}>
          {user?.projects?.map((project) => (
            <TypographyLink key={project.id} to={`/projects/${project.id}`}>
              {project.name},{'\u00a0'}
            </TypographyLink>
          ))}
        </Box>
      </Box>
      <Box>
        <Typography variant="h3">{t('projects.title2')}</Typography>
        <Box>
          {user?.customers?.map((customer) => (
            <TypographyLink key={customer.id} to={`/customers/${customer.id}`}>
              {customer.name},{'\u00a0'}
            </TypographyLink>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default Projects
