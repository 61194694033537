import * as yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography } from '@mui/material'
import {
  CustomizedDataPicker,
  CustomAutocompleteInput,
} from 'components/common'
import { IUserProps } from 'components/users'
import { useAddTimeframes } from 'hooks/customers'

import { getProjectName } from '../../components/utils'

const AddNewTimeFrames: React.FC<IUserProps> = ({ user }): JSX.Element => {
  const { t } = useTranslation(['userAllocations', 'validation', 'buttons'])
  const { addNewTimeframes } = useAddTimeframes()
  const projectName = getProjectName(user?.allocations?.[0], user)
  const initialValues = {
    selectedProject: projectName
      ? {
          name: projectName,
          id: user?.projects?.[0]?.ProjectUser?.projectId || null,
        }
      : null,
    startDate: new Date(),
    endDate: new Date(),
    notes: '',
  }
  const validationSchema = yup.object({
    notes: yup.string(),
    selectedProject: yup
      .object()
      .required(t('project.required', { ns: 'validation' }))
      .nullable(true),
    startDate: yup.date().required('startDate'),
    endDate: yup
      .date()
      .min(yup.ref('startDate'), t('endDate.minValue', { ns: 'validation' })),
  })
  const optionsArr = user?.projects?.map(({ name, ProjectUser }) => {
    return {
      name: name,
      id: ProjectUser?.projectId,
    }
  })
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const timeFramesData = {
            endDate: values.endDate,
            startDate: values.startDate,
            notes: values.notes,
            userId: user?.id,
            userProjectId: Number(values?.selectedProject?.id),
          }
          addNewTimeframes(timeFramesData)
        }}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form>
            <Grid container mt={2} columnSpacing={1}>
              <Grid item xs={12} mb={2}>
                <Field
                  component={CustomAutocompleteInput}
                  optionsData={optionsArr || []}
                  name="selectedProject"
                  label={t('modals.addTimeFrame.label1')}
                  disableClearable={true}
                  error={errors?.selectedProject}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label={t('modals.addTimeFrame.label2')}
                  name="startDate"
                  component={CustomizedDataPicker}
                  inputFormat="dd/MM/yyyy"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label={t('modals.addTimeFrame.label3')}
                  name="endDate"
                  component={CustomizedDataPicker}
                  inputFormat="dd/MM/yyyy"
                />
                {errors.endDate && (
                  <Typography variant="h6" color="error" mt={0.5}>
                    <>{errors.endDate}</>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  component={TextField}
                  name="notes"
                  label={t('modals.addTimeFrame.label4')}
                  margin="normal"
                  size="small"
                />
              </Grid>
            </Grid>
            <Button type="submit">{t('save', { ns: 'buttons' })}</Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddNewTimeFrames
