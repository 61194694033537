import { AuthorsWorkForm } from 'pages/Pkup/components'

const AddAuthorsWork: React.FC = (): JSX.Element => {
  const initialValuesAdd = {
    title: '',
    description: '',
    projectId: undefined,
    members: [],
    files: [],
  }

  return (
    <AuthorsWorkForm
      initialValues={initialValuesAdd}
      buttonName="add"
      disabled={false}
    />
  )
}
export default AddAuthorsWork
