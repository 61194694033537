import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import fileDownload from 'js-file-download'
import { Form, useFormikContext } from 'formik'
import { Box, Button, Container, Paper, Grid } from '@mui/material'
import { IProfile, useUser } from 'pages/Users/pages/utlis'

import { useGenerateCV, useSaveProfile } from '../UserCV/hooks'
import { IInitialValues } from '../UserCV/hooks/useGenerateCV'

import {
  BioOption,
  EducationOption,
  ProjectsOption,
  ProfileOption,
  TemplateOption,
} from './components'

const CVForm = ({
  initialValues,
  setCVPreview,
  cvPreview,
}: {
  initialValues: IInitialValues
  setCVPreview: (a: string) => void
  cvPreview: string
}) => {
  const { id } = useParams()
  const { userProfile } = useUser()
  const { previewUserCV } = useGenerateCV()
  const { t } = useTranslation('userCV')
  const { values, errors, touched, setFieldValue } =
    useFormikContext<IInitialValues>()

  const { user } = useUser()
  const {
    selectedProfile,
    selectedBio,
    selectedEducation,
    selectedProjects,
    selectedTemplate,
  } = values

  const { generateUserCV } = useGenerateCV(
    user.id,
    selectedBio,
    selectedTemplate,
    selectedEducation,
    selectedProjects
  )
  const updateCVProfile = useSaveProfile(
    selectedProfile,
    selectedBio,
    selectedEducation,
    selectedProjects
  )
  const onChangeValues = (selectProfile: IProfile | null) => {
    const educationIds = selectProfile?.educations?.map((education) =>
      education.educationId.toString()
    )
    const profileIds = selectProfile?.projects?.map((project) =>
      project.cvProjectId.toString()
    )
    setFieldValue(
      'selectedBio',
      selectProfile?.bios?.[0]?.bioId.toString() || ''
    )
    setFieldValue('selectedEducation', educationIds)
    setFieldValue('selectedProjects', profileIds)
  }

  const onChangeProfile = (selectProfile: IProfile | null) => {
    const projectId = selectProfile?.projects?.map(
      (project) => `&projectsIds=${project.cvProjectId}`
    )
    const biosId = selectProfile?.bios?.map((bio) => `&biosIds=${bio.bioId}`)
    const educationId = selectProfile?.educations?.map(
      (education) => `&educationsIds=${education.educationId}`
    )

    const params =
      selectProfile?.id !== undefined
        ? `template=${initialValues?.selectedTemplate}&userId=${id}${biosId}${educationId}${projectId}`
        : `template=${initialValues?.selectedTemplate}&userId=${id}`

    previewUserCV(params).then((data) => {
      setCVPreview(data)
    })
  }

  const onSaveProfile = () => {
    const projectId = selectedProjects?.map(
      (project) => `&projectsIds=${project}`
    )
    const biosId = `&biosIds=${selectedBio}`
    const educationId = selectedEducation?.map(
      (education) => `&educationsIds=${education}`
    )
    const params = `template=${selectedTemplate}&userId=${id}${biosId}${educationId}${projectId}`
    previewUserCV(params).then((data) => {
      setCVPreview(data)
    })
  }
  const generateCVInHTMLFormat = () => {
    fileDownload(
      new Blob([cvPreview]),
      `cv - ${user.firstName} ${user.lastName}.html`
    )
  }

  const generateCVInPDFFormat = () => {
    generateUserCV().then((response) => {
      fileDownload(
        new Blob([response]),
        `cv - ${user.firstName} ${user.lastName}.pdf`
      )
    })
  }
  const handleSaveCV = () => {
    onSaveProfile()
    updateCVProfile()
  }
  useEffect(() => {
    onChangeProfile(selectedProfile)
    onChangeValues(selectedProfile)
  }, [selectedProfile])

  return (
    <Grid container spacing={2}>
      <Grid item xs={5} md={4}>
        <Form>
          <Grid container>
            <Grid item xs={12}>
              <ProfileOption
                userProfile={userProfile}
                setFieldValue={setFieldValue}
                profileError={errors.selectedProfile}
                profileTouched={touched.selectedProfile}
              />
            </Grid>
            <Grid item xs={12}>
              <TemplateOption
                templateValue={selectedTemplate}
                setFieldValue={setFieldValue}
                templateError={errors.selectedTemplate}
                templateTouch={touched.selectedTemplate}
              />
            </Grid>
            <Grid item xs={12}>
              <BioOption
                bioValue={selectedBio}
                setFieldValue={setFieldValue}
                bioError={errors.selectedBio}
                bioTouch={touched.selectedBio}
              />
            </Grid>
            <Grid item xs={12}>
              <EducationOption />
            </Grid>
            <Grid item xs={12}>
              <ProjectsOption />
            </Grid>
          </Grid>
          <Button sx={{ marginTop: '1rem' }} type="submit">
            {t('generateProfile.generateProfile')}
          </Button>
          {selectedProfile?.id && selectedBio && (
            <Button
              sx={{ marginTop: '1rem', marginLeft: '0.5rem' }}
              onClick={() => handleSaveCV()}
            >
              {t('generateProfile.saveProfile')}
            </Button>
          )}
        </Form>
      </Grid>

      <Grid item xs={7} md={8}>
        <Container
          sx={{
            position: 'sticky',
            top: '3rem',
            right: '0',
            height: '90vh',
            overflow: 'auto',
            minWidth: '30rem',
          }}
        >
          {cvPreview && (
            <Paper
              sx={{
                padding: '1rem',
                margin: '1rem 0',
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: cvPreview }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '2rem',
                }}
              >
                <Button
                  sx={{ marginRight: '2rem' }}
                  onClick={() => generateCVInHTMLFormat()}
                >
                  {t('generateProfile.generateHtml')}
                </Button>
                <Button onClick={() => generateCVInPDFFormat()}>
                  {t('generateProfile.generatePdf')}
                </Button>
              </Box>
            </Paper>
          )}
        </Container>
      </Grid>
    </Grid>
  )
}

export default CVForm
