import { useMutation, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'
import { useTranslation } from 'react-i18next'

export const useUserProject = (
  userId: number | undefined,
  title?: string,
  description?: string,
  stack?: string,
  editId?: number
) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('handleMessage')
  const { setNotification, handleCloseModal } = useNotifications()

  const addProjectToUser = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_PROJECT_CREATE,
      {
        title,
        description,
        stack,
      },
      { id: userId }
    )
    return data
  }
  const deleteProjectFromUser = async (id?: number) => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_PROJECT_DELETE,
      {},
      { id }
    )
    return data
  }
  const editProjectFromUser = async () => {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.CV_PROJECT_UPDATE,
      {
        title,
        description,
        stack,
      },
      { id: editId }
    )
    return data
  }
  const { mutate: addProject } = useMutation(addProjectToUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('project')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdProjects])
    },
  })
  const { mutate: deleteProject } = useMutation(deleteProjectFromUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('project')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdProjects])
    },
  })
  const { mutate: editProject } = useMutation(editProjectFromUser, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('project')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.userByIdProjects])
    },
  })
  return { addProject, deleteProject, editProject }
}
