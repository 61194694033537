import { toDate } from 'date-fns'
import { Workday } from 'pages/Calendar/context/types'

export const tempArrWithDays = (arrayWithDays: Workday[]): Workday[] => {
  return arrayWithDays.sort(function (a, b) {
    return new Date(a.start).getTime() - new Date(b.start).getTime()
  })
}
export const convertToDate = (date: string): Date => {
  const splitDate = date?.split('-')
  const newDate = toDate(
    new Date(
      Number(splitDate?.[0]),
      Number(splitDate?.[1]) - 1,
      Number(splitDate?.[2].substring(0, 2))
    )
  )
  return newDate
}
