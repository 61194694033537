import { Paper, styled } from '@mui/material'

export const MainCard = styled(Paper)(({ theme }) => ({
  padding: '1rem',
  marginTop: '1rem',
  backgroundColor: theme.palette.secondary.main,
}))

export const PaperTitle = styled(Paper)(({ theme }) => ({
  padding: '0.25rem 0.5rem',
  margin: '0.25rem 0',
  backgroundColor: theme.palette.primary.light,
}))
