import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { Box, Button, Grid } from '@mui/material'
import { IFields } from 'views/LayoutUI/components/types'
import { PermissionsList } from 'pages/Groups/components'

import { regexNoWhiteSpace } from '../../utils'

import { usePermissions } from './utils'

const ManagePermissions: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['groups', 'validation', 'buttons'])
  const { addPermission, deletePermission } = usePermissions()
  const validationSchema = yup.object({
    code: yup
      .string()
      .matches(
        regexNoWhiteSpace,
        `${t('groupCode.match', { ns: 'validation' })}`
      )
      .required(`${t('codeOfPermission.required', { ns: 'validation' })}`),
    name: yup
      .string()
      .required(`${t('nameOfPermission.required', { ns: 'validation' })}`),
  })

  const permissionFields: IFields[] = [
    {
      id: '1',
      label: `${t('modal.managePermissions.label1')}`,
      name: 'code',
      type: 'text',
      inputProps: { style: { textTransform: 'uppercase' } },
    },
    {
      id: '2',
      label: `${t('modal.managePermissions.label2')}`,
      name: 'name',
      type: 'text',
    },
  ]

  return (
    <>
      <Box sx={{ height: '65vh', overflow: 'auto' }}>
        <Box>
          <PermissionsList deletePermission={deletePermission} />
        </Box>
      </Box>

      <Formik
        initialValues={{ code: '', name: '' }}
        onSubmit={(values, { setSubmitting }) => {
          addPermission({ code: values.code.toUpperCase(), name: values.name })
          setSubmitting(false)
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Grid container spacing={3} my={1}>
            {permissionFields.map(({ name, label, id, inputProps }) => (
              <Grid key={id} item xs={5}>
                <Field
                  fullWidth
                  sx={{ flex: 2 }}
                  component={TextField}
                  label={label}
                  name={name}
                  size="small"
                  inputProps={inputProps}
                />
              </Grid>
            ))}
            <Grid item xs={2}>
              <Button type="submit" sx={{ height: 40 }}>
                {t('save', { ns: 'buttons' })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  )
}

export default ManagePermissions
